import { API, UseCase } from '@ppg/common';
import { getListQueryParam, getRangeDateParam } from '../../../helpers';
import { IProjectDashboardRequestBody } from '../../../interfaces';
import { IListQueryOptions } from '../../../../interfaces/IListQueryOptions';

interface IGetAutomationStatsHistogramRequest extends IProjectDashboardRequestBody, IListQueryOptions {
}

export interface IAutomationStatisticsHistogramItem {
  day: string;
  clicked: number;
  delivered: number;
  ctr: number;
}

interface IGetAutomationStatsHistogramResult {
  dashboardAutomationStatisticsHistogram: {
    total: number,
    histogram: IAutomationStatisticsHistogramItem[]
  };
}

export class GetProjectAutomationStatsHistogramUseCase extends UseCase<IGetAutomationStatsHistogramRequest, IGetAutomationStatsHistogramResult> {
  protected async execute({
                            from,
                            to,
                            limit,
                            offset,
                            timezone
                          }: IGetAutomationStatsHistogramRequest) {

    return API.HTTP.get<IGetAutomationStatsHistogramResult>(`statistics/v2/projects/${ API.HTTP.currentProjectId }/automations/statistics/histogram${ getRangeDateParam(from, to) }${ getListQueryParam(offset, limit) }&timezone=${ timezone }`);
  };
}

export const getProjectAutomationStatsHistogramUseCase = new GetProjectAutomationStatsHistogramUseCase();
