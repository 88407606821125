import { UseCase, API } from '@ppg/common';
import { IGetPlugin, IGetSubscriptionFormsResult } from '../interfaces';

export class GetSubscriptionFormsUseCase extends UseCase<IGetPlugin, IGetSubscriptionFormsResult[]> {
  protected async execute({ websiteIntegrationId }: IGetPlugin) {
    return API.HTTP.get<IGetSubscriptionFormsResult[]>(`wi/projects/${ API.HTTP.currentProjectId }/website-integrations/${ websiteIntegrationId }/subscription-forms`);
  }
}

export const getSubscriptionForms = new GetSubscriptionFormsUseCase();
