import { API, UseCase } from '@ppg/common';
import { eventTypes, Webhook } from '../../modelsMobx/Webhook';

interface IWebhookListResponse {
  data: Webhook[];
  metadata: {
    total: number;
  }
}

interface IWebhookList {
  limit: number;
  offset: number;
  project: string;
}

interface IUpdateWebhook {
  id: string;
  project: string;
  headers: object,
  url: string;
  type: eventTypes;
  enabled: boolean;
}

interface IDeleteWebhook {
  project: string;
  id: string;
}

interface ICreateWebhook {
  project: string;
  headers: object;
  url: string;
  type: eventTypes;
}

interface ICreateWebhookResponse {
  id: string;
}

export class GetWebhookList extends UseCase<IWebhookList, IWebhookListResponse> {
  protected async execute({ project, offset, limit }: IWebhookList) {
    return API.HTTP.get<IWebhookListResponse>(`wh/${ project }/webhooks?offset=${ offset }&limit=${ limit }`);
  }
}

export class CreateWebhook extends UseCase<ICreateWebhook, ICreateWebhookResponse> {
  protected async execute({ project, headers, url, type }: ICreateWebhook) {
    return API.HTTP.post<ICreateWebhookResponse>(`wh/${ project }/webhooks`, {
      headers: headers,
      url: url,
      type: type,
    });
  }
}

export class UpdateWebhook extends UseCase<IUpdateWebhook, void> {
  protected async execute({ project, headers, url, type, id, enabled }: IUpdateWebhook) {
    return API.HTTP.put<void>(`wh/${ project }/webhooks/${ id }`, {
      headers: headers,
      url: url,
      type: type,
      enabled: enabled,
    });
  }
}

export class DeleteWebhook extends UseCase<IDeleteWebhook, void> {
  protected async execute({ project, id }: IDeleteWebhook) {
    return API.HTTP.delete<void>(`wh/${ project }/webhooks/${ id }`);
  }
}

export const getWebhookListUseCase = new GetWebhookList();
export const createWebhookUseCase = new CreateWebhook();
export const updateWebhookUseCase = new UpdateWebhook();
export const deleteWebhookUseCase = new DeleteWebhook();
