import { API, UseCase } from "@ppg/common";

interface IGetResolveLabelsNamesToIdsRequest {
  labels: string[];
}

type IGetResolveLabelsNamesToIdsResponse = string[]

export class GetResolveLabelsNamesToIds extends UseCase<IGetResolveLabelsNamesToIdsRequest, IGetResolveLabelsNamesToIdsResponse> {
  protected async execute({labels}: IGetResolveLabelsNamesToIdsRequest) {

    let params = new URLSearchParams();

    for (let label of labels) {
      params.append("labels", label)
    }

    const paramsLabels = labels.length !== 0 ? params.toString() : 'labels='

    return API.HTTP.get<IGetResolveLabelsNamesToIdsResponse>(`core/projects/${ API.HTTP.currentProjectId }/segmentation/ids?${paramsLabels}`)
  }
}

export const getResolveLabelsNamesToIds = new GetResolveLabelsNamesToIds()
