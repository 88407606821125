import { DefaultRawDatum } from '@nivo/pie';
import { TimeUnit } from '@ppg/common';

export enum PlatformType {
  WEB = 'web',
  MOBILE = 'mobile'
}

export interface IOrganizationDashboardRequestBody extends IProjectDashboardRequestBody {
  organization: string;
}

export interface IProjectDashboardRequestBody {
  platform: PlatformType;
  from: string;
  to: string;
  timezone: string;
}

export interface ICampaignClickedDeliveredHourStats {
  hour: string;
  clicked: number;
  delivered: number;
}

export interface IDashboardCampaignsStats {
  clicked: number;
  delivered: number;
  sent: number;
  ctr: number;
  campaignsCount: number,
  campaignHistogram: ICampaignHistogramItem[];
}

export interface ICampaignHistogramItem {
  day: string,
  y?: ICampaignHistogramData;
  data?: ICampaignHistogramData;
}

export interface ICampaignHistogramData {
  dsp: number;
  campaignCount: number;
  clicked: number;
  ctr: number;
  delivered: number;
  sent: number;
  averageCtr?: number;
  averageDsp?: number;
}

export interface ICampaignSentHourStats {
  hour: string;
  count: number;
}

export interface IDashboardCampaignsStatsWithCount extends IDashboardCampaignsStats {
  campaignsCount: number;
  averageCtr?: number;
}

export interface IActiveSubscribersHistogramItem {
  day: string;
  projectsCount: number;
  activeSubscribers: number;
}

export interface IGetActiveSubscribersHistogramResult {
  activeSubscribers: {
    total: number;
    histogram: IActiveSubscribersHistogramItem[]
  };
}

export interface IGetActiveSubscribersInRangeResult {
  maxProjectCount: number,
  data: {
    projectsCount: number,
    x: string,
    y: number,
  }[]
}

export interface IGetActiveSubscribersResult {
  activeSubscribers: number;
  iosActiveSubscribers?: number;
  androidActiveSubscribers?: number;
  huaweiActiveSubscribers?: number;
}

export interface IGetSubscribersStructureResult {
  browser: IPieChartStats;
  device: IPieChartStats;
  os: IPieChartStats;
}

export interface IPieChartStats {
  stats: DefaultRawDatum[];
}

export interface ISubscriberActivityHistogramItem {
  day: string,
  activity: number
}

export interface IGetSubscribedActivityResult {
  subscribersActivity: {
    subscribed: number,
    unsubscribed: number,
    subscribedHistogram: ISubscriberActivityHistogramItem[]
    unsubscribedHistogram: ISubscriberActivityHistogramItem[]
  };
}

export interface IGetSubscribersActivityHistogramResult {
  subscribersActivity: {
    total: number,
    subscribedHistogram: ISubscribedActivityHistogramItem[],
    unsubscribedHistogram: IUnsubscribedActivityHistogramItem[]
  };
}

export interface ISubscribedActivityHistogramItem {
  day: string;
  subscribed: number;
}

export interface IUnsubscribedActivityHistogramItem {
  day: string;
  unsubscribed: number;
}

export interface ISubscribersActivityHistogram extends ISubscribedActivityHistogramItem, IUnsubscribedActivityHistogramItem {
}

export interface IGetSubscribersAverageLifetimeUseCaseResult {
  subscribersAverageLifetime: number;
  unit: TimeUnit;
}

export interface ILifecycleStructure {
  bucket: string,
  total: number
}

export interface IGetSubscribersLifetimeStructureResult {
  subscribersLifetimeStructure: ILifecycleStructure[];
}

export interface ISubscriberLocalization {
  latitude: number;
  longitude: number;
  count: number;
}

export interface IGetSubscribersLocalisationResult {
  averageSubscribersLocalisations: {
    localisations: ISubscriberLocalization[]
    total: number
  };
}
