import { API, UseCase } from '@ppg/common';
import { getRangeDateParam } from '../../../helpers';

export interface IAutomationCampaign {
  automation: string,
  campaigns: string[]
}

interface IGetAutomationRankRequest {
  organization: string;
  from: string,
  to: string,
  timezone: string,
  automations: IAutomationCampaign[]
}

export interface IAutomationRankResultItem {
  automation: string;
  clicked: number;
  delivered: number;
  ctr: number;
}

export interface GetAutomationRankResult {
  ranking: IAutomationRankResultItem[];
}

export class GetAutomationRankUseCase extends UseCase<IGetAutomationRankRequest, GetAutomationRankResult> {
  protected async execute({ organization, from, to, timezone, automations }: IGetAutomationRankRequest) {
    return API.HTTP.post<GetAutomationRankResult>(`statistics/organizations/${ organization }/campaigns/statistics/automation/rank${ getRangeDateParam(from, to) }&timezone=${ timezone }`, {
      automations
    });
  }
}

