import { API, UseCase } from "@ppg/common";
import { IUpdateSendPushCampaign, ICampaignIdResponse } from '../interfaces';

interface IUpdateLabelPushRequest extends IUpdateSendPushCampaign{}
interface IUpdateLabelPushResponse extends ICampaignIdResponse {}

export class UpdateLabelPushUseCase extends UseCase<IUpdateLabelPushRequest, IUpdateLabelPushResponse> {
  protected async execute({ campaignId, options, message }: IUpdateLabelPushRequest) {
    return API.HTTP.put<IUpdateLabelPushResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/labels/${ campaignId }`, { message, options });
  }
}

export const updateLabelPushUseCase = new UpdateLabelPushUseCase();
