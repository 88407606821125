import { property } from '@ppg/common';
import { WebsiteIntegrationRelated } from '../../stores/project/WebsiteIntegrationRelated';
import { updateServiceWorkerUseCase } from '../../useCases/website-integration';

export interface IServiceWorker {
  swPath: string;
  swScope: string;
}

export class SwWorker extends WebsiteIntegrationRelated implements IServiceWorker {
  @property() public swPath: string;
  @property() public swScope: string;

  constructor(serviceWorkerDTO: IServiceWorker) {
    super();
    this.swPath = serviceWorkerDTO.swPath;
    this.swScope = serviceWorkerDTO.swScope;
  }

  static createPlugin(serviceWorkerDTO?: IServiceWorker) {
    if (!serviceWorkerDTO) {
      return SwWorker.createDefault();
    } else {
      return new SwWorker(serviceWorkerDTO);
    }
  }

  static createDefault() {
    return new SwWorker({
      swPath: '',
      swScope: ''
    })
  }

  public async save(): Promise<void> {
    await updateServiceWorkerUseCase.exec({
      websiteIntegrationId: this.websiteIntegrationId,
      serviceWorkerScope: this.swScope,
      serviceWorkerPath: this.swPath,
    })
  }
}
