import * as lodash from 'lodash';
import * as React from 'react';
import { AbstractField } from './AbstractField';

import { IAbstractFieldProps } from './IAbstractFieldProps';

export interface IRangeFieldProps extends IAbstractFieldProps {
  disabled?: boolean;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  min?: number;
  step?: number;
  max?: number;
}

export class RangeField extends AbstractField<IRangeFieldProps, {}> {

  private onChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.props.attribute.setValue(e.currentTarget.value);
    lodash.invoke(this.props, 'onChange', e);
  };

  public render() {
    const { attribute, step, min, max, disabled } = this.props;

    return <input ref="inputRange" type="range"
                  id={ this.inputId }
                  value={ ~~attribute.getValue() }
                  onChange={ this.onChange }
                  disabled={ disabled }
                  step={ step || 1 }
                  min={ min || 0 }
                  max={ max || 100 }/>;
  }

}
