import * as lodash from 'lodash';
import { property } from '../../base/decorators';
import { Model } from '../../base';
import { default as Backbone, Collection } from 'backbone';

import {
  IGroupCondition,
  ISingleConditionItem,
  SingleConditionItem,
  IConditionOperatorType,
  Condition } from './index';

export class GroupCondition extends Model<any, any> implements IGroupCondition {

  @property() type: 'group' = 'group';
  @property() operator: IConditionOperatorType;
  //@ts-ignore
  @property() items: Collection<SingleConditionItem|GroupCondition> = new Collection<SingleConditionItem|GroupCondition>();

  public constructor(private condition: Condition, groupCondition: IGroupCondition) {
    super();
    this.listenTo(this, 'change destroy', () => condition.trigger('change', condition, {}));
    this.listenTo(this.items, 'change update add remove', () => condition.trigger('change', condition, {}));
    this.fromGroup(groupCondition);
  }

  public destroy(options: Backbone.ModelDestroyOptions) {
    this.trigger('destroy', this.items , options);
    this.trigger('destroy', this.condition.condition , options);
    this.condition.condition.remove(this);
    return super.destroy(options);
  }

  /**
   * Add group
   */
  public addSubGroup(groupCondition?: IGroupCondition) {
    this.items.add(new GroupCondition(this.condition, groupCondition));
  }

  /**
   * Add item
   */
  public addItem(condition?: ISingleConditionItem) {
    this.items.add(new SingleConditionItem(this.condition, condition));
  }

  public fromGroup(groupCondition: IGroupCondition) {
    if (!groupCondition) return;

    this.operator = groupCondition.operator;

    lodash.each(groupCondition.items, c => {
      //@ts-ignore
      switch (c.type) {
        case 'single':
          //@ts-ignore
          return this.addItem(c);
        case 'group':
          //@ts-ignore
          return this.addSubGroup(c);
      }
    })
  }

}
