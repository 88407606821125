export class ImageHelper {
  private constructor() {}

  /**
   * @param src {string} b64 64 | path
   */
  public static loadImage(src: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;

      img.addEventListener('load', () => resolve(img));
      img.addEventListener('error', () => reject());
    })
  }
}