import { API, UseCase } from '@ppg/common';


export interface IOrganizationAutomationsCampaignsItem {
  automation: string;
  name: string;
  campaigns: string[];
  project: string,
}

export interface IGetAutomationsCampaignsRequest {
  organization: string
}

interface IGetAutomationsCampaignsUseCaseResult {
  organizationAutomationsCampaigns: IOrganizationAutomationsCampaignsItem[]
}

export class GetAutomationsCampaignsUseCase extends UseCase<IGetAutomationsCampaignsRequest, IGetAutomationsCampaignsUseCaseResult> {
  protected async execute({ organization }: IGetAutomationsCampaignsRequest) {
    return API.HTTP.get<IGetAutomationsCampaignsUseCaseResult>(`statistics/organizations/${ organization }/automations/campaigns`);
  }
}