import * as React from 'react';
import { AbstractFiled } from './AbstractFiled';
import { ISwitchProps } from './ISwitchProps';

export class Switch extends AbstractFiled<ISwitchProps, {}> {

  private convertToBoolean(value):boolean {
    return typeof value === 'boolean' ? value : value == 'true';
  }

  private onChangeHandler() {
    const { attribute, onChange } = this.props;
    attribute.setValue(!this.convertToBoolean(attribute.getValue()))
    if (onChange) {
      onChange();
    }
  }

  public render() {
    const { id, name, className, attribute } = this.props;
    const isChecked = this.convertToBoolean(attribute.getValue());

    return (
      <div className={`switch m20b ${className}`} key={ name } id={ id } onClick={() => this.onChangeHandler()}>
        <input type='radio'
               className={ isChecked ? 'on' : 'off'}
               name={ name }
               value={isChecked.toString()}
        />
        <div className='switch-dot'/>
        <span className='switch-highlight'/>
      </div>
    );

  }
}
