import { API, UseCase } from '@ppg/common';

interface IRequestToAddCard {
  organization: string;
}

interface IRequestToAddCardResult {
  secretKey: string;
}

export class RequestToAddCardUseCase extends UseCase<IRequestToAddCard, IRequestToAddCardResult> {
  protected async execute({ organization }) {
    return API.HTTP.post<IRequestToAddCardResult>(`acc/organizations/${ organization }/payments/cards`)
  }
}

export const requestToAddCardUseCase = new RequestToAddCardUseCase();
