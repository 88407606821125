import { action, computed, observable } from 'mobx';
import { DEFAULT_LOCALIZATION_MAP_PROPERTIES } from '../../pages/Dashboard/common';
import { ILocalizationMapItem } from '../../pages/Dashboard/common/LocalizationMapCard/LocalizationMapCard';
import { ISubscriberLocalization } from '../../useCases/interfaces';

const { marker } = DEFAULT_LOCALIZATION_MAP_PROPERTIES;
const { color, bucket, line, minSize } = marker;

export class SubscriberLocalizationBuckets {
  @observable private xs: SubscriberLocalizationBucket = new SubscriberLocalizationBucket(color.xs, color.s, bucket.xs)
  @observable private s: SubscriberLocalizationBucket = new SubscriberLocalizationBucket(color.s, color.m, bucket.s)
  @observable private m: SubscriberLocalizationBucket = new SubscriberLocalizationBucket(color.m, color.l, bucket.m)
  @observable private l: SubscriberLocalizationBucket = new SubscriberLocalizationBucket(color.l, color.xl, bucket.l)
  @observable private xl: SubscriberLocalizationBucket = new SubscriberLocalizationBucket(color.xl, color.xl_line, bucket.xl)
  @observable private xxl: SubscriberLocalizationBucket = new SubscriberLocalizationBucket(color.xxl, color.xxl_line, bucket.xxl)
  @observable public isLoading: boolean = false;

  private EMPTY_DATA = [{
    type: DEFAULT_LOCALIZATION_MAP_PROPERTIES.type,
    hoverinfo: 'none',
    lat: [],
    lon: [],
    name: ``,
    marker: {
      color: DEFAULT_LOCALIZATION_MAP_PROPERTIES.marker.color.s,
      size: [],
      line: {
        color: DEFAULT_LOCALIZATION_MAP_PROPERTIES.marker.color.s,
        width: line.width,
      },
    }
  }]

  @action
  public setFromLocalization(localization: ISubscriberLocalization[]): void {
    this.isLoading = true;

    let localizationMap = new Map<SubscriberLocalizationBucket, ISubscriberLocalization[]>();

    localization.map(l => {
      let round = Math.round(l.count);
      if (round < 1) return;
      const bucket = this.buckets.find(b => l.count < b.limit);
      localizationMap.set(bucket, (localizationMap.get(bucket) || []).concat(l));
    })

    for (let [key, val] of localizationMap) {
      this.setToBucket(val, key);
    }

    this.isLoading = false;
  }

  @action
  private setToBucket(points: ISubscriberLocalization[], bucket: SubscriberLocalizationBucket): void {
    let lat: string[] = new Array(points.length);
    let long: string[] = new Array(points.length);
    let sizes: number[] = new Array(points.length);

    points.map((point, idx) => {
      let sizeValue = Math.log(point.count) * 3;

      lat[idx] = point.latitude.toString();
      long[idx] = point.longitude.toString();
      sizes[idx] = sizeValue < 1 ? minSize : sizeValue;
    });

    bucket.setValues(lat, long, sizes);
  }

  get buckets(): SubscriberLocalizationBucket[] {
    return [this.xs, this.s, this.m, this.l, this.xl, this.xxl];
  }

  @computed
  public get mapData(): ILocalizationMapItem[] {
    let minVal = 1;
    let result: ILocalizationMapItem[] = new Array(this.buckets.length);

    if (this.buckets.every(b => !b.hasValues)) {
      return this.EMPTY_DATA;
    }

    this.buckets.forEach(function mapToDisplayableData(bucket, idx) {
      if (bucket.hasValues) {
        result[idx] = {
          type: DEFAULT_LOCALIZATION_MAP_PROPERTIES.type,
          hoverinfo: 'none',
          lat: bucket.lat,
          lon: bucket.long,
          name: `${ minVal.toLocaleString() } - ${ (bucket.limit - 1).toLocaleString() }`,
          marker: {
            color: bucket.color,
            size: bucket.sizes,
            line: {
              color: bucket.line,
              width: line.width,
            },
          }
        };
      }
      minVal = bucket.limit;
    })

    return result.filter(r => !!r);
  }
}

class SubscriberLocalizationBucket {
  @observable public lat: string[] = [];
  @observable public long: string[] = [];
  @observable public sizes: number[] = [];

  constructor(
    public color: string,
    public line: string,
    public limit: number,
  ) {
  }

  @action
  public setValues(lat: string[], long: string[], sizes: number[]) {
    this.lat = lat;
    this.long = long;
    this.sizes = sizes;
  }

  @computed
  public get hasValues(): boolean {
    return this.sizes.length !== 0;
  }
}
