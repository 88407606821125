import { API, UseCase } from '@ppg/common';

interface ICreateCampaignActivityExport {
  project: string;
  from: string;
  to: string;
}

interface ICreateCampaignActivityExportResponse {
  id: string;
  type: string;
  state: string;
  createdAt: string;
  from: string;
  to: string;
  rangeReference: string;
}

export class CreateCampaignActivityExportUseCase extends UseCase<ICreateCampaignActivityExport, ICreateCampaignActivityExportResponse> {
  protected async execute({ project, from, to }): Promise<ICreateCampaignActivityExportResponse> {
    return API.HTTP.post<ICreateCampaignActivityExportResponse>(`statistics/projects/${ project }/campaign-days/export`, {
      from, to
    });
  }
}

