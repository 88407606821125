import { API, UseCase } from "@ppg/common";
import { ICampaignId, ICampaignIdResponse } from '../interfaces';

interface ICopyGeolocationPushRequest extends ICampaignId {}
interface ICopyGeolocationPushResponse extends ICampaignIdResponse {}

export class CopyGeolocationPushUseCase extends UseCase<ICopyGeolocationPushRequest, ICopyGeolocationPushResponse> {
  protected async execute({ campaignId }: ICopyGeolocationPushRequest) {
    return API.HTTP.post<ICopyGeolocationPushResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/geolocation/${ campaignId }/copy`);
  }
}

export const copyGeolocationPushUseCase = new CopyGeolocationPushUseCase();
