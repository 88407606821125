import { LegendAnchor, LegendDirection, LegendSymbolShape } from '@nivo/legends';
import { fixedPercentValue } from '@ppg/common';

export const labelValue = (d): string => d.value;
export const labelFormat = (d): string => d.id + ' (' + d.value + ')';
export const percentLabelValue = (d): string => `${ fixedPercentValue(d.value) }`;
export const percentLabelFormat = (d): string => d.id + '(' + fixedPercentValue(d.value) + ')';

const DEFAULT_ANCHOR: LegendAnchor = 'bottom-left';
const DEFAULT_DIRECTION: LegendDirection = 'column';
const DEFAULT_SYMBOL_SHAPE: LegendSymbolShape = 'circle';

export const DEFAULT_LEGENDS_PROPERTIES = {
  anchor: DEFAULT_ANCHOR,
  direction: DEFAULT_DIRECTION,
  symbolShape: DEFAULT_SYMBOL_SHAPE,
  translateY: 80,
  translateX: 0,
  itemsSpacing: 2,
  itemWidth: 50,
  itemHeight: 15,
  symbolSize: 12,
};

export const DEFAULT_PIE_CHART_PROPERTIES = {
  colors: ['#56cbb4', '#f1e15b', '#67d8e7', '#e8a838', '#f47560', '#97e3d5', '#e8c1a0'],
  innerRadius: 0.6,
  padAngle: 2,
  cornerRadius: 3,
  borderWidth: 1,
  borderColor: 'inherit:darker(0.2)',
  activeOuterRadiusOffset: 2.5,
  activeInnerRadiusOffset: 2.5,
  arcLinkLabel: labelFormat,
  arcLinkLabelValue: labelValue,
  arcLinkPercentLabel: percentLabelFormat,
  arcLinkPercentValue: percentLabelValue,
  arcLinkLabelsSkipAngle: 15,
  arcLinkLabelsTextColor: '#333333',
  arcLinkLabelsDiagonalLength: 16,
  arcLinkLabelsStraightLength: 16,
  arcLinkLabelsThickness: 1,
  arcLinkLabelsColor: { from: 'color', modifiers: [] },
  enableSlicesLabels: false,
  slicesLabelsSkipAngle: 10,
  slicesLabelsTextColor: "#333333",
  motionStiffness: 90,
  motionDamping: 15,
  defs: [{ "id": "dots" }, { "id": "lines" }],
  margin: { "top": 30, "bottom": 100, "right": 30, "left": 30 },
};

