import { API, UseCase } from '@ppg/common';
import { SelectorDTO } from '../../../modelsMobx/selector/SelectorDTO';
import { ICreateSelector } from './interfaces';
import { Selector } from '../../../modelsMobx/selector/Selector';

export class CreateSelectorUseCase extends UseCase<ICreateSelector, Selector> {
  protected async execute({ project, event, selector, labelKey, labelStrategy, labelTTL, attribute, name, prettyName, settingsType, tagLabel, group, valueType, reducer, bindAll } : SelectorDTO) {
    return API.HTTP.post<Selector>(`core/projects/${ project }/selectors`, {
      event,
      selector,
      attribute,
      name,
      prettyName,
      settingsType,
      tagLabel,
      group,
      valueType,
      reducer,
      bindAll,
      labelKey,
      labelStrategy,
      labelTTL,
    })
  }
}

export const createSelectorUseCase = new CreateSelectorUseCase();
