export interface ITextColors {
  text100: string,
  text200: string,
  text300: string,
  text400: string,
  text500: string,
  text600: string,
  text700: string,
  text800: string,
  text900: string,
}

export const text: ITextColors = {
  text100: '#e0dfdf',
  text200: '#d0d0d0',
  text300: '#afafaf',
  text400: '#969595',
  text500: '#7a7a7a',
  text600: '#626161',
  text700: '#585858',
  text800: '#4a4a4a',
  text900: '#3b3a3a',
};
