import { API, UseCase } from '@ppg/common';

interface IConfirmPayment {
  organization: string;
  transaction: string;
  invoices: string[];
}

export class ConfirmPaymentUseCase extends UseCase<IConfirmPayment, void> {
  protected async execute({ organization, transaction, invoices }) {
    await API.HTTP.post(`acc/organizations/${ organization }/payments/cards/pay/confirm`, { transaction, invoices });
  }
}

export const confirmPaymentUseCase = new ConfirmPaymentUseCase();
