import { API, UseCase } from '@ppg/common';
import { getListQueryParam, getPlatformTypeParam, getRangeDateParam } from '../../../helpers';
import { IOrganizationDashboardRequestBody } from '../../../interfaces';
import { IListQueryOptions } from '../../../../interfaces/IListQueryOptions';

interface IGetCampaignsStatisticsHistogramRequest extends IOrganizationDashboardRequestBody, IListQueryOptions {
  timezone: string;
  campaignType?: string;
}

export interface IStatisticsHistogramItem {
  day: string;
  clicked: number;
  delivered: number;
  sent: number;
  ctr: number;
  dsp: number;
  campaignCount: number;
}

interface IGetCampaignsStatisticsHistogramResult {
  dashboardCampaignStatisticsHistogram: {
    total: number,
    histogram: IStatisticsHistogramItem[]
  };
}

export class GetCampaignsStatisticsHistogramUseCase extends UseCase<IGetCampaignsStatisticsHistogramRequest, IGetCampaignsStatisticsHistogramResult> {
  protected async execute({
                            organization,
                            platform,
                            from,
                            to,
                            limit,
                            offset,
                            timezone,
                            campaignType = 'all',
                          }: IGetCampaignsStatisticsHistogramRequest) {

    return API.HTTP.get<IGetCampaignsStatisticsHistogramResult>(`statistics/organizations/${ organization }/v2/campaigns/statistics/${ campaignType }/histogram${ getPlatformTypeParam(platform) }${ getRangeDateParam(from, to) }${ getListQueryParam(offset, limit) }&timezone=${ timezone }`)
  };
}
