import * as React from 'react';
import { observer } from 'mobx-react';
import { CreatePushStore } from '../../../../../stores/project/CreatePushStore';
import { t } from '../../../../../base/helpers';
import { useState } from 'react';
import { ProjectsList } from './ProjectsList';
import { SimpleProject } from '../../../../../modelsMobx/project/SimpleProject';
import { SearchInput } from '@ppg/styled';
import * as Styled from './CampaignCopyToProjectsModal.styled';

export enum TypeOfProjects {
  OWNED = 'owned',
  SHARED = 'shared'
}

interface ICampaignCopyToProjectsModal {
  createPushStore: CreatePushStore;
}

export const CampaignCopyToProjectsModal = observer(({ createPushStore }: ICampaignCopyToProjectsModal): JSX.Element => {
  const { projectsToCopyCampaign, hasSelectedProjects } = createPushStore;

  const [searchProject, setSearchProject] = useState<string>("");
  const [typeOfProjects, setTypeOfProjects] = useState<TypeOfProjects>(TypeOfProjects.OWNED);

  const ownedProjects: SimpleProject[] = createPushStore.getUserProjects(searchProject, TypeOfProjects.OWNED);
  const sharedProjects: SimpleProject[] = createPushStore.getUserProjects(searchProject, TypeOfProjects.SHARED);

  const projectsList: SimpleProject[] = typeOfProjects === TypeOfProjects.OWNED ? ownedProjects : sharedProjects;

  const hasProjects: boolean = projectsList.length !== 0;

  const emptyProjects: string =
    typeOfProjects === TypeOfProjects.OWNED ?
      "You don't have any other owned projects than current" :
      "You don't have any shared projects";

  return <Styled.CampaignCopyBox>
    <Styled.CampaignCopyTitle>
      { t('campaign/list/pushes/all::Choose projects to which you want to copy the campaign') }
    </Styled.CampaignCopyTitle>

    <Styled.SearchProjectBox>
      <SearchInput
        value={ searchProject }
        handleSearch={ (e) => setSearchProject(e.currentTarget.value) }
        placeholder={ t('campaign/list/pushes/all::Search project by it`s name') }/>
    </Styled.SearchProjectBox>

    <Styled.BtnTypeProjectsBox>
      <Styled.BtnOwnedProjects
        isActive={ typeOfProjects === TypeOfProjects.OWNED }
        onClick={ () => setTypeOfProjects(TypeOfProjects.OWNED) }>
        { t('campaign/list/pushes/all::Your projects') }
      </Styled.BtnOwnedProjects>

      <Styled.BtnSharedProjects
        isActive={ typeOfProjects === TypeOfProjects.SHARED }
        onClick={ () => setTypeOfProjects(TypeOfProjects.SHARED) }>
        { t('campaign/list/pushes/all::Projects shared to you') }
      </Styled.BtnSharedProjects>
    </Styled.BtnTypeProjectsBox>

    { hasProjects ? <>
      <ProjectsList
        typeOfProjects={ typeOfProjects }
        projectsList={ projectsList }
        createPushStore={ createPushStore }/>

      <Styled.SelectedProjectsBox>
        <Styled.SelectedProjectsTitle>{ t('campaign/list/pushes/all::Selected projects') }</Styled.SelectedProjectsTitle>

        { !hasSelectedProjects ?
          <p>{ t('campaign/list/pushes/all::No selected any project') }</p> :
          <Styled.SelectedProjects>{ projectsToCopyCampaign.map(project =>
            <Styled.SelectedProject key={ project.id }>{ project.name }</Styled.SelectedProject>) }
          </Styled.SelectedProjects>
        }

      </Styled.SelectedProjectsBox>
    </> : <Styled.EmptyProjectsTitle>{ emptyProjects }</Styled.EmptyProjectsTitle> }
  </Styled.CampaignCopyBox>;
});