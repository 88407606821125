import { API, UseCase } from '@ppg/common';

interface IRemovePolygon {
  project: string;
  polygon: string
}

export class RemovePolygonUseCase extends UseCase<IRemovePolygon, void> {
  protected async execute({ project, polygon }) {
    return API.HTTP.delete<void>(`ah/projects/${ project }/polygons/${ polygon }`);
  }
}

export const removePolygonUseCase = new RemovePolygonUseCase();
