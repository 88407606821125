import { API, UseCase } from "@ppg/common";

interface FetchFeatureSwitchPayload {
  projectId: string;
}

export class FetchFeatureSwitchUseCase extends UseCase<FetchFeatureSwitchPayload, string[]> {
  protected async execute({ projectId }: FetchFeatureSwitchPayload) {
    return API.HTTP.get<string[]>(`core/projects/${ projectId }/feature-switch`);
  }
}

export const fetchFeatureSwitchUseCase = new FetchFeatureSwitchUseCase();
