import * as React from 'react';
import './UserNavigationBar.scss';
import { t } from '../../../../base/helpers';
import { UserNavigationItem } from './UserNavigationItem';
import { observer } from 'mobx-react';
import { ProjectRoutesTypes } from '../../../../routes/moduleProjectRoutes';
import { IconAsLink } from '@ppg/styled';
import InfoIcon from "../../../../assets/icons/info.svg";
import ReactTooltip from 'react-tooltip';

export enum IUserNavigationTabType {
  UNDERLINED = 'underlined'
}

interface UserNavigationBarProps {
  navigationTabs: IUserNavigationTab[];
  navigationType?: IUserNavigationTabType;
  hideUserGuide?: boolean;
  userGuideLink?: string;
  newUserGuide?: boolean;
  userGuideTip?: string;
}

export interface IUserNavigationTab {
  icon?: string,
  title: string,
  linkTo: string,
}

@observer
export class UserNavigationBar extends React.Component<UserNavigationBarProps, {}> {
  private tabScrollArea;

  constructor(props) {
    super(props);
    this.tabScrollArea = (React as any).createRef();
  }

  public componentDidMount(): void {
    if (window.location.pathname.includes(ProjectRoutesTypes.ORGANIZATION_DASHBOARD_AUTOMATIONS)) {
      this.tabScrollArea.current.scrollLeft = this.tabScrollArea.current.scrollWidth - this.tabScrollArea.current.clientWidth;
    }
  }

  public render() {

    const { navigationTabs, hideUserGuide, navigationType, userGuideLink, newUserGuide, userGuideTip} = this.props;

    const showOldUserGuide: boolean = !hideUserGuide && !newUserGuide;
    const showNewUserGuide: boolean = !hideUserGuide && newUserGuide;

    return (
      <nav className={ `account-navigation${ navigationType ? `-${ navigationType }` : '' }` }>
        <div className={`account-navigation-wrapper ${newUserGuide ? 'new-user-guide' : ''}`}>
          <div className={ 'account-main-navigation-tabs' } ref={ this.tabScrollArea }>
            { navigationTabs.map((tab, idx) => {
              return <UserNavigationItem showTitleOnMobile={ navigationType === IUserNavigationTabType.UNDERLINED }
                                         key={ idx }
                                         item={ tab }
                                         isFirst={ idx === 0 }/>;
            })
            }
          </div>
          { showOldUserGuide && <a className="user-guide" href={ userGuideLink || 'https://pushpushgo.com/en/user-guide/start' } target={ '_blank' }>
            <span className="account-navigation-item-title" data-label={ t('User guide') }/>
            <span className={ `icon-light-bulb` }/>
          </a> }
          { showNewUserGuide && <>
            <IconAsLink link={ userGuideLink } dataTip={ userGuideTip } icon={ InfoIcon }/>
            <ReactTooltip type="light" effect="solid" place="top"/>
          </> }

        </div>
        { navigationType !== IUserNavigationTabType.UNDERLINED && <div className="account-navigation-border"/> }
      </nav>
    );
  }
}
