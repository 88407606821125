import classNames from 'classnames';
import * as React from 'react';
import { UserStore } from '../../../../stores/user/UserStore';
import { inject, observer } from 'mobx-react';
import { connector } from '../../../../base/index';
import { WebUser } from '../../../../models/index';
import { t } from '../../../../base/helpers';

interface IPaginationProps {
  userStore?: UserStore;
}

// @todo refactor
@inject('userStore')
@observer
class Pagination extends React.Component<IPaginationProps> {

  private handleClick() {
    const user = connector.get<WebUser>('user');
    user.infinity = !user.infinity;
    this.forceUpdate();

    this.props.userStore.settings.infinity = user.infinity;
  }

  private paginationButton(title: string, isActive: boolean) {

    const className = classNames('m5r', 'button', {
      'button-primary': isActive,
      'button-outline': !isActive
    });

    return <button
      onClick={ () => this.handleClick() }
      disabled={ isActive }
      className={ className }>{ title }</button>;
  }

  public render() {
    const user = connector.get<WebUser>('user');

    return (
      <div className="m20t">
        <h4 className="form-title">{ t('Pagination') }</h4>
         <div className={'pagination-options-wrapper'}>
              <label>{ t('Customize your pagination settings') }</label>
              { this.paginationButton(t('Default Pagination'), !user.infinity) }
              { this.paginationButton(t('Infinity Scroll'), user.infinity) }
         </div>
      </div>
    );
  }
}

export function SettingsApplication() {
  return (
    <>
      <Pagination/>
    </>
  );
}
