import { UseCase, API } from '@ppg/common';
import { ICheckResult, IGetPlugin } from '../interfaces';

export class CheckIntegrationValidationUseCase extends UseCase<IGetPlugin, ICheckResult> {
  protected async execute({ websiteIntegrationId }: IGetPlugin) {
    return API.HTTP.post<ICheckResult>(`wi/projects/${ API.HTTP.currentProjectId }/website-integrations/${ websiteIntegrationId }/integration-validators/check`)
  }
}

export const checkIntegrationValidationUseCase = new CheckIntegrationValidationUseCase();
