import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { UserStore } from '../../../../stores/user/UserStore';
import { Input, toast } from '@ppg/styled';
import { t } from '../../../../base/helpers';
import { SaveButtonWrapped } from '../../../../assets/wrapped';

interface IBasicProfileSettings {
  userStore?: UserStore;
}

@inject('userStore')
@observer
export class SettingsContactInfo extends React.Component<IBasicProfileSettings> {

  private async handleChangeContactInfoUseCaseClick() {
    await this.props.userStore.settings.saveSettings();
    this.props.userStore.user.validate();

    if (!this.props.userStore.user.isValid()) {
      return toast.error(t('Fields cannot be blank'));
    }

    await this.props.userStore.settings.saveSettings().then(() => toast.success(t('Changes are saved')));
  }

  public render() {
    const { userStore } = this.props;

    return (
      <form className="contact-info-wrapper" autoComplete="off">
        <div className="avatar-wrapper">
          <div className="avatar m10t"/>
        </div>

        <div className="contact-info-container">
          <div className='contact-user m5t m10b'>
            <span className='contact-info-username-label'>{ t('Username') }</span>
            <div className="contact-info-username"> { userStore.user.username } </div>
          </div>
          <div className="contact-inputs-container">
            <div className="contact-input-wrapper">
              <Input property={ userStore.user.getProperty('firstName') } label={ t('First Name') + ' *' }/>
            </div>
            <div className="contact-input-wrapper">
              <Input property={ userStore.user.getProperty('lastName') } label={ t('Last Name') + ' *' }/>
            </div>
            <div className="contact-input-wrapper">
              <Input
                property={ userStore.user.getProperty('phone') }
                label={ t('Phone number') }
                placeholder="+11 234 567 890"
              />
            </div>
          </div>
          <div className="contact-info-button-wrapper">
            <SaveButtonWrapped preventToast callback={ () => this.handleChangeContactInfoUseCaseClick() }/>
          </div>
        </div>
      </form>
    );
  }
}
