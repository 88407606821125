import * as React from 'react';
import { Modal, SectionHeader, IHeaderButton } from '@ppg/styled';
import { t } from "../../../base/helpers";
import { WebhooksTable } from "./WebhooksTable";
import { inject, observer } from "mobx-react";
import { WebhookEditView } from "./WebhookEditView";
import { WebhookStore } from '../../../stores/project/WebhooksStore';
import './WebhooksView.scss'
import { UserStore } from '../../../stores/user/UserStore';
import { ProjectEmptyData } from '../../../components/commonMobX';

interface IWebhooksProps {
  webhookStore: WebhookStore;
  userStore: UserStore;
}

@ProjectEmptyData.wrap()
@inject('webhookStore', 'userStore')
@observer
export class WebhooksView extends React.Component<IWebhooksProps, any> {

  componentWillMount() {
    this.props.webhookStore.setInfinity(this.props.userStore.settings.infinity)
  }

  public componentWillUnmount() {
    this.props.webhookStore.setDefaultValues()
  }

  public render() {

    const { webhookStore } = this.props;

    const newButton: IHeaderButton = {
      onClick: () => webhookStore.onNewWebhook(),
      content: t('New webhook'),
      icon: 'plus-badge',
      size: 'medium',
      id: 't-webhooks-newWebhookBtn',
    };

    return <section>
      <SectionHeader title={ t('Webhooks') }
                     subtitle={ t('Add webhooks to get events from PushPushGo') }
                     buttons={ [newButton] }/>

      <WebhooksTable key={ `${ new Date() }` } webhookStore={ webhookStore }/>

      <Modal show={ webhookStore.webhookToEdit !== null }
             title={ <h1>{ webhookStore.isNewWebhook ? t('Create new webhook') : t('Edit webhook') }</h1> }
             content={ <WebhookEditView/> }
             onCancel={ () => webhookStore.onCancel() }
             onConfirm={ () => webhookStore.onSave() }
             confirmButton={ t('Save') }
             cancelButton={ t('Cancel') }
             width={ 400 }
             contentHeight={ 450 }
             disabled={ webhookStore.disableSave }
      />

    </section>;
  }
}
