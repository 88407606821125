import { ProjectRelated } from '../ProjectRelatedStore';
import { action, computed, observable } from "mobx";
import { formatLabelUseCase, getAutomationAvailableLabels } from "../../useCases/automation";
import { AutomationLabel } from "../../components/Automation/TagsForm/AutomationLabel";
import { CustomField } from "../../modelsMobx/CustomField";
import { getLabelsKeysUseCase } from "../../useCases/core";
import { DynamicField, DynamicFieldTypeTypes } from "../../modelsMobx/helpers/DynamicField";

/**
 * TODO: cfLabels and labels will "joined" after third stage of segmentation task (shared repository)
 */
export class AutomationProjectDataStore extends ProjectRelated {

  @observable public isLoading: boolean = true;
  @observable private wasFetched: boolean = false;
  @observable private userLabels: Set<string>;
  @observable private labelsKeys: string[] = [];
  @observable private cfUserLabels: AutomationLabel[];

  @action
  public toAutomationScope(): Record<string, any>[] {
    return this.cfUserLabels.map(field => ({
      [field.key]: DynamicField.getSampleValueByType(field.type as DynamicFieldTypeTypes)
    }));
  }

  @action
  public toCampaignVariableScope(): Record<string, any> {
    return Object.assign.apply({}, this.toAutomationScope());
  }

  @computed
  public get serverLabelKeys(): string[] {
    return this.labelsKeys;
  }

  @action
  async fetchAutomationUsedData(): Promise<void> {
    if (this.wasFetched) {
      return;
    }

    this.isLoading = true;

    const { cfLabels, labels } = await getAutomationAvailableLabels.exec({
      project: this.projectId.toString()
    });

    this.labelsKeys = await getLabelsKeysUseCase.exec();

    for (let cfLabel of cfLabels) {
      this.appendUserCfLabel(AutomationLabel.createLabel(cfLabel));
    }

    for (let label of labels) {
      this.appendUserLabel(label.key, label.value);
    }

    this.wasFetched = true;
    this.isLoading = false;
  }

  @computed
  public get labelsKeysWithUserOnes(): string[] {
    const userLabels = this.fetchUserLabels().map(item => {
      const [labelKey] = item.split(":");
      return labelKey;
    });

    const cfUserLabels = this.fetchUserCfLabels().map(item => item.name);

    const allUniqueLabels = new Set(
      userLabels.concat(cfUserLabels, this.labelsKeys)
    );

    return [...allUniqueLabels];
  }

  public get labelsKeysWithoutSys(): string[] {
    const keys = this.labelsKeysWithUserOnes;
    return keys.filter(key => !key.includes("sys."));
  }

  @action
  fetchUserCfLabels(): CustomField[] {
    return [...this.cfUserLabels].map(item => new CustomField({
      name: item.key,
      prettyName: item.key,
      valueType: item.type
    }));
  }

  @action
  public appendUserCfLabel(label: AutomationLabel): void {
    if (!this.cfUserLabels.some(current => current.key === label.key)) {
      if (!label.getType()) {
        label.setType(CustomField.guessType(label.value));
      }
      this.cfUserLabels.push(label);
    }
  }

  @action
  public deleteUserCfLabel(label: AutomationLabel): void {
    this.cfUserLabels = this.cfUserLabels.filter(cfUserLabel => cfUserLabel.serialize() !== label.serialize());
  }

  async formatLabelKey(key: string): Promise<string> {
    const response = await formatLabelUseCase.exec({ key });

    return response.result;
  }

  async formatLabelValue(value: string): Promise<string> {
    const response = await formatLabelUseCase.exec({ value });

    return response.result;
  }

  @action
  public appendUserLabel(key: string, value: string): void {
    this.userLabels.add(AutomationLabel.createLabel({ key, value }).serialize());
  }

  @action
  public addUserLabel(label: AutomationLabel): void {
    this.userLabels.add(label.serialize());
  }

  @action
  public deleteUserLabel(label: AutomationLabel): void {
    this.userLabels.delete(label.serialize());
  }

  @action
  public fetchUserLabels(): string[] {
    return [...this.userLabels];
  }

  public setDefaultValues() {
    this.userLabels = new Set<string>();
    this.cfUserLabels = [];
    this.wasFetched = false;
  }
}
