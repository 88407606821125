import { property, PropertyHandler } from '@ppg/common';
import { observable } from 'mobx';
import { Invoice } from './Invoice';
import { PaymentsType } from '../stores/payment/PaymentsStore';

export class Transaction extends PropertyHandler {
  @property() public saveNewCard: boolean = false;
  @property() public acceptTerms: boolean = false;

  @observable public paymentType: PaymentsType = null;
  @observable public invoices: Invoice[] = [];
  @observable public invoicesId: string[] = [];
  @observable public currency: string = '';
  @observable public amount: number = 0;
  @observable public secret: string = '';
  @observable public card: any = null;

  public reset() {
    this.paymentType = null;
    this.saveNewCard = false;
    this.acceptTerms = false;
    this.invoices = [];
    this.currency = '';
    this.invoicesId = [];
    this.card = null;
  }
}
