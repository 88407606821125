import { API, UseCase } from "@ppg/common";
import { ILabelDeleteDTO } from "../../../modelsMobx/subscriber/Subscriber";

interface IDeleteSubscriberLabelsRequest {
  subscriberId: string;
  labels: ILabelDeleteDTO[];
}

export class DeleteSubscriberLabelsUseCase extends UseCase<IDeleteSubscriberLabelsRequest, void> {
  protected async execute({subscriberId, labels}: IDeleteSubscriberLabelsRequest) {
    return API.HTTP.delete<void>(`core/projects/${ API.HTTP.currentProjectId }/segmentation/subscribers/${subscriberId}`, {labels});
  }
}

export const deleteSubscriberLabelsUseCase = new DeleteSubscriberLabelsUseCase();
