import * as React from 'react';
import '../../User/Account/Settings/Settings.scss'
import PPGLogo from '../../../assets/images/ppg-logo.svg';
import { redirect, t } from '../../../base/helpers';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { PaymentsRoutesTypes } from '../../../routes/paymentsRoutes';

@observer
export class PaymentsSuccess extends React.Component<{}, {}> {
  @observable isLoading: boolean = false;

  componentDidMount() {
    setTimeout(() => redirect(PaymentsRoutesTypes.INVOICES_LIST), 3500);
  }

  public render() {
    if (this.isLoading) {
      return null;
    }

    return <div className='success-request-wrapper'>
      <div className='success-request-background'>
        <div className="success-request-container">
          <img src={ PPGLogo } alt="PushPushGo" className="ppg-logo"/>
          <div className="success-request-pannel">
            <p className="success-feedback-message">
              <span className='feedback-title'>{ t('Your payment was successful!') }</span>
              <span className='feedback-desc'>{ t('You will return to the application in a moment.') }</span>
            </p>
            <svg className="confirmation-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle className="confirmation-icon__circle" cx="26" cy="26" r="25" fill="none"/>
              <path className="confirmation-icon__tick" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
            </svg>
          </div>
        </div>
      </div>
    </div>;
  }
}
