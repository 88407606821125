import * as React from "react";
import { Button, ButtonTypes } from '@ppg/styled';
import { t } from '../../../base/helpers';
import { Nunjucks, TestPush } from '../../../services';

interface ITestPushButton {
  campaign: ITestPush;
  scope?: any;
  isDirectionRtl?: boolean;
  buttonType?: ButtonTypes;
  contentIsIcon: boolean;
}

export interface ITestPush {
  title: string,
  redirectLink: string,
  content: string,
  requireInteraction: boolean,
  icon: string,
  image: string
}

export const TestSendPushButton = ({ isDirectionRtl, contentIsIcon, scope = {}, campaign, buttonType = ButtonTypes.DEFAULT }: ITestPushButton): JSX.Element => {

  const renderProp = (value: string): string => {
    let val;

    try {
      val = Nunjucks.render(campaign[value], scope);
    } catch (e) {
      val = e.message;
    }

    return val;
  };

  const sendTestPush = () => {
    const testPush = new TestPush();

    const options: NotificationOptions = {
      requireInteraction: campaign.requireInteraction,
      body: renderProp('content'),
      icon: campaign.icon,
      dir: isDirectionRtl ? 'rtl' : 'ltr' as NotificationDirection,
      image: campaign.image,
    };

    testPush.send(renderProp('title'), renderProp('redirectLink'), options);
  };

  return <Button content={ !contentIsIcon && t('Send to yourself') }
                 size={ 'medium' }
                 type={ buttonType }
                 isSquare={ contentIsIcon }
                 icon={ contentIsIcon && "send" }
                 onClick={ sendTestPush }/>;
};