import { API, UseCase } from '@ppg/common';

interface IDeleteAutomation {
  projectId: string;
  automationId: string;
}

export class DeleteAutomationUseCase extends UseCase<IDeleteAutomation, void> {
  protected async execute({ projectId, automationId }: IDeleteAutomation) {
    await API.HTTP.delete<void>(`project/${ projectId }/automation/${ automationId }`);
  }
}

export const deleteAutomationUseCase = new DeleteAutomationUseCase();
