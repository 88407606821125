import { ProjectRelated } from '../ProjectRelatedStore';
import { property, debounce } from '@ppg/common';
import { ISelectOption, toast } from '@ppg/styled';
import { AutomationScenario } from '../../modelsMobx/AutomationScenario';
import { action, computed, observable, reaction } from 'mobx';
import { getAutomationListUseCase, deleteAutomationUseCase } from '../../useCases/core';
import { t } from '../../base/helpers';

enum filterTypes {
  ALL = '',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  TEST_MODE = 'testMode'
}

export class AutomationListStore extends ProjectRelated {
  @property() public filter: string = "";

  @property() public enabled: boolean = true;
  @property() public disabled: boolean = true;
  @property() public draft: boolean = true;
  @property() public tagScenario: boolean = true;
  @property() public testMode: boolean = true;

  @property() public searchQuery: string = '';

  @property() automations: AutomationScenario[] = [];

  @observable isLoading: boolean = true;
  @observable priorityView: boolean = false;

  private reactOnSearchQueryChange = reaction(() => this.searchQuery,
    () => this.debouncedOnSearch());
  private debouncedOnSearch = debounce(() => this.fetchAutomations(), 300);

  @action
  public async fetchAutomations(): Promise<void> {
    this.isLoading = true;
    const scenarios = await getAutomationListUseCase.exec({
      projectId: this.projectId,
      query: this.searchQuery
    });
    //todo: do ogrania case gdy dwa lub wiecej scenariuszy mają ten sam priorytet
    this.automations = scenarios.map(s => new AutomationScenario(s))
      .sort((a, b) => a.priority - b.priority);
    this.isLoading = false;
  }

  @action
  public updateAllScenarios = async (): Promise<void> => {
    await Promise.all(this.automations.map(a => a.updateScenario()));
  };

  @computed
  public get hasScenarios(): boolean {
    return this.automationList.length > 0;
  }

  @action
  public handleSearchQuery = (value: string) => {
    this.searchQuery = value;
  };

  @computed
  public get automationList(): AutomationScenario[] {
    if (this.filter === filterTypes.TEST_MODE) {
      return this.automations.filter(a => a.isEnabledForTest);
    }

    if (this.filter === filterTypes.DISABLED) {
      return this.automations.filter(a => !a.isEnabledForAll && !a.isEnabledForTest);
    }

    if (this.filter === filterTypes.ENABLED) {
      return this.automations.filter(a => a.isEnabledForAll);
    }

    if (this.filter === filterTypes.ALL) {
      return this.automations;
    }

    return this.automations;
  }

  public onDeleteConfirm = async (automationId: string): Promise<void> => {
    await this.removeAutomation(automationId)
      .then(() => toast.success(t('Your automation has been deleted successfully')));

    const idxDeletedScenario = this.automations.findIndex(a => a.id === automationId);
    this.automations.splice(idxDeletedScenario, 1);
    this.automations.forEach(a => {
      a.priority = this.automations.indexOf(a);
    });
    this.updateAllScenarios().catch(console.error);
    this.automations = [];
    await this.fetchAutomations();
  };

  @action
  public removeAutomation = async (automation: string): Promise<void> => {
    await deleteAutomationUseCase.exec({
      projectId: this.projectId,
      automationId: automation
    });
  };

  @computed
  public get isFilterOn(): boolean {
    return !!this.filter || !!this.searchQuery;
  }

  @action
  public changePriorityView(): void {
    this.priorityView = !this.priorityView;
  }

  @action
  public cancelPriorityView(): void {
    this.priorityView = false;
  }

  @computed
  public get isAnyFilterOn(): boolean {
    const filters = [this.enabled, this.disabled, this.draft, this.tagScenario, this.testMode];
    return filters.some(f => f === false);
  }

  @computed
  public get isSearchOn(): boolean {
    return this.searchQuery.length > 0;
  }

  @computed
  public get isEditView(): boolean {
    return !this.priorityView && !this.isAnyFilterOn && !this.isSearchOn;
  }

  @action
  public savePriority(): void {
    this.changePriorityView();
  }

  public get filterOptions(): ISelectOption[] {
    return [
      { name: t('All'), value: filterTypes.ALL },
      { name: t('Enabled'), value: filterTypes.ENABLED },
      { name: t('Disabled'), value: filterTypes.DISABLED },
      { name: t('Test mode'), value: filterTypes.TEST_MODE },
    ];
  }

  public setDefaultValues(): void {
    this.enabled = true;
    this.disabled = true;
    this.draft = true;
    this.tagScenario = true;
    this.testMode = true;
    this.automations = [];
    this.priorityView = false;
    this.searchQuery = '';
    this.filter = '';
  }
}
