import { action, observable } from 'mobx';
import { ProjectRelated } from './ProjectRelatedStore';
import { CustomField } from '../modelsMobx/CustomField';
import { AutomationLabel } from "../components/Automation/TagsForm/AutomationLabel";
import { IAutomationFlowStep } from "../models";
import { ProjectStore } from "./project/ProjectStore";
import { AutomationProjectDataStore } from "./project/AutomationProjectDataStore";

export class AutomationUpdateStepStore extends ProjectRelated {

  private automationProjectDataStore: AutomationProjectDataStore;

  constructor(projectStore: ProjectStore, automationProjectDataStore: AutomationProjectDataStore) {
    super(projectStore);
    this.automationProjectDataStore = automationProjectDataStore
  }

  /**
   * Field used in automation for updateStep only
   */
  @observable public cfLabels: AutomationLabel[];

  @action
  setDefaultValues(): void {
    this.cfLabels = [];
  }

  @action
  public removeCfLabel(toRemove: AutomationLabel) {
    this.cfLabels = this.cfLabels.filter(label => label.getLabelIdentity() !== toRemove.getLabelIdentity())
  }

  @action
  public setLabelsFromStep(step: IAutomationFlowStep<any>) {
    this.cfLabels = [];

    for (let key in step.payload) {

      let value = step.payload[key];

      // FIXME: Remove condition, after backend fix go to prod ;) (migration)
      if (typeof value === 'object') {
        value = value.value;
      }

      if (key !== 'nextStepId') {
        this.cfLabels.push(
          AutomationLabel.createLabel({
            key: key,
            value: value,
            type: CustomField.guessType(value)
          })
        )
      }
    }
  }

  @action
  public addNewCfLabel(): AutomationLabel {
    const al = AutomationLabel.createLabel({key: '', value: ''});
    this.cfLabels.push(al);
    return al;
  }
}
