import { IPushCampaignOptions } from './PushCampaignOptions';
import { Polygon } from '../Polygon';
import { property } from '@ppg/common';
import { action, computed } from 'mobx';
import { ITogglePolygon } from '../../stores/project/ICreateABTestStore';

export type IPolygonType = Array<[number, number]>
export type IPolygonsType = Array<IPolygonType>

interface IPolygonsPushCampaignOptions extends IPushCampaignOptions {
  polygons: Polygon[];
  selectedPolygons: Polygon[];
}

export class PolygonsPushCampaignOptions implements IPolygonsPushCampaignOptions {
  @property() public sentBy: string;
  @property() public fromAPI: boolean;

  @property() public polygons: Polygon[] = [];
  @property() public selectedPolygons: Polygon[] = [];

  constructor(sentBy, fromAPI, polygons) {
    this.sentBy = sentBy;
    this.fromAPI = fromAPI;
    this.polygons = polygons;
  }

  public getOptionsDTO(): Record<string, any> {
    return {
      polygons: this.getCoordinates()
    };
  }

  public getCoordinates(): IPolygonsType {
    return this.polygons.map(polygon => polygon.coordinates);
  }

  @computed
  public get hasPolygons(): boolean {
    return this.polygons.length > 0;
  }

  @action
  public togglePolygon = ({ polygon }: ITogglePolygon): void => {
    if (polygon.isSelected) {
      this.polygons.push(polygon);
      return;
    }

    const polygonIndex = this.polygons.findIndex(item => item.id === polygon.id);
    this.polygons.splice(polygonIndex, 1);
  };

  @action
  public toggleSelectedPolygon = ({ polygon }: ITogglePolygon): void => {
    if (polygon.isSelected) {
      this.selectedPolygons.push(polygon);
      return;
    }

    const polygonIndex = this.selectedPolygons.findIndex(item => item.id === polygon.id);
    this.selectedPolygons.splice(polygonIndex, 1);
  };

  @action
  public deselectSelectedPolygons(): void {
    this.selectedPolygons = [];
  }

  @action
  public onSavePolygons(): void {
    this.polygons = [...this.selectedPolygons]
    this.deselectSelectedPolygons()
  }

  @action
  public onSetSelectedPolygons(): void {
    this.selectedPolygons = [...this.polygons]
  }
}
