export class Monitor {
  private timeoutId;
  private callback;
  private interval;
  private timeout;
  private forceStop = false;

  constructor(callback: Function, interval: number, timeout: number = 0) {
    this.callback = callback;
    this.interval = interval;
    this.timeout = timeout;
    this.firstRun();
  }

  private wrappedCallback() {
    const result = this.callback && this.callback();
    if (typeof result && typeof result.then === 'function') {
      return result
        .then(() => this.run())
        .catch(() => this.clear());
    }

    this.run();
  }

  private firstRun() {
    if (this.forceStop)
      return;
    this.timeoutId = setTimeout(() => this.wrappedCallback(), this.timeout);
  }

  private run() {
    if (this.forceStop)
      return;

    this.timeoutId = setTimeout(() => this.wrappedCallback(), this.interval);
  }

  public clear() {
    if (!this.timeoutId)
      return;

    this.forceStop = true;
    clearTimeout(this.timeoutId);
    this.timeoutId = null;
  };
}
