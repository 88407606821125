import * as React from 'react';
import { CustomFormBuilder, DefaultFormBuilder, TinyFormBuilder, TopFormBuilder } from './index';
import { t } from '../../../../base/helpers';

interface ISubscriptionFormTemplateProperties {
  color?: string;
  picture?: string;
  siteUrl?: string;
  headerText?: string;
  descriptionText?: string;
  allowText?: string;
  denyText?: string;
  isNative?: boolean;
  isDirectionRtl?: boolean;
}

export class SubscriptionFormBuilder {

  static defaultHeaderText = () => t('Subscribe for push notifications');
  static defaultContentText = (siteUrl) => t('Allow {{url}} to send you web push notifications', { url: siteUrl });
  static defaultAllowText = () => t('Allow');
  static defaultDenyText = () => t('Deny');

  protected formProperties: ISubscriptionFormTemplateProperties = {
    siteUrl: '',
    allowText: SubscriptionFormBuilder.defaultAllowText(),
    denyText: SubscriptionFormBuilder.defaultDenyText(),
    picture: '',
  };

  constructor(formProperties: ISubscriptionFormTemplateProperties) {
    this.formProperties = formProperties;
  }

  protected get description() {
    const { descriptionText } = this.formProperties;
    const siteUrl = this.formProperties.siteUrl || '';
    const address = siteUrl.substring(siteUrl.indexOf('://') + 3, siteUrl.length);
    return descriptionText.includes('{{url}}') ? descriptionText.replace(/{{url}}/, address) : descriptionText
  }

  protected get header() {
    return this.formProperties.headerText;
  }

  private getInstance(type: string) {
    switch (type) {
      case 'custom':
        return new CustomFormBuilder(this.formProperties);
      case 'top':
        return new TopFormBuilder(this.formProperties);
      case 'default':
        if (this.formProperties.isNative) {
          this.formProperties.headerText = SubscriptionFormBuilder.defaultHeaderText();
          this.formProperties.descriptionText = SubscriptionFormBuilder.defaultContentText(this.formProperties.siteUrl);
          this.formProperties.allowText = SubscriptionFormBuilder.defaultAllowText();
          this.formProperties.denyText = SubscriptionFormBuilder.defaultDenyText();
        }

        return new DefaultFormBuilder(this.formProperties);
      case 'tiny':
        return new TinyFormBuilder(this.formProperties);
    }
  }

  public getTypeTemplate(type: string) {
    const instance = this.getInstance(type);
    return instance.getTemplate();
  }
}
