import { UseCase, API } from '@ppg/common';
import { IGetDataLayer, IGetPlugin } from '../interfaces';

export class GetDataLayerPluginUseCase extends UseCase<IGetPlugin, IGetDataLayer> {
  protected async execute({ websiteIntegrationId }: IGetPlugin) {
    return API.HTTP.get<IGetDataLayer>(`wi/projects/${ API.HTTP.currentProjectId }/website-integrations/${ websiteIntegrationId }/plugins/data-layer`);
  }
}

export const getDataLayerPluginUseCase = new GetDataLayerPluginUseCase();
