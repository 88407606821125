import { AttributeModel } from './AttributeModel';
import { IAttribute } from './IAttribute';

export class SubModelAttribute<T> implements IAttribute<T> {
  constructor(public subModel: AttributeModel,
              public attribute: string) {}

  public getValue(): any {
    return this.subModel.get(this.attribute);
  };

  public setValue(val: any) {
    this.subModel.set(this.attribute, val);
  }

  public getMessages(): any[] {
    return [];
  }

  public hasErrors(): boolean {
    return false;
  }

  public hasWarnings(): boolean {
    return false;
  }

  public hasInfoMessages(): boolean {
    return false;
  }

  public isSuccess(): boolean {
    return false;
  }

  public onChangeSetter<T extends HTMLInputElement>() {
    return (event: React.FormEvent<T>) => {
      this.setValue(event.currentTarget.value);
    };
  }
}