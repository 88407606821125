import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Loader } from '@ppg/styled';
import { ProjectLayout } from '../components/Layout/ProjectLayout';
import { ProjectRoutesTypes } from './moduleProjectRoutes';

const Suspense = (React as any).Suspense;

export function ApplicationRoutes() {
  return (
    <Suspense fallback={ <Loader/> }>
      <Switch>
        <Route path={ProjectRoutesTypes.HOME} component={ ProjectLayout }/>
      </Switch>
    </Suspense>
  );
}