import { API, UseCase } from '@ppg/common';
import { getPlatformTypeParam, getRangeDateParam, getTimezoneParam } from '../../../helpers';
import { IGetSubscribersLifetimeStructureResult, IOrganizationDashboardRequestBody } from '../../../interfaces';

export class GetSubscribersLifetimeStructureUseCase extends UseCase<IOrganizationDashboardRequestBody, IGetSubscribersLifetimeStructureResult> {
  protected async execute({ organization, platform, from, to, timezone }: IOrganizationDashboardRequestBody) {
    const endpoint = `statistics/organizations/${ organization }/subscribers/inactive/lifetime/structure`
      + getPlatformTypeParam(platform) + getRangeDateParam(from, to) + getTimezoneParam(timezone);

    return API.HTTP.get<IGetSubscribersLifetimeStructureResult>(endpoint);
  }
}
