import { API, UseCase } from "@ppg/common";
import { ICampaignId, ICampaignIdResponse } from '../interfaces';

interface ISendDraftSegmentedPushRequest extends ICampaignId {}
interface ISendDraftSegmentedPushResponse extends ICampaignIdResponse {}

export class SendDraftSegmentedPushUseCase extends UseCase<ISendDraftSegmentedPushRequest, ISendDraftSegmentedPushResponse> {
  protected async execute({ campaignId }: ISendDraftSegmentedPushRequest) {
    return API.HTTP.post<ISendDraftSegmentedPushResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/segmented/${ campaignId }/accept`);
  }
}

export const sendDraftSegmentedPushUseCase = new SendDraftSegmentedPushUseCase();
