import { API, UseCase } from '@ppg/common';
import { Image } from '../../../modelsMobx/Image';

interface GetProjectMainImagesUseCasePayload {
  project: string;
}

export class GetProjectImagesUseCase extends UseCase<GetProjectMainImagesUseCasePayload, Image[]> {
  protected async execute(payload: GetProjectMainImagesUseCasePayload): Promise<Image[]> {
    const result = await API.HTTP.get<any[]>(`core/projects/${ payload.project }/images`);

    return result.map((item) => new Image(item));
  }
}

export const getProjectImagesUseCase = new GetProjectImagesUseCase();
