import styled from '@emotion/styled';
import { MainTheme } from '../../../../assets/themes/MainTheme';
import { maxWidthMedia } from '../../../../assets/themes/devices/breakpoints';

export const ProjectRank = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 0.4rem;
  background: ${ MainTheme.colors.functional.white };
  padding: 2.0rem;
  margin-bottom: 2rem;
  border: 1px solid rgba(54, 64, 74, 0.13);
  word-break: break-word;
  min-width: min-content;
`;

export const ProjectRankInitialLoaderContainer = styled(ProjectRank)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
`

export const ProjectRankHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ProjectRankTitle = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: ${MainTheme.font.weight.bold};
  font-size: ${MainTheme.font.size.l};
`;

export const ProjectSelectWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const ProjectSelectLabel = styled.div`
  margin-bottom: 15px;
`;


export const ProjectRankTable = styled.table`
  margin-bottom: 0;
  border: none;

  td, th, tr {
    border: none;
    text-align: center;
  }

  td:first-of-type {
    text-align: left;
  }
  
  th:last-child {
    padding-right: 15px;
  }

  td:last-child {
    padding-right: 15px;
  }

  tr {
    border-bottom: ${ MainTheme.border.base };

    &:last-of-type {
      border-bottom: none;
    }
  }

  @media ${maxWidthMedia.laptopM} {
    td, th {
      padding: 0.5rem 0.5rem;
    }
  }
  
  @media ${maxWidthMedia.laptopS} {
        display: table;

        thead {
          display: none;
        }

        td, tr {
          display: block;
          width: 100%;
          padding: 1.5rem 1.5rem;
        }

        tr {
          border-bottom: 0.3rem solid ${MainTheme.colors.functional.border};
          padding-bottom: 20px;
        }

        td {
          text-align: right;
          padding-left: 50%;
          position: relative;
          display: flex;
          justify-content: flex-end;

          &:first-of-type {
            justify-content: flex-start;
            padding-left: 15px;
          }

          &:last-child {
            border-bottom: none;
          }

          &::before {
            content: attr(data-label);
            position: absolute;
            left: 0;
            width: 50%;
            padding-left: 15px;
            font-weight: ${MainTheme.font.weight.bold};
            text-align: left;
            font-size: 1.4rem;
        }
    }
  }
`;


/*
 * Project rank item
 */

export const ProjectRankItemDetails = styled.td`
  display: flex;
  gap: 10px;
`;

export const ProjectRankItemLabel = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProjectRankItemTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProjectRankItemIcon = styled.div`
  display: flex;
  align-items: center;
`

export const ProjectRankItemProject = styled.div`
  color: ${MainTheme.colors.text.text300}
`


