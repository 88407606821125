import { Link } from 'react-router-dom';
import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { UserStore } from '../../../../stores/user/UserStore';
import { PaymentsStore } from '../../../../stores/payment/PaymentsStore';
import { t } from '../../../../base/helpers';
import { PPGLite, PPGLiteRole } from '../../../../components/PPGLite/PPGLite';
import {PaymentsRoutesTypes} from '../../../../routes/paymentsRoutes';

interface IPaymentDetailsProps {
  userStore?: UserStore;
  paymentsStore?: PaymentsStore;
}

@inject('userStore', 'paymentsStore')
@observer
export class PaymentDetails extends React.Component<IPaymentDetailsProps, {}> {
  public render() {
    const { paymentsStore } = this.props;

    return (
      <div className="column column-50">

        <div className="well">
          <div className="row m10t">
            <div className="column">
              <h4 className="form-title">{ t('Payments') }</h4>
            </div>
            <div className="column text-right">
          <span className="m10b">
            <Link to={PaymentsRoutesTypes.PAYMENTS} className="acts-as-link"> { t('Go to Payments') } <span className="m5l icon-arrow-right size10"/> </Link>
          </span>
            </div>
          </div>
          <div className="row m30t">
            <div className="column">
              <div>{ t('Balance') }:</div>
              <div className="balance-details">

                <PPGLite role={ PPGLiteRole.DISPLAY }>
                  <span>
                    <Link to={PaymentsRoutesTypes.PAYMENTS} className="acts-as-link size24"> { t('Change Plan') }  </Link>
                  </span>
                </PPGLite>

                <div className={ `account-balance-wrapper ${paymentsStore.accountDetails.length > 1 && 'multiple-balance'}` }>
                  {
                    paymentsStore.accountDetails.length > 0
                    && paymentsStore.accountDetails.map((detail, index) => {
                      return <span key={ index }>
                    <span className="size30">{ (detail.balance / 100).toFixed(2) } </span>
                    <span className="size20 m10l">{ detail.currency }</span>
                  </span>
                    })
                  }
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
