import { t } from '../../../base/helpers';
import { ILineChartProps } from '../../../components/Charts/LineChart/LineChart';
import { AutomationTabsTypes, CampaignsTabsTypes } from './interfaces';

export const getLabels = (value: string | number): any => {
  switch (value) {
    case 'all':
      return t('organization/dashboard/campaigns::All');
    case 'mass':
      return t('organization/dashboard/campaigns::Mass');
    case 'geolocation':
      return t('organization/dashboard/campaigns::With geolocation');
    case 'targeted':
      return t('organization/dashboard/campaigns::Targeted');
    default:
      return value;
  }
};

export const customCtrLineChartProperties: Partial<ILineChartProps> = {
  axisLeft: {
    "format": function (value) {
      return `${ Math.round(value * 100) } %`;
    },
    "orient": "left",
    "tickSize": 5,
    "tickPadding": 5,
    "tickRotation": 0,
    "legendOffset": -40,
    "legendPosition": "center"
  },
};

export const automationTabs: AutomationTabsTypes = {
  GENERAL: t('organization/dashboard::Statistics'),
  CTR: t('organization/dashboard::CTR'),
}

export const campaignsTabs: CampaignsTabsTypes = {
  SENT: t('organization/dashboard/campaigns::Number of campaigns'),
  GENERAL: t('organization/dashboard/campaigns::Statistics'),
  CTR: t('organization/dashboard/campaigns::CTR'),
  DSP: t('organization/dashboard/campaigns::DSP'),
  SENDING_HOURS: t('organization/dashboard/campaigns::Sending hours'),
  SUBSCRIBERS_ACTIVITY: t('organization/dashboard/campaigns::Subscribers activity'),
}

export type OrganizationFiltersCampaignTypes = {
  SUMMARY: string,
  TARGETING: string,
  GEOLOCATION: string,
  MASS: string,
}

export const dashboardFiltersCampaignTypes: OrganizationFiltersCampaignTypes = {
  SUMMARY: t('organization/dashboard/campaigns::All'),
  TARGETING: t('organization/dashboard/campaigns::Targeted'),
  GEOLOCATION: t('organization/dashboard/campaigns::With geolocation'),
  MASS: t('organization/dashboard/campaigns::Mass'),
}