import { API, UseCase } from '@ppg/common';
import { getPlatformTypeParam, getRangeDateParam, getTimezoneParam } from '../../../helpers';
import { IGetActiveSubscribersInRangeResult, IOrganizationDashboardRequestBody } from '../../../interfaces';

export class GetActiveSubscribersInRangeUseCase extends UseCase<IOrganizationDashboardRequestBody, IGetActiveSubscribersInRangeResult> {
  protected async execute({ organization, platform, from, to, timezone }: IOrganizationDashboardRequestBody) {
    let rangeEndpoint = `statistics/organizations/${ organization }/subscribers/active/range`
      + getPlatformTypeParam(platform) + getRangeDateParam(from, to) + getTimezoneParam(timezone);

    return API.HTTP.get<IGetActiveSubscribersInRangeResult>(rangeEndpoint);
  }
}

