import { API, UseCase } from "@ppg/common";
import { IUtm } from '../../../modelsMobx/helpers/Utm';

interface GetUtmRequest {
  projectId: string;
}

interface GetUtmResponse extends IUtm {
  id: string;
  project: string;
}

export class GetUtmUseCase extends UseCase<GetUtmRequest, GetUtmResponse> {
  protected async execute({ projectId }: GetUtmRequest) {
    return API.HTTP.get<GetUtmResponse>(`core/projects/${ projectId }/utm`);
  }
}

export const getUtmUseCase = new GetUtmUseCase();
