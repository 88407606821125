import { IMap } from '../interfaces';

class LSSettings {
  private obj: IMap<any> = {};

  constructor(private key: string) {
    try {
      this.obj = JSON.parse(window.localStorage.getItem(this.key) || "{}");
    } catch (e) {
      console.warn('Got error:', e);
      this.obj = {};
    }
  }

  public setValue(key: string, val: any): void {
    this.obj[key] = val;
    this.sync();
  }

  public getValue<T>(key: string): T {
    return this.obj[key];
  }

  private sync(): void {
    localStorage.setItem(this.key, JSON.stringify(this.obj));
  }
}

export const lsSettings = new LSSettings('__pushpushgo');

export enum lsPropertyKeys {
  HEADERS = 'headers',
  SUBSCRIBERS_LIST_HEADERS = 'subscribers_list_headers'
}