import { PropertyHandler } from '@ppg/common';
import { computed, observable, reaction } from 'mobx';
import { ProjectStore } from './project/ProjectStore';

export interface IProjectRelated {
  projectName: string;
  projectSiteUrl: string;
  projectResourceUrl: string;
  projectCreatedDate: Date;
}

/**
 * abstract class for stores in project scope
 * enables reading basic information about project
 * instances react when current project changes with setDefaultValues
 */
export abstract class ProjectRelated extends PropertyHandler implements IProjectRelated {

  @observable protected projectStore: ProjectStore;

  private reaction;

  constructor(projectStore: ProjectStore) {
    super();
    this.projectStore = projectStore;
    // once current project is set, call setDefaultValues and create a reaction on currentProjectId changes
    reaction(() => this.hasCurrentProject,
      (arg, initialReaction): void => {
        this.hasCurrentProject && this.setDefaultValues();

        this.reaction = reaction(() => this.projectStore.currentProjectID,
          () => this.hasCurrentProject && this.setDefaultValues()
        );

        initialReaction.dispose();
      }
    );
  }

  abstract setDefaultValues();

  @computed
  get projectResourceUrl() {
    return `project/${ this.projectId }`;
  }

  @computed
  get projectId() {
    return this.projectStore?.currentProjectID;
  }

  @computed
  public get organizationId(): string {
    return this.projectStore?.organizationId;
  }

  @computed
  get projectName() {
    return this.projectStore?.currentProject?.name;
  }

  @computed
  get projectSiteUrl() {
    return this.projectStore?.currentProject?.siteUrl;
  }

  @computed
  get hasIntegrationErrors(): boolean {
    const { websiteIntegrationStore } = this.projectStore;
    return websiteIntegrationStore && websiteIntegrationStore.integration && !websiteIntegrationStore.integration.hasNoIntegrationErrors;
  }

  // todo: deprecated - remove when project mobx model ready
  get currentProject() {
    return this.projectStore.currentProject;
  }

  get hasCurrentProject() {
    return !!this.currentProject;
  }

  async refreshCurrentProject() {
    return this.projectStore.setProject(this.projectStore.currentProjectID);
  }

  @computed
  get projectCreatedDate(): Date {
    return new Date(this.projectStore?.currentProject?.createdAt)
  }
}
