import * as React from 'react';
import { t } from '../../../base/helpers';
import { getDateAndTimeFromStringDate } from '@ppg/common';
import { Button, ConfirmIconButton, TypeSizeBtn } from '@ppg/styled';
import { AccessKey } from '../../../modelsMobx/AccessKey';
import { AccessManagerStore } from '../../../stores/AccessManagerStore';
import { ProjectStore } from '../../../stores/project/ProjectStore';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { DEFAULT_DISPLAYED_PROJECTS } from '../../../constants';
import "./AccessManager.scss";

interface IAccessManagerKeyProps {
  accessKey: AccessKey;
  accessManagerStore: AccessManagerStore;
  projectStore: ProjectStore;
}

@observer
export class AccessManagerKey extends React.Component<IAccessManagerKeyProps> {

  @observable private lengthProjects: number;
  @observable private displayedProjectsCount: number = DEFAULT_DISPLAYED_PROJECTS;

  @action
  private getProjectsNames = (projects: string[]): string => {
    let projectsList;

    projectsList = Array
      .from(projects)
      .filter((projectID) => this.props.projectStore.projects.some((project) => project.id === projectID))
      .map((projectID) => this.props.projectStore.getProjectNameByID(projectID));

    this.lengthProjects = projectsList.length;
    this.displayedProjectsCount = this.lengthProjects < DEFAULT_DISPLAYED_PROJECTS ? 0 : this.displayedProjectsCount;

    projectsList = this.displayedProjectsCount < DEFAULT_DISPLAYED_PROJECTS ? projectsList : projectsList.slice(0, this.displayedProjectsCount);

    return projectsList.join(', ');
  };

  @action
  private handleShowMoreProjects = (): void => {
    const subtraction = this.lengthProjects - this.displayedProjectsCount;
    this.displayedProjectsCount = subtraction > DEFAULT_DISPLAYED_PROJECTS ? this.displayedProjectsCount + DEFAULT_DISPLAYED_PROJECTS : this.lengthProjects;
  };

  private showLoadMoreButton = (): boolean => {
    return this.lengthProjects > DEFAULT_DISPLAYED_PROJECTS && this.displayedProjectsCount !== this.lengthProjects;
  };

  private async handleDeleteKey(): Promise<void> {
    const { accessKey, accessManagerStore } = this.props;
    await accessManagerStore.removeAccessKey(accessKey.id);
  }

  public render() {
    const { accessKey } = this.props;

    return <tr className="p10 borderb" key={ accessKey.token }>
      <td data-label={ t('user/access-manager/keys::Key token') }>{ accessKey.token }</td>
      <td data-label={ t('user/access-manager/keys::Projects') } className="access-manager-projects">
        { this.getProjectsNames(accessKey.projects) }
        { this.showLoadMoreButton() && <Button id="access-manager-projects-btn"
                                               content="show more"
                                               size="small"
                                               isOutline
                                               onClick={ this.handleShowMoreProjects }/>
        }
      </td>

      <td className="expire-date" data-label={ t('user/access-manager/keys::Expire date') }>{ getDateAndTimeFromStringDate(accessKey.expiredAt) }</td>
      <td className="delete-cell">
        <ConfirmIconButton icon={ 'trash' }
                           typeBtn="white"
                           positionBtn="row"
                           size={ TypeSizeBtn.MEDIUM }
                           dataTip={ t('user/access-manager/keys::Delete key') }
                           dataTipConfirmBtn={ t('user/access-manager/keys::Confirm delete key') }
                           dataTipCancelBtn={ t('user/access-manager/keys::Cancel delete key') }
                           onConfirm={ () => this.handleDeleteKey() }/>

      </td>
    </tr>;
  }
}
