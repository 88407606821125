import { PropertyHandler } from '@ppg/common';
import { action, observable } from 'mobx';

export class ComparatorTable extends PropertyHandler {
  @observable public data: IComparatorStatisticsDay[] = [];
  @observable public offset: number = 0;
  @observable public total: number = 0;
  @observable public headers: string[] = [];

  @action
  public setData(data: IComparatorStatisticsDay[], total: number) {
    this.data = data.sort((a,b) => new Date(a.day).getTime() - new Date(b.day).getTime());
    this.total = total;
    this.headers = this.setHeaders(data);
  }

  @action
  private setHeaders(data: IComparatorStatisticsDay[]): string[] {
    if (data.length < 1) {
      return [];
    }
    const projects = new Set<string>();
    data.map(day => day.data.map(stats => projects.add(stats.project)))
    return Array.from(projects);
  }
}

interface IComparatorStatisticsDay {
  day: string;
  data: ComparatorStatisticsDay[];
}

export interface ComparatorStatisticsDay {
  project: string;
  value: number;
}
