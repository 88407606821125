import * as React from 'react';
import { observer } from 'mobx-react';
import { paymentDataStore } from '../../../../stores/index';
import { IUserNavigationTab } from './UserNavigationBar';

interface IUserNavigationBadge {
  item: IUserNavigationTab;
}

@observer
export class UserNavigationBadge extends React.Component<IUserNavigationBadge, {}> {

  private renderUserBadge(tab:IUserNavigationTab):JSX.Element {
    switch (tab.title.toLowerCase()){
      case 'settings':
        return !paymentDataStore.hasUserData && <span className="badge variant-badge--4 m10l ">!</span>
      case 'invoice details':
        return !paymentDataStore.hasPaymentData && <span className="badge red m10l">!</span>
      default:
        return null
    }
  }

  public render() {
   return this.renderUserBadge(this.props.item)
  }
}
