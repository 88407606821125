import * as React from 'react';
import { t } from "../../../base/helpers";
import { inject, observer } from "mobx-react";
import { webhookStore } from "../../../stores/index";
import { Select, UrlInput } from '@ppg/styled'
import { WebhookStore } from '../../../stores/project/WebhooksStore';

const { JsonTree } = require('react-editable-json-tree');

interface IWebhookEditView {
  webhookStore?: WebhookStore;
}

interface IWebhookEditState {
  showHeadersEditor: boolean;
}

@inject('webhookStore')
@observer
export class WebhookEditView extends React.Component<IWebhookEditView, IWebhookEditState> {

  public state: IWebhookEditState = {
    showHeadersEditor: webhookStore.webhookToEdit.hasHeaders,
  };

  public toggleEditorMode() {
    this.setState({ showHeadersEditor: !this.state.showHeadersEditor });
  }

  public render() {

    const { webhookStore } = this.props;
    const { webhookToEdit } = webhookStore;

    return (
      <div>
        <div className="column">
          <Select property={ webhookToEdit.getProperty('type') }
                  options={ webhookToEdit.eventOptions }
                  label={ t('Event') }/>
        </div>

        <UrlInput label={ t('Webhook Url') } schemas={['https', 'http']}
                  urlProperty={ webhookToEdit.getProperty('url') }/>

        <span className="icon-user size12 m5r"/>
        <span className="acts-as-link" onClick={ () => this.toggleEditorMode() }>{ t('Personalize headers') }</span>

        { this.state.showHeadersEditor && (
          <div className="p20 background-yellow-light m20b radius">
            <label className="m10b">
              <span className="icon-settings m10r"/>
              <span className="m10r">
                            { t('Your headers with sample values:') }
                          </span>
            </label>
            <JsonTree
              data={ webhookToEdit.headers || {} }
              rootName={ 'headers' }
              addButtonElement={ <span className="button button--square button--small m10l"><span className="icon-tick size12"/></span> }
              cancelButtonElement={ <span className="button button--square button--small button--default m10l"><span className="icon-close size12"/></span> }
              editButtonElement={ <span className="button button--square button--small m10l"><span className="icon-pencil"/></span> }
              minusMenuElement={ <span className="button button--square"><span className="icon-trash m20l size14 text-normal"/></span> }
              plusMenuElement={ <span className="button button--square"><span className="icon-plus-badge m10l text-green"/></span> }
              onFullyUpdate={ (hd: any) => webhookToEdit.headers = hd }/>
          </div>
        ) }
      </div>
    );
  }
}
