import { uploadImageUseCase } from '../../useCases/core';
import { Image, ImageType } from '../../modelsMobx';

interface UploadImagePrams {
  name: string;
  type: ImageType;
  base64?: string;
  blob?: Blob;
}

export class ImageStore {

  public async uploadImage({ name, type, blob, base64 }: UploadImagePrams): Promise<Image> {
    const formData = new FormData();

    if (!blob && base64) {
      blob = this.dataURItoBlob(base64);
    }

    formData.append('imageType', type);
    formData.append('imageName', name || type);
    formData.append('imageMime', blob.type);
    formData.append('imageFile', blob);

    return uploadImageUseCase.exec({
      formData,
    });
  }

  public dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);

    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }

  setDefaultValues() {}
}
