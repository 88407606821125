import { API, UseCase } from "@ppg/common";
import { IProvider } from '../interfaces';

interface IUpdateHMSProvider extends IProvider {
  appId: string;
  appSecret: string;
  pushUrl: string;
  authUrl: string;
}

interface IUpdateHMSResponse {
  appId: string;
  appSecret: string;
  pushUrl: string;
  authUrl: string;
}

export class UpdateHMSProviderUseCase extends UseCase<IUpdateHMSProvider, IUpdateHMSResponse> {
  protected async execute({ appId, appSecret, authUrl, pushUrl, projectId, organizationId }: IUpdateHMSProvider) {
    return API.HTTP.post<IUpdateHMSResponse>(`core/organizations/${ organizationId }/projects/${ projectId }/providers/hms`, {
      appId, appSecret, authUrl, pushUrl
    });
  }
}

export const updateHMSProviderUseCase = new UpdateHMSProviderUseCase();
