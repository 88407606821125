import { isNotEmpty, minLength, property } from '@ppg/common';
import { changePasswordUseCase } from '../../useCases/auth-and-identity/ChangePasswordUseCase';
import { UserRelatedStore } from './UserRelatedStore';
import { action, computed } from 'mobx';
import { checkPasswordUseCase } from '../../useCases/auth-and-identity/CheckPasswordUseCase';
import { changePasswordWithTokenUseCase } from '../../useCases/auth-and-identity/ChangePasswordWithTokenUseCase';
import { toast } from '@ppg/styled';
import { checkChangePasswordTokenExpireUseCase } from '../../useCases/auth-and-identity/CheckChangePasswordTokenExpireUseCase';

export class PasswordStore extends UserRelatedStore {
  @property([minLength(2), isNotEmpty()])
  public password: string = '';

  @property([isNotEmpty()])
  public confirmationPassword: string = '';

  @property([isNotEmpty()])
  public currentPassword: string = '';

  @property() errors: string[] = [];

  @computed
  public get areSamePasswords(): boolean {
    return this.password !== '' && this.password === this.confirmationPassword;
  }

  @computed
  public get isLongEnough(): boolean {
    return this.password.length >= 8;
  }

  @computed
  public get containsNumber(): boolean {
    return /\d/g.test(this.password);
  }

  @computed
  public get containsLowerCase(): boolean {
    return /[a-z]/.test(this.password);
  }

  @computed
  public get containsUpperCase(): boolean {
    return /[A-Z]/.test(this.password);
  }

  @computed
  public get containsSpecialCharacter(): boolean {
    return /[\s~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?()\._]/g.test(this.password);
  }

  public containsSequenceOf4Chars(): boolean {
    const sequences = [
      '01234567890',
      '09876543210',
      'abcdefghijklmnoprstuwxyz',
      'zyxwutsrponmlkjihgfedcba',
      'qwertyuiop',
      'asdfghjkl',
      'zxcvbnm',
    ];
    for (let i = 0; i < this.password.length; i++) {
      const sample = this.password.slice(i, i + 4);
      if (sample.length !== 4) {
        break;
      }
      for (let sequence of sequences) {
        if (sequence.includes(sample)) {
          return true;
        }
      }
    }
  }

  @computed
  public get isNotSimple(): boolean {
    return this.password !== '' && this.password.length >= 4 && !this.containsSequenceOf4Chars();
  }

  public get allRulesMet(): boolean {
    return this.isLongEnough && this.isNotSimple && this.containsSpecialCharacter
      && this.containsUpperCase && this.containsLowerCase && this.containsNumber && this.areSamePasswords;
  }

  @action
  public async changePassword(): Promise<void> {
    await changePasswordUseCase.exec({
        currentPassword: this.currentPassword,
        newPassword: this.password,
        repeatedPassword: this.confirmationPassword
      })
      .then(() => this.clear());
  }

  @action
  public async secondStepPasswordReset(token): Promise<void> {
    await changePasswordWithTokenUseCase.exec({
        token,
        password: this.password,
        confirmedPassword: this.confirmationPassword
      })
      .catch((error) => {
        this.parseErrors(error);
        this.errors.map(error => toast.error(error));
      })
      .finally(() => this.errors = []);
  }

  @action
  public async checkChangePasswordTokenExpire(token): Promise<void> {
    await checkChangePasswordTokenExpireUseCase.exec({ token });
  }

  public parseErrors(error) {
    error && [].concat(error.map(err => this.errors.push(err.message)));
  }

  @action
  public async validatePassword() {
    await checkPasswordUseCase.exec({ password: this.password })
      .then(() => this.errors = [])
      .catch((error) => this.errors = error);
  }

  @action
  public clear(): void {
    this.password = '';
    this.confirmationPassword = '';
    this.errors = [];
  }
}
