import { API, UseCase } from '@ppg/common';
import { IListSelectorsResult } from './interfaces';

export interface IListSelectors  {
  projectID: string;
}

export class ListAllSelectorsUseCase extends UseCase<IListSelectors, IListSelectorsResult> {
  protected async execute({ projectID }) {
    return API.HTTP.get<IListSelectorsResult>(`core/projects/${ projectID }/selectors?limit=1000`)
  }
}

export const listAllSelectorsUseCase = new ListAllSelectorsUseCase();
