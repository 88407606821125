import { API, UseCase } from '@ppg/common';

interface ChangeUserDetailData {
  firstName: string;
  language: string;
  lastName: string;
  phone: string;
}

export class ChangeUserDetailUseCase extends UseCase<ChangeUserDetailData, void> {
  protected async execute({ firstName, lastName, phone }: ChangeUserDetailData) {
    await API.HTTP.patch('ah/user-details/details', { firstName, lastName, phone })
  }
}

export const changeUserDetailUseCase = new ChangeUserDetailUseCase();
