import { API, UseCase } from '@ppg/common';

interface IAssertPartnershipExistsRequest {
  partnershipCode: string
}

export class AssertPartnershipExistsUseCase extends UseCase<IAssertPartnershipExistsRequest, void> {
  protected async execute({ partnershipCode }) {
    await API.HTTP.get(`acc/partnerships/${ partnershipCode }`);
  }
}

export const assertPartnershipExistsUseCase = new AssertPartnershipExistsUseCase();
