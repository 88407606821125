import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { LineChart } from '../../../../components/Charts/LineChart/LineChart';
import { BarChart } from '../../../../components/Charts/BarChart/BarChart';
import './ChartCard.scss'
import { ChartLoader, EmptyDataComponent, LineLoader } from '@ppg/styled';
import { FunnelChart } from '../../../../components/Charts/FunnelChart/FunnelChart';
import { isArrayEmpty } from '@ppg/common';
import { t } from '../../../../base/helpers';
import { ChartType, IDetailsChartProps } from './__types__';

export const ChartCard: FC<IDetailsChartProps> = observer(
  ({
     data,
     fetchData,
     mountFetchData,
     size,
     scrollAreaSize,
     chartType,
     customProperties,
     disablePlatformFetch,
     externalLoaders,
     title,
     valueInPercent,
     rangeChangeFactors,
     platformChangeFactors,
     isPlatformSelected
   }) => {
    const [initialLoader, setInitialLoader] = useState<boolean>(false);
    const [isMounting, setIsMounting] = useState<boolean>(true);
    const [isChartLoading, setChartLoader] = useState<boolean>(true);
    const customLineProperties = customProperties && { ...customProperties.line }
    const customBarProperties = customProperties && { ...customProperties.bar }
    const customFunnelProperties = customProperties && { ...customProperties.funnel }

    useEffect(() => {
      if (!mountFetchData) {
        return;
      }
      setIsMounting(true);
      mountFetchData()
        .then(() => {
          setChartLoader(false)
          setIsMounting(false);
        })
    }, []);

    // react on range changes
    useEffect(() => {
      if (!fetchData) {
        disableLoaders();
        return;
      }
      if (!isMounting) {
        fetchChartData();
      }
    }, [rangeChangeFactors])

    // react on platform changes
    useEffect(() => {
      if (disablePlatformFetch || !fetchData) {
        disableLoaders();
        return;
      }
      if (!isMounting) {
        fetchChartData();
      }
    }, [platformChangeFactors])

    const isChartDataEmpty = (): boolean => {
      switch (chartType) {
        case ChartType.FUNNEL:
          return isArrayEmpty(data.funnel);
        case ChartType.LINE:
          return data.line.every(lineDatum => isArrayEmpty(lineDatum.data) || lineDatum.data.every(d => d.y === 0));
        case ChartType.BAR:
          return isBarChartEmpty();
      }
    }

    const isBarChartEmpty = (): boolean => {
      if (isArrayEmpty(data.bar)) {
        return true
      }

      return data.bar
        .map(barDatum => {
          return Object.values(barDatum)
            .splice(1)
            .every(value => value === 0)
        })
        .every(barDatum => barDatum === true)

    }
    const disableLoaders = (): void => {
      setInitialLoader(false)
      setChartLoader(false)
    }

    const fetchChartData = (): void => {
      setChartLoader(true);
      fetchData().then(() => {
        setInitialLoader(true)
        setChartLoader(false)
      })
    }

    const showEmptyComponent = (): boolean => {
      return !isChartLoading && (isChartDataEmpty() || !isPlatformSelected());
    }

    const showExternalInitialLoader = (): boolean => {
      return externalLoaders && (externalLoaders.isLoading && !externalLoaders.isInitialLoader)
    }

    const showInternalInitialLoader = (): boolean => {
      return isChartLoading && !initialLoader;
    }

    const showInitialLoader = (): boolean => {
      return showInternalInitialLoader() || showExternalInitialLoader();
    }

    const showLineLoader = (): boolean => {
      return isChartLoading || (externalLoaders && externalLoaders.isLoading);
    }

    if (showInitialLoader()) {
      return <>
        { title && <h5 className={ 'chart-card__title m10t' }>{ title }</h5> }
        <div className={ `chart-card${ size ? `-${ size }` : '' }` }>
          <div className={ 'chart-card__loaders' }>
            <LineLoader/>
            <ChartLoader/>
          </div>
        </div>
      </>
    }

    if (showEmptyComponent()) {
      return <>
        { title && <h5 className={ 'chart-card__title m10t' }>{ title }</h5> }
        <div className={ 'm20b m10t' }>
          <EmptyDataComponent icon={ 'stat-lined' }
                              content={ t('Sorry, we don\'t have data to show for the filters you\'ve chosen') }
                              hideButton/>
        </div>
      </>
    }

    const renderChart = (): JSX.Element | null => {
      if (!isPlatformSelected() || isChartDataEmpty()) {
        return <div className={ 'chart-card__loaders' }>
          <LineLoader/>
          <ChartLoader/>
        </div>;
      }

      switch (chartType) {
        case ChartType.BAR:
          return <BarChart data={ data.bar } { ...customBarProperties } />
        case ChartType.LINE:
          return <LineChart data={ data.line } { ...customLineProperties } valueInPercent={ valueInPercent }/>
        case ChartType.FUNNEL:
          return <FunnelChart data={ data.funnel }  { ...customFunnelProperties }/>
      }
    }

    return <>
      { title && <h5 className={ 'chart-card__title m10t' }>{ title }</h5> }
      <div className={ `chart-card${ size ? `-${ size }` : '' }` }>
        <>
          { showLineLoader() && <LineLoader/> }
          <div className={ `chart-card__scrollArea${ scrollAreaSize ? `-${ scrollAreaSize }` : '' }` }>
            { renderChart() }
          </div>
        </>
      </div>
    </>
  })
