import * as React from 'react';
import { Link } from 'react-router-dom';
import { connector } from '../../base/index';
import { t } from '../../base/helpers';

import { ProjectEmptyData } from '../../components/common/index';
import { ProjectRoutesTypes } from '../../routes/moduleProjectRoutes';

@ProjectEmptyData.wrap()
@connector.inject('user')
export class Integrations extends React.Component<any, any> {

  public render() {

    return (
      <div className="text-center p30t">
        <h3>{ t('Integrations') }</h3>
        <p className="m10t">{ t('Integrate with other platforms using available tools') }</p>

        <div className="choose-blocks m20t">
          <Link to={ ProjectRoutesTypes.INTEGRATIONS_WEBHOOKS } className="choose-blocks__item well">
            <span className="icon-condition size40 m20b"/>
            <div>{ t('Webhooks') }</div>
          </Link>
          <a href="https://docs.pushpushgo.company/integration/rest-api" className="choose-blocks__item well" target="_blank">
            <span className="icon-settings size40 m20b"/>
            <div>API</div>
          </a>
        </div>
      </div>
    );
  }
}

export default Integrations;
