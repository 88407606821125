import { API, UseCase } from "@ppg/common";
import { IGetFCMResponse, IProvider } from '../interfaces';

export class GetAndroidProviderUseCase extends UseCase<IProvider, IGetFCMResponse> {
  protected async execute({ projectId, organizationId }: IProvider) {
    return API.HTTP.get<IGetFCMResponse>(`core/organizations/${ organizationId }/projects/${ projectId }/providers/android`);
  }
}

export const getAndroidProviderUseCase = new GetAndroidProviderUseCase();
