import { ElementIdType } from '../../SubscriptionFormEnums';

export const topStylesMap = {
  [ElementIdType.BACKDROP]: {
    display: 'none',
    width: '100%',
    height: '100%',
    zIndex: 99999999999,
    position: 'fixed',
    top: 0,
    left: 0,
  },
  [ElementIdType.FORM]: {
    position: 'fixed',
    lineHeight: '1.2',
    display: 'flex',
    direction: 'ltr',
    boxShadow: '0px 10px 40px 3px rgba(0, 0, 0, 0.3)',
    left: 0,
    top: 0,
    width: '100%',
    backgroundColor: '#fff',
    color: '#787878',
    padding: '15px 12px 15px 55px',
    textAlign: 'left',
    boxSizing: 'border-box',
    zIndex: 999999999999,
    fontFamily: 'sans-serif',
    '@media (max-width: 590px)': {
      lineHeight: '12px',
      fontSize: '12px',
      flexDirection: 'column',
      padding: '15px 12px 8px 10px',
    },
    '@media (max-width: 480px)': {},
  },
  [ElementIdType.ICON]: {
    width: '14px',
    height: '14px',
    display: 'inline-block',
    fontSize: '20px',
    position: 'absolute' as 'absolute',
    marginRight: '15px',
    marginLeft: 'auto',
    top: '24px',
    left: '20px',
    right: 'auto',
    '@media (max-width: 590px)': {
      top: '15px',
      left: '30px',
      right: 'auto',
    },
    '@media (max-width: 480px)': {
      top: '15px',
      left: '20px',
      right: 'auto',
    },
  },
  [ElementIdType.SVG]: {},
  [ElementIdType.DESCRIPTION]: {
    color: '#585858',
    width: '70%',
    textAlign: 'left',
    margin: 'auto',
    alignSelf: 'stretch',
    fontSize: '16px',
    lineHeight: '18px',
    '@media (max-width: 768px)': {
      fontSize: '14px',
      lineHeight: '16px',
    },
    '@media (max-width: 480px)': {
      fontSize: '13px',
      lineHeight: '13px',
      padding: '0 0 5px 25px',
      width: '80%',
    }
  },
  [ElementIdType.BUTTONS]: {
    width: '30%',
    textAlign: 'right',
    fontSize: '15px',
    '@media (max-width: 590px)': {
      display: 'flex',
      flexDirection: 'row',
      alignSelf: 'flex-end',
      width: 'auto',
    },
  },
  [ElementIdType.ALLOW]: {
    margin: '3px 5px',
    cursor: 'pointer',
    borderStyle: 'solid',
    borderWidth: '2px',
    color: '#F9F9F9',
    textTransform: 'none',
    borderColor: '#16a085',
    lineHeight: '1em',
    textDecoration: 'none',
    padding: '0 15px',
    backgroundColor: '#16a085',
    backgroundImage: 'none',
    fontSize: '15px',
    fontWeight: '700',
    borderRadius: '5px',
    overflow: 'visible',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    position: 'relative',
    verticalAlign: 'baseline',
    whiteSpace: 'nowrap',
    transition: 'border-color .2s, background-color .2s',
    height: '35px',
    '&::after, &::before': {
      content: 'none'
    },
    '&:hover': {
      color: '#F9F9F9',
    },
    '@media (max-width: 768px)': {
      fontSize: '14px',
    },
    '@media (max-width: 480px)': {
      display: 'block',
      width: '100%',
      padding: '3px',
      fontSize: '13px',
      margin: '2px 5px',
      height: 'auto',
    },
  },
  [ElementIdType.DENY]: {
    margin: '3px 5px',
    cursor: 'pointer',
    borderStyle: 'solid',
    borderWidth: '2px',
    textDecoration: 'none',
    color: '#16a085',
    textTransform: 'none',
    borderColor: '#fff',
    fontWeight: 400,
    backgroundColor: '#fff',
    backgroundImage: 'none',
    lineHeight: '1em',
    padding: '0 15px',
    fontSize: '15px',
    borderRadius: '5px',
    overflow: 'visible',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    position: 'relative',
    verticalAlign: 'baseline',
    whiteSpace: 'nowrap',
    transition: 'border-color .2s, background-color .2s',
    height: '35px',
    '&::after, &::before': {
      content: 'none'
    },
    '&:hover': {
      color: '#16a085',
    },
    '@media (max-width: 768px)': {
      fontSize: '14px',
    },
    '@media (max-width: 480px)': {
      width: '100%',
      display: 'block',
      padding: '3px',
      margin: '2px 5px',
      fontSize: '13px',
      height: 'auto',
    },
  },
  [ElementIdType.POWERED_BY]: {
    position: 'absolute' as 'absolute',
    bottom: '3px',
    fontSize: '10px',
    width: '100%',
  },
  [ElementIdType.POWERED_BY_TEXT]: {
    color: '#70787f',
    textDecoration: 'none',
    display: 'inline',
    fontSize: '10px',
    fontWeight: 400,
    transition: 'none',
    verticalAlign: 'baseline',
    letterSpacing: 'normal',
    position: 'relative',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.2',
    '&:link, &:visited, &:active': {
      color: '#70787f',
      textDecoration: 'none',
    },
    '&::after, &::before': {
      content: 'none'
    },
    '@media (max-width: 480px)': {
      fontSize: '9px',
    },
  }
};

export const topRtlStylesMap = {
  [ElementIdType.BACKDROP]: {
    display: 'none',
    width: '100%',
    height: '100%',
    zIndex: 99999999999,
    position: 'fixed',
    top: 0,
    left: 0,
  },
  [ElementIdType.FORM]: {
    position: 'fixed',
    lineHeight: '1.2',
    display: 'flex',
    direction: 'rtl',
    boxShadow: '0px 10px 40px 3px rgba(0, 0, 0, 0.3)',
    left: 0,
    top: 0,
    width: '100%',
    backgroundColor: '#fff',
    color: '#787878',
    padding: '15px 55px 15px 12px',
    textAlign: 'right',
    boxSizing: 'border-box',
    zIndex: 999999999999,
    fontFamily: 'sans-serif',
    '@media (max-width: 590px)': {
      lineHeight: '12px',
      fontSize: '12px',
      flexDirection: 'column',
      padding: '15px 12px 8px 10px',
    },
    '@media (max-width: 480px)': {},
  },
  [ElementIdType.ICON]: {
    width: '14px',
    height: '14px',
    display: 'inline-block',
    fontSize: '20px',
    position: 'absolute' as 'absolute',
    marginLeft: '15px',
    marginRight: 'auto',
    top: '24px',
    right: '20px',
    left: 'auto',
    '@media (max-width: 590px)': {
      top: '15px',
      left: 'auto',
      right: '30px',
    },
    '@media (max-width: 480px)': {
      top: '15px',
      left: 'auto',
      right: '20px',
    },
  },
  [ElementIdType.SVG]: {},
  [ElementIdType.DESCRIPTION]: {
    color: '#585858',
    width: '70%',
    alignSelf: 'stretch',
    textAlign: 'right',
    margin: 'auto',
    fontSize: '16px',
    lineHeight: '18px',
    '@media (max-width: 768px)': {
      fontSize: '14px',
      lineHeight: '16px',
    },
    '@media (max-width: 480px)': {
      fontSize: '13px',
      lineHeight: '13px',
      padding: '0 25px 5px 0',
      width: '80%',
    }
  },
  [ElementIdType.BUTTONS]: {
    width: '30%',
    textAlign: 'left',
    fontSize: '15px',
    '@media (max-width: 590px)': {
      display: 'flex',
      flexDirection: 'row',
      alignSelf: 'flex-end',
      width: 'auto',
    },
  },
  [ElementIdType.ALLOW]: {
    margin: '3px 5px',
    cursor: 'pointer',
    borderStyle: 'solid',
    borderWidth: '2px',
    color: '#F9F9F9',
    textTransform: 'none',
    borderColor: '#16a085',
    lineHeight: '1em',
    textDecoration: 'none',
    padding: '0 15px',
    backgroundColor: '#16a085',
    fontSize: '15px',
    fontWeight: '700',
    borderRadius: '5px',
    overflow: 'visible',
    whiteSpace: 'nowrap',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    position: 'relative',
    verticalAlign: 'baseline',
    transition: 'border-color .2s, background-color .2s',
    height: '35px',
    '&::after, &::before': {
      content: 'none'
    },
    '&:hover': {
      color: '#F9F9F9',
    },
    '@media (max-width: 768px)': {
      fontSize: '14px',
    },
    '@media (max-width: 480px)': {
      display: 'block',
      width: '100%',
      padding: '3px',
      fontSize: '13px',
      margin: '2px 5px',
      height: 'auto',
    },
  },
  [ElementIdType.DENY]: {
    margin: '3px 5px',
    cursor: 'pointer',
    borderStyle: 'solid',
    borderWidth: '2px',
    textDecoration: 'none',
    color: '#16a085',
    textTransform: 'none',
    borderColor: '#fff',
    fontWeight: 400,
    backgroundColor: '#fff',
    lineHeight: '1em',
    padding: '0 15px',
    fontSize: '15px',
    borderRadius: '5px',
    overflow: 'visible',
    whiteSpace: 'nowrap',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    position: 'relative',
    verticalAlign: 'baseline',
    transition: 'border-color .2s, background-color .2s',
    height: '35px',
    '&::after, &::before': {
      content: 'none'
    },
    '&:hover': {
      color: '#16a085',
    },
    '@media (max-width: 768px)': {
      fontSize: '14px',
    },
    '@media (max-width: 480px)': {
      width: '100%',
      display: 'block',
      padding: '3px',
      margin: '2px 5px',
      fontSize: '13px',
      height: 'auto',
    },
  },
  [ElementIdType.POWERED_BY]: {
    position: 'absolute' as 'absolute',
    bottom: '3px',
    fontSize: '10px',
    width: '100%',
  },
  [ElementIdType.POWERED_BY_TEXT]: {
    color: '#70787f',
    textDecoration: 'none',
    display: 'inline',
    fontSize: '10px',
    fontWeight: 400,
    transition: 'none',
    verticalAlign: 'baseline',
    letterSpacing: 'normal',
    position: 'relative',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.2',
    '&:link, &:visited, &:active': {
      color: '#70787f',
      textDecoration: 'none',
    },
    '&::after, &::before': {
      content: 'none'
    },
    '@media (max-width: 480px)': {
      fontSize: '9px',
    },
  }
};

export const poweredTextProperties = {
  href: 'https://pushpushgo.com/en/?utm_medium=form&utm_source=https://n.porannakawka.com/&utm_campaign=referal',
  target: '_blank'
};

export const svgProperties = {
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  width: '22',
  height: '22',
  viewBox: '0 0 32 32',
};

export const svgPath1 = {
  fill: '#585858',
  d: 'M17.629 8.567c0 0.9-0.729 1.629-1.629 1.629v0c-0.9 0-1.63-0.729-1.63-1.629v-3.716c0-0.9 0.73-1.63 1.63-1.63v0c0.9 0 1.629 0.729 1.629 1.63v3.716z',
};

export const svgPath2 = {
  fill: '#585858',
  d: 'M13.129 25.909c0 1.586 1.286 2.871 2.871 2.871s2.872-1.285 2.872-2.871v-0.404h-5.742v0.404z',
};

export const svgPath3 = {
  fill: '#585858',
  d: 'M26.246 20.096c0-0.161-0.121-0.292-0.27-0.292-1.423-0.224-3.537-0.582-3.769-4.41-0.603-9.938-5.54-10.107-6.206-10.090-0.668-0.017-5.605 0.152-6.207 10.090-0.232 3.828-2.346 4.187-3.77 4.41-0.148 0-0.269 0.131-0.269 0.292v3.793c0 0.011 0.002 0.024 0.003 0.036l-0.003 0.051h0.014c0.034 0.118 0.134 0.206 0.255 0.206h19.952c0.122 0 0.222-0.087 0.256-0.206h0.014l-0.003-0.051c0.002-0.012 0.003-0.024 0.003-0.036v-3.793z',
};

export const topAnimations = {
  '@keyframes subtleAppear': {
    from: { opacity: .2 },
    to: { opacity: 1 }
  },
  '@keyframes slideTop': {
    from: {
      opacity: .8,
      transform: 'translateY(-100%)'
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)'
    }
  },
  '@keyframes slideBottom': {
    from: {
      opacity: .5,
      transform: 'translateY(100%)'
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)'
    }
  },
  '@keyframes jumpTop': {
    '0%': {
      transform: 'translateY(-100%)',
      opacity: 0,
    },
    '60%': {
      transform: 'translateY(10%)',
      opacity: 1,
    },
    '80%': {
      transform: 'translateY(-5%)',
    },
    '100%': {
      transform: 'translate(0)',
    }
  },
  '@keyframes jumpBottom': {
    '0%': {
      transform: 'translateY(100%)',
      opacity: 0,
    },
    '60%': {
      transform: 'translateY(-5%)',
      opacity: 1,
    },
    '80%': {
      transform: 'translateY(5%)',
    },
    '100%': {
      transform: 'translate(0)',
    }
  },
};
