import * as React from 'react';
import './Authorization.scss';
import { t } from '../../base/helpers';
import { Link } from 'react-router-dom';

export const RegisterConfirmation = () => {
  return <div className='register-feedback-message'>
    <h1>{ t('register::Confirm your account') }</h1>
    <span className='icon-msg feedback-icon'/>
    <p>{ t("register::We've sent you an e-mail with the link to activate your account. It will be active for 60 minutes.") }</p>
    <div className="switch-to-login">
                    <span className="app-back">
                      { t('register::Back to') }
                      <Link className='app-register-button' to="/login">{ t('register::Login') }</Link>
                    </span>
    </div>
  </div>
};
