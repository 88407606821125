import { UseCase, API } from '@ppg/common';
import { IUpdateFormDisplay } from '../../interfaces';

export class UpdateFormDisplayRulesUseCase extends UseCase<IUpdateFormDisplay, void> {
  protected async execute({ websiteIntegrationId, delayTime, exitPopup, resubscribeTime }: IUpdateFormDisplay) {
    await API.HTTP.put(`wi/projects/${ API.HTTP.currentProjectId }/website-integrations/${ websiteIntegrationId }/plugins/web-push/time`,
      { delayTime, exitPopup, resubscribeTime })
  }
}

export const updateFormDisplayRulesUseCase = new UpdateFormDisplayRulesUseCase();
