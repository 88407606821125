import { API, UseCase } from '@ppg/common';
import { IImage } from '../../modelsMobx/Image';
import { PlatformType } from '../interfaces';

interface GetDashboardMassCampaignsCommand {
  projectId: string;
  platform: PlatformType;
  from: string;
  to: string;
}

interface CampaignRankingStatistics {
  clicked: number;
  delivered: number;
  ctr: number;
}

interface CampaignRankingData {
  id: string;
  title: string;
  content: string;
  redirectLink: string;
  sendDate: string;
  icon: IImage;
  statistics: CampaignRankingStatistics;
}

interface CampaignsStatistics {
  ctr: number;
  sentCampaigns: number;
  campaignRanking: CampaignRankingData[];
}

interface ProjectCampaignsStatisticsResult {
  campaignsStatistics: CampaignsStatistics;
}

export class GetDashboardMassCampaigns extends UseCase<GetDashboardMassCampaignsCommand, CampaignsStatistics> {
  protected async execute({ projectId, platform, from, to }: GetDashboardMassCampaignsCommand
  ): Promise<CampaignsStatistics> {
    if (projectId) {
      const { campaignsStatistics } = await API.HTTP.get<ProjectCampaignsStatisticsResult>(`statistics/v2/projects/${ projectId }/campaigns/all/statistics${ platform ? `/${ platform }` : '' }?from=${ from }&to=${ to }`);

      return campaignsStatistics;
    }
  }
}

export const getDashboardMassCampaigns = new GetDashboardMassCampaigns();
