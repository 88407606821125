import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { NavBarUserMenu } from './NavBarUserMenu';

export type DropdownRefs = {
  dropdownButton: any;
  dropdownAvatar : any;
  dropdownArrow : any;
}

@observer
export class NavBarUser extends React.Component {

  @observable private isUserMenuOpened: boolean = false;

  private readonly dropdownButton : any;
  private readonly dropdownAvatar :  any
  private readonly dropdownArrow : any;
  private dropdownRefs: DropdownRefs;

  componentDidMount() {
    this.dropdownRefs = {
      dropdownButton: this.dropdownButton,
      dropdownAvatar: this.dropdownAvatar,
      dropdownArrow: this.dropdownArrow,
    };
  }

  constructor(props) {
    super(props);
    this.dropdownButton = (React as any).createRef();
    this.dropdownAvatar = (React as any).createRef();
    this.dropdownArrow = (React as any).createRef();
  }

  private toggleUserMenu(): () => void {
    return () => {
      this.isUserMenuOpened = !this.isUserMenuOpened;
    }
  }

  private onProfileClickHandler(event):void {
    event.stopPropagation()
    this.isUserMenuOpened = !this.isUserMenuOpened;
  }

  private onProfileChildClickHandler = (event):void => {
    event.stopPropagation();
    this.isUserMenuOpened = !this.isUserMenuOpened;
  }


  public render() {
    return (
      <div className="navigation-profile" id="t-navbar-user">
        <div className="navigation-profile-trigger" onClick={ e => this.onProfileClickHandler(e) } ref={this.dropdownButton}>
          <div className="avatar" onClick={event => this.onProfileChildClickHandler(event)} ref={this.dropdownAvatar} />
          <span className="icon-arrow-down size8 m10l" onClick={event => this.onProfileChildClickHandler(event)} ref={this.dropdownArrow}/>
        </div>

        <NavBarUserMenu isUserMenuOpened={ this.isUserMenuOpened }
                        toggleUserMenu={ this.toggleUserMenu() }
                        dropdownRefs={this.dropdownRefs}
        />
      </div>
    );
  }
}
