import { API, UseCase } from '@ppg/common';
import {
  IGetSubscribersActivityHistogramResult,
  IOrganizationDashboardRequestBody,
} from '../../../interfaces';
import { getListQueryParam, getPlatformTypeParam, getRangeDateParam, getTimezoneParam } from '../../../helpers';
import { IListQueryOptions } from '../../../../interfaces/IListQueryOptions';

interface IGetSubscribersActivityHistogramRequest extends IOrganizationDashboardRequestBody, IListQueryOptions {
}

export class GetSubscribersActivityHistogramUseCase extends UseCase<IGetSubscribersActivityHistogramRequest, IGetSubscribersActivityHistogramResult> {
  protected async execute({
                            organization,
                            platform,
                            from,
                            to,
                            limit,
                            offset,
                            timezone
                          }: IGetSubscribersActivityHistogramRequest) {
    const endpoint = `statistics/organizations/${ organization }/subscribers/activity/histogram`
      + getPlatformTypeParam(platform) + getRangeDateParam(from, to) + getTimezoneParam(timezone) + getListQueryParam(offset, limit);

    return API.HTTP.get<IGetSubscribersActivityHistogramResult>(endpoint);
  }
}

