import { property } from '@ppg/common';
import { WebsiteIntegrationRelated } from '../../stores/project/WebsiteIntegrationRelated';
import { updateFormDisplayRulesUseCase } from '../../useCases/website-integration';

export interface IPopupDisplaySettings {
  exitPopup: boolean;
  delay: number;
  reopenTime: number;
}

export class PopupDisplaySettings extends WebsiteIntegrationRelated implements IPopupDisplaySettings {
  @property() public exitPopup: boolean;
  @property() public delay: number;
  @property() public reopenTime: number;

  constructor(popupDisplaySettings: IPopupDisplaySettings) {
    super();
    this.exitPopup = popupDisplaySettings.exitPopup;
    this.delay = popupDisplaySettings.delay;
    this.reopenTime = popupDisplaySettings.reopenTime;
  }

  static createSettings(popupDisplaySettings?: IPopupDisplaySettings) {
    if (!popupDisplaySettings) {
      return PopupDisplaySettings.createDefault();
    } else {
      return new PopupDisplaySettings(popupDisplaySettings);
    }
  }

  static createDefault() {
    return new PopupDisplaySettings({
      reopenTime: 0,
      exitPopup: false,
      delay: 0,
    })
  }

  public async save(): Promise<void> {
    await updateFormDisplayRulesUseCase.exec({
      websiteIntegrationId: this.websiteIntegrationId,
      resubscribeTime: Number(this.reopenTime),
      exitPopup: this.exitPopup,
      delayTime: Number(this.delay)
    })
  }
}
