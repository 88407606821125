import * as React from 'react';
import * as lodash from 'lodash';
import { Model as BackboneModel, Collection, ModelSetOptions, ModelDestroyOptions } from 'backbone';
import { IMap, Partial } from '../interfaces';
import { Message, MessageType } from './Message';
import { Attribute } from './Attribute';
import { apiManager, IApiManagerResponse } from './apiManager';
import { FindQueryBuilder, CountQueryBuilder } from './QueryBuilder';
import { IRelation, RelationAssignFn } from './IRelation';
import { IAnyModel } from './IAnyModel';
import { IModelConstructor } from './IModelConstructor';
import { AttributeModel } from './AttributeModel';
import { AbstractValidator } from './index';

export class Model<TAttributes extends Object, TResponseAttributes> extends BackboneModel {
  protected _shouldResetOnProjectChange: Boolean = false;
  private _messages: IMap<Message[]> = {};
  private _attributesMap: IMap<Attribute<any>> = {};
  protected serverAttributes: TAttributes;
  private _relations: IMap<IRelation>; // don't add initial value
  private validators: Array<(model: BackboneModel) => AbstractValidator>;
  private _validators: AbstractValidator[];

  constructor(attributes?: Partial<TAttributes>, options?: any) {
    super(attributes, options);
    this.listenTo(this, 'change', () => {
      this.validate(false);
    });
    this.serverAttributes = lodash.cloneDeep<TAttributes>(this.attributes as any);
  }

  public set<TKey extends keyof TAttributes>(attributeName: TKey, value: TAttributes[TKey], options?: ModelSetOptions): this;
  public set(key: string, value: any, options?: ModelSetOptions): this;
  public set(obj: Partial<TAttributes>, options?: ModelSetOptions): this;
  public set(obj: any, options?: ModelSetOptions): this;
  public set(): this {
    return BackboneModel.prototype.set.apply(this, arguments);
  }

  public static resourceUrl(): string {
    throw new Error('Static method "resourceUrl" is not implemented.');
  }

  public static findOne<TModel extends Model<any, any>>(id: string | number): Promise<TModel> {
    const ClassName = this as any;
    return apiManager
      .get<{}>(`${ this.resourceUrl() }/${ id }`)
      .then(res => new ClassName().setFromResponse(res.data));
  }

  public static find<TModel extends Model<any, any>>(): FindQueryBuilder<TModel> {
    return new FindQueryBuilder<TModel>(this as any);
  }

  public static count(): CountQueryBuilder<any> {
    return new CountQueryBuilder(this as any);
  }

  protected resourceUrl(): string {
    return lodash.invoke(this.constructor, 'resourceUrl');
  }

  /**
   * If arguments has -attribute, will be omit in serverAttributes
   * @param {string} attributes
   * @returns {Promise<this>}
   */
  public savePartial(...attributes: string[]): Promise<this> {
    const toOmit = attributes
      .filter(i => `${ i[0] }` === '-')
      .map(i => i.replace('-', ''));

    const toSave = attributes
      .filter(i => `${ i[0] }` !== '-');

    const data: TResponseAttributes = lodash.pick(this.toJSON(), toSave) as TResponseAttributes;
    const serverAttributes: Partial<TAttributes> = lodash.omit(this.serverAttributes, toOmit);

    return apiManager
      .put<TResponseAttributes>(this.createUrl('update'), lodash.extend({}, serverAttributes, data))
      .then(res => this.setFromResponse(res.data));
  }


  public static create(attrs?: any) {
    return new this(attrs).save();
  }

  public setFromResponse(attrs: TResponseAttributes): this {
    const attributes = this.parse(attrs);
    this.set(attributes, { silent: true });
    this.serverAttributes = lodash.cloneDeep(attributes);
    this.trigger('change', this, this.collection);
    return this;
  }

  public save(): any {
    return this.isNew() ? this.insert() : this.update();
  }

  public destroy(options?: ModelDestroyOptions): any {
    return this.isNew() ? this.destroyLocally(options) : this.destroyRemotely(options);
  }

  protected destroyRemotely(options?: ModelDestroyOptions): Promise<this> {
    return apiManager
      .del<TResponseAttributes>(this.createUrl('delete'))
      .then(res => this.setFromResponse(res.data))
      .then(() => this.destroyLocally(options));
  }

  protected destroyLocally(options?: ModelDestroyOptions): Promise<this> {
    this.trigger('destroy', this, this.collection, options);
    this.stopListening();
    return Promise.resolve(this);
  }

  protected insert(): Promise<this> {
    return apiManager
      .post<TResponseAttributes>(this.createUrl('create'), this.toJSON())
      .then(res => this.setFromResponse(res.data));
  }

  protected update(): Promise<this> {
    return apiManager
      .put<TResponseAttributes>(this.createUrl('update'), this.toJSON())
      .then(res => this.setFromResponse(res.data));
  }

  protected createUrl(action: 'create' | 'update' | 'delete' | 'get' | 'list'): string {
    if (['create', 'list'].indexOf(action) !== -1) {
      return this.resourceUrl();
    }
    return `${ this.resourceUrl() }/${ this.id }`;
  }

  public onChangeSetter<TKey extends keyof TAttributes>(attribute: TKey): (e: React.FormEvent<HTMLInputElement>) => void {
    return (e: React.FormEvent<HTMLInputElement>): void => {
      this.set(attribute, e.currentTarget.value as any);
    };
  }

  public static hasAll(ModelConstructor: IModelConstructor<IAnyModel>, assignFn?: RelationAssignFn): PropertyDecorator {
    return (modelPrototype: IAnyModel, assignToProperty: string) => {
      modelPrototype._relations = modelPrototype._relations || {};

      modelPrototype._relations[assignToProperty] = { type: 'has_all', ModelConstructor, assignFn, assignToProperty };
    };
  }

  public static hasMany(ModelConstructor: IModelConstructor<IAnyModel>, remoteKey: string, localProperty: string, assignFn?: RelationAssignFn): PropertyDecorator {
    return (modelPrototype: IAnyModel, assignToProperty: string) => {
      modelPrototype._relations = modelPrototype._relations || {};

      modelPrototype._relations[assignToProperty] = {
        type: 'has_many',
        ModelConstructor,
        remoteKey,
        localProperty,
        assignFn,
        assignToProperty
      };
    };
  }

  public static hasOne(ModelConstructor: IModelConstructor<IAnyModel>, localProperty: string, assignFn?: RelationAssignFn, cached?: boolean): PropertyDecorator {
    return (modelPrototype: IAnyModel, assignToProperty: string) => {
      modelPrototype._relations = modelPrototype._relations || {};

      modelPrototype._relations[assignToProperty] = {
        type: 'has_one',
        ModelConstructor,
        localProperty,
        assignFn,
        assignToProperty,
        cached
      };
    };
  }

  /**
   * Get attribute as separate object.
   */
  public getAttribute<T>(attribute: string, deep: boolean = false): Attribute<T> {
    if (!this._attributesMap[attribute]) {
      this._attributesMap[attribute] = new Attribute<T>(this, attribute, deep);
    }
    return this._attributesMap[attribute];
  }

  private getValidators(): AbstractValidator[] {
    if (!this._validators) {
      this._validators = lodash.map(this.validators, (createValidator) => createValidator(this as any));
    }
    return this._validators;
  }

  /**
   * Returns true if all model attributes are valid
   *
   * @property validateAll {boolean} validate all attributes,
   *           in other case - validate only changed attributes
   */
  //@ts-ignore
  public validate(validateAll: boolean = false): void {
    this._messages = {};

    lodash.each(this.getValidators(), (v: AbstractValidator) => {
      const isValid = v.validate();
      const changedAttributes = this.changedAttributes();
      const isChangedField = lodash.keys(changedAttributes).indexOf(v.getPropertyName()) > -1;

      if ((!isValid && validateAll) || ((!isValid && !validateAll && isChangedField) || (!isValid && !changedAttributes))) {
        const messageWarning = v.isWarning();
        this.addMessage(v.getPropertyName(), messageWarning ? v.getWarningMessage() : v.getErrorMessage(), messageWarning ? 'WARNING' : 'ERROR');
      }
    });
  }

  public areFieldsValid(fields: string[]): boolean {
    return lodash.isEmpty(lodash.pick(this._messages, fields));
  }

  public isValid(): boolean {
    return !this.hasAnyErrors();
  }

  public getServerAttribute<T>(key: string): T {
    return lodash.result<T>(this.serverAttributes, key);
  }

  public wasChanged(...attributes: string[]) {
    if (!lodash.isEmpty(attributes)) {
      return lodash.some(attributes, attribute => {
        return !lodash.isEqual(this.get(attribute), this.getServerAttribute(attribute));
      });
    }

    // @todo: test this method
    return lodash.isEqual(this.attributes, this.serverAttributes);
  }

  /**
   * This method should be used for checking only simple types like: string, number, boolean
   */

  public differInAttributes(model: BackboneModel, attributes: string[]): boolean {
    return !lodash.isEqual(lodash.pick(model, attributes), lodash.pick(this, attributes));
  }

  public restore() {
    this.set(this.serverAttributes);
  }

  public defaults(): Partial<TAttributes> {
    return {} as TAttributes;
  }

  public parse(data: TResponseAttributes): TAttributes {
    return super.parse(data) as TAttributes;
  }

  public toJSON(): TResponseAttributes {
    const json = lodash.clone(this.attributes);
    for (let attr in json) {
      if ((json[attr] instanceof Model) || (json[attr] instanceof Collection)) {
        json[attr] = json[attr].toJSON();
      }
    }
    return json as TResponseAttributes;
  }

  public toCopy(): TResponseAttributes {
    return this.toJSON();
  }

  public hasErrors(attribute: string): boolean {
    return lodash.some(this._messages[attribute], (messages: Message) => messages.isError());
  }

  public hasAnyErrors(): boolean {
    return lodash.some(this._messages, (messages: Message[], attribute: string) => this.hasErrors(attribute));
  }

  public getErrors(attribute: string): Message[] {
    return lodash.filter(this._messages[attribute], {
      type: Message.ERROR
    });
  }

  public getMessages(attribute: string): Message[] {
    return this._messages[attribute] || [];
  }

  protected addMessage(attribute: string, message: string, type: MessageType = Message.ERROR): this {
    this._messages[attribute] = (this._messages[attribute] || []).concat([
      new Message(type, message)
    ]);
    return this;
  }

  public reset({ silent = false } = {}): void {
    Object.keys(this.attributes).forEach((attribute: string) => this.unset(attribute, { silent: true }));
    this.set(this.defaults(), { silent: true });
    if (silent === true)
      this.trigger('change', this, this.collection);
  }

  public getSubModel(attribute: string): AttributeModel {
    return new AttributeModel(this as IAnyModel, attribute);
  }

  /**
   * @deprecated - DO NOT USE
   */
  public fetchRelated(): Promise<void>;
  public fetchRelated(...relations: string[]): Promise<void>;
  public fetchRelated(...args: any[]): Promise<void> {
    // @todo: add cache system
    const relations = (!lodash.isEmpty(args) ? args : Object.keys(this._relations || {})) as string[];

    return relations.reduce(
      (res: Promise<void>, relation: string): Promise<any> => res
        .then(() => this
          .fetchRelation(relation)
          .then(() => this.trigger('change', this, this.collection))
        ),
      Promise.resolve()
    );
  }

  public refresh(withDependencies: boolean = false): Promise<this> {
    return apiManager
      .get<TResponseAttributes>(this.createUrl('get'))
      .then(res => this.setFromResponse(res.data))
      .then(() => withDependencies && this.fetchRelated())
      .then(() => this);
  }

  private fetchRelation(relation: string): Promise<void> {
    const rel: IRelation = this._relations[relation];

    if (!rel) return Promise.reject(new Error(`Relation ${ relation } not found`));

    switch (rel.type) {
      case 'has_all':
        return this.fetchAll(relation, rel);
      case 'has_one':
        return this.fetchOne(relation, rel);
      case 'has_many':
        return this.fetchMany(relation, rel);
    }

    return Promise.reject(new Error(`Invalid relation ${ JSON.stringify(rel) }.`));
  }

  private fetchAll(relation: string, { ModelConstructor, assignFn }: IRelation): Promise<void> {
    return apiManager
      .get(ModelConstructor.resourceUrl() + '?limit=250') // todo Norbert ograc inaczej limit
      .then(({ data }: IApiManagerResponse<Object[]>) => {
        const relatedRecords = data.map((rawRecord) => new ModelConstructor().setFromResponse(rawRecord));

        if (assignFn) {
          return assignFn(this as IAnyModel, relatedRecords);
        }

        lodash.assign(this, {
          [relation]: relatedRecords
        });
      });
  }

  // @todo basia rozkminić cache

  private cache = {};

  private fetchOne(relation: string, { ModelConstructor, assignFn, localProperty, cached }: IRelation): Promise<void> {
    const id: string = this.get(localProperty);
    if (!id)
      return Promise.resolve();

    if (cached && this.cache[id])
      return Promise.resolve(this.cache[id]);

    return apiManager
      .get(`${ ModelConstructor.resourceUrl() }/${ id }`)
      .then(({ data }: IApiManagerResponse<Object[]>) => {
        if (cached)
          this.cache[id] = data;

        const relatedRecord = new ModelConstructor().setFromResponse(data)

        if (assignFn) {
          return assignFn(this as IAnyModel, relatedRecord);
        }

        lodash.assign(this, {
          [relation]: relatedRecord
        });
      });
  }

  private fetchMany(relation: string, { ModelConstructor, remoteKey, localProperty, assignFn }: IRelation): Promise<void> {
    const localProperties: string[] = lodash.compact([].concat(this.get(localProperty)));

    return ModelConstructor.find()
      .where(remoteKey).in(localProperties)
      .all()
      .then<void>((relatedRecords: IAnyModel[]) => {
        if (assignFn) {
          return assignFn(this as IAnyModel, relatedRecords);
        }

        lodash.assign(this, {
          [relation]: relatedRecords
        });
      });
  }

  public result<TResult>(key: string, defaults?: TResult): TResult {
    return lodash.result<TResult>(this.toJSON(), key, defaults);
  }

  public results(keys: string[], separator: string = ', '): string {
    return lodash
      .chain(keys)
      .map(key => this.result(key, ''))
      .compact()
      .value()
      .join(separator);
  }

  public static getRelations(...relations: string[]): IRelation[] {
    this.prototype._relations = this.prototype._relations || {};
    return relations.reduce((res: IRelation[], relationName: string) => {
      if (this.prototype._relations[relationName]) {
        return res.concat(this.prototype._relations[relationName]);
      }
      return res;
    }, []);
  }
}
