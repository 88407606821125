import * as React from 'react';
import '../../User/Account/Settings/Settings.scss';
import PPGLogo from '../../../assets/images/ppg-logo.svg';
import { t } from '../../../base/helpers';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { LinkButton } from '@ppg/styled';
import {PaymentsRoutesTypes} from '../../../routes/paymentsRoutes';

@observer
export class PaymentsConfirm extends React.Component {
  @observable isLoading: boolean = false;

  public render() {
    if (this.isLoading) {
      return null;
    }

    return <div className='success-request-wrapper'>
      <div className='success-request-background'>
        <div className="success-request-container">
          <img src={ PPGLogo } alt="PushPushGo" className="ppg-logo"/>
          <div className="success-request-pannel">
            <p className="success-feedback-message">
              <span className='feedback-title'>{ t('We are waiting for payment information') }</span>
              <span className='feedback-desc'>{ t('Once the transaction is confirmed, you will get the invoice to the e-mail address provided in the settings.') }</span>
            </p>

            <LinkButton content={ t('Go back to application') } path={ PaymentsRoutesTypes.INVOICES_LIST }/>
          </div>
        </div>
      </div>
    </div>;
  }
}
