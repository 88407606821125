import { property } from '@ppg/common';
import { Segment } from '../../modelsMobx/segmentation/Segment';
import { action, observable } from 'mobx';
import { IGetSegmentsResponse } from '../../useCases/core/segments/GetSegmentsUseCase';
import { Utils } from '../utils';
import { SortSegmentsOptions } from './SegmentsStore';
import { ProjectRelated } from '../ProjectRelatedStore';
import { getReadySegmentsUseCase, getSegmentUseCase } from '../../useCases/core';

export class SegmentsListStore extends ProjectRelated {
  static SEGMENTS_LIMIT = 20;

  @property()
  public segments: Segment[] = [];

  @property()
  public total = 0;

  @property()
  public offset = 0;

  @observable
  isSegmentsLoading = true;

  public async fetchSegments(): Promise<IGetSegmentsResponse> {
    return await getReadySegmentsUseCase.exec({
      projectId: this.projectId,
      name: "",
      sortBy: SortSegmentsOptions.NEWEST,
      limit: SegmentsListStore.SEGMENTS_LIMIT,
      offset: this.offset
    });
  }

  @action
  public async getSegments(): Promise<void> {
    await this.executeWithSegmentsLoader(async () => {

      const { data, metadata } = await this.fetchSegments();

      this.segments = data.map(segment => Segment.createSegment(segment));
      this.total = metadata.total;
    });
  }

  @action
  public async getMoreSegments(): Promise<void> {
    await this.executeWithSegmentsLoader(async () => {
      this.offset = this.offset + SegmentsListStore.SEGMENTS_LIMIT;

      const { data, metadata } = await this.fetchSegments();

      const segments = data.map(segment => Segment.createSegment(segment));
      this.total = segments.length !== 0 ? metadata.total : this.segments.length;
      this.segments = this.segments.concat(segments);
    });
  }

  public showLoadMoreSegmentsBtn(): boolean {
    const isOverPageLimit = this.segments.length >= SegmentsListStore.SEGMENTS_LIMIT;
    const currentSegmentsLengthIsNotOverTotal = this.segments.length < this.total;

    return isOverPageLimit && currentSegmentsLengthIsNotOverTotal;
  }

  @action
  public async getSegment(segmentId: string): Promise<Segment> {
    const segment = await getSegmentUseCase.exec({
      projectId: this.projectId,
      segment: segmentId
    });

    return Segment.createSegment(segment);
  }

  private executeWithSegmentsLoader<T = any>(callback: () => Promise<T>) {
    return Utils.executeWithLoader(
      callback,
      () => this.isSegmentsLoading = true,
      () => this.isSegmentsLoading = false,
      (err) => {
        console.error(err);
        this.isSegmentsLoading = false;
      }
    );
  }

  public setDefaultValues(): void {
  }

}
