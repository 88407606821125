import { API, UseCase } from "@ppg/common";
import { ISegmentConditionPlainObject } from './CreateSegmentUseCase';

interface IUpdateSegmentDraftRequest {
  segment: string;
  name: string;
  conditions: ISegmentConditionPlainObject;
}

export class UpdateSegmentDraftUseCase extends UseCase<IUpdateSegmentDraftRequest, void> {
  protected async execute({segment, name, conditions}: IUpdateSegmentDraftRequest) {
    return API.HTTP.put<void>(`core/projects/${API.HTTP.currentProjectId}/segment/${segment}/draft`, {name, conditions})
  }
}

export const updateSegmentDraftUseCase = new UpdateSegmentDraftUseCase()
