import * as React from 'react';
import {Input, Select, Loader, Autocomplete, DateTimePicker, Button} from '@ppg/styled';
import { inject, observer } from 'mobx-react';
import { t } from '../../../base/helpers';
import { ProjectStore } from '../../../stores/project/ProjectStore';
import { AccessProject } from '../../../modelsMobx/AccessProject';
import { observable } from 'mobx';

interface IAccessKeyManagerKeysModalProps {
  newAccessProject: AccessProject;
  projectStore?: ProjectStore;
}

@inject('projectStore')
@observer
export class AccessManagerShareProjectModal extends React.Component<IAccessKeyManagerKeysModalProps> {

  @observable isLoading: boolean = true;

  public async componentDidMount() {
    this.props.projectStore.fetchProjects()
      .finally(() => this.isLoading = false);
  }

  public render() {
    const { newAccessProject, projectStore } = this.props;

    if (this.isLoading) {
      return <Loader/>
    }

    return (
      <>
        <div className="m5b">{ t('user/access-manager/share::User E-Mail') }</div>
        <Autocomplete
          value="username"
          availableItems={ [] }
          selectedItems={ newAccessProject.selectedUsersAutocomplete }
          onRemove={ (user) => newAccessProject.removeUser(user.username) }
          onCreate={ (user) => newAccessProject.addUser(user) }
          onSelect={ (user) => newAccessProject.addUser(user) }
          placeholder={t('user/access-manager/share::Type e-mail to share')}
        />
        <div className="m5b">{ t('user/access-manager/share::Projects') }</div>
        <Button onClick={ () => newAccessProject.selectAllProjects(projectStore.userOwnedProjects as any[]) }
                icon={ 'plus-badge' }
                iconLeft={ true }
                content={ t('Check All') }
                size={ 'medium' }/>

        <Button onClick={ () => newAccessProject.deselectAllProjects(projectStore.userOwnedProjects as any[]) }
                icon={ 'minus-badge' }
                iconLeft={ true }
                content={ t('Uncheck All') }
                size={ 'medium' }/>

        <Autocomplete
          value={ 'name' }
          availableItems={ projectStore.userOwnedProjects }
          selectedItems={ newAccessProject.selectedProjects }
          onRemove={ (project) => newAccessProject.removeProject(project) }
          onSelect={ (project) => newAccessProject.addProject(project) }
          emptyAvailableItemsMessage={t('user/access-manager/share::There are no available projects')}
          placeholder={t('user/access-manager/share::Search project by name...')}
        />

      </>
    );
  }
}
