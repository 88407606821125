import * as React from 'react';
import { t } from '../../../../base/helpers';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from '../../../../stores/project/ProjectStore';

interface IPaymentsDataInfoProps {
  projectStore?: ProjectStore;
}

@inject('projectStore')
@observer
export class InvoiceDetailsInfo extends React.Component<IPaymentsDataInfoProps> {
  public render() {
    const { projectStore } = this.props;
    if (projectStore.hasProjects && !projectStore.hasOwnedProjects) {
      return <div className="alert-block alert--warning">{ t('You\'re changing the data of your own account, not the main one.') }</div>;
    }

    return null;
  }
}
