import { PushAction } from "../PushAction";
import {
  CampaignDirectionType,
  IAppendUTMs,
  ICollapse,
  IDefaultTitleValue,
  IDirectionValue,
  IOmitCappingEnabled,
  IProjectValues,
  IPushActionValue,
  IPushPreview,
  IPushScope,
  IRedirectLinkValue,
  IRequireInteractionValue,
  ISetMetadataImage,
  IStartExpireTimeValues,
  ITestPush,
  ITTLRangeResponse,
  PushSendType,
} from "./interfaces";
import {
  isCorrectRedirectLink,
  isNotEmpty,
  property,
  PropertyHandler,
} from "@ppg/common";
import { Image, ImageFormatType, ImageType } from "../Image";
import { action, computed } from "mobx";
import { api, canCompileTest } from "../../base/helpers";
import { readMetaDataUseCase } from "../../useCases/core";
import { imageStore, projectImageStore } from "../../stores";
import { CAMPAIGN_ACTION_LIMIT, dummyImage } from "../../constants";
import * as DummyIcon from "../../assets/images/dummy-icon.png";
import dayjs from "dayjs";
import { Render } from "../../components/commonMobX";
import { IAction } from "../../useCases/core/campaigns/interfaces";
import { RedirectLink } from "../../components/PushCampaignCreator/RedirectLink";

interface IPushCampaignContent {
  title: string;
  content: string;
  icon: Image;
  image: Image;
  redirectLink: string;
  actions: PushAction[];
  direction: CampaignDirectionType;
  requireInteraction: boolean;
  omitCapping: boolean;
  sendType: PushSendType;
  sendDate: Date;
  expireDate: Date;
  delayTime: number;
}

export class PushCampaignContent
  extends PropertyHandler
  implements IPushCampaignContent
{
  @property([isNotEmpty()]) public title: string = "";
  @property([isNotEmpty()]) public content: string = "";
  @property() public icon: Image = null;
  @property() public image: Image = null;
  @property([isCorrectRedirectLink()]) public redirectLink: string = "";
  @property() public actions: PushAction[] = [];
  @property() public direction: CampaignDirectionType =
    CampaignDirectionType.AUTO;
  @property() public requireInteraction: boolean = false;
  @property() public sendType: PushSendType = PushSendType.NOW;
  @property() public expireDate: Date = null;

  @property() public delayTime: number = 0;
  @property() public isDelayTimeValid: boolean = true;

  @property() public sendDate: Date = null;
  @property() public isSendDateValid: boolean = true;

  @property() public omitCapping: boolean = false;
  @property() public omitCappingEnabled: boolean = false;

  @property() public _scope: Record<string, string> = {};

  public static safariCollapse: ICollapse = { title: 22, content: 24 };
  public static campaignCollapse: ICollapse = { title: 50, content: 120 };
  public static imageCropWidth: number = 240;
  public static imageCropHeight: number = 360;
  public static MAX_TTL = 259200;
  public static MIN_TTL = 3600;
  public static MAX_DELAY_TIME = 10;
  public static characterLimitTitle = 50;
  public static characterLimitMessage = 120;
  public static characterLimitActionTitle = 50;

  constructor(
    title,
    content,
    icon,
    image,
    redirectLink,
    actions,
    direction,
    requireInteraction,
    omitCapping,
    sendDate,
    expireDate,
    delayTime
  ) {
    super();
    this.title = title;
    this.content = content;
    this.icon = icon ? this.convertIconURLToImage(icon) : null;
    this.image = image ? this.convertImageURLToImage(image) : null;
    this.redirectLink = redirectLink;
    this.actions = actions
      ? actions.map((action) => new PushAction(action))
      : [];
    this.direction = direction;
    this.requireInteraction = requireInteraction;
    this.omitCapping = omitCapping;
    this.sendDate = sendDate ? new Date(sendDate) : null;
    this.expireDate = expireDate ? new Date(expireDate) : null;
    this.sendType = dayjs(sendDate).isAfter(dayjs(), "minute")
      ? PushSendType.SCHEDULE
      : PushSendType.NOW;
    this.delayTime = this.getDelayTimeInHours(delayTime);
  }

  public getDTO() {
    return {
      title: this.title,
      content: this.content,
      icon: this.getIconURL ?? "",
      image: this.getImageURL ?? "",
      redirectLink: this.redirectLink,
      actions: this.getActions(),
      direction: this.direction,
      sendDate: this.sendDate ? this.sendDate.toISOString() : "",
      expireDate: this.expireDate ? this.expireDate.toISOString() : "",
      delayTime: this.getDelayTimeInSeconds,
    };
  }

  public getDefaultOptionsDTO() {
    return {
      forceImagesOnMobile: true,
      requireInteraction: this.requireInteraction,
      omitCapping: this.omitCapping,
      useLogoAsIcon: true,
    };
  }

  public setProjectValues({
    isDirectionRtl,
    pushActions,
    requireInteraction,
    redirectLink,
    utm,
    omitCappingEnabled,
    isDefaultTitleEnabled,
    defaultTitle,
  }: IProjectValues): void {
    this.setDirectionSetting({ isDirectionRtl });
    this.setStartPushActions({ pushActions });
    this.setStartRequireInteraction({ requireInteraction });
    this.setStartRedirectLink({ redirectLink, utm });
    this.setStartOmitCappingEnabled({ omitCappingEnabled });
    this.setDefaultTitleValue({ isDefaultTitleEnabled, defaultTitle });
  }

  public setProjectValuesEditCampaign(omitCappingEnabled: boolean) {
    this.setStartOmitCappingEnabled({ omitCappingEnabled });
  }

  @action
  private setDirectionSetting({ isDirectionRtl }: IDirectionValue): void {
    this.direction = isDirectionRtl
      ? CampaignDirectionType.RTL
      : CampaignDirectionType.AUTO;
  }

  @action
  private setStartPushActions({ pushActions }: IPushActionValue): void {
    if (this.hasActions) return;
    const actions = pushActions.map((a) => new PushAction(a));
    this.actions = this.actions.concat([...actions]);
  }

  @action
  private setStartRequireInteraction({
    requireInteraction,
  }: IRequireInteractionValue): void {
    this.requireInteraction = requireInteraction;
  }

  @action
  private setStartRedirectLink({
    redirectLink,
    utm,
  }: IRedirectLinkValue): void {
    this.redirectLink = this.addProjectUTMs({ utm, link: redirectLink });
  }

  @action
  private setStartOmitCappingEnabled({
    omitCappingEnabled,
  }: IOmitCappingEnabled): void {
    this.omitCappingEnabled = omitCappingEnabled;
  }

  @action
  private setDefaultTitleValue({
    isDefaultTitleEnabled,
    defaultTitle,
  }: IDefaultTitleValue): void {
    this.title = isDefaultTitleEnabled ? defaultTitle : "";
  }

  public addProjectUTMs = ({ utm, link }): string => {
    let parsedUrl;

    try {
      if (canCompileTest(link)) {
        return link;
      }

      parsedUrl = new URL(link);

      if (!utm.disabled) {
        parsedUrl = this.appendUTMs({
          url: parsedUrl,
          utm,
        });
      }
    } catch (error) {
      console.error(error);
    }

    return this.encodeURIWithNunjucks(`${parsedUrl}`);
  };

  public encodeURIWithNunjucks(parsedUrl: string): string {
    return parsedUrl.replace(/%7B/g, "{").replace(/%7D/g, "}");
  }

  public appendUTMs = ({ url, utm }: IAppendUTMs): URL => {
    if (!url.searchParams.has("utm_medium") && utm.isMediumOn)
      url.searchParams.append("utm_medium", utm.medium);

    if (!url.searchParams.has("utm_source") && utm.isSourceOn)
      url.searchParams.append("utm_source", utm.source);

    if (!url.searchParams.has("utm_campaign") && utm.isCampaignOn)
      url.searchParams.append("utm_campaign", utm.campaign);

    if (utm.customUtms.length > 0) {
      utm.customUtms.forEach((utm) => {
        !url.searchParams.has(`utm_${utm.parameter}`) &&
          url.searchParams.append(`utm_${utm.parameter}`, utm.value);
      });
    }

    return url;
  };

  public hasDefaultUTMCampaignName(utmDisabled: boolean): boolean {
    //@ts-ignore
    const redirectLink = new RedirectLink();

    if (canCompileTest(this.redirectLink)) {
      return false;
    }

    if (redirectLink.validUrl(this.redirectLink)) {
      const redirectLinkUrl =
        redirectLink.validUrl(this.redirectLink) && new URL(this.redirectLink);
      const utmCampaignName = redirectLinkUrl.searchParams.get("utm_campaign");

      return !utmDisabled && utmCampaignName === "CampaignName";
    }

    return false;
  }

  @action
  public async setMetadataForPush(): Promise<void> {
    const linkWithoutUtm = this.redirectLink.split("?")[0];
    const data = await readMetaDataUseCase.exec({ url: linkWithoutUtm });
    this.title = this.title === "" ? data["title"] : this.title;
    this.content = this.content === "" ? data["description"] : this.content;

    if (!this.image) {
      await this.setImageFromMetadata({
        url: data["image"],
        type: ImageType.PUSH_IMAGE,
      });
    }
  }

  @action
  public async getDataFromUrl(): Promise<void> {
    const linkWithoutUtm = this.redirectLink.split("?")[0];
    const data = await readMetaDataUseCase.exec({ url: linkWithoutUtm });
    this.title = data["title"];
    this.content = data["description"];

    await this.setImageFromMetadata({
      url: data["image"],
      type: ImageType.PUSH_IMAGE,
    });
  }

  @action
  public setImageFromMetadata = async ({
    url,
    type,
  }: ISetMetadataImage): Promise<Image> => {
    if (!url) return;
    const image = await this.fetchImage(url);
    if (!image) return;

    const uploadedImage = await imageStore.uploadImage({
      blob: await image,
      name: type,
      type: type,
    });

    type === ImageType.PUSH_ICON
      ? (this.icon = uploadedImage)
      : (this.image = uploadedImage);

    return uploadedImage;
  };

  private fetchImage = async (url: string): Promise<Blob> => {
    try {
      const req = await fetch(api(`/proxy?url=${encodeURIComponent(url)}`));
      if (!req.ok) return;
      return req.blob();
    } catch (error) {
      return;
    }
  };

  // Content

  @computed
  public get isTitleValid(): boolean {
    return this.title !== "";
  }

  @computed
  public get isContentValid(): boolean {
    return this.content !== "";
  }

  // Redirect Link

  @action
  public convertLinkToRedirectLink(utm, redirectLink): void {
    this.redirectLink = this.addProjectUTMs({ utm, link: redirectLink });
  }

  //  Actions

  @computed
  public get hasActions(): boolean {
    return this.actions.length > 0;
  }

  @computed
  public get canAddAction(): boolean {
    return this.actions.length < CAMPAIGN_ACTION_LIMIT;
  }

  @action
  public addAction = (): void => {
    const action = PushAction.createPushAction();
    this.actions = this.actions.concat([action]);
  };

  @action
  public deleteAction = (action: PushAction): void => {
    this.actions = this.actions.filter((a) => a !== action);
  };

  public getActions(): IAction[] {
    return this.actions.map((action) => ({
      title: action.title,
      link: action.link,
    }));
  }

  public get isActionsValid(): boolean {
    return this.actions.every((action) => action.actionIsValid());
  }

  //  Image

  @computed
  public get getIcon(): string {
    return this.getIconURL || DummyIcon;
  }

  @computed
  public get getImageURL(): string {
    return this.image?.url;
  }

  @computed
  public get getIconURL(): string {
    return this.icon?.url;
  }

  @computed
  public get dummyIcon(): string {
    return DummyIcon;
  }

  public getCurrentIcon(): string {
    return this.icon?.url || null;
  }

  @computed
  public get isDefaultIcon(): boolean {
    return (
      this.getCurrentIcon() === this.getDefaultIcon() ||
      this.getCurrentIcon() === dummyImage.url
    );
  }

  public getDefaultIcon(): string {
    const logo = projectImageStore.getLogoURL(ImageFormatType.MEDIUM);
    return logo || this.dummyIcon;
  }

  @action
  public setDefaultIcon = (): void => {
    if (this.getDefaultIcon() === this.dummyIcon) {
      this.icon = dummyImage;
      return;
    }
    this.icon = projectImageStore.getImageByType(ImageType.LOGO);
  };

  public convertIconURLToImage(icon): Image {
    this.setImageFromMetadata({ url: icon, type: ImageType.PUSH_ICON });
    return this.icon;
  }

  public convertImageURLToImage(image): Image {
    this.setImageFromMetadata({ url: image, type: ImageType.PUSH_IMAGE });
    return this.image;
  }

  // Time

  @action
  public validateSendDate(): void {
    this.isSendDateValid = dayjs(this.sendDate).isSameOrAfter(
      dayjs(),
      "minute"
    );
  }

  @action
  public validateDelayTime(): void {
    this.isDelayTimeValid =
      this.delayTime <= PushCampaignContent.MAX_DELAY_TIME;
  }

  @action
  public setTimeForSendTypeNow({
    expireTime,
    isExpireTimeEnabled,
  }: IStartExpireTimeValues): void {
    if (this.isSendTypeScheduled) return;

    this.sendDate = new Date();
    const expireTimeInHours = this.getStartExpireTimeValues({
      expireTime,
      isExpireTimeEnabled,
    });
    this.expireDate = dayjs(this.sendDate)
      .add(expireTimeInHours, "hour")
      .toDate();
  }

  @action
  public setScheduleDefaultDates = ({
    expireTime,
    isExpireTimeEnabled,
  }: IStartExpireTimeValues): void => {
    this.isSendDateValid = true;
    this.sendDate = dayjs().add(15, "minute").toDate();
    const expireTimeInHours = this.getStartExpireTimeValues({
      expireTime,
      isExpireTimeEnabled,
    });
    this.expireDate = dayjs(this.sendDate)
      .add(expireTimeInHours, "hour")
      .toDate();
  };

  @action
  public getStartExpireTimeValues({
    expireTime,
    isExpireTimeEnabled,
  }: IStartExpireTimeValues): number {
    return isExpireTimeEnabled
      ? expireTime
      : PushCampaignContent.MAX_TTL / 60 / 60;
  }

  @action
  public adjustExpireDate({
    expireTime,
    isExpireTimeEnabled,
  }: IStartExpireTimeValues): Date {
    if (!this.isSendDateValid) this.validateSendDate();

    const initialDate = this.sendDate ? dayjs(this.sendDate) : dayjs();
    const expireTimeInHours = this.getStartExpireTimeValues({
      expireTime,
      isExpireTimeEnabled,
    });
    this.expireDate = initialDate.add(expireTimeInHours, "hour").toDate();

    return this.expireDate;
  }

  @action
  public changeSendType = (type: PushSendType): void => {
    this.sendType = type;
  };

  @computed
  public get isSendTypeScheduled(): boolean {
    return this.sendType === PushSendType.SCHEDULE;
  }

  @computed
  public get isSendTypeNow(): boolean {
    return this.sendType === PushSendType.NOW;
  }

  @action
  public setDelayTime = (hours: number): void => {
    this.delayTime = hours;
  };

  @computed
  public get getDelayTimeInSeconds(): number {
    return Number(this.delayTime) * 60;
  }

  public getDelayTimeInHours(delayTime: number): number {
    return delayTime / 60;
  }

  @computed
  public get getSendDateRange(): ITTLRangeResponse {
    return {
      from: dayjs().toDate(),
      to: dayjs().add(1, "month").toDate(),
    };
  }

  @computed
  public get getExpireDateRange(): ITTLRangeResponse {
    return {
      from: dayjs(this.sendDate)
        .add(PushCampaignContent.MIN_TTL, "second")
        .toDate(),
      to: dayjs(this.sendDate)
        .add(PushCampaignContent.MAX_TTL, "second")
        .toDate(),
    };
  }

  // Other

  public get toSendPushToYourself(): ITestPush {
    return {
      title: this.title,
      content: this.content,
      icon: this.getIcon,
      image: this.getImageURL,
      redirectLink: this.redirectLink,
      requireInteraction: this.requireInteraction,
    };
  }

  @action
  public setPushScope = (scope: Record<string, string>): void => {
    this._scope = scope;
  };

  public getPushScope = (): IPushScope => {
    return {
      title: this.title,
      redirectLink: this.redirectLink,
      ...Render.getDateVariablesScope(),
      ...this._scope,
    };
  };

  public getPushPreview = (): IPushPreview => {
    return {
      title: this.title,
      content: this.content,
      image: this.getImageURL,
      icon: this.getIcon,
      requireInteraction: this.requireInteraction,
      iconURL: this.getIconURL,
      defaultIcon: this.getDefaultIcon(),
      actions: this.actions.map((action) => action.title),
      isDirectionRtl: this.direction,
    };
  };
}
