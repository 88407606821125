import { observer } from 'mobx-react';
import * as React from 'react';
import { t } from '../../../../base/helpers';
import { Modal } from '@ppg/styled';

interface INegativeBalanceModal {
  onCancel: () => void;
  show: boolean;
}

@observer
export class NegativeBalanceModal extends React.Component<INegativeBalanceModal, {}> {
  public render() {
    const { onCancel, show } = this.props;

    const ModalContent = () => {
      return (
        <>
          <p style={ { marginBottom: '1.5rem' } } data-cy={'user-delete-modal-negative'} >
            { t("You cannot delete your account because you have a negative balance") }
          </p>
        </>
      )
    };

    return (
      <Modal
        show={ show }
        title={ <h1>{ t('Negative Balance') }</h1> }
        cancelButton={ t('Cancel') }
        onCancel={ () => onCancel() }
        width={ 400 }
        content={ <ModalContent/> }
      />);
  }
}
