import { isArrayEmpty, property } from '@ppg/common';
import { action, observable } from 'mobx';
import { toast } from '@ppg/styled';
import { t } from '../../base/helpers';
import { PaymentCard as Card } from '../../modelsMobx/PaymentCard';
import { getPaymentCardsUseCase } from '../../useCases/accounting/GetPaymentCardsUseCase';
import { removeCardUseCase } from '../../useCases/accounting/RemoveCardUseCase';
import { removeRecurringCardUseCase } from '../../useCases/accounting/RemoveRecurringCardUseCase';
import { requestToAddCardUseCase } from '../../useCases/accounting/RequestToAddCardUseCase';
import { setRecurringCardUseCase } from '../../useCases/accounting/SetRecurringCardUseCase';
import { chargePPGLiteUser } from '../../useCases/accounting/ChargePPGLiteUser';
import { UserRelatedStore } from '../user/UserRelatedStore';

export const CARD_OPTIONS = {
  style: {
    base: {
      color: '#585858',
      fontFamily: '"Roboto", Helvetica, Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#888888',
      },
    },
    invalid: {
      color: '#e8575e',
      iconColor: '#e8575e',
    },
  },
};

export class CardsStore extends UserRelatedStore {
  @property() public secret: string = '';
  @property() public cardForRecurringPayment: Card = null;
  @property() public showCards: boolean = false;

  @observable public cards: Card[] = [];
  @observable public newCardDetails: string = '';
  @observable public isCardModalOpen: boolean = false;
  @observable public isSecretLoading: boolean = true;

  public async fetchCards() {
    const cards = await getPaymentCardsUseCase.exec({
      organization: this.userStore.user.organization
    });

    this.cards = cards.length > 0 && cards.map(card => Card.createCard(card));
  }

  public async requestToAddCard() {
    this.isSecretLoading = true;
    const { secretKey } = await requestToAddCardUseCase.exec({
      organization: this.userStore.user.organization
    });
    this.secret = secretKey;
    this.isSecretLoading = false;
  }

  public setSubscriptionRecurringCard = async (): Promise<void> => {
    await this.fetchCards();
    if (isArrayEmpty(this.cards)) {
      return;
    }

    await setRecurringCardUseCase.exec({
      organization: this.userStore.user.organization,
      cardId: this.cards[0].id
    });
    await this.fetchCards();
  };

  public chargePPGLiteUser = async (): Promise<void> => {
    await chargePPGLiteUser.exec({
      organization: this.userStore.user.organization,
    });
  };

  public removeRecurringCard = async (): Promise<void> => {
    await removeRecurringCardUseCase.exec({
      organization: this.userStore.user.organization,
      cardId: this.cardForRecurringPayment.id
    });
  };

  public setRecurringCard = async (card?: Card): Promise<void> => {
    await setRecurringCardUseCase.exec({
      organization: this.userStore.user.organization,
      cardId: card ? card.id : this.cardForRecurringPayment.id
    });
  };

  public async onRecurringCardConfirmed() {
    this.cardForRecurringPayment.recurring
      ? await this.removeRecurringCard()
      : await this.setRecurringCard();

    toast.success(t('Recurring card action success'));
    this.cardForRecurringPayment = null;
    await this.fetchCards();
  }

  public onRecurringClicked(card: Card) {
    this.cardForRecurringPayment = card;
  }

  public onRecurringCancel() {
    this.cardForRecurringPayment = null;
  }

  public async removeCard(cardId: string) {
    await removeCardUseCase.exec({
      organization: this.userStore.user.organization,
      id: cardId
    }).then(() => toast.success(t('Your card has been deleted successfully')));
    await this.fetchCards();

    this.userStore.isLite && await this.setSubscriptionRecurringCard();

  }

  public get hasSavedCards() {
    return this.cards.length > 0;
  }

  public handleBillingDataChange = e => {
    this.newCardDetails = e.target.value;
  };

  @action
  public enableCardModal = (): void => {
    this.isCardModalOpen = true;
  };

  @action
  public disableCardModal = (): void => {
    this.isCardModalOpen = false;
  };

  public reset() {
    this.cards = [];
    this.secret = '';
    this.newCardDetails = '';
    this.showCards = false;
  }
}
