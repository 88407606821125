import { t } from '../../base/helpers';
import { PropertyHandler } from '@ppg/common';

export enum DynamicFieldTypeTypes {
  STRING = 'string',
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  DATE = 'date',
}

export interface IDynamicFieldTypeOptions {
  name: string;
  value: DynamicFieldTypeTypes;
}

export abstract class DynamicField extends PropertyHandler {

  public static getSampleValueByType(type: DynamicFieldTypeTypes): String | Number | Date | Boolean {
    const rand = Math.floor(Math.random() * 100) - 1;

    const strings: string[] = ['Sample', 'Joe', 'Doe', 'Any text'];
    switch (type) {
      case DynamicFieldTypeTypes.STRING:
        return strings[Math.floor(Math.random() * strings.length)];
      case DynamicFieldTypeTypes.NUMBER:
        return rand;
      case DynamicFieldTypeTypes.DATE:
        return new Date().toISOString();
      case DynamicFieldTypeTypes.BOOLEAN:
        return rand > 50;
    }
  }

  public static get typeOptions(): IDynamicFieldTypeOptions[] {
    return [
      { value: DynamicFieldTypeTypes.STRING, name: t('String') },
      { value: DynamicFieldTypeTypes.NUMBER, name: t('Number') },
      { value: DynamicFieldTypeTypes.BOOLEAN, name: t('Boolean') },
      { value: DynamicFieldTypeTypes.DATE, name: t('Date') }
    ];
  }
}
