import { debounce, isArrayEmpty, property, PropertyHandler } from '@ppg/common';
import { action, computed, observable, reaction } from 'mobx';
import { getCountSubscribersUseCase, getLabelsKeysUseCase, getLabelsOptimizedUseCase } from '../../useCases/core';
import { ISelectOption } from '@ppg/styled';
import { t } from '../../base/helpers';
import { Label, LabelCountStrategy, LabelState } from '../../modelsMobx/Label';
import { IGetLabelsResponse } from '../../useCases/core/label/GetLabelsUseCase';
import { Utils } from '../utils';

export interface ILabelCountStrategyOptions {
  name: string;
  value: LabelCountStrategy;
  helperContent: string;
}

export enum SortTypeOptions {
  ALPHABETICAL = 'ALPHABETICAL',
  ALPHABETICAL_DESC = '-ALPHABETICAL',
  NEWEST = 'NEWEST',
  OLDEST = '-NEWEST',
  POPULAR = 'POPULAR',
  UNPOPULAR = '-POPULAR'
}

export enum selectionLabelType {
  INCLUDE = 'include',
  EXCLUDE = 'exclude'
}

export class SegmentationStore extends PropertyHandler {
  static MAX_LABELS_DELETE = 100;
  static LABELS_LIMIT = 50;

  @property()
  public labelsKeys: string[] = [];

  @property()
  public labelKey: string = "";

  @property()
  public labels: Label[] = [];

  @property()
  public offset: number = 0;

  @property()
  public total: number = 0;

  @property()
  public sortBy: SortTypeOptions = SortTypeOptions.POPULAR;

  @property()
  public searchQuery: string = "";

  @property()
  public selectionMode: selectionLabelType = selectionLabelType.INCLUDE;

  @property()
  public excludedStrategy: LabelCountStrategy = LabelCountStrategy.OR;

  @property()
  public includedStrategy: LabelCountStrategy = LabelCountStrategy.OR;

  @observable
  public isLoading: boolean = true;

  @property()
  public subscribersCount: number = 0;

  @property()
  public newSegmentLabel: string = "";

  private debouncedOnSearchQuery = debounce(() => this.filterLabels(), 800);

  reactOnSortChange = reaction(() => this.sortBy, () => this.filterLabels());
  reactOnQueryChange = reaction(() => this.searchQuery, () => this.debouncedOnSearchQuery());

  constructor(private readonly projectId: string, labels: Label[], includedStrategy, excludedStrategy) {
    super();
    this.setLabels(labels);
    this.setIncludedStrategy(includedStrategy);
    this.setExcludedStrategy(excludedStrategy);
  }

  @action
  public resetStateOfLabels(): void {
    for (let lbl of this.labels) {
      lbl.setState(LabelState.IDLE);
    }
  }

  @action
  public onSearchQueryChange(value: string): void {
    this.searchQuery = value;
  }

  @action
  public setLabelKey(labelKey: string): void {
    this.labelKey = labelKey;
  }

  public setDefaultValues(): void {
    this.offset = 0;
    this.total = 0;
  }

  /** @deprecated **/
  public setLabels(labels: Label[]): void {
    this.labels = [].concat(labels).filter(i => i);
  }

  /** @deprecated **/
  public setIncludedStrategy(includedStrategy: LabelCountStrategy): void {
    this.includedStrategy = includedStrategy ?? LabelCountStrategy.OR;
  }

  /** @deprecated **/
  public setExcludedStrategy(excludedStrategy: LabelCountStrategy): void {
    this.excludedStrategy = excludedStrategy ?? LabelCountStrategy.OR;
  }

  @computed
  public get includedLabels(): Label[] {
    return this.labels.filter(label => label.isIncluded());
  }

  @computed
  public get excludedLabels(): Label[] {
    return this.labels.filter(label => label.isExcluded());
  }

  @action
  public async fetchCountSubscribers(): Promise<void> {
    const includedStrategy: LabelCountStrategy = this.includedLabels.length === 0 ? LabelCountStrategy.AND : this.includedStrategy;
    const excludedStrategy: LabelCountStrategy = this.excludedLabels.length === 0 ? LabelCountStrategy.AND : this.excludedStrategy;

    const { data } = await getCountSubscribersUseCase.exec({
      projectId: this.projectId,
      includedLabels: this.includedLabels.map((label) => label.serialize()),
      excludedLabels: this.excludedLabels.map((label) => label.serialize()),
      includedStrategy,
      excludedStrategy
    });

    this.subscribersCount = data;
  }

  @computed
  public get setIncludedOrExcludedLabels(): boolean {
    return !isArrayEmpty(this.includedLabels) || !isArrayEmpty(this.excludedLabels);
  }

  @computed
  public get canCreateSegment(): boolean {
    return this.labels.length > 0;
  }

  @action
  public async createSegmentBasedOnTags(): Promise<void> {
    alert('todo: implement!');
  }

  public availableLabels(): Label[] {

    const filterNotSelected = (label: Label) => !this.excludedLabels.some(excludedLabel => label.getLabelIdentity() === excludedLabel.getLabelIdentity()) && !this.includedLabels.some(includedLabel => label.getLabelIdentity() === includedLabel.getLabelIdentity());

    const filterByLabel = (label: Label) => {
      return this.labelKey ? label.key === this.labelKey : true;
    };

    const filterByQuery = (label: Label) => String(label.value).includes(this.searchQuery);

    return this.labels.filter(filterNotSelected).filter(filterByLabel).filter(filterByQuery);
  }

  @action
  public async fetchLabelsKeys(): Promise<void> {
    this.labelsKeys = await getLabelsKeysUseCase.exec();

    if (this.labelsKeys.length === 0) {
      this.isLoading = false;
      return;
    }

    this.labelKey = this.labelsKeys[0];
  };

  public async fetchLabelsForProject(): Promise<IGetLabelsResponse> {
    return await getLabelsOptimizedUseCase.exec({
      offset: this.offset,
      limit: SegmentationStore.LABELS_LIMIT,
      sortBy: this.sortBy,
      key: this.labelKey ?? "",
      query: this.searchQuery
    });
  }

  @action
  public async getLabelsForProject(): Promise<void> {
    await this.executeWithLabelsLoader(async () => {
      const { data, metadata } = await this.fetchLabelsForProject();
      this.total = metadata.total;
      const newLabels = data.map(label => Label.createLabel(label));

      const labelsSelected = this.labels.filter(label => label.isIncluded() || label.isExcluded());
      const selectedLabelKeys = labelsSelected.map(label => label.getLabelIdentity());
      const labelsWithoutSelected = newLabels.filter(label => !selectedLabelKeys.includes(label.getLabelIdentity()));

      this.labels = labelsSelected.concat(labelsWithoutSelected);
    });
  };

  @action
  public async getMoreLabelsForProject(): Promise<void> {
    await this.executeWithLabelsLoader(async () => {
      this.offset = this.offset + SegmentationStore.LABELS_LIMIT;

      const { data, metadata } = await this.fetchLabelsForProject();

      this.total = metadata.total;
      const labels = data.map(label => Label.createLabel(label));

      const selectedLabelKeys = this.labels.map(label => label.getLabelIdentity());
      const labelsWithoutSelected = labels.filter(label => !selectedLabelKeys.includes(label.getLabelIdentity()));

      if (labels.length === 0) {
        this.total = this.labels.length;
      }

      this.labels = this.labels.concat(labelsWithoutSelected);
    });
  }

  @action
  public async filterLabels(): Promise<void> {
    this.offset = 0;
    await this.getLabelsForProject();
  }

  @computed
  public get labelsKeysOptionsForMigrateProject(): ISelectOption[] {
    return this.labelsKeys.map((label, id) => {
      return { value: label, name: label };
    }) ?? [];
  }

  @computed
  public get labelsKeysOptions(): ISelectOption[] {
    const labelsOptions = this.labelsKeys.map((label, id) => {
      return { value: label, name: label };
    }) ?? [];

    return [{ value: null, name: t('All') }]
      .concat(labelsOptions);
  }

  @computed
  public get sortOptions(): ISelectOption[] {
    return [
      { name: 'Most popular', value: SortTypeOptions.POPULAR },
      { name: 'Least popular', value: SortTypeOptions.UNPOPULAR },
      { name: 'A-Z', value: SortTypeOptions.ALPHABETICAL },
      { name: 'Z-A', value: SortTypeOptions.ALPHABETICAL_DESC },
      { name: 'Newest', value: SortTypeOptions.NEWEST },
      { name: 'Oldest', value: SortTypeOptions.OLDEST }
    ];
  }

  @computed
  public get sortOptionsWithoutNewestAndOldest(): ISelectOption[] {
    return [
      { name: 'Most popular', value: SortTypeOptions.POPULAR },
      { name: 'Least popular', value: SortTypeOptions.UNPOPULAR },
      { name: 'A-Z', value: SortTypeOptions.ALPHABETICAL },
      { name: 'Z-A', value: SortTypeOptions.ALPHABETICAL_DESC }
    ];
  }

  @action
  public setSelectionMode(mode: selectionLabelType): void {
    this.selectionMode = mode;
  }

  @computed
  public get hasLabels(): boolean {
    const labels = this.labels.filter(label => label.state === LabelState.IDLE);
    return !isArrayEmpty(labels);
  }

  @computed
  public get canCount(): boolean {
    const hasExcludedLabels = this.excludedLabels.length > 0;
    const hasIncludedLabels = this.includedLabels.length > 0;
    const isIncludedStrategyAND = this.includedStrategy === LabelCountStrategy.AND;
    const isExcludedStrategyAND = this.excludedStrategy === LabelCountStrategy.AND;

    if (!hasExcludedLabels && !hasIncludedLabels) return true;
    if (hasIncludedLabels && hasExcludedLabels) return isIncludedStrategyAND && isExcludedStrategyAND;
    if (hasExcludedLabels) return isExcludedStrategyAND;
    if (hasIncludedLabels) return isIncludedStrategyAND;
    return;
  }

  private executeWithLabelsLoader<T = any>(callback: () => Promise<T>) {
    return Utils.executeWithLoader(
      callback,
      () => this.isLoading = true,
      () => this.isLoading = false,
      (err) => {
        console.error(err);
        this.isLoading = false;
      }
    );
  }
}


