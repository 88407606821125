import styled from "@emotion/styled";

export const LabelsFormExpireDate = styled.div`
  display: flex;

  input {
    max-width: 60px;
  }

  label {
    margin-bottom: 0;
  }

  .select-wrapper {
    margin-left: 10px;
    display: flex;
    align-items: flex-end;
    width: 130px;
  }

  select {
    margin: 0;
  }

  @media(max-width: 1200px) {
    input {
      max-width: 80px;
    }
  }

  @media (max-width: 1200px) {
    .select-wrapper {
      width: 100%;
    }
  }
`;

export const LabelsFormExpireDateLabel = styled.h3`
  width: 100%;
  margin-bottom: 5px;
  font-size: 1.3rem;
  font-weight: 300;
  white-space: normal;
  line-height: 1.6;
  letter-spacing: .01em;
`;
