import { API, UseCase } from "@ppg/common";
import { IProvider } from "../interfaces";

interface IToggleProductionFlag extends IProvider, IToggleProductionFlagResponse {
}

interface IToggleProductionFlagResponse {
  production: boolean;
}

export class ToggleProductionFlagOnIOSProviderUseCase extends UseCase<IToggleProductionFlag, IToggleProductionFlagResponse> {
  protected async execute({ production, organizationId, projectId }: IToggleProductionFlag) {
    return API.HTTP.put<IToggleProductionFlagResponse>(`core/organizations/${ organizationId }/projects/${ projectId }/providers/ios/production`, {
      production
    });
  }
}

export const toggleProductionFlagOnIOSProviderUseCase = new ToggleProductionFlagOnIOSProviderUseCase();
