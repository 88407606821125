import { property, PropertyHandler } from '@ppg/common';
import { action, computed } from 'mobx';
import { updateAutomationUseCase } from '../useCases/core';

export enum AutomationStateType {
  PAUSE = 'pause',
  RESUME = 'resume',
  ERROR = 'error',
}

export enum AutomationOfferType {
  DEFAULT = 'ppg_default',
  STANDARD = 'ppg_lite_standard',
  PREMIUM = 'ppg_lite_premium',
}

export interface IAutomationScenario {
  id: string;
  name: string;
  priority: number;
  state: AutomationStateType;
  steps: Step[];
  testMode: boolean;
  meta_offer_type: AutomationOfferType;
//  todo: remove on new enpoint
  additionalFields: any[];
  createdAt: string;
  deleted: boolean;
  expireDate: string;
  firstStepId: string;
  project: string;
  resetTime: number;
  resetTimePeriod: string;
  trigger: string;
  triggerType: string;
  updatedAt: string;
}

export interface Step {
  type: string;
  payload: Record<string, any>;
}

export class AutomationScenario extends PropertyHandler {
  @property() public id: string;
  @property() public name: string;
  @property() public priority: number;
  @property() public state: AutomationStateType;
  @property() public enabled: boolean;
  @property() public steps: Step[];
  @property() public testMode: boolean;
  @property() public offerType: AutomationOfferType;

  //todo remove when new endpoints integrated
  @property() public additionalFields: any[];
  @property() public createdAt: string;
  @property() public deleted: boolean;
  @property() public expireDate: string;
  @property() public firstStepId: string;
  @property() public project: string;
  @property() public resetTime: number;
  @property() public resetTimePeriod: string;
  @property() public trigger: string;
  @property() public triggerType: string;
  @property() public updatedAt: string;


  constructor(scenario: IAutomationScenario) {
    super();
    this.id = scenario.id;
    this.name = scenario.name;
    this.priority = scenario.priority;
    this.state = scenario.state;
    this.enabled = scenario.state === AutomationStateType.RESUME;
    this.steps = scenario.steps;
    this.testMode = scenario.testMode;
    this.offerType = scenario.meta_offer_type;

    this.additionalFields = scenario.additionalFields;
    this.createdAt = scenario.createdAt;
    this.deleted = scenario.deleted;
    this.expireDate = scenario.expireDate;
    this.firstStepId = scenario.firstStepId;
    this.resetTime = scenario.resetTime;
    this.resetTimePeriod = scenario.resetTimePeriod;
    this.trigger = scenario.trigger;
    this.triggerType = scenario.triggerType;
    this.updatedAt = scenario.updatedAt;
  }

  @action
  public updateScenario = async (): Promise<void> => {
    await updateAutomationUseCase.exec({
      automationId: this.id,
      meta_offer_type: this.offerType,
      ...this
    })
  }

  @action
  public toggleEnabledState = (): void => {
    this.state = this.state === AutomationStateType.RESUME ? AutomationStateType.PAUSE : AutomationStateType.RESUME;
  }

  @computed
  public get isEnabledForAll(): boolean {
    return this.enabled && !this.testMode;
  }

  public handleOnEnableChange = async (): Promise<void> => {
    this.toggleEnabledState();
    await this.updateScenario();
  }

  @computed
  public get isEnabledForTest(): boolean {
    return this.isStateResume && this.testMode;
  }

  @computed
  public get isStateResume(): boolean {
    return this.state === AutomationStateType.RESUME;
  }

  @computed
  public get isPremium(): boolean {
    return this.offerType === AutomationOfferType.PREMIUM;
  }
}
