import * as React from 'react';
import { FC } from 'react';
import * as Styled from './PieChart.styled'
import { ComputedDatum } from '@nivo/pie/dist/types/types';
import { DefaultRawDatum } from '@nivo/pie';
import { fixedPercentValue } from '@ppg/common';

interface IBarChartTooltip {
  datum: ComputedDatum<DefaultRawDatum & {id: string | number, value: number}>
  percentValue?: boolean;
}

export const PieChartTooltip: FC<IBarChartTooltip> = ({ datum, percentValue }) => {
  const { id, value, color } = datum;
  return <Styled.PieTooltipContainer>
    <Styled.PieTooltipColorBox color={ color }/>
    <div>
      <span>{ id }:</span> <b>{ percentValue ? fixedPercentValue(value) : value }</b>

    </div>
  </Styled.PieTooltipContainer>
}
