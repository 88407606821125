import * as React from "react";
import "./AutomationChartsWrapper.scss";

interface IAutomationChartsWrapper {
  children: React.ReactNode;
}

export const AutomationChartsWrapper = ({ children }: IAutomationChartsWrapper): JSX.Element => {
  return <div className={ 'automation-details-wrapper' }>
    { children }
  </div>
}