import * as lodash from 'lodash';
import { IAttribute } from './IAttribute';
import { Message } from './Message';
import { Model } from './Model';

export class Attribute<T> implements IAttribute<T> {
  constructor(
    public readonly model: Model<any, any>,
    public readonly attribute: string,
    public readonly deep: boolean
  ) {}

  public getValue(): T {
    return this.deep ? this.model.result(this.attribute) : this.model.get(this.attribute);
  }

  public setValue(val: T): void {
    if (this.deep) {
      lodash.set(this.model, `attributes.${this.attribute}`, val);
      this.model.trigger('change', this, {});
      return;
    }

    this.model.set(this.attribute, val);
  }

  public getMessages(): Message[] {
    return this.model.getMessages(this.attribute);
  }

  public hasErrors(): boolean {
    // @todo: extract predicats to separate functions or static methods
    // it will increase performance
    return lodash.some(this.getMessages(), (message: Message) => message.isError());
  }

  public hasWarnings(): boolean {
    return lodash.some(this.getMessages(), (message: Message) => message.isWarning());
  }

  public hasInfoMessages(): boolean {
    return lodash.some(this.getMessages(), (message: Message) => message.isInfo());
  }

  public isSuccess(): boolean {
    return lodash.isEmpty(this.getMessages()) ||
           lodash.every(this.getMessages(), (message: Message) => message.isSuccess())
  }

  public onChangeSetter(): (e: React.FormEvent<HTMLInputElement>) => void {
    return this.model.onChangeSetter(this.attribute);
  }
}