import { property } from "@ppg/common";
import { IDataLayerConfig } from '../../useCases/website-integration/interfaces';
import { action } from 'mobx';
import { getDataLayerPluginUseCase, getGAPluginUseCase, updateDataLayerPluginUseCase, updateGAPluginUseCase } from '../../useCases/website-integration';
import { WebsiteIntegrationRelated } from '../../stores/project/WebsiteIntegrationRelated';

export interface IAnalyticsSupport {
  allowGaEvents: boolean;
  gaEventTracker: string;
  dataLayerEnabled: boolean;
  dataLayerConfig: IDataLayerConfig;
}

export class AnalyticsSupport extends WebsiteIntegrationRelated implements IAnalyticsSupport {
  @property() public allowGaEvents: boolean;
  @property() public gaEventTracker: string;
  @property() public dataLayerEnabled: boolean;
  @property() public dataLayerConfig: IDataLayerConfig;

  public async getDataLayer(): Promise<{ enabled: boolean, config: IDataLayerConfig }> {
    const { payload } = await getDataLayerPluginUseCase.exec({ websiteIntegrationId: this.websiteIntegrationId });
    return payload;
  }

  public async getGA(): Promise<{ enabled: boolean, tracker: string }> {
    const { payload } = await getGAPluginUseCase.exec({ websiteIntegrationId: this.websiteIntegrationId });
    return payload;
  }

  @action
  public async fetchData(): Promise<void> {
    const dataLayerSettings = await this.getDataLayer();
    const gaSettings = await this.getGA();

    this.allowGaEvents = gaSettings.enabled;
    this.gaEventTracker = gaSettings.tracker;
    this.dataLayerEnabled = dataLayerSettings.enabled;
    this.dataLayerConfig = dataLayerSettings.config;
  }


  public async save(): Promise<void> {
    await updateDataLayerPluginUseCase.exec({
      websiteIntegrationId: this.websiteIntegrationId,
      enabled: this.dataLayerEnabled,
      config: this.dataLayerConfig
    });
    await updateGAPluginUseCase.exec({
      websiteIntegrationId: this.websiteIntegrationId,
      enabled: this.allowGaEvents,
      tracker: this.gaEventTracker
    })
  }
}
