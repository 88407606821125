import { debounce, property } from '@ppg/common';
import { observable, reaction } from 'mobx';
import { AccessKey } from '../modelsMobx/AccessKey';
import { listApiKeyUseCase } from '../useCases/auth-and-identity/ListApiKeyUseCase';
import { getAllSharedProjectsUseCase } from '../useCases/auth-and-identity/GetAllSharedProjectsUseCase';
import { addLegacyApiKeyUseCase } from '../useCases/auth-and-identity/AddLegacyApiKeyUseCase';
import { revokeLegacyApiKeyUseCase } from '../useCases/auth-and-identity/RevokeLegacyApiKeyUseCase';
import { AccessProject } from '../modelsMobx/AccessProject';
import { revokeSharedProjectUseCase } from '../useCases/auth-and-identity/RevokeSharedProjectUseCase';
import { UserRelatedStore } from './user/UserRelatedStore';
import { ITEMS_PER_PAGE } from '../constants';
import { v4 as uuidv4 } from 'uuid';
import { getDateAndTimeFromStringDate } from '@ppg/common';
import { shareManyProjectsToManyUsersUseCase } from "../useCases/auth-and-identity/ShareManyProjectsToManyUsersUseCase";
import { toast } from '@ppg/styled';
import { t } from '../base/helpers';

export class AccessManagerStore extends UserRelatedStore {

  @property()
  public perPage: number = ITEMS_PER_PAGE;

  @property()
  public infinity: boolean;

  @property()
  public accessKeysOffset: number = 0;

  @property()
  public accessKeysTotal: number = 0;

  @property()
  public sharedProjectsOffset: number = 0;

  @property()
  public sharedProjectsTotal: number = 0;

  @property()
  public selectedTab: string = 'keys';

  @property()
  public projectSelect: string = '';

  @property()
  public search: string = '';

  @property()
  public isLoading: boolean = true;

  @observable public accessKeys: AccessKey[] = [];

  @observable public newAccessKey: AccessKey = new AccessKey({});

  @observable public accessProjects: AccessProject[] = [];

  @observable public newAccessProject: AccessProject = new AccessProject({});

  fetchKeysOnPaginationChange = reaction(
    () => this.doPaginationFetch(this.accessKeysOffset),
    () => this.fetchAccessKeys()
  );

  fetchProjectsOnPaginationChange = reaction(
    () => this.doPaginationFetch(this.sharedProjectsOffset),
    () => this.fetchSharedProjects()
  );

  fetchProjectsOnFilterChange = reaction(
    () => this.doFilterFetch(),
    debounce(() => {
      this.setDefaultValues();
      this.fetchSharedProjects();
    }, 500)
  );

  public async fetchAccessKeys(): Promise<void> {
    this.isLoading = true;

    listApiKeyUseCase.exec({
        offset: this.accessKeysOffset,
        limit: this.perPage,
        organization: this.userStore.user.organization
      })
      .then(({ data, metadata }) => {
        const accessKeys = data.map(key => {
          return new AccessKey(key);
        });
        this.accessKeysTotal = metadata.total;
        this.accessKeys = this.infinity ? this.accessKeys.concat(accessKeys) : accessKeys;
        this.isLoading = false;
      })
      .catch((error) => console.error(error));
  }

  public async fetchSharedProjects(): Promise<void> {
    this.isLoading = true;

    getAllSharedProjectsUseCase.exec({
        offset: this.sharedProjectsOffset,
        limit: this.perPage,
        organization: this.userStore.user.organization,
        project: this.projectSelect,
        email: encodeURIComponent(this.search)
      })
      .then(({ data, metadata }) => {
        const accessProjects = data.map(sharedProject => new AccessProject(sharedProject));
        this.sharedProjectsTotal = metadata.total;
        this.accessProjects = this.infinity ? this.accessProjects.concat(accessProjects) : accessProjects;
        this.isLoading = false;
      })
      .catch((error) => console.error(error));
  }

  public async saveAccessKey(): Promise<void> {
    const expireAt = this.newAccessKey.expiredAt;
    const projectsIDS = Array.from(this.newAccessKey.projectIDS);

    addLegacyApiKeyUseCase
      .exec({
        expiresAt: expireAt,
        projects: projectsIDS,
        organization: this.userStore.user.organization,
      }).then(() => {
      const key = new AccessKey({
        id: uuidv4(),
        projects: projectsIDS,
        expiredAt: getDateAndTimeFromStringDate(expireAt),
      });

      if (this.infinity) {
        this.accessKeys = [key].concat(this.accessKeys);
        this.accessKeysTotal++;
      } else {
        this.fetchAccessKeys();
      }
    });

  }

  public async shareProjectsToMany(): Promise<void> {
    const usernames = this.newAccessProject.selectedUsers;
    const projects = this.newAccessProject.projectIDS;

    return shareManyProjectsToManyUsersUseCase
      .exec({
        usernames: usernames,
        projects: projects,
        organization: this.userStore.user.organization
      }).then((data) => {
        const sharedProjects = data
          .results
          .filter(item => !item.error)
          .map(item => new AccessProject({
            id: uuidv4(),
            sharedTo: {
              username: item.user,
            },
            project: item.project
          }));

        if (this.infinity) {
          this.accessProjects = sharedProjects.concat(this.accessProjects);
          this.sharedProjectsTotal = this.sharedProjectsTotal + sharedProjects.length;
        } else {
          this.fetchSharedProjects();
        }
      });
  }

  public async removeAccessKey(id: string): Promise<void> {
    this.isLoading = true;
    revokeLegacyApiKeyUseCase.exec({ id, organization: this.userStore.user.organization })
      .then(() => toast.success(t('Your key has been deleted successfully')))
      .then(() => {
        this.accessKeys = [];
        if (this.infinity) {
          this.accessKeysOffset === 0 ? this.fetchAccessKeys() : this.accessKeysOffset = 0;
        } else {
          this.fetchAccessKeys();
        }
      });
  }

  public async revokeSharedProject(shareProjectID: string): Promise<void> {
    this.isLoading = true;
    revokeSharedProjectUseCase.exec({
        organization: this.userStore.user.organization,
        shareProjectID
      })
      .then(() => toast.success(t('Your shared project has been deleted successfully')))
      .then(() => {
        this.accessProjects = [];
        if (this.infinity) {
          this.sharedProjectsOffset === 0 ? this.fetchSharedProjects() : this.sharedProjectsOffset = 0;
        } else {
          this.fetchSharedProjects();
        }
      });
  }

  public doPaginationFetch(offset): { offset: number, perPage: number } {
    return {
      offset: offset,
      perPage: this.perPage,
    };
  }

  public doFilterFetch() {
    return {
      search: this.search,
      select: this.projectSelect
    };
  }

  setDefaultValues() {
    this.accessKeys = [];
    this.accessProjects = [];
    this.sharedProjectsOffset = 0;
    this.sharedProjectsTotal = 0;
    this.accessKeysOffset = 0;
    this.accessKeysTotal = 0;
    this.perPage = ITEMS_PER_PAGE;
    this.isLoading = true;
    this.infinity = false;
  }
}
