import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Loader } from '@ppg/styled';
import { ProjectRoutesTypes } from './moduleProjectRoutes';

const Suspense = (React as any).Suspense;

interface IRenderRoutes {
  routes: any[];
}

export default function RenderRoutes({ routes }: IRenderRoutes) {
  return (
    <Suspense fallback={ <Loader/> }>
      <Switch>
        { routes.map(({ component: Component, ...route }, index) =>
          <Route { ...route } key={ index } component={ Component }/>) }
        <Redirect to={ ProjectRoutesTypes.DASHBOARD } />
      </Switch>
    </Suspense>
  );
}
