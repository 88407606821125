import * as React from "react";
import { observer } from 'mobx-react';
import { Segment } from '../../../modelsMobx/segmentation/Segment';
import { t } from '../../../base/helpers';
import { Input, InputDataList } from '@ppg/styled';
import { SegmentCondition } from '../../../modelsMobx/segmentation/SegmentCondition';
import { useEffect, useRef } from 'react';
import * as Styled from "./SegmentCreator.styled";
import dayjs from 'dayjs';
import { SegmentConditionValueDate } from './SegmentConditionValueDate';

export enum ConditionValueType {
  STRING = 'string',
  NUMBER = 'number',
  DATE = 'date'
}

interface ISegmentConditionValues {
  segment: Segment;
  condition: SegmentCondition;
  positionOfCondition: string;
}

export const SegmentConditionValues = observer(({ segment, condition, positionOfCondition }: ISegmentConditionValues): JSX.Element => {

  const { key, value, operator, conditionValuesHints, conditionValueType } = condition;

  const isInitialMount = useRef(true);

  useEffect(() => {
    segment.getConditionValueType(condition, operator);
  }, [operator]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    const fetchHints = async () => await condition.getHintsConditionValues(key);
    condition.isStringConditionValueType && fetchHints().catch(console.error);
  }, [value]);

  const currentDate = dayjs().format('YYYY-MM-DD');

  const renderValueTypeField = (): JSX.Element => {
    switch (conditionValueType) {
      case ConditionValueType.STRING:
        return <>
          <Input
            list={ `condition_value_string_${ positionOfCondition }` }
            label={ t('segments/new::Label value') }
            helperContent={ t('If you want use date, recommend format is: YYYY-MM-DD (%{currentDate})', { currentDate }) }
            property={ condition.getProperty('value') }/>
          <InputDataList id={ `condition_value_string_${ positionOfCondition }` } values={ conditionValuesHints }/>
        </>;

      case ConditionValueType.NUMBER:
        return <>
          <Input
            label={ t('segments/new::Label value') }
            list={ `condition_value_number_${ positionOfCondition }` }
            type="number"
            property={ condition.getProperty('value') }/>
        </>;

      case ConditionValueType.DATE:
        return <SegmentConditionValueDate condition={ condition } />;
    }
  };

  return <Styled.SegmentConditionField>
    { conditionValueType && renderValueTypeField() }
  </Styled.SegmentConditionField>;
});