import { UseCase, API } from '@ppg/common';
import { IUpdateBeaconTracker } from '../interfaces';

export class UpdateBeaconTrackerPluginUseCase extends UseCase<IUpdateBeaconTracker, void> {
  protected async execute({ websiteIntegrationId, enabled, beaconStrategyType, beaconSelector }: IUpdateBeaconTracker) {
    await API.HTTP.put(`wi/projects/${ API.HTTP.currentProjectId }/website-integrations/${ websiteIntegrationId }/plugins/beacon-tracker`, {
      enabled, beaconStrategyType, beaconSelector
    })
  }
}

export const updateBeaconTrackerPluginUseCase = new UpdateBeaconTrackerPluginUseCase();
