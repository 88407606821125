import { API, UseCase } from "@ppg/common";

interface IDeleteSegmentRequest {
  segment: string;
}

export class DeleteSegmentUseCase extends UseCase<IDeleteSegmentRequest, void> {
  protected async execute({segment }: IDeleteSegmentRequest) {
    return API.HTTP.delete<void>(`core/projects/${API.HTTP.currentProjectId}/segment/${segment}`)
  }
}

export const deleteSegmentUseCase = new DeleteSegmentUseCase();
