import { API, UseCase } from "@ppg/common";
import { getPlatformTypeParam } from '../../../helpers';
import { IGetSubscribersAverageLifetimeUseCaseResult, PlatformType } from '../../../interfaces';

interface IGetProjectSubscribersAverageLifetimeRequest {
  platform?: PlatformType;
}

export class GetProjectSubscribersAverageLifetimeUseCase extends UseCase<IGetProjectSubscribersAverageLifetimeRequest, IGetSubscribersAverageLifetimeUseCaseResult> {
  protected async execute({ platform }: IGetProjectSubscribersAverageLifetimeRequest) {
    const endpoint = `statistics/v2/projects/${ API.HTTP.currentProjectId }/subscribers/dashboard/lifetime/average`
      + getPlatformTypeParam(platform);

    return API.HTTP.get<IGetSubscribersAverageLifetimeUseCaseResult>(endpoint);
  }
}

export const getProjectSubscribersAverageLifetimeUseCase = new GetProjectSubscribersAverageLifetimeUseCase();
