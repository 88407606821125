import * as React from 'react';
import { Link } from 'react-router-dom';
import { t } from '../../base/helpers';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from '../../stores/project/ProjectStore';
import { PPGLite, PPGLiteRole } from '../PPGLite/PPGLite';
import { LiteProjectEmptyData } from '../LiteProjectEmptyData/LiteProjectEmptyData';
import { ProjectRoutesTypes } from '../../routes/moduleProjectRoutes';

interface IProjectEmptyData {
  projectStore?: ProjectStore;
  props: any;
}

export class ProjectEmptyData extends React.Component<{}, {}> {

  public render() {
    return (
      <div className="empty-data">
        <span className="icon-big icon-projects"/>
        <h3 className="empty-data-title">{ t("You don't have any project") }</h3>
        <p className="empty-data-subtitle">{ t('First thing you need to do is to add a project for your website and get installation scripts.') }</p>
        <Link to={ ProjectRoutesTypes.PROJECT_NEW } id="createProject" className="button">{ t('Create project') }</Link>
      </div>
    );
  }

  public static wrap(): ClassDecorator {
    return function (ComponentClass: React.ComponentClass<any>) {

      return inject("projectStore")(observer(({ projectStore, props }: IProjectEmptyData) => {
        return projectStore.hasProjects ?
          <ComponentClass { ...props } /> :
          <>
            <PPGLite role={ PPGLiteRole.HIDE }><ProjectEmptyData { ...props } /></PPGLite>
            <PPGLite role={ PPGLiteRole.DISPLAY }><LiteProjectEmptyData/></PPGLite>
          </>;
      }));

    } as ClassDecorator;
  }
}
