import * as React from 'react';
import { Redirect, RouteProps } from 'react-router-dom';

import { ProjectRoutesTypes } from './moduleProjectRoutes';

import { t } from '../base/helpers';
import Integrations from '../pages/Integrations';
import { WebhooksView } from '../pages/Integrations/webhooks/WebhooksView';
import { NewProject } from '../pages/NewProject/NewProject';
import { SelectorsView } from '../pages/Selectors/SelectorsView';
import { AccessManager } from '../pages/User/AccessManager/AccessManager';
import { Account } from '../pages/User/Account/Account';
import { Payments } from '../pages/User/Payments/Payments';
import { Partnership } from '../pages/User/Partnership/Partnership';
import { PaymentsRoutesTypes } from './paymentsRoutes';
import { AccessManagerKeys } from '../pages/User/AccessManager/AccessManagerKeys';
import { AccessManagerSharedProjects } from '../pages/User/AccessManager/AccessManagerSharedProjects';
import { UserInformation } from '../pages/User/Account/UserInfo/Start';
import { NotificationChannels } from '../pages/User/Account/NotificationChannels/NotificationChannels';
import { Settings } from '../pages/User/Account/Settings/Settings';
import { IUserNavigationTab } from '../pages/User/Navigations/UserNavigationBar/UserNavigationBar';

const lazy = React.lazy;

const Dashboard = lazy(() => import(/* webpackChunkName: 'Dashboard' */ '../pages/Dashboard/Start'));
const OrganizationAnalyticsDashboard = lazy(() => import(/* webpackChunkName: 'OrganizationDashboard' */ '../pages/Dashboard/Organization/OrganizationAnalyticsDashboard'));
const OrganizationDashboardSubscribers = lazy(() => import(/* webpackChunkName: 'OrganizationDashboardSubscribers' */ '../pages/Dashboard/Organization/OrganizationAnalytics/OrganizationDashboardSubscribers'));
const OrganizationDashboardCampaigns = lazy(() => import(/* webpackChunkName: 'OrganizationDashboardCampaigns' */ '../pages/Dashboard/Organization/OrganizationAnalytics/OrganizationDashboardCampaigns/OrganizationDashboardCampaings'));
const OrganizationDashboardAutomations = lazy(() => import(/* webpackChunkName: 'OrganizationDashboardAutomations' */ '../pages/Dashboard/Organization/OrganizationAnalytics/OrganizationDashboardAutomation/OrganizationDashboardAutomation'));
const ProjectComparison = lazy(() => import(/* webpackChunkName: 'ProjectComparison' */ '../pages/Dashboard/Organization/ProjectComparison'));

const ProjectAnalyticsDashboard = lazy(() => import(/* webpackChunkName: 'ProjectDashboard' */ '../pages/Dashboard/Project/ProjectAnalyticsDashboard'));
const ProjectDashboardSubscribers = lazy(() => import(/* webpackChunkName: 'ProjectDashboardSubscribers' */ '../pages/Dashboard/Project/Subscribers'));
const ProjectDashboardCampaigns = lazy(() => import(/* webpackChunkName: 'ProjectDashboardCampaigns' */ '../pages/Dashboard/Project/Campaigns'));
const ProjectDashboardAutomations = lazy(() => import(/* webpackChunkName: 'ProjectDashboardAutomations' */ '../pages/Dashboard/Project/Automation'));

const AutomationList = lazy(() => import(/* webpackChunkName: 'AutomationList' */ '../pages/AutomationList/AutomationList'));
const NewAutomation = lazy(() => import(/* webpackChunkName: 'NewAutomation' */ '../components/Automation/NewAutomation'));
const EditAutomation = lazy(() => import(/* webpackChunkName: 'EditAutomation' */ '../components/Automation/EditAutomation'));

const ABCampaigns = lazy(() => import(/* webpackChunkName: 'ABCampaigns' */ '../pages/Campaigns/ABTestList/ABCampaigns'));
const PushCampaigns = lazy(() => import(/* webpackChunkName: 'PushCampaigns' */ '../pages/Campaigns/PushCampaignList/PushCampaigns'));
const PushCampaignsNew = lazy(() => import(/* webpackChunkName: 'PushCampaignsNew' */ '../pages/Campaigns/PushCampaignListNew/PushCampaigns'));
const NewCampaign = lazy(() => import(/* webpackChunkName: 'NewCampaign' */ '../components/NewCampaign'));
const NewPushCampaign = lazy(() => import(/* webpackChunkName: 'NewPushCampaign' */ '../components/PushCampaignCreator/New'));
const PushCampaignView = lazy(() => import(/* webpackChunkName: 'PushCampaignView' */ '../components/Campaign/push'));
const RocketPushCampaign = lazy(() => import(/* webpackChunkName: 'RocketPushCampaign' */ '../components/PushCampaignCreator/Rocket'));
const EditPushCampaign = lazy(() => import(/* webpackChunkName: 'EditPushCampaign' */ '../components/PushCampaignCreator/Edit'));
const ABReportView = lazy(() => import(/* webpackChunkName: "ABReportView" */ '../pages/Reports/ABTest/ABTestReport'));

const EditABTest = lazy(() => import(/* webpackChunkName: 'EditABTest' */ '../pages/ABTest/EditABTest'));
const NewABTest = lazy(() => import(/* webpackChunkName: 'NewABTest' */ '../pages/ABTest/NewABTest'));

const EditPush = lazy(() => import(/* webpackChunkName: 'EditABTest' */ '../pages/PushCreator/EditPush'));
const NewPush = lazy(() => import(/* webpackChunkName: 'NewABTest' */ '../pages/PushCreator/NewPush'));
const PushCampaignReportView = lazy(() => import(/* webpackChunkName: "PushCampaignReportView" */ '../pages/Reports/Push/PushReport'));

const ProjectEdit = lazy(() => import(/* webpackChunkName: 'ProjectEdit' */ '../pages/ProjectEdit/ProjectEdit'));
const ProjectIntegration = lazy(() => import(/* webpackChunkName: 'ProjectIntegration' */ '../pages/ProjectEdit/Integration'));

const Elements = lazy(() => import(/* webpackChunkName: 'Elements' */ '../pages/Elements'));
const SubscribeFormCreator = lazy(() => import(/* webpackChunkName: 'SubscribeForm' */ '../pages/FormCreator'));
const SubscriptionManager = lazy(() => import(/* webpackChunkName: 'MsgSidebar' */ '../pages/Elements/SubscriptionManager'));

const SubscribersPage = lazy(() => import(/* webpackChunkName: 'SubscribersPage' */ '../pages/SubscribersComponent/Subscribers'));
const SubscriberHistory = lazy(() => import(/* webpackChunkName: 'SubscribersHistory' */ '../pages/SubscriberHistory/SubscriberHistory'));

const SubscribersPageNew = lazy(() => import(/* webpackChunkName: 'SubscribersPage' */ '../pages/Subscribers/Subscribers'));
const SubscriberDetails = lazy(() => import(/* webpackChunkName: 'SubscribersHistory' */ '../pages/Subscribers/SubscriberDetails/SubscriberDetails'));

const Segmentation = lazy(() => import(/* webpackChunkName: 'Segmentation' */ '../pages/Segmentation/Segmentation'));
const NewSegment = lazy(() => import(/* webpackChunkName: 'NewSegment' */ '../pages/Segmentation/SegmentCreator/NewSegment'));
const EditSegment = lazy(() => import(/* webpackChunkName: 'EditSegment' */ '../pages/Segmentation/SegmentCreator/EditSegment'));

const Geolocation = lazy(() => import(/* webpackChunkName: 'Geolocation' */ '../pages/Geolocation'));
const Export = lazy(() => import(/* webpackChunkName: 'Export' */ '../pages/Export'));
const PhotoLibrary = lazy(() => import(/* webpackChunkName: "PhotoLibrary" */ '../pages/PhotoLibrary'));
const ProjectProviders = lazy(() => import(/* webpackChunkName: "ProjectProviders" */ '../pages/ProjectProviders/ProjectProviders'));


export const projectRoutes: IProjectRoutesTypes[] = [
  { exact: true, path: ProjectRoutesTypes.HOME, component: () => <Redirect to={ ProjectRoutesTypes.DASHBOARD }/> },
  { exact: true, title: t('Dashboard'), path: ProjectRoutesTypes.DASHBOARD, component: Dashboard },
  { title: t('View'), path: ProjectRoutesTypes.DASHBOARD_VIEW, component: Dashboard },

  { exact: true, title: t('Automation'), path: ProjectRoutesTypes.AUTOMATION, component: AutomationList },
  { title: t('List'), path: ProjectRoutesTypes.AUTOMATION_LIST, component: AutomationList },
  { title: t('New'), path: ProjectRoutesTypes.AUTOMATION_NEW, component: NewAutomation },
  { title: t('Selectors'), path: ProjectRoutesTypes.AUTOMATION_SELECTORS, component: SelectorsView },
  { exact: true, path: ProjectRoutesTypes.AUTOMATION_ID, component: EditAutomation },
  { title: t('Edit'), path: ProjectRoutesTypes.AUTOMATION_ID_EDIT, component: EditAutomation },

  { exact: true, title: t('Campaigns'), path: ProjectRoutesTypes.CAMPAIGN, component: () => <Redirect to={ ProjectRoutesTypes.CAMPAIGN_LIST_PUSH }/> },
  { exact: true, title: t('New Campaigns'), path: ProjectRoutesTypes.CAMPAIGN_NEW, component: NewCampaign },
  { title: t('Push'), path: ProjectRoutesTypes.CAMPAIGN_NEW_PUSH, component: NewPushCampaign },

  { title: t('AB Test'), path: ProjectRoutesTypes.CAMPAIGN_NEW_AB, component: NewABTest },
  { title: t('AB Test Edit'), path: ProjectRoutesTypes.CAMPAIGN_AB_EDIT, component: EditABTest },
  { title: t('AB Test Resend'), path: ProjectRoutesTypes.CAMPAIGN_AB_RESEND, component: EditABTest },

  { title: t('Push'), path: ProjectRoutesTypes.CAMPAIGN_NEW_TARGETED_PUSH, component: NewPush },
  { title: t('Push Edit'), path: ProjectRoutesTypes.CAMPAIGN_TARGETED_PUSH_ID_EDIT, component: EditPush },
  { title: t('Push Resend'), path: ProjectRoutesTypes.CAMPAIGN_TARGETED_PUSH_ID_RESEND, component: EditPush },
  { title: t('Push Report'), path: ProjectRoutesTypes.CAMPAIGN_TARGETED_PUSH_REPORT, component: PushCampaignReportView },

  { exact: true, title: t('Campaigns'), path: ProjectRoutesTypes.CAMPAIGN_LIST, component: () => <Redirect to={ ProjectRoutesTypes.CAMPAIGN_LIST_PUSH_ALL }/> },
  { exact: true, title: t('List'), path: ProjectRoutesTypes.CAMPAIGN_LIST_PUSH, component: () => <Redirect to={ ProjectRoutesTypes.CAMPAIGN_LIST_PUSH_ALL }/> },
  { title: t('List'), path: ProjectRoutesTypes.CAMPAIGN_LIST_PUSH_STATE, component: PushCampaigns },

  { title: t('List'), path: ProjectRoutesTypes.CAMPAIGN_LIST_PUSHES_STATE, component: PushCampaignsNew },
  { exact: true, title: t('List'), path: ProjectRoutesTypes.CAMPAIGN_LIST_PUSHES, component: () => <Redirect to={ ProjectRoutesTypes.CAMPAIGN_LIST_PUSHES_ALL }/> },

  { exact: true, title: t('List'), path: ProjectRoutesTypes.CAMPAIGN_LIST_AB, component: () => <Redirect to={ ProjectRoutesTypes.CAMPAIGN_LIST_AB_ALL }/> },
  { exact: true, title: t('List'), path: ProjectRoutesTypes.CAMPAIGN_LIST_AB_STATE, component: ABCampaigns },

  { title: t('AB Report'), path: ProjectRoutesTypes.AB_REPORT, component: ABReportView },

  { exact: true, title: t('Push campaign details'), path: ProjectRoutesTypes.CAMPAIGN_PUSH_ID, component: PushCampaignView },
  { title: t('Report'), path: ProjectRoutesTypes.CAMPAIGN_PUSH_ID_REPORT, component: PushCampaignView },
  { title: t('Edit'), path: ProjectRoutesTypes.CAMPAIGN_PUSH_ID_EDIT, component: EditPushCampaign },
  { title: t('Send again'), path: ProjectRoutesTypes.CAMPAIGN_PUSH_ID_RESEND, component: EditPushCampaign },
  { title: t('Rocket campaign'), path: ProjectRoutesTypes.CAMPAIGN_NEW_ROCKET, component: RocketPushCampaign },
  { exact: true, title: t('Geolocation'), path: ProjectRoutesTypes.GEOLOCATION, component: Geolocation },

  { exact: true, title: t('Subscriber list'), path: ProjectRoutesTypes.SUBSCRIBER_LIST, component: SubscribersPage },
  { exact: true, title: t('Subscriber history'), path: ProjectRoutesTypes.SUBSCRIBER_ID, component: SubscriberHistory },

  { exact: true, title: t('Subscriber list'), path: ProjectRoutesTypes.SUBSCRIBERS, component: SubscribersPageNew },
  { exact: true, title: t('Subscriber details'), path: ProjectRoutesTypes.SUBSCRIBER_DETAILS, component: SubscriberDetails },

  { exact: true, title: t('Export'), path: ProjectRoutesTypes.EXPORT, component: Export },
  { title: t('List'), path: ProjectRoutesTypes.EXPORT_LIST, component: Export },

  { exact: true, title: t('Project'), path: ProjectRoutesTypes.PROJECT, component: ProjectEdit },
  { path: ProjectRoutesTypes.PROJECT_SETTING, component: () => <Redirect to={ ProjectRoutesTypes.PROJECT }/> },
  { title: t('New Project'), path: ProjectRoutesTypes.PROJECT_NEW, component: NewProject },
  { title: t('Integration'), path: ProjectRoutesTypes.PROJECT_INTEGRATION, component: ProjectIntegration },
  { title: t('Providers'), path: ProjectRoutesTypes.PROJECT_PROVIDERS, component: ProjectProviders },

  { exact: true, path: ProjectRoutesTypes.TEMPLATES, component: () => <Redirect to={ ProjectRoutesTypes.TEMPLATES_ELEMENTS }/> },
  { title: t('Elements'), path: ProjectRoutesTypes.TEMPLATES_ELEMENTS, component: Elements },
  { title: t('Form Creator'), path: ProjectRoutesTypes.TEMPLATES_FORM_CREATOR, component: SubscribeFormCreator },
  { title: t('Inbox'), path: ProjectRoutesTypes.TEMPLATES_MSG_SIDEBAR, component: SubscriptionManager },

  { title: t('Photo Library'), path: ProjectRoutesTypes.PHOTO_LIBRARY, component: PhotoLibrary },

  { exact: true, title: t('Integrations'), path: ProjectRoutesTypes.INTEGRATIONS, component: Integrations },
  { title: t('Webhooks'), path: ProjectRoutesTypes.INTEGRATIONS_WEBHOOKS, component: WebhooksView },

  { path: ProjectRoutesTypes.USER_ACCESS_MANAGER, component: AccessManager },
  { path: ProjectRoutesTypes.USER_ACCOUNT, component: Account },
  { path: ProjectRoutesTypes.ORGANIZATION_DASHBOARD, component: OrganizationAnalyticsDashboard },
  { path: ProjectRoutesTypes.PROJECT_DASHBOARD, component: ProjectAnalyticsDashboard },
  { path: ProjectRoutesTypes.ORGANIZATION_PROJECTS_COMPARISON, component: ProjectComparison }, // todo: @Basia - comment when sth goes wrong
  { title: t('Payments'), path: PaymentsRoutesTypes.PAYMENTS, component: Payments },
  { title: t('Partnership'), path: ProjectRoutesTypes.USER_PARTNERSHIP, component: Partnership },

  { exact: true, title: t('Segmentation'), path: ProjectRoutesTypes.SEGMENTS, component: Segmentation },
  { exact: true, title: t('New Segment'), path: ProjectRoutesTypes.SEGMENTS_NEW, component: NewSegment },
  { exact: true, title: t('Edit Segment'), path: ProjectRoutesTypes.SEGMENTS_EDIT, component: EditSegment },
];

export const accessManagerRoutes = [
  { exact: true, title: t('Access Manager'), path: ProjectRoutesTypes.USER_ACCESS_MANAGER, component: () => <Redirect to={ ProjectRoutesTypes.USER_ACCESS_MANAGER_SHARE }/> },
  { exact: true, title: t('API Keys'), path: ProjectRoutesTypes.USER_ACCESS_MANAGER_KEYS, component: AccessManagerKeys },
  { exact: true, title: t('Share Project'), path: ProjectRoutesTypes.USER_ACCESS_MANAGER_SHARE, component: AccessManagerSharedProjects },
];

export const accountRoutes = [
  { exact: true, path: ProjectRoutesTypes.USER_ACCOUNT, component: () => <Redirect to={ ProjectRoutesTypes.USER_ACCOUNT_GENERAL_INFORMATION }/> },
  { exact: true, title: t('General information'), path: ProjectRoutesTypes.USER_ACCOUNT_INFO, component: UserInformation },
  { exact: true, title: t('Notifications'), path: ProjectRoutesTypes.USER_ACCOUNT_NOTIFICATIONS, component: NotificationChannels },
  { exact: true, title: t('Settings'), path: ProjectRoutesTypes.USER_ACCOUNT_SETTINGS, component: Settings },
  { exact: true, title: t('Settings'), path: ProjectRoutesTypes.USER_SETTINGS_TOKEN, component: Settings }
];

{/*todo @Basia - uncomment if sth goes wrong */
}
//export const analyticsDashboardRoutes: IProjectRoutesTypes[] = [
//  { exact: true, title: t('Organization analytics dashboard'), path: ProjectRoutesTypes.ORGANIZATION_DASHBOARD, component: () => <Redirect to={ ProjectRoutesTypes.DASHBOARD }/> },
//  { exact: true, title: t('Project comparison '), path: ProjectRoutesTypes.ORGANIZATION_PROJECTS_COMPARISON, component: () => <Redirect to={ ProjectRoutesTypes.DASHBOARD }/> },
//];

export const analyticsDashboardRoutes: IProjectRoutesTypes[] = [
  {
    exact: true,
    title: t('Organization analytics dashboard'),
    path: ProjectRoutesTypes.ORGANIZATION_DASHBOARD,
    component: () => <Redirect to={ ProjectRoutesTypes.ORGANIZATION_DASHBOARD_SUBSCRIBERS }/>
  },
  { exact: true, title: t('Project comparison '), path: ProjectRoutesTypes.ORGANIZATION_PROJECTS_COMPARISON, component: ProjectComparison },
  { exact: true, title: t('Organization dashboard subscribers'), path: ProjectRoutesTypes.ORGANIZATION_DASHBOARD_SUBSCRIBERS, component: OrganizationDashboardSubscribers },
  { exact: true, title: t('Organization dashboard campaigns '), path: ProjectRoutesTypes.ORGANIZATION_DASHBOARD_CAMPAIGNS, component: OrganizationDashboardCampaigns },
  { exact: true, title: t('Organization dashboard automations '), path: ProjectRoutesTypes.ORGANIZATION_DASHBOARD_AUTOMATIONS, component: OrganizationDashboardAutomations },
];

export const projectDashboardRoutes: IProjectRoutesTypes[] = [
  {
    exact: true,
    title: t('Project analytics dashboard'),
    path: ProjectRoutesTypes.PROJECT_DASHBOARD,
    component: () => <Redirect to={ ProjectRoutesTypes.PROJECT_DASHBOARD_SUBSCRIBERS }/>
  },
  { exact: true, title: t('Project dashboard subscribers'), path: ProjectRoutesTypes.PROJECT_DASHBOARD_SUBSCRIBERS, component: ProjectDashboardSubscribers },
  { exact: true, title: t('Project dashboard campaigns '), path: ProjectRoutesTypes.PROJECT_DASHBOARD_CAMPAIGNS, component: ProjectDashboardCampaigns },
  { exact: true, title: t('Project dashboard automations '), path: ProjectRoutesTypes.PROJECT_DASHBOARD_AUTOMATIONS, component: ProjectDashboardAutomations },
];

export const projectDashboardNavTabs: IUserNavigationTab[] = [
  { icon: 'subscribers', title: t('Subscribers'), linkTo: ProjectRoutesTypes.PROJECT_DASHBOARD_SUBSCRIBERS },
  { icon: 'bell', title: t('Campaigns'), linkTo: ProjectRoutesTypes.PROJECT_DASHBOARD_CAMPAIGNS },
  { icon: 'play', title: t('Automation'), linkTo: ProjectRoutesTypes.PROJECT_DASHBOARD_AUTOMATIONS },
];

export const accountNavigationTabs: Record<string, IUserNavigationTab[]> = {
  accessManager: [
    { icon: 'access', title: t('API Keys'), linkTo: ProjectRoutesTypes.USER_ACCESS_MANAGER_KEYS },
    { icon: 'projects', title: t('Shared projects'), linkTo: ProjectRoutesTypes.USER_ACCESS_MANAGER_SHARE }
  ],
  account: [
    { icon: 'user', title: t('Account'), linkTo: ProjectRoutesTypes.USER_ACCOUNT_INFO },
    { icon: 'bell', title: t('Notifications'), linkTo: ProjectRoutesTypes.USER_ACCOUNT_NOTIFICATIONS },
    { icon: 'settings', title: t('Settings'), linkTo: ProjectRoutesTypes.USER_ACCOUNT_SETTINGS }
  ],
  analyticsDashboard: [
    { icon: 'stat-lined', title: t('organization/dashboard::Organization dashboard'), linkTo: ProjectRoutesTypes.ORGANIZATION_DASHBOARD },
    { icon: 'stat-stacked', title: t('organization/dashboard::Project comparison'), linkTo: ProjectRoutesTypes.ORGANIZATION_PROJECTS_COMPARISON },
  ],
  analyticsDashboardNavigation: [
    { icon: 'subscribers', title: t('Subscribers'), linkTo: ProjectRoutesTypes.ORGANIZATION_DASHBOARD_SUBSCRIBERS },
    { icon: 'bell', title: t('Campaigns'), linkTo: ProjectRoutesTypes.ORGANIZATION_DASHBOARD_CAMPAIGNS },
    { icon: 'play', title: t('Automation'), linkTo: ProjectRoutesTypes.ORGANIZATION_DASHBOARD_AUTOMATIONS },
  ]
};


export interface IProjectRoutesTypes extends RouteProps {
  title?: string;
}
