import { ElementIdType } from "../../SubscriptionFormEnums";

export const customStylesMap = {
  [ElementIdType.BACKDROP]: {
    display: 'none',
    background: 'none',
    width: '100%',
    height: '100%',
    zIndex: 99999999999,
    position: 'fixed',
    top: 0,
    left: 0,
  },
  [ElementIdType.FORM]: {
    backgroundColor: '#ffffff',
    boxShadow: '0px 10px 40px 3px rgba(0, 0, 0, 0.3)',
    width: '600px',
    height: '350px',
    minHeight: '250px',
    borderRadius: '0',
    display: 'flex',
    zIndex: 999999999999,
    position: 'fixed',
    lineHeight: '1.2',
    flexDirection: 'row',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontFamily: 'sans-serif',
    '@media (min-width: 481px) and (max-width: 700px)': {
      flexDirection: 'column',
      width: '350px',
      height: '550px',
    },
    '@media (max-width: 480px)': {
      minHeight: '180px',
      width: '100%',
      height: 'auto',
      flexDirection: 'row',
      bottom: '0',
      top: 'auto',
      transform: 'translate(-50%)'
    },
  },
  [ElementIdType.SVG]: {},
  [ElementIdType.ICON]: {
    display: 'none',
    height: '25%',
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginTop: '10px'
  },
  [ElementIdType.PICTURE]: {
    background: '#f1f1f1',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    minWidth: '50%',
    height: '100%',
    margin: '0',
    width: 'auto',
    '@media (min-width: 481px) and (max-width: 700px)': {
      minWidth: '100%',
      minHeight: '50%',
      height: '50%',
      margin: '0',
      width: 'auto',
    },
    '@media (max-width: 480px)': {
      height: '180px',
      minWidth: '40%',
      width: '40%',
      margin: 'auto 0',
      backgroundSize: 'cover',
    },
  },
  [ElementIdType.CONTENT]: {
    padding: "3% 3% 20px 3%",
    display: 'flex',
    justifyContent: 'space-evenly',
    minHeight: '200px',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    boxSizing: 'border-box',
    '@media (min-width: 481px) and (max-width: 700px)': {
      minWidth: '100%',
      height: '100%',
    },
    '@media (max-width: 480px)': {
      width: '100%',
      minHeight: '160px',
      padding: '8px 1% 15px 1%',
      justifyContent: 'space-evenly',
      alignSelf: 'center',
    },
  },
  [ElementIdType.HEADER]: {
    color: '#585858',
    textAlign: 'center',
    fontWeight: '700',
    direction: 'ltr',
    alignSelf: 'stretch',
    fontSize: '20px',
    margin: '5px',
    '@media (max-width: 480px)': {
      lineHeight: '1.2em',
      fontSize: '14px',
      margin: '0 5px',
    }
  },
  [ElementIdType.DESCRIPTION]: {
    fontSize: '14px',
    color: '#585858',
    direction: 'ltr',
    alignSelf: 'stretch',
    textAlign: 'center',
    lineHeight: '24px',
    margin: '5px',
    '@media (max-width: 480px)': {
      lineHeight: '1.2em',
      fontSize: '12px',
    }
  },
  [ElementIdType.MULTICHANNEL]: {
    display: 'none',
    border: '1px solid #dedede',
    padding: '8px 10px',
    borderRadius: '2px',
    margin: '5px auto',
    width: '100%',
    maxWidth: '300px',
    '&::placeholder': {
      color: '#bcbcbc',
    }
  },
  [ElementIdType.BUTTONS]: {
    textAlign: 'center',
    '@media (max-width: 480px)': {
      fontSize: '12px',
      lineHeight: '14px',
    },
  },
  [ElementIdType.DENY]: {
    margin: '5px',
    borderStyle: 'solid',
    borderWidth: '2px',
    height: '33px',
    direction: 'ltr',
    lineHeight: '1em',
    padding: '0 15px',
    textDecoration: 'none',
    fontSize: '1em',
    color: '#808080',
    textTransform: 'none',
    borderColor: '#ffffff',
    fontWeight: 400,
    backgroundColor: '#ffffff',
    backgroundImage: 'none',
    cursor: 'pointer',
    overflow: 'visible',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    position: 'relative',
    verticalAlign: 'baseline',
    transition: 'background-color .2s, border-color .2s',
    '&::after, &::before': {
      content: 'none',
    },
    '&:hover': {
      color: '#808080',
    },
    '@media (max-width: 480px)': {
      height: '30px',
      padding: '0 10px',
      margin: '2px',
      fontSize: '12px',
    }
  },
  [ElementIdType.ALLOW]: {
    margin: '5px',
    height: '33px',
    lineHeight: '1em',
    fontSize: '1em',
    padding: '0 15px',
    borderStyle: 'solid',
    textDecoration: 'none',
    fontWeight: '700',
    borderWidth: '2px',
    color: '#F9F9F9',
    direction: 'ltr',
    textTransform: 'none',
    borderColor: '#16a085',
    backgroundColor: '#16a085',
    backgroundImage: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    overflow: 'visible',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    position: 'relative',
    verticalAlign: 'baseline',
    transition: 'background-color .2s, border-color .2s',
    '&::after, &::before': {
      content: 'none',
    },
      '&:hover': {
      color: '#F9F9F9',
    },
    '@media (max-width: 480px)': {
      height: '30px',
      padding: '0 10px',
      margin: '2px',
      fontSize: '12px',
    }
  },
  [ElementIdType.POWERED_BY]: {
    position: 'absolute',
    bottom: '5px',
    fontSize: '10px',
    '@media (max-width: 480px)': {
      fontSize: '9px',
      bottom: '3px',
    }
  },
  [ElementIdType.POWERED_BY_TEXT]: {
    color: '#70787f',
    textDecoration: 'none',
    display: 'inline',
    fontSize: '10px',
    fontWeight: 400,
    transition: 'none',
    verticalAlign: 'baseline',
    letterSpacing: 'normal',
    position: 'relative',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.2',
    '&:link, &:visited, &:active': {
      color: '#70787f',
      textDecoration: 'none',
    },
    '&::after, &::before': {
      content: 'none'
    },
    '@media (max-width: 480px)': {
      fontSize: '9px',
    },
  },
  [ElementIdType.CLOSE]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#70787f',
    cursor: 'pointer',
    width: '30px',
    height: '30px',
    position: 'absolute',
    right: '0',
    top: '0',
    '&:after': {
      position: 'absolute',
      content: '"\\2715"',
      fontSize: '24px',
      lineHeight: '24px'
    }
  }
};

export const customRtlStylesMap = {
  [ElementIdType.BACKDROP]: {
    display: 'none',
    background: 'none',
    width: '100%',
    height: '100%',
    zIndex: 99999999999,
    position: 'fixed',
    top: 0,
    left: 0,
  },
  [ElementIdType.FORM]: {
    backgroundColor: '#ffffff',
    boxShadow: '0px 10px 40px 3px rgba(0, 0, 0, 0.3)',
    width: '600px',
    height: '350px',
    minHeight: '250px',
    borderRadius: '0',
    display: 'flex',
    zIndex: 999999999999,
    position: 'fixed',
    lineHeight: '1.2',
    flexDirection: 'row-reverse',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontFamily: 'sans-serif',
    '@media (min-width: 481px) and (max-width: 700px)': {
      flexDirection: 'column',
      width: '350px',
      height: '550px',
    },
    '@media (max-width: 480px)': {
      minHeight: '180px',
      width: '100%',
      height: 'auto',
      flexDirection: 'row',
      bottom: '0',
      top: 'auto',
      transform: 'translate(-50%)'
    },
  },
  [ElementIdType.SVG]: {},
  [ElementIdType.ICON]: {
    display: 'none',
    height: '25%',
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginTop: '10px'
  },
  [ElementIdType.PICTURE]: {
    background: '#f1f1f1',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    minWidth: '50%',
    height: '100%',
    margin: '0',
    width: 'auto',
    '@media (min-width: 481px) and (max-width: 700px)': {
      minWidth: '100%',
      minHeight: '50%',
      height: '50%',
      margin: '0',
      width: 'auto',
    },
    '@media (max-width: 480px)': {
      height: '180px',
      minWidth: '40%',
      width: '40%',
      margin: 'auto 0',
      backgroundSize: 'cover',
    },
  },
  [ElementIdType.CONTENT]: {
    padding: "3% 3% 20px 3%",
    display: 'flex',
    justifyContent: 'space-evenly',
    minHeight: '200px',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    boxSizing: 'border-box',
    '@media (min-width: 481px) and (max-width: 700px)': {
      minWidth: '100%',
      height: '100%',
    },
    '@media (max-width: 480px)': {
      width: '100%',
      minHeight: '160px',
      padding: '8px 1% 15px 1%',
      justifyContent: 'space-evenly',
      alignSelf: 'center',
    },
  },
  [ElementIdType.HEADER]: {
    color: '#585858',
    textAlign: 'center',
    alignSelf: 'stretch',
    fontWeight: '700',
    direction: 'rtl',
    fontSize: '20px',
    margin: '5px',
    '@media (max-width: 480px)': {
      lineHeight: '1.2em',
      fontSize: '14px',
      margin: '0 5px',
    }
  },
  [ElementIdType.DESCRIPTION]: {
    fontSize: '14px',
    color: '#585858',
    textAlign: 'center',
    direction: 'rtl',
    alignSelf: 'stretch',
    lineHeight: '24px',
    margin: '5px',
    '@media (max-width: 480px)': {
      lineHeight: '1.2em',
      fontSize: '12px',
    }
  },
  [ElementIdType.MULTICHANNEL]: {
    display: 'none',
    border: '1px solid #dedede',
    padding: '8px 10px',
    borderRadius: '2px',
    margin: '5px auto',
    width: '100%',
    maxWidth: '300px',
    '&::placeholder': {
      color: '#bcbcbc',
    }
  },
  [ElementIdType.BUTTONS]: {
    textAlign: 'center',
    '@media (max-width: 480px)': {
      fontSize: '12px',
      lineHeight: '14px',
    },
  },
  [ElementIdType.DENY]: {
    margin: '5px',
    borderStyle: 'solid',
    borderWidth: '2px',
    height: '33px',
    lineHeight: '1em',
    padding: '0 15px',
    textDecoration: 'none',
    fontSize: '1em',
    direction: 'rtl',
    color: '#808080',
    textTransform: 'none',
    borderColor: '#ffffff',
    fontWeight: 400,
    backgroundColor: '#ffffff',
    cursor: 'pointer',
    overflow: 'visible',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    position: 'relative',
    verticalAlign: 'baseline',
    transition: 'background-color .2s, border-color .2s',
    '&:hover': {
      color: '#808080',
    },
    '&::after, &::before': {
      content: 'none'
    },
    '@media (max-width: 480px)': {
      height: '30px',
      padding: '0 10px',
      margin: '2px',
      fontSize: '12px',
    }
  },
  [ElementIdType.ALLOW]: {
    margin: '5px',
    height: '33px',
    lineHeight: '1em',
    fontSize: '1em',
    padding: '0 15px',
    borderStyle: 'solid',
    textDecoration: 'none',
    fontWeight: '700',
    borderWidth: '2px',
    color: '#F9F9F9',
    direction: 'rtl',
    textTransform: 'none',
    borderColor: '#16a085',
    backgroundColor: '#16a085',
    borderRadius: '5px',
    cursor: 'pointer',
    overflow: 'visible',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    position: 'relative',
    verticalAlign: 'baseline',
    transition: 'background-color .2s, border-color .2s',
    '&::after, &::before': {
      content: 'none',
    },
    '&:hover': {
      color: '#F9F9F9',
    },
    '@media (max-width: 480px)': {
      height: '30px',
      padding: '0 10px',
      margin: '2px',
      fontSize: '12px',
    }
  },
  [ElementIdType.POWERED_BY]: {
    position: 'absolute',
    bottom: '5px',
    fontSize: '10px',
    '@media (max-width: 480px)': {
      fontSize: '9px',
      bottom: '3px',
    }
  },
  [ElementIdType.POWERED_BY_TEXT]: {
    color: '#70787f',
    textDecoration: 'none',
    display: 'inline',
    fontSize: '10px',
    fontWeight: 400,
    transition: 'none',
    verticalAlign: 'baseline',
    letterSpacing: 'normal',
    position: 'relative',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.2',
    '&:link, &:visited, &:active': {
      color: '#70787f',
      textDecoration: 'none',
    },
    '&::after, &::before': {
      content: 'none'
    },
    '@media (max-width: 480px)': {
      fontSize: '9px',
    },
  },
  [ElementIdType.CLOSE]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#70787f',
    cursor: 'pointer',
    width: '30px',
    height: '30px',
    position: 'absolute',
    right: '0',
    top: '0',
    '&:after': {
      position: 'absolute',
      content: '"\\2715"',
      fontSize: '24px',
      lineHeight: '24px'
    }
  }
};

export const poweredProperties = {
  href: 'https://pushpushgo.com/en/?utm_medium=form&utm_source=https://n.porannakawka.com/&utm_campaign=referal',
  target: '_blank'
};

export const customAnimations = {
  '@keyframes subtleAppear': {
    from: { opacity: .2 },
    to: { opacity: 1 }
  },
  '@keyframes slideTop': {
    from: {
      opacity: 0,
      transform: 'translate(-50%, -100%)'
    },
    to: {
      opacity: 1,
      transform: 'translate(-50%, -50%)'
    }
  },
  '@keyframes top-slideTop': {
    from: {
      opacity: 0,
      transform: 'translate(-50%, -50%)'
    },
    to: {
      opacity: 1,
      transform: 'translate(-50%, 0)'
    }
  },
  '@keyframes slideBottom': {
    from: {
      opacity: 0,
      transform: 'translate(-50%, 0%)'
    },
    to: {
      opacity: 1,
      transform: 'translate(-50%, -50%)'
    }
  },
  '@keyframes top-slideBottom': {
    from: {
      opacity: 0,
      transform: 'translate(-50%, 50%)'
    },
    to: {
      opacity: 1,
      transform: 'translate(-50%, 0)'
    }
  },
  '@keyframes jumpTop': {
    '0%': {
      transform: 'translate(-50%, -90%)',
      opacity: 0,
    },
    '60%': {
      transform: 'translate(-50%, -45%)',
      opacity: 1,
    },
    '80%': {
      transform: 'translate(-50%, -52%)',
    },
    '100%': {
      transform: 'translate(-50%, -50%)',
    }
  },
  '@keyframes top-jumpTop': {
    '0%': {
      transform: 'translate(-50%, -40%)',
      opacity: 0,
    },
    '60%': {
      transform: 'translate(-50%, 5%)',
      opacity: 1,
    },
    '80%': {
      transform: 'translate(-50%, -2%)',
    },
    '100%': {
      transform: 'translate(-50%, 0)',
    }
  },
  '@keyframes jumpBottom': {
    '0%': {
      transform: 'translate(-50%, 0%)',
      opacity: 0,
    },
    '60%': {
      transform: 'translate(-50%, -55%)',
      opacity: 1,
    },
    '80%': {
      transform: 'translate(-50%, -48%)',
    },
    '100%': {
      transform: 'translate(-50%, -50%)',
    }
  }, '@keyframes top-jumpBottom': {
    '0%': {
      transform: 'translate(-50%, 50%)',
      opacity: 0,
    },
    '60%': {
      transform: 'translate(-50%, -5%)',
      opacity: 1,
    },
    '80%': {
      transform: 'translate(-50%, 2%)',
    },
    '100%': {
      transform: 'translate(-50%, 0)',
    }
  },
};


export const svgProperties = {
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  width: '30',
  height: '30',
  viewBox: '0 0 32 32',
};

export const svgPath1 = {
  fill: '#585858',
  d: 'M17.629 8.567c0 0.9-0.729 1.629-1.629 1.629v0c-0.9 0-1.63-0.729-1.63-1.629v-3.716c0-0.9 0.73-1.63 1.63-1.63v0c0.9 0 1.629 0.729 1.629 1.63v3.716z',
};

export const svgPath2 = {
  fill: '#585858',
  d: 'M13.129 25.909c0 1.586 1.286 2.871 2.871 2.871s2.872-1.285 2.872-2.871v-0.404h-5.742v0.404z',
};

export const svgPath3 = {
  fill: '#585858',
  d: 'M26.246 20.096c0-0.161-0.121-0.292-0.27-0.292-1.423-0.224-3.537-0.582-3.769-4.41-0.603-9.938-5.54-10.107-6.206-10.090-0.668-0.017-5.605 0.152-6.207 10.090-0.232 3.828-2.346 4.187-3.77 4.41-0.148 0-0.269 0.131-0.269 0.292v3.793c0 0.011 0.002 0.024 0.003 0.036l-0.003 0.051h0.014c0.034 0.118 0.134 0.206 0.255 0.206h19.952c0.122 0 0.222-0.087 0.256-0.206h0.014l-0.003-0.051c0.002-0.012 0.003-0.024 0.003-0.036v-3.793z',
};
