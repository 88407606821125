import { API, UseCase } from '@ppg/common';
import { PlatformType } from '../../interfaces';

interface IGetActiveSubscribersRequest {
  platform?: PlatformType;
}

interface IGetActiveSubscribersResult {
  activeSubscribers: number;
}

export class GetActiveSubscribersUseCase extends UseCase<IGetActiveSubscribersRequest, IGetActiveSubscribersResult> {
  protected async execute({ platform }: IGetActiveSubscribersRequest) {
    if (API.HTTP.currentProjectId) {
      return API.HTTP.get<IGetActiveSubscribersResult>(`statistics/v2/projects/${ API.HTTP.currentProjectId }/subscribers/active/${ platform ? platform : '' }`);
    }
  }
}
