import { API, UseCase } from '@ppg/common';

interface IRemoveRecurring {
  organization: string;
  cardId: string;
}

export class RemoveRecurringCardUseCase extends UseCase<IRemoveRecurring, void> {
  protected async execute({ organization, cardId }: IRemoveRecurring) {
    return API.HTTP.delete<void>(`acc/organizations/${ organization }/payments/cards/recurring`, {
      card: cardId
    })
  }
}

export const removeRecurringCardUseCase = new RemoveRecurringCardUseCase();
