import { API, UseCase } from '@ppg/common';
import { IUpdateCustomSelector } from './interfaces';

export class UpdateCustomSelectorUseCase extends UseCase<IUpdateCustomSelector, {}> {
  protected async execute({ projectID, selectorID, selector, labelKey, labelStrategy, labelTTL, prettyName, tagLabel, event, attribute, valueType, reducer }) {
    return API.HTTP.put(`core/projects/${ projectID }/selectors/${ selectorID }/custom`, {
      selector,
      prettyName,
      tagLabel,
      event,
      attribute,
      valueType,
      reducer,
      labelKey,
      labelStrategy,
      labelTTL,
    });
  }
}

export const updateCustomSelectorUseCase = new UpdateCustomSelectorUseCase();
