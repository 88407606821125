import { API, UseCase } from "@ppg/common";
import { IListQueryOptions } from '../../../interfaces/IListQueryOptions';
import { IListResponse } from '../../../interfaces/IListResponse';
import { ISegmentConditionPlainObject } from './CreateSegmentUseCase';

interface IGetSegmentsRequest extends IListQueryOptions {
  projectId: string;
  sortBy: string;
  name: string;
}

export interface ISegmentDTO {
  id: string;
  project: string;
  name: string;
  conditions: ISegmentConditionPlainObject;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  state: string;
  audience: number;
}

export interface IGetSegmentsResponse extends IListResponse<ISegmentDTO[]> {}

export class GetSegmentsUseCase extends UseCase<IGetSegmentsRequest, IGetSegmentsResponse> {
  protected async execute({ projectId, offset, limit, sortBy, name }: IGetSegmentsRequest) {
    const result = await API.HTTP.get<IGetSegmentsResponse>(`core/projects/${ projectId }/segment?offset=${ offset }&limit=${ limit }&sortBy=${ sortBy }&name=${ name }`);

    const data: any[] = result.data.map(segment => {
      return {...segment, conditions: segment.conditions.conditions.map(conditionGroup => conditionGroup.conditions) }
    })

    return {metadata: result.metadata, data}
  }
}

export const getSegmentsUseCase = new GetSegmentsUseCase();
