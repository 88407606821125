import { property, PropertyHandler } from '@ppg/common';
import { observable } from 'mobx';
import { CurrencyType } from './CurrencyType';

export interface IInvoice {
  id: string;
  type: string;
  number: string;
  amount: number;
  currency: string;
  paymentTo: string;
  createdAt: string;
  correction: boolean;
}

export class Invoice extends PropertyHandler implements IInvoice {
  @property() public id: string;
  @property() public type: string;
  @property() public number: string;
  @property() public amount: number;
  @property() public currency: string;
  @property() public paymentTo: string;
  @property() public createdAt: string;
  @property() public correction: boolean;
  @property() public selected: boolean;

  @observable public isEnabled: boolean;

  constructor(invoice) {
    super();
    this.id = invoice.id;
    this.type = invoice.type;
    this.number = invoice.number;
    this.amount = invoice.amount;
    this.currency = invoice.currency;
    this.paymentTo = invoice.paymentTo;
    this.createdAt = invoice.createdAt;
    this.correction = invoice.correction;
    this.selected = invoice.selected || false;
    this.isEnabled = invoice.isEnabled || false;
  }

  static createInvoice(invoice: IInvoice) {
    return new Invoice({
      id: invoice.id,
      type: invoice.type,
      number: invoice.number,
      amount: invoice.amount,
      currency: invoice.currency,
      paymentTo: invoice.paymentTo,
      createdAt: invoice.createdAt,
      correction: invoice.correction,
      selected: false,
      isEnabled: false,
    })
  }

  public setEnable() {
    this.isEnabled = true;
  }

  public setDisable() {
    this.isEnabled = false;
  }

  public reset() {
    this.getProperty('selected').setValue(false);
    this.isEnabled = false;
  }

  static currencyOptions() {
    return Object.keys(CurrencyType).map(type => {
      return {
        name: type,
        value: CurrencyType[type]
      }
    })
  }
}
