import { UserRelatedStore } from './UserRelatedStore';
import { property } from '@ppg/common';
import { action, computed, observable, reaction } from 'mobx';
import { Channel, EditChannelProxy } from '../../modelsMobx/Channel';
import { ITEMS_PER_PAGE } from '../../constants';
import { listChannelsUseCase } from '../../useCases/notification/ListChannelsUseCase';
import { removeChannelUseCase } from '../../useCases/notification/RemoveChannelUseCase';
import { createEmailChannelUseCase } from '../../useCases/notification/CreateEmailChannelUseCase';
import { updateTagsUseCase } from '../../useCases/notification/UpdateTagsUseCase';
import { getAvailableOptionsUseCase } from '../../useCases/notification/GetAvailableOptionsUseCase';
import { changeLanguageUseCase } from '../../useCases/notification/ChangeLanguageUseCase';
import { toast } from '@ppg/styled';
import { t } from '../../base/helpers';

export class ChannelsStore extends UserRelatedStore {
  @property() public channels: Channel[] = [];
  @property() public isLoading: boolean;
  @property() public channelToEdit: EditChannelProxy;
  @property() public infinity: boolean;

  @property() public tags: string[] = [];
  @property() public languages: string[] = [];
  @property() public offset: number = 0;
  @property() public perPage: number = ITEMS_PER_PAGE;
  @property() public errors: string[] = [];

  @observable public total: number = 0;
  @observable public newChannel: Channel = null;

  fetchOnPaginationChange = reaction(
    () => this.doFetch,
    () => this.listChannels(),
  );

  async listChannels() {
    this.isLoading = true;
    listChannelsUseCase.exec({
      limit: this.perPage,
      offset: this.offset,
      organization: this.userStore.user.organization
    }).then(({ data, metadata }) => {
        const channels = data.map((channel) => Channel.createChannel({
          id: channel.id,
          language: channel.language,
          payload: channel.payload,
          status: channel.status,
          tags: channel.tags,
          type: channel.type
        }));
        this.total = metadata.total;
        this.channels = this.infinity ? this.channels.concat(channels) : channels;
        this.isLoading = false;
      })
      .catch((error) => console.error(error));

  }

  async createChannel() {
    await createEmailChannelUseCase.exec({
      organization: this.userStore.user.organization,
      receiverEmail: this.newChannel.payload,
      tags: this.newChannel.tags,
      language: this.newChannel.language,
      organizationType: this.userStore.user.organizationType
    }).then((data) => {
        const channel = Channel.createChannel({
          id: data.id,
          language: data.language,
          payload: data.payload,
          status: data.status,
          tags: data.tags,
          type: data.channelType
        });
        this.channels = [channel].concat(this.channels);
        this.total++;

      })
      .catch((error) => {
        this.parseErrors(error);
      })
      .finally(() => {
        if (this.errors.length === 0) {
          this.newChannel = null;
        }
        this.errors = [];
      });
  }

  async removeChannel(channelId: string) {
    this.isLoading = true;
    await removeChannelUseCase.exec({
      organization: this.userStore.user.organization,
      channel: channelId
    }).then(() => toast.success(t('Your channel has been deleted successfully')))
      .then(() => {
        this.total--;
        this.channels = [];
        if (this.infinity) {
          this.offset === 0 ? this.listChannels() : this.offset = 0;
        } else {
          this.listChannels();
        }
      })
      .finally(() => this.onChannelCancel());
  }

  async updateTags(channel: Channel): Promise<void> {
    return updateTagsUseCase.exec({
      organization: this.userStore.user.organization,
      channel: channel.id,
      tags: channel.tags,
    }).catch((error) => console.error(error));
  }

  async updateLanguage(channel: Channel): Promise<void> {
    return changeLanguageUseCase.exec({
      organization: this.userStore.user.organization,
      channel: channel.id,
      language: channel.language
    }).catch((error) => console.error(error));
  }

  async getAvailableOptions() {
    await getAvailableOptionsUseCase.exec({
        organization: this.userStore.user.organization
      })
      .then(({ languages, tags }) => {
        this.languages = languages;
        this.tags = tags;
      });
  }

  public async fetchChannels() {
    if (this.channels.length > 0) {
      return;
    }
    this.listChannels();
  }

  public parseErrors(error) {
    error && this.errors.push(error.message);
  }

  public hasData() {
    return this.channels.length > 0;
  }

  @computed
  get doFetch() {
    return {
      offset: this.offset,
      perPage: this.perPage,
    };
  }

  public onChannelEdit(channel: Channel) {
    this.channelToEdit = new EditChannelProxy(channel);
  }

  public onChannelCreate() {
    this.newChannel = Channel.createChannel();
  }

  public onChannelCancel() {
    this.channelToEdit = null;
    this.newChannel = null;
    this.errors = [];
  }

  public reset() {
    this.channels = [];
    this.offset = 0;
    this.total = 0;
    this.infinity = false;
    this.isLoading = true;
    this.perPage = ITEMS_PER_PAGE;
    this.languages = [];
    this.tags = [];
    this.errors = [];
    this.onChannelCancel();
  }

  @action
  public setInfinity(infinity: boolean): void {
    this.infinity = infinity;
  }
}
