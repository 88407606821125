import { toast } from '@ppg/styled';
import 'emoji-mart/css/emoji-mart.css';
import 'milligram/dist/milligram.css';
import * as React from 'react';
import 'react-day-picker/lib/style.css';
import * as ReactDOM from 'react-dom';
import '@ppg/styled/dist/styled.css';

import 'react-image-crop/dist/ReactCrop.css';
import { history } from './base/helpers';
import { Router } from 'react-router-dom';
import './assets/less/main.less';
import { connector } from './base';

import { TriggeredPushForm } from './components/Automation/TriggeredPushForm/TriggeredPushForm';

import * as stores from './stores';

import {
  AutomationFlowForm,
  PushCampaignForm,
} from './forms';

import { WebUser } from './models';

import * as Sentry from '@sentry/browser';
import { AuthError, Config, UseCase } from '@ppg/common';

import { Provider } from 'mobx-react';
import App from './App';
import { AutomationsViewForm } from './forms/AutomationsViewForm';

(async () => {
  await Config.initializeConfig();
  connector
    .model('user', new WebUser())
    .model('pushCampaignForm', new PushCampaignForm())
    .model('automationFlowForm', new AutomationFlowForm())
    .model('automationsViewForm', new AutomationsViewForm())
    .model('triggeredPushForm', new TriggeredPushForm())

  const config = Config.getConfig();
  let isLoggedOut = false;
  window['__version'] = process.env.CI_COMMIT_SHORT_SHA;

  Sentry
    .init({
      dsn: config.sentry,
      release: process.env.RELEASE,
      environment: process.env.NODE_ENV,
      ignoreErrors: [
        /Access is denied/,
      ]
    });

  Sentry.setTag('lastCommit', process.env.COMMIT);

  UseCase.registerGlobalErrorHandler((error) => {


    if (error instanceof AuthError) {

      stores.userStore.logout().then(() => {
        if (!isLoggedOut) isLoggedOut = true;
      });
    }

    if (typeof error === 'string') {
      toast.error(error);
    }

    if (error.message) {
      toast.error(error.message);
    }

    if (Array.isArray(error)) {
      error.forEach(error => {
        toast.error(error)
      })
    }

    throw error;
  });

  Sentry.withScope(() => {
    ReactDOM.render(
      <Provider { ...stores }>
        <Router history={ history }>
          <App/>
        </Router>
      </Provider>,
      document.getElementById('ppg-app')
    );
  });
})();

