export class SimpleProject {
  public readonly id?: string;
  public readonly name: string;
  public readonly siteUrl: string;
  public readonly accountOwner: string;
  public readonly enabled: boolean;

  public isIntegrated: boolean;

  constructor(
    project: any
  ) {
    this.id = project.id;
    this.name = project.name;
    this.siteUrl = project.siteUrl;
    this.accountOwner = project.accountOwner.id;
    this.enabled = project.enabled;
    this.isIntegrated = project.isIntegrated;
  }

  public isOwnedBy(userId: string): boolean {
    return this.accountOwner === userId;
  }

}
