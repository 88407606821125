import cs from 'classnames';
import * as React from 'react';
import { canCompileTest, t } from '../../base/helpers';
import { Render } from '../Render/Render';
import { AbstractFiled } from './AbstractFiled';
import { ITextareaProps, ITextareaState } from './ITextareaProps';
import { Picker } from 'emoji-mart';
import { flagEmojiReg } from '@ppg/common';
import { Help } from '@ppg/styled';

export class Textarea extends AbstractFiled<ITextareaProps, ITextareaState> {
  private el: HTMLTextAreaElement;
  private emojiPicker;
  private emojiButton;

  constructor(props) {
    super(props);
    this.emojiPicker = (React as any).createRef();
    this.emojiButton = (React as any).createRef();
    this.handleClosePicker = this.handleClosePicker.bind(this);
    this.state = { showPicker: false, caretPosition: 0 }
  }

  private getStartCaretPosition() {
    const { attribute } = this.props;
    const startPosition = attribute && attribute.getValue() ? attribute.getValue().toString().length : 0;
    this.setState({ caretPosition: startPosition });
  }

  public toggleShowPicker = () => {
    this.setState({ showPicker: this.state.showPicker !== true });
  };

  public setClosePicker = () => {
    this.setState({ showPicker: false });
  }

  public componentDidMount() {
    this.getStartCaretPosition();
    document.addEventListener('click', this.handleClosePicker);
  }

  public componentWillUnmount() {
    document.removeEventListener('click', this.handleClosePicker);
  }

  public handleClosePicker = (event) => {
    const emojiButtonRef = this.emojiButton && this.emojiButton.current;
    const emojiPickerRef = this.emojiPicker && this.emojiPicker.current;
    if (emojiPickerRef && !this.emojiPicker.current.contains(event.target) && (event.target !== emojiButtonRef)) {
      this.setClosePicker();
    }
  }

  public addEmoji(e) {
    const { attribute } = this.props;
    const message = attribute.getValue();
    const emoji = e.native;
    const index = this.state.caretPosition;
    const messageWithEmoji = [message.slice(0, index), emoji, message.slice(index)].join('');
    attribute.setValue(messageWithEmoji);
  }

  private canCompile() {
    return this.props.scope && canCompileTest(this.props.attribute.getValue());
  }

  private isCharactersLimitReached():boolean {
    return this.props.attribute.getValue().length > this.props.charactersLimit;
  }

  private getCurrentCaretPosition(event: React.FormEvent<HTMLTextAreaElement>) {
    this.setState({ caretPosition: event.currentTarget.selectionStart });
  }

  public handleOnChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
    this.setState({ caretPosition: event.currentTarget.selectionStart });
    this.props.attribute.setValue(event.currentTarget.value);
  }

  private isFlagEmojiEntered():boolean {
    return flagEmojiReg.test(this.props.attribute.getValue())
  }

  public render() {
    const { attribute, label, placeholder, scope, emoji, isDirectionRtl, emojiPosition, characterCounter, charactersLimit } = this.props;

    const textareaClasses = [
      'input-wrapper',
      !!isDirectionRtl ? 'direction--rtl' : '',
      !!emoji ? 'emoji-panel' : ''
    ];

    return (
      <div className={ cs(textareaClasses, this.getClassName()) }>
        <div>
          <label htmlFor={ this.inputId }>{ label }</label>

          <textarea id={ this.inputId }
                    ref={ el => this.el = el }
                    onKeyUp={ (e) => this.getCurrentCaretPosition(e) }
                    onClick={ (e) => this.getCurrentCaretPosition(e) }
                    onChange={ (e) => this.handleOnChange(e) }
                    value={ attribute.getValue() || "" }
                    placeholder={ placeholder }/>
          { emoji && <span className={ `emoji-opener ${ this.state.showPicker ? 'active' : '' }` }
                           ref={ this.emojiButton }
                           onClick={ this.toggleShowPicker }/> }
          { emoji && this.state.showPicker &&
          <div className={ `emoji-position-${ emojiPosition ? emojiPosition : 'bottom' }` }
               ref={ this.emojiPicker }>
            <Picker title={ "" }
                    emoji={ "" }
                    set={ 'facebook' }
                    style={ { width: '320px' } }
                    onSelect={ (e) => this.addEmoji(e) }/>
          </div> }

        </div>
        { this.isFlagEmojiEntered() && <span className={'flag-icon-warning text-red m10l m15b'}>
          {t('Be careful! Windows users will not be able to see the flag icon')}
          <Help content={t('Microsoft doesn\'t support any country flags on Windows, instead showing the two-letter country codes')} />
        </span>}
        { characterCounter && <span className={`character-counter ${this.isCharactersLimitReached() && 'text-red'}`}>{ attribute.getValue().length} / { charactersLimit } </span>}
        { this.canCompile() && <span className="m10l m15b"><b>{ t('Preview compiled: ') }</b><i><Render silent={ true } scope={ scope } fallback={ "" }>{ attribute.getValue() }</Render></i></span> }
        { this.renderErrors() }
        { this.renderWarnings() }
      </div>
    );
  }
}
