import { API, UseCase } from "@ppg/common";

interface ICancelABTest {
  abTestId: string;
}

export class CancelABTestUseCase extends UseCase<ICancelABTest, void> {
  protected async execute({ abTestId }: ICancelABTest) {
    return API.HTTP.post<void>(`core/projects/${ API.HTTP.currentProjectId }/ab-tests/${ abTestId }/cancel`);
  }
}

export const cancelABTestUseCase = new CancelABTestUseCase();
