import { API, UseCase } from "@ppg/common";
import { ICampaignId, IGetPolygonsPushCampaign } from '../interfaces';

interface IGetGeolocationPushRequest extends ICampaignId {}
interface IGetGeolocationPushResponse extends IGetPolygonsPushCampaign {}

export class GetGeolocationPushUseCase extends UseCase<IGetGeolocationPushRequest, IGetGeolocationPushResponse> {
  protected async execute({ campaignId }: IGetGeolocationPushRequest) {
    return API.HTTP.get<IGetGeolocationPushResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/geolocation/${ campaignId }`);
  }
}

export const getGeolocationPushUseCase = new GetGeolocationPushUseCase();
