import { API, UseCase } from '@ppg/common';
import { getPlatformTypeParam, getRangeDateParam } from '../../../helpers';
import { IOrganizationDashboardRequestBody } from '../../../interfaces';

export interface IGetCampaignsComparatorStatisticsRequest extends IOrganizationDashboardRequestBody {
  timezone: string;
  type: string;
  customKey?: string;
}

export interface IComparatorProjectStatsItem {
  project: string;
  clicked: number;
  ctr: number;
  campaignCount: number;
}

export interface ICampaignComparatorStats {
  sentCampaignsSum: number;
  clickedSum: number;
  averageCampaignCTR: number;
  projectStats: IComparatorProjectStatsItem[];
}

export interface IGetCampaignsComparatorStatisticsResult {
  [key: string]: ICampaignComparatorStats;
}

const DEFAULT_CAMPAIGNS_COMPARATOR_STATS_RESULT_KEY = 'campaignComparatorStats';

export class GetCampaignsComparatorStatisticsUseCase extends UseCase<IGetCampaignsComparatorStatisticsRequest, IGetCampaignsComparatorStatisticsResult> {
  protected async execute({ organization, platform, from, to, customKey, timezone, type }: IGetCampaignsComparatorStatisticsRequest) {
    const result = await API.HTTP.get<IGetCampaignsComparatorStatisticsResult>(`statistics/organizations/${ organization }/campaigns/comparator/statistics/${type}${ getPlatformTypeParam(platform) }${ getRangeDateParam(from, to) }&timezone=${timezone}`);
    return {
      [customKey ?? DEFAULT_CAMPAIGNS_COMPARATOR_STATS_RESULT_KEY]: result.campaignComparatorStats
    };
  }
}
