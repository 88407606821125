import * as React from 'react';
import { observer } from 'mobx-react';
import { UserLayout } from '../Layouts/UserLayout/UserLayout/UserLayout';
import PaymentsLayout from '../../Payments/PaymentsLayout';
import { useLocation } from 'react-router-dom';
import { t } from '../../../base/helpers';
import { PaymentsRoutesTypes } from '../../../routes/paymentsRoutes';
import { IUserNavigationTab } from '../Navigations/UserNavigationBar/UserNavigationBar';

export const Payments = observer(() => {
  let { pathname } = useLocation();

  const paymentsLink: string = pathname === PaymentsRoutesTypes.INVOICES_CARDS ? PaymentsRoutesTypes.INVOICES_CARDS : PaymentsRoutesTypes.INVOICES_LIST;

  const payments: IUserNavigationTab[] = [
    { icon: 'payment', title: t('Payments'), linkTo: paymentsLink },
    { icon: 'wallet', title: t('Invoice details'), linkTo: PaymentsRoutesTypes.INVOICE_DETAILS },
  ];

  return (
    <UserLayout navigationTabs={ payments }>
      <PaymentsLayout/>
    </UserLayout>
  );
});