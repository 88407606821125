import { API, UseCase } from '@ppg/common';

interface IUpdateImage {
  projectID: string;
  imageOwnerID: string;
  imageName: string;
}

export class UpdateImageNameUseCase extends UseCase<IUpdateImage, void> {
  protected async execute({ projectID, imageOwnerID, imageName }: IUpdateImage) {
    await API.HTTP.put<IUpdateImage>(`core/projects/${ projectID }/images/owner/${ imageOwnerID }`, { name: imageName });
  }
}

export const updateImageNameUseCase = new UpdateImageNameUseCase();
