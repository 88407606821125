import { API, UseCase } from '@ppg/common';
import { ISendPushCampaign, ICampaignIdResponse } from '../interfaces';

interface ICreateLabelPushDraftRequest extends ISendPushCampaign {}
interface ICreateLabelPushDraftResponse extends ICampaignIdResponse {}

export class CreateLabelPushDraftUseCase extends UseCase<ICreateLabelPushDraftRequest, ICreateLabelPushDraftResponse> {
  protected async execute({ message, options }: ICreateLabelPushDraftRequest) {
    return API.HTTP.post<ICreateLabelPushDraftResponse>(`core/projects/${API.HTTP.currentProjectId}/pushes/labels/draft`, { message, options });
  }
}

export const createLabelPushDraftUseCase = new CreateLabelPushDraftUseCase();
