import { Collection } from 'backbone';
import { apiManager, connector, Model } from '../../base';
import { property } from '../../base/decorators';
import * as lodash from 'lodash';
import { IKeyValue, Partial } from '../../interfaces';
import { v1 } from 'uuid';
import { KeyValue } from '../KeyValue';
import { IAutomationFlowStep } from './IAutomationFlowStep';
import { AbstractAutomationStep } from './AbstractAutomationStep';
import { automationStepFactory } from './AutomationStepFactory';
import { IAutomationFlow } from './IAutomationFlow';
import { AutomationFlowType } from './AutomationFlowType';
import { AutomationFlowStateType } from './AutomationFlowStateType';
import { WebUser } from "../index";
import { IAutomationDayResponse } from "./IAutomationDayResponse";
import { AutomationMetaOfferType } from './AutomationMetaOfferType';
import { OpUnitType } from 'dayjs';

export class AutomationFlow extends Model<IAutomationFlow, IAutomationFlow> {
  @property() public name: string;
  @property() public stats: any;
  @property() public priority: number;
  @property() public project: string;
  @property() public testMode: boolean;
  @property() public state: AutomationFlowStateType;
  @property() public firstStepId: string;
  @property() public triggerType: AutomationFlowType;
  @property() public additionalFields: Collection<KeyValue | any>;
  @property() public recurrencyTime: string;
  @property() public resetTime: number;
  @property() public resetTimePeriod: OpUnitType;
  @property() public steps: IAutomationFlowStep<any>[];
  @property() public expireDate: Date;
  @property() public meta_offer_type: AutomationMetaOfferType;

  public static resourceUrl(): string {
    const user = connector.get<WebUser>('user');
    return `project/${user.currentProject}/automation`;
  }

  get enabled() {
    return this.state === AutomationFlowStateType.RESUME;
  }

  set enabled(value: boolean) {
    this.state = value ? AutomationFlowStateType.RESUME : AutomationFlowStateType.PAUSE;
  }

  public async resolveStatistics(from = new Date(), to = new Date()) {
    const response = await apiManager.get<IAutomationDayResponse>(`/statistics/projects/${this.project}/automations/${this.id}/range?from=${from.toISOString()}&to=${to.toISOString()}`);
    this.stats = response.data.stats;
  }

  public extractIdsFromSteps() {
    return lodash
      .chain(this.steps)
      .filter('payload.reference')
      .map('payload.reference')
      .value();
  }

  // Mongoose lost empty objects
  public parse(document: IAutomationFlow) {
    document.steps = document.steps.map(step => {
      step.payload = step.payload || {};
      return step;
    });

    document.additionalFields = new Collection<KeyValue | any>(document.additionalFields as IKeyValue[], {model: KeyValue});

    return document;
  }

  /**
   * Add step
   */
  public addStep(step: IAutomationFlowStep<any>) {
    step.stepId = v1();
    this.steps.push(step);
    this.trigger('change', this, this.collection);
    return step;
  }

  /**
   * Get trigger as dummy step
   */
  public getTriggerStep(): IAutomationFlowStep<any> {
    return automationStepFactory.createOne(this, {
      stepId: 'trigger',
      type: 'trigger',
      payload: {
        nextStepId: this.firstStepId
      }
    });
  }

  /**
   * Returns steps instance[]
   */
  public getStep(stepId: string): AbstractAutomationStep<any> {
    const step = lodash.find(this.steps, { stepId });
    return step ? automationStepFactory.createOne(this, step) : null;
  }

  /**
   * Returns steps instance[]
   */
  public getSteps(): AbstractAutomationStep<any>[] {
    return automationStepFactory.create(this, this.steps);
  }

  public getStepsWithTrigger(trigger: any): AbstractAutomationStep<any>[] {
    const stepsWithTrigger = [trigger].concat(this.steps);
    return automationStepFactory.create(this, stepsWithTrigger);
  }

  /**
   * Returns boolean if steps collection is empty
   */
  public isEmpty(): boolean {
    return lodash.isEmpty(this.steps);
  }

  public toJSON(): IAutomationFlow & { trigger?: AutomationFlowType } {
    return {
      ...super.toJSON(),
      trigger: this.triggerType,
    };
  }

  public defaults(): Partial<IAutomationFlow> {
    return {
      id: null,
      name: null,
      testMode: true,
      firstStepId: null,
      priority: 0,
      resetTime: 0,
      resetTimePeriod: 'days',
      triggerType: null,
      steps: []
    };
  }

  get pushSteps() {
    return lodash.filter(this.steps, step => step.type === 'sendPush');
  }

  get isEnabledForTest() {
    return this.enabled && this.testMode;
  }
}
