import { API, UseCase } from "@ppg/common";
import { ICampaignId, ICampaignIdResponse } from '../interfaces';

interface IRetrySegmentedPushRequest extends ICampaignId {}
interface IRetrySegmentedPushResponse extends ICampaignIdResponse {}

export class RetrySegmentedPushUseCase extends UseCase<IRetrySegmentedPushRequest, IRetrySegmentedPushResponse> {
  protected async execute({ campaignId }: IRetrySegmentedPushRequest) {
    return API.HTTP.post<IRetrySegmentedPushResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/segmented/${ campaignId }/retry`);
  }
}

export const retrySegmentedPushUseCase = new RetrySegmentedPushUseCase();
