import { PropertyHandler } from '@ppg/common';
import dayjs from 'dayjs';
import { action, computed, observable } from 'mobx';
import { OrganizationDashboardStore } from './OrganizationDashboardStore';
import { PlatformType } from '../../useCases/interfaces';

export abstract class OrganizationDashboardRelated extends PropertyHandler {

  @observable
  private organizationDashboardStore: OrganizationDashboardStore;

  constructor(organizationDashboardStore: OrganizationDashboardStore) {
    super();
    this.organizationDashboardStore = organizationDashboardStore;
  }

  @computed
  get platformType(): PlatformType | null {
    return this.organizationDashboardStore.platformType;
  }

  @computed
  get isPlatformSelected(): boolean {
    return this.organizationDashboardStore.isPlatformSelected();
  }

  @computed
  get bothPlatformsEnabled(): boolean {
    return this.organizationDashboardStore.bothPlatformsEnabled;
  }

  @computed
  get rangeChangeFactors(): { from: string, to: string } {
    return this.organizationDashboardStore.rangeChangeFactors;
  }

  @computed
  public get isOnlyMobilePlatformSelected(): boolean {
    return this.organizationDashboardStore.mobilePlatformEnabled && !this.organizationDashboardStore.webPlatformEnabled;
  }

  @computed
  get platformChangeFactors() {
    return this.organizationDashboardStore.platformChangeFactors;
  }

  @computed
  get organizationRequestBody() {
    return this.organizationDashboardStore.organizationRequestBody;
  }

  @computed
  get requestDateRange(): { from: Date, to: Date } {
    return {
      from: new Date(this.organizationRequestBody.from),
      to: new Date(this.organizationRequestBody.to),
    };
  }

  @computed
  get undifferentiatedPlatforms() {
    return this.organizationDashboardStore.undifferentiatedPlatforms;
  }

  @computed
  get activeTab(): string {
    return this.organizationDashboardStore.activeTab;
  }

  @computed
  get timezone(): string {
    return this.organizationDashboardStore.timezone;
  }

  protected calculateListRange(from: Date, to: Date, limit: number, offset: number): { from: Date, to: Date } {
    const calculatedFrom = dayjs(from).add(offset, 'days').toDate();
    const calculatedTo = dayjs(calculatedFrom).add(limit, 'days').isAfter(to) ? new Date(to) : dayjs(calculatedFrom).add(limit, 'days').toDate();
    return {
      from: calculatedFrom,
      to: calculatedTo,
    };
  }

  @action
  public parseAndAddMissingDays<T>(initData: Array<T>, from: Date, to: Date, dayKey: string, emptyObj: object, valueName: string): Array<T> {
    let result = [];
    for (let date = dayjs(from); date.isBefore(to); date = dayjs(date).add(1, 'days')) {
      let dayDate = date.format('YYYY-MM-DD');
      const dayItem = initData.find(day => dayjs(day[dayKey]).format('YYYY-MM-DD') === dayDate);
      if (!dayItem) {
        result = result.concat({ [dayKey]: dayDate, ...emptyObj });
        continue;
      }
      result = result.concat({ [dayKey]: dayDate, y: valueName ? dayItem[valueName] : dayItem });
    }
    return result;
  }
}
