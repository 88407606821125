import { property } from '@ppg/common';
import { WebsiteIntegrationRelated } from '../../stores/project/WebsiteIntegrationRelated';
import { action } from 'mobx';
import { getBeaconTrackerPluginUseCase, updateBeaconTrackerPluginUseCase } from '../../useCases/website-integration';

export type BeaconStrategyType = 'default' | 'history' | 'hash';
export type BeaconSelectorEventType = 'view' | 'click' | 'blur';
export type BeaconSelectorTypeType = 'number' | 'string' | 'boolean' | 'date';

export interface BeaconSelector {
  type: BeaconSelectorTypeType;
  event?: BeaconSelectorEventType;
  selector?: string;
  tagLabel?: string;
  settingsType?: string;
  attribute?: string;
  name?: string;
  prettyName?: string;
  reducer?: string;
  bindAll?: boolean;
}

interface IBeaconTrackerPlugin {
  enabled: boolean;
  selectors: BeaconSelector[];
  strategy: BeaconStrategyType;
}

export class BeaconTrackerPlugin extends WebsiteIntegrationRelated implements IBeaconTrackerPlugin {
  @property() enabled: boolean;
  @property() selectors: BeaconSelector[];
  @property() strategy: BeaconStrategyType;

  @action
  public async fetchData(): Promise<void> {
    const { payload } = await getBeaconTrackerPluginUseCase.exec({ websiteIntegrationId: this.websiteIntegrationId });
    this.enabled = payload.enabled;
    this.strategy = payload.strategy;
    this.selectors = payload.selectors;
  }

  public async save(): Promise<void> {
    await updateBeaconTrackerPluginUseCase.exec({
      websiteIntegrationId: this.websiteIntegrationId,
      enabled: this.enabled,
      beaconSelector: this.selectors,
      beaconStrategyType: this.strategy
    });
  }
}
