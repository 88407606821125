import * as React from 'react';
import { UserNavigationBar, IUserNavigationTab } from '../../../Navigations/UserNavigationBar/UserNavigationBar';
import './UserLayout.scss'

interface UserLayoutProps {
  children: React.ReactNode;
  navigationTabs: IUserNavigationTab[];
  userGuideLink?: string;
  userGuideTip?: string;
  newUserGuide?: boolean;
}

export class UserLayout extends React.Component<UserLayoutProps, {}> {
  public render() {
    return <>
        <UserNavigationBar { ...this.props }/>
        <div className={'user-layout-container'}>
          { this.props.children }
        </div>
      </>
  }
}
