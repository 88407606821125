import * as nunjucks from "nunjucks";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";

dayjs.extend(isoWeek);

export interface INunjucksScope {
  [k: string]: number | string | null;
}

export class Nunjucks {
  static renderFactory(scope: INunjucksScope) {
    return function render(key: string) {
      return Nunjucks.render(key, scope);
    };
  }

  static createEnv(envParams) {
    const env = nunjucks.configure({ ...envParams });

    env.addFilter("nth", function (data, index = 1) {
      return data[index];
    });

    env.addFilter("strikethrough", function (text: string) {
      const strikethroughChar = "\u0336";
      return text
        .split("")
        .map((char) => char + strikethroughChar)
        .join("");
    });

    env.addFilter("underline", function (text: string) {
      const underlineChar = "\u0332";
      return text
        .split("")
        .map((char) => char + underlineChar)
        .join("");
    });

    env.addFilter("split", function (data, splitChar = ",") {
      return data.split(splitChar);
    });

    env.addFilter("dateConvertTo", function (date, to = "days", now = null) {
      return dayjs(date).diff(dayjs(now), to);
    });

    env.addFilter("nextWeekDay", function (startDate = dayjs(), dayINeed) {
      const today = dayjs(startDate).isoWeekday();
      if (today < dayINeed) {
        return dayjs(startDate).isoWeekday(dayINeed);
      } else {
        return dayjs(startDate)
          .add(1, "weeks")
          .isoWeekday(dayINeed)
          .toISOString();
      }
    });

    env.addFilter(
      "next",
      function (startDate = dayjs(), what = "day", offset = 1) {
        return dayjs(startDate).add(offset, what).toISOString();
      }
    );

    return env;
  }

  static checkCode(code: string) {
    if (code.indexOf(".constructor") > -1) {
      throw new Error("Cannot render suspicious code");
    }

    if (code.indexOf(".__proto") > -1) {
      throw new Error("Cannot render suspicious code");
    }

    if (code.indexOf("({})") > -1) {
      throw new Error("Cannot render suspicious code");
    }
  }

  static render(
    template: string,
    scope: INunjucksScope,
    silent: boolean = true
  ) {
    let val;

    const env = Nunjucks.createEnv({ autoescape: false });

    try {
      Nunjucks.checkCode(template);
    } catch (e) {
      console.error(e);
      return;
    }

    try {
      val = env.renderString(template, {
        now: new Date().toISOString(),
        ...scope,
      });
    } catch (e) {
      if (silent) {
        console.error("Render component nunjucks parse: ", e.message);
      } else {
        val = e.message;
      }
    }

    return val;
  }
}
