import * as React from 'react';
import { redirect, t } from '../../base/helpers';
import { Input, SectionHeader, UrlInput, Select } from '@ppg/styled';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from '../../stores/project/ProjectStore';
import './NewProject.scss';
import { SaveButtonWrapped } from '../../assets/wrapped';
import { OfferStore } from '../../stores/OfferStore';
import { ProjectRoutesTypes } from '../../routes/moduleProjectRoutes';

interface INewProjectProps {
  projectStore?: ProjectStore;
  offerStore?: OfferStore;
}

@inject('projectStore', 'offerStore')
@observer
export class NewProject extends React.Component<INewProjectProps, {}> {

  public async componentDidMount() {
    await this.props.projectStore.projectCreator.getSectors();
  }

  private async onClickAdd() {
    await this.props.projectStore.saveNewProject();
    await this.props.offerStore.fetchCurrentAndNextPlans();
    setTimeout(() => redirect(ProjectRoutesTypes.PROJECT_INTEGRATION), 250);
  }

  public render() {
    const { projectCreator } = this.props.projectStore;

    return (
      <section className="new-project-section">
        <SectionHeader title={ t('project/new::Integrate new website') }
                       subtitle={ t('project/new::Give us some basic info about the website, on which you want to collect your web push subscribers.') }/>
        <div className="well">
          <div id="projectName" className="project-name-wrapper">
            <div className="project-name-part">
              <Input property={ projectCreator.getProperty('name') }
                     label={ t('project/new::Project name') }
                     helperContent={ t('project/new::Project name will be visible to your page visitors in the subscription form\'s Confirmation Window for the Simple Manual Integration.') }
                     placeholder={ t('project/new::My awesome website') }/>
            </div>

            <div className="project-sector-part">
              <Select label={ t('project/new::Project\'s segment') }
                      helperContent={ t('project/new::This information will help us to adjust our service and reports to your needs.') }
                      property={ projectCreator.getProperty('sector') }
                      options={ projectCreator.getSectorsSelectOptions }
              />

            </div>
          </div>
          <div className="project-website-wrapper" id="projectWebsite">
            <UrlInput urlProperty={ projectCreator.getProperty('siteUrl') }
                      schemas={['https', 'http']}
                      label={ t('project/new::Website address') }
                      defaultScheme={ 'https' }
            />

            { !projectCreator.siteHasHttps &&
            <p>
              { t('project/new::On notifications you will see address: %{slug} if you do not integrate with https protocol',
                { slug: projectCreator.slug }) }
            </p> }
          </div>

          <div className="save-button-wrapper">
            <SaveButtonWrapped callback={ () => this.onClickAdd() }
                               content={ t("project/new::Add and proceed to add-on installation") } id="addProjectButton"
                               disabled={ !projectCreator.canSave }
                               successMessage={ t("project/new::Project has been created successfully. Now you can integrate your site.") }
            />
          </div>
        </div>
      </section>
    );
  }
}
