import * as React from "react";
import { t } from '../../../../../base/helpers';
import * as Styled from "./ProjectComparisonInfo.styled";
import { IProjectComparisonInfo } from './ProjectComparisonDetails';

export const ProjectComparisonInfo = ({ showComparisonInfo }: IProjectComparisonInfo): JSX.Element => {
  if (!showComparisonInfo) {
    return null;
  }

  return <Styled.InfoWrapper>
    <Styled.InfoIcon className={ 'icon-warning-badge' }/>{ t('For visibility purposes charts will show up to 10 projects') }
  </Styled.InfoWrapper>
}