import { API, localSettings, UseCase } from '@ppg/common';

interface IConfirmOauthUser {
  url: URL;
}

export class ConfirmOauthUserUseCase extends UseCase<IConfirmOauthUser, any> {
  protected async execute({ url }: IConfirmOauthUser) {
    const params = url.searchParams.toString();
    const csrfToken = localSettings.getValue('_csrf');

    return API.HTTP.post(`aai/oauth/onet/callback?${ params }`, {
      externalToken: csrfToken
    });
  }
}

export const confirmOauthUserUseCase = new ConfirmOauthUserUseCase();
