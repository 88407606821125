import { API, UseCase } from "@ppg/common";

export interface ICapping {
  sent: {
    enabled: boolean;
    limit: number;
  };
  delivered: {
    enabled: boolean;
    limit: number;
  };
}

interface ICreateCapping {
  sentCappingEnabled: boolean;
  sentCappingLimit: number;
  deliveredCappingEnabled: boolean;
  deliveredCappingLimit: number;
}

export interface ReadCappingResponse {
  capping: ICapping | null;
}

export class ReadPushCappingUseCase extends UseCase<void, ReadCappingResponse> {
  protected async execute() {
    return API.HTTP.get<ReadCappingResponse>(`core/projects/${ API.HTTP.currentProjectId }/push-cappings`)
  }
}

export class UpdatePushCappingUseCase extends UseCase<ICreateCapping, void> {
  protected async execute({ sentCappingEnabled, sentCappingLimit, deliveredCappingLimit, deliveredCappingEnabled }: ICreateCapping) {
    return API.HTTP.put<void>(`core/projects/${ API.HTTP.currentProjectId }/push-cappings`, {
        sent: {
          enabled: sentCappingEnabled,
          limit: ~~sentCappingLimit,
        },
        delivered: {
          enabled: deliveredCappingEnabled,
          limit: ~~deliveredCappingLimit,
        },
    })
  }
}

export class CreatePushCappingUseCase extends UseCase<ICreateCapping, void> {
  protected async execute({ sentCappingEnabled, sentCappingLimit, deliveredCappingLimit, deliveredCappingEnabled }: ICreateCapping) {
    return API.HTTP.post<void>(`core/projects/${ API.HTTP.currentProjectId }/push-cappings`, {
      sent: {
        enabled: sentCappingEnabled,
        limit: ~~sentCappingLimit,
      },
      delivered: {
        enabled: deliveredCappingEnabled,
        limit: ~~deliveredCappingLimit,
      },
    })
  }
}

export const readPushCappingUseCase = new ReadPushCappingUseCase();
export const createPushCappingUseCase = new CreatePushCappingUseCase();
export const updatePushCappingUseCase = new UpdatePushCappingUseCase();
