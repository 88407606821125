import { API, UseCase } from '@ppg/common';

interface FinishUserOnboardingStateData {
}

export class FinishUserOnboardingStateUseCase extends UseCase<FinishUserOnboardingStateData, void> {
  protected async execute() {
    await API.HTTP.patch<void>(`ah/user-details/user-onboards`);
  }
}

export const finishUserOnboardingStateUseCase = new FinishUserOnboardingStateUseCase();
