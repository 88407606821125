import { property } from '@ppg/common';
import { ProjectPushCapping } from '../../modelsMobx/helpers/ProjectPushCapping';
import { Utm } from '../../modelsMobx';
import { ProjectRelated } from '../ProjectRelatedStore';
import { updateUtmUseCase } from '../../useCases/core/utm/UpdateUtmUseCase';
import { action, computed } from 'mobx';
import { getUtmUseCase } from '../../useCases/core/utm/GetUtmUseCase';
import { fetchFeatureSwitchUseCase } from "../../useCases/core";

export type FeatureSwitchType =
  "SEGMENT_NEW_SUBSCRIBER"
  | "SEGMENT_SENDER"
  | "SEGMENT_EXPORT"
  | "SEGMENT_AUTOMATION"
  | "SEGMENT_SUBSCRIBER_MIGRATED"
  | "SEGMENT_FULLY_MIGRATED";

export class FeatureStore extends ProjectRelated {

  @property() private features: Set<FeatureSwitchType> = new Set();

  @property() public utm: Utm;
  @property() public pushCapping: ProjectPushCapping;
  @property() public subscribersCount: number;

  setDefaultValues() {
    this.utm = Utm.createUtm();
    this.subscribersCount = this.currentProject.subscribersCount;
    this.pushCapping = new ProjectPushCapping();
    this.features = new Set()
  }

  public hasFeature(feature: FeatureSwitchType): boolean {
    // Turn on for all projects, due to switch on backend...

    if (["SEGMENT_FULLY_MIGRATED"].includes(feature)) {
      return true;
    }

    return this.features.has(feature);
  }

  @action
  public async fetchFeatureSwitches(): Promise<void> {
    try {
      const data = await fetchFeatureSwitchUseCase.exec({
        projectId: this.projectId
      });

      this.features.clear();

      for (let feature of data) {
        this.features.add(feature as FeatureSwitchType);
      }
    } catch (error) {
      console.error(error);
      console.error('Feature switch backend is not ready or deployed')
    }
  }

  @action
  public async getUtm(): Promise<void> {
    const data = await getUtmUseCase.exec({
      projectId: this.projectId
    });

    this.utm = Utm.createUtm(data);
  }

  @action
  public async getProjectPushCapping(): Promise<void> {
    return this.pushCapping.fetchProjectPushCapping();
  }

  @computed
  public get hasPushCappingEnabled(): boolean {
    return this.pushCapping.hasPushCappingEnabled;
  }

  public async saveUtm(): Promise<void> {
    await updateUtmUseCase.exec({
      projectId: this.projectId,
      source: this.utm.source,
      campaign: this.utm.campaign,
      medium: this.utm.medium,
      disabled: this.utm.disabled,
      isMediumOn: this.utm.isMediumOn,
      isSourceOn: this.utm.isSourceOn,
      isCampaignOn: this.utm.isCampaignOn,
      customUtms: this.utm.serializeCustomUtms(),
    });
  }
}
