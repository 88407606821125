import * as React from 'react';
import { useState } from 'react';
import './PricingSwitch.scss'
import { debounce } from '@ppg/common';
import { redirect } from '../../base/helpers';

interface IEnterpriseLiteSwitch {
  isLite: boolean;
  isRegister: boolean;
}

export const EnterpriseLiteSwitch = ({ isLite, isRegister }: IEnterpriseLiteSwitch): JSX.Element => {
  const [clicked, setClicked] = useState(false);

  const onSwitchClickHandler = (): void => {
    if (clicked) {
      return;
    }

    const redirectLink = `/${ isLite ? '' : 'lite/' }${ isRegister ? 'register' : 'login' }`;
    redirect(redirectLink);
    setClicked(true);
    debounce(() => setClicked(false), 100)();
  }

  return <div className="switch-btn-container">
    <label className={ `btn-switch btn-color-mode-switch ${ isLite ? 'lite' : '' }` } onClick={ onSwitchClickHandler }>
      <input type="checkbox" name="color_mode" id="color_mode" defaultChecked={ isLite }/>
      <label htmlFor="color_mode" data-on={ 'Lite' } data-off={ 'Enterprise' } className={ `btn-color-mode-switch-inner` }/>
    </label>
  </div>
}
