import { API, UseCase } from "@ppg/common";

export interface ILabelsKeys {
  id: string;
  key: string;
}

interface IGetResolveLabelsKeysToIdsRequest {
  keys: string[];
}

type IGetResolveLabelsKeysToIdsResponse = ILabelsKeys[]

export class GetResolveLabelsKeysToIds extends UseCase<IGetResolveLabelsKeysToIdsRequest, IGetResolveLabelsKeysToIdsResponse> {
  protected async execute({ keys }: IGetResolveLabelsKeysToIdsRequest) {

    if (!keys || keys.length === 0) {
      return [];
    }

    let params = new URLSearchParams();

    for (let key of keys) {
      params.append("keys", key);
    }

    return API.HTTP.get<IGetResolveLabelsKeysToIdsResponse>(`core/projects/${ API.HTTP.currentProjectId }/segmentation/label-keys/ids?${ params }`);
  }
}

export const getResolveLabelsKeysToIds = new GetResolveLabelsKeysToIds();
