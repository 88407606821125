import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { observable } from 'mobx';
import { UserStore } from '../../../../stores/user/UserStore';
import { AuthorizationType } from '../../../../modelsMobx/User';
import { PageSection } from '../../Layouts/UserLayout/PageSection';
import { t } from '../../../../base/helpers';
import { Input, ShowPasswordButton } from '@ppg/styled';
import { SaveButtonWrapped } from '../../../../assets/wrapped';

interface IPasswordSettingsProps {
  userStore?: UserStore;
}

@inject('userStore')
@observer
export class SettingsPassword extends React.Component<IPasswordSettingsProps> {
  @observable isCurrentPasswordHidden: boolean = true;
  @observable isNewPasswordHidden: boolean = true;
  @observable isRepeatedPasswordHidden: boolean = true;

  public componentDidMount():void {
     this.props.userStore.password.clear();
  }

  private async handleChangePassword() {
    return this.props.userStore.password.changePassword();
  }

  private toggleCurrentPassword() {
    this.isCurrentPasswordHidden = !this.isCurrentPasswordHidden;
  }

  private toggleNewPassword() {
    this.isNewPasswordHidden = !this.isNewPasswordHidden;
  }

  private toggleRepeatedPassword() {
    this.isRepeatedPasswordHidden = !this.isRepeatedPasswordHidden;
  }

  public render() {
    const { userStore } = this.props;

    if (userStore.user.authorizationStrategy === AuthorizationType.OAUTH) {
      return null;
    }

    return (
      <PageSection>
        <h4 className="form-title">{ t('Change password') }</h4>
        <form className="password-change-wrapper" autoComplete="off">
          <div className='password-inputs-container'>
            <div className="password-input-wrapper">
              <Input
                property={ userStore.password.getProperty('currentPassword') }
                type={ this.isCurrentPasswordHidden ? 'password' : 'text' }
                label={ t('Current password') }/>
              <ShowPasswordButton hidePassword={ this.isCurrentPasswordHidden }
                                  onClickHandler={ this.toggleCurrentPassword.bind(this) }/>
            </div>
            <div className="password-input-wrapper">
              <Input
                property={ userStore.password.getProperty('password') }
                type={ this.isNewPasswordHidden ? 'password' : 'text' }
                label={ t('New password') }/>
              <ShowPasswordButton hidePassword={ this.isNewPasswordHidden }
                                  onClickHandler={ this.toggleNewPassword.bind(this) }/>
            </div>
            <div className="password-input-wrapper">
              <Input property={ userStore.password.getProperty('confirmationPassword') }
                     type={ this.isRepeatedPasswordHidden ? 'password' : 'text' }
                     label={ t('Confirm new password') }/>
              <ShowPasswordButton hidePassword={ this.isRepeatedPasswordHidden }
                                  onClickHandler={ this.toggleRepeatedPassword.bind(this) }/>
            </div>
          </div>

          <div className="password-rules-wrapper">
            <p className="password-rules-feedback">{ userStore.password.allRulesMet && t('Good job! Your password has met all the rules.') }</p>
            <p className='password-rules-header'>{ t('Make sure your new password:') }</p>
            <div className="password-rules-list-wrapper">
              <ul className='password-rules-list'>
                <li><span className={ `${ userStore.password.isLongEnough ? 'icon-tick rule-fullfiled' : 'icon-ok-badge' } list-icon` }/>{ t('has minimum 8 characters') }</li>
                <li><span className={ `${ userStore.password.isNotSimple ? 'icon-tick rule-fullfiled' : 'icon-ok-badge' } list-icon` }/>{ t('doesn\'t contain sequence of 4 characters') }</li>
                <li><span className={ `${ userStore.password.containsSpecialCharacter ? 'icon-tick rule-fullfiled' : 'icon-ok-badge' } list-icon` }/>{ t('has one special character') }</li>
                <li><span className={ `${ userStore.password.containsUpperCase ? 'icon-tick rule-fullfiled' : 'icon-ok-badge' } list-icon` }/>{ t('has one uppercase character') }</li>
              </ul>
              <ul className='password-rules-list'>
                <li><span className={ `${ userStore.password.containsLowerCase ? 'icon-tick rule-fullfiled' : 'icon-ok-badge' } list-icon` }/>{ t('has one lowercase character') }</li>
                <li><span className={ `${ userStore.password.containsNumber ? 'icon-tick rule-fullfiled' : 'icon-ok-badge' } list-icon` }/>{ t('has one number') }</li>
                <li><span className={ `${ userStore.password.areSamePasswords ? 'icon-tick rule-fullfiled' : 'icon-ok-badge' } list-icon` }/>{ t('is the same one in \'Confirm new password field\'') }
                </li>
              </ul>
            </div>

          </div>
          <div className="change-password-button-wrapper">
            <SaveButtonWrapped callback={ () => this.handleChangePassword() }
                               content={ t('Change password') }
                               disabled={ !userStore.password.allRulesMet }
                               successMessage={ t('Password was changed') }/>
          </div>
        </form>
      </PageSection>
    );
  }
}
