import { API, UseCase } from '@ppg/common';
import { ExportFileState, ExportFileType } from '../../modelsMobx/ExportFile';

interface CreateExportResult {
  id: string;
  type: ExportFileType;
  state: ExportFileState;
  createdAt: Date;
  from: string;
  to: string;
  rangeReference: string;
}

interface ICreateExportCommand {
  projectID: string;
  from: string;
  to: string;
  type: string;
  exportBy: exportByType;
}

type exportByType = 'createdAt' | 'sendDate';


export class CreateExportUseCase extends UseCase<ICreateExportCommand, CreateExportResult> {
  protected async execute({ projectID, from, to, type, exportBy }) {
    return API.HTTP.post<CreateExportResult>(`core/project/${ projectID }/exports`, {
      from,
      to,
      type,
      rangeReference: type !== 'campaign' ? 'createdAt' : exportBy,
    });
  }
}

export const createExportUseCase = new CreateExportUseCase();
