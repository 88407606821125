import { API, UseCase } from '@ppg/common';

interface RequestData {
  projectID: string;
  campaignID: string;
}

interface ICampaignSummaryDTO {
  campaignId: string;
  sent: number;
  actionPrimary: number;
  actionSecondary: number;
  lost: number;
  clicked: number;
  delivered: number;
  ctr: number;
  cappedDelivered: number;
  cappedSent: number;
}

export class GetCampaignStatisticsSummary extends UseCase<RequestData, ICampaignSummaryDTO> {
  protected async execute({ projectID, campaignID }) {
    return API.HTTP.get<ICampaignSummaryDTO>(
      `statistics/projects/${ projectID }/pushes/${campaignID}/summary`,
    );
  }
}

export const getCampaignStatisticsSummary = new GetCampaignStatisticsSummary();

