import { API, UseCase } from '@ppg/common';
import { getRangeDateParam } from '../../../helpers';

export interface IAutomationCampaign {
  automation: string,
  campaigns: string[]
}

interface IGetProjectAutomationRankRequest {
  from: string,
  to: string,
  timezone: string,
  automations: IAutomationCampaign[]
}

export interface IAutomationRankResultItem {
  automation: string;
  clicked: number;
  delivered: number;
  ctr: number;
}

export interface GetAutomationRankResult {
  ranking: IAutomationRankResultItem[];
}

export class GetProjectAutomtionRankUseCase extends UseCase<IGetProjectAutomationRankRequest, GetAutomationRankResult> {
  protected async execute({ from, to, timezone, automations }: IGetProjectAutomationRankRequest) {
    return API.HTTP.post<GetAutomationRankResult>(`statistics/v2/projects/${ API.HTTP.currentProjectId }/automations/statistics/rank${ getRangeDateParam(from, to) }&timezone=${ timezone }`, {
      automations
    });
  }
}

export const getProjectAutomtionRankUseCase = new GetProjectAutomtionRankUseCase();
