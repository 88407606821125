import * as React from 'react';
import { Link } from 'react-router-dom';
import { t } from '../../base/helpers';
import { WebUser } from '../../models';
import { Help } from '@ppg/styled';
import { PaymentsRoutesTypes } from '../../routes/paymentsRoutes';

interface IPermissionProps {
  user: WebUser;
  role: string;
  title?: string;
  isButton?: boolean;
  buttonClass?: string;
}

export class Permission extends React.Component<IPermissionProps> {

  public render() {
    const { isButton, role, user, title, buttonClass } = this.props;
    return user.hasRole(role) ? (isButton ? <span> { this.props.children } </span> : <div> { this.props.children } </div>) : (
      isButton
        ? <span className="nowrap">
          <Link to={ PaymentsRoutesTypes.PLAN } className={ `button button--disabled ${ buttonClass } button--icon m5r` }>
            { title }
            <span className="icon-access"/>
          </Link>
          <Help content={ t('This feature is not available on your current plan') }/>
        </span>
        : <div className="well text-center">
          <div className="p20">
            <span className="icon-access size30 m20b"/>
            <h3>{ title }</h3>
            <div className="m20b">
              { t('This feature is not available on your current plan') }
            </div>
            <Link to={ PaymentsRoutesTypes.PLAN } className="button button--blue button--medium button--icon">{ t('Upgrade') } <span
              className="icon-arrow-right size10"/></Link>
          </div>
        </div>
    );
  }
}
