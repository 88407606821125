import {isNotEmpty, isSelector, isValidRegExp, Property, property, PropertyHandler} from '@ppg/common';
import {reaction, set} from 'mobx';
import {t} from '../../base/helpers';
import {selectorEventTypes, selectorReducerTypes, selectorSettingsTypes, selectorTypeTypes} from './ISelector';
import {DynamicField, DynamicFieldTypeTypes} from '../helpers/DynamicField';
import {ISelectorDTO, SelectorDTO} from './SelectorDTO';
import {LabelStrategyEnum} from "../LabelStrategyEnum";
import {toast} from "@ppg/styled";

const slug = require('slug');

/**
 * Selector gets data from user website that
 *  - can be used in automation
 *  - can dynamically tag subscriber (magic selector)
 *
 * Selector needs reference to a project, and project stores selectors ids
 * User identifies selector by prettyName
 * Automation identifies selector by name
 *
 * Magic selector needs name = 'tag'
 *
 * modelName is used in clipboardComponents
 * __source is used in automation
 */

export class Selector extends PropertyHandler {

  private initialValues;

  @property() public id?: string;
  @property() public name: string;
  @property([isNotEmpty()]) public prettyName: string;
  @property([isSelector(), isNotEmpty()]) public selector: string;

  @property() public event: selectorEventTypes;
  @property() public bindAll: boolean;
  @property() public group: string;
  /**
   * @deprecated
   */
  @property() public tagLabel: string;

  @property([isValidRegExp(/(^[a-z0-9\-._]+$)|(^$)/)]) public labelKey: string;
  @property() public labelStrategy: LabelStrategyEnum;
  @property() public labelTTL: number;
  @property() public reducer: selectorReducerTypes;
  @property() public valueType: DynamicFieldTypeTypes;
  @property() public settingsType: selectorSettingsTypes;
  @property() public attribute: string;

  public modelName: string = 'selector';

  /**
   * AutomationFields
   */
  public __source: string = 'selector';

  public get type(): string {
    return this.valueType;
  }

  public get fieldIdentity() {
    return `${ this.__source }:${ this.name }:${ this.type }`;
  }

  private constructor(selector) {
    super();
    this.id = selector.id;
    this.selector = selector.selector;
    this.name = selector.name;
    this.prettyName = selector.prettyName;
    this.group = selector.group;
    this.reducer = selector.reducer;
    this.valueType = selector.valueType;
    this.event = selector.event;
    this.bindAll = selector.bindAll;
    this.tagLabel = selector.tagLabel;
    this.labelKey = selector.labelKey;
    this.labelStrategy = selector.labelStrategy || LabelStrategyEnum.APPEND;
    this.labelTTL = selector.labelTTL || 0;
    this.settingsType = selector.settingsType;
    this.attribute = selector.attribute;

    this.initialValues = selector;

    reaction(() => this.settingsType, () => this.setSettings());
    reaction(() => this.prettyName, () => this.setName());
  }

  handleValidateError(validator: Property) {
    if (validator && validator.validationMessage) {
      toast.error(validator.validationMessage);
    }
  }

  validateAndRewriteLabelSettings() {
    if (this.labelKey === '') {
      this.labelTTL = null;
      this.labelStrategy = null;
    }
  }

  private setSettings() {
    if (this.isNew) {
      set(this, this.settingsByType);
    }
  }

  private setName() {
    if (this.isNew && !this.isMagic) {
      this.name = this.slug;
    }
  }

  static createNewSelector(selector?: ISelectorDTO) {
    if (selector) {
      return new Selector(selector);
    }
    return new Selector({
      selector: '',
      attribute: '',
      name: '',
      prettyName: '',
      group: '',
      tagLabel: '',
      labelKey: '',
      labelStrategy: LabelStrategyEnum.APPEND,
      labelTTL: 0,
      reducer: selectorReducerTypes.LAST,
      valueType: selectorTypeTypes.STRING,
      event: selectorEventTypes.VIEW,
      settingsType: selectorSettingsTypes.GET_TEXT,
      bindAll: false,
    });
  }

  static defaultAutomationSelectors() {
    return {
      url: new Selector({ name: 'url', prettyName: t('Visited URLs'), valueType: selectorTypeTypes.STRING }),
      visitTime: new Selector({ name: 'pageVisitTime', prettyName: t('Page visit time (s)'), valueType: selectorTypeTypes.NUMBER }),
      referrer: new Selector({ name: 'referrer', prettyName: t('Referrer URL'), valueType: selectorTypeTypes.STRING }),
      origin: new Selector({ name: 'origin', prettyName: t('Origin (enter / sign in) URL'), valueType: selectorTypeTypes.STRING }),
      lastVisitDate: new Selector({ name: 'lastVisitDate', prettyName: t('Last visit date'), valueType: selectorTypeTypes.DATE })
    };
  }

  static createWizardSelector(wizardData) {
    const wizardSelector = Selector.createNewSelector();
    set(wizardSelector, wizardData);
    return wizardSelector;
  }

  static createFromSelectorDTO(selectorDTO: ISelectorDTO) {
    return new Selector(selectorDTO);
  }

  get slug() {
    return slug(this.prettyName, { lower: true, replacement: '_' });
  }

  public get isNew() {
    return !this.id;
  }

  public get isMagic() {
    return this.settingsType.includes('tag');
  }

  public get isJS() {
    return this.settingsType === selectorSettingsTypes.GET_JS || this.settingsType === selectorSettingsTypes.CUSTOM_ID_FROM_VARIABLE || this.settingsType === selectorSettingsTypes.TAG_JS;
  }

  public get sample() {
    return DynamicField.getSampleValueByType(this.valueType);
  }

  public toScope() {
    return { [this.name]: this.sample };
  }

  public restore() {
    set(this, this.initialValues);
  }

  public toCopy() {
    return { ...this };
  }

  public toSelectorDTO(projectId: string) {
    return new SelectorDTO({ project: projectId, ...this });
  }

  public get settingsTypeDescription() {
    return Selector.settingsTypeOptions.find(setting => setting.value === this.settingsType).name;
  }

  static get settingsTypeOptions() {
    return [
      { value: selectorSettingsTypes.GET_TEXT, name: t('Get text from element') },
      { value: selectorSettingsTypes.GET_NUMBER, name: t('Get number from element') },
      { value: selectorSettingsTypes.GET_CLICK, name: t('Check if element was clicked') },
      { value: selectorSettingsTypes.GET_COUNT, name: t('Get number of clicks on element') },
      { value: selectorSettingsTypes.TAG, name: t('Add tag, based on element content') },
      { value: selectorSettingsTypes.GET_INPUT, name: t('Get text value from input') },
      { value: selectorSettingsTypes.GET_JS, name: t('Get variable value from JavaScript window. object') },
      { value: selectorSettingsTypes.TAG_JS, name: t('Add tag, based on variable value from JavaScript window. object') },
      { value: selectorSettingsTypes.CUSTOM_ID_FROM_VARIABLE, name: t('Get subscriber customId, based on variable value from JavaScript window. object') },
      { value: selectorSettingsTypes.CUSTOM_ID_FROM_SELECTOR, name: t('Get subscriber customId, based on text selector') },
      { value: selectorSettingsTypes.CUSTOM, name: t('Custom type') }
    ];
  }

  static get typeOptions() {
    return [
      { value: selectorTypeTypes.STRING, name: t('String') },
      { value: selectorTypeTypes.NUMBER, name: t('Number') },
      { value: selectorTypeTypes.BOOLEAN, name: t('Boolean') },
      { value: selectorTypeTypes.DATE, name: t('Date') }
    ];
  }

  static get eventOptions() {
    return [
      { value: selectorEventTypes.CLICK, name: t('Click') },
      { value: selectorEventTypes.VIEW, name: t('View') },
      { value: selectorEventTypes.BLUR, name: t('Blur') }
    ];
  }

  get correctedReducer() {
    return this.name === 'tag' ? selectorReducerTypes.UNIQ : this.reducer;
  }

  get reducerOptions() {
    let options = {
      sum: { value: selectorReducerTypes.SUM, name: t('Sum') },
      last: { value: selectorReducerTypes.LAST, name: t('Last') },
      avg: { value: selectorReducerTypes.AVG, name: t('Avg') },
      uniq: { value: selectorReducerTypes.UNIQ, name: t('Uniq') },
      count: { value: selectorReducerTypes.COUNT, name: t('Count') },
      or: { value: selectorReducerTypes.OR, name: t('Or') },
    };

    switch (this.valueType) {
      case DynamicFieldTypeTypes.STRING:
        return [options.last, options.uniq, options.count];
      case DynamicFieldTypeTypes.NUMBER:
        return [options.last, options.count, options.sum, options.avg, options.uniq];
      case DynamicFieldTypeTypes.BOOLEAN:
        return [options.or, options.last];
      case DynamicFieldTypeTypes.DATE:
        return [options.last];
    }
  }

  private get settingsByType() {
    switch (this.settingsType) {
      case selectorSettingsTypes.CUSTOM_ID_FROM_SELECTOR:
        return {
          valueType: selectorTypeTypes.STRING,
          event: selectorEventTypes.VIEW,
          reducer: selectorReducerTypes.LAST,
          name: 'customId'
        };

      case selectorSettingsTypes.CUSTOM_ID_FROM_VARIABLE:
        return {
          valueType: selectorTypeTypes.STRING,
          event: selectorEventTypes.VIEW,
          reducer: selectorReducerTypes.LAST,
          name: 'customId'
        };

      case selectorSettingsTypes.GET_JS:
        return {
          valueType: selectorTypeTypes.STRING,
          event: selectorEventTypes.VIEW,
          reducer: selectorReducerTypes.LAST,
          name: this.slug
        };

      case selectorSettingsTypes.TAG_JS:
        return {
          valueType: selectorTypeTypes.STRING,
          event: selectorEventTypes.VIEW,
          reducer: selectorReducerTypes.UNIQ,
          name: 'tag'
        };

      case selectorSettingsTypes.GET_TEXT:
        return {
          valueType: selectorTypeTypes.STRING,
          event: selectorEventTypes.VIEW,
          reducer: selectorReducerTypes.LAST,
          name: this.slug
        };

      case selectorSettingsTypes.GET_NUMBER:
        return {
          valueType: selectorTypeTypes.NUMBER,
          event: selectorEventTypes.VIEW,
          reducer: selectorReducerTypes.LAST,
          name: this.slug
        };

      case selectorSettingsTypes.GET_CLICK:
        return {
          valueType: selectorTypeTypes.BOOLEAN,
          event: selectorEventTypes.CLICK,
          reducer: selectorReducerTypes.OR,
          name: this.slug
        };

      case selectorSettingsTypes.GET_COUNT:
        return {
          valueType: selectorTypeTypes.NUMBER,
          event: selectorEventTypes.CLICK,
          reducer: selectorReducerTypes.COUNT,
          name: this.slug
        };

      case selectorSettingsTypes.GET_INPUT:
        return {
          valueType: selectorTypeTypes.STRING,
          event: selectorEventTypes.BLUR,
          reducer: selectorReducerTypes.LAST,
          name: this.slug
        };

      case selectorSettingsTypes.TAG:
        return {
          valueType: selectorTypeTypes.STRING,
          event: selectorEventTypes.VIEW,
          reducer: selectorReducerTypes.UNIQ,
          name: 'tag'
        };

      default:
        return { name: this.slug };
    }
  }

  public validate = (): string | null => {
    const chooseTagSettingsType = this.settingsType === selectorSettingsTypes.TAG || this.settingsType === selectorSettingsTypes.TAG_JS;
    if (!this.prettyName) {
      return t('Name is required');
    } else if (chooseTagSettingsType && !this.labelKey) {
      return t('Label key is required')
    }

    return null;
  };
}
