import * as React from 'react';
import { FC } from 'react';
import { IOrganizationProjectItem } from './ProjectsRank';
import * as Styled from './ProjectRank.style'
import { observer } from 'mobx-react';
import { t } from '../../../../base/helpers';
import { fixedPercentValue } from '@ppg/common';

interface IAutomationItem {
  project: IOrganizationProjectItem;
  showAutomationCtrZero: boolean;
}

export const ProjectRankItem: FC<IAutomationItem> = observer(({ project, showAutomationCtrZero }) => {
  const { name, url, subscribers, subscriptions, sent, clicked, campaignCTR, automationCTR } = project
  return <tr>
    <Styled.ProjectRankItemDetails>
      <Styled.ProjectRankItemLabel>
        <Styled.ProjectRankItemTitle> { name } </Styled.ProjectRankItemTitle>
        <Styled.ProjectRankItemProject> { url }</Styled.ProjectRankItemProject>
      </Styled.ProjectRankItemLabel>
    </Styled.ProjectRankItemDetails>

    <td data-label={ t('organization/project-comparison::Subscribers') }>{ subscribers }</td>
    <td data-label={ t('organization/project-comparison::Subscriptions') }>{ subscriptions }</td>
    <td data-label={ t('organization/project-comparison::Sent') }>{ sent }</td>
    <td data-label={ t('organization/project-comparison::Clicked') }>{ clicked }</td>
    <td data-label={ t('organization/project-comparison::Campaign CTR') }>{ fixedPercentValue(campaignCTR)}</td>
    <td data-label={ t('organization/project-comparison::Automation CTR') }>{ showAutomationCtrZero ? '0%' : fixedPercentValue(automationCTR) }</td>

  </tr>;
})
