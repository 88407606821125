import { UseCase, API } from '@ppg/common';
import { IUpdateConfirmWindow } from '../../interfaces';

export class UpdateConfirmWindowUseCase extends UseCase<IUpdateConfirmWindow, void> {
  protected async execute({ websiteIntegrationId, doubleOptInCustomHTML, doubleOptInDescription, doubleOptInProjectLogoURL, doubleOptInCustomButton }: IUpdateConfirmWindow) {
    await API.HTTP.put(`wi/projects/${ API.HTTP.currentProjectId }/website-integrations/${ websiteIntegrationId }/plugins/web-push/double-opt-in`, {
      doubleOptInCustomHTML,
      doubleOptInProjectLogoURL,
      doubleOptInDescription,
      doubleOptInCustomButton
    })
  }
}

export const updateConfirmWindowUseCase = new UpdateConfirmWindowUseCase();
