import { API, UseCase } from '@ppg/common';
import { IBeaconTracker, IGetPlugin } from '../interfaces';

export class GetBeaconTrackerPluginUseCase extends UseCase<IGetPlugin, IBeaconTracker> {
  protected async execute({ websiteIntegrationId }: IGetPlugin) {
    return API.HTTP.get<IBeaconTracker>(`wi/projects/${ API.HTTP.currentProjectId }/website-integrations/${ websiteIntegrationId }/plugins/beacon-tracker`);
  }
}

export const getBeaconTrackerPluginUseCase = new GetBeaconTrackerPluginUseCase();
