export interface ITertiaryColors {
  green100: string,
  green200: string,
  green300: string,
  green400: string,
  green500: string,
  green600: string,
  green700: string,
  green800: string,
  green900: string,

  yellow100: string,
  yellow200: string,
  yellow300: string,
  yellow400: string,
  yellow500: string,
  yellow600: string,
  yellow700: string,
  yellow800: string,
  yellow900: string,

  orange100: string,
  orange200: string,
  orange300: string,
  orange400: string,
  orange500: string,
  orange600: string,
  orange700: string,
  orange800: string,
  orange900: string,

  red100: string,
  red200: string,
  red300: string,
  red400: string,
  red500: string,
  red600: string,
  red700: string,
  red800: string,
  red900: string,

  blue100: string,
  blue200: string,
  blue300: string,
  blue400: string,
  blue500: string,
  blue600: string,
  blue700: string,
  blue800: string,
  blue900: string,

  gray100: string,
  gray200: string,
  gray300: string,
  gray400: string,
  gray500: string,
  gray600: string,
  gray700: string,
  gray800: string,
  gray900: string,
}

export const tertiary: ITertiaryColors = {
  green100: '#cffae1',
  green200: '#a0f5cc',
  green300: '#6de2b5',
  green400: '#45c5a0',
  green500: '#16a085',
  green600: '#10897d',
  green700: '#0b7372',
  green800: '#07555c',
  green900: '#043f4c',

  yellow100: '#fefadc',
  yellow200: '#fdf4ba',
  yellow300: '#faea96',
  yellow400: '#f5df7b',
  yellow500: '#efcf51',
  yellow600: '#cdac3b',
  yellow700: '#ac8b28',
  yellow800: '#8a6c19',
  yellow900: '#72560f',

  orange100: '#fef6e1',
  orange200: '#feeac4',
  orange300: '#fedba7',
  orange400: '#fdcc91',
  orange500: '#fdb46d',
  orange600: '#d98c4f',
  orange700: '#b66836',
  orange800: '#924822',
  orange900: '#793214',

  red100: '#fde7de',
  red200: '#fccbbd',
  red300: '#f8a79b',
  red400: '#f18480',
  red500: '#e8575e',
  red600: '#c73f52',
  red700: '#a72b47',
  red800: '#861b3d',
  red900: '#6f1036',

  blue100: '#def8fd',
  blue200: '#bdeffc',
  blue300: '#9bdef7',
  blue400: '#80caf0',
  blue500: '#75aee6',
  blue600: '#3f89c5',
  blue700: '#2b67a5',
  blue800: '#1b4985',
  blue900: '#10336e',

  gray100: '#f1f1f1',
  gray200: '#d6d6d6',
  gray300: '#adadad',
  gray400: '#8f8f8f',
  gray500: '#707070',
  gray600: '#666666',
  gray700: '#595959',
  gray800: '#474747',
  gray900: '#333333',
};
