import { API, UseCase } from '@ppg/common';

export interface ISettlementPolicyPlan {
  id: string;
  name: string;
  startFrom: string;
}

interface IGetCurrentAndNextSettlementPolicyPlanRequest {
  organization: string
}

interface CurrentAndNextPolicyPlan {
  project: string;
  currentPlan: ISettlementPolicyPlan;
  nextPlan: ISettlementPolicyPlan;
}

export class GetCurrentAndNextSettlementPolicyPlan
  extends UseCase<IGetCurrentAndNextSettlementPolicyPlanRequest, CurrentAndNextPolicyPlan[]> {
  protected async execute({organization}) {
    return API.HTTP.get<CurrentAndNextPolicyPlan[]>(
      `acc/organizations/${organization}/settlements/plans`
    )
  }
}

export const getCurrentAndNextSettlementPolicyPlan = new GetCurrentAndNextSettlementPolicyPlan();
