import { API, UseCase } from "@ppg/common";
import { ISendPushCampaign, ICampaignIdResponse } from '../interfaces';

interface ICreateRocketPushDraftRequest extends ISendPushCampaign {}
interface ICreateRocketPushDraftResponse extends ICampaignIdResponse {}

export class CreateRocketPushDraftUseCase extends UseCase<ICreateRocketPushDraftRequest, ICreateRocketPushDraftResponse> {
  protected async execute({ message, options }: ICreateRocketPushDraftRequest) {
    return API.HTTP.post<ICreateRocketPushDraftResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/rocket/draft`, { message, options });
  }
}

export const createRocketPushDraftUseCase = new CreateRocketPushDraftUseCase();
