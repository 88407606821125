import { colors, IColors } from './colors/colors';
import { font, IFont } from './font/font';
import { ITiming, timing, } from './timing/timing';
import { border, IBorder } from './border/border';
import { IShadow, shadows } from './shadows/shadows';

interface IMainTheme {
  colors: IColors,
  font: IFont,
  timing: ITiming,
  border: IBorder,
  shadows: IShadow,
}

export const MainTheme: IMainTheme = {
  colors,
  font,
  timing,
  border,
  shadows
};
