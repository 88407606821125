import { isEmail, localSettings, property } from '@ppg/common';
import { api } from '../base/helpers';
import { projectIntegrationTypes } from '../modelsMobx/project/IProject';
import { action, computed, observable } from 'mobx';
import { IntegrationValidators } from '../modelsMobx/helpers/IntegrationValidators';
import { sendIntegrationInstructionsUseCase } from "../useCases/notification/SendIntegrationInstructionsUseCase";
import { checkIntegrationValidationUseCase, getCurrentIntegrationValidatorsUseCase, getScriptsUseCase, updateStrategyUseCase } from '../useCases/website-integration';
import { WebsiteIntegrationRelated } from './project/WebsiteIntegrationRelated';
import { IIntegrationValidators, IProviderGCM } from '../useCases/website-integration/interfaces';

interface ISendInstructions {
  organization: string;
  embedCode: string;
  siteUrl: string;
  packageUrl: string;
}

interface IIntegrationStore {
  strategy: string;
  gcm: IProviderGCM;
  integrationValidators: IntegrationValidators;
  integrationInstructionEMail: string;
}

export class IntegrationStore extends WebsiteIntegrationRelated implements IIntegrationStore {

  private static REQUEST_INTERVAL = null;
  private static REQUEST_INTERVAL_TIME_MS = 1000;
  private static REQUEST_TIMEOUT = null;
  private static REQUEST_TIMEOUT_MS = 15000;

  @property() public strategy: string;
  @property() public gcm: IProviderGCM;
  @observable public integrationValidators: IntegrationValidators;
  @observable public isChecking: boolean = false;

  @observable @property([isEmail()]) public integrationInstructionEMail: string;

  @observable private checkId: string;

  public sendInstructions({ organization, embedCode, siteUrl, packageUrl }: ISendInstructions): Promise<void> {
    return sendIntegrationInstructionsUseCase.exec({
      email: this.integrationInstructionEMail,
      integration: this.strategy,
      siteUrl,
      files: api(packageUrl),
      embedCode,
      organization,
    });
  }

  @action
  public async saveStrategy(strategy: projectIntegrationTypes): Promise<void> {
    this.strategy = strategy;
    await updateStrategyUseCase.exec({
      websiteIntegrationId: this.websiteIntegrationId,
      strategy: this.strategy,
    });
    this.resetValidators();
  }

  @action
  public async checkIntegrationValidation(): Promise<void> {
    const { checkID } = await checkIntegrationValidationUseCase.exec({ websiteIntegrationId: this.websiteIntegrationId });
    this.checkId = checkID;
  }

  @action
  public resetValidators(): void{
    this.integrationValidators = null;
  }

  public async getCurrentIntegrationValidators(): Promise<IIntegrationValidators[]> {
    const token = localSettings.getValue('token');
    if (token === null) {
      return;
    }
    return await getCurrentIntegrationValidatorsUseCase.exec({
      websiteIntegrationId: this.websiteIntegrationId,
      checkID: this.checkId
    });
  }

  public isProcessed(arr: any[]): boolean {
    return arr && arr.every(validator => validator.state === 'finished' || validator.state === 'failed');
  }

  private async checkIntegrationValidators(): Promise<void> {

    const token = localSettings.getValue('token');
    if (token === null) {
      return this.clear();
    }
    let res = await this.getCurrentIntegrationValidators();

    if (this.isProcessed(res)) {
      this.clear();
      this.isChecking = false;
      return this.setValidators(res);
    }
  }

  public async checkIntegrationValidatorsStatus(): Promise<void> {
    await this.checkIntegrationValidation();

    if (!this.checkId) return;

    this.isChecking = true;
    this.checkIntegrationValidators().then(() => {
      IntegrationStore.REQUEST_INTERVAL = setInterval(
        async () => await this.checkIntegrationValidators(),
        IntegrationStore.REQUEST_INTERVAL_TIME_MS,
      );
    });

    IntegrationStore.REQUEST_TIMEOUT = setTimeout(
      async () => {
        clearInterval(IntegrationStore.REQUEST_INTERVAL);
        this.isChecking = false;
        this.setValidators(await this.getCurrentIntegrationValidators());
      },
      IntegrationStore.REQUEST_TIMEOUT_MS,
    );
  }

  @computed
  public get isNativeSelected(): boolean {
    return this.strategy === projectIntegrationTypes.NATIVE;
  }

  @computed
  public get isSimpleSelected(): boolean {
    return this.strategy === projectIntegrationTypes.SIMPLE;
  }

  @computed
  public get hasNoIntegrationErrors(): boolean {
    return this.integrationValidators && this.integrationValidators.hasNoErrors;
  }

  public async downloadScripts(): Promise<void> {
    await getScriptsUseCase.exec({
      websiteIntegrationId: this.websiteIntegrationId
    }).catch(err => err)
  }

  public clear(): void {
    clearInterval(IntegrationStore.REQUEST_INTERVAL);
    clearTimeout(IntegrationStore.REQUEST_TIMEOUT);
  }

  @action
  public setValidators(validators): void {
    this.integrationValidators = IntegrationValidators.createFromValidators(validators);
  }

  @computed
  public get hasGcm(): boolean {
    return this.gcm && this.gcm.enabled;
  }

  @computed
  public get integrationErrors(): string[] {
    return this.integrationValidators && this.integrationValidators.errorMessages || [];
  }
}
