import { API, UseCase } from '@ppg/common';

interface IChangePasswordWithTokenUseCase {
  token: string;
  password: string;
  confirmedPassword: string;
}

export class ChangePasswordWithTokenUseCase extends UseCase<IChangePasswordWithTokenUseCase, void> {
  protected async execute({ token, password, confirmedPassword }) {
    await API.HTTP.post<void>(`aai/user/password/reset/${token}`, { password, confirmedPassword });
  }
}

export const changePasswordWithTokenUseCase = new ChangePasswordWithTokenUseCase();
