import { API, UseCase } from '@ppg/common';
import { IOrganizationDashboardRequestBody } from '../../../interfaces';

interface IGetAutomationsStatisticsUseCaseRequest extends IOrganizationDashboardRequestBody {
  timezone: string;
}

interface IGetAutomationsStatisticsUseCaseResult {
  automationStats: IAutomationStats;
}

export interface IAutomationStats {
  clicked: number;
  delivered: number;
  ctr: number;
  automationHistogram: IAutomationDayStats[];
}

interface IAutomationDayStats {
  day: string;
  clicked: number;
  delivered: number;
  ctr: number;
}

export class GetAutomationsStatisticsUseCase extends UseCase<IGetAutomationsStatisticsUseCaseRequest, IGetAutomationsStatisticsUseCaseResult> {
  protected async execute({ organization, from, to, timezone }: IGetAutomationsStatisticsUseCaseRequest) {
    return API.HTTP.get<IGetAutomationsStatisticsUseCaseResult>(
      `statistics/organizations/${ organization }/campaigns/statistics/automation?from=${ from }&to=${ to }&timezone=${ timezone }`
    );
  }
}
