import * as lodash from 'lodash';
import { Model } from '../../base';
import { Collection } from 'backbone';

import {
  IGroupCondition,
  GroupCondition,
  ISingleConditionItem,
  SingleConditionItem,
  ICondition } from './index';

export class Condition extends Model<any, any> implements ICondition {

  public condition = new Collection();
  public step: any = null;

  constructor(step: any) {
    super();
    this.step = step;
    const attribute = step.getAttribute('condition');
    this.listenTo(this, 'all', () => attribute.setValue(this.toJSON()));
    this.fromCondition(attribute.getValue());
  }

  public toJSON() {
    return this.condition.toJSON();
  }

  /**
   * Add group
   */
  public addGroup(condition?: IGroupCondition) {
    this.condition.add(new GroupCondition(this, condition));
    this.trigger('change', this, this.collection);
  }

  /**
   * Add item
   */
  public addItem(condition?: ISingleConditionItem) {
    this.condition.add(new SingleConditionItem(this, condition));
    this.trigger('change', this, this.collection);
  }

  public fromCondition(conditions: (ISingleConditionItem | IGroupCondition)) {
    lodash.each(conditions, condition => {
      //@ts-ignore
      switch (condition.type) {
        case 'single':
          //@ts-ignore
          return this.addItem(condition);
        case 'group':
          //@ts-ignore
          return this.addGroup(condition);
      }
    });
  }

}
