import { API, UseCase } from '@ppg/common';
import { getListQueryParam, getRangeDateParam } from '../../../helpers';
import { IOrganizationDashboardRequestBody } from '../../../interfaces';
import { IListQueryOptions } from '../../../../interfaces/IListQueryOptions';

interface IGetCampaignsStatisticsHistogramRequest extends IOrganizationDashboardRequestBody, IListQueryOptions {
  timezone: string;
}

export interface IAutomationStatisticsHistogramItem {
  day: string;
  clicked: number;
  delivered: number;
  ctr: number;
}

interface IGetCampaignsStatisticsHistogramResult {
  dashboardAutomationStatisticsHistogram: {
    total: number,
    histogram: IAutomationStatisticsHistogramItem[]
  };
}

export class GetAutomationsStatisticsHistogramUseCase extends UseCase<IGetCampaignsStatisticsHistogramRequest, IGetCampaignsStatisticsHistogramResult> {
  protected async execute({
                            organization,
                            from,
                            to,
                            limit,
                            offset,
                            timezone
                          }: IGetCampaignsStatisticsHistogramRequest) {

    return API.HTTP.get<IGetCampaignsStatisticsHistogramResult>(`statistics/organizations/${ organization }/campaigns/statistics/automation/histogram${ getRangeDateParam(from, to) }${ getListQueryParam(offset, limit) }&timezone=${ timezone }`);
  };
}
