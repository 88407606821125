import { API, UseCase } from "@ppg/common";
import { ISendPushCampaign, ICampaignIdResponse } from '../interfaces';

interface ISendRocketPushRequest extends ISendPushCampaign {}
interface ISendRocketPushResponse extends ICampaignIdResponse {}

export class SendRocketPushUseCase extends UseCase<ISendRocketPushRequest, ISendRocketPushResponse> {
  protected async execute({ message, options }: ISendRocketPushRequest) {
    return API.HTTP.post<ISendRocketPushResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/rocket`, { message, options });
  }
}

export const sendRocketPushUseCase = new SendRocketPushUseCase();
