import { IPushAction, PushAction } from '../PushAction';
import { Utm } from '../helpers/Utm';
import { IDateVariables } from '../../components/commonMobX/Render';
import { Image, ImageType } from '../Image';
import { CampaignType } from './PushCampaign';

export interface ICollapse {
  title: number;
  content: number;
}

export enum CampaignDirectionType {
  AUTO = 'auto',
  LTR = 'ltr',
  RTL = 'rtl',
}

export type IProjectValues = IDirectionValue & IPushActionValue & IRequireInteractionValue & IRedirectLinkValue & IOmitCappingEnabled & IDefaultTitleValue;

export interface IProjectValuesEditCampaign {
  id: string;
  utm: Utm;
  omitCappingEnabled: boolean;
}

export interface IDirectionValue {
  isDirectionRtl: boolean;
}

export interface IPushActionValue {
  pushActions: IPushAction[];
}

export interface IRequireInteractionValue {
  requireInteraction: boolean;
}

export interface IRedirectLinkValue {
  redirectLink: string;
  utm: Utm;
}

export interface IOmitCappingEnabled {
  omitCappingEnabled: boolean;
}

export interface IAppendUTMs {
  utm: Utm;
  url: URL;
}

export interface IPushScope extends IDateVariables {
  title: string;
  redirectLink: string;
}

export interface IBasePush {
  title: string;
  content: string;
  icon: string;
  image: string;
  requireInteraction: boolean;
}

export interface IPushPreview extends IBasePush {
  iconURL: string;
  defaultIcon: string;
  actions: string[];
  isDirectionRtl: string;
}

export interface ITestPush extends IBasePush {
  redirectLink: string;
}

export interface ITTLRangeResponse {
  from: Date;
  to: Date;
  maxTTL?: number;
}

export interface IDefaultTitleValue {
  isDefaultTitleEnabled: boolean;
  defaultTitle: string;
}

export interface IStartExpireTimeValues {
  expireTime: number;
  isExpireTimeEnabled: boolean;
}

export interface ISaveCampaignValues extends IStartExpireTimeValues {
  isEditView: boolean;
  campaignType: CampaignType;
}

export interface ISaveDraftCampaignValues extends IStartExpireTimeValues {
  isEditView: boolean;
}

export interface ISetMetadataImage {
  url: string;
  type: ImageType;
}

export enum PushSendType {
  NOW = 'now',
  SCHEDULE = 'schedule'
}

export interface IPush {
  id: string;
  title: string;
  content: string;
  icon: Image;
  image: Image;
  redirectLink: string;
  actions: PushAction[];
  direction: CampaignDirectionType;
  requireInteraction: boolean;
  omitCapping: boolean;
  sendType: PushSendType;
  sendDate: Date;
  expireDate: Date;
  delayTime: number;
}
