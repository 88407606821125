import { observable } from 'mobx';
import { Accident } from '../modelsMobx/Accident';
import { getActiveAccidentsUseCase } from '../useCases/app-helper';

export class AccidentStore {

  @observable
  public accidents: Accident[] = [];

  public async fetchItems() {
    const response = await getActiveAccidentsUseCase.exec();
    this.accidents = response.map(accident => new Accident(accident));
  }

  currentAccidents(currentLocation: string) {
    return this.accidents.filter(accident => accident.occurredHere(currentLocation));
  }
}
