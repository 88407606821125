import { API, UseCase } from '@ppg/common';
import { IUpdateSendPushCampaign, ICampaignIdResponse } from '../interfaces';

interface IUpdateGeolocationPushRequest extends IUpdateSendPushCampaign {}
interface IUpdateGeolocationPushResponse extends ICampaignIdResponse {}

export class UpdateGeolocationPushUseCase extends UseCase<IUpdateGeolocationPushRequest, IUpdateGeolocationPushResponse> {
  protected async execute({ campaignId, options, message }: IUpdateGeolocationPushRequest) {
    return API.HTTP.put<IUpdateGeolocationPushResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/geolocation/${ campaignId }`, { message, options });
  }
}

export const updateGeolocationPushUseCase = new UpdateGeolocationPushUseCase();
