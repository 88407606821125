import { API, UseCase } from '@ppg/common';

interface RevokeLegacyApiKeyData {
  id: string;
  organization: string;
}

export class RevokeLegacyApiKeyUseCase extends UseCase<RevokeLegacyApiKeyData, void> {
  protected async execute({ id, organization }: RevokeLegacyApiKeyData) {
    return API.HTTP.delete<void>(`aai/organizations/${ organization }/api-keys/${ id }`);
  }
}

export const revokeLegacyApiKeyUseCase = new RevokeLegacyApiKeyUseCase();
