import { API, UseCase } from '@ppg/common';
import { IVideoListing } from '../../../modelsMobx/VideoTutorial';

interface IListActiveTutorials {
}

export interface IVideoTutorialResponse {
  id: string;
  componentName: string;
  lang: string;
  externalVideoId: string;
  listing: IVideoListing[];
}

interface IListActiveTutorialsResult {
  metadata: {
    total: number;
  };
  data: IVideoTutorialResponse[];
}

export class ListActiveVideoTutorialsUseCase extends UseCase<IListActiveTutorials, IListActiveTutorialsResult> {
  protected async execute(offset=0, limit=500) {
    return API.HTTP.get<IListActiveTutorialsResult>(`ah/video-tutorials/active?offset=${offset}&limit=${limit}`);
  }
}

export const listActiveVideoTutorialsUseCase = new ListActiveVideoTutorialsUseCase();
