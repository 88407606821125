import { BarDatum } from '@nivo/bar';
import { IChartCardSize } from './ChartCard/__types__';
import { DashboardCampaignType } from './enums';
import { Property } from '@ppg/common';
import { ICampaignClickedDeliveredHourStats, IDashboardCampaignsStatsWithCount } from '../../../useCases/interfaces';

export type CampaignsTabsTypes = {
  SENT: string,
  GENERAL: string,
  CTR: string,
  DSP: string,
  SENDING_HOURS: string,
  SUBSCRIBERS_ACTIVITY: string,
}

export interface CustomBarDatum extends BarDatum {
  color: string;
}

export enum IAutomationRankOptionsType {
  NUMBER_OF_CLICKED = 'Clicked',
  BEST_CTR = 'CTR'
}

export enum IAutomationItemOptionKeyType {
  CLICKED = 'clicked',
  CTR = 'ctr'
}

export interface IRangeFactors {
  from: string,
  to: string
}

export interface IChangeFactors {
  web: boolean,
  mobile: boolean
}

export type AutomationTabsTypes = {
  GENERAL: string,
  CTR: string,
}

export interface IDashboardAutomationChart {
  scrollAreaSetting: IChartCardSize | null;
  sizeSetting: IChartCardSize | null;
}

export interface IAssignBarChartItem {
  campaignType: DashboardCampaignType,
  campaignsTypeProperty: Property,
  campaigns: string[],
}

export interface IAssignLineChartItem {
  id: string,
  campaignsTypeProperty: Property,
  campaigns: string[],
  campaignType: DashboardCampaignType,
}

export interface IBarChartUpdateItem {
  property: Property,
  campaigns: string[],
  key: string,
  campaignTypeData: BarDatum[],
}

export interface IClickedDeliveredUpdateItem {
  campaignType: DashboardCampaignType,
  type: 'clicked' | 'delivered',
  clickedDeliveredHourStats: ICampaignClickedDeliveredHourStats[],
  campaigns: string[]
}

export interface ILineChartUpdateItem {
  stats: IDashboardCampaignsStatsWithCount,
  id: string,
  histogramItemDataKey: string,
}

export interface IExtendedLineChartUpdateItem extends ILineChartUpdateItem {
  chartDataProperty: Property,
  color?: string;
}
