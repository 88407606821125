import { API, UseCase } from '@ppg/common';
import { IUpdateWebPushEnabled } from '../../interfaces';

export class UpdateWebPushEnabledUseCase extends UseCase<IUpdateWebPushEnabled, void> {
  protected async execute({ websiteIntegrationId, enabled }: IUpdateWebPushEnabled) {
    await API.HTTP.put(`wi/projects/${ API.HTTP.currentProjectId }/website-integrations/${ websiteIntegrationId }/plugins/web-push/enabled`, { enabled })
  }
}

export const updateWebPushEnabledUseCase = new UpdateWebPushEnabledUseCase();
