import * as React from 'react';
import { SettingsContactInfo } from './SettingsContactInfo';
import { SettingsPassword } from './SettingsPassword';
import { SettingsApplication } from './SettingsApplication';
import { SettingsLanguage } from './SettingsLanguage';
import { SettingsDeleteUser } from './SettingsDeleteUser';
import './Settings.scss';
import { PageSection } from '../../Layouts/UserLayout/PageSection';
import { UserHeader } from '../../Layouts/UserLayout/UserHeader/UserLayoutHeader';
import { t } from '../../../../base/helpers';

export class Settings extends React.Component {
  public render() {
    return (
      <section>
        <UserHeader title={ t('Profile settings') }/>

        <PageSection>
          <SettingsContactInfo/>
        </PageSection>

        <PageSection>
          <SettingsLanguage/>
          <SettingsApplication/>
        </PageSection>

        <SettingsPassword/>

        <PageSection>
          <SettingsDeleteUser/>
        </PageSection>
      </section>
    );
  }
}
