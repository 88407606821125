import * as React from "react";
import "./AutomationStatWrapper.scss";

interface IAutomationStatWrapper {
  children: React.ReactNode;
}

export const AutomationStatWrapper = ({ children }: IAutomationStatWrapper): JSX.Element => {
  return <div className={ 'automation-stats-cards' }>
    { children }
  </div>
}