import { ProjectRelated } from "../ProjectRelatedStore";
import { property } from "@ppg/common";
import { action } from "mobx";
import {
  createIOSIntegrationUseCase,
  createSafariIntegrationUseCase,
  getAndroidProviderUseCase,
  getHMSProviderUseCase,
  getProjectProviderUseCase,
  toggleProductionFlagOnIOSProviderUseCase,
  updateFCMProviderUseCase,
  updateHMSProviderUseCase,
  updateIOSCertificatesUseCase,
  updateSafariCertificatesUseCase,
} from "../../useCases/core";
import {
  FCMProvider,
  HMSProvider,
  IOSProvider,
  SafariProvider,
  VapidKeys,
} from "../../modelsMobx";
import { IWebsiteDetails } from "../../pages/ProjectProviders/ProjectProviders";

interface IProviderStore {
  vapidKeys: VapidKeys;
  fcmProvider: FCMProvider;
  hmsProvider: HMSProvider;
  safariProvider: SafariProvider;
  iosProvider: IOSProvider;
}

export class ProviderStore extends ProjectRelated implements IProviderStore {
  @property() public vapidKeys;
  @property() public fcmProvider;
  @property() public hmsProvider;
  @property() public safariProvider;
  @property() public iosProvider;

  @action
  public getProvidersData = async (): Promise<void> => {
    const { integrations } = await getProjectProviderUseCase.exec({
      projectId: this.projectId,
      organizationId: this.organizationId,
    });

    const vapidData = integrations[0];
    const hmsData = integrations[1];
    const fcmData = integrations[2];
    const safariData = integrations[3];
    const iosData = integrations[4];

    this.hmsProvider = HMSProvider.createProvider({
      authUrl: hmsData.payload.authUrl,
      appId: hmsData.payload.appId,
      pushUrl: hmsData.payload.pushUrl,
      appSecret: hmsData.payload.appSecret,
      actions: hmsData.actions,
    });

    this.fcmProvider = FCMProvider.createProvider({
      fcmSenderId: fcmData.payload.fcmSenderId,
      fcmName: fcmData.payload.fcmName,
      authorizationKey: fcmData.payload.authorizationKey,
      actions: fcmData.actions,
    });

    this.iosProvider = IOSProvider.createProvider({
      production: iosData.payload.production,
      actions: iosData.actions,
      notBefore: iosData.payload.notBefore
        ? new Date(iosData.payload.notBefore)
        : null,
      notAfter: iosData.payload.notAfter
        ? new Date(iosData.payload.notAfter)
        : null,
    });

    this.safariProvider = SafariProvider.createProvider({
      websitePushId: safariData.payload.websitePushId,
      actions: safariData.actions,
      notBefore: safariData.payload.notBefore
        ? new Date(safariData.payload.notBefore)
        : null,
      notAfter: safariData.payload.notAfter
        ? new Date(safariData.payload.notAfter)
        : null,
    });

    this.vapidKeys = VapidKeys.createProvider({
      actions: vapidData.actions,
      publicKey: vapidData.payload.publicKey,
      privateKey: vapidData.payload.privateKey,
    });
  };

  @action
  public createSafariIntegration = async ({
    name,
    siteUrl,
  }: IWebsiteDetails): Promise<void> => {
    await createSafariIntegrationUseCase.exec({
      projectId: this.projectId,
      siteUrl,
      name,
      passphrase: this.safariProvider.passphrase,
      p12: this.safariProvider.p12,
      organizationId: this.organizationId,
    });
  };

  @action
  public updateSafariCertificates = async (): Promise<void> => {
    await updateSafariCertificatesUseCase.exec({
      projectId: this.projectId,
      passphrase: this.safariProvider.passphrase,
      p12: this.safariProvider.p12,
      organizationId: this.organizationId,
    });
  };

  @action
  public createIOSIntegration = async (): Promise<void> => {
    await createIOSIntegrationUseCase.exec({
      projectId: this.projectId,
      p12: this.iosProvider.p12,
      production: this.iosProvider.production,
      passphrase: this.iosProvider.passphrase,
      organizationId: this.organizationId,
    });
  };

  @action
  public updateIOSCertificates = async (): Promise<void> => {
    await updateIOSCertificatesUseCase.exec({
      projectId: this.projectId,
      p12: this.iosProvider.p12,
      production: this.iosProvider.production,
      passphrase: this.iosProvider.passphrase,
      organizationId: this.organizationId,
    });
  };

  @action
  public toggleProductionFlag = async (): Promise<void> => {
    await toggleProductionFlagOnIOSProviderUseCase.exec({
      projectId: this.projectId,
      production: this.iosProvider.production,
      organizationId: this.organizationId,
    });
  };

  @action
  public updateHMSProvider = async (): Promise<void> => {
    await updateHMSProviderUseCase.exec({
      projectId: this.projectId,
      pushUrl: this.hmsProvider.pushUrl,
      authUrl: this.hmsProvider.authUrl,
      appSecret: this.hmsProvider.appSecret,
      appId: this.hmsProvider.appId,
      organizationId: this.organizationId,
    });
  };

  @action
  public updateFCMProvider = async (): Promise<void> => {
    await updateFCMProviderUseCase.exec({
      projectId: this.projectId,
      fcmName: this.fcmProvider.fcmName,
      fcmSenderId: this.fcmProvider.fcmSenderId,
      authorizationKey: this.fcmProvider.authorizationKey,
      v1: this.fcmProvider.v1,
      organizationId: this.organizationId,
    });
  };

  @action
  public fetchHMSProviderUnmaskedData = async (): Promise<void> => {
    const { appSecret } = await getHMSProviderUseCase.exec({
      projectId: this.projectId,
      organizationId: this.organizationId,
    });
    this.hmsProvider.isAppSecretUnmasked = true;
    this.hmsProvider.setAppSecret(appSecret);
  };

  @action
  public fetchAndroidUnmaskedAuthKey = async (): Promise<void> => {
    const { authorizationKey } = await getAndroidProviderUseCase.exec({
      projectId: this.projectId,
      organizationId: this.organizationId,
    });
    this.fcmProvider.isAuthKeyUnmasked = true;
    this.fcmProvider.setAuthKeySecret(authorizationKey);
  };

  public getHMSProviderUnmaskedSecret = async (): Promise<void> => {
    if (this.hmsProvider.hasUnmaskedAppSecret) {
      return this.hmsProvider.appSecret;
    }
    await this.fetchHMSProviderUnmaskedData();
  };

  public getAndroidAuthKey = async (): Promise<void> => {
    if (this.fcmProvider.hasUnmaskedAppSecret) {
      return this.fcmProvider.authorizationKey;
    }
    await this.fetchAndroidUnmaskedAuthKey();
  };

  public setDefaultValues(): void {
    this.vapidKeys = VapidKeys.createProvider();
    this.iosProvider = IOSProvider.createProvider();
    this.hmsProvider = HMSProvider.createProvider();
    this.fcmProvider = FCMProvider.createProvider();
    this.safariProvider = SafariProvider.createProvider();
  }
}
