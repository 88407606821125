import * as lodash from 'lodash';
import * as React from 'react';
import { t } from '../../base/helpers';
import { AbstractFiled } from './AbstractFiled';
import { ISelectFieldProps } from './ISelectFieldProps';

export class SelectField extends AbstractFiled<ISelectFieldProps, {}> {

  public componentWillMount() {
    const { attribute, defaultValue } = this.props;
    if (lodash.isEmpty(attribute.getValue())) {
      attribute.setValue(defaultValue);
    }
  }

  private onChange(event: React.FormEvent<HTMLSelectElement>) {
    this.props.attribute.setValue(event.currentTarget.value || null);
    lodash.invoke(this.props, 'onChange', event);
  }

  public render() {
    const { attribute, options, placeholder, disabled, className } = this.props;
    return (
      <div>
        { placeholder && <label>{ placeholder }</label> }
        <select
          value={ attribute.getValue() || '' }
          disabled={ disabled || false }
          onChange={ (e) => this.onChange(e) }
          className={ className }>
          { lodash.map(options, (option, index) =>
            <option key={ `${this.inputId}_${option.name}_${index}` } value={ option.value || '' }
                    disabled={ option.disabled || false }>{ (this.props.t || option.translate) ? t(option.name) : option.name }</option>
          ) }
        </select>
      </div>
    );
  }
}
