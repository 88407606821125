import * as React from 'react';
import { numberWithSpaces, t } from '../../../../base/helpers';
import './StatCard.scss'
import { Help, LineLoader } from '@ppg/styled';

export interface IStatCardDetail {
  title: string;
  count: number | string;
}

interface IStatCardProps {
  title: string;
  count: number | string;
  details: IStatCardDetail[];
  isValueInPercent?: boolean;
  isLoading: boolean;
  unit?: string;
  helper?: string;
}

export const StatCardDetailed = function ({ title = '', count = 0, isValueInPercent = false, isLoading, unit = '', helper = '', details }: IStatCardProps): JSX.Element {
  const countVal = (count: number | string): string => (typeof count === 'string') ? count : numberWithSpaces(count);
  return <div className={ `stats-card stats-card-detailed column ` }>
    { isLoading && <>
      <span className={ `icon-refresh size16` }/>
      <LineLoader/>
    </> }
    <div className='stats-card__data'>
      <div className="count-num">{ countVal(count) }{ isValueInPercent ? '%' : '' } { unit } </div>
      <div className="count-title">{ title } { !!helper && <Help content={ helper }/> }</div>
    </div>
    <div className='stats-card__data'>
      <ul className="count-title count-title-list">{ t('Includes') }:
        { details.map(({ title = '', count = 0 }, idx) => <li className='detail-item' key={ idx }>
          <span className='details-title'>{ title }</span>: { countVal(count) }
        </li>) }
      </ul>
    </div>
  </div>
};
