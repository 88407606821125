import * as React from 'react';
import { t } from '../../base/helpers';
import { Modal, PasteButton } from '@ppg/styled';
import { SelectorsFormView } from './SelectorsFormView';
import { SelectorStore } from '../../stores/project/SelectorStore';
import { inject, observer } from 'mobx-react';

interface ISelectorsProps {
  selectorStore?: SelectorStore;
  modalConfirmHandler: () => {};
  availableLabels: string[]
}

@inject('selectorStore')
@observer
export class SelectorsModal extends React.Component<ISelectorsProps, any> {

  public render() {
    const { selectorStore, modalConfirmHandler, availableLabels } = this.props;

    if (selectorStore.selectorToEdit === null) {
      return null;
    }

    return <Modal show={ !!selectorStore.selectorToEdit }
                  title={ this.modalHeader() }
                  content={ <SelectorsFormView
                    availableLabels={availableLabels}
                    selector={ selectorStore.selectorToEdit }
                    labelStrategyOptions={ selectorStore.labelStrategyOptions }
                  /> }
                  onCancel={ () => selectorStore.onCancel() }
                  onConfirm={ () => modalConfirmHandler() }
                  confirmButton={ t('Save') }
                  cancelButton={ t('Cancel') }
                  disabled={ !selectorStore.canSave }
                  width={ 500 }
                  contentHeight={ 500 }/>;
  }

  private modalHeader() {
    const { selectorStore } = this.props;
    return (
      <div className="row row-center selector-modal">

        <div className={ `heading-wrapper` }>
          <h1>{ t('Selector settings') }</h1>
        </div>
        { selectorStore.selectorToEdit.isNew &&
        <PasteButton size="small"
                     dataTipText={ t('Paste') }
                     messageWhenToPaste={ t('Paste from clipboard') }
                     messageWhenPasted={ t('Pasted successfully') }
                     modelName="selector"
                     toPaste={ (model) => {
                       selectorStore.onPaste(model);
                     } }/>
        }
      </div>);
  }
}
