import { API, UseCase } from '@ppg/common';
import { PlatformType } from '../../interfaces';
import { ComparisonActivity } from '../../../modelsMobx/ComparisonActivity';

interface IGetSubscribersActivityComparisonRequest {
  from: string;
  to: string;
  platform?: PlatformType;
}

export interface SubscribersActivityComparison {
  subscribed: ComparisonActivity;
  unregistered: ComparisonActivity;
}

interface IGetSubscribersActivityComparisonResult {
  subscribersActivityComparison: SubscribersActivityComparison;
}

export class GetSubscribersActivityComparisonUseCase extends UseCase<IGetSubscribersActivityComparisonRequest, IGetSubscribersActivityComparisonResult> {
  protected async execute({ from, to, platform }: IGetSubscribersActivityComparisonRequest) {
    if (API.HTTP.currentProjectId) {
      return API.HTTP.get<IGetSubscribersActivityComparisonResult>(`statistics/v2/projects/${ API.HTTP.currentProjectId }/subscribers/activity/comparison${ platform ? `/${ platform }` : '' }?from=${ from }&to=${ to }`);
    }
  }
}

