import { API, UseCase } from "@ppg/common";

interface ICopySegmentRequest {
  segment: string;
}

interface ICopySegmentResponse {
  id: string;
}

export class CopySegmentUseCase extends UseCase<ICopySegmentRequest, ICopySegmentResponse> {
  protected async execute({ segment }: ICopySegmentRequest) {
    return API.HTTP.post<ICopySegmentResponse>(`core/projects/${ API.HTTP.currentProjectId }/segment/${ segment }`);
  }
}

export const copySegmentUseCase = new CopySegmentUseCase();
