import { API, UseCase } from "@ppg/common";

interface IGetPushStateCommand {
  projectId: string;
  pushId: string;
}

interface IGetPushState {
  state: string;
}

export class GetPushStateUseCase extends UseCase<IGetPushStateCommand, IGetPushState> {
  protected async execute({ projectId, pushId }: IGetPushStateCommand) {
    return API.HTTP.get<IGetPushState>(`core/projects/${ projectId }/pushes/${ pushId }/state`);
  }
}

export const getPushStateUseCase = new GetPushStateUseCase();
