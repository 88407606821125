import { API, UseCase } from '@ppg/common';

interface IGetPaymentCards {
  organization: string;
}

interface IGetPaymentCardsResult {
  id: string;
  brand: string;
  number: string;
  expireMonth: number;
  expireYear: number;
  recurring: boolean;
}

export class GetPaymentCardsUseCase extends UseCase<IGetPaymentCards, IGetPaymentCardsResult[]> {
  protected async execute({ organization }) {
    return API.HTTP.get<IGetPaymentCardsResult[]>(`acc/organizations/${ organization }/payments/cards`)
  }
}

export const getPaymentCardsUseCase = new GetPaymentCardsUseCase();
