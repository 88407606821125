import { UseCase, API, localSettings } from '@ppg/common';
import { IGetPlugin } from './interfaces';

export class GetScriptsUseCase extends UseCase<IGetPlugin, any> {
  protected async execute({ websiteIntegrationId }: IGetPlugin) {
    const token = localSettings.getValue('token');

    await fetch(`${ API.HTTP.getBasePath() }/wi/projects/${ API.HTTP.currentProjectId }/website-integrations/${ websiteIntegrationId }/scripts`, {
      headers: {
        'x-token': token,
      }
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Cannot download scripts');
        }

        return res.arrayBuffer();
      })
      .then((data) => {
        const file = new Blob([data], { type: 'application/zip' });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(file);
        link.download = 'pushpushgo.zip';
        link.click()
      })

  }
}

export const getScriptsUseCase = new GetScriptsUseCase();
