import { API, UseCase } from "@ppg/common";
import { ICampaignId, ICampaignIdResponse } from '../interfaces';

interface ISendDraftLabelPushRequest extends ICampaignId {}
interface ISendDraftLabelPushResponse extends ICampaignIdResponse {}

export class SendDraftLabelPushUseCase extends UseCase<ISendDraftLabelPushRequest, ISendDraftLabelPushResponse> {
  protected async execute({ campaignId }: ISendDraftLabelPushRequest) {
    return API.HTTP.post<ISendDraftLabelPushResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/labels/${ campaignId }/accept`);
  }
}

export const sendDraftLabelPushUseCase = new SendDraftLabelPushUseCase();
