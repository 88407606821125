import cs from "classnames";
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { connector } from '../../base';
import { t } from '../../base/helpers';
import { WebUser } from '../../models';
import { ProjectSelector } from './projectSelector';
import { PPGLite, PPGLiteRole } from '../PPGLite/PPGLite';
import { ProjectRoutesTypes } from '../../routes/moduleProjectRoutes';
import { projectStore } from '../../stores'

interface IProjectSidebarProps {
  user?: WebUser;
  hideNavigation: boolean;
  toggleNav: () => void;
}

@connector.inject('user')
export class ProjectSidebar extends React.Component<IProjectSidebarProps, {}> {
  public render() {
    const { user, hideNavigation, toggleNav:toggleNavigation } = this.props;
    const isNavLinksDisabled = !projectStore.hasProjects;
    const toggleNav = (e) => isNavLinksDisabled ? e.preventDefault() : toggleNavigation ;

    const isMigratedToSegmentation: boolean = projectStore.featureStore.hasFeature("SEGMENT_FULLY_MIGRATED")

    return (
      <PPGLite role={ PPGLiteRole.WRAP }>
        <div className={ cs('main-nav', { 'nav-hidden': hideNavigation }) }>
          <div className={ cs( `main-nav ${isNavLinksDisabled ? 'disabled' : ''}`) }>
              <ProjectSelector { ...this.props as any } />
              <nav className="main-nav-list" id="navigation">
                <NavLink to={ ProjectRoutesTypes.DASHBOARD } activeClassName="active" id="dashboardNav" onClick={ toggleNavigation }>
                  <span className="icon icon-stat-lined"/>
                  <span>{ t('Dashboard') }</span>
                </NavLink>
                { user.selectedProject &&
                  <div className="main-nav-submenu">
                    <NavLink to={ ProjectRoutesTypes.PROJECT_DASHBOARD } activeClassName="active" onClick={ toggleNav }>
                      <span>{ t('Project analytics') }</span>
                    </NavLink>
                  </div>
                }
                <NavLink to={ ProjectRoutesTypes.CAMPAIGN_NEW } activeClassName="active" id="sendCampaignNav" onClick={ toggleNav }>
                  <span className="icon icon-send"/>
                  <span>{ t('Send Campaign') }</span>
                </NavLink>
                <NavLink to={ isMigratedToSegmentation ? ProjectRoutesTypes.CAMPAIGN_LIST_PUSHES : ProjectRoutesTypes.CAMPAIGN_LIST_PUSH } activeClassName="active" id="campaignsNav" onClick={ toggleNav }>
                  <span className="icon icon-apps"/>
                  <span>{ t('Campaigns') }</span>
                </NavLink>
                <NavLink to={ ProjectRoutesTypes.AUTOMATION } activeClassName="active" id="automationNav" onClick={ toggleNav }>
                  <span className="icon icon-play"/>
                  <span>{ t('Automation') }</span>
                </NavLink>
                <div id="selectorsNav">
                  { user.selectedProject &&
                  <div className="main-nav-submenu">
                    <NavLink to={ ProjectRoutesTypes.AUTOMATION_SELECTORS } activeClassName="active" onClick={ toggleNav }>
                      <span>{ t('Selectors') }</span>
                    </NavLink>
                  </div>
                  }
                </div>
                <NavLink to={ isMigratedToSegmentation ? ProjectRoutesTypes.SUBSCRIBERS : ProjectRoutesTypes.SUBSCRIBER_LIST } activeClassName="active" id="subsNav" onClick={ toggleNav }>
                  <span className="icon icon-subscribers"/>
                  <span>{ t('Subscribers') }</span>
                </NavLink>
                <div className="main-nav-submenu">
                  <NavLink to={ ProjectRoutesTypes.GEOLOCATION } activeClassName="active" onClick={ toggleNav }>
                    <span>{ t('Geolocation') }</span>
                  </NavLink>
                </div>
                { isMigratedToSegmentation && <div className="main-nav-submenu">
                  <NavLink to={ ProjectRoutesTypes.SEGMENTS } activeClassName="active" onClick={ toggleNav }>
                    <span>{ t('Segments') }</span>
                  </NavLink>
                </div> }
                { user.selectedProject &&
                <NavLink to={ ProjectRoutesTypes.PROJECT } activeClassName="active" id="websiteNav" onClick={ toggleNav }>
                  <span className="icon icon-browser"/>
                  <span>{ t('Website') }</span>
                </NavLink>
                }
                { user.selectedProject &&
                  <div className="main-nav-submenu">
                    <NavLink to={ ProjectRoutesTypes.PROJECT_PROVIDERS } activeClassName="active" onClick={ toggleNav }>
                      <span>{ t('Providers') }</span>
                    </NavLink>
                  </div>
                }
                { user.selectedProject &&
                <div className="main-nav-submenu">
                  <NavLink to={ ProjectRoutesTypes.PROJECT_INTEGRATION } activeClassName="active" onClick={ toggleNav }>
                    <span>{ t('Installation') }</span>
                  </NavLink>
                </div>
                }
                <NavLink to={ ProjectRoutesTypes.TEMPLATES_ELEMENTS } activeClassName="active" id="elementsNav" onClick={ toggleNav }>
                  <span className="icon icon-pencil size16"/>
                  <span>{ t('Elements') }</span>
                </NavLink>
                <div className="main-nav-submenu">
                  <NavLink to={ ProjectRoutesTypes.TEMPLATES_MSG_SIDEBAR } activeClassName="active" onClick={ toggleNav }>
                    <span>{ t('Subscription manager') }</span>
                  </NavLink>
                </div>

                <NavLink to={ ProjectRoutesTypes.PHOTO_LIBRARY } activeClassName="active" onClick={ toggleNav }>
                  <span className="icon icon-picture"/>
                  <span>{ t('Photo Library') }</span>
                </NavLink>

                <PPGLite role={PPGLiteRole.HIDE}>
                  <NavLink to={ ProjectRoutesTypes.INTEGRATIONS } activeClassName="active" id="integrationsNav" onClick={ toggleNav }>
                    <span className="icon icon-puzzle"/>
                    <span>{ t('Integrations') }</span>
                  </NavLink>

                  <NavLink to={ ProjectRoutesTypes.EXPORT_LIST } activeClassName="active" id="exportsNav" onClick={ toggleNav }>
                    <span className="icon icon-cloud-sync"/>
                    <span>{ t('Exports') }</span>
                  </NavLink>
                </PPGLite>

              </nav>
          </div>
        </div>
      </PPGLite>
    );
  }
}
