import { LineChartData } from '../../components/Charts/LineChart/LineChart';
import { action, computed, observable, reaction } from 'mobx';
import { ComparatorTable } from '../../pages/Dashboard/Organization/ProjectComparison/ProjectComparisonDetails/ComparatorTable';
import {
  getActiveSubscribersComparatorUseCase,
  getAutomationsComparatorHistogramListUseCase,
  getAutomationsComparatorHistogramUseCase,
  getAutomationsComparatorStatsUseCase,
  getCampaignsComparatorHistogramUseCase,
  getCampaignsComparatorStatisticsUseCase,
  getCampaignsComparatorTableUseCase,
  getComparatorProjectRankUseCase,
  getSubscribedComparatorUseCase,
  getSubscriberComparatorHistogramUseCase,
  getSubscriberComparatorTableDataUseCase,
} from '../../useCases/statistics/organization';
import { DashboardCampaignType } from '../../pages/Dashboard/common/enums';
import { IComparatorStatisticsDay, StatsType } from '../../useCases/statistics/organization/campaigns/GetCampaignsComparatorHistogramUseCase';
import { OrganizationDashboardRelated } from './OrganizationDashboardRelated';
import dayjs from 'dayjs';
import { projectStore } from '..';
import { comparisonTabs } from '../../pages/Dashboard/Organization/ProjectComparison/ProjectComparisonDetails/ProjectComparisonDetails';
import { IOrganizationProjectItem } from '../../pages/Dashboard/common/ProjectsRank/ProjectsRank';
import { property, PropertyHandler } from '@ppg/common';
import { REDUCED_ITEMS_PER_PAGE } from '../../constants';

export class ProjectComparisonStore extends OrganizationDashboardRelated {

  @observable
  public projectRankSelect: ProjectRankOptionsType = ProjectRankOptionsType.SUBSCRIBERS;

  @observable
  public projectRankItems: IOrganizationProjectItem[] = [];

  /*
   * Stats
   * */

  @observable
  public subscriptions: number = 0;

  @observable
  public activeSubscribers: number = 0;

  @observable
  public sentCampaigns: number = 0;

  @observable
  public averageSummaryCTR: number = 0;

  @observable
  public averageTriggeredCTR: number = 0;

  @observable
  public clicked: number = 0;

  /*
   * Stats loaders
   * */

  @observable
  public isSubscriptionsLoading: boolean = true;

  @observable
  public isActiveSubscribersLoading: boolean = true;

  @observable
  public isSummaryCampaignsStatsLoading: boolean = true;

  @observable
  public isTriggeredCampaignsCtrLoading: boolean = true;

  @observable
  public triggeredCampaignsInitialLoading: boolean = false;

  /*
   * Charts
   * */

  @observable
  public subscribedChartData: LineChartData = [];

  @observable
  public unsubscribedChartData: LineChartData = [];

  @observable
  public campaignsClickedChartData: LineChartData = [];

  @observable
  public campaignsSentChartData: LineChartData = [];

  @observable
  public campaignsCtrChartData: LineChartData = [];

  @observable
  public automationCtrChartData: LineChartData = [];

  /*
   * Tables
   * */

  @property()
  public offset: number = 0;

  @property()
  public limit: number = REDUCED_ITEMS_PER_PAGE;

  @property()
  public total: number = 0;

  @observable
  public comparatorAutomationTables: AutomationTables = new AutomationTables();

  @observable
  public comparatorSubscriberTables: SubscribersTables = new SubscribersTables();

  @observable
  public comparatorCampaignTables: CampaignsTables = new CampaignsTables();

  cleanSubscribersData = (): void => {
    this.subscribedChartData = [];
    this.unsubscribedChartData = [];
    this.comparatorSubscriberTables = new SubscribersTables();
  };

  cleanCampaignsData = (): void => {
    this.campaignsCtrChartData = [];
    this.campaignsClickedChartData = [];
    this.campaignsSentChartData = [];
    this.comparatorCampaignTables = new CampaignsTables();
  };

  cleanAutomationData = (): void => {
    this.automationCtrChartData = [];
    this.comparatorAutomationTables = new AutomationTables();
  };

  @action
  public parseComparatorHistogramToLineChartData = (comparatorHistogram: any, histogramDaysKey: string, histogramDayItemKey: string, from: Date, to: Date): LineChartData => {
    return comparatorHistogram.map(histogramItem => {
      return {
        id: projectStore.projects.find(project => project.id === histogramItem.project).name,
        data: this.parseAndAddMissingDays(
          histogramItem[histogramDaysKey].map(histogramDaysItem => {
            return {
              x: dayjs(histogramDaysItem.day).format('YYYY-MM-DD'),
              y: histogramDaysItem[histogramDayItemKey]
            };
          }), from, to, 'x', { y: 0 }, 'y')
      };
    });
  };

  /*
   * Stats
   * */

  @action
  public fetchComparatorStats = async () => {
    if (!this.isPlatformSelected) {
      this.clearCampaignsStats();
      return;
    }

    await Promise.all([
      this.fetchActiveSubscribersComparator(),
      this.fetchAutomationStatsWithHistogram(),
      this.fetchSummaryCampaignsComparatorStatistics(),
      this.fetchSubscribedComparator(),
    ]);
  };

  @action
  public fetchSubscribedComparator = async (): Promise<void> => {
    this.isSubscriptionsLoading = true;
    const { subscribersActivity } = await getSubscribedComparatorUseCase.exec({ ...this.organizationRequestBody });
    this.subscriptions = subscribersActivity.subscribedSum;
    this.isSubscriptionsLoading = false;
  };

  @action
  public fetchActiveSubscribersComparator = async (): Promise<void> => {
    if (!this.isPlatformSelected) {
      return;
    }
    this.isActiveSubscribersLoading = true;
    const { activeSubscribers } = await getActiveSubscribersComparatorUseCase.exec({ ...this.organizationRequestBody });
    this.activeSubscribers = activeSubscribers.activeSubscribersSum;
    this.isActiveSubscribersLoading = false;
  };

  @action
  public fetchSummaryCampaignsComparatorStatistics = async (): Promise<void> => {
    this.isSummaryCampaignsStatsLoading = true;

    const { campaignComparatorStats } = await getCampaignsComparatorStatisticsUseCase.exec({
      ...this.organizationRequestBody,
      timezone: this.timezone,
      type: DashboardCampaignType.SUMMARY,
    });

    this.sentCampaigns = campaignComparatorStats.sentCampaignsSum;
    this.averageSummaryCTR = campaignComparatorStats.averageCampaignCTR;
    this.clicked = campaignComparatorStats.clickedSum;
    this.isSummaryCampaignsStatsLoading = false;
  };

  @action
  public fetchAutomationStatsWithHistogram = async (): Promise<void> => {
    if (!this.bothPlatformsEnabled) {
      this.clearTriggeredCampaigns();
      return;
    }

    this.isTriggeredCampaignsCtrLoading = true;
    if (this.activeTab === comparisonTabs.AUTOMATION) {
      await Promise.all([
        this.fetchAutomationCampaignsCtrStatistics(),
        this.fetchAutomationHistogram()
      ]);
    } else {
      await this.fetchAutomationCampaignsCtrStatistics();
    }

    this.isTriggeredCampaignsCtrLoading = false;
    this.triggeredCampaignsInitialLoading = true;
  };

  @action
  public fetchAutomationCampaignsCtrStatistics = async (): Promise<void> => {
    const { automationStats } = await getAutomationsComparatorStatsUseCase.exec({
      ...this.organizationRequestBody,
      timezone: this.timezone,
    });

    this.averageTriggeredCTR = automationStats.averageCampaignCTR;
  };

  @action
  public clearTriggeredCampaigns = (): void => {
    this.averageTriggeredCTR = 0;
    this.automationCtrChartData = [];
  };

  @action
  public clearCampaignsStats = (): void => {
    this.subscriptions = 0;
    this.activeSubscribers = 0;
    this.sentCampaigns = 0;
    this.averageSummaryCTR = 0;
    this.averageTriggeredCTR = 0;
    this.clicked = 0;
  };

  @action
  public clearOffset = (): void => {
    this.comparatorCampaignTables.clearOffset();
    this.comparatorSubscriberTables.clearOffset();
    this.comparatorAutomationTables.clearOffset();
  };

  reactOnTabChange = reaction(
    () => this.activeTab,
    () => this.clearOffset()
  );

  reactOnRangeChange = reaction(
    () => this.rangeChangeFactors,
    () => this.clearOffset(),
  );

  /*
   * Subscribers
   * */

  @action
  public fetchSubscribersHistogram = async (): Promise<void> => {
    if (!this.isPlatformSelected) {
      return;
    }

    const { subscribersHistogram } = await getSubscriberComparatorHistogramUseCase.exec(this.organizationRequestBody);
    const { from, to } = this.requestDateRange;
    this.subscribedChartData = this.parseComparatorHistogramToLineChartData(subscribersHistogram, 'subscriberDays', 'subscribed', from, to);
    this.unsubscribedChartData = this.parseComparatorHistogramToLineChartData(subscribersHistogram, 'subscriberDays', 'unsubscribed', from, to);
  };

  @action
  public fetchSubscribersTableData = async (type: 'subscribed' | 'unsubscribed'): Promise<void> => {
    const { subscribersActivityStatistics } = await getSubscriberComparatorTableDataUseCase.exec({
      ...this.organizationRequestBody,
      limit: this.limit,
      offset: this.comparatorSubscriberTables[type].offset,
    });

    const { from, to } = this.requestDateRange;
    this.comparatorSubscriberTables.fromData(subscribersActivityStatistics[type], type, from, to, this.limit);
  };

  /*
   * Campaigns
   * */

  @action
  public fetchCampaignsHistogram = async (): Promise<void> => {
    if (!this.isPlatformSelected) {
      return;
    }

    const { campaignComparatorHistogram } = await getCampaignsComparatorHistogramUseCase.exec({
      ...this.organizationRequestBody,
      type: DashboardCampaignType.SUMMARY,
    });
    const { from, to } = this.requestDateRange;
    this.campaignsCtrChartData = this.parseComparatorHistogramToLineChartData(campaignComparatorHistogram, 'campaignDays', 'ctr', from, to);
    this.campaignsSentChartData = this.parseComparatorHistogramToLineChartData(campaignComparatorHistogram, 'campaignDays', 'campaignCount', from, to);
    this.campaignsClickedChartData = this.parseComparatorHistogramToLineChartData(campaignComparatorHistogram, 'campaignDays', 'clicked', from, to);
  };

  @action
  public clearCampaignsHistogram = (): void => {
    this.campaignsCtrChartData = [];
    this.campaignsSentChartData = [];
    this.campaignsClickedChartData = [];
  };

  @action
  public fetchComparisonCampaignTable = async (type: StatsType): Promise<void> => {
    const { campaignStatistics } = await getCampaignsComparatorTableUseCase.exec({
      ...this.organizationRequestBody,
      type: DashboardCampaignType.SUMMARY,
      limit: this.limit,
      offset: this.comparatorCampaignTables[type].offset,
      statsType: type,
    });

    this.comparatorCampaignTables[type].setData(campaignStatistics.histogram, campaignStatistics.total);
  };

  @action
  public fetchComparisonAutomationTable = async (type: StatsType): Promise<void> => {
    if (!this.bothPlatformsEnabled) {
      this.comparatorAutomationTables[type].setData([], 0);
      return;
    }

    const { automationHistogramList } = await getAutomationsComparatorHistogramListUseCase.exec({
      ...this.organizationRequestBody,
      limit: this.limit,
      offset: this.comparatorAutomationTables[type].offset,
    });

    this.comparatorAutomationTables[type].setData(automationHistogramList.histogram, automationHistogramList.total);
  };

  /*
   * Automation
   * */

  @action
  public fetchAutomationHistogram = async (): Promise<void> => {
    const { automationComparatorHistogram } = await getAutomationsComparatorHistogramUseCase.exec({
      ...this.organizationRequestBody,
    });
    const { from, to } = this.requestDateRange;
    this.automationCtrChartData = this.parseComparatorHistogramToLineChartData(automationComparatorHistogram, 'automationDays', 'ctr', from, to);
  };

  /*
   * Project Rank
   **/

  @action
  public fetchProjectRank = async (): Promise<void> => {
    const { projectsRank } = await getComparatorProjectRankUseCase.exec({ ...this.organizationRequestBody });

    this.projectRankItems = projectsRank
      .map(projectRankItem => {
        const project = projectStore.projects.find(project => project.id === projectRankItem.project);
        if (!project) {
          return {
            ...projectRankItem,
            name: '',
            url: '',
          };
        }
        return {
          ...projectRankItem,
          name: project.name,
          url: project.siteUrl,
        };
      })
      .filter(project => project.name !== '');
  };

  @computed
  public get sortedProjectRank() {
    const projectSelect = Object.entries(ProjectRankOptionsType).find(selectOption => selectOption[1] === this.projectRankSelect)[0].toLowerCase();
    const itemKey = getItemKeyName(projectSelect);
    return this.projectRankItems.sort((a, b) => b[itemKey] - a[itemKey]);
  }
}

const getItemKeyName = (name: string): string => {
  switch (name) {
    case 'automation_ctr':
      return 'automationCTR';
    case 'campaign_ctr':
      return 'campaignCTR';
    case 'subscribed':
      return 'subscriptions';
    case 'campaigns_number':
      return 'sent';
    default:
      return name;
  }
};

export enum ProjectRankOptionsType {
  SUBSCRIBERS = 'Subscribers',
  SUBSCRIBED = 'Subscribed',
  CLICKED = 'Clicked',
  CAMPAIGNS_NUMBER = 'Number of campaigns',
  CAMPAIGN_CTR = 'Campaign CTR',
  AUTOMATION_CTR = 'Automation CTR',
}

class AutomationTables extends PropertyHandler {
  @observable public ctr: ComparatorTable = new ComparatorTable();
  @observable public clicked: ComparatorTable = new ComparatorTable();
  @observable public delivered: ComparatorTable = new ComparatorTable();

  @action
  public clearOffset() {
    this.ctr.offset = 0;
    this.clicked.offset = 0;
    this.delivered.offset = 0;
  }
}

class CampaignsTables extends PropertyHandler {
  @observable public ctr: ComparatorTable = new ComparatorTable();
  @observable public clicked: ComparatorTable = new ComparatorTable();
  @observable public campaignCount: ComparatorTable = new ComparatorTable();

  @action
  public clearOffset() {
    this.ctr.offset = 0;
    this.clicked.offset = 0;
    this.campaignCount.offset = 0;
  }
}

class SubscribersTables extends PropertyHandler {
  @observable public subscribed: ComparatorTable = new ComparatorTable();
  @observable public unsubscribed: ComparatorTable = new ComparatorTable();

  @action
  public clearOffset() {
    this.subscribed.offset = 0;
    this.unsubscribed.offset = 0;
  }

  private calculateToDate(from: Date, to: Date, limit: number): Date {
    const calculatedDate = dayjs(from).add(limit, 'days');
    return calculatedDate.isAfter(to) ? to : calculatedDate.toDate();
  }

  public fromData(data: IComparatorStatisticsDay[], type: 'subscribed' | 'unsubscribed', from: Date, to: Date, limit: number): void {
    const total = dayjs(to).diff(from, 'days') + 1;
    let fromTable = dayjs(from).add(this[type].offset, 'days').toDate();
    let toTable = this.calculateToDate(fromTable, to, limit);
    const fullData = this.addMissingDates(data, fromTable, toTable);

    this[type].setData(fullData, total);
  }

  private addMissingDates(statsData: IComparatorStatisticsDay[], from: Date, to: Date): IComparatorStatisticsDay[] {
    let data = [].concat(statsData);

    for (let date = dayjs(from); date.isBefore(to); date = dayjs(date).add(1, 'days')) {
      let hasData = statsData.find(stats => stats.day === date.format('YYYY-MM-DD'));
      if (!hasData) {
        data = data.concat({ day: date.format('YYYY-MM-DD'), data: [] });
      }
    }
    return data;
  }
}
