import { ILineChartProps } from '../../../components/Charts/LineChart/LineChart';
import { fixedPercentValue } from '@ppg/common';

export const customLineChartPropertiesInPercent: Partial<ILineChartProps> = {
  axisLeft: {
    'format': function(value) {
      return fixedPercentValue(value);
    },
    'orient': 'left',
    'tickSize': 5,
    'tickPadding': 5,
    'tickRotation': 0,
    'legendOffset': -40,
    'legendPosition': 'center',
    'tickValues': 5
  },
  legends: [
    {
      'anchor': 'bottom-left',
      'direction': 'row',
      'justify': false,
      'translateX': 0,
      'translateY': 50,
      'itemsSpacing': 100,
      'itemDirection': 'left-to-right',
      'itemWidth': 130,
      'itemHeight': 15,
      'itemOpacity': 0.75,
      'symbolSize': 12,
      'symbolShape': 'circle',
      'symbolBorderColor': 'rgba(0, 0, 0, .5)',
    }
  ],
};

export const customGeneralLineChartProperties: Partial<ILineChartProps> = {
  legends: [
    {
      'anchor': 'bottom-left',
      'direction': 'row',
      'justify': false,
      'translateX': 0,
      'translateY': 50,
      'itemsSpacing': 100,
      'itemDirection': 'left-to-right',
      'itemWidth': 130,
      'itemHeight': 15,
      'itemOpacity': 0.75,
      'symbolSize': 12,
      'symbolShape': 'circle',
      'symbolBorderColor': 'rgba(0, 0, 0, .5)',
    }
  ],
};

export const customComparatorLineChartProperties: Partial<ILineChartProps> = {
  customMargin: {
    right: 160,
  },
  legends: [
    {
      'anchor': 'top-right',
      'direction': 'column',
      'justify': false,
      'translateX': 170,
      'translateY': 0,
      'itemsSpacing': 10,
      'itemDirection': 'left-to-right',
      'itemWidth': 160,
      'itemHeight': 15,
      'itemOpacity': 0.75,
      'symbolSize': 12,
      'symbolShape': 'circle',
      'symbolBorderColor': 'rgba(0, 0, 0, .5)',
    }
  ],
};

export const customComparatorLineChartPropertiesInPercent: Partial<ILineChartProps> = {
  customMargin: {
    right: 160,
  },
  axisLeft: {
    'format': function(value) {
      return fixedPercentValue(value);
    },
    'orient': 'left',
    'tickSize': 5,
    'tickPadding': 5,
    'tickRotation': 0,
    'legendOffset': -40,
    'legendPosition': 'center',
    'tickValues': 5
  },
  legends: [
    {
      'anchor': 'top-right',
      'direction': 'column',
      'justify': false,
      'translateX': 170,
      'translateY': 0,
      'itemsSpacing': 10,
      'itemDirection': 'left-to-right',
      'itemWidth': 160,
      'itemHeight': 15,
      'itemOpacity': 0.75,
      'symbolSize': 12,
      'symbolShape': 'circle',
      'symbolBorderColor': 'rgba(0, 0, 0, .5)',
    }
  ],
};
