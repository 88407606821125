import * as React from 'react';
import classNames from 'classnames';
import { NavBarLogo } from './NavBarLogo';
import { NavBarBalance } from './NavBarBalance';
import { t } from '../../base/helpers';
import { NavBarUser } from './NavBarUser';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from '../../stores/project/ProjectStore';
import AppLogo from '../../assets/images/logo.svg';
import AppLogoLite from '../../assets/images/ppglite-logo.svg';
import { Link } from 'react-router-dom';
import { PPGLite, PPGLiteRole } from '../PPGLite/PPGLite';
import './navBar.scss'
import { NavBarTrialDaysLeft } from './NavBarTrialDaysLeft';
import { PaymentsStore } from '../../stores/payment/PaymentsStore';
import { OfferStore } from '../../stores/OfferStore';
import { ProjectRoutesTypes } from '../../routes/moduleProjectRoutes';

interface INavBar {
  projectStore?: ProjectStore;
  paymentsStore?: PaymentsStore;
  offerStore?: OfferStore;
  hideNavigation: boolean;
  showShortLogo?: boolean;
  toggleNav?: () => void;
}

@inject('projectStore', 'paymentsStore', 'offerStore')
@observer
export class NavBar extends React.Component<INavBar, {}> {

  public async componentDidMount() {
    await this.props.paymentsStore.fetchAccountList();
    if(this.props.projectStore.currentProject){
      await this.props.offerStore.fetchCurrentAndNextPlans();
    }

    // todo: @basia rm next week
    if (!localStorage.getItem('ppg_changelogClicked')) {
      this.setAnimation();
    }
  }

  private showNavBarBalance = ():boolean => {
    return (this.props.projectStore.userOwnedProjects.length > 0 || this.props.paymentsStore.hasNegativeBalance) && window.innerWidth > 721;
  }

  private onClickHandler(event) {
    event.currentTarget.classList.remove('waviy');
    localStorage.setItem('ppg_changelogClicked', 'clicked');
  }

  private setAnimation() {
    const navFeedbackElement = document.querySelector('.navigation-feedback');
    navFeedbackElement ? navFeedbackElement.classList.add('waviy') : null;
  }

  public render() {
    const { hideNavigation, toggleNav, showShortLogo, offerStore } = this.props;
    const isDeviceSmall = window.innerWidth < 721;

    return (
      <nav className={ classNames('navigation') }>

        <div className="navigation-top">

          <NavBarLogo hideNavigation={ hideNavigation }
                      isDeviceSmall={ isDeviceSmall }
                      showShortLogo={ showShortLogo }
                      toggleNav={ toggleNav }/>

          <div className="navigation-right">
            { this.showNavBarBalance() && <NavBarBalance/> }
            {!offerStore.isLoading && <NavBarTrialDaysLeft />}

            {
              isDeviceSmall
                ? showShortLogo
                ? null
                : <Link to={ ProjectRoutesTypes.HOME } className="logo-wrapper">
                  <PPGLite role={PPGLiteRole.HIDE} ><img alt="PushPushGo" src={ AppLogo }/> </PPGLite>
                  <PPGLite role={PPGLiteRole.DISPLAY} ><img className="m10t" alt="PushPushGo Lite" src={ AppLogoLite }/> </PPGLite>
                </Link>
                : <a className={ "navigation-feedback" } target="_blank" href="https://docs.pushpushgo.company/changelog" onClick={this.onClickHandler}>
                  <span className="icon-winner-cup m10r"/>
                  { t('What\'s new') }
                </a>
            }
            <NavBarUser/>

          </div>
        </div>

      </nav>
    );
  }
}
