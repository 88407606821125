import { API, UseCase } from '@ppg/common';
import { CampaignState, IPushCampaign } from '../../../modelsMobx/PushCampaign';
import { Image } from '../../../modelsMobx/Image';

interface IListCampaigns {
  projectID: string;
  limit: number;
  offset: number;
  search: string;
  sort: string;
  state: string;
}

interface IListCampaignsResponse {
  metadata: {
    total: number;
  },
  data: ICampaignResponse[];
}

interface IStatisticsResponse {
  campaigns: {
    campaignId: string;
    sent: number;
    clicked: number;
    delivered: number;
    actionPrimary: number;
    actionSecondary: number;
    lost: number;
    ctr: number;
    cappedSent: number;
    cappedDelivered: number;
  }[];
}

interface ICampaignResponse {
  id: string;
  campaignType: string;
  title: string;
  content: string;
  redirectLink: string;
  state: CampaignState;
  sendDate: string;
  expireDate: string;
  sentBy: string;
  icon: Image;
}

interface IListPushCampaignResult {
  data: IPushCampaign[];
  metadata: {
    total: number;
  };
}

export class ListPushCampaignsUseCase extends UseCase<IListCampaigns, IListPushCampaignResult> {
  protected async execute({ projectID, limit, search, offset, sort, state }) {
    const campaignResponse: IListCampaignsResponse = await API.HTTP.post<IListCampaignsResponse>(`core/projects/${ projectID }/pushes?offset=${ offset }&limit=${ limit }`,
      { search, state, sort });
    const statisticsResponse: IStatisticsResponse = await API.HTTP.post<any>(`statistics/projects/${ projectID }/pushes/bulk/summary`, {
      campaigns: campaignResponse.data.map((campaign) => campaign.id)
    });

    const campaigns = campaignResponse.data.map((campaign) => {
      const campaignStats = statisticsResponse.campaigns.find((c) => c.campaignId === campaign.id);
      return { ...campaign, ...campaignStats };
    });

    return { data: campaigns, metadata: campaignResponse.metadata };
  }
}

export const listPushCampaignsUseCase = new ListPushCampaignsUseCase();

