import * as React from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { t } from '../../base/helpers';
import { PaymentsStore } from '../../stores/payment/PaymentsStore';
import {PaymentsRoutesTypes} from '../../routes/paymentsRoutes';

interface INavBarAccountBalanceProps {
  paymentsStore?: PaymentsStore;
}

@inject('paymentsStore')
@observer
export class NavBarBalance extends React.Component<INavBarAccountBalanceProps> {
  private renderBalance() {
    const { paymentsStore } = this.props;

    const balanceDetails = paymentsStore.accountDetails && paymentsStore.accountDetails[0];

    return paymentsStore.hasMoreThanOneAccount
      ? paymentsStore.hasNegativeBalance
        ? <Link className={ classNames("navigation-balance size16", { "text-error": paymentsStore.hasNegativeBalance }) } to={ PaymentsRoutesTypes.INVOICES_LIST }>
          <span><span className='icon-wallet m5r'/>{ t('You have unpaid invoices') }</span>
        </Link>
        : null
      : <Link className={ classNames("navigation-balance size20", { "text-error": paymentsStore.hasNegativeBalance }) } to={PaymentsRoutesTypes.INVOICES_LIST}>
        <span>{ balanceDetails && balanceDetails.balance && (balanceDetails.balance / 100).toFixed(2) }&nbsp;</span>
        <span>{ balanceDetails && balanceDetails.currency }</span>
      </Link>
  }

  // @todo handle only shared projects
  public render() {
    return (
      <span className="payment-balance-wrapper">
        { this.renderBalance() }
      </span>
    );
  }
}
