import { API, UseCase } from "@ppg/common";

interface SectorsList {
  originalSectors: string[];
}

export class GetSectorsList extends UseCase<null, SectorsList> {
  protected async execute(): Promise<SectorsList> {
    return API.HTTP.get<SectorsList>(`csas/projects/original-sectors`);
  }
}

export const getSectorsList = new GetSectorsList();
