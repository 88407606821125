import { API, UseCase } from '@ppg/common';
import { getListQueryParam, getPlatformTypeParam, getRangeDateParam } from '../../../helpers';
import { IProjectDashboardRequestBody } from '../../../interfaces';
import { IListQueryOptions } from '../../../../interfaces/IListQueryOptions';

interface IGetProjectCampaignStatsHistogramRequest extends IProjectDashboardRequestBody, IListQueryOptions {
  campaignType?: string;
}

export interface IStatisticsHistogramItem {
  day: string;
  clicked: number;
  delivered: number;
  sent: number;
  ctr: number;
  dsp: number;
  campaignCount: number;
  averageCtr?: number;
  averageDsp?: number;
}

interface IGetProjectCampaignStatsHistogramResult {
  dashboardCampaignStatisticsHistogram: {
    total: number,
    histogram: IStatisticsHistogramItem[]
  };
}

export class GetProjectCampaignStatsHistogramUseCase extends UseCase<IGetProjectCampaignStatsHistogramRequest, IGetProjectCampaignStatsHistogramResult> {
  protected async execute({
                            platform,
                            from,
                            to,
                            limit,
                            offset,
                            timezone,
                            campaignType = 'all',
                          }: IGetProjectCampaignStatsHistogramRequest) {

    return API.HTTP.get<IGetProjectCampaignStatsHistogramResult>(`statistics/v2/projects/${ API.HTTP.currentProjectId }/campaigns/dashboard/statistics/${ campaignType }/histogram${ getPlatformTypeParam(platform) }${ getRangeDateParam(from, to) }${ getListQueryParam(offset, limit) }&timezone=${ timezone }`);
  };
}

export const getProjectCampaignStatsHistogramUseCase = new GetProjectCampaignStatsHistogramUseCase();
