import { property } from "@ppg/common";
import { WebsiteIntegrationRelated } from '../../stores/project/WebsiteIntegrationRelated';
import { updateFallbackUseCase } from '../../useCases/website-integration';

export interface IFallbackNotification {
  title: string;
  content: string;
}

export class FallbackNotification extends WebsiteIntegrationRelated implements IFallbackNotification {
  @property() readonly title: string;
  @property() readonly content: string;

  constructor(fallbackNotification: IFallbackNotification) {
    super();
    this.title = fallbackNotification.title;
    this.content = fallbackNotification.content;
  }

  static createPlugin(fallbackNotification?: IFallbackNotification) {
    if (!fallbackNotification) {
      return FallbackNotification.createDefault();
    } else {
      return new FallbackNotification(fallbackNotification);
    }
  }

  static createDefault() {
    return new FallbackNotification({
      content: '',
      title: ''
    })
  }

  public async save(): Promise<void> {
    await updateFallbackUseCase.exec({
      websiteIntegrationId: this.websiteIntegrationId,
      fallback: this.toSave()
    })
  }

  public toSave(): IFallbackNotification {
    return {
      title: this.title,
      content: this.content,
    }
  }
}
