import * as React from 'react';

interface IPageSectionProps {
  children: JSX.Element | JSX.Element[];
}

export function PageSection({ children }: IPageSectionProps) {
  return (
    <div className="well">
      { children }
    </div>
  );
}
