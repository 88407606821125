import * as React from 'react';
import { debounce } from '@ppg/common';
import { ITimePickerProps } from './ITimePickerProps';
import dayjs from 'dayjs';

export class TimePicker extends React.Component<ITimePickerProps> {

  componentDidMount() {
    this.setState({
      minutes: dayjs(this.props.date).get('minute'),
      hours: dayjs(this.props.date).get('hour')
    })
  }

  componentWillReceiveProps(nextProps: Readonly<ITimePickerProps>) {
    this.setState({
      minutes: dayjs(nextProps.date).get('minute'),
      hours: dayjs(nextProps.date).get('hour')
    })
  }

  public state = {
    minutes: 0,
    hours: 0,
  };

  public render() {
    const { disabled, calculatedMax, calculatedMin } = this.props;
    return (
      <div>
        <div className="time-picker">
          <div className="input-wrapper">
            <input type="number"
                   min={ calculatedMin.hours }
                   max={ calculatedMax.hours }
                   value={ this.state.hours }
                   disabled={ disabled || false }
                   onChange={ e => this.handleHoursChange(e) }/>
          </div>
          :
          <div className="input-wrapper">
            <input type="number"
                   min={ calculatedMin.minutes }
                   max={ calculatedMax.minutes }
                   value={ this.state.minutes }
                   disabled={ disabled || false }
                   onChange={ e => this.handleMinutesChange(e) }/>
          </div>
        </div>
      </div>
    );
  }

  private handleHoursChange(event: React.ChangeEvent<HTMLInputElement>) {
    const hours = Number(event.currentTarget.value);
    this.setState({ hours });
    this.debouncedHours(hours);
  }

  private handleMinutesChange(event: React.ChangeEvent<HTMLInputElement>) {
    const minutes = Number(event.currentTarget.value);
    this.setState({ minutes });
    this.debouncedMinutes(minutes);
  }

  private debouncedHours = debounce(function (hours: number) {
    this.props.setNewDate(dayjs(this.props.date).hour(hours));
  }, 400);

  private debouncedMinutes = debounce(function (minutes: number) {
    this.props.setNewDate(dayjs(this.props.date).minute(minutes));
  }, 400);

}
