import { API, UseCase } from "@ppg/common";

interface IReadMetaDataCommand {
  url: string;
  trim?: boolean;
}

export interface IReadMetaData {
  'image': string;
  'description': string;
  'title': string;
  'url': string;
}

export class ReadMetaDataUseCase extends UseCase<IReadMetaDataCommand, IReadMetaData> {
  protected async execute({ url, trim }: IReadMetaDataCommand) {
    let parsedUrl;

    const dummy = Promise.resolve({
      image: "",
      description: "",
      title: "",
      url: "",
    });

    try {
      parsedUrl = new URL(url);

      if (!parsedUrl.protocol.includes("http")) {
        return dummy;
      }

      return API.HTTP.post<IReadMetaData>(`core/projects/${ API.HTTP.currentProjectId }/pushes/metadata`, { url, trim });
    } catch(error) {
      return dummy;
    }
  }
}

export const readMetaDataUseCase = new ReadMetaDataUseCase();
