import { API, UseCase } from '@ppg/common';
import { getRangeDateParam, getTimezoneParam } from '../../../helpers';
import { IGetSubscribersStructureResult } from '../../../interfaces';

interface IGetProjectSubscribersStructureRequest {
  from: string;
  to: string;
  timezone: string;
}

export class GetProjectSubscribersStructureUseCase extends UseCase<IGetProjectSubscribersStructureRequest, IGetSubscribersStructureResult> {
  protected async execute({ from, to, timezone }: IGetProjectSubscribersStructureRequest) {
    const endpoint = `statistics/v2/projects/${ API.HTTP.currentProjectId }/subscribers/dashboard/structure`
      + getRangeDateParam(from, to) + getTimezoneParam(timezone);

    return API.HTTP.get<IGetSubscribersStructureResult>(endpoint);
  }
}

export const getProjectSubscribersStructureUseCase = new GetProjectSubscribersStructureUseCase();
