import { API, UseCase } from "@ppg/common";
import { ICampaignId, ICampaignIdResponse } from '../interfaces';

interface ICopyRocketPushRequest extends ICampaignId {}
interface ICopyRocketPushResponse extends ICampaignIdResponse {}

export class CopyRocketPushUseCase extends UseCase<ICopyRocketPushRequest, ICopyRocketPushResponse> {
  protected async execute({ campaignId }: ICopyRocketPushRequest) {
    return API.HTTP.post<ICopyRocketPushResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/rocket/${ campaignId }/copy`);
  }
}

export const copyRocketPushUseCase = new CopyRocketPushUseCase();
