import { API, UseCase } from "@ppg/common";
import { IPushCampaignResource } from "../../../models/IPushCampaign";

interface IGetPushCampaignCommand {
  projectId: string;
  pushId: string;
}

interface ICampaignSummaryDTO {
  campaignId: string;
  sent: number;
  actionPrimary: number;
  actionSecondary: number;
  lost: number;
  clicked: number;
  delivered: number;
  ctr: number;
}

interface IGetPushCampaignResult {
  campaignResponse: IPushCampaignResource;
  statisticsResponse: ICampaignSummaryDTO;
}

export class GetPushCampaignUseCase extends UseCase<IGetPushCampaignCommand, IGetPushCampaignResult> {
  protected async execute({ projectId, pushId }: IGetPushCampaignCommand) {
    const campaignResponse = await API.HTTP.get<IPushCampaignResource>(`core/projects/${ projectId }/pushes/${ pushId }`);
    const statisticsResponse = await API.HTTP.get<ICampaignSummaryDTO>(`statistics/projects/${ projectId }/pushes/${ pushId }/summary`);

    return { campaignResponse, statisticsResponse }
  }
}

export const getPushCampaignUseCase = new GetPushCampaignUseCase();

