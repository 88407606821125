import { property, PropertyHandler } from '@ppg/common';
import { IProviderAction } from '../../useCases/core/providers/GetProjectProviderUseCase';
import { action, computed, observable } from 'mobx';

interface IHMSProvider {
  appId: string;
  authUrl: string;
  pushUrl: string;
  appSecret: string;

  actions: IProviderAction[];
}

export class HMSProvider extends PropertyHandler implements IHMSProvider {
  @property() public appId;
  @property() public authUrl;
  @property() public pushUrl;
  @property() public appSecret;

  @observable public actions;
  @observable public isAppSecretUnmasked: boolean = false;

  constructor({ authUrl, pushUrl, appSecret, appId, actions }: IHMSProvider) {
    super();
    this.appSecret = appSecret;
    this.authUrl = authUrl;
    this.pushUrl = pushUrl;
    this.appId = appId;
    this.actions = actions;
  }

  static createProvider(HMSProviderDTO?: IHMSProvider): HMSProvider {
    if (HMSProviderDTO) {
      return new HMSProvider(HMSProviderDTO);
    } else {
      return HMSProvider.createDefault();
    }
  }

  @computed
  public get isIntegrated(): boolean {
    return !this.actions.includes(IProviderAction.CREATE);
  }

  @computed
  public get canRequestPassword(): boolean {
    return this.actions.includes(IProviderAction.GET) || this.actions.includes(IProviderAction.CREATE);
  }

  @action
  public setAppSecret = (secret: string): void => {
    this.appSecret = secret;
  };

  @computed
  public get hasUnmaskedAppSecret(): boolean {
    return this.isAppSecretUnmasked;
  }

  static createDefault(): HMSProvider {
    return new HMSProvider({
      appId: '',
      appSecret: '',
      authUrl: '',
      pushUrl: '',
      actions: []
    });
  }
}
