import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { t } from '../../../../base/helpers';
import { SettingsDeleteUserModal } from './SettingsDeleteUserModal';
import { UserStore } from '../../../../stores/user/UserStore';
import { PaymentsStore } from '../../../../stores/payment/PaymentsStore';
import { NegativeBalanceModal } from './NegativeBalanceModal';

interface IDeleteAccount {
  userStore?: UserStore;
  paymentsStore?: PaymentsStore;
}

@inject('userStore', 'paymentsStore')
@observer
export class SettingsDeleteUser extends React.Component<IDeleteAccount, {}> {
  @observable private isOpenedAccountDeleteModal: boolean = false;
  @observable private isOpenedNegativeBalanceModal: boolean = false;

  private async confirmDeleteUser() {
    const { userStore } = this.props;
    userStore.settings.firstStepAccountDelete()
      .then(() => {
        this.closeUserDeleteModal();
        userStore.logoutCleanData();
      })
      .catch(error => console.error(error.message));
  }

  private openUserDeleteModal() {
    if (this.props.paymentsStore.hasNegativeBalance) {
      this.isOpenedNegativeBalanceModal = true;
      return;
    }

    this.isOpenedAccountDeleteModal = true;
  }

  private closeUserDeleteModal() {
    this.isOpenedAccountDeleteModal = false;
  }

  private closeNegativeBalanceModal() {
    this.isOpenedNegativeBalanceModal = false;
  }

  public render() {

    return (
      <>
        <div className='delete-account-wrapper'>
          <h4 className='form-title'>{ t('Delete Account') }</h4>
            <button
              className='button button--error text-left'
              onClick={ () => this.openUserDeleteModal() }
            >{ t('Delete') }
            </button>
        </div>

        <NegativeBalanceModal
          onCancel={ () => this.closeNegativeBalanceModal() }
          show={ this.isOpenedNegativeBalanceModal }
        />

        <SettingsDeleteUserModal
          onCancel={ () => this.closeUserDeleteModal() }
          onSave={ () => this.confirmDeleteUser() }
          show={ this.isOpenedAccountDeleteModal }/>
      </>
    )
  }
}
