import { Model } from '../base';
import { property } from '../base/decorators';
import { ISystemField, ISystemFieldResource } from './ISystemField';
import { t } from '../base/helpers';
//@ts-ignore
export class SystemField extends Model<ISystemField, ISystemFieldResource> implements ISystemField {
  @property()
  public name: string;

  @property()
  public prettyName: string;

  @property()
  public type: string;

  @property()
  public __source: string = '';

  @property()
  public autocomplete?: boolean;

  public get fieldIdentity() {
    return `${this.__source}:${this.name}:${this.type}`
  }

  public static defaultAutomationTags() {
    return new SystemField({
      name: 'tags',
      prettyName: t('Subscriber has tag'),
      type: 'array',
      autocomplete: true
    });
  }

  public static defaultAutomationWeekDay() {
    const field = new SystemField({
      name: 'weekDay',
      prettyName: t('Day of week 1 - 7'),
      type: 'number',
    });

    field.set('__source', 'predefined');

    return field;
  }
}
