import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Login } from '../pages/Authorization/Login';
import { Register } from '../pages/Authorization/Register';
import { ResetPassword } from '../pages/Authorization/ResetPassword';
import { ChangePassword } from '../pages/Authorization/ChangePassword';
import { RegisterLite } from '../pages/Authorization/RegisterLite';
import { LoginOnet } from '../pages/Authorization/LoginOnet';
import { LoginOnetConfirm } from '../pages/Authorization/LoginOnetConfirm';
import { LoginLite } from '../pages/Authorization/LoginLite';
import { ProjectRoutesTypes } from './moduleProjectRoutes';

export const authorizationRoutes = [
  { exact: true, path: '/login/oauth/ring', component: LoginOnet },
  { exact: true, path: '/login/oauth/ring/confirm', component: LoginOnetConfirm },
  { path: '/login', component: Login },
  { exact: true, path: '/register', component: Register },
  { exact: true, path: '/lite/login', component: LoginLite },
  { exact: true, path: '/lite/register/:offerPlan?', component: RegisterLite },
  { path: '/reset-password/change/:token', component: ChangePassword },
  { path: '/reset-password', component: ResetPassword },

  { component: (props) => <Redirect to={ { pathname: '/login', search: props.location.pathname !== ProjectRoutesTypes.HOME ? `?redirect=${ props.location.pathname }` : '' } }/> },
];
