import { action, observable } from 'mobx';
import { getCappingLimitsAndActualValue, ICapping } from '../useCases/accounting';
import { projectStore } from './index';
import { CappingPlanType } from '../modelsMobx/Offer';
import { UserRelatedStore } from './user/UserRelatedStore';

export class CappingStore extends UserRelatedStore {
  @observable public cappings: ICapping[] = [];
  @observable public currentProjectCapping: ICapping = null;

  @action
  public fetchCappings = async (): Promise<void> => {
    if (!this.userStore.user.organization) return;

    this.cappings = await getCappingLimitsAndActualValue.exec({
      organization: this.userStore.user.organization,
      project: projectStore.currentProjectID,
    });
  };

  public isOrganizationCapping(type: CappingPlanType): boolean {
    return [
      CappingPlanType.PROJECT
    ].includes(type);
  }

  public getCappingByType = (type: CappingPlanType): ICapping | null => {
    const capping = this.cappings
      .filter((item) => {
        if (this.isOrganizationCapping(type)) {
          return item.project === null;
        }
        return item.project === projectStore.currentProjectID;
      })
      .find((item) => {
        return item.type === type;
      });

    if (!capping) {
      return null;
    }

    return capping;
  };

  @action
  public isCampaignLimitReached = (): boolean => {
    const campaignCapping = this.getCappingByType(CappingPlanType.CAMPAIGN);

    if (!campaignCapping) {
      return false;
    }

    return campaignCapping.actual >= campaignCapping.limit;
  };
}
