import { UseCase } from '@ppg/common';
import { getActiveSubscribersComparatorUseCase, getAutomationsComparatorStatsUseCase, getCampaignsComparatorStatisticsUseCase, getSubscribedComparatorUseCase } from '../index';
import { ICampaignComparatorStats, IComparatorProjectStatsItem } from './GetCampaignsComparatorStatisticsUseCase';
import { IProjectsActivityItem, ISubscribersActivity } from '../subscribers/GetSubscribedComparatorUseCase';
import { IActiveSubscribers, IProjectActiveSubscribers } from '../subscribers/GetActiveSubscribersComparatorUseCase';
import { DashboardCampaignType } from '../../../../pages/Dashboard/common/enums';
import { IOrganizationDashboardRequestBody } from '../../../interfaces';

export interface IProjectRankItem {
  project: string,
  subscribers: number,
  subscriptions: number,
  sent: number,
  clicked: number,
  campaignCTR: number,
  automationCTR: number
}

export interface IGetCampaignsComparatorStatisticsResult {
  projectsRank: IProjectRankItem[];
}

interface IComparatorStats {
  campaignComparatorStats: ICampaignComparatorStats;
  automationStats: ICampaignComparatorStats;
  subscribersActivity: ISubscribersActivity;
  activeSubscribers: IActiveSubscribers;
}

type ProjectStatsType = IComparatorProjectStatsItem[] | IProjectActiveSubscribers[] | IProjectsActivityItem[]

interface IComparatorCampaignType {
  triggeredCampaignIds: string[],
  campaignIds: string[]
}

const campaignComparatorUpdateValues = (projectItem: IComparatorProjectStatsItem) => {
  return {
    project: projectItem.project,
    sent: projectItem.campaignCount,
    clicked: projectItem.clicked,
    campaignCTR: projectItem.ctr,
  };
};

const triggeredCampaignComparatorUpdateValues = (projectItem: IComparatorProjectStatsItem) => {
  return {
    automationCTR: projectItem.ctr,
  };
};

const activeSubscribersUpdateValues = (projectItem: IProjectActiveSubscribers) => {
  return {
    subscribers: projectItem.activeSubscribers,
  };
};


const subscriberActivityUpdateValues = (projectItem: IProjectsActivityItem) => {
  return {
    subscriptions: projectItem.subscribed,
  };
};

const emptyProjectRank = () => {
  return {
    subscribers: 0,
    subscriptions: 0,
    sent: 0,
    clicked: 0,
    campaignCTR: 0,
    automationCTR: 0,
  };
};


export class GetComparatorProjectRankUseCase extends UseCase<IOrganizationDashboardRequestBody, IGetCampaignsComparatorStatisticsResult> {
  protected async execute(params: IOrganizationDashboardRequestBody) {

    const projectsRank: IProjectRankItem[] = [];

    const comparatorStats: IComparatorStats = {
      campaignComparatorStats: null,
      subscribersActivity: null,
      activeSubscribers: null,
      automationStats: null,
    };

    const comparatorCampaigns: IComparatorCampaignType = {
      triggeredCampaignIds: null,
      campaignIds: null,
    };

    /**
     * Sets the corresponding values in passed comparator to the values of the promise array result
     * @param result
     * @param comparatorObject
     */
    const parseResultToComparatorObject = (result: any[], comparatorObject: IComparatorStats | IComparatorCampaignType) => {
      for (let atr of result) {
        const [key, value] = Object.entries(atr)[0];
        comparatorObject[key] = value;
      }
    };

    /**
     * Responsible for merging given ProjectStatsType object into Project Rank array
     * @param projectStats
     * @param updateValues
     */
    const merge = (projectStats: ProjectStatsType, updateValues) => {
      projectStats.map(projectItem => {
        const projectRankItemIndex = projectsRank.findIndex(projectRankItem => projectRankItem.project === projectItem.project);
        if (projectRankItemIndex === -1) {
          return projectsRank.push({
            ...emptyProjectRank(),
            ...updateValues(projectItem),
            project: projectItem.project,
          });
        }
        projectsRank[projectRankItemIndex] = {
          ...projectsRank[projectRankItemIndex],
          ...updateValues(projectItem),
          project: projectItem.project,
        };

      });
    };

    let campaignsResult = [];
    let statsResult = [];

    const fetchStats = async () => {
      statsResult = await Promise.all([
        getAutomationsComparatorStatsUseCase.exec({ ...params }),
        getCampaignsComparatorStatisticsUseCase.exec({ ...params, type: DashboardCampaignType.SUMMARY }),
        getSubscribedComparatorUseCase.exec(params),
        getActiveSubscribersComparatorUseCase.exec(params)
      ]);
    };

    const initialize = async () => {
      parseResultToComparatorObject(campaignsResult, comparatorCampaigns);
      await fetchStats();
      parseResultToComparatorObject(statsResult, comparatorStats);

      merge(comparatorStats.activeSubscribers.activeSubscribers, activeSubscribersUpdateValues);
      merge(comparatorStats.campaignComparatorStats.projectStats, campaignComparatorUpdateValues);
      merge(comparatorStats.automationStats.projectStats, triggeredCampaignComparatorUpdateValues);
      merge(comparatorStats.subscribersActivity.projectsActivity, subscriberActivityUpdateValues);
    };

    await initialize();
    return {
      projectsRank
    };
  }
}

