import { API, localSettings, UseCase } from '@ppg/common';
import { Invoice } from '../../modelsMobx/Invoice';

interface IGetInvoicePDF {
  organization: string;
  invoice: Invoice;
  correction?: boolean;
}

export class GetInvoicePDFUseCase extends UseCase<IGetInvoicePDF, any> {
  protected async execute({ organization, invoice, correction }: IGetInvoicePDF) {
    const token = localSettings.getValue('token');

    const response = await fetch(`${ API.HTTP.getBasePath() }/acc/organizations/${ organization }/invoices/${ invoice.id }/pdf${ correction ? '?type=correction' : '' }`, {
      headers: {
        'x-token': token,
      }
    })

    if (!response.ok) {
      throw new Error('Cannot download PDF')
    }

    const numberCorrectionInvoice = response.headers.get('Content-disposition').split("filename=")[1];

    const data = await response.arrayBuffer()

    const file = new Blob([data], { type: 'application/pdf' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(file);
    link.download =  !correction ? `${invoice.number}.pdf` : numberCorrectionInvoice;
    link.click();
  }
}

export const getInvoicePDFUseCase = new GetInvoicePDFUseCase();
