import { API, UseCase } from '@ppg/common';

interface ChangeUserDetailResult {
  firstName: string;
  lastName: string;
  phone: string;
  language: string;
  onboardingFinish: boolean;
  user: string;
  authorizationStrategy: string;
}

export class GetUserDetailUseCase extends UseCase<null, ChangeUserDetailResult> {
  protected async execute(): Promise<ChangeUserDetailResult> {
    return API.HTTP.get('ah/user-details')
  }
}

export const getUserDetailUseCase = new GetUserDetailUseCase();

getUserDetailUseCase.disableGlobalErrorHandler();
