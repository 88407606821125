import { API, UseCase } from '@ppg/common';
import { IListQueryOptions } from '../../../interfaces/IListQueryOptions';
import { IListResponse } from '../../../interfaces/IListResponse';
import { ISubscriberNewDTO } from '../../../modelsMobx/subscriber/interfaces';

interface ListSubscribersRequest extends IListQueryOptions {
  customId: string;
  segment: string | null;
}

interface ListSubscribersResponse extends IListResponse<ISubscriberNewDTO[]> {}

export class ListSubscribersUseCase extends UseCase<ListSubscribersRequest, ListSubscribersResponse> {
  protected async execute({ offset, limit, customId, segment }: ListSubscribersRequest) {
    let endpoint = `core/projects/${ API.HTTP.currentProjectId }/subscribers/list?offset=${ offset }&limit=${ limit }&customId=${customId}`

    if (segment) {
      endpoint += `&segment=${segment}`;
    }

    return API.HTTP.get<ListSubscribersResponse>(endpoint);
  }
}

export const listSubscribersBySegmentUseCase = new ListSubscribersUseCase();
