import { API, UseCase } from "@ppg/common";
import { IVariantDTO } from './CreateABTestUseCase';
import { LabelCountStrategy } from '../../../modelsMobx/Label';
import { SenderStrategyType } from '../../../stores/PushSettingStore';

interface IUpdateABTest {
  abTestId: string;
  name: string;
  sample: number;
  variants: IVariantDTO[];
  excludedTags: string[];
  tags: string[];
  polygons: [Number, Number][][];
  senderStrategy: SenderStrategyType;
  excludedTagStrategy: LabelCountStrategy;
  tagStrategy: LabelCountStrategy;
  segment?: string;
  labels?: string[];
  labelsStrategy?: string;
  excludedLabels?: string[];
  excludedLabelsStrategy?: string;
}

export class UpdateABTestUseCase extends UseCase<IUpdateABTest, void> {
  protected async execute({ abTestId, name, sample, variants, excludedTagStrategy, tagStrategy, tags, excludedTags, polygons, senderStrategy }: IUpdateABTest) {
    return API.HTTP.put<void>(`core/projects/${ API.HTTP.currentProjectId }/ab-tests/${ abTestId }`, {
      name, sample, variants, excludedTags, tags, excludedTagStrategy, tagStrategy, polygons, senderStrategy
    });
  }
}

export const updateABTestUseCase = new UpdateABTestUseCase();
