import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { redirect, t } from '../../base/helpers';
import { ProjectStore } from '../../stores/project/ProjectStore';
import { inject, observer } from 'mobx-react';
import { LinkButton, SearchInput } from '@ppg/styled';
import { observable, reaction } from 'mobx';
import * as DummyScreenshot from '../../assets/images/dummy-screenshot.png';
import { SimpleProject } from '../../modelsMobx/project/SimpleProject';
import { PPGLite, PPGLiteRole } from '../PPGLite/PPGLite';
import { Project } from '../../models';
import { debounce } from 'lodash';
import { CappingStore } from '../../stores/CappingStore';
import { CappingPlanType } from '../../modelsMobx/Offer';
import { ProjectImageStore } from '../../stores/project/ProjectImageStore';
import { ImageFormatType } from '../../modelsMobx/Image';
import { ProjectRoutesTypes } from '../../routes/moduleProjectRoutes';

interface IProjectSelectorProps extends RouteComponentProps<{}, {}> {
  projectStore?: ProjectStore;
  cappingStore?: CappingStore;
  projectImageStore?: ProjectImageStore;
}

@inject('projectStore', 'cappingStore', 'projectImageStore')
@observer
export class ProjectSelector extends React.Component<IProjectSelectorProps, {}> {
  @observable isLoading: boolean = true;
  @observable searchValue = '';
  @observable showProjectSelector = false;

  componentDidMount() {
    const { cappingStore } = this.props;
    cappingStore.fetchCappings()
      .then(() => this.isLoading = false);
  }

  clearSearch = reaction(() => this.showProjectSelector, () => this.searchValue = '');

  toggleProjectSelector() {
    this.showProjectSelector = !this.showProjectSelector;
  }

  public render() {
    const { projectStore, cappingStore } = this.props;

    if (this.isLoading) {
      return null;
    }

    const canCreateNewProject = (): boolean => {
      const projectCreateCapping = cappingStore.getCappingByType(CappingPlanType.PROJECT);

      if (!projectCreateCapping) {
        return true;
      }

      return projectStore.projects.length <= projectCreateCapping.limit;
    };

    return (
      <PPGLite role={ PPGLiteRole.WRAP }>
        <div className="project-selector" id="projectSelector">
          { projectStore.currentProject ?
            <div>
              <div className={ `project-selector-profile ${ this.showProjectSelector ? 'profile-active' : '' }` }>
                <img src={ this.props.projectImageStore.getScreenshotURL(ImageFormatType.SMALL) } className="project-selector-avatar"
                     onError={ (e) => {
                       e.currentTarget.onerror = null;
                       e.currentTarget.src = DummyScreenshot;
                     } }/>

                <div className="project-selector-info">
                  <CurrentProject project={ projectStore.currentProject }/>
                </div>
              </div>

              { canCreateNewProject() && this.showProjectSelector && <div className="project-selector-dropdown">

                <div className="project-selector-panel">
                  <LinkButton path={ ProjectRoutesTypes.PROJECT_NEW }
                              content={ t('Create new project') }
                              size={ 'small' }
                              icon={ 'plus-badge' }/>
                  <SearchInput value={ this.searchValue }
                               dataCy={ 'project-selector--search' }
                               placeholder={ t('Search project') }
                               handleSearch={ this.handleSearch.bind(this) }/>
                </div>

                { this.renderProjectList() }

              </div> }
            </div>
            :
            <div className="project-selector-empty-project p20 text-center">
              { t("You don't have any project yet") }
              <PPGLite role={ PPGLiteRole.HIDE }>
                { canCreateNewProject() && <Link to={ ProjectRoutesTypes.PROJECT_NEW }
                                                 className="button button--small m10t"
                                                 onClick={ () => this.toggleProjectSelector() }> { t('Create project') }
                </Link> }
              </PPGLite>
            </div>
          }

          <PPGLite role={ PPGLiteRole.HIDE }>
            { canCreateNewProject() && projectStore.hasProjects &&
              <span className={ `project-selector-toggle ${ this.showProjectSelector ? 'selector-active' : '' }` } onClick={ () => this.toggleProjectSelector() }>
            <span className={ (this.showProjectSelector ? 'icon-arrow-top' : 'icon-arrow-down') + ' size15' }/>
          </span> }
          </PPGLite>

        </div>
      </PPGLite>
    );
  }

  private renderProjectList() {
    const isSearchActive = this.searchValue.length;
    const lowerCasedValue = this.searchValue.toLowerCase();

    const otherProjects = this.props.projectStore.projects.filter(p => p.id !== this.props.projectStore.currentProject.id);
    const anyProjectFound = otherProjects.some(project => project.name.toLowerCase().includes(lowerCasedValue));
    const projectsFound = otherProjects.filter(project => project.name.toLowerCase().includes(lowerCasedValue));

    if (this.props.projectStore.projects.length < 2) return <div className={ "p10 text-center" }>{ t("You don't have any other projects") }</div>;

    return <ul className="project-selector-list">
      {
        isSearchActive ?
          anyProjectFound
            ? projectsFound.map(project => <ProjectItem key={ project.id } project={ project } onClick={ () => this.debouncedSetProjectAsCurrent(project) }/>)
            : <p className="text-center p10">{ t('Sorry, no matches found') }</p>
          : otherProjects.map(project => <ProjectItem key={ project.id } project={ project } onClick={ () => this.debouncedSetProjectAsCurrent(project) }/>)
      }
    </ul>
  }

  private debouncedSetProjectAsCurrent = debounce(this.setProjectAsCurrent, 750);

  private async setProjectAsCurrent(project: SimpleProject): Promise<void> {
    await this.props.projectStore.setProject(project.id);
    await this.props.projectStore.websiteIntegrationStore.getWebsiteIntegrationId();
    this.toggleProjectSelector();
    redirect(ProjectRoutesTypes.HOME);
  }

  private handleSearch(event) {
    this.searchValue = event.target.value;
  }
}

const CurrentProject = ({ project }: { project: Project }) => {
  if (!project) return null;

  return (
    <span>
      <div className="project-title">{ project.get('name') }</div>
      <div className="subtitle">ID: { project.get('id') }</div>
      <div className="subtitle">{ project.get('siteUrl') }</div>
    </span>
  );
};

const ProjectItem = ({ project, onClick }: { project: SimpleProject, onClick: React.MouseEventHandler<HTMLElement> }) => {
  return (
    <li key={ project.id } onClick={ onClick } className="project-subitem">
      <div className="row m10l">
        <div className="p5l">
          <span>{ project.name }</span>
          <div className="subtitle">{ project.siteUrl }</div>
        </div>
      </div>
    </li>
  );
};

