import * as React from 'react';
import { observer } from 'mobx-react';
import { InvoiceDetailsAlert } from './InvoiceDetailsAlert';
import { InvoiceDetailsInfo } from './InvoiceDetailsInfo';
import { t } from '../../../../base/helpers';
import { InvoiceDetailsForms } from './InvoiceDetailsForms';
import { UserHeader } from '../../Layouts/UserLayout/UserHeader/UserLayoutHeader';

@observer
export class InvoiceDetails extends React.Component<{}, {}> {

  public render() {
    return <>
        <InvoiceDetailsAlert/>
        <InvoiceDetailsInfo/>
        <UserHeader title={ t('Company information') } subtitle={ t('We need your company info for issuing invoices. Fields marked with * are required.') } />
        <InvoiceDetailsForms />
      </>
  }
}
