import { IAutomationFlowStep } from './IAutomationFlowStep';
import { IMap } from '../../interfaces';
import { AutomationFlow } from './AutomationFlow';
import { AbstractAutomationStep } from './AbstractAutomationStep';

class AutomationStepFactory<T extends typeof AbstractAutomationStep> {
  constructor(private constructorMap: IMap<T>) {
  }

  public create(automationFlow: AutomationFlow, steps: IAutomationFlowStep<any>[]): AbstractAutomationStep<any>[] {
    return steps.map(step => this.createOne(automationFlow, step));
  }

  public createOne(automationFlow: AutomationFlow, step: IAutomationFlowStep<any>): AbstractAutomationStep<any> {
    return new (this.constructorMap as any)[step.type](automationFlow, step);
  }
}

/**
 * t('nextStepId')
 * t('onTrue')
 * t('onFalse')
 * t('onDeliver')
 * t('onClick')
 * t('onLost')
 */
class TriggerStep extends AbstractAutomationStep<any> {
  public resolvers = ['nextStepId'];
}

class DelayStep extends AbstractAutomationStep<any> {
  public resolvers = ['nextStepId'];
}

class UntilStep extends AbstractAutomationStep<any> {
  public resolvers = ['nextStepId'];
}

class SendPushStep extends AbstractAutomationStep<any> {
  public resolvers = ['nextStepId', 'onClick', 'onDeliver'];
}

class ConditionStep extends AbstractAutomationStep<any> {
  public resolvers = ['onTrue', 'onFalse'];
}
class TagStep extends AbstractAutomationStep<any> {
  public resolvers = ['nextStepId'];
}

class UpdateStep extends AbstractAutomationStep<any> {
  public resolvers = ['nextStepId'];
}

class GetStep extends AbstractAutomationStep<any> {
  public resolvers = ['nextStepId'];
}

class ABStep extends AbstractAutomationStep<any> {
  public resolvers = ['testA', 'testB'];
}

/** Translations
 * t('trigger')
 * t('delay')
 * t('sendPush')
 * t('sendSms')
 * t('condition')
 * t('tag')
 * t('update')
 * t('get')
 */
export const automationStepFactory = new AutomationStepFactory({
  trigger: TriggerStep,
  delay: DelayStep,
  until: UntilStep,
  sendPush: SendPushStep,
  condition: ConditionStep,
  tag: TagStep,
  update: UpdateStep,
  get: GetStep,
  ab: ABStep,
});
