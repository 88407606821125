import { apiManager } from '../../base';
import { isNotEmpty, property, localProperty } from '@ppg/common';
import { UserRelatedStore } from './UserRelatedStore';
import { generateConfirmationTokenForDeleteUseCase } from '../../useCases/auth-and-identity/GenerateConfirmationTokenForDeleteUseCase';
import { confirmAccountDeleteWithTokenUseCase } from '../../useCases/auth-and-identity/ConfirmAccountDeleteWithTokenUseCase';

export type gridType = 'tiles' | 'list';

export class SettingsStore extends UserRelatedStore {
  @property([isNotEmpty()])
  public userToDelete: string;

  @property()
  public errors: string[] = [];

  @localProperty(false) public infinity: boolean;
  @localProperty('list') public gridType: gridType;

  public async saveSettings(): Promise<void> {
    await this.userStore.changeUserDetail();
  }

  public async deleteUser() {
    return apiManager.del('user/delete', { userToDelete: this.userToDelete });
  }

  public async firstStepAccountDelete() {
    await generateConfirmationTokenForDeleteUseCase.exec({});
  }

  public async secondStepAccountDelete(token) {
    await confirmAccountDeleteWithTokenUseCase.exec({ token });
  }
}
