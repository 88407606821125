import * as React from 'react';
import { useEffect, useState } from 'react';
import { t } from '../../../../../base/helpers';
import { organizationDashboardStore } from '../../../../../stores';
import { observer } from 'mobx-react';
import { ChartCard, ChartTableLayout, EmptyComponentDashboardStatsType, customComparatorLineChartPropertiesInPercent } from '../../../common';
import { ChartType, IExternalLoaders } from '../../../common/ChartCard/__types__';
import { ActiveViewType } from '../../../../User/Navigations/UnderlinedNavigationBar/UnderlinedNavigationBar';
import { ProjectComparatorTable } from './ProjectComparatorTable';

export const ProjectComparisonAutomation = observer(() => {
  const { platformChangeFactors, rangeChangeFactors, projectComparisonStore, isPlatformSelected } = organizationDashboardStore;
  const [isLoading, setLoader] = useState<boolean>(true)
  const [initialLoader, setInitialLoader] = useState<boolean>(false);

  const campaignLoaders = (): IExternalLoaders => {
    return {
      isLoading,
      isInitialLoader: initialLoader
    }
  }

  const {
    automationCtrChartData,
    comparatorAutomationTables,
    fetchAutomationStatsWithHistogram,
    fetchComparisonAutomationTable,
    cleanAutomationData,
  } = projectComparisonStore;

  useEffect(() => {
    if (organizationDashboardStore.activeViewType === ActiveViewType.CHART) {
      setLoader(true)
      fetchAutomationStatsWithHistogram()
        .then(() => {
          setLoader(false)
          setInitialLoader(true);
        })
    }

  }, [platformChangeFactors, rangeChangeFactors])

  useEffect(() => {
    return cleanAutomationData;
  }, [])

  return <ChartTableLayout activeViewType={ organizationDashboardStore.activeViewType }
                           chart={ <ChartCard disablePlatformFetch
                                              size={ 'large' }
                                              platformChangeFactors={ platformChangeFactors }
                                              isPlatformSelected={ isPlatformSelected }
                                              rangeChangeFactors={ rangeChangeFactors }
                                              externalLoaders={ campaignLoaders() }
                                              data={ { line: automationCtrChartData } }
                                              chartType={ ChartType.LINE }
                                              customProperties={ { line: customComparatorLineChartPropertiesInPercent } }
                                              title={ t('organization/project-comparison::CTR') }
                                              valueInPercent/> }
                           table={ <ProjectComparatorTable title={ t('organization/project-comparison::CTR') }
                                                           percentage
                                                           emptyComponentHeight={ 505 }
                                                           emptyComponentType={ EmptyComponentDashboardStatsType.AUTOMATION }
                                                           comparatorTable={ comparatorAutomationTables.ctr }
                                                           fetchTableData={ () => fetchComparisonAutomationTable('ctr') }/> }/>
})
