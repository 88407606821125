import { API, UseCase } from "@ppg/common";
import { ISegmentConditionPlainObject } from './CreateSegmentUseCase';

interface IUpdateSegmentRequest {
  segment: string;
  name: string;
  conditions: ISegmentConditionPlainObject;
}

export class UpdateSegmentUseCase extends UseCase<IUpdateSegmentRequest, void> {
  protected async execute({segment, name, conditions}: IUpdateSegmentRequest) {
    return API.HTTP.put<void>(`core/projects/${API.HTTP.currentProjectId}/segment/${segment}`, {name, conditions})
  }
}

export const updateSegmentUseCase = new UpdateSegmentUseCase()
