import * as React from 'react';
import { Input, ShowPasswordButton } from '@ppg/styled';
import { t } from '../../base/helpers';
import { Link, RouteComponentProps } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { UserStore } from '../../stores/user/UserStore';
import { observable } from 'mobx';
import './Authorization.scss';
import { SaveButtonWrapped } from '../../assets/wrapped';

interface IChangePasswordProps extends RouteComponentProps<{ token: string }> {
  userStore?: UserStore;
}

@inject('userStore')
@observer
export class ChangePassword extends React.Component<IChangePasswordProps> {
  @observable isLoading: boolean = true;
  @observable isPasswordHidden: boolean = true;
  @observable isRepeatPasswordHidden: boolean = true;
  @observable allRulesMet: boolean = false;
  @observable isRequestSubmitted: boolean = false;

  public componentDidMount():void {
    this.props.userStore.password.checkChangePasswordTokenExpire(this.props.match.params.token)
      .finally(() => this.isLoading = false);
  }

  public componentWillUnmount():void {
    this.props.userStore.password.clear();
  }

  public render() {
    const { password } = this.props.userStore;

    if (this.isLoading) {
      return null;
    }

    return (
        <div className="change-password-page-wrapper" data-cy={'change-password--wrapper'}>
          {
            !this.isRequestSubmitted
              ? <>
                <h1 className="change-password-title title">{ t('Change password') }</h1>
                <div className="auth-form app-form-region">
                  <ul className='password-rules-list'>{ t('In order to protect your account, make sure your password:') }
                    <li><span className={ `${ password.isLongEnough ? 'icon-tick rule-fullfiled' : 'icon-ok-badge' } list-icon` }/>{ t('has at least 8 characters') }</li>
                    <li><span className={ `${ password.isNotSimple ? 'icon-tick rule-fullfiled' : 'icon-ok-badge' } list-icon` }/>{ t('doesn\'t contain sequence of 4 characters') }</li>
                    <li><span className={ `${ password.containsSpecialCharacter ? 'icon-tick rule-fullfiled' : 'icon-ok-badge' } list-icon` }/>{ t('has a special character') }</li>
                    <li><span className={ `${ password.containsUpperCase ? 'icon-tick rule-fullfiled' : 'icon-ok-badge' } list-icon` }/>{ t('contains uppercase character') }</li>
                    <li><span className={ `${ password.containsLowerCase ? 'icon-tick rule-fullfiled' : 'icon-ok-badge' } list-icon` }/>{ t('contains lowercase character') }</li>
                    <li><span className={ `${ password.containsNumber ? 'icon-tick rule-fullfiled' : 'icon-ok-badge' } list-icon` }/>{ t('contains number') }</li>
                    <li><span className={ `${ password.areSamePasswords ? 'icon-tick rule-fullfiled' : 'icon-ok-badge' } list-icon` }/>{ t('is the same in both fields') }</li>
                  </ul>

                  <p className="password-rules-feedback">{ password.allRulesMet && t('Good job! Your password has met all the rules.') }</p>

                  <div className="password-input-wrapper">
                    <Input
                      label={ t('New password') }
                      type={ this.isPasswordHidden ? 'password' : 'text' }
                      dataCy={ 'change-password-input--password' }
                      onKeyPress={ (event) => this.handleEnter(event) }
                      autoComplete = { 'new-password' }
                      property={ password.getProperty('password') }/>
                    <ShowPasswordButton hidePassword={ this.isPasswordHidden }
                                        onClickHandler={ this.toggleShowPassword.bind(this) }/>
                  </div>

                  <div className="password-input-wrapper">
                    <Input
                      label={ t('Repeat new password') }
                      autoComplete = { 'new-password' }
                      dataCy={'change-password-input--repeat-password'}
                      type={ this.isRepeatPasswordHidden ? 'password' : 'text' }
                      onKeyPress={ (event) => this.handleEnter(event) }
                      property={ password.getProperty('confirmationPassword') }/>
                    <ShowPasswordButton hidePassword={ this.isRepeatPasswordHidden }
                                        onClickHandler={ this.toggleShowRepeatPassword.bind(this) }/>
                  </div>

                  {
                    password.errors.length > 0 &&
                    <ul className="change-password-errors">
                      { [].concat(password.errors).map((error, index) => <li key={ index }>{ error }</li>) }
                    </ul>
                  }

                  <div className="bottom-actions">
                    <SaveButtonWrapped dataCy={'change-password-button--save'}
                                       disabled={ !password.allRulesMet }
                                       preventToast={ true }
                                       content={t('Set new password') }
                                       callback={ () => this.handleClickChangePassword() } />
                  </div>
                </div>
                <div className="switch-to-login">
                    <span className="app-back">
                      { t('Back to') }
                      <Link className='app-register-button' to="/login">{ t('Login') }</Link>
                    </span>
                </div>
              </>
              : <>
                <p className='password-feedback-message'>{ t('You have successfully reset your password.') }</p>
                <svg className="confirmation-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle className="confirmation-icon__circle" cx="26" cy="26" r="25" fill="none"/>
                  <path className="confirmation-icon__tick" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                </svg>
                <div className="switch-to-login">
                    <span className="app-back">
                      { t('Back to') }
                      <Link className='app-register-button' to="/login">{ t('Login') }</Link>
                    </span>
                </div>
              </>
          }
        </div>
    )
  }

  private async handleClickChangePassword() {
    const { token } = this.props.match.params;

    return this.props.userStore.password.secondStepPasswordReset(token)
      .then(() => this.isRequestSubmitted = true)
  }

  private toggleShowPassword(e) {
    e.preventDefault();
    this.isPasswordHidden = !this.isPasswordHidden;
  }

  private toggleShowRepeatPassword(e) {
    e.preventDefault();
    this.isRepeatPasswordHidden = !this.isRepeatPasswordHidden;
  }

  private async handleEnter(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.which === 13) {
      await this.handleClickChangePassword();
    }
  }
}
