import { UseCase, API } from '@ppg/common';
import { IGetPlugin, IPluginResult } from '../interfaces';

export class GetGeolocationPluginUseCase extends UseCase<IGetPlugin, IPluginResult> {
  protected async execute({ websiteIntegrationId }: IGetPlugin) {
    return API.HTTP.get<IPluginResult>(`wi/projects/${ API.HTTP.currentProjectId }/website-integrations/${ websiteIntegrationId }/plugins/geolocation`)
  }
}

export const getGeolocationPluginUseCase = new GetGeolocationPluginUseCase();
