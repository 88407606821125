import { API, UseCase } from '@ppg/common';
import { ICampaignId, ICampaignIdResponse } from '../interfaces';

interface ICancelGeolocationPushUseCaseRequest extends ICampaignId {}
interface ICancelGeolocationPushUseCaseResponse extends ICampaignIdResponse {}

export class CancelGeolocationPushUseCase extends UseCase<ICancelGeolocationPushUseCaseRequest, ICancelGeolocationPushUseCaseResponse> {
  protected async execute({ campaignId }: ICancelGeolocationPushUseCaseRequest) {
    return API.HTTP.post<ICancelGeolocationPushUseCaseResponse>(`core/projects/${API.HTTP.currentProjectId}/pushes/geolocation/${campaignId}/cancel`);
  }
}

export const cancelGeolocationPushUseCase = new CancelGeolocationPushUseCase();
