import { DynamicField, DynamicFieldTypeTypes } from './helpers/DynamicField';
import { action, reaction, set } from 'mobx';
import { property, PropertyHandler } from '@ppg/common';

const slug = require('slug');

export type SimpleType = 'string' | 'date' | 'boolean' | 'number';

export class CustomField extends PropertyHandler {

  @property() public prettyName: string;
  @property() public name: string;
  @property() public id?: string;
  @property() public type: DynamicFieldTypeTypes;

  @property() private editMode: boolean;
  @property() private deleteMode: boolean;
  private initialValues;

  /**
   * Automation fields why here? Should be other representation of "condition component field"
   */
  @property() public __source: string;

  static DATE_FORMAT = /^(\d{4})-(\d{2})-(\d{2})(|T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?))$/;

  public get fieldIdentity() {
    return `${ this.__source }:${ this.name }:${ this.type }`;
  }

  public static guessType(input: string): SimpleType {
    const inputValue = `${input}`

    if (inputValue === 'true' || inputValue === 'false') {
      return 'boolean';
    }

    const valueWithReplaceCommaToDot = inputValue.replace(/,/g, '.');
    if (!isNaN(Number(valueWithReplaceCommaToDot))) {
      return 'number';
    }

    const valueWithoutSpecialCharacters = CustomField.DATE_FORMAT.test(inputValue);
    if (valueWithoutSpecialCharacters && new Date(inputValue).toString() !== 'Invalid Date') {
      return 'date';
    }

    return 'string';
  }

  constructor(customFieldDTO) {
    super();
    this.id = customFieldDTO.id;
    this.prettyName = customFieldDTO.name;
    this.type = customFieldDTO.valueType;
    this.__source = 'customField';
    this.name = customFieldDTO.name;

    this.initialValues = { ...this };
    reaction(() => this.prettyName, () => this.name = this.slug);
  }

  @action
  setEditMode(): void {
    this.editMode = true;
  }

  @action
  unsetEditMode(): void {
    this.editMode = false;
  }

  @action
  setDeleteMode(): void {
    this.deleteMode = true;
  }

  @action
  unsetDeleteMode(): void {
    this.deleteMode = false;
  }

  @action
  restore() {
    set(this, this.initialValues);
  }

  get slug() {
    return slug(this.prettyName, { lower: false, replacement: '_' });
  }

  get isNew(): boolean {
    return !this.id;
  }

  get isEdited(): boolean {
    return this.editMode;
  }

  get isInDeleteMode(): boolean {
    return this.deleteMode;
  }

  public get sample() {
    return DynamicField.getSampleValueByType(this.type);
  }

  public onSave() {
    this.unsetEditMode();
    this.initialValues = { ...this };
  }

  public toScope() {
    return { [this.name]: this.sample };
  }
}
