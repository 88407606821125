import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { redirect, t } from '../../../../base/helpers';
import { ProjectStore } from '../../../../stores/project/ProjectStore';
import { projectStore } from '../../../../stores';
import { SimpleProject } from '../../../../modelsMobx/project/SimpleProject';
import { ProjectRoutesTypes } from '../../../../routes/moduleProjectRoutes';
import { observable } from 'mobx';
import { Button, ButtonTypes, Modal } from '@ppg/styled';
import "./start.scss";

interface IProjectListItemProps {
  projectStore?: ProjectStore;
  project: SimpleProject;
  canDelete: boolean;
}

@inject('projectStore')
@observer
export class ProjectListItem extends React.Component<IProjectListItemProps, {}> {

  @observable private deleteProjectModalIsOpen: boolean = false;

  private selectProject(project: SimpleProject) {
    projectStore.setProject(project.id)
      .then(() => projectStore.websiteIntegrationStore.getWebsiteIntegrationId())
      .then(() => redirect(ProjectRoutesTypes.DASHBOARD));
  }

  private onDeleteProjectConfirmHandler(): void {
    projectStore.deleteProject(projectStore.projectToDelete.id)
      .then(() => this.closeDeleteProjectModal());
  }

  private closeDeleteProjectModal(): void {
    projectStore.clearProjectToDelete();
    this.deleteProjectModalIsOpen = false;
  }

  private selectProjectToDelete(project: SimpleProject) {
    projectStore.setProjectToDelete(project);
    this.deleteProjectModalIsOpen = true;
  }

  public render() {
    const { project, canDelete } = this.props;

    return (
      <>
        <tr>
          <td data-label={ t('Name') }>
            <span className={ 'size16 primary acts-as-link p5b' } onClick={ () => this.selectProject(project) }> { project.name } </span>
          </td>
          <td data-label={ t('Website address') }> { project.siteUrl }</td>
          <td data-label={ t('Project Id') }>{ project.id } </td>
          <td>
            { canDelete && <Button icon="trash" size="small" type={ ButtonTypes.DEFAULT } isSquare onClick={ () => this.selectProjectToDelete(project) } dataTip={ t('Delete project') }/> }
          </td>
        </tr>
        <Modal
          isConfirmModal
          title={ t('Are you sure') }
          content={ <span> { t('Do you want do delete project: ') } <b>{ projectStore.projectToDelete && projectStore.projectToDelete.name }</b>?</span> }
          onCancel={ () => this.closeDeleteProjectModal() }
          onConfirm={ () => this.onDeleteProjectConfirmHandler() }
          show={ this.deleteProjectModalIsOpen }
          cancelButton={ t('Cancel') }
          confirmButton={ t('Yes') }
        />
      </>
    );
  }
}
