import * as lodash from 'lodash';
import { property } from '../../base/decorators';
import { Model } from '../../base';
import { t } from '../../base/helpers';
import { v4 } from 'uuid';

import {
  IConditionOperatorType,
  ICompareOperatorType,
  ISingleConditionItem,
  IConditionValueType,
  IConditionValueTypeType,
  IConditionValueSubTypeType,
  Condition
} from './index';
import { Selector } from "../../modelsMobx/selector/Selector";
import { CustomField } from "../../modelsMobx/CustomField";
import { SystemField } from "../SystemField";
import { ExternalField } from "../ExternalField";
import { ISelectOption } from "@ppg/styled";

export const conditionConfig = {
  operators: [
    { name: t('OR'), value: 'or' },
    { name: t('AND'), value: 'and' }
  ],
  comparatorsMap: {
    boolean: [
      { name: t('isEqual'), value: 'eq' },
    ],
    string: [
      { name: t('isEqual'), value: 'eq' },
      { name: t('isNotEqual'), value: 'neq' },
      { name: t('contains'), value: 'contains' },
      { name: t('notContains'), value: 'ncontains' },
    ],
    array: [
      { name: t('contains'), value: 'contains' },
      { name: t('notContains'), value: 'ncontains' },
    ],
    number: [
      { name: t('isEqual'), value: 'eq' },
      { name: t('isNotEqual'), value: 'neq' },
      { name: t('lessThen'), value: 'lt' },
      { name: t('lessThenEqual'), value: 'lte' },
      { name: t('greaterThan'), value: 'gt' },
      { name: t('greaterThanEqual'), value: 'gte' },
    ],
    date: [
      { name: t('greaterThanEqual'), value: 'lte' },
      { name: t('lessThenEqual'), value: 'gte' },
    ],
  },
  booleanSelect: [
    { name: t('false'), value: 'false' },
    { name: t('true'), value: 'true' }
  ]
};

/**
 * @property {string} source:name:type
 */
type FieldIdentity = string;

export class SingleConditionItem extends Model<any, any> implements ISingleConditionItem {

  @property() public fieldIdentity: string;
  @property() public operator: IConditionOperatorType;
  @property() public type: 'single';
  @property() public source: string;
  @property() public fields: any[];
  @property() public property: string;
  @property() public compareOperator: ICompareOperatorType;
  @property() public value: IConditionValueType;
  @property() public parameter: String | Number;
  @property() public valueType: IConditionValueTypeType;
  @property() public valueSubType: IConditionValueSubTypeType;

  @property() public autocompleteValue: string;

  constructor(private condition: Condition, model: ISingleConditionItem) {
    super();

    this.id = v4();

    if (model) {
      if (!model.fieldIdentity) {
        this.fieldIdentity = `${ model.source }:${ model.property }:${ model.valueType }`;
      } else {
        this.fieldIdentity = model.fieldIdentity;
      }

      this.operator = model.operator;
      this.type = model.type;
      this.source = model.source;
      this.property = model.property;
      this.compareOperator = model.compareOperator;
      this.value = model.value;
      this.parameter = model.parameter;
      this.valueType = model.valueType;
      this.valueSubType = model.valueSubType;
    }

    this.listenTo(this, 'change:fieldIdentity', (e: any, data: FieldIdentity) => this.onFieldChange(e, data));
    this.listenTo(this, 'change:valueSubType', () => this.prepareDateField());
    this.listenTo(this, 'all', (...args: any[]) => condition.trigger('change', condition, ...args));
    this.listenTo(this, 'change:valueType', () => this.changeValueType());
  }

  public defaults() {
    return {
      type: 'single',
      operator: 'or',
    };
  }

  public changeValueType(): void {
    this.compareOperator = this.getCompareOperator();
    this.prepareDateField();
  }

  public prepareDateField(): void {
    if (this.valueType === 'date') {
      if (this.valueSubType === 'period') {
        this.parameter = this.parameter || 'months';
        this.value = null;
      } else {
        this.value = this.value || new Date() as any;
        this.parameter = null;
        this.valueSubType = this.valueSubType || 'period';
      }
    } else if (this.valueType === "boolean") {
      const isBooleanType = this.value === "true" || this.value === "false";
      this.value = this.value === null || !isBooleanType ? conditionConfig.booleanSelect[0].value : this.value;
    } else {
      this.value = null;
    }
  }

  public onFieldChange(model: any, fieldIdentity: FieldIdentity): void {
    const [source, name, type] = fieldIdentity.split(':');

    this.property = name;
    this.valueType = type as any;
    this.source = source;
    this.prepareDateField();

    this.compareOperator = this.getCompareOperator();
  }

  public getCompareOperator(): ICompareOperatorType {
    const comparators = this.getComparators();
    return (comparators.map(c => c.value).indexOf(this.compareOperator) !== -1)
      ? this.compareOperator
      : this.getComparators()[0].value as ICompareOperatorType;
  }

  public getFieldOptions(hideSelectors: boolean = false) {
    const getPrefix = (model) => {
      if (model instanceof Selector) {
        return `[Sel] ${ t(model.prettyName) || model.name }`;
      }

      if (model instanceof CustomField) {
        return `[Cfd] ${ t(model.prettyName) || model.name }`;
      }

      if (model instanceof SystemField) {
        return `[Sys] ${ t(model.prettyName) || model.name }`;
      }

      if (model instanceof ExternalField) {
        return `[Get] ${ t(model.prettyName) || model.name }`;
      }
    };

    const getFieldIdentity = (field): string => {
      const thisFieldWithoutValueType: string = this.fieldIdentity.substring(0, this.fieldIdentity.lastIndexOf(":"));
      const fieldWithoutValueType: string = field.fieldIdentity.substring(0, field.fieldIdentity.lastIndexOf(":"));

      const compareFields: boolean = fieldWithoutValueType === thisFieldWithoutValueType
      const compareFieldsLowerCase: boolean = fieldWithoutValueType.toLowerCase() === thisFieldWithoutValueType.toLowerCase()

      const checkIdentityWhenTypeChange: string = compareFields || compareFieldsLowerCase ? this.fieldIdentity : field.fieldIdentity;
      return field.fieldIdentity.includes(this.fieldIdentity) ? field.fieldIdentity : checkIdentityWhenTypeChange;
    };

    return this.fields
      .filter((field: any) => hideSelectors ? !(field instanceof Selector) : true)
      .map((field: any) => {
        return {
          name: `${ getPrefix(field) }`,
          value: getFieldIdentity(field),
          translate: !field.id // System fields will be translated, client one omitted.
        };
      });
  }

  public getValueTypesOptions(): ISelectOption[] {
    const valueTypes: IConditionValueTypeType[] = ['number', 'boolean', 'string', 'date'];
    return valueTypes.map(valueType => {
      return {
        name: valueType,
        value: valueType,
        translate: true,
      };
    });
  }

  public getComparators() {
    return conditionConfig.comparatorsMap[this.valueType];
  }

  public toJSON() {
    const omit = ['fields'];
    const result = super.toJSON();
    if (result.valueType === 'date' && result.valueSubType !== 'period') omit.push('parameter');
    return lodash.omit(result, omit);
  }
}
