import styled from '@emotion/styled';

interface IBtnGetDataFromUrl {
  disabledBtn: boolean;
}

export const PushRedirectLinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .url-input-box {
    width: 100%;
  }
  
  >.input-wrapper {
    width: 100%;
  }

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;


export const BtnGetDataFromUrl = styled.button<IBtnGetDataFromUrl>`
  margin: 0 0 0 10px;
  padding: 0 0 0 12px;
  border: 1px solid #E0E0E0;
  height: 40px;
  background-color: #F6F6F6;
  color: #585858;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.1s;
  cursor: ${ (props) => props.disabledBtn ? "inherit" : "pointer" };
  opacity: ${ (props) => props.disabledBtn ? 0.5 : 1 };

  span:nth-of-type(2) {
    margin-top: 2px;
    white-space: normal;
    line-height: 14px;
    font-size: 10px;
    width: 90px;
  }

  &:hover, &:focus {
    background-color: ${ (props) => props.disabledBtn ? '#F6F6F6' : '#dbdbdb' };
    border-color: ${ (props) => props.disabledBtn ? '#E0E0E0' : '#dbdbdb' };
    color: #585858;
  }

  @media (max-width: 650px) {
    margin: 5px 0 0;
    padding: 0 15px;

    span:nth-of-type(2) {
      margin-left: 12px;
      width: auto;
    }
  }
`;
