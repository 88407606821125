import { API, UseCase } from '@ppg/common';
import { Image } from '../../../modelsMobx/Image';

enum TagCountStrategy {
  AND = 'and',
  OR = 'or'
}

export interface ISendCampaign {
  projectId: string;
  actions: any[];
  content: string;
  delayTime: number;
  direction: string;
  excludedTags: any[];
  excludedTagsCountStrategy: TagCountStrategy;
  expireDate: Date;
  icon: Image;
  iconOwnerID: string;
  image: Image;
  polygons: any[];
  redirectLink: string;
  requireInteraction: boolean;
  sample: any;
  sendDate: Date;
  senderStrategy: any;
  sentBy: string;
  state: string;
  tags: any[];
  tagsCountStrategy: TagCountStrategy;
  title: string;
  omitCapping: boolean;
}

interface ISendCampaignResponse {
  id: string;
}

export class SendCampaignUseCase extends UseCase<ISendCampaign, ISendCampaignResponse> {
  protected async execute({
                            projectId,
                            actions,
                            content,
                            delayTime,
                            direction,
                            excludedTags,
                            excludedTagsCountStrategy,
                            expireDate,
                            icon,
                            iconOwnerID,
                            image,
                            polygons,
                            redirectLink,
                            requireInteraction,
                            sample,
                            sendDate,
                            senderStrategy,
                            sentBy,
                            state,
                            tags,
                            tagsCountStrategy,
                            title
                          }: ISendCampaign) {
    return API.HTTP.post<ISendCampaignResponse>(`project/${ projectId }/push`, {
      project: projectId,
      projectId,
      actions,
      content,
      delayTime,
      direction,
      excludedTags,
      excludedTagsCountStrategy,
      expireDate,
      icon,
      iconOwnerID,
      image,
      polygons,
      redirectLink,
      requireInteraction,
      sample,
      sendDate,
      senderStrategy,
      sentBy,
      state,
      tags,
      tagsCountStrategy,
      title,
    });
  }
}

export const sendCampaignUseCase = new SendCampaignUseCase();
