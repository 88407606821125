import { API, UseCase } from '@ppg/common';
import { PlatformType } from '../../interfaces';
import { ComparisonActivity } from '../../../modelsMobx/ComparisonActivity';

interface IGetCampaignActivityComparisonRequest {
  platform?: PlatformType
  from: string,
  to: string,
}

export interface IGetCampaignActivityComparisonResult {
  delivered: ComparisonActivity;
}

export class GetCampaignActivityComparisonUseCase extends UseCase<IGetCampaignActivityComparisonRequest, IGetCampaignActivityComparisonResult> {
  protected async execute({ platform, from, to }: IGetCampaignActivityComparisonRequest) {
    if (API.HTTP.currentProjectId) {
      return API.HTTP.get<IGetCampaignActivityComparisonResult>(`statistics/v2/projects/${ API.HTTP.currentProjectId }/campaigns/delivered/comparison${ platform ? `/${ platform }` : '' }?from=${ from }&to=${ to }`);
    }
  }
}

