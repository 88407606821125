import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { UserStore } from '../../../../stores/user/UserStore';
import { t } from '../../../../base/helpers';
import { ProjectRoutesTypes } from '../../../../routes/moduleProjectRoutes';

interface IUserDetailsProps {
  userStore?: UserStore;
}

@inject('userStore')
@observer
export class UserDetails extends React.Component<IUserDetailsProps> {
  public render() {
    const { user } = this.props.userStore;

    return (
      <div className="column column-50">
        <div className="well">
          <div className="size30">
            { user.firstName } { user.lastName }
          </div>
          <div>{ user.username }</div>
          <div>{ t('user id') }: { user.id }</div>
          <div className="m10b">{ t('organization id') }: { user.organization }</div>
          <div className="m10t">
            <Link to={ ProjectRoutesTypes.USER_ACCOUNT_SETTINGS }> <span className="icon-pencil size12 m5r"/> { t('Edit profile') } </Link>
          </div>
        </div>
      </div>
    );
  }
}
