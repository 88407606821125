import { API, UseCase } from "@ppg/common";
import { ABTestSortType } from '../../../stores/project/ABTestListStore';
import { ABTestState, ABVariant, ActionsUseCases } from '../../../modelsMobx';
import { PushAction } from '../../../modelsMobx/PushAction';

interface IListABTests {
  offset: number;
  limit: number;
  query: string;
  sort: ABTestSortType;
  state: ABTestState;
}

interface IListABTestResponse {
  metadata: {
    total: number;
  };
  data: IABTestDTO[];
}

export interface IABTestDTO {
  id: string;
  name: string;
  state: ABTestState;
  variants: ABVariant[];
  testStartDate: Date;
  testEndDate: Date;
  actions: PushAction[];
  availableActions: ActionsUseCases[];
}

export class ListABTestsUseCase extends UseCase<IListABTests, IListABTestResponse> {
  protected async execute({ limit, offset, query, state, sort }: IListABTests) {
    let queryParams =`?offset=${ offset }&limit=${ limit }&sort=${ sort }`;
    queryParams += state !== ABTestState.ALL ? `&state=${ state }` : '';
    queryParams += query ? `&search=${ query }` : '';

    return API.HTTP.get<IListABTestResponse>(`core/projects/${ API.HTTP.currentProjectId }/ab-tests${ queryParams }`,);
  }
}

export const listABTestsUseCase = new ListABTestsUseCase();
