import { API, UseCase } from '@ppg/common';
import { IProjectDashboardRequestBody } from '../../../interfaces';

interface IGetProjectAutomationsStatisResult {
  automationStats: IAutomationStats;
}

export interface IAutomationStats {
  clicked: number;
  delivered: number;
  ctr: number;
  automationHistogram: IAutomationDayStats[];
}

interface IAutomationDayStats {
  day: string;
  clicked: number;
  delivered: number;
  ctr: number;
}

export class GetProjectAutomationStatsUseCase extends UseCase<IProjectDashboardRequestBody, IGetProjectAutomationsStatisResult> {
  protected async execute({ from, to, timezone }: IProjectDashboardRequestBody) {
    return API.HTTP.get<IGetProjectAutomationsStatisResult>(
      `statistics/v2/projects/${ API.HTTP.currentProjectId }/automations/statistics?from=${ from }&to=${ to }&timezone=${ timezone }`
    );
  }
}

export const getProjectAutomationStatsUseCase = new GetProjectAutomationStatsUseCase();
