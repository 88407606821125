import rootStore from './RootStore';

export const {
  userStore,
  projectStore,
  countryStore,
  paymentsStore,
  cardsStore,
  paymentDataStore,
  videoTutorialStore,
  accidentStore,
  accessManagerStore,
  offerStore,
  cappingStore,
  partnershipStore,
  imageStore,
  organizationDashboardStore,
  projectComparisonStore,
  projectDashboardStore
} = rootStore;

export const {
  campaignDayStore,
  dashboardStore,
  webhookStore,
  selectorStore,
  automationUpdateStepStore,
  clipboardStore,
  featureStore,
  pushSettingStore,
  appSettingStore,
  geolocationStore,
  exportStore,
  websiteIntegrationStore,
  photoLibraryStore,
  pushCampaignStore,
  automationListStore,
  projectImageStore,
  abTestListStore,
  abTestReportStore,
  createABTestStore,
  subscribersStore,
  subscribersPageStore,
  notificationPreviewStore,
  automationProjectDataStore,
  automationLabelsStore,
  segmentsStore,
  createPushStore,
  providerStore,
  segmentsListStore,
  pushCampaignReportStore
} = projectStore;

export const channelsStore = userStore.notificationChannels;
export const websiteDetailsStore = websiteIntegrationStore.websiteDetails;
export const subscriptionFormStoreCreator = websiteIntegrationStore.subscriptionFormCreator;
