import { API, UseCase } from "@ppg/common";
import { SubscriberLabel } from '../../../modelsMobx/subscriber/SubscriberLabel';

interface IGetSubscribersLabelsRequest {
  offset: number;
  limit: number;
  subscribers: string[];
}

interface ISubscriberLabelValueDTO {
  expiresAt: string | null;
  valueLabel: string | number | boolean;
}

export interface ISubscriberLabelDTO {
  key: string;
  values: ISubscriberLabelValueDTO[];
}

interface ISubscriberLabelsDTO {
  subscriber: string;
  labels: ISubscriberLabelDTO[];
  count: number;
}

export interface ISubscriberLabels {
  subscriber: string;
  labels: SubscriberLabel[];
  count: number;
}

export class GetSubscribersLabelsUseCase extends UseCase<IGetSubscribersLabelsRequest, ISubscriberLabels[]> {
  protected async execute({ offset, limit, subscribers}:IGetSubscribersLabelsRequest) {
    let params = new URLSearchParams();

    for (let subscriber of subscribers) {
      params.append("subscribers", subscriber)
    }

    params.toString()

    const results = await API.HTTP.get<ISubscriberLabelsDTO[]>(`core/projects/${ API.HTTP.currentProjectId }/segmentation/subscribers?offset=${ offset }&limit=${ limit }&${params}`)

    return results.map(result => ({
      subscriber: result.subscriber,
      count: result.count,
      labels: SubscriberLabel.createSubscriberLabelsFromDTO(result.labels)
    }))
  }
}

export const getSubscribersLabelsUseCase = new GetSubscribersLabelsUseCase();
