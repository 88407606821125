import cs from 'classnames';
import * as lodash from 'lodash';
import * as React from 'react';
import { Help } from '@ppg/styled';
import { AbstractFiled } from './AbstractFiled';
import { ICheckboxProps } from './ICheckboxProps';

export class Checkbox extends AbstractFiled<ICheckboxProps, {}> {

  private onChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.props.attribute.setValue(event.currentTarget.checked);
    lodash.invoke(this.props, 'onChange', event);
  }

  private convertToBoolean(value) {
    return typeof value === 'boolean' ? value : value == 'true';
  }

  public render() {
    const { attribute, label, help, helpPosition, safelessLabel, disabled } = this.props;
    const isDisabled = disabled || false;

    return (
      <div className="input-holder">
        <label className={ cs('checkbox-label', { 'checkbox-label--disabled': isDisabled || false }) }>
          <span className="checkbox m10r">
            <input type="checkbox" id={ this.inputId } checked={ this.convertToBoolean(attribute.getValue()) } onChange={ this.onChange } disabled={ isDisabled }/>
            <span/>
          </span>
          { !safelessLabel ? label :
            <span className="checkbox-label" dangerouslySetInnerHTML={ { __html: safelessLabel } }/> }
          { help && <Help content={ help } place={ helpPosition }/> }
        </label>
      </div>
    );
  }
}
