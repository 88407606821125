import * as React from 'react';
import { t } from '../../../../base/helpers';
import { inject, observer } from 'mobx-react';
import { PaymentDataStore } from '../../../../stores/payment/PaymentDataStore';

interface IPaymentDataAlertProps {
  paymentDataStore?: PaymentDataStore;
}

@inject('paymentDataStore')
@observer
export class InvoiceDetailsAlert extends React.Component<IPaymentDataAlertProps> {
  public render() {
    if (this.props.paymentDataStore.hasPaymentData) {
      return null;
    }

    return <div className="alert-block alert--error">{ t('Your company data is missing. Please fill in the fields below.') }</div>;
  }
}
