import { brand, IBrandColors } from './brand';
import { functional, IFunctionalColors } from './functional';
import { ITextColors, text } from './text';
import { ISecondaryColors, secondary } from './secondary';
import { ITertiaryColors, tertiary } from './tertiary';
import { action, IActionColors } from './action';

export interface IColors {
  brand: IBrandColors;
  functional: IFunctionalColors;
  text: ITextColors,
  secondary: ISecondaryColors;
  tertiary: ITertiaryColors;
  action: IActionColors,
}

export const colors: IColors = {
  brand,
  functional,
  text,
  secondary,
  tertiary,
  action,
};
