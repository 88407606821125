import { UseCase, API } from '@ppg/common';
import { IGetWebsiteIntegration } from './interfaces';

export class GetWebsiteIntegrationByProjectIdUseCase extends UseCase<IGetWebsiteIntegration, string> {
  protected async execute({ projectID }: IGetWebsiteIntegration) {
    return API.HTTP.get<string>(`wi/projects/${ projectID }/website-integrations/resolve`);
  }
}

export const getWebsiteIntegrationByProjectIdUseCase = new GetWebsiteIntegrationByProjectIdUseCase();
