import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { t } from '../../../../base/helpers';
import { UserStore } from '../../../../stores/user/UserStore';
import { Modal } from '@ppg/styled';

interface IDeleteUserModalProps {
  onCancel: () => void;
  onSave: () => void;
  show: boolean;
  userStore?: UserStore;
}

@inject('userStore')
@observer
export class SettingsDeleteUserModal extends React.Component<IDeleteUserModalProps, {}> {
  public render() {
    const { onCancel, show, onSave } = this.props;

    const ModalContent = () => {
      return (
        <div data-cy={'user-delete-modal'}>
          <p style={ { marginBottom: '1.5rem' } }>{ t("You're about to delete your account. If you're sure, you will be logged out of the application and we will send you a link with further instructions.") }</p>
          <p style={ { marginBottom: 0 } }>{ t('Remember that once your account is deleted, you cannot get it back.') }</p>
        </div>
      )
    };

    return (
      <Modal
        show={ show }
        title={ <h1>{ t('Are you sure?') }</h1> }
        confirmButton={ t("Yes, I'm sure") }
        cancelButton={ t('Cancel') }
        onCancel={ () => onCancel() }
        onConfirm={ () => onSave() }
        width={ 400 }
        content={ <ModalContent/> }
        isConfirmModal={ true }
      />);
  }
}
