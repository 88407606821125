import { AbstractAutomationStep, ITagStepPayload } from '../../models';
import { action, IObservableArray, observable } from 'mobx';
import { getLabelsUseCase } from '../../useCases/core';
import { Utils } from '../utils';
import { SortTypeOptions } from './SegmentationStore';
import { AutomationLabel } from "../../components/Automation/TagsForm/AutomationLabel";
import { LabelStrategyEnum } from "../../modelsMobx/LabelStrategyEnum";
import { ProjectRelated } from "../ProjectRelatedStore";
import { ProjectStore } from "./ProjectStore";
import { AutomationProjectDataStore } from "./AutomationProjectDataStore";

export enum LabelPeriodTimeOptions {
  SECONDS = 'seconds',
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days'
}

export enum LabelStrategyOptions {
  APPEND = 'append',
  REWRITE = 'rewrite'
}

export enum SelectionButtonType {
  ADD = 'add',
  DELETE = 'delete'
}

export interface IAutomationStepLabel {
  task: string;
  key: string;
  value: string;
  strategy: LabelStrategyEnum;
  ttl: number;
}

export class AutomationLabelsStore extends ProjectRelated {

  private automationProjectDataStore: AutomationProjectDataStore;

  constructor(projectStore: ProjectStore, automationProjectDataStore: AutomationProjectDataStore) {
    super(projectStore);
    this.automationProjectDataStore = automationProjectDataStore;
  }

  @observable public lastQueries: Set<string> = new Set<string>();
  @observable public addTagKeyword: string;
  @observable public labels: IObservableArray<AutomationLabel> = observable.array([]);
  @observable public labelsToAdd: IObservableArray<AutomationLabel> = observable.array([]);
  @observable public labelsToRemove: IObservableArray<AutomationLabel> = observable.array([]);
  @observable public offset: number;
  @observable public isLoadingLabels: boolean;

  @observable public newLabel = AutomationLabel.createLabel({
    key: "default",
    value: "",
  });

  @action
  fetchUserLabels(): string[] {
    return this.automationProjectDataStore.fetchUserLabels();
  }

  @action
  fetchLabelsKeysWithUserOnes(): string[] {
    return this.automationProjectDataStore.labelsKeysWithoutSys;
  }

  @action
  private checkWasQueried(key: string): boolean {
    return this.lastQueries.has(key);
  }

  @action
  private addLastQueryItem(key: string) {
    this.lastQueries.add(key);
  }

  @action
  public async asyncAppendLabels() {
    const promises = [];

    if (this.checkWasQueried(this.addTagKeyword)) {
      return;
    }

    promises.push(await getLabelsUseCase.exec({
      offset: this.offset,
      limit: 20,
      sortBy: SortTypeOptions.POPULAR,
      key: "",
      query: this.addTagKeyword
    }));

    this.addLastQueryItem(this.addTagKeyword);

    const label = this.automationProjectDataStore.serverLabelKeys.find((label) => label.startsWith(this.addTagKeyword));

    if (label) {
      promises.push(await getLabelsUseCase.exec({
        offset: this.offset,
        limit: 20,
        sortBy: SortTypeOptions.POPULAR,
        key: label,
        query: ""
      }));

      this.addLastQueryItem(label);
    }

    const responses = await Promise.all(promises);

    for (let { data } of responses) {
      data.forEach(label => this.automationProjectDataStore.appendUserLabel(label.key, `${ label.value }`));
    }
  }

  @action
  public prepareStepPayload(step: AbstractAutomationStep<ITagStepPayload>): void {
    if (Object.keys(step.payload).length === 0) {
      step.payload.labels = [];
      this.labelsToAdd.clear();
      this.labelsToRemove.clear();
    }

    if (step.payload.labels && step.payload.labels.length !== 0) {
      const labelsToAddList = step.payload.labels.filter(label => label.task === SelectionButtonType.ADD);
      const labelsToRemoveList = step.payload.labels.filter(label => label.task === SelectionButtonType.DELETE);

      this.labelsToAdd.replace(labelsToAddList.map(label => AutomationLabel.createLabel(label)));
      this.labelsToRemove.replace(labelsToRemoveList.map(label => AutomationLabel.createLabel(label)));
    }
  }

  @action
  public async fetchLabels(searchValue: string): Promise<void> {
    if (![""].concat(this.automationProjectDataStore.serverLabelKeys).includes(searchValue)) {
      return;
    }

    await this.executeWithLabelsLoader(async () => {
      const { data } = await getLabelsUseCase.exec({
        offset: this.offset,
        limit: 20,
        sortBy: SortTypeOptions.POPULAR,
        key: searchValue,
        query: ""
      });

      this.labels.replace(data.map(label => AutomationLabel.createLabel({
        key: label.key,
        value: `${ label.value }`,
      })));
    });
  };

  public getFilteredByLabelKeyUserValues(currentLabel: string | null): string[] {

    const userLabels = this.automationProjectDataStore
      .fetchUserLabels()
      // Map to format
      .map(label => {
        const [key, ...values] = label.split(":");
        return [key, values.join(':')];
      })
      // Filter via label
      .filter(([key, _]) => {
        return currentLabel ? currentLabel === key : true;
      })
      // Map to value
      .map(([_, value]) => value);

    return this.labels
      .filter(label => {
        return currentLabel ? currentLabel === label.key : true;
      })
      .map(label => `${ label.value }`)
      .concat(userLabels)
      // Remove duplicates
      .filter(function (value, index, source) {
        return source.indexOf(value) === index;
      });
  }

  @action
  public setAddNewLabel(typeOfAddLabels: SelectionButtonType, label: AutomationLabel): void {
    typeOfAddLabels === SelectionButtonType.ADD
      ? this.addLabel(label)
      : this.labelsToRemove.push(label)
    this.automationProjectDataStore.addUserLabel(label);
  }

  private addLabel(label: AutomationLabel) {
    if (label.isKeyDefault()) {
      label.strategy = LabelStrategyEnum.APPEND;
    }

    this.labelsToAdd.push(label);
  }

  @action
  public onDeleteAddLabel(deleteLabel: AutomationLabel): void {
    const index = this.labelsToAdd.findIndex((label: AutomationLabel) => label.getLabelIdentity() === deleteLabel.getLabelIdentity());
    this.labelsToAdd.splice(index, 1);
    this.automationProjectDataStore.addUserLabel(deleteLabel);
  }

  @action
  public onDeleteRemoveLabel(deleteLabel: AutomationLabel): void {
    const index = this.labelsToRemove.findIndex((label: AutomationLabel) => label.getLabelIdentity() === deleteLabel.getLabelIdentity());
    this.labelsToRemove.splice(index, 1);
    this.automationProjectDataStore.deleteUserLabel(deleteLabel);
  }

  private executeWithLabelsLoader<T = any>(callback: () => Promise<T>) {
    return Utils.executeWithLoader(
      callback,
      () => this.isLoadingLabels = true,
      () => this.isLoadingLabels = false,
      (err) => {
        console.error(err);
        this.isLoadingLabels = false;
      }
    );
  }

  setDefaultValues() {
    this.newLabel = AutomationLabel.createLabel({
      key: "",
      value: "",
    });
    this.addTagKeyword = '';
    this.labels.clear();
    this.offset = 0;
    this.isLoadingLabels = true;
    this.labelsToAdd.clear();
    this.labelsToRemove.clear();
    this.lastQueries = new Set<string>();
  }
}
