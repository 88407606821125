import { Property, property, PropertyHandler } from "@ppg/common";
import { action, computed, observable } from 'mobx';
import dayjs from 'dayjs';
import { IDisableDays } from '@ppg/styled';
import { DATE_RANGE_MONTH_LIMIT } from '../../../constants';
import { ProjectDashboardSubscriberStore } from './ProjectDashboardSubscriberStore';
import { ProjectDashboardAutomationStore } from './ProjectDashboardAutomationStore';
import { ProjectDashboardCampaignStore } from './ProjectDashboardCampaignStore';
import { ActiveViewType } from '../../../pages/User/Navigations/UnderlinedNavigationBar/UnderlinedNavigationBar';
import { IChangeFactors, IRangeFactors } from '../../../pages/Dashboard/common/interfaces';
import { PlatformType } from '../../../useCases/interfaces';
import { IDefaultRange, IProjectDashboardStore } from './interfaces';

export class ProjectDashboardStore extends PropertyHandler implements IProjectDashboardStore {
  @property()
  public dateRangeFrom = this.defaultRangeDate.from;

  @property()
  public dateRangeTo = this.defaultRangeDate.to;

  @property()
  public mobilePlatformEnabled = true;

  @property()
  public webPlatformEnabled = true;

  @property()
  public activeTab = 'General';

  @property()
  public activeViewType = ActiveViewType.CHART;

  @observable
  public timezone = '';

  public subscriber: ProjectDashboardSubscriberStore;
  public campaign: ProjectDashboardCampaignStore;
  public automation: ProjectDashboardAutomationStore;

  constructor() {
    super();
    this.subscriber = new ProjectDashboardSubscriberStore(this);
    this.campaign = new ProjectDashboardCampaignStore(this);
    this.automation = new ProjectDashboardAutomationStore(this);
    this.setTimezone();
  }

  @computed
  public get rangeChangeFactors(): IRangeFactors {
    return {
      from: this.dateRangeFrom.toISOString(),
      to: this.dateRangeTo.toISOString()
    };
  }

  @action
  public enableAllPlatforms = (): void => {
    this.webPlatformEnabled = true;
    this.mobilePlatformEnabled = true;
  };

  public isPlatformSelected = (): boolean => {
    return this.webPlatformEnabled || this.mobilePlatformEnabled;
  };

  @computed
  public get platformChangeFactors(): IChangeFactors {
    return {
      web: this.webPlatformEnabled,
      mobile: this.mobilePlatformEnabled,
    };
  }

  @computed
  public get isMobilePlatformSelected(): boolean {
    return this.mobilePlatformEnabled;
  }

  @computed
  public get platformType(): PlatformType | null {
    if (this.bothPlatformsEnabled) {
      return null;
    }

    if (this.webPlatformEnabled) {
      return PlatformType.WEB;
    }

    if (this.mobilePlatformEnabled) {
      return PlatformType.MOBILE;
    }
  }

  private setTimezone = (): void => {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  @computed
  public get defaultRangeDate(): IDefaultRange {
    return {
      from: dayjs().subtract(6, 'days').startOf('day').toDate(),
      to: dayjs().endOf('day').toDate(),
    };
  }

  @computed
  public get disabledDays(): IDisableDays {
    const maxRangeTo = dayjs(this.dateRangeFrom).add(DATE_RANGE_MONTH_LIMIT, 'months');
    const defaultTo = this.defaultRangeDate.to;
    return {
      before: dayjs(this.dateRangeTo).subtract(DATE_RANGE_MONTH_LIMIT, 'months').startOf('day').toDate(),
      after: maxRangeTo.isAfter(defaultTo) ? defaultTo : maxRangeTo.toDate(),
    };
  }

  @computed
  public get bothPlatformsEnabled(): boolean {
    return this.webPlatformEnabled && this.mobilePlatformEnabled;
  }

  @action
  public undifferentiatedPlatforms = async (callback: () => Promise<void>, PropertyToClear: Property, emptyValue: any = []): Promise<void> => {
    if (!this.bothPlatformsEnabled) {
      PropertyToClear.setValue(emptyValue);
      return;
    }

    await callback();
  };
}
