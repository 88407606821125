import { UseCase, API } from '@ppg/common';
import { IUpdateDataLayer } from '../interfaces';

export class UpdateDataLayerPluginUseCase extends UseCase<IUpdateDataLayer, void> {
  protected async execute({ websiteIntegrationId, enabled, config }: IUpdateDataLayer) {
    await API.HTTP.put(`wi/projects/${ API.HTTP.currentProjectId }/website-integrations/${ websiteIntegrationId }/plugins/data-layer`, { enabled, config })
  }
}

export const updateDataLayerPluginUseCase = new UpdateDataLayerPluginUseCase();
