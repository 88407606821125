import { UseCase, API } from "@ppg/common";

interface IAcceptABTest {
  abTestId: string;
  testStartDate: Date;
  testEndDate: Date;
}

export class AcceptABTestWithManualWinnerUseCase extends UseCase<IAcceptABTest, void> {
  protected async execute({ testStartDate, testEndDate, abTestId }: IAcceptABTest) {
    return API.HTTP.post<void>(`core/projects/${ API.HTTP.currentProjectId }/ab-tests/${ abTestId }/accept/manual`, {
      testStartDate, testEndDate
    });
  }
}


export const acceptABTestWithManualWinnerUseCase = new AcceptABTestWithManualWinnerUseCase();
