import { API, UseCase } from '@ppg/common';
import { PlatformType } from '../../interfaces';

interface IGetSubscribersActivityHistogramDataRequest {
  from: string;
  to: string;
  platform: PlatformType;
  timezone: string;
}

interface IGetSubscribersActivityHistogramDataResult {
  histogram: IActivityHistogram[];
}

export interface IActivityHistogram {
  day: string,
  subscribed: number,
  unregistered: number
}

export class GetSubscribersActivityHistogramDataUseCase extends UseCase<IGetSubscribersActivityHistogramDataRequest, IGetSubscribersActivityHistogramDataResult> {
  protected async execute({ from, to, platform, timezone }: IGetSubscribersActivityHistogramDataRequest) {
    if (API.HTTP.currentProjectId) {
      return API.HTTP.get<IGetSubscribersActivityHistogramDataResult>(`statistics/v2/projects/${ API.HTTP.currentProjectId }/subscribers/activity/histogram${ platform ? `/${ platform }` : '' }?from=${ from }&to=${ to }&timezone=${ timezone }`);
    }
  }
}
