import * as React from "react";
import { Collection } from 'backbone';
import * as lodash from 'lodash';
import { OpUnitType } from 'dayjs';
import { connector, Form } from '../base';
import { lsProperty, property } from '../base/decorators';
import { IKeyValue } from '../interfaces';
import { AbstractAutomationStep, AutomationFlowType, IAutomationFlow, IAutomationFlowStep, SystemField, WebUser } from '../models';
import { t } from '../base/helpers';
import { KeyValue } from '../models/KeyValue';
import { automationStepFactory } from "../models/automation/AutomationStepFactory";
import { Selector } from '../modelsMobx/selector/Selector';
import { ExternalField } from "../models/ExternalField";

export interface IAutomationFlowForm extends Partial<IAutomationFlow> {
  enabled?: boolean;
  testMode?: boolean;
  showTriggerWarning: boolean;
  name?: string;
  canvasHeight?: number;
  recurrencyTime?: string;
  //@ts-ignore
  additionalFields: Collection<KeyValue> | IKeyValue[];
  user?: WebUser;
  resetTime?: number;
  resetTimePeriod?: OpUnitType;
  selectedStep?: AbstractAutomationStep<any>;
  stepToChange?: AbstractAutomationStep<any>;
  transform?: any;
  triggerType?: AutomationFlowType;
  availableConditionFields: any;
}
//@ts-ignore
export class AutomationFlowForm extends Form<IAutomationFlowForm, IAutomationFlowForm> implements IAutomationFlowForm {
  @property() public selectedStep: AbstractAutomationStep<any>;
  @property() public stepToChange: AbstractAutomationStep<any>;
  @property() public isSaved: boolean;
  @property() public name: string;
  @property() public automationId: string;
  //@ts-ignore
  @property() public additionalFields: Collection<KeyValue>;
  @property() public resetTime: number;
  @property() public resetTimePeriod: OpUnitType;
  @property() public recurrencyTime: string;
  @property() public enabled: boolean;
  @property() public testMode: boolean;
  @property() public triggerType: AutomationFlowType;
  @property() public simulateSteps: string[];
  @property() public reportsMode: boolean;
  @property() public automationExpires: boolean;
  @property() public expireDate: Date;

  /**
   * Dates for reports range
   */
  @property() public from: Date;
  @property() public to: Date;

  @lsProperty() public canvasHeight: number = 350;

  private _isUiLocked: boolean = false;

  public user: WebUser = connector.get('user');

  public get isUiLocked(): boolean {
    return this._isUiLocked;
  }

  public onCheckboxChange(event: React.FormEvent<HTMLInputElement>) {
    if (!event.currentTarget.checked && this.selectedStep) {
      this.selectedStep.getAttribute('declaredSendFrom').setValue('');
      this.selectedStep.getAttribute('declaredSendTill').setValue('');
    }
  }

  // d3 temporary position (do not trigger model change - loop on rendering)
  public transform: any;

  get availableTriggerTypes(): any[] {
    return [
      { name: 'sessionEnd', label: 'sessionEnd', description: t('Choosing this trigger, you would have access to data from selectors') },
      { name: 'sessionStart', label: 'sessionStart', description: t('Choosing this trigger, you do not have access to any session data.') },
      { name: 'subscribed', label: 'subscribed', description: t('Use this trigger to welcome a new subscriber') },
      { name: 'unsubscribed', label: 'unsubscribed', description: t('You cannot send any notifications using this trigger.') },
      { name: 'custom', label: 'customTrigger', description: t('Use this to set your own trigger automation') },
    ];
  }

  private getAdditionalFieldsModels() {
    return this.additionalFields && this.additionalFields.models || [];
  }

  public setAutomationVariables(customFields, selectors?: Selector[]) {
    if (customFields) {
      this.automationVariables.customFields = customFields;
    }
    if (selectors) {
      this.automationVariables.selectors = selectors;
    }

    const generatedExternalFields = ExternalField.generateExternalFields(this.getAdditionalFieldsModels());

    this.automationVariables.additionalFields = generatedExternalFields;
  }

  public automationVariables = {
    additionalFields: [],
    defaultSessionEndVariables: [
      Selector.defaultAutomationSelectors().referrer,
      Selector.defaultAutomationSelectors().origin,
      Selector.defaultAutomationSelectors().url,
      Selector.defaultAutomationSelectors().visitTime,
      Selector.defaultAutomationSelectors().lastVisitDate,
      SystemField.defaultAutomationTags(),
      SystemField.defaultAutomationWeekDay(),
    ],
    defaultVariables: [
      Selector.defaultAutomationSelectors().referrer,
      Selector.defaultAutomationSelectors().origin,
      SystemField.defaultAutomationTags(),
      SystemField.defaultAutomationWeekDay(),
    ],
    customFields: [],
    selectors: [],
  };

  get availableConditionFields() {
    const additionalAfterGetStep = this.selectedStep.isAfterStep(AbstractAutomationStep.AutomationStepTypes.GET) && this.automationVariables.additionalFields || [];

    switch (this.triggerType) {
      case AutomationFlowType.SESSION_END:
        return [].concat(
          this.automationVariables.defaultSessionEndVariables,
          this.automationVariables.customFields,
          this.automationVariables.selectors,
          additionalAfterGetStep
        );
      case AutomationFlowType.CUSTOM:
        return [].concat(
          this.automationVariables.defaultVariables,
          this.automationVariables.customFields,
          additionalAfterGetStep
        );
      default:
        return [].concat(
          this.automationVariables.defaultVariables,
          additionalAfterGetStep
        );
    }
  }

  public joinTrigger(steps: AbstractAutomationStep<any>[], firstStepId: string): AbstractAutomationStep<any>[] {
    return [automationStepFactory.createOne(null, this.getTrigger(firstStepId) as IAutomationFlowStep<any>)].concat(steps) as any;
  }

  public get showTriggerWarning() {
    return this.automationId && this.hasChanged('triggerType');
  }

  public getTrigger(firstStepId: string) {
    return {
      stepId: 'trigger',
      type: 'trigger',
      resolvers: ['nextStepId'],
      active: !this.selectedStep,
      payload: {
        nextStepId: firstStepId
      }
    };
  }

  public createDummyD3Step(currentStep: AbstractAutomationStep<any>) {
    return {
      stepId: currentStep.stepId,
      type: currentStep.type,
      remove: ['trigger', 'add'].indexOf(currentStep.type) === -1,
      active: lodash.result(this, 'selectedStep.stepId', null) === currentStep.stepId,
      append: currentStep.resolvers,
      children: []
    } as any;
  }

  public appendAdditionalFields(models: IKeyValue[]) {
    //@ts-ignore
    this.additionalFields = new Collection<KeyValue>(models, { model: KeyValue });

  }

  public isAfterUntilStep() {
    const { selectedStep } = this.attributes;

    const currentStepId = selectedStep.stepId;
    const steps = selectedStep.automationFlow && selectedStep.automationFlow.steps || [];
    const currentStepIdx = steps.findIndex(el => el.stepId === currentStepId);
    if (currentStepIdx === 0) {
      return false;
    }

    const previousStep = steps[currentStepIdx - 1];
    return previousStep && previousStep.type === 'until';
  }

  /**
   * Checks step type on path after stepId
   */
  checkStepTypeOnPathAfterId(steps, stepId, type) {
    const step = steps.find(item => item.stepId === stepId);

    if (!step) {
      return null;
    }

    if (step.type === type) {
      return step;
    }

    if (step.payload && step.payload.nextStepId) {
      return this.checkStepTypeOnPathAfterId(steps, step.payload.nextStepId, type);
    }

    return null;
  }

  public resolveLoop(steps: AbstractAutomationStep<any>[]) {
    const untilStep = steps.find(step => step.type === 'until');

    if (!untilStep) {
      return [];
    }

    const conditionStep = this.checkStepTypeOnPathAfterId(steps, untilStep.stepId, 'condition');

    if (!conditionStep) {
      return [];
    }

    return [untilStep.stepId, conditionStep.stepId];
  }

  /**
   * Generate nested tree for d3 rendering
   * @param steps
   * @param stepId
   */
  public generateTree(steps: AbstractAutomationStep<any>[], append: boolean = true) {
    const self = this;

    /**
     * Prepare dataMap
     */
    const dataMap = steps.reduce((map: any, step: any) => {
      map[step.stepId] = lodash.extend(step, this.createDummyD3Step(step));
      return map;
    }, {});

    /**
     * Generate tree
     */
    steps.forEach(function (step: any) {
      step.resolvers.forEach(function (resolver: any) {
        /**
         * Get children, if not create dummy object for append next child
         */
        const fakeChildren = append ? self.createDummyD3Step({
          stepId: step.stepId,
          type: 'add',
          resolvers: [resolver]
        } as any) : null;

        const children = dataMap[step.payload[resolver]] || fakeChildren;

        if (children) {
          if (self.simulateSteps.indexOf(children.stepId) !== -1 && children.type !== 'add') {
            children.simulate = true;
          }

          if (children instanceof AbstractAutomationStep) {
            children.setParent(step);
          }

          children.parentStepId = step.stepId;
          children.resolver = resolver;
          children.colorAction = resolver;

          dataMap[step.stepId].children.push(children);
        }
      });
    });

    return dataMap.trigger;
  }

  public defaults() {
    return {
      visitedUrls: [],
      canvasHeight: 350,
      enabled: false,
      name: t('New Automation'),
      simulateSteps: [],
      resetTime: 0,
      triggerType: 'sessionEnd',
      resetTimePeriod: 'days',
      reportsMode: false,
    } as any;
  }

  public setDefaultCanvasHeight() {
    this.canvasHeight = 350;
  }

  public setCanvasHeight(height: number = 350) {
    this.canvasHeight = height;
  }

  public initialize() {
    this.on('change:automationExpires', function (event, model) {
      if (this.expireDate) return;
      if (model) {
        this.expireDate = new Date();
      } else {
        this.expireDate = null;
      }
    });
  }

  public fromAutomation(automation: IAutomationFlow = {} as IAutomationFlow) {
    this.set({
      automationId: automation.id,
      isSaved: Boolean(automation.id),
      enabled: automation.state === 'resume',
      testMode: automation.testMode,
      name: automation.name,
      resetTime: automation.resetTime,
      resetTimePeriod: automation.resetTimePeriod,
      additionalFields: automation.additionalFields,
      recurrencyTime: automation.recurrencyTime,
      triggerType: automation.triggerType,
      automationExpires: !!automation.expireDate,
      expireDate: automation.expireDate,
    });
  }

  public toAutomationFlow() {
    return {
      triggerType: this.triggerType,
      testMode: this.testMode,
      recurrencyTime: this.recurrencyTime,
      resetTime: this.resetTime,
      resetTimePeriod: this.resetTimePeriod,
      additionalFields: this.additionalFields,
      name: this.name,
      state: this.enabled ? 'resume' : 'pause',
      expireDate: this.expireDate,
    };
  }

  public selectStep(nextStep: AbstractAutomationStep<any>) {
    this.selectedStep = nextStep;
  }

  public onNextStepAccepted = () => {
    this.selectedStep = this.stepToChange;
    this.stepToChange = null;
  };

  get canUseSelectors() {
    return this.typeOf(AutomationFlowType.SESSION_END);
  }

  /**
   * Checks type of form
   * @param type AutomationFlowType
   * @returns boolean
   */
  public typeOf(type: AutomationFlowType): boolean {
    return this.triggerType === type;
  }

  public lockUI(isLocked: boolean) {
    const wasChanged = isLocked !== this._isUiLocked;
    this._isUiLocked = isLocked;

    if (wasChanged) {
      this.trigger('change', this, this.collection);
    }
  }

  public toggleReportsMode(value?: boolean) {
    this.reportsMode = typeof value === 'boolean' ? value : !this.reportsMode;
  }
}
