import { css } from "@emotion/react";
import { MainTheme } from '../themes/MainTheme';

export const GlobalStyles = css`
body {
  color: ${ MainTheme.colors.functional.text };
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background:${ MainTheme.colors.functional.background };
  font-size: ${ MainTheme.font.size.m };
  padding: 0;
  margin: 0;
}

.__react_component_tooltip.type-light {
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.2);
  padding: 5px 10px !important;
  font-size: 11px !important;
}
`;