import { IPushCampaignOptions } from './PushCampaignOptions';
import { property } from '@ppg/common';
import { action } from 'mobx';
import { ISegmentDTO } from '../../useCases/core/campaigns/interfaces';

interface ISegmentPushCampaignOptions extends IPushCampaignOptions {
  segment: ISegmentDTO | null;
  selectedSegment: ISegmentDTO | null;
}

export class SegmentPushCampaignOptions implements ISegmentPushCampaignOptions {
  @property() public sentBy: string;
  @property() public fromAPI: boolean;

  @property() public segment: ISegmentDTO | null = null;
  @property() public selectedSegment: ISegmentDTO | null = null;

  constructor(sentBy, fromAPI, segment) {
    this.sentBy = sentBy;
    this.fromAPI = fromAPI;
    this.segment = segment;
  }

  public getOptionsDTO(): Record<string, any> {
    return {
      segment: this.segment ? this.segment.id : ""
    };
  }

  public get hasSegment(): boolean {
    return !!this.segment;
  }

  public get hasSelectedSegment(): boolean {
    return !!this.selectedSegment;
  }

  @action
  public selectSegment(segment: ISegmentDTO): void {
    if (this.isSelected(segment)) {
      this.selectedSegment = null;
    } else {
      this.selectedSegment = segment;
    }
  }


  public isSelected(segment: ISegmentDTO): boolean {
    if (!this.selectedSegment) {
      return false;
    }

    return this.selectedSegment.id === segment.id;
  }

  @action
  public deselectSelectedSegment(): void {
    this.selectedSegment = null;
  }

  @action
  public deselectSegment(): void {
    this.segment = null;
  }

  @action
  public onSaveSegment(): void {
    this.segment = this.selectedSegment;
    this.deselectSelectedSegment();
  }

  @action
  public onSetSegment(): void {
   this.selectedSegment = this.segment
  }
}
