import { API, UseCase } from "@ppg/common";
import { ISegmentDTO } from './GetSegmentsUseCase';

interface IGetSegmentRequest {
  projectId: string;
  segment: string;
}

export class GetSegmentUseCase extends UseCase<IGetSegmentRequest, ISegmentDTO> {
  protected async execute({projectId, segment}: IGetSegmentRequest) {
    const result: any = await API.HTTP.get<ISegmentDTO>(`core/projects/${projectId}/segment/${segment}`)

    result.conditions = result.conditions.conditions.map(conditionGroup => conditionGroup.conditions)

    return result
  }
}

export const getSegmentUseCase = new GetSegmentUseCase()
