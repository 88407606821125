import * as React from 'react';
import { FC } from 'react';
import { numberWithSpaces, t } from '../../../../base/helpers';
import { IAutomationRankItem } from './AutomationRank';
import { observer } from 'mobx-react';
import { fixedPercentValue } from '@ppg/common';

interface IAutomationItem {
  automation: IAutomationRankItem;
}

export const AutomationItem: FC<IAutomationItem> = observer(({ automation }) => {
  return <tr className="automationItem">
    <td className="automationItem__details">
      <span className={ 'icon-play automationItem__icon size35' }/>
      <div className='automationItem__label'>
        <span className="automationItem__title">{ automation.automationName }</span>
        { automation.projectName && <span className="automationItem__project">{ automation.projectName }</span> }
      </div>
    </td>
    <td data-label={ t('organization/dashboard/automation::Campaigns number') }><span className="data-description">{ t('organization/dashboard/automation::Campaigns number') }:</span>{ automation.campaignsNumber }</td>
    <td data-label={ t('organization/dashboard/automation::CTR') }><span className="data-description">{ t('organization/dashboard/automation::CTR') }:</span>{ fixedPercentValue(automation.ctr) }</td>
    <td data-label={ t('organization/dashboard/automation::Delivered') }><span className="data-description">{ t('organization/dashboard/automation::Delivered') }:</span>{ numberWithSpaces(automation.delivered) }</td>
    <td data-label={ t('organization/dashboard/automation::Clicked') }><span className="data-description">{ t('organization/dashboard/automation::Clicked') }:</span>{ numberWithSpaces(automation.clicked) }</td>
  </tr>;
});
