import { API, localSettings, UseCase } from '@ppg/common';

export class AuthenticateOauthUserUseCase extends UseCase<null, string> {
  protected async execute() {
    const csrfToken = localSettings.getValue('_csrf');
    return API.HTTP.get<string>(`aai/oauth/redirect?csrf=${ csrfToken }&type=ONET`);
  }
}

export const authenticateOauthUserUseCase = new AuthenticateOauthUserUseCase();
