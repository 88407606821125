import { property, PropertyHandler } from '@ppg/common';
import { v4 } from 'uuid';
import { Project } from '../models';
import { action, computed } from 'mobx';

export class AccessKey extends PropertyHandler {
  @property()
  public id: string;

  @property()
  public projects: string[] = [];

  @property()
  public token: string;

  @property()
  public expiredAt: string;

  @property()
  private _selectedProjects = new Map<string, Project>();

  constructor(accessKey: any) {
    super();

    this.id = accessKey?.id;
    this.projects = accessKey?.projects;
    this.token = accessKey?.token ?? v4();
    this.expiredAt = accessKey?.expiredAt;

    Array
      .from(accessKey?.payload?.projects ?? [])
      .forEach((projectID: string) => {
        this._selectedProjects.set(projectID, null);
      });
  }

  public parseAccessKey(data: object) {
    Object
      .keys(data)
      .forEach(item => {
        if (this.hasOwnProperty(item)) {
          this[item] = data[item] ?? '';
        }
      });
  }

  @action
  public addProject(project: Project) {
    this._selectedProjects.set(project.id as string, project);
  }

  @action
  public removeProject(project: Project) {
    this._selectedProjects.delete(project.id as string);
  }

  @computed
  public get selectedProjects(): Project[] {
    return Array.from(this._selectedProjects.values() ?? []);
  }

  @computed
  public get projectIDS(): string[] {
    return Array.from(this._selectedProjects.keys() ?? []);
  }
}
