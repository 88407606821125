import { API, UseCase } from "@ppg/common";
import { IUpdateSendPushCampaign, ICampaignIdResponse } from '../interfaces';

interface IUpdateSegmentedPushRequest extends IUpdateSendPushCampaign {}
interface IUpdateSegmentedPushResponse extends ICampaignIdResponse {}

export class UpdateSegmentedPushUseCase extends UseCase<IUpdateSegmentedPushRequest, IUpdateSegmentedPushResponse> {
  protected async execute({ campaignId, message, options }: IUpdateSegmentedPushRequest) {
    return API.HTTP.put<IUpdateSegmentedPushResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/segmented/${ campaignId }`, { message, options });
  }
}

export const updateSegmentedPushUseCase = new UpdateSegmentedPushUseCase();
