import { UseCase, API } from '@ppg/common';

interface GetAllSharedProjectsRequest {
  organization: string,
  offset: number,
  limit: number,
  project?: string;
  email?: string;
}

interface GetAllSharedProjectsResult {
  data: ISharedProject[];
  metadata: {
    total: number
  }
}

export interface ISharedProject {
  id: string;
  project: string;
  sharedTo: {
    id: string,
    username: string,
  };
}

export class GetAllSharedProjectsUseCase extends UseCase<GetAllSharedProjectsRequest, GetAllSharedProjectsResult> {
  protected async execute({ organization, offset, limit, project, email }: GetAllSharedProjectsRequest) {
    return API.HTTP.get<GetAllSharedProjectsResult>(`aai/organizations/${ organization }/shared-projects?offset=${ offset }&limit=${ limit }&email=${ email }&project=${ project }`);
  }
}

export const getAllSharedProjectsUseCase = new GetAllSharedProjectsUseCase();
