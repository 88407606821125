import { API, UseCase } from "@ppg/common";
import { getRangeDateParam, getTimezoneParam } from '../../../helpers';
import { IGetSubscribersStructureResult } from '../../../interfaces';

interface IGetOrganizationSubscribersStructureRequest {
  organization: string;
  from: string;
  to: string;
  timezone: string;
}

export class GetOrganizationSubscribersStructureUseCase extends UseCase<IGetOrganizationSubscribersStructureRequest, IGetSubscribersStructureResult> {
  protected async execute({ organization, from, to, timezone }: IGetOrganizationSubscribersStructureRequest) {
    const endpoint = `statistics/organizations/${ organization }/subscribers/structure`
      + getRangeDateParam(from, to)+ getTimezoneParam(timezone);

    return API.HTTP.get<IGetSubscribersStructureResult>(endpoint);
  }
}

