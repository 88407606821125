import { API, UseCase } from "@ppg/common";
import { getRangeDateParam } from '../../../helpers';
import { IGetSubscribersLocalisationResult } from '../../../interfaces';

interface IGetSubscribersLocalisationRequest {
  from: string,
  to: string
}

export class GetProjectSubscribersLocalisationUseCase extends UseCase<IGetSubscribersLocalisationRequest, IGetSubscribersLocalisationResult> {
  protected async execute({ from, to }: IGetSubscribersLocalisationRequest) {
    const endpoint = `statistics/v2/projects/${ API.HTTP.currentProjectId }/subscribers/dashboard/localisation`
      + getRangeDateParam(from, to);

    return API.HTTP.get<IGetSubscribersLocalisationResult>(endpoint);
  }
}

export const getProjectSubscribersLocalisationUseCase = new GetProjectSubscribersLocalisationUseCase();
