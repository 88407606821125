import { API, UseCase } from '@ppg/common';

interface IRemoveCard {
  organization: string;
  id: string;
}

export class RemoveCardUseCase extends UseCase<IRemoveCard, void> {
  protected async execute({ organization, id }) {
    return API.HTTP.delete<void>(`acc/organizations/${ organization }/payments/cards/${ id }`)
  }
}

export const removeCardUseCase = new RemoveCardUseCase();
