import { API, UseCase } from "@ppg/common";

interface IGetOrganizationMobileFeaturesEnabled {
  organization: string,
}

export interface IGetActiveTriggeredCampaignsCountResult {
  hasMobileSettingsEnabled: boolean;
}

export class GetOrganizationMobileFeaturesEnabled extends UseCase<IGetOrganizationMobileFeaturesEnabled, IGetActiveTriggeredCampaignsCountResult> {
  protected async execute({ organization }: IGetOrganizationMobileFeaturesEnabled) {
    return API.HTTP.get<IGetActiveTriggeredCampaignsCountResult>(`ah/organizations/${ organization }/settings/has-mobile`);
  }
}


