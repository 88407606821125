import { property, PropertyHandler } from '@ppg/common';
import {
  createPushCappingUseCase,
  readPushCappingUseCase,
  updatePushCappingUseCase
} from '../../useCases/core/capping/CreateProjectPushCappingUseCase';
import { action, computed } from 'mobx';

export interface IProjectPushCapping {
  sentCappingEnabled: boolean;
  sentCappingLimit: number;
  deliveredCappingEnabled: boolean;
  deliveredCappingLimit: number;
}

export class ProjectPushCapping extends PropertyHandler implements IProjectPushCapping {
  @property() public sentCappingEnabled: boolean = false;
  @property() public sentCappingLimit: number = 10;
  @property() public deliveredCappingEnabled: boolean = false;
  @property() public deliveredCappingLimit: number = 15;
  @property() public hasPushCapping: boolean = false;

  @action
  public async fetchProjectPushCapping(): Promise<void> {
    const { capping } = await readPushCappingUseCase.exec();
    this.hasPushCapping = capping !== null;
    if (this.hasPushCapping) {
      this.deliveredCappingEnabled = capping.delivered.enabled;
      this.deliveredCappingLimit = capping.delivered.limit;
      this.sentCappingEnabled = capping.sent.enabled;
      this.sentCappingLimit = capping.sent.limit;
    }
  }

  @computed
  public get hasPushCappingEnabled(): boolean {
    if (!this.hasPushCapping) {
      return false;
    }
    return this.sentCappingEnabled || this.deliveredCappingEnabled;
  }

  public async save(): Promise<void> {
    const body = {
      sentCappingEnabled: this.sentCappingEnabled,
      sentCappingLimit: this.sentCappingLimit,
      deliveredCappingEnabled: this.deliveredCappingEnabled,
      deliveredCappingLimit: this.deliveredCappingLimit,
    }

    this.hasPushCapping
      ? updatePushCappingUseCase.exec(body)
      : createPushCappingUseCase.exec(body)
  }

  public setDefaultValues() {
    this.sentCappingEnabled = false;
    this.sentCappingLimit = 10;
    this.deliveredCappingEnabled = false;
    this.deliveredCappingLimit = 15;
    this.hasPushCapping = false;
  }
}
