import { API, UseCase } from '@ppg/common';

interface IAcceptChannel {
  token: string;
}

export class AcceptChannelUseCase extends UseCase<IAcceptChannel, void> {
  protected async execute({ token }) {
    return API.HTTP.post<void>(`notification/channels/accept/${ token }`);
  }
}

export const acceptChannelUseCase = new AcceptChannelUseCase();
