import * as React from 'react';
import { Link } from 'react-router-dom';
import AppLogoShort from '../../assets/images/app-logo-short.svg';
import AppLogo from '../../assets/images/app-logo.svg';
import AppLogoLite from '../../assets/images/app-logo-lite.png'
import { observer } from 'mobx-react';
import { PPGLite, PPGLiteRole } from '../PPGLite/PPGLite';

interface INavBarLogo {
  hideNavigation: boolean;
  toggleNav: () => void;
  isDeviceSmall?: boolean;
  showShortLogo?: boolean;
}

@observer
export class NavBarLogo extends React.Component<INavBarLogo> {
  public render() {
    const { hideNavigation, toggleNav, isDeviceSmall, showShortLogo } = this.props;

    return (
      <div className="float-left side-nav-wrapper">
        <div className={ `navigation-title ${ hideNavigation ? 'nav-hidden' : '' }` }>
          {
            !!isDeviceSmall
              ? showShortLogo
              ? <Link to="/" className="logo">
                <img className="short" src={ AppLogoShort }/>
              </Link>

              : <div className={ 'hamburger-menu-wrapper' }
                     aria-label='Open the menu'
                     onClick={ () => toggleNav() }>
                <div className={ `mobile-menu-toggle js-toggle-menu hamburger-menu ${ hideNavigation ? '' : 'open' }` }>
                  <span className="menu-item"/>
                  <span className="menu-item"/>
                  <span className="menu-item"/>
                </div>
              </div>
              : <Link to="/" className="logo">
                <PPGLite role={PPGLiteRole.DISPLAY}><img className="full" alt="PushPushGo" src={ AppLogoLite }/></PPGLite>
                <PPGLite role={PPGLiteRole.HIDE}><img className="full" alt="PushPushGo" src={ AppLogo }/></PPGLite>
              </Link> }
        </div>
      </div>
    );
  }
}
