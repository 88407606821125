import { API, UseCase } from '@ppg/common';


interface IDeleteExportCommand {
  projectID: string;
  exportID: number;
}

export class DeleteExportUseCase extends UseCase<IDeleteExportCommand, void> {
  protected async execute({ projectID, exportID }) {
    return API.HTTP.delete<void>(`core/project/${ projectID }/exports/${ exportID }`);
  }
}

export const deleteExportUseCase = new DeleteExportUseCase();
