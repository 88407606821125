import * as React from 'react';
import { Link } from 'react-router-dom';
import { t } from '../../../base/helpers';
import PPGLogo from '../../../assets/images/ppg-logo.svg';
import CheckoutForm from './CheckoutForm';
import { PaymentsStore } from '../../../stores/payment/PaymentsStore';
import { inject, observer } from 'mobx-react';
import { PaymentsRoutesTypes } from '../../../routes/paymentsRoutes';

interface ICheckoutPage {
  paymentsStore?: PaymentsStore;
}

@inject('paymentsStore')
@observer
export class CheckoutPage extends React.Component<ICheckoutPage, {}> {

  public componentWillUnmount() {
    this.props.paymentsStore.clearAndRefetch();
  }

  public render() {
    const { transaction } = this.props.paymentsStore;
    const { invoices } = transaction;

    return <div className='container'>
      <div className='page-background'>
        <div className="go-to-app">
          <span className="icon-arrow-left"/>
          <Link to={ PaymentsRoutesTypes.INVOICES_LIST } className='go-to-app-button'>{ t('Return to application') }</Link>
        </div>

        <div className='checkout-page-wrapper'>
          <img src={ PPGLogo } alt="PushPushGo" className='ppg-logo'/>
          <div className="form-wrapper">


            <div className="panel-column--left">
              <h1>{ t('Summary') }</h1>

              <div className="selected-invoices">
                { invoices.length > 0 && invoices.map(invoice => {
                  return <div key={ invoice.id } className='summary-selected-invoice'>
                    <span>{ invoice.number }</span>
                    <span>{ invoice.amount / 100 } { invoice.currency }</span>
                  </div>;
                }) }
              </div>

              <div className="summary-total">
                <span>{ t('Total') }:</span>
                <span>{ transaction.amount / 100 } { transaction.currency }</span>
              </div>

            </div>

            <div className="column-line"/>

            <div className="panel-column--right">
              <CheckoutForm/>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}
