import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { IUserNavigationTab } from './UserNavigationBar';
import { UserNavigationBadge } from './UserNavigationBadge';
import { projectStore } from '../../../../stores/index';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { t } from '../../../../base/helpers';

interface IUserNavigationItemProps {
  item: IUserNavigationTab;
  isFirst?: boolean;
  showTitleOnMobile?: boolean;
}

@observer
export class UserNavigationItem extends React.Component<IUserNavigationItemProps, {}> {
  @observable public isMobile: boolean = false;

  public componentDidMount(): void {
    this.checkDeviceWidth();
    window.addEventListener('resize', this.checkDeviceWidth);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.checkDeviceWidth);
  }

  public checkDeviceWidth = (): void => {
    this.isMobile = window.innerWidth < 721;
  };

  private checkTabAccessibility(tab: IUserNavigationTab): boolean {
    switch (tab.title.toLowerCase()) {
      case 'payments':
        return projectStore.userOwnedProjects.length <= 0;
      default:
        return false;
    }
  }

  private get showTitle(): boolean {
    return !this.isMobile || this.props.showTitleOnMobile;
  }

  public render() {
    const { item, isFirst } = this.props;
    return <NavLink to={ item.linkTo } className={ `account-navigation-item ${ this.checkTabAccessibility(item) && 'hide' }` } activeClassName={ isFirst ? 'active-first' : 'active' }>
      { item.icon && <span className={ `account-navigation-item-icon icon-${ item.icon }` }/> }
      { this.showTitle && <span className={ 'account-navigation-item-title' }> { t(`${ item.title }`) }</span> }
      <UserNavigationBadge item={ item }/>
    </NavLink>;
  }
}

/**Translations
 * t('API Keys')
 * t('Shared projects')
 * t('Account')
 * t('Notifications')
 * t('Settings')`
 * t('Payments')
 * t('Invoice details')
 */
