import { API, UseCase } from '@ppg/common';

interface IGenerateConfirmationTokenForDeleteUseCase {
}

export class GenerateConfirmationTokenForDeleteUseCase extends UseCase<IGenerateConfirmationTokenForDeleteUseCase, void> {
  protected async execute({ email }) {
    await API.HTTP.post<void>('aai/user/delete', { email });
  }
}

export const generateConfirmationTokenForDeleteUseCase = new GenerateConfirmationTokenForDeleteUseCase();
