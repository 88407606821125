import { UseCase, API } from '@ppg/common';

interface RegisterToPPGLiteRequest {
  email: string;
  password: string;
  repeatedPassword: string;
  organizationName: string;
  acceptRules: boolean;
  code: string
  offerPlan: string;
  language: string;
  privacyPolicyCheckDate: string;
  termsOfAgreementCheckDate: string;
}

interface RegisterToPPGLitResult {
  token: string;
}

export class RegisterToPPGLiteUseCase extends UseCase<RegisterToPPGLiteRequest, RegisterToPPGLitResult> {
  protected async execute( body ) {
    return API.HTTP.post<RegisterToPPGLitResult>('aai/user/register/ppg-lite',
      { ...body });
  }
}

export const registerToPPGLiteUseCase = new RegisterToPPGLiteUseCase();
