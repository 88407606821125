export enum FormType {
  DEFAULT = 'default',
  TOP = 'top',
  CUSTOM = 'custom',
  TINY = 'tiny'
}

export enum AnimationType {
  SUBTLE_APPEAR = '$subtleAppear',
  SLIDE_TOP = '$slideTop',
  SLIDE_BOTTOM = '$slideBottom',
  JUMP_TOP = '$jumpTop',
  JUMP_BOTTOM = '$jumpBottom',
  NONE = 'none',
}

export enum AnimationTopType {
  SUBTLE_APPEAR = '$subtleAppear',
  SLIDE_TOP = '$top-slideTop',
  SLIDE_BOTTOM = '$top-slideBottom',
  JUMP_TOP = '$top-jumpTop',
  JUMP_BOTTOM = '$top-jumpBottom',
  NONE = 'none',
}

export const AnimationFromTop = {
  [AnimationTopType.SUBTLE_APPEAR]: AnimationType.SUBTLE_APPEAR,
  [AnimationTopType.SLIDE_TOP]: AnimationType.SLIDE_TOP,
  [AnimationTopType.SLIDE_BOTTOM]: AnimationType.SLIDE_BOTTOM,
  [AnimationTopType.JUMP_TOP]: AnimationType.JUMP_TOP,
  [AnimationTopType.JUMP_BOTTOM]: AnimationType.JUMP_BOTTOM,
  [AnimationTopType.NONE]: AnimationType.NONE,
}

export const AnimationFromBase = {
  [AnimationType.SUBTLE_APPEAR]: AnimationTopType.SUBTLE_APPEAR,
  [AnimationType.SLIDE_TOP]: AnimationTopType.SLIDE_TOP,
  [AnimationType.SLIDE_BOTTOM]: AnimationTopType.SLIDE_BOTTOM,
  [AnimationType.JUMP_TOP]: AnimationTopType.JUMP_TOP,
  [AnimationType.JUMP_BOTTOM]: AnimationTopType.JUMP_BOTTOM,
  [AnimationType.NONE]: AnimationTopType.NONE,
}

export enum ElementIdType {
  BACKDROP = 'backdrop',
  FORM = 'form',
  ICON = 'icon',
  SVG = '',
  PICTURE = 'picture',
  CONTENT = 'content',
  HEADER = 'header',
  DESCRIPTION = 'description',
  MULTICHANNEL = 'multichannel',
  BUTTONS = 'buttons',
  DENY = 'deny',
  ALLOW = 'allow',
  POWERED_BY = 'poweredByPPG',
  POWERED_BY_TEXT = 'poweredByPPGText',
  CLOSE = 'close',
}
