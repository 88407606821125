export interface IFunctionalColors {
  white: string,
  backgroundGrey: string,
  border: string,
  text: string,
  textLight: string,
  background: string,
  gold: string,
}

export const functional: IFunctionalColors = {
  white: '#fff',
  backgroundGrey: '#f1f1f1',
  border: '#dedede',
  text: '#585858',
  textLight: '#afafaf',
  background: '#efefef',
  gold: '#b1a474',
};
