import { UseCase, API } from '@ppg/common';
import { IUpdatePopupRules } from '../../interfaces';

export class UpdatePopupRulesUseCase extends UseCase<IUpdatePopupRules, void> {
  protected async execute({ websiteIntegrationId, popupRules }) {
    await API.HTTP.put(`wi/projects/${ API.HTTP.currentProjectId }/website-integrations/${ websiteIntegrationId }/plugins/web-push/popup-rules`, { popupRules })
  }
}

export const updatePopupRulesUseCase = new UpdatePopupRulesUseCase();
