import { ElementIdType } from '../../SubscriptionFormEnums';
import { SubscriptionFormBuilder, customAnimations, poweredProperties } from './index';
import { customStylesMap, customRtlStylesMap, SubscriptionFormLayer } from './index';
import { svgPath1, svgPath2, svgPath3, svgProperties } from "./customFormStyles";

export class CustomFormBuilder extends SubscriptionFormBuilder {

  public createLayer(type: string, id: string) {
    const stylesMap = this.formProperties.isDirectionRtl ? customRtlStylesMap : customStylesMap;
    return new SubscriptionFormLayer(type, id, this.formProperties.color, stylesMap, this.formProperties.picture);
  }

  public getTemplate = () => [
    this.createLayer('div', ElementIdType.BACKDROP)
      .withAnimation(customAnimations),
    this.createLayer('div', ElementIdType.FORM)
      .withChild(
        this.createLayer('div', ElementIdType.PICTURE)
          .withDefaultPicture())
      .withChild(
        this.createLayer('div', ElementIdType.ICON)
          .withChild(
            this.createLayer('svg', ElementIdType.SVG)
              .withProperties(svgProperties)
              .withChild(
                this.createLayer('title', ElementIdType.SVG)
                  .withText('bell-native')
              )
              .withChild(
                this.createLayer('path', ElementIdType.SVG)
                  .withProperties(svgPath1)
              )
              .withChild(
                this.createLayer('path', ElementIdType.SVG)
                  .withProperties(svgPath2)
              )
              .withChild(
                this.createLayer('path', ElementIdType.SVG)
                  .withProperties(svgPath3)
              )
          )
      )
      .withChild(
        this.createLayer('div', ElementIdType.CONTENT)
          .withChild(
            this.createLayer('div', ElementIdType.HEADER)
              .withText(this.header)
          )
          .withChild(
            this.createLayer('div', ElementIdType.DESCRIPTION)
              .withText(this.description)
          )
          .withChild(
            this.createLayer('input', ElementIdType.MULTICHANNEL)
              .withProperties({ placeholder: 'Your e-mail' })
          )
          .withChild(
            this.createLayer('div', ElementIdType.BUTTONS)
              .withChild(
                this.createLayer('button', ElementIdType.DENY)
                  .withText(this.formProperties.denyText)
                  .withDefaultColor()
              )
              .withChild(
                this.createLayer('button', ElementIdType.ALLOW)
                  .withText(this.formProperties.allowText)
                  .withDefaultBackgroundColor()
              )
          )
          .withChild(
            this.createLayer('div', ElementIdType.POWERED_BY)
              .withChild(
                this.createLayer('a', ElementIdType.POWERED_BY_TEXT)
                  .withText('Powered by PushPushGo')
                  .withProperties(poweredProperties)
              )
          )
      )
      .withChild(
        this.createLayer('a', ElementIdType.CLOSE)
      )
  ]
}
