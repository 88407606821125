import { apiManager } from '../base';
import { ISelectOption } from '@ppg/styled';
import * as lodash from 'lodash';
import { observable } from 'mobx';

interface ICountry {
  name: string;
  code: string;
  ue: boolean;
  block: boolean;
}

export class CountryStore {
  @observable
  private countries: ICountry[] = [];

  public async fetchCountries(): Promise<void> {
    if (this.countries.length === 0) {
      const { data } = await apiManager.get<ICountry[]>('/country');

      this.countries = data;
    }
  }

  public getCountriesListAsOptions(): ISelectOption[] {
    const countries = this.countries
      .filter(country => !country.block || country.ue)
      .map(({ code, name }) => ({ value: code, name: `${ name } (${ code === 'pl' ? 'PLN' : 'EUR' })` }));

    return lodash.sortBy(countries, country => country.name)
  }

  public getCountriesListAsVATOptions(): ISelectOption[] {
    const countries = this.countries
      .filter(country => !country.block)
      .map(({ code, name }) => ({ value: code, name: `${ name } (${ code === 'pl' ? 'VAT' : 'REVERSE CHARGE' })` }));

    return lodash.sortBy(countries, country => country.name);
  }
}
