import * as React from 'react';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { t } from '../../../../base/helpers';
import { EmptyDataComponent, ISelectOption, LineLoader, Select } from '@ppg/styled';
import { isArrayEmpty } from '@ppg/common';
import * as Styled from './ProjectRank.style'
import { ProjectRankItem } from './ProjectsRankItem';
import { organizationDashboardStore } from '../../../../stores';
import { IProjectRankItem } from '../../../../useCases/statistics/organization/campaigns/GetComparatorProjectRankUseCase';
import { ChartLoader } from '@ppg/styled';
import { ProjectRankOptionsType } from '../../../../stores/organizationDashboard/ProjectComparisonStore';

interface IAutomationRankProps {
  projects: IOrganizationProjectItem[];

  fetchProjectRank(): Promise<void>;
}

export interface IOrganizationProjectItem extends IProjectRankItem {
  name: string,
  url: string,
}

const sortSelectOptions: ISelectOption[] = [
  { name: t('organization/project-comparison::Subscribers'), value: ProjectRankOptionsType.SUBSCRIBERS },
  { name: t('organization/project-comparison::Subscribed'), value: ProjectRankOptionsType.SUBSCRIBED },
  { name: t('organization/project-comparison::Clicked'), value: ProjectRankOptionsType.CLICKED },
  { name: t('organization/project-comparison::Number of campaigns'), value: ProjectRankOptionsType.CAMPAIGNS_NUMBER },
  { name: t('organization/project-comparison::Campaign CTR'), value: ProjectRankOptionsType.CAMPAIGN_CTR },
  { name: t('organization/project-comparison::Automation CTR'), value: ProjectRankOptionsType.AUTOMATION_CTR }
];

export const ProjectsRank = observer(({ projects }: IAutomationRankProps) => {
  const [isLoading, setLoader] = useState<boolean>(true)
  const [initialLoader, setInitialLoader] = useState<boolean>(false)
  const { projectComparisonStore, platformChangeFactors, rangeChangeFactors, organizationHasMobileFeaturesEnabled, bothPlatformsEnabled, isPlatformSelected } = organizationDashboardStore;
  const { fetchProjectRank } = projectComparisonStore;

  useEffect(() => {
    setLoader(true)
    fetchProjectRank()
      .then(() => {
        setLoader(false)
        setInitialLoader(true)
      });
  }, [rangeChangeFactors, platformChangeFactors])

  if (!initialLoader && isLoading) {
    return <Styled.ProjectRankInitialLoaderContainer>
      <ChartLoader/>
    </Styled.ProjectRankInitialLoaderContainer>
  }

  if (!isPlatformSelected() || isArrayEmpty(projects)) {
    return <>
      <h3 className={ 'm10b' }>{ t('organization/project-comparison::Project rank') }</h3>
      <EmptyDataComponent buttonContent={ t('organization/project-comparison::Create project') }
                          icon={ 'projects' }
                          path={ '/project/new' }
                          content={ t('organization/project-comparison::Sorry, we don\'t have data to show for the filters you\'ve chosen') }
                          isLoading={ isLoading }/>
    </>
  }

  const showAutomationCtrZero = organizationHasMobileFeaturesEnabled && !bothPlatformsEnabled;

  return <Styled.ProjectRank>
    { isLoading && <LineLoader/> }

    <Styled.ProjectRankHeader>
      <Styled.ProjectRankTitle>
        <span className="icon-winner-cup"/>
        { t('organization/project-comparison::Project rank') }
      </Styled.ProjectRankTitle>
      <Styled.ProjectSelectWrapper>
        <Styled.ProjectSelectLabel>{ t('Sort by') }</Styled.ProjectSelectLabel>
        <Select property={ projectComparisonStore.getProperty('projectRankSelect') }
                options={ sortSelectOptions }/>
      </Styled.ProjectSelectWrapper>
    </Styled.ProjectRankHeader>


    <Styled.ProjectRankTable>
      <thead>
      <tr>
        <th/>
        <th>{ t('organization/project-comparison::Subscribers') }</th>
        <th>{ t('organization/project-comparison::Subscribed') }</th>
        <th>{ t('organization/project-comparison::Number of campaigns') }</th>
        <th>{ t('organization/project-comparison::Clicked') }</th>
        <th>{ t('organization/project-comparison::Campaign CTR') }</th>
        <th>{ t('organization/project-comparison::Automation CTR') }</th>
      </tr>
      </thead>
      <tbody>
      { projects.map((project, idx) => <ProjectRankItem key={ idx }
                                                        showAutomationCtrZero={ showAutomationCtrZero }
                                                        project={ project }/>) }
      </tbody>
    </Styled.ProjectRankTable>
  </Styled.ProjectRank>;
});