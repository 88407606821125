import { UseCase, API } from '@ppg/common';


export class CheckTokenAndProlongateUseCase extends UseCase<null, void> {
  protected async execute() {
    await API.HTTP.post('aai/check-expiration');
  }
}

export const checkTokenAndProlongateUseCase = new CheckTokenAndProlongateUseCase();

checkTokenAndProlongateUseCase.disableGlobalErrorHandler();
