import cs from 'classnames';
import * as lodash from 'lodash';
import * as React from 'react';
import { canCompileTest, t } from '../../base/helpers';
import { Render } from '../Render/Render';
import { AbstractFiled } from './AbstractFiled';
import { IInputFieldProps, IInputFieldState } from './IInputFieldProps';
import { Picker } from 'emoji-mart';
import { Help } from '@ppg/styled';
import { flagEmojiReg } from '@ppg/common';

export class InputField extends AbstractFiled<IInputFieldProps, IInputFieldState> {
  private emojiPicker;
  private emojiButton;

  constructor(props) {
    super(props);
    this.emojiPicker = (React as any).createRef();
    this.emojiButton = (React as any).createRef();
    this.handleClosePicker = this.handleClosePicker.bind(this);
    this.state = { showPicker: false, caretPosition: 0 }
  }

  private getStartCaretPosition() {
    const { attribute } = this.props;
    const startPosition = attribute && attribute.getValue() ? attribute.getValue().toString().length : 0;
    this.setState({ caretPosition: startPosition });
  }

  public toggleShowPicker = () => {
    this.setState({ showPicker: this.state.showPicker !== true });
  };

  public setClosePicker = () => {
    this.setState({ showPicker: false });
  }

  private isCharactersLimitReached():boolean {
    return this.props.attribute.getValue().length > this.props.charactersLimit;
  }

  public componentDidMount() {
    this.getStartCaretPosition();
    document.addEventListener('click', this.handleClosePicker);
  }

  public componentWillUnmount() {
    document.removeEventListener('click', this.handleClosePicker);
  }

  public handleClosePicker = (event) => {
    const emojiButtonRef = this.emojiButton && this.emojiButton.current;
    const emojiPickerRef = this.emojiPicker && this.emojiPicker.current;
    if (emojiPickerRef && !this.emojiPicker.current.contains(event.target) && (event.target !== emojiButtonRef)) {
      this.setClosePicker();
    }
  }

  public addEmoji(e) {
    const { attribute } = this.props;
    const message = attribute.getValue();
    const emoji = e.native;
    const index = this.state.caretPosition;
    const messageWithEmoji = [message.slice(0, index), emoji, message.slice(index)].join('');
    attribute.setValue(messageWithEmoji);
  }

  private onChange(event: React.FormEvent<HTMLInputElement>) {
    this.setState({ caretPosition: event.currentTarget.selectionStart });
    this.props.attribute.setValue(event.currentTarget.value);
    lodash.invoke(this.props, 'onChange', event);
  }

  private getCurrentCaretPosition(event: React.FormEvent<HTMLInputElement>) {
    this.setState({ caretPosition: event.currentTarget.selectionStart });
  }

  private canCompile() {
    return this.props.scope && canCompileTest(this.props.attribute.getValue());
  }

  private isFlagEmojiEntered():boolean {
    return flagEmojiReg.test(this.props.attribute.getValue())
  }

  public render() {
    const { attribute, label, placeholder, type, step, emoji, pattern, min, max, disabled, onBlur, onKeyPress, inputId, onFocus, scope, isDirectionRtl, emojiPosition, characterCounter, charactersLimit } = this.props;

    const inputClasses = [
      'input-wrapper',
      !!isDirectionRtl ? 'direction--rtl' : '',
      !!emoji ? 'emoji-panel' : ''
    ];

    return (
      <div className={ cs(inputClasses, this.getClassName()) }>
        <div>
          <label htmlFor={ inputId || this.inputId }>{ label }</label>
          <input type={ type }
                 onKeyUp={ (e) => this.getCurrentCaretPosition(e) }
                 id={ inputId || this.inputId }
                 value={ attribute.getValue() || '' }
                 onChange={ (e) => this.onChange(e) }
                 onBlur={ onBlur }
                 onFocus={ onFocus }
                 onKeyPress={ onKeyPress }
                 onClick={ (e) => this.getCurrentCaretPosition(e) }
                 placeholder={ placeholder }
                 disabled={ disabled }
                 step={ step }
                 pattern={ pattern }
                 min={ min }
                 max={ max }/>

          { emoji && <span className={ `emoji-opener ${ this.state.showPicker ? 'active' : '' }` }
                           ref={ this.emojiButton }
                           onClick={ this.toggleShowPicker }/> }
          { emoji && this.state.showPicker && <div ref={ this.emojiPicker } className={ `emoji-position-${ emojiPosition ? emojiPosition : 'bottom' }` }>
            <Picker title={ "" }
                    emoji={ "" }
                    set={ 'facebook' }
                    style={ { width: '320px' } }
                    onSelect={ (e) => this.addEmoji(e) }/>
          </div> }

        </div>
        { this.isFlagEmojiEntered() && <span className={'flag-icon-warning text-red m10l m15b'}>
          {t('Be careful! Windows users will not be able to   see the flag icon')}
          <Help content={t('Microsoft doesn\'t support any country flags on Windows, instead showing the two-letter country codes')} />
        </span>}
        { characterCounter && <span className={`character-counter ${this.isCharactersLimitReached() && 'text-red'}`}>{attribute.getValue().length} / { charactersLimit } </span>}
        { this.canCompile() && <span className="m10l m15b"><b>{ t('Preview compiled: ') }</b><i><Render silent={ true } scope={ scope } fallback={ "" }>{ attribute.getValue() }</Render></i></span> }
        { this.renderErrors() }
        { this.renderWarnings() }
      </div>
    );
  }
}
