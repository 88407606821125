import { IMap, IConstructor } from '../interfaces';
import { Model as BackboneModel } from 'backbone';
import * as lodash from 'lodash';
import { canCompileTest, t, capitalize } from './helpers';
import { Nunjucks } from "../services/Nunjucks";
import dayjs from 'dayjs';

interface IValidationRange {
  min: number;
  max: number;
  nunjucks?: boolean;
}

interface ISafariCollapse {
  length: number;
  warning: boolean;
}

interface IRegExpPattern {
  pattern: RegExp
}

export abstract class AbstractValidator {
  constructor(private model: BackboneModel, private propertyName: string, protected options: IMap<any>) {
    this.options = this.options || [];
  }

  public getErrorMessage(): string {
    if (this.options['message']) {
      return this.options['message'];
    }

    return t('Property "%{label}" is invalid %{what}', {
      label: this.getPropertyLabel(),
      what: this.getValidatorName(),
    });
  }

  public getWarningMessage(): string {
    if (this.options['warning'] && this.options['message']) {
      return this.options['message'];
    }

    return t('Property "%{label}" is invalid %{what}', {
      label: this.getPropertyLabel(),
      what: this.getValidatorName(),
    });
  }

  public getPropertyName(): string {
    return this.propertyName;
  }

  public getPropertyLabel(): string {
    return this.options['label'] ? this.options['label']() : capitalize(this.propertyName);
  }

  public isWarning(): string {
    return this.options['warning'];
  }

  public getModel(): BackboneModel {
    return this.model;
  }

  get value() {
    return this.model.get(this.propertyName);
  }

  public abstract validate(): boolean;

  public abstract getValidatorName(): string
}

export class RequiredValidator extends AbstractValidator {
  public validate(): boolean {
    if (Array.isArray(this.value)) {
      return this.value.length > 0;

    }
    return this.value !== null && this.value !== undefined && this.value !== '';
  }

  public getErrorMessage() {
    return t('%{fieldName} is required', { fieldName: this.getPropertyLabel() });
  }

  public getValidatorName() {
    return `required`;
  }
}

export class NumberValidator extends AbstractValidator {
  public validate(): boolean {
    if (this.value === undefined) {
      return true;
    }

    return lodash.isNumber(this.value);
  }

  public getErrorMessage() {
    return t(`%{fieldName} must be a number`, { fieldName: this.getPropertyLabel() });
  }

  public getValidatorName() {
    return `number`;
  }
}

export class RangeValidator extends AbstractValidator {
  public options: IValidationRange;

  public validate(): boolean {
    if (this.value === undefined) {
      return true;
    }

    return lodash.isNumber(this.value) &&
      this.value >= this.options.min &&
      this.value <= this.options.max;
  }

  public getErrorMessage() {
    if (this.value > this.options.max) {
      return t(`%{fieldName} must be lesser then %{max}`, { fieldName: this.getPropertyLabel(), max: this.options.max });
    }

    if (this.value < this.options.min) {
      return t(`%{fieldName} must be greater then %{min}`, { fieldName: this.getPropertyLabel(), min: this.options.min });
    }
  }

  public getValidatorName() {
    return `range`;
  }
}

export class CharacterRangeValidator extends AbstractValidator {
  public options: IValidationRange;

  public validate() {
    if (this.options.nunjucks && canCompileTest(this.value)) {
      return true;
    }

    if (this.value === undefined) {
      return true;
    }

    return lodash.isString(this.value) &&
      this.value.length >= this.options.min &&
      this.value.length <= this.options.max;
  }

  public getErrorMessage() {
    if (this.value.length > this.options.max) {
      return t(`%{fieldName} must be shorter then %{max} characters`, { fieldName: this.getPropertyLabel(), max: this.options.max });
    }

    if (this.value.length < this.options.min) {
      return t(`%{fieldName} must be longer then %{min} characters`, { fieldName: this.getPropertyLabel(), min: this.options.min });
    }
  }

  public getValidatorName() {
    return `characterRange`;
  }
}

export class DateValidator extends AbstractValidator {
  public validate() {
    if (this.value === undefined) {
      return true;
    }

    return dayjs(this.value).isValid();
  }

  public getErrorMessage() {
    return t(`%{fieldName} is not valid date.`, { fieldName: this.getPropertyLabel() });
  }

  public getValidatorName() {
    return `date`;
  }
}

export class MethodValidator extends AbstractValidator {
  public validate(): boolean {
    return lodash.invoke(this.getModel(), this.options['method']) || false;
  }

  public getErrorMessage(): string {
    return this.options['message'] || super.getErrorMessage();
  }

  public getValidatorName() {
    return `method`;
  }
}

export class UrlValidator extends AbstractValidator {
  public static readonly RE: RegExp = /(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

  public validate(): boolean {
    return !!this.value && UrlValidator.RE.test(this.value);
  }

  public getErrorMessage() {
    return t('Invalid url address');
  }

  public getValidatorName(): string {
    return `url`;
  }
}

export class RegExpValidator extends AbstractValidator {

  public validate(): boolean {
    return this.options.pattern.test(this.value);
  }

  public getErrorMessage() {
    return t('Invalid characters please match the pattern' + this.options.pattern.toString());
  }

  public getValidatorName(): string {
    return `regexp`;
  }
}

export class NunjucksUrlValidator extends AbstractValidator {

  public validate(): boolean {
    return !!this.value && canCompileTest(this.value) ? NunjucksValidator.validator(this.value, this) : UrlValidator.RE.test(this.value);
  }

  public getErrorMessage() {
    return t('Invalid url address or bad template pattern');
  }

  public getValidatorName(): string {
    return `regexp`;
  }
}

export class SafariCollapseValidator extends AbstractValidator {
  public options: ISafariCollapse;

  public validate() {
    if (this.value === undefined) {
      return true;
    }

    return lodash.isString(this.value) &&
      this.value.length <= this.options.length;
  }

  public getWarningMessage() {
    if (this.value.length > this.options.length) {
      return t(`Safari users will see only %{length} characters from field %{fieldName}`, { fieldName: this.getPropertyLabel(), length: this.options.length });
    }
  }

  public getValidatorName() {
    return `safariCollapse`;
  }
}

export class EmailValidator extends AbstractValidator {
  public static readonly RE: RegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  public validate(): boolean {
    return !!this.value && EmailValidator.RE.test(this.value);
  }

  public getErrorMessage() {
    return t('Invalid e-mail address');
  }

  public getValidatorName(): string {
    return `email`;
  }
}

export class SelectorValidator extends AbstractValidator {
  public static validator(value: string): boolean {
    let validSelector = true;
    try {
      document.querySelector(value);
    } catch {
      validSelector = false;
    }
    return validSelector;
  }

  public validate(): boolean {
    return (`${ this.value }`.indexOf('window.') === 0) || SelectorValidator.validator(this.value);
  }

  public getErrorMessage() {
    return t(`%{selectorName} is not valid css selector`, { selectorName: this.value ? this.value : 'this' });
  }

  public getValidatorName(): string {
    return `selector`;
  }
}

export class NunjucksValidator extends AbstractValidator {
  public static validator(text: string, params?: any): boolean {
    let rendered = true;
    try {
      Nunjucks.render(text, params || {}, false);
    } catch {
      rendered = false;
    }
    return rendered;
  }

  public validate(): boolean {
    return NunjucksValidator.validator(this.value, this);
  }

  public getErrorMessage() {
    return t('Your input is not rendering correctly, check syntax');
  }

  public getValidatorName() {
    return `nunjucks`;
  }
}

export class CreditCardValidator extends AbstractValidator {
  public static readonly monthReg: RegExp = /(^0?[1-9]$)|(^1[0-2]$)/;
  public static readonly yearReg: RegExp = /(^20[0-9]{2}$)/;
  public static readonly cvcReg: RegExp = /^[0-9]{3,4}$/;
  public errorMessage = '';

  private luhnCheck(val: String) {
    val = val.replace(/[ -]/g, "");
    let sum = 0;
    for (let i = 0; i < val.length; i++) {
      let intVal = parseInt(val.substr(i, 1), 10);
      if (i % 2 === 0) {
        intVal *= 2;
        if (intVal > 9) {
          intVal = 1 + (intVal % 10);
        }
      }
      sum += intVal;
    }
    return (sum % 10) === 0;
  }

  public validate(): boolean {
    if (this.value === undefined) {
      return true;
    }

    switch (this.getPropertyName()) {
      case 'card':
        this.errorMessage = t('This is not valid credit card number');
        return this.luhnCheck(this.value);
      case 'mm':
        this.errorMessage = t('This is not valid month number');
        return CreditCardValidator.monthReg.test(this.value);
      case 'yyyy':
        this.errorMessage = t('This is not valid year');
        return CreditCardValidator.yearReg.test(this.value);
      case 'cvc':
        this.errorMessage = t('This is not valid CVC/CVV code');
        return CreditCardValidator.cvcReg.test(this.value);
    }
  }

  public getErrorMessage() {
    return this.errorMessage;
  }

  public getValidatorName() {
    return `creditCard`;
  }
}

export const defaultValidator: IMap<IConstructor<AbstractValidator>> = {
  required: RequiredValidator,
  number: NumberValidator,
  range: RangeValidator,
  characterRange: CharacterRangeValidator,
  date: DateValidator,
  url: UrlValidator,
  nunjucks: NunjucksValidator,
  url_nunjucks: NunjucksUrlValidator,
  safariCollapse: SafariCollapseValidator,
  creditCard: CreditCardValidator,
  selector: SelectorValidator,
  email: EmailValidator,
  regexp: RegExpValidator,
};
