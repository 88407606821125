import { API, UseCase } from '@ppg/common';

interface IRemoveChannel {
  organization: string;
  channel: string;
}

export class RemoveChannelUseCase extends UseCase<IRemoveChannel, void> {
  protected async execute({ organization, channel }) {
    await API.HTTP.delete<void>(`notification/organizations/${ organization }/channels/${ channel }`)
  }
}

export const removeChannelUseCase = new RemoveChannelUseCase();
