import { API, UseCase } from '@ppg/common';

interface GetCampaignDaysForCampaignCommand {
  projectID: string;
  campaign: string;
  from: Date;
  to: Date;
}

type MinuteDay = number[]; // array of length 1440

interface CampaignDay {
  day: string;
  clicked: MinuteDay;
  delivered: MinuteDay;
}

interface GetCampaignStatsInDateRangeResponse {
  stats: CampaignDay[];
}

export class GetCampaignDaysForCampaignInDateRange extends UseCase<GetCampaignDaysForCampaignCommand, GetCampaignStatsInDateRangeResponse> {
  protected async execute({ projectID, campaign, from, to }: GetCampaignDaysForCampaignCommand) {
    return API.HTTP.post<GetCampaignStatsInDateRangeResponse>(`statistics/projects/${ projectID }/campaign-days/${ campaign }/range`, {
      from: from.toISOString(),
      to: to.toISOString(),
    });
  }
}

export const getCampaignDaysForCampaignInDateRange = new GetCampaignDaysForCampaignInDateRange();
