import { API, UseCase } from '@ppg/common';
import { ICampaignId, ICampaignIdResponse } from '../interfaces';

interface ISendDraftGeolocationPushRequest extends ICampaignId {}
interface ISendDraftGeolocationPushResponse extends ICampaignIdResponse {}

export class SendDraftGeolocationPushUseCase extends UseCase<ISendDraftGeolocationPushRequest, ISendDraftGeolocationPushResponse> {
  protected async execute({ campaignId }: ISendDraftGeolocationPushRequest) {
    return API.HTTP.post<ISendDraftGeolocationPushResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/geolocation/${ campaignId }/accept`);
  }
}

export const sendDraftGeolocationPushUseCase = new SendDraftGeolocationPushUseCase();
