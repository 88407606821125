import { property } from '../base/decorators';
import { Model } from '../base';
import { IKeyValue } from '../interfaces';

export class KeyValue extends Model<IKeyValue, any> implements IKeyValue {
  @property() public key: string;
  @property() public value: string;

  public toScope() {
    return {
      [this.key]: this.value
    };
  }
}