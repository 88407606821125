import * as React from 'react';
import './Authorization.scss';
import { t } from '../../base/helpers';
import { SaveButtonWrapped } from '../../assets/wrapped';
import { inject } from 'mobx-react';
import { UserStore } from '../../stores/user/UserStore';

interface ILoginOnet {
  userStore: UserStore;
}

@inject('userStore')
export class LoginOnet extends React.Component<ILoginOnet> {

  private handleLoginClick = async () => {
    const { authorization } = this.props.userStore;

    await authorization.loginByOauthOnet()
      .then(() => authorization.authenticateOauthUser())
      .then(url => window.location.href = url);
  }

  public render(): JSX.Element | false | null {
    return <div className={ 'login-page-wrapper login-page-wrapper--onet' }>
      <h1>{ t('Login') }</h1>
      <p>{ t('Hi there, nice to see you') } 😊</p>
      <SaveButtonWrapped content={ t('Login with Ring Publishing') }
                         preventToast
                         callback={ this.handleLoginClick }/>
    </div>;
  }
}
