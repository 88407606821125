import { merge, property, TimeUnit } from "@ppg/common";
import { action, computed, observable, reaction } from 'mobx';
import { t } from '../../../base/helpers';
import { SubscriberLocalizationBuckets } from '../../organizationDashboard/SubscriberLocalizationBuckets';
import { ProjectDashboardRelated } from './ProjectDashboardRelated';
import { DisplayType, IProjectDashboardSubscriberStore, ISubscriberCount } from './interfaces';
import {
  getProjectActiveSubscriberHistogramUseCase,
  getProjectActiveSubscribersInRangeResult,
  getProjectActiveSubscribersUseCase,
  getProjectSubscribedActivityUseCase,
  getProjectSubscribersActivityHistogramUseCase,
  getProjectSubscribersAverageLifetimeUseCase,
  getProjectSubscribersLifetimeStructureUseCase,
  getProjectSubscribersLocalisationUseCase,
  getProjectSubscribersStructureUseCase,
} from '../../../useCases/statistics/project';
import { LineChartData } from '../../../components/Charts/LineChart/LineChart';
import dayjs from 'dayjs';
import { FunnelDatum } from '@nivo/funnel/dist/types/types';
import { ILifecycleStructure, ISubscriberActivityHistogramItem } from "../../../useCases/interfaces";

export class ProjectDashboardSubscriberStore extends ProjectDashboardRelated implements IProjectDashboardSubscriberStore {
  public static REDUCED_ITEMS_PER_PAGE: number = 10;

  // Charts
  @observable
  public generalChartData = [];

  @observable
  public subscribersCountChartData = [];

  @observable
  public subscriberLifecycleChartData = [];

  @observable
  public subscriberLocalizationBuckets = new SubscriberLocalizationBuckets();

  @observable
  public subscribersStructureChartData = null;

  // Tables

  @property()
  public limit = ProjectDashboardSubscriberStore.REDUCED_ITEMS_PER_PAGE;

  @property()
  public offset = 0;

  @property()
  public total = 0;

  @observable
  public generalSubscriberActivityTableData = [];

  @property()
  public subscriberActivityOffset = 0;

  @property()
  public activeSubscribersTotal = 0;

  @observable
  public generalActiveSubscribersTableData = [];

  @property()
  public activeSubscribersOffset = 0;

  @property()
  public subscriberActivityTotal = 0;

  @observable
  public subscriberLifecycleTableData = [];

  // Stats

  @observable
  public subscribersAverageLifetime = 0;

  @observable
  public subscribersAverageLifetimeUnit = TimeUnit.MINUTE;

  @observable
  public activeSubscribers = 0;

  @observable
  public iosActiveSubscribers = 0;

  @observable
  public androidActiveSubscribers = 0;

  @observable
  public huaweiActiveSubscribers = 0;

  @observable
  public subscribed = 0;

  @observable
  public unsubscribed = 0;

  //  Loaders

  @observable
  public isSubscribersAverageLifetimeLoading = true;

  @observable
  public isActiveSubscribersLoading = true;

  @observable
  public isSubscribedActivityLoading = true;

  reactOnRangeChange = reaction(
    () => this.rangeChangeFactors,
    () => this.clearOffsets(),
  );

  reactOnTabChange = reaction(
    () => this.activeTab,
    () => this.clearOffsets(),
  );

  @computed
  public get subscribersCountCustomY(): ISubscriberCount | null {
    const countChart = this.subscribersCountChartData.find(d => d.id === t('Count'));
    if (!countChart) {
      return null;
    }

    const y: number[] = countChart.data.map(d => d.y);
    const max = Math.max(...y);
    const min = Math.min(...y);

    let maxMargin = (10 - this.restFromTen(max)) || 10;
    let minMargin = 10 + (max - min);

    const maxValue = max + maxMargin;

    let minValue = (min - minMargin < 0) ? 0 : min - minMargin;

    minValue -= this.restFromTen(minValue);

    return {
      max: maxValue,
      min: minValue || 0,
    };
  }

  private restFromTen(value: number): number {
    return value % 10;
  }

  @action
  public parseSubscriberActivityHistogramToLineChartData = (histogram: ISubscriberActivityHistogramItem[], id: string, from: Date, to: Date): LineChartData => {
    let data = [];
    if (histogram.length === 0) {
      return [{ id, data }];
    }

    for (let date = dayjs(from); date.isBefore(to); date = dayjs(date).add(1, 'days')) {
      let dayDate = date.format('YYYY-MM-DD');
      const dayItem = histogram.find(day => day.day === dayDate);
      data = data.concat({ x: dayDate, y: dayItem?.activity ?? 0 });
    }

    return [{ id, data }];
  };

  @action
  public fetchGeneralSubscriberLineChartData = async (): Promise<void> => {
    const { platform, timezone, from, to } = this.projectRequestBody;
    const { subscribersActivity } = await getProjectSubscribedActivityUseCase.exec({
      platform, from, to, timezone
    });
    const { from: fromDate, to: toDate } = this.requestDateRange;
    const subscribed = this.parseSubscriberActivityHistogramToLineChartData(subscribersActivity.subscribedHistogram, t('Subscribed'), fromDate, toDate);
    const unsubscribed = this.parseSubscriberActivityHistogramToLineChartData(subscribersActivity.unsubscribedHistogram, t('Unsubscribed'), fromDate, toDate);
    this.generalChartData = subscribed.concat(unsubscribed);
  };

  @action
  public fetchGeneralSubscriberCountLineChartData = async (): Promise<void> => {
    const { platform, from, to, timezone } = this.projectRequestBody;
    const { data } = await getProjectActiveSubscribersInRangeResult.exec({
      platform, timezone, from, to
    });
    const { from: fromDate, to: toDate } = this.requestDateRange;
    let countData = this.parseAndAddMissingDays(data, fromDate, toDate, 'x', { y: 0 }, 'y');
    this.subscribersCountChartData = [{ id: t('Count'), data: countData }];
  };

  @action
  public fetchGeneralActiveSubscribersTableData = async (): Promise<void> => {
    const { platform, to, from, timezone } = this.projectRequestBody;
    const { activeSubscribers } = await getProjectActiveSubscriberHistogramUseCase.exec({
      platform,
      from,
      to,
      timezone,
      limit: this.limit,
      offset: this.activeSubscribersOffset
    });

    this.activeSubscribersTotal = activeSubscribers.total;
    this.generalActiveSubscribersTableData = activeSubscribers.histogram;
  };

  @action
  public fetchGeneralSubscriberActivityTableData = async (): Promise<void> => {
    const { platform, timezone, from, to } = this.projectRequestBody;
    const { subscribersActivity } = await getProjectSubscribersActivityHistogramUseCase.exec({
      platform,
      timezone,
      from,
      to,
      limit: this.limit,
      offset: this.subscriberActivityOffset,
    });

    this.subscriberActivityTotal = dayjs(to).diff(from, 'days') + 1;
    const data = merge([...subscribersActivity.subscribedHistogram, ...subscribersActivity.unsubscribedHistogram], 'day');

    if (data.length === 0) {
      this.generalSubscriberActivityTableData = [];
      return;
    }

    const { from: fromDate, to: toDate } = this.calculateListRange(
      this.requestDateRange.from,
      this.requestDateRange.to,
      this.limit,
      this.subscriberActivityOffset,
    );

    let result = [];
    for (let date = dayjs(fromDate); date.isBefore(toDate); date = dayjs(date).add(1, 'days')) {
      let dayDate = date.format('YYYY-MM-DD');
      const dayItem = data.find(day => day.day === dayDate);
      result = result.concat({
        day: dayDate,
        subscribed: dayItem?.subscribed ?? 0,
        unsubscribed: dayItem?.unsubscribed ?? 0
      });
    }

    this.generalSubscriberActivityTableData = result;
  };

  @action
  public fetchSubscriberLifecycle = async (): Promise<void> => {
    const { platform, to, from, timezone } = this.projectRequestBody;
    const { subscribersLifetimeStructure } = await getProjectSubscribersLifetimeStructureUseCase.exec({
      platform, from, to, timezone
    });

    this.subscriberLifecycleChartData = this.parseLifetimeStructureToDatum(subscribersLifetimeStructure, DisplayType.CHART);
    this.subscriberLifecycleTableData = this.parseLifetimeStructureToDatum(subscribersLifetimeStructure, DisplayType.TABLE);
  };

  @action
  public parseLifetimeStructureToDatum = (subscribersLifetimeStructure: ILifecycleStructure[], type: DisplayType): FunnelDatum[] => {
    const MAX_BUCKET = '1440';
    return subscribersLifetimeStructure
      .sort((a, b) => parseInt(a.bucket) - parseInt(b.bucket))
      .map((lifecycleItem, i) => {
        let nextBucket = subscribersLifetimeStructure[i + 1] ? subscribersLifetimeStructure[i + 1].bucket : '';

        let labelDefault = type === DisplayType.CHART
          ? t(`of subscribers live from %{bucket} to %{nextBucket} days`, {
            bucket: lifecycleItem.bucket,
            nextBucket: nextBucket
          })
          : t(`from %{bucket} to %{nextBucket} days`, {
            bucket: lifecycleItem.bucket,
            nextBucket: nextBucket
          });
        let labelOver = type === DisplayType.CHART
          ? t(`of subscribers live over %{bucket} days`, { bucket: lifecycleItem.bucket })
          : t(`over %{bucket} days`, { bucket: lifecycleItem.bucket });
        let labelMax = type === DisplayType.CHART
          ? t('of subscribers live over %{bucket}', { bucket: lifecycleItem.bucket })
          : t('% of subscribers live over %{bucket}', { bucket: lifecycleItem.bucket });

        let fromToBucketLabel = !!nextBucket
          ? labelDefault
          : labelOver;
        const bucketLabel = lifecycleItem.bucket === MAX_BUCKET
          ? labelMax
          : fromToBucketLabel;
        return {
          id: `${ lifecycleItem.bucket }`,
          label: bucketLabel,
          value: lifecycleItem.total
        };
      })
      .filter(lifecycleItem => lifecycleItem.value > 0);
  };

  @action
  public fetchLocalizationChartData = async (): Promise<void> => {
    return this.undifferentiatedPlatforms(this.getLocalizationChartData, this.getProperty('subscriberLocalizationBuckets'), new SubscriberLocalizationBuckets());
  };

  @action
  public getLocalizationChartData = async (): Promise<void> => {
    this.subscriberLocalizationBuckets.isLoading = true;
    const { from, to } = this.projectRequestBody;
    const { averageSubscribersLocalisations } = await getProjectSubscribersLocalisationUseCase.exec({
      from, to
    });
    this.subscriberLocalizationBuckets.setFromLocalization(averageSubscribersLocalisations.localisations);
  };

  @action
  public fetchProjectSubscribersStructure = async (): Promise<void> => {
    if (!this.bothPlatformsEnabled) {
      return this.subscribersStructureChartData = null;
    }

    const { from, to, timezone } = this.projectRequestBody;
    this.subscribersStructureChartData = await getProjectSubscribersStructureUseCase.exec({
      from, to, timezone
    });
  };

  @action
  public fetchSubscribedActivity = async (): Promise<void> => {
    this.isSubscribedActivityLoading = true;

    const { platform, to, timezone, from } = this.projectRequestBody;

    const { subscribersActivity } = await getProjectSubscribedActivityUseCase.exec({
      from,
      to,
      timezone,
      platform
    });

    this.subscribed = subscribersActivity.subscribed;
    this.unsubscribed = subscribersActivity.unsubscribed;
    this.isSubscribedActivityLoading = false;
  };

  @action
  public fetchProjectActiveSubscribers = async (): Promise<void> => {
    this.isActiveSubscribersLoading = true;

    const { platform, timezone } = this.projectRequestBody;

    const {
      activeSubscribers,
      iosActiveSubscribers = 0,
      huaweiActiveSubscribers = 0,
      androidActiveSubscribers = 0
    } = await getProjectActiveSubscribersUseCase.exec({
      platform,
      timezone
    });

    this.activeSubscribers = activeSubscribers;
    this.iosActiveSubscribers = iosActiveSubscribers;
    this.huaweiActiveSubscribers = huaweiActiveSubscribers;
    this.androidActiveSubscribers = androidActiveSubscribers;
    this.isActiveSubscribersLoading = false;
  };

  @action
  public fetchSubscribersAverageLifetime = async (): Promise<void> => {
    this.isSubscribersAverageLifetimeLoading = true;

    const { platform } = this.projectRequestBody;

    const { subscribersAverageLifetime, unit } = await getProjectSubscribersAverageLifetimeUseCase.exec({
      platform
    });

    this.subscribersAverageLifetime = subscribersAverageLifetime;
    this.subscribersAverageLifetimeUnit = unit;
    this.isSubscribersAverageLifetimeLoading = false;
  };

  @action
  public onPlatformChangeHandler = async (): Promise<void> => {
    if (!this.isPlatformSelected) {
      this.clearStats();
      return;
    }

    await Promise.all([
      this.fetchProjectActiveSubscribers(),
      this.fetchSubscribersAverageLifetime()
    ]);
  };

  @action
  public onPlatformAndRangeDateChangeHandler = async (): Promise<void> => {
    if (!this.isPlatformSelected) {
      this.clearStats();
      return;
    }

    await this.fetchSubscribedActivity();
  };

  // Cleaners
  @action
  public cleanLocalizationMapData = (): void => {
    this.subscriberLocalizationBuckets = new SubscriberLocalizationBuckets();
  };

  @action
  public cleanGeneralSubscribersData = (): void => {
    this.generalChartData = [];
    this.subscribersCountChartData = [];
    this.generalSubscriberActivityTableData = [];
  };

  @action
  public cleanLifecycleData = (): void => {
    this.subscriberLifecycleChartData = [];
    this.subscriberLifecycleTableData = [];
  };

  @action
  public clearStats = (): void => {
    this.subscribersAverageLifetime = 0;
    this.activeSubscribers = 0;
    this.iosActiveSubscribers = 0;
    this.androidActiveSubscribers = 0;
    this.huaweiActiveSubscribers = 0;
    this.subscribed = 0;
    this.unsubscribed = 0;
  };

  @action
  public clearOffsets = (): void => {
    this.activeSubscribersOffset = 0;
    this.subscriberActivityOffset = 0;
    this.offset = 0;
  };
}
