import { API, UseCase } from '@ppg/common';
import { IDashboardCampaignsStats, IOrganizationDashboardRequestBody } from '../../../interfaces';

interface IGetCampaignsStatisticsUseCaseRequest extends IOrganizationDashboardRequestBody {
  type: string;
  timezone: string;
}

export interface IGetCampaignsStatisticsUseCaseResult {
  campaignStats: IDashboardCampaignsStats;
}

export class GetCampaignsStatisticsUseCase extends UseCase<IGetCampaignsStatisticsUseCaseRequest, IGetCampaignsStatisticsUseCaseResult> {
  protected async execute({ organization, platform, from, to, type, timezone }: IGetCampaignsStatisticsUseCaseRequest) {
    const platformType = platform ? `/${ platform }` : '';

    return API.HTTP.get<IGetCampaignsStatisticsUseCaseResult>(
      `statistics/organizations/${ organization }/campaigns/statistics/${ type }${ platformType }?from=${ from }&to=${ to }&timezone=${ timezone }`,
    );
  }
}
