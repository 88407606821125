import { API, UseCase } from '@ppg/common';
import { IListQueryOptions } from '../../../interfaces/IListQueryOptions';
import { IListResponse } from '../../../interfaces/IListResponse';
import { ISubscriberDTO } from '../../../modelsMobx/subscriber/interfaces';
import { LabelCountStrategy } from '../../../modelsMobx/Label';

interface ListSubscribersRequest extends IListQueryOptions {
  customId: string;
  from: string;
  to: string;
  sortBy: string;
  includedLabels: string[];
  excludedLabels: string[];
  includedStrategy: LabelCountStrategy;
  excludedStrategy: LabelCountStrategy;
}

interface ListSubscribersResponse extends IListResponse<ISubscriberDTO[]> {}

export class ListSubscribersUseCase extends UseCase<ListSubscribersRequest, ListSubscribersResponse> {
  protected async execute({ offset, limit, customId, from, sortBy, to, includedLabels, excludedLabels, includedStrategy, excludedStrategy }: ListSubscribersRequest) {

    let paramsIncludedLabelsList = new URLSearchParams();

    for (let includedLabel of includedLabels) {
      paramsIncludedLabelsList.append("includedLabels", includedLabel)
    }

    let paramsExcludedLabelsList = new URLSearchParams();

    for (let excludedLabel of excludedLabels) {
      paramsExcludedLabelsList.append("excludedLabels", excludedLabel)
    }

    const paramsIncludedLabels = includedLabels.length !== 0 ? paramsIncludedLabelsList.toString() : 'includedLabels='
    const paramsExcludedLabels = excludedLabels.length !== 0 ? paramsExcludedLabelsList.toString() : 'excludedLabels='

    return API.HTTP.get<ListSubscribersResponse>(`core/projects/${ API.HTTP.currentProjectId }/subscribers?offset=${ offset }&limit=${ limit }&customId=${customId}&from=${from}&sortBy=${sortBy}&to=${to}&includedStrategy=${includedStrategy}&excludedStrategy=${excludedStrategy}&${paramsIncludedLabels}&${paramsExcludedLabels}`);
  }
}

export const listSubscribersUseCase = new ListSubscribersUseCase();
