import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { OfferStore, PlanType } from '../../stores/OfferStore';
import { t } from '../../base/helpers';

interface INavBarTrialDaysLeftProps {
  offerStore?: OfferStore;
}

@inject('offerStore')
@observer
export class NavBarTrialDaysLeft extends React.Component<INavBarTrialDaysLeftProps> {

  private isTrailPeriodFinished = ():boolean => {
    return this.props.offerStore.trailDaysLeft <= 0;
  }

  private isNextPlanSameAsCurrent = ():boolean => {
    const { offerStore } = this.props;
    return offerStore.currentSettlementPolicyPlan.id === offerStore.nextSettlementPolicyPlan.id
  }

  public render() {
    const { offerStore } = this.props;
    const currentPlanName = offerStore.currentSettlementPolicyPlan.name.toUpperCase();

    if(this.isTrailPeriodFinished() || this.isNextPlanSameAsCurrent()) {
      return null;
    }

    if(currentPlanName === PlanType.START_TRIAL || currentPlanName === PlanType.START_PLAN_LITE) {
      return <span className="trial-days-left-wrapper">
         {offerStore.trailDaysLeft} {(t('@lite/navigation::days left to end of trial'))}
      </span>
    }

    return null;
  }
}
