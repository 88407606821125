import { API, UseCase } from "@ppg/common";
import { ISendPushCampaign, ICampaignIdResponse } from '../interfaces';

interface ICreateSegmentedPushDraftRequest extends ISendPushCampaign {}
interface ICreateSegmentedPushDraftResponse extends ICampaignIdResponse {}

export class CreateSegmentedPushDraftUseCase extends UseCase<ICreateSegmentedPushDraftRequest, ICreateSegmentedPushDraftResponse> {
  protected async execute({ message, options }: ICreateSegmentedPushDraftRequest) {
    return API.HTTP.post<ICreateSegmentedPushDraftResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/segmented/draft`, { message, options });
  }
}

export const createSegmentedPushDraftUseCase = new CreateSegmentedPushDraftUseCase();
