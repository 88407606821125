import { API, UseCase } from '@ppg/common';
import { ExportFileState, ExportFileType } from '../../modelsMobx/ExportFile';

export interface ExportResponse {
  id: string;
  type: ExportFileType;
  state: ExportFileState;
  createdAt: Date;
  from: string;
  to: string;
  rangeReference: string;
}

interface ListExportsResult {
  data: ExportResponse[],
  metadata: {
    total: number,
  },
}

interface IListExportsCommand {
  projectID: string;
  offset: number;
  limit: number;
}

export class ListExportsUseCase extends UseCase<IListExportsCommand, ListExportsResult> {
  protected async execute({ projectID, offset, limit }) {
    return API.HTTP.get<ListExportsResult>(`core/project/${ projectID }/exports?offset=${ offset }&limit=${ limit }`);
  }
}

export const listExportsUseCase = new ListExportsUseCase();
