import * as React from 'react';
import { v1 } from 'uuid';
import cs from "classnames";
import { IAttribute } from '../../base';
import { IUtm } from '../../modelsMobx/helpers/Utm';
import { AbstractFiled } from '../common/AbstractFiled';
import {canCompileTest, t} from "../../base/helpers";
import { Render } from '../Render/Render';
import ReactTooltip from 'react-tooltip';
import * as Styled from './RedirectLink.styled'

interface IRedirectLinkProps {
  attribute: IAttribute<string>;
  utm?: IUtm;
  schemas: string[];
  scope?: any;
  onPaste?: Function;
  defaultSchema?: string;

  testId?: string;
  label?: string;
  showGetDataFromUrlBtn: boolean;
  getDataFromUrl?: () => Promise<void>;
  getDataBtnDisabled?: boolean;
}

interface IRedirectLinkState {
  isOpened: boolean;
  wasPasted: boolean;
  url: string;
  schema: schemaTypes;
}

type schemaTypes = 'http' | 'https' | 'app' | string;

export class RedirectLink extends AbstractFiled<IRedirectLinkProps, IRedirectLinkState> {
  private componentID: string = v1();
  public testComponentId = 't-redirectLink';

  public state = {
    isOpened: false,
    wasPasted: false,
    url: '',
    schema: 'https' as schemaTypes
  };

  public componentDidMount() {
    const { defaultSchema } = this.props;
    if (defaultSchema) {
      this.setState({ schema: defaultSchema as schemaTypes });
    }
    this.setUrlAttributes();
  }

  public componentWillReceiveProps(nextProps) {
    if (nextProps.attribute.getValue() !== `${ this.state.schema }://${ this.state.url }`) {
      this.setUrlAttributes();
    }
  }

  public static encodeURIWithNunjucks(url: string) {
    return url
      .replace(/%7B/g, "{")
      .replace(/%7D/g, "}");
  }

  private canCompile() {
    return this.props.scope && canCompileTest(`${ this.state.schema }://${ this.state.url }`);
  }

  private startsWithSchema(url: string): boolean {
    return /^[\w_+-]+:\/\//.test(url);
  }

  private setUrlAttributes() {
    const fullUrl = this.props.attribute.getValue();
    if (this.startsWithSchema(fullUrl)) {
      const [schema, url] = this.splitUrl(fullUrl);
      if (this.props.schemas.indexOf(schema) === -1) {
        console.warn('Schema not supported');
        return;
      }

      this.setState({
        schema: schema as schemaTypes,
        url: RedirectLink.encodeURIWithNunjucks(url),
      });
    }
  }

  public static getUrl(url) {
    return url.split('://')[1];
  }

  private splitUrl(url): [string, string] {
    const [schema, ...rest] = url.split('://');
    return [schema, rest.join('://')];
  }

  private setUrl(newUrl: string, opts: Partial<IRedirectLinkState> = {}, callback: Function = null) {
    const { attribute } = this.props;

    if (this.startsWithSchema(newUrl)) {
      const [schema, url] = this.splitUrl(newUrl);

      if (this.props.schemas.indexOf(schema) === -1) {
        console.warn('Schema is not supported');
        return;
      }

      this.setState({
        schema: schema as schemaTypes,
        url: RedirectLink.encodeURIWithNunjucks(url),
        ...opts as IRedirectLinkState
      }, function () {
        attribute.setValue(`${ this.state.schema }://${ this.state.url }`);
        if (callback) {
          callback();
        }
      });
    } else {
      this.setState({ url: RedirectLink.encodeURIWithNunjucks(newUrl), ...opts as IRedirectLinkState }, function () {
        attribute.setValue(`${ this.state.schema }://${ this.state.url }`);
      });
    }
  }

  private toggleOpen = () => {
    this.setState({ isOpened: !this.state.isOpened });
  };

  public get schemaOptions() {
    return this.props.schemas.map(schema => ({
      value: schema, name: `${schema}://`
    }));
  }

  public addSchemaIfMissing(url: string) {
    if (!this.startsWithSchema(url)) {
      return `${ this.state.schema }://${ url }`;
    } else {
      return url;
    }
  }

  private appendUTMs(url: string, utm: IUtm): string {
    if (canCompileTest(url)) {
      return url;
    }

    const urlWithSchema = this.addSchemaIfMissing(url);

    if (utm.disabled) {
      return url;
    }

    const newUrl = new URL(urlWithSchema);

    if (utm.isMediumOn) {
      if (newUrl.searchParams.has('utm_medium')) {
        newUrl.searchParams.delete('utm_medium');
      }
      newUrl.searchParams.append('utm_medium', utm.medium);
    }

    if (utm.isSourceOn) {
      if (newUrl.searchParams.has('utm_source')) {
        newUrl.searchParams.delete('utm_source');
      }
      newUrl.searchParams.append('utm_source', utm.source);
    }

    if (utm.isCampaignOn) {
      if (newUrl.searchParams.has('utm_campaign')) {
        newUrl.searchParams.delete('utm_campaign');
      }
      newUrl.searchParams.append('utm_campaign', utm.campaign);
    }

    if (utm.customUtms.length > 0) {
      utm.customUtms.forEach(utm => {
        !newUrl.searchParams.has(`utm_${ utm.parameter }`) &&
        newUrl.searchParams.append(`utm_${ utm.parameter }`, utm.value);
      });
    }

    return newUrl.toString();
  }

  public onChangeHandler(event) {
    const { utm, onPaste } = this.props;
    this.setUrl(event.currentTarget.value);
    if (this.state.wasPasted && utm) {
      const linkWithUtms = this.appendUTMs((event.currentTarget.value).trim(), utm);
      this.setUrl(RedirectLink.encodeURIWithNunjucks(linkWithUtms), {
        wasPasted: false
      }, onPaste);
    } else if (this.state.wasPasted && onPaste) {
      this.setUrl((event.currentTarget.value).trim(), { wasPasted: false }, onPaste);
    }
  }

  public validUrl(url: string) {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  }

  private selectSchema(schema) {
    this.setState({ schema: schema.value }, function () {
      this.props.attribute.setValue(`${ this.state.schema }://${ this.state.url }`);
    });
    this.toggleOpen();
  }

  private onPasteHandler() {
    this.setState({ wasPasted: true });
  }

  public render() {

    const { testId, label, scope, getDataBtnDisabled, getDataFromUrl, showGetDataFromUrlBtn } = this.props;
    const inputLabel = label || '';

    const btnFetDataFromUrlDataTip: string = getDataBtnDisabled ? t('no data to get from the url') : null;

    return (
      <Styled.PushRedirectLinkWrapper>
        <div className={ cs('input-wrapper', this.getClassName()) }>
          <div id={ testId || this.testComponentId }>
            {inputLabel && <label htmlFor={ this.componentID }>{ inputLabel }</label>}
            <div className="input-joined-button--left" style={ { width: '100%' } }>
              <div className={ cs(`prefix-select ${ this.state.isOpened && 'opened' }`) }>

                <div className="button prefix-select-button" onClick={ this.toggleOpen }>
                  <span>{ this.state.schema }://</span>
                  <span className="icon-arrow-down size8 m5l"/>
                </div>

                <ul className="prefix-select-list">
                  { this.schemaOptions.map(schema => {
                    return <li key={ schema.name } onClick={ () => this.selectSchema(schema) }>
                      { schema.name }
                    </li>;
                  }) }
                </ul>

              </div>
              <input type="text"
                     id={ this.componentID }
                     onPaste={ () => this.onPasteHandler() }
                     value={ this.state.url || '' }
                     onChange={ e => this.onChangeHandler(e) }/>
            </div>
            { this.canCompile() && <span className="m10l m15b"><b>{ t('Preview compiled: ') }</b><i><Render silent={ true } scope={ scope }
                                                                                                            fallback={ "" }>{ `${ this.state.schema }://${ this.state.url }` }</Render></i></span> }
            { this.renderErrors() }
          </div>
          { this.props.children }
        </div>
        { showGetDataFromUrlBtn && <Styled.BtnGetDataFromUrl onClick={ () => !getDataBtnDisabled && getDataFromUrl() } disabledBtn={getDataBtnDisabled} data-tip={ btnFetDataFromUrlDataTip }>
          <span className="icon-upload"/>
          <span>{ t('get data from url') }</span>
        </Styled.BtnGetDataFromUrl> }

        <ReactTooltip type="light" effect="solid" place="top"/>
      </Styled.PushRedirectLinkWrapper>
    );
  }
}
