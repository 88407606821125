import { UserStore } from './UserStore';
import { observable } from 'mobx';
import { PropertyHandler } from '@ppg/common';

export abstract class UserRelatedStore extends PropertyHandler {
  @observable protected userStore: UserStore;

  constructor(userStore: UserStore) {
    super();
    this.userStore = userStore;
  }
}
