import * as React from 'react';
import { flagEmojiReg } from '@ppg/common';
import { t } from '../../../base/helpers';
import { Help } from '@ppg/styled';
import * as Styled from "./Validators.styled";

interface IFlagWarning {
  value: string;
}

export const FlagWarning = ({ value }: IFlagWarning): JSX.Element => {
  return flagEmojiReg.test(value) && <Styled.WarningWrapper>
    { t('Be careful! Windows users will not be able to   see the flag icon') }
    <Help content={ t('Microsoft doesn\'t support any country flags on Windows, instead showing the two-letter country codes') }/>
  </Styled.WarningWrapper>
};