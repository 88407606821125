import { IAnyModel } from './IAnyModel';
import { SubModelAttribute } from './SubModelAttribute';
import * as lodash from 'lodash';

export class AttributeModel {

  constructor(private model: IAnyModel, private attribute: string) {}

  public set(key: string, value: any): void {
    this.model.set(this.attribute, lodash.extend({}, this.model.get(this.attribute), {
      [key]: value
    }));
  }

  public get(key: string): any {
    return lodash.result(this.model.get(this.attribute), key);
  }

  public getAttribute<TAttribute>(attribute: string): SubModelAttribute<TAttribute> {
    return new SubModelAttribute<TAttribute>(this, attribute);
  }
}
