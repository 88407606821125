import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ProjectListHeader } from './ProjectListHeader';
import { ProjectListItem } from './ProjectListItem';
import { t } from '../../../../base/helpers';
import { ProjectStore } from '../../../../stores/project/ProjectStore';
import { observable } from 'mobx';
import ReactTooltip from 'react-tooltip';

interface IProjectListProps {
  projectStore?: ProjectStore;
}

@inject('projectStore')
@observer
export class ProjectList extends React.Component<IProjectListProps> {
  @observable private searchValue = '';

  public handleSearchProjectChange(event: React.FormEvent<HTMLInputElement>): void {
    this.searchValue = event.currentTarget.value;
  }

  public render() {
    const { projectStore } = this.props;
    const lowerCasedValue = this.searchValue.toLowerCase();

    const anyProjectFound = projectStore.userOwnedProjects.some(project => project.name.toLowerCase().includes(lowerCasedValue));
    const projectsFound = projectStore.userOwnedProjects.filter(project => project.name.toLowerCase().includes(lowerCasedValue));
    const isSearchActive = this.searchValue.length;

    return (
      <>
        <ProjectListHeader
          handleSearchProjectChange={ (event) => this.handleSearchProjectChange(event) }
          value={ this.searchValue }
          hasProjects={ this.props.projectStore.hasProjects }
          hasOwnedProject = { this.props.projectStore.hasOwnedProjects }
        />

        { <table className="project-table">
            { projectsFound.length > 0 && <thead>
            <tr>
              <th>{ t('Name') }</th>
              <th>{ t('Website address') }</th>
              <th>{ t('Project Id') }</th>
              <th/>
            </tr>
            </thead> }
            <tbody>
            { isSearchActive ?
                anyProjectFound
                  ? projectsFound.map(project => <ProjectListItem key={ project.id } project={ project } canDelete={ true }/>)
                  : <div className='empty-project-search-result'>{ t('Sorry, no project name matches what you are looking for') }</div>
                : projectStore.userOwnedProjects.map(project => <ProjectListItem key={ project.id } project={ project } canDelete={ true }/>)
            }
            </tbody>
          </table> }

        { projectStore.sharedProjects.length > 0 && <>
          <div className="row row-center p10t">
            <div className="column">
              <h4 className="form-title">
                { t('Projects shared to you') }:
              </h4>
            </div>
          </div>

          <table className="project-table">
            <thead>
            <tr>
              <th>{ t('Name') }</th>
              <th>{ t('Website address') }</th>
              <th>{ t('Project Id') }</th>
              <th/>
            </tr>
            </thead>
            <tbody>
            { projectStore.sharedProjects.map(project => <ProjectListItem key={ project.id } project={ project } canDelete={ false }/>) }
            </tbody>
          </table>
        </> }

        <ReactTooltip type="light" effect="solid"/>
      </>
    );
  }
}
