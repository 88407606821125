import { UseCase, API } from '@ppg/common';
import { IGetPlugin, ISubscriptionFormResult } from '../interfaces';

export class GetSubscriptionFormPluginUseCase extends UseCase<IGetPlugin, ISubscriptionFormResult> {
  protected async execute({ websiteIntegrationId }: IGetPlugin) {
    return API.HTTP.get<ISubscriptionFormResult>(`wi/projects/${ API.HTTP.currentProjectId }/website-integrations/${ websiteIntegrationId }/plugins/subscription-form`);
  }
}

export const getSubscriptionFormPluginUseCase = new GetSubscriptionFormPluginUseCase();
