import * as lodash from 'lodash';
import { lsSettings } from '../lsSettings';

export interface IPropertyOptions {
  defaults?: any;
}

export const defaultsOptions: IPropertyOptions = {
  defaults: null
};

export function lsProperty(options?: IPropertyOptions): PropertyDecorator {
  return (proto: Object, propertyKey: string) => {
    const { defaults } = lodash.extend<IPropertyOptions>({}, defaultsOptions, options);

    Object.defineProperty(proto, propertyKey, {
      get(): any {
        const val = lsSettings.getValue(propertyKey);
        return lodash.isUndefined(val) ? defaults : val;
      },
      set(value: any): any {
        lsSettings.setValue(propertyKey, value);
        // return this.set(propertyKey, value);
      }
    });
  }
}
