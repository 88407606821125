import { API, UseCase } from '@ppg/common';

interface DownloadExportResult {
  downloadUrl: string;
}

interface IDownloadExportCommand {
  projectID: string;
  exportID: number;
}

export class DownloadExportUseCase extends UseCase<IDownloadExportCommand, DownloadExportResult> {
  protected async execute({ projectID, exportID }) {
    return API.HTTP.get<DownloadExportResult>(`core/project/${ projectID }/exports/${ exportID }/download`);
  }
}

export const downloadExportUseCase = new DownloadExportUseCase();
