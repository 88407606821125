import * as React from 'react';
import { Modal, toast } from '@ppg/styled';
import { inject, observer } from 'mobx-react';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { PaymentMethodCreateParams, Stripe, StripeElements } from '@stripe/stripe-js';
import { t } from '../../base/helpers';
import { CardsStore } from '../../stores/payment/CardsStore';
import { AddCardModalContent } from './CardPaymentModalContent';
import { UserStore } from '../../stores/user/UserStore';
import './CardPaymentModal.scss';

interface ICardPaymentModalProps {
  cardsStore?: CardsStore;
  userStore?: UserStore;
  disableClose?: boolean;
  stripe?: Stripe;
  elements?: StripeElements;
  title?: string;
  description?: string;
  disableCloseOnEscape?: boolean;
}

@inject('cardsStore', 'userStore')
@observer
class CardPaymentModalComponent extends React.Component<ICardPaymentModalProps, {}> {


  public handleAddCardRequest = async e => {
    e.preventDefault();

    const { stripe, elements, cardsStore, userStore } = this.props;

    if (!stripe || !elements) {
      return;
    }


    const cardElement = elements.getElement('card');
    const billingDetails = cardsStore.newCardDetails as PaymentMethodCreateParams.BillingDetails;

    await stripe.confirmCardSetup(cardsStore.secret, {
      payment_method: {
        card: cardElement,
        billing_details: billingDetails
      },
    }).then(result => {
      if (result.error) {
        result.error && result.error.message && toast.error(result.error.message);
      }

      if (result.setupIntent) {
        userStore.isLite && cardsStore.setSubscriptionRecurringCard();
        cardsStore.disableCardModal()
      }
    });

    cardsStore.secret = '';
    cardsStore.newCardDetails = '';
  };

  public componentWillUnmount() {
    this.props.cardsStore.reset();
    this.props.cardsStore.disableCardModal()
  }

  public render() {
    const { cardsStore, title, disableClose, description, disableCloseOnEscape } = this.props;
    return <Modal content={ <AddCardModalContent billingDetails={ cardsStore.newCardDetails }
                                                 onInputChange={ cardsStore.handleBillingDataChange }
                                                 description={ description }/> }
                  title={ <h1>{ t(title ? title : 'Add new card') }</h1> }
                  show={ cardsStore.isCardModalOpen }
                  width={ 400 }
                  confirmButtonFormId={ 'add-card-form' }
                  cancelButton={ t('Cancel') }
                  confirmButton={ t('Add card') }
                  onCancel={ () => this.props.cardsStore.disableCardModal() }
                  onConfirm={ this.handleAddCardRequest.bind(this) }
                  hideCancelButton={ disableClose }
                  hideCloseButton={ disableClose }
                  disableCloseOnEscape={ disableCloseOnEscape }/>

  }
}

const CardPaymentModal = (props: ICardPaymentModalProps) => {
  return <ElementsConsumer>
    { ({ stripe, elements }) => (
      <CardPaymentModalComponent stripe={ stripe } elements={ elements } { ...props } />
    ) }
  </ElementsConsumer>;
};

export default CardPaymentModal;
