import * as React from 'react';
import {  DateTimePicker } from '@ppg/styled';
import { inject, observer } from 'mobx-react';
import { t } from '../../../base/helpers';
import { AccessKey } from '../../../modelsMobx/AccessKey';
import { ProjectStore } from '../../../stores/project/ProjectStore';
import { Autocomplete } from '@ppg/styled';

interface IAccessKeyManagerKeysModalProps {
  newAccessKey: AccessKey;
  projectStore?: ProjectStore;
}

@inject('projectStore')
@observer
export class AccessManagerNewAccessKeyModal extends React.Component<IAccessKeyManagerKeysModalProps> {
  public render() {
    const { newAccessKey } = this.props;

    return (
      <>
        <div className="m5b">{ t('user/access-manager/keys::Projects') }</div>
        <Autocomplete
          value={ 'name' }
          availableItems={ this.props.projectStore.userOwnedProjects }
          selectedItems={ newAccessKey.selectedProjects }
          onRemove={ (project) => newAccessKey.removeProject(project) }
          onSelect={ (project) => newAccessKey.addProject(project) }
          emptyAvailableItemsMessage={t('user/access-manager/keys::There are no available projects')}
          placeholder={t('user/access-manager/keys::Search project by name...')}
        />

        <div className="m10t">{ t('user/access-manager/keys::Expire date') }</div>
        <DateTimePicker
          calendarButtonContent={ t('user/access-manager/keys::Save') }
          disablePastDays={ true }
          showTime={ false }
          displayBelow={ true }
          property={ newAccessKey.getProperty('expiredAt') }
        />
      </>
    );
  }
}
