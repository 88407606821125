import * as React from 'react';
import { numberWithSpaces, redirect } from '../../../../base/helpers';
import './StatCard.scss'
import { Help, LineLoader } from '@ppg/styled';

interface IStatCardProps {
  title: string;
  count: number | string;
  color?: string;
  icon?: string;
  path?: string;
  isValueInPercent?: boolean;
  isLoading: boolean;
  unit?: string;
  helper?: string;
}

export const StatCard = function ({ title = '', count = 0, color = '', icon = '', path = '', isValueInPercent = false, isLoading, unit = '', helper = '' }: IStatCardProps): JSX.Element {
  const countValue = (typeof count === 'string') ? count : numberWithSpaces(count)
  return <div className={ `stats-card column ` } onClick={ () => path && redirect(path) }>
    { isLoading && <>
      <span className={ `icon-refresh size16` }/>
      <LineLoader/>
    </> }
    <div className='stats-card__data'>
      <div className="count-num">{ countValue }{ isValueInPercent ? '%' : '' } { unit } </div>
      <div className="count-title">{ title } { !!helper && <Help content={ helper }/> }</div>
    </div>
    { icon && <span className={ `stat-card__icon stat-${ color }` }>
          <span className={ 'size20 icon-' + icon }/>
     </span> }
  </div>
};
