interface ICropOptions {
  x: number;
  y: number;
  width: number;
  height: number;
}

class CroppedItem {
  constructor(private canvas: HTMLCanvasElement) { }

  public toBlob(type?: string, ...args: any[]): Promise<Blob> {
    return new Promise(resolve => {
      this.canvas.toBlob(result => resolve(result), type, ...args);
    });
  }

  public toDataURL(type?: string, ...args: any[]): Promise<string> {
    return Promise.resolve(this.canvas.toDataURL(type, ...args));
  }
}

export class ImageCropper {
  constructor(
    private img: HTMLImageElement,
    private cropOptions: ICropOptions
  ) { }

  public crop(resultWidth?: number, resultHeight?: number): CroppedItem {
    return this.cropToDesiredSize(resultWidth, resultHeight);
  }

  private cropToDesiredSize(resultWidth: number, resultHeight: number): CroppedItem {
    resultHeight = resultHeight || resultWidth;

    const { cropOptions, img } = this;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const sourceWidth = img.width;
    const sourceHeight = img.height;
    const sourceOffsetX = cropOptions.x * sourceWidth / 100;
    const sourceOffsetY = cropOptions.y * sourceHeight / 100;

    const destinationOffsetX = 0;
    const destinationOffsetY = 0;

    const croppedWidth = cropOptions.width * sourceWidth / 100;
    const croppedHeight = cropOptions.height * sourceHeight / 100;

    canvas.width = croppedWidth;
    canvas.height = croppedHeight;

    ctx.drawImage(
      img,
      sourceOffsetX,
      sourceOffsetY,
      sourceWidth,
      sourceHeight,
      destinationOffsetX,
      destinationOffsetY,
      sourceWidth,
      sourceHeight,
    );

    return new CroppedItem(canvas);
  }
}
