import { ProjectRelated } from '../ProjectRelatedStore';
import { debounce, property } from '@ppg/common';
import { redirect, t } from '../../base/helpers';
import { Segment, SegmentState } from '../../modelsMobx/segmentation/Segment';
import { action, computed, observable, reaction } from 'mobx';
import { Utils } from '../utils';
import { copySegmentUseCase, getReadySegmentsUseCase, getSegmentsUseCase, getSegmentUseCase } from '../../useCases/core';
import { ISelectOption, toast } from '@ppg/styled';
import { ProjectRoutesTypes } from '../../routes/moduleProjectRoutes';
import { userStore } from '../index';
import { IListQueryOptions } from '../../interfaces/IListQueryOptions';
import { IGetSegmentsResponse } from '../../useCases/core/segments/GetSegmentsUseCase';
import { IGetReadySegmentsResponse } from '../../useCases/core/segments/GetReadySegmentsUseCase';

export enum SortSegmentsOptions {
  ALPHABETICAL = 'ALPHABETICAL',
  ALPHABETICAL_DESC = '-ALPHABETICAL',
  NEWEST = 'NEWEST',
  OLDEST = '-NEWEST',
}

interface ISegmentsStore {
  segments: Segment[];
  segment: Segment;
  sortSegmentsBy: SortSegmentsOptions;
  searchQuery: string;
  total: number;
  offset: number;
  limit: number;
  infinityScroll: boolean;
  isSegmentsLoading: boolean;
  isSegmentLoading: boolean;
}

export class SegmentsStore extends ProjectRelated implements ISegmentsStore {
  static SEGMENTS_LIMIT = 20;
  static MIN_VALUE_TYPE_NUMBER_CONDITION = 0;

  @property()
  public selectedSegment: Segment | null;

  @property()
  public segments: Segment[] = [];

  @property()
  public segment: Segment;

  @property()
  public sortSegmentsBy = SortSegmentsOptions.NEWEST;

  @property()
  public searchQuery = "";

  @property()
  public total = 0;

  @property()
  public offset = 0;

  @property()
  public limit = SegmentsStore.SEGMENTS_LIMIT;

  @property()
  public infinityScroll = false;

  @observable
  isSegmentsLoading = true;

  @observable
  isSegmentLoading = true;

  private debouncedFetchSegments = debounce(() => this.fetchSegmentsWithClearOffset(), 800);

  reactOnPaginationChange = reaction(
    () => this.onChangePagination,
    () => this.getSegments(true),
    { equals: () => this.checkChangePagination() }
  );

  reactOnSortChange = reaction(() => this.sortSegmentsBy, () => this.fetchSegmentsWithClearOffset());
  reactOnSearchQuery = reaction(() => this.searchQuery, () => this.debouncedFetchSegments());

  public getSegmentById(segmentId: string | null): Segment | null {
    if (!segmentId) {
      return null;
    }

    for (let segment of this.segments) {
      if (segment.id === segmentId) {
        return segment;
      }
    }

    return null;
  }

  public isSelected(segment: Segment): boolean {
    if (!this.selectedSegment) {
      return false;
    }

    return this.selectedSegment.id === segment.id;
  }

  @action
  public deselectSegment(): void {
    this.selectedSegment = null;
  }

  public get hasSegments(): boolean {
    return this.segments.length > 0;
  }

  @computed
  public get onChangePagination(): IListQueryOptions {
    return {
      offset: this.offset,
      limit: this.limit
    };
  }

  public checkChangePagination(): boolean {
    return location.pathname !== ProjectRoutesTypes.SEGMENTS;
  }

  @action
  public selectSegment(segment: Segment): void {
    if (this.isSelected(segment)) {
      this.selectedSegment = null;
    } else {
      this.selectedSegment = segment;
    }
  }

  @action
  public async fetchOnChangeSegments(): Promise<void> {
    this.segments = [];
    this.offset === 0 ? await this.getSegments(true) : this.offset = 0;
  }

  @action
  public async fetchSegmentsWithClearOffset(): Promise<void> {
    this.offset = 0;
    await this.getSegments(true);
  }

  @action
  public onSearchQueryChange(value: string): void {
    this.searchQuery = value;
  }

  @action
  public setInfinityScroll(): void {
    this.infinityScroll = userStore.settings.infinity;
  }

  public async fetchSegments(): Promise<IGetSegmentsResponse> {
    return await getSegmentsUseCase.exec({
      projectId: this.projectId,
      name: this.searchQuery,
      sortBy: this.sortSegmentsBy,
      limit: SegmentsStore.SEGMENTS_LIMIT,
      offset: this.offset
    });
  }

  public async fetchReadySegments(): Promise<IGetReadySegmentsResponse> {
    return await getReadySegmentsUseCase.exec({
      projectId: this.projectId,
      name: this.searchQuery,
      sortBy: this.sortSegmentsBy,
      limit: SegmentsStore.SEGMENTS_LIMIT,
      offset: this.offset
    });
  }

  @action
  public async getSegments(getAllSegments: boolean): Promise<void> {
    await this.executeWithSegmentsLoader(async () => {

      const { data, metadata } = getAllSegments ? await this.fetchSegments() : await this.fetchReadySegments();

      const segments = data.map(segment => Segment.createSegment(segment));
      this.segments = this.infinityScroll ? this.segments.concat(segments) : segments;
      this.total = metadata.total;
    });
  }

  @action
  public async getMoreSegments(getAllSegments: boolean): Promise<void> {
    await this.executeWithSegmentsLoader(async () => {
      this.offset = this.offset + SegmentsStore.SEGMENTS_LIMIT;

      const { data, metadata } = getAllSegments ? await this.fetchSegments() : await this.fetchReadySegments();

      const segments = data.map(segment => Segment.createSegment(segment));
      this.total = segments.length !== 0 ? metadata.total : this.segments.length;
      this.segments = this.segments.concat(segments);
    });
  }

  @action
  public async fetchSegment(segmentId: string): Promise<void> {
    await this.executeWithSegmentLoader(async () => {
      const segment = await getSegmentUseCase.exec({
        projectId: this.projectId,
        segment: segmentId
      });

      this.segment = Segment.createSegment(segment);
    });
  }

  @action
  public handleOnCancelSegment(): void {
    this.clearSegment();
    redirect(ProjectRoutesTypes.SEGMENTS);
  }

  @action
  public async handleOnSaveSegment(segment: Segment, isEditView: boolean): Promise<void> {
    if (segment.validateSegmentFields()) {
      toast.error(segment.validateSegmentFields());
      return;
    }

    !isEditView ? await segment.createNewSegment(segment) : await segment.updateSegment(segment);
    this.clearSegment();
    redirect(ProjectRoutesTypes.SEGMENTS);
    !isEditView ?
      toast.success(t('Your segment has been saved successfully')) :
      toast.success(t('Your segment has been edited successfully'));
  }

  public async handleOnSaveSegmentDraft(segment: Segment, isEditView: boolean): Promise<void> {
    if (segment.validateSegmentFields()) {
      toast.error(segment.validateSegmentFields());
      return;
    }

    !isEditView ? await segment.createSegmentDraft(segment) : await segment.updateSegmentDraft(segment);
    this.clearSegment();
    redirect(ProjectRoutesTypes.SEGMENTS);
    !isEditView ?
      toast.success(t('Your segment draft has been saved successfully')) :
      toast.success(t('Your segment draft has been edited successfully'));
  }

  public async handleOnDeleteSegment(segment: Segment): Promise<void> {
    try {
      await segment.onDeleteSegment();
      toast.success(t('Your segment has been deleted successfully'));
      await this.fetchOnChangeSegments();
    } catch (err) {
      console.log('error', err);
    }
  }

  @action
  public async handleOnCopySegment(segment: string): Promise<void> {
    const { id } = await copySegmentUseCase.exec({ segment });
    redirect(`${ ProjectRoutesTypes.SEGMENTS }/${ id }/edit`);
  }

  public handleOnSendCampaignToSegment(segment: Segment): void {
    this.projectStore.createPushStore.push.setSegment(segment.convertToSegmentDTO());
    redirect(`${ ProjectRoutesTypes.CAMPAIGN_NEW_TARGETED_PUSH_BY_SEGMENT }`);
  }

  public showLoadMoreSegmentsBtn(): boolean {
    const isOverPageLimit = this.segments.length >= SegmentsStore.SEGMENTS_LIMIT;
    const currentSegmentsLengthIsNotOverTotal = this.segments.length < this.total;

    return isOverPageLimit && currentSegmentsLengthIsNotOverTotal;
  }

  public get sortOptions(): ISelectOption[] {
    return [
      { name: t('A-Z'), value: SortSegmentsOptions.ALPHABETICAL },
      { name: t("Z-A"), value: SortSegmentsOptions.ALPHABETICAL_DESC },
      { name: t("Newest"), value: SortSegmentsOptions.NEWEST },
      { name: t("Oldest"), value: SortSegmentsOptions.OLDEST }
    ];
  }

  public schemaNewSegment(): Segment {
    return Segment.createSegment({
      id: "",
      name: "",
      conditions: [],
      audience: 0,
      state: SegmentState.READY
    });
  }

  private executeWithSegmentsLoader<T = any>(callback: () => Promise<T>) {
    return Utils.executeWithLoader(
      callback,
      () => this.isSegmentsLoading = true,
      () => this.isSegmentsLoading = false,
      (err) => {
        console.error(err);
        this.isSegmentsLoading = false;
      }
    );
  }

  private executeWithSegmentLoader<T = any>(callback: () => Promise<T>) {
    return Utils.executeWithLoader(
      callback,
      () => this.isSegmentLoading = true,
      () => this.isSegmentLoading = false,
      (err) => {
        console.error(err);
        this.isSegmentLoading = false;
      }
    );
  }

  @action
  private clearSegment(): void {
    this.segment = null;
  }

  public setDefaultValues(): void {
    this.selectedSegment = null;
    this.segments = [];
    this.segment = null;
    this.sortSegmentsBy = SortSegmentsOptions.NEWEST;
    this.searchQuery = "";
    this.offset = 0;
    this.total = 0;
    this.limit = SegmentsStore.SEGMENTS_LIMIT;
  }
}
