import * as React from 'react';
import { t } from '../../base/helpers';
import { SaveButton, ISaveButtonProps } from '@ppg/styled';

export const SaveButtonWrapped = ({ callback, disabled, icon, id, content, isOutline, preventStateChange, size, preventToast, successMessage, className, dataCy }: ISaveButtonProps) => {
  return <SaveButton content={ content || t('Save') }
                     disabled={ disabled }
                     id={ id }
                     preventStateChange={ preventStateChange }
                     preventToast={ preventToast }
                     isOutline={ isOutline }
                     icon={ icon }
                     dataCy={ dataCy }
                     size={ size }
                     successMessage={ successMessage || t('Changes are saved') }
                     callback={ callback }
                     className={ className }/>
}
