import { property, PropertyHandler } from '@ppg/common';
import { computed } from 'mobx';
import { getResolveLabelsNamesToIds } from '../useCases/core';
import { SystemLabelWrapper } from "./SystemLabelWrapper";
import dayjs from 'dayjs';

export enum LabelState {
  IDLE = 'IDLE',
  INCLUDED = 'INCLUDED',
  EXCLUDED = 'EXCLUDED'
}

export enum LabelCountStrategy {
  AND = 'and',
  OR = 'or'
}

export interface ILabel {
  key: string;
  value: string | number | boolean;
  approxCount: number;
  state: LabelState;
}

export class Label extends PropertyHandler implements ILabel {
  @property() public key: string;
  @property() public value: string | number | boolean;
  @property() public approxCount: number;
  @property() public state: LabelState;
  @property() private legacyId: string | null;

  static DATE_ISO_FORMAT = /^(\d{4})-(\d{2})-(\d{2})(|T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?))$/;

  static deserialize(serializedLabel: string): Label {
    const [key, ...values] = serializedLabel.split(":");
    const value = values.join(":");

    return new Label({
      key,
      value,
      approxCount: 0,
      state: LabelState.IDLE
    })
  }

  constructor(label: ILabel) {
    super();
    this.key = label.key;
    this.value = label.value;
    this.approxCount = label.approxCount;
    this.state = label.state;
  }

  static createLabel(label): Label {
    return new Label({ ...label, state: LabelState.IDLE });
  }

  public getLabelKey(): string {
    return new SystemLabelWrapper(this).labelKey;
  }

  public getLabelIdentity():string {
    return `${this.getLabelKey()}: ${this.value}`
  }

  public isIncluded(): boolean {
    return this.state === LabelState.INCLUDED;
  }

  public isExcluded(): boolean {
    return this.state === LabelState.EXCLUDED;
  }

  public setState(state: LabelState): void {
    this.state = state;
  }

  public toggleIncluded(): void {
    this.state = this.isIncluded() ? LabelState.IDLE : LabelState.INCLUDED;
  }

  public toggleExcluded(): void {
    this.state = this.isExcluded() ? LabelState.IDLE : LabelState.EXCLUDED;
  }

  public get isSelected(): boolean {
    return this.isIncluded() || this.isExcluded()
  }

  @computed
  public get labelWithCount(): string {
    return `${ this.value } (${ this.approxCount })`;
  }

  private getLabelValue(): string {
    if(Label.DATE_ISO_FORMAT.test(`${this.value}`)) {
      return dayjs(`${this.value}`).format('LLL');
    }

    return `${this.value}`
  }

  @computed
  public get valueWithCountForMigrateProject(): string {
      return `${this.getLabelValue()} (${ this.approxCount })`;
  }

  @computed
  public get serializeForMigrateProject(): string {
    return `${this.key}: ${this.getLabelValue()}`
  }

  public serialize(): string {
    return `${this.key}:${this.value}`
  }

  /**
   * @deprecated
   */
  public getLegacyId(): string {
    if (!this.legacyId) throw new Error('Cannot this legacy id before resolve it');

    return this.legacyId;
  }

  /**
   * @deprecated
   */
  public async resolveLegacyId(): Promise<void> {
    try {
      const [labelId] = await getResolveLabelsNamesToIds.exec({
        labels: [this.serialize()]
      });

      this.legacyId = labelId;
    } catch(error) {
      console.error(error);
    }

  }
}
