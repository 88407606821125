import { API, UseCase } from '@ppg/common';
import { getRangeDateParam } from '../../../helpers';
import { IOrganizationDashboardRequestBody } from '../../../interfaces';

interface AutomationComparatorStatistics {
  automationStats: AutomationComparatorStats
}

interface AutomationComparatorStats {
  averageCampaignCTR: number;
  projectStats: AutomationProjectStats[];
}

interface AutomationProjectStats {
  project: string;
  clicked: number;
  delivered: number;
  ctr: number;
}

export class GetAutomationsComparatorStatsUseCase extends UseCase<IOrganizationDashboardRequestBody, AutomationComparatorStatistics> {
  protected async execute({
                            organization,
                            platform,
                            from,
                            to,
                            timezone,
                          }: IOrganizationDashboardRequestBody) {
    return API.HTTP.get<AutomationComparatorStatistics>(
      `statistics/organizations/${ organization }/campaigns/statistics/automation/comparator${ getRangeDateParam(from, to) }&timezone=${ timezone }`
    );
  }
}
