import { PropertyHandler, property } from '@ppg/common';
import { IProviderAction } from '../../useCases/core/providers/GetProjectProviderUseCase';
import { computed, observable } from 'mobx';
import { t } from '../../base/helpers';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

interface ISafariProvider {
  websitePushId: string;
  actions: IProviderAction[];
  passphrase?: string;
  p12?: string;
  notAfter?: Date;
  notBefore?: Date;
  fileName?: string;
}

export class SafariProvider extends PropertyHandler implements ISafariProvider {
  @property() public websitePushId;
  @property() public passphrase;
  @property() public p12;
  @property() public notAfter;
  @property() public notBefore;

  @observable public actions;
  @observable public fileName = '';

  constructor({ websitePushId, actions = [], passphrase = '', p12 = '', notAfter, notBefore }: ISafariProvider) {
    super();
    this.websitePushId = websitePushId;
    this.p12 = p12;
    this.passphrase = passphrase;
    this.actions = actions;
    this.notBefore = notBefore ? new Date(notBefore) : null;
    this.notAfter = notAfter ? new Date(notAfter) : null;
  }

  static createProvider(ISafariProviderDTO?: ISafariProvider): SafariProvider {
    if (ISafariProviderDTO) {
      return new SafariProvider(ISafariProviderDTO);
    } else {
      return SafariProvider.createDefault();
    }
  }

  static createDefault(): SafariProvider {
    return new SafariProvider({
      websitePushId: '',
      actions: [],
      p12: '',
      passphrase: '',
      notAfter: null,
      notBefore: null,
      fileName: ''
    });
  }

  @computed
  public get isFirstUpload(): boolean {
    return this.actions.includes(IProviderAction.CREATE);
  }

  @computed
  public get isIntegrated(): boolean {
    return this.actions.includes(IProviderAction.UPDATE_CERTIFICATES) && this.isInDateRange;
  }

  @computed
  public get isInDateRange(): boolean {
    if (!this.notBefore || !this.notAfter) {
      return false;
    }

    const wrappedNotAfter = dayjs(this.notAfter);
    const wrappedNotBefore = dayjs(this.notBefore);
    const today = dayjs();
    return today.isSameOrAfter(wrappedNotBefore, 'date') && today.isSameOrBefore(wrappedNotAfter, 'date');
  }

  @computed
  public get willExpireSoon(): boolean {
    return this.hasCertDates && this.isCertificateToExpire;
  }

  @computed
  public get isCertificateToExpire(): boolean {
    const wrappedNotAfter = dayjs(this.notAfter);
    const today = dayjs();

    if (wrappedNotAfter.isBefore(today, 'date')) {
      return false;
    }

    const daysToExpire = this.expirationDaysNumber;

    return daysToExpire < 30;
  }

  @computed
  private get expirationDaysNumber(): number {
    const wrappedNotAfter = dayjs(this.notAfter);
    const today = dayjs();

    return wrappedNotAfter.diff(today, 'day');
  }

  @computed
  public get daysToCertExpiration(): number {
    if (!this.willExpireSoon) {
      return null;
    }

    return this.expirationDaysNumber;
  }

  @computed
  public get hasCertificateExpired(): boolean {
    return this.hasCertDates && this.isCertificateExpired;
  }

  @computed
  public get isCertificateExpired(): boolean {
    const wrappedNotAfter = dayjs(this.notAfter);
    const today = dayjs();

    return today.isAfter(wrappedNotAfter);
  }

  @computed
  public get hasCertDates(): boolean {
    return this.notBefore && this.notAfter;
  }

  @computed
  public get canIntegrate(): boolean {
    return this.p12;
  }

  public onKeyUpload = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = this.getFileAndValidate(event);

    if (!file) return;

    this.p12 = await this.readAsBase64(file);
    this.fileName = file.name;
  };

  private getFileAndValidate = (event: React.ChangeEvent<HTMLInputElement>): File => {
    const file = event.target.files[0];
    if (file.name.indexOf('.p12') === -1) {
      alert(t('Please upload .p12 file'));
      event.target.value = null;
      return null;
    }

    return file;
  };

  private readAsBase64 = async (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      const timer = setTimeout(reject, 2000);

      reader.addEventListener('load', function (event: ProgressEvent<FileReader>) {
        resolve(event.target.result as string);
        window.clearTimeout(timer);
      });

      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString() || '');
      reader.onerror = error => reject(error);
    });
  };
}
