import { API, UseCase } from '@ppg/common';
import { Image, ImageType } from '../../../modelsMobx/Image';

interface IGetImages {
  projectID: string;
  offset: number;
  limit: number;
  imageType: ImageType;
  searchQuery?: string;
}

interface IGetImagesResult {
  metadata: {
    total: number;
  },
  data: Image[];
}

export class GetImagesByTypeUseCase extends UseCase<IGetImages, IGetImagesResult> {
  protected async execute({ projectID, offset, limit, imageType, searchQuery }: IGetImages) {

    return API.HTTP.post<IGetImagesResult>(`core/projects/${ projectID }/images/type/${ imageType }?offset=${ offset }&limit=${ limit }`, {
      search: searchQuery
    })
  }
}

export const getImagesByTypeUseCase = new GetImagesByTypeUseCase();
