import { ProjectRelated } from '../ProjectRelatedStore';
import { debounce, property } from '@ppg/common';
import { Label, LabelCountStrategy } from '../../modelsMobx/Label';
import { getCountSubscribersUseCase, getLabelsUseCase } from '../../useCases/core';
import { SortTypeOptions } from './SegmentationStore';
import { action } from 'mobx';
import { IGetLabelsResponse } from '../../useCases/core/label/GetLabelsUseCase';

export class NotificationPreviewStore extends ProjectRelated {
  static LABELS_START_LIMIT = 20;
  static LABELS_LIMIT = 50;

  @property()
  public availableLabels: Label[] = [];

  @property()
  public selectedLabels: Label[] = [];

  @property()
  public selectedLabelsIds: string[] = [];

  @property()
  public searchLabel: string = "";

  @property()
  public offset: number = 0;

  @property()
  public total: number = 0;

  @property()
  public count: number = 0;

  @action
  public debouncedGetLabels = debounce(() => this.getLabels(), 500);

  public setDefaultValues(): void {
    this.clearValues();
  }

  public async fetchLabels(): Promise<IGetLabelsResponse> {
    const limit = this.availableLabels.length === 0 ? NotificationPreviewStore.LABELS_START_LIMIT : NotificationPreviewStore.LABELS_LIMIT;

    return await getLabelsUseCase.exec({
      offset: this.offset,
      limit,
      query: this.searchLabel,
      key: "",
      sortBy: SortTypeOptions.POPULAR
    });
  };

  @action
  public getLabels = async (): Promise<void> => {
    const { data, metadata } = await this.fetchLabels();

    this.total = metadata.total;
    const newLabels = data.map(item => {
      const label = { ...item, value: `${ item.value }` };
      return Label.createLabel(label);
    });

    const newLabelsWithoutDuplicate = newLabels.filter(newLabel => {
      return this.availableLabels.every(label => newLabel.getLabelIdentity() !== label.getLabelIdentity());
    });

    const labels = newLabelsWithoutDuplicate.concat(this.availableLabels);
    this.availableLabels = labels.sort((a, b) => {
      return (a.value < b.value) ? -1 : (a.value > b.value) ? 1 : 0;
    });
  };

  @action
  public onChangeSearchValue = (inputValue: string): void => {
    if (this.availableLabels.some(label => label.value === inputValue) || !inputValue) return;

    this.searchLabel = inputValue;
    this.debouncedGetLabels();
  };

  @action
  public onSelectLabel = async (selectedLabel: Label): Promise<void> => {
    this.selectedLabels.unshift(selectedLabel);
    this.availableLabels = this.availableLabels.filter(label => label.getLabelIdentity() !== selectedLabel.getLabelIdentity());

    await selectedLabel.resolveLegacyId();
    this.selectedLabelsIds.push(selectedLabel.getLegacyId());
  };

  @action
  public deselectLabel = async (deselectLabel: Label): Promise<void> => {
    this.availableLabels.unshift(deselectLabel);
    this.selectedLabels = this.selectedLabels.filter(label => label.getLabelIdentity() !== deselectLabel.getLabelIdentity());

    await deselectLabel.resolveLegacyId();
    this.selectedLabelsIds = this.selectedLabelsIds.filter(label => label !== deselectLabel.getLegacyId());
  };

  @action
  public async fetchCountSubscribers(): Promise<void> {
    const { data } = await getCountSubscribersUseCase.exec({
      projectId: this.projectId,
      includedLabels: this.selectedLabels.map(label => label.serialize()),
      excludedLabels: [],
      includedStrategy: LabelCountStrategy.OR,
      excludedStrategy: LabelCountStrategy.OR
    });

    this.count = data;
  }

  @action
  public clearValues = (): void => {
    this.availableLabels = [];
    this.selectedLabels = [];
    this.selectedLabelsIds = [];
    this.searchLabel = "";
    this.offset = 0;
    this.total = 0;
    this.count = 0;
  };
}

