import { isEmail, isNotEmpty, minLength, property, PropertyHandler, arraysEqual } from '@ppg/common';

interface IChannel {
  id: string;
  language: string;
  type: string;
  status: string;
  payload: string;
  tags: string[];
}

export class EditChannelProxy extends PropertyHandler {
  @property() readonly initialChannel: {
    tags: string[],
    language: string,
  } = { tags: [], language: 'en' };
  @property() public channel: Channel;

  constructor(channel) {
    super();
    this.initialChannel.tags = [...channel.tags];
    this.initialChannel.language = channel.language;

    this.channel = channel;
  }

  public hasLanguageChanged() {
    return this.initialChannel.language !== this.channel.language;
  }

  public haveTagsChanged() {
    return !arraysEqual(this.initialChannel.tags, this.channel.tags);
  }
}

export class Channel extends PropertyHandler {
  @property() public id: string;
  @property([isNotEmpty()]) public language: string;
  @property() public type: string;
  @property() public status: string;
  @property([isEmail()]) public payload: string;
  @property([isNotEmpty(), minLength(1)]) public tags: string[];

  constructor(channel: IChannel) {
    super();
    this.id = channel.id;
    this.language = channel.language;
    this.type = channel.type;
    this.status = channel.status;
    this.payload = channel.payload;
    this.tags = channel.tags;
  }

  static createChannel(channel?: IChannel) {
    if (channel) {
      return new Channel(channel);
    } else {
      return new Channel({
        id: '',
        type: 'EMAIL',
        tags: [],
        status: '',
        payload: '',
        language: 'en'
      })
    }
  }
}
