import { API, UseCase } from "@ppg/common";
import { getRangeDateParam } from '../../../helpers';
import { IGetSubscribersLocalisationResult } from '../../../interfaces';

interface IGetSubscribersLocalisationRequest {
  organization: string;
  from: string,
  to: string
}

export class GetSubscribersLocalisationUseCase extends UseCase<IGetSubscribersLocalisationRequest, IGetSubscribersLocalisationResult> {
  protected async execute({ organization, from, to }: IGetSubscribersLocalisationRequest) {
    const endpoint = `statistics/organizations/${ organization }/subscribers/localisation`
      + getRangeDateParam(from, to)

    return API.HTTP.get<IGetSubscribersLocalisationResult>(endpoint);
  }
}

