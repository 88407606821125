import { observable, set, toJS } from 'mobx';
import { deepObserve } from 'mobx-utils';
import { ISubscriptionForm, ISubscriptionFormElements } from './SubscriptionFormInterfaces';
import { SubscriptionFormElementStore } from './SubscriptionFormElementStore';
import { FormType } from './SubscriptionFormEnums';

export class SubscriptionFormStore {
  @observable public elements: ISubscriptionFormElements = {};
  @observable public formTemplate: ISubscriptionForm[] = [];
  @observable public version = 0;
  public readonly formType: FormType;

  constructor(formTemplate: ISubscriptionForm[], type) {
    set(this.formTemplate, formTemplate);
    this.formType = type;
    this.createFormElements();

    deepObserve(this.elements, () => {
      this.version++;
    });
  }

  private createFormElements() {
    for (let element of this.formTemplate) {
      this.createFormElement(element);
    }
    this.createFormElement({ id: 'mobile' });
  }

  private createFormElement(element: ISubscriptionForm) {
    if (element.child) {
      element.child.forEach(item => this.createFormElement(item));
    }
    const formElement = new SubscriptionFormElementStore(element);

    if (!!formElement.styles.animationName) {
      const { animationName } = formElement.styles;
      formElement.setAnimation({ animationName }, animationName);
    }
    set(this.elements, formElement.id, formElement);
  }

  private generateFormTemplateElement(element: ISubscriptionForm) {
    if (element.child) {
      element.child = element.child.map(item => this.generateFormTemplateElement(item));
    }
    return toJS(Object.assign(element, this.elements[element.id]));
  }

  public toFormTemplate() {
    return this.formTemplate.map(element => this.generateFormTemplateElement(element));
  }

  public toFinalTemplate() {
    this.elements.form.setCachedAnimation();
    return this.toFormTemplate();
  }

  get isFormVertical() {
    return this.formType === 'custom' && this.elements.form.styles.flexDirection.includes('row');
  }

  get isNoPicture() {
    return this.formType === 'custom' && this.elements.picture.styles.display === 'none';
  }

}
