import { observer } from 'mobx-react';
import * as React from 'react';
import { t } from '../../../../../base/helpers';
import { organizationDashboardStore, projectStore } from '../../../../../stores';
import {
  IComparatorStatisticsDay
} from '../../../../../useCases/statistics/organization/campaigns/GetCampaignsComparatorHistogramUseCase';
import { EmptyComponentDashboardStatsType, StatsTable } from '../../../common';
import { ComparatorStatisticsDay, ComparatorTable } from './ComparatorTable';

interface IProjectComparatorTable {
  comparatorTable: ComparatorTable;
  title: string;
  fetchTableData: () => Promise<void>;
  emptyComponentType: EmptyComponentDashboardStatsType;
  percentage?: boolean;
  emptyComponentHeight?: number;
}

export const ProjectComparatorTable = observer(({
                                                  title,
                                                  comparatorTable,
                                                  fetchTableData,
                                                  emptyComponentType,
                                                  percentage,
                                                  emptyComponentHeight
                                                }: IProjectComparatorTable) => {

  const { rangeChangeFactors, platformChangeFactors, isPlatformSelected, projectComparisonStore } = organizationDashboardStore;

  function renderValue(projectDay: ComparatorStatisticsDay | undefined): string {
    if (projectDay === undefined || projectDay.value === 0) {
      return `0`;
    }

    if (percentage) {
      return `${ (projectDay.value * 100).toFixed(2) }%`
    }

    return projectDay.value.toLocaleString();
  }

  function checkIfHasData(): IComparatorStatisticsDay[] {
    if (comparatorTable.data.every(day => day.data.length === 0)) {
      return [];
    }
    return comparatorTable.data;
  }

  return <StatsTable title={ title }
                     tableData={ checkIfHasData() }
                     rangeChangeFactors={ rangeChangeFactors }
                     platformChangeFactors={ platformChangeFactors }
                     isPlatformSelected={ isPlatformSelected }
                     emptyComponentHeight={ emptyComponentHeight }
                     showPagination
                     emptyComponentSubtitle={ comparatorTable.data[0] && `${ t('organization/project-comparison::from') } ${ comparatorTable.data[0].day } ${ t('organization/project-comparison::to') } ${ comparatorTable.data[comparatorTable.data.length - 1].day }` }
                     pagination={ {
                       total: comparatorTable.getProperty('total'),
                       offset: comparatorTable.getProperty('offset'),
                       limit: projectComparisonStore.getProperty('limit'),
                     } }
                     fetchTable={ fetchTableData }
                     headers={ [t('organization/project-comparison::Date')].concat(comparatorTable.headers.map(project => projectStore.projects.find(p => p.id === project).name)) }
                     emptyComponentType={ emptyComponentType }
                     body={ <>
                       { comparatorTable.data.map((dayData, idx) => <tr key={ idx + Math.random() }>
                         <td data-label={ t('organization/project-comparison::Date') }>{ dayData.day } </td>
                         { comparatorTable.headers.map(project => <td
                           key={ project + idx }
                           data-label={ projectStore.projects.find(p => p.id === project).name }>

                           { renderValue(dayData.data.find(projectDay => projectDay.project === project)) }

                         </td>) }
                       </tr>) }
                     </> }
  />
})