/**
 * Do not remove - Translations
 * t('sessionStart')
 * t('sessionEnd')
 * t('subscribed')
 * t('unsubscribed')
 * t('periodic')
 * t('custom')
 * t('customTrigger')
 */
export enum AutomationFlowType {
  PERIODIC = 'periodic',
  SESSION_START = 'sessionStart',
  SESSION_END = 'sessionEnd',
  SUBSCRIBED = 'subscribed',
  UNSUBSCRIBED = 'unsubscribed',
  CUSTOM = 'custom'
}