import { size, IFontSize } from './size';
import { IFontWeight, weight } from './weight';

export interface IFont  {
  size: IFontSize;
  weight: IFontWeight;
}

export const font:IFont = {
  size,
  weight,
}



