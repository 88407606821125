import { API, UseCase } from '@ppg/common';

interface GetCampaignStatsInDateRangeCommand {
  projectID: string;
  campaign: string;
  from: Date;
  to: Date;
}

interface GetCampaignStatsInDateRangeResponse {
  clicked: number;
  delivered: number;
}

// automation report campaign stats
export class GetCampaignStatsInDateRange extends UseCase<GetCampaignStatsInDateRangeCommand, GetCampaignStatsInDateRangeResponse> {
  protected async execute({ projectID, campaign, from, to }: GetCampaignStatsInDateRangeCommand) {
    return API.HTTP.post<GetCampaignStatsInDateRangeResponse>(`statistics/projects/${ projectID }/campaign-days/automation/${ campaign }/range`, {
      from: from.toISOString(),
      to: to.toISOString(),
    })
  }
}

export const getCampaignStatsInDateRange = new GetCampaignStatsInDateRange();
