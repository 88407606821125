import { API, UseCase } from '@ppg/common';

interface ISendInstruction {
  email: string;
  siteUrl: string;
  organization: string;
  integration: string;
  embedCode: string;
  files: string;
}

export class SendIntegrationInstructionsUseCase extends UseCase<ISendInstruction, void> {
  protected async execute({ email, files, siteUrl, integration, embedCode, organization }) {
    return API.HTTP.post<void>(`notification/integration`,
      { email, siteUrl, integration, code: embedCode, files, organization });
  }
}

export const sendIntegrationInstructionsUseCase = new SendIntegrationInstructionsUseCase();
