import * as React from "react";
import "./CampaignStatTable.scss";
import { numberWithSpaces, t } from '../../../../base/helpers';
import { fixedPercentValue } from '@ppg/common';
import { observer } from 'mobx-react';
import { IDashboardCampaignsStatsWithCount } from "../../../../useCases/interfaces";
import { Help } from '@ppg/styled';
import { useLocation } from 'react-router-dom';

interface ICampaignStatTable {
  isSummaryCampaignsEnabled: boolean;
  summaryCampaigns: IDashboardCampaignsStatsWithCount;
  isTargetingCampaignsEnabled: boolean;
  targetingCampaigns: IDashboardCampaignsStatsWithCount;
  isGeolocationCampaignsEnabled: boolean;
  geolocationCampaigns: IDashboardCampaignsStatsWithCount;
  isMassCampaignsEnabled: boolean;
  massCampaigns: IDashboardCampaignsStatsWithCount;
}

export const CampaignStatTable = observer(({
                                             isSummaryCampaignsEnabled,
                                             summaryCampaigns,
                                             targetingCampaigns,
                                             isTargetingCampaignsEnabled,
                                             geolocationCampaigns,
                                             isGeolocationCampaignsEnabled,
                                             massCampaigns,
                                             isMassCampaignsEnabled
                                           }: ICampaignStatTable): JSX.Element => {
  const location = useLocation();
  const isProjectDashboard = location.pathname.includes("project");

  const renderTableItem = (label: string, isEnabled: boolean, stats: IDashboardCampaignsStatsWithCount): JSX.Element | null => {
    if (!isEnabled) {
      return null;
    }

    return <tr>
      <td data-label={ t('organization/dashboard/campaigns::Campaigns') }>{ label } </td>
      <td data-label={ t('organization/dashboard/campaigns::Number of campaigns') }>{ numberWithSpaces(stats.campaignsCount) } </td>
      <td data-label={ t('organization/dashboard/campaigns::CTR') }>{ fixedPercentValue(stats.ctr) }</td>
      { isProjectDashboard && <td data-label={ t('organization/dashboard/campaigns::Average CTR') }>{ fixedPercentValue(stats.averageCtr) }</td> }
      <td data-label={ t('organization/dashboard/campaigns::Clicked') }>{ numberWithSpaces(stats.clicked) }</td>
      <td data-label={ t('organization/dashboard/campaigns::Delivered') }>{ numberWithSpaces(stats.delivered) }</td>
      <td data-label={ t('organization/dashboard/campaigns::Sent') }>{ numberWithSpaces(stats.sent) }</td>
    </tr>
  }

  return <table className="campaign-stats-table">
    <thead>
    <tr>
      <th>{ t('organization/dashboard/campaigns::Campaigns') }</th>
      <th>{ t('organization/dashboard/campaigns::Number of campaigns') }</th>
      <th>{ t('organization/dashboard/campaigns::CTR') } { isProjectDashboard && <Help content={ t('Sum clicked to sum delivered ratio') }/> }</th>
      { isProjectDashboard && <th>{ t('organization/dashboard/campaigns::Average CTR') } <Help content={ t('Average CTR from your campaigns CTR') }/></th> }
      <th>{ t('organization/dashboard/campaigns::Clicked') }</th>
      <th>{ t('organization/dashboard/campaigns::Delivered') }</th>
      <th>{ t('organization/dashboard/campaigns::Sent') }</th>
    </tr>
    </thead>
    <tbody>
    { renderTableItem(t('organization/dashboard/campaigns::All'), isSummaryCampaignsEnabled, summaryCampaigns) }
    { renderTableItem(t('organization/dashboard/campaigns::Targeted'), isTargetingCampaignsEnabled, targetingCampaigns) }
    { renderTableItem(t('organization/dashboard/campaigns::With geolocation'), isGeolocationCampaignsEnabled, geolocationCampaigns) }
    { renderTableItem(t('organization/dashboard/campaigns::Mass'), isMassCampaignsEnabled, massCampaigns) }
    </tbody>
  </table>
})