import { ISubscriptionForm, ISubscriptionFormLayer } from '../../SubscriptionFormInterfaces';
import * as React from 'react';
import { VNodeProperties } from 'maquette';

export class SubscriptionFormLayer implements ISubscriptionFormLayer {
  public id: string;
  public type?: string;
  public text?: string;
  public uuid?: string;
  public styles?: React.CSSProperties;
  public commonStyles?: { [key: string]: React.CSSProperties };
  public child?: ISubscriptionForm[];
  public properties?: VNodeProperties;
  private setDefaultColor: Function;
  private setDefaultPicture: Function;

  constructor(type: string, id: string, defaultColor: string, stylesMap: any, defaultPicture?: string) {
    this.type = type;
    this.id = id;
    this.styles = stylesMap[id];
    this.child = [];
    this.setDefaultColor = () => defaultColor;
    this.setDefaultPicture = () => defaultPicture || '';
  }

  public withChild(newChild: SubscriptionFormLayer) {
    this.child = this.child.concat(newChild);
    return this;
  }

  public withAnimation(animations: any) {
    this.commonStyles = animations;
    return this;
  }

  public withText(text: string) {
    this.text = text;
    return this;
  }

  public withProperties(properties: any) {
    this.properties = properties;
    return this;
  }

  public withDefaultBackgroundColor() {
    this.styles.backgroundColor = this.setDefaultColor();
    this.styles.borderColor = this.setDefaultColor();
    return this;
  }

  public withDefaultPicture() {
    this.styles.background = `url(${ this.setDefaultPicture() })`;
    return this;
  }

  public withDefaultColor() {
    this.styles.color = this.setDefaultColor();
    if (this.styles['&:hover']) {
      this.styles['&:hover'].color = this.setDefaultColor();
    }
    return this;
  }
}
