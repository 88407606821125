import { Image, ImageFormatType, ImageType } from './modelsMobx/Image';
// please not use this file, please declare as static in stores or components

export const DEFAULT_PRIMARY_COLOR: string = '#16a085';
export const PUSH_IMAGE_SIZE: number = 196;
export const ITEMS_PER_PAGE: number = 20;
export const REDUCED_ITEMS_PER_PAGE: number = 10;
export const SUBSCRIBER_HISTORY_ITEMS_LIMIT: number = 4;
export const MAX_VALUE_LENGTH: number = 90;
export const POLYGON_LIMIT: number = 9999;
export const MAX_FILE_SIZE: number = 250000;
export const MAX_FILE_SIZE_INFO: number = 200000;
export const MAX_TTL = 259200;
export const MIN_TTL = 3600;
export const MIN_TEST = 900;
export const MAX_TEST_CAMPAIGNS = 10;
export const DASHBOARD_INITIAL_DAYS = 6;
export const DUMMY_ICON_CDN = 'https://cdn.pushpushgo.com/static/dummy-icon.png';
export const MAX_PUSH_ACTIONS_COUNT = 2;
export const SAFARI_COLLAPSE = { TITLE: 22, CONTENT: 24 };
export const CAMPAIGN_CHARS = { TITLE: 50, CONTENT: 120 };
export const CAMPAIGN_MONITOR_TIME = 300000;
export const MILLISECOND = 1;
export const SECOND_MS = 1000;
export const HOUR_MS = 3600 * SECOND_MS;
export const DAY_MS = 24 * HOUR_MS;
export const DEFAULT_DISPLAYED_PROJECTS = 10;
export const DAY = 1;
export const MONTH = 1;
export const WEEK_DAYS_RANGE = 7;
export const MONTH_DAYS_RANGE = 30;
export const THREE_MONTHS_RANGE = 3;
export const MAX_SUBSCRIBER_HEADERS = 8;
export const DEFAULT_EXPIRE_DATE = 1;
export const ID_LENGTH = 24;
export const CAMPAIGN_ACTION_LIMIT = 2;
export const DRAFT_LINK = 'your-link.com/';
export const AUTOMATION_RANK_LIMIT = 3;
export const DATE_RANGE_MONTH_LIMIT = 3;

export const dummyImage = new Image({
  formats: [{
    type: ImageFormatType.MEDIUM,
    url: DUMMY_ICON_CDN
  }],
  type: ImageType.LOGO,
  id: 'test',
  createdAt: Date(),
  name: 'Dummy_image',
  owner: ''
});

export const CROPPER_OPTIONS = {
  x: 0,
  y: 0,
  width: 100,
  maxWidth: 100,
  aspect: 1
};

export const DEFAULT_IMAGE_CROPPER_OPTIONS = {
  x: 5,
  y: 5,
  width: 90,
  height: 90,
};

export const BIG_IMAGE_CROPPER_OPTIONS = {
  x: 0,
  y: 0,
  width: 100,
  aspect: 2
};

export const CUSTOM_IMAGE_CROPPER_OPTIONS = {
  x: 0,
  y: 0,
  width: 100,
  aspect: 6/7
};

export const TINY_IMAGE_CROPPER_OPTIONS = {
  x: 0,
  y: 0,
  width: 100,
  aspect: 1
};

export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';

export const iconCodes = {
  add: '\ue91b',
  tick: '\ue907',
  send: '\ue910',
  play: '\ue91d',
  msg: '\ue924',
  condition: '\ue939',
  clock: '\ue93c',
  refresh: '\ue914',
  click: '\ue93d',
  bell: '\ue942',
  close: '\ue93b',
  tag: '\ue909',
  pencil: '\ue91f',
  link: '\ue92c',
  cloudSync: '\ue93a',
  lock: '\ue92b',
  arrowUp: '\u25b2',
  arrowDown: '\u25bc',
  lab: '\ue94e',
};

export const directionRtlFabStyles = '.ppg__fab-bubble-inner {  direction: rtl; text-align: right;}';

export const SENDER_STRATEGY_DEFAULT = 'all';

export const campaignConstants = {
  WINDOWS: {
    CHROME: {
      TITLE: 70,
      CONTENT: 138,
    },
    EDGE: {
      TITLE: 66,
      CONTENT: 135,
    },
    FIREFOX: {
      TITLE: 53,
      CONTENT: 198,
    }
  },
  MAC: {
    CHROME: {
      TITLE: 35,
      CONTENT: 39,
    },
    SAFARI: {
      TITLE: 48,
      CONTENT: 52,
    },
    FIREFOX: {
      TITLE: 35,
      CONTENT: 38,
    }
  },
  ANDROID: {
    CHROME: {
      TITLE: 43,
      CONTENT: 520,
      RICH_CONTENT: 50,
    },
    FIREFOX: {   //TODO: check when form issue is fixed
      TITLE: 43,
      CONTENT: 520,
    },
    SAMSUNG_BROWSER: {
      TITLE: 43,
      CONTENT: 520,
    },
  }
}
