import { property } from '@ppg/common';

export interface IPushCampaignOptions {
  sentBy: string;
  fromAPI: boolean;
  getOptionsDTO(): Record<string, any>;
}

export class PushCampaignOptions implements IPushCampaignOptions {
  @property() public sentBy: string;
  @property() public fromAPI: boolean;

  constructor(sentBy, fromAPI) {
    this.sentBy = sentBy;
    this.fromAPI = fromAPI;
  }

  public getOptionsDTO(): Record<string, any> {
    return {}
  }
}
