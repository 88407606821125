import { API, UseCase } from '@ppg/common';

interface IChangeLanguage {
  organization: string;
  channel: string;
  language: string;
}

export class ChangeLanguageUseCase extends UseCase<IChangeLanguage, void> {
  protected async execute({ organization, channel, language }) {
    await API.HTTP.put(`notification/organizations/${ organization }/channels/${ channel }/languages`, { language });
  }
}

export const changeLanguageUseCase = new ChangeLanguageUseCase();
