import { t } from '../base/helpers';

const Window: any = window;
export const Notification: any = Window.Notification || { permission: 'denied' };
export const notificationGranted = Notification.permission === 'granted';
export const notificationDenied = Notification.permission !== 'denied';

export interface IPushOptionsInterface {
  requireInteraction?: boolean;
  body?: string;
  icon?: string;
  image?: string;
  actions?: any[];
  url?: string;
}

export class TestPush {

  public send(title: string, url: string, options: IPushOptionsInterface) {
    let newNotification;

    if (!Window.Notification) {
      alert(t('This browser does not support system notifications'));
    }

    else if (notificationGranted) {
      newNotification = new Notification(title, options);
    }

    else if (notificationDenied) {
      Notification.requestPermission(function(permission: any) {
        if (permission === 'granted') {
          newNotification = new Notification(title, options);
        }
      });
    }

    if (newNotification) {
      newNotification.onclick = function(event: any) {
        event.preventDefault();
        try {
          window.open(url, '_blank');
        } catch (error) {
          console.log('Bad url given');
        }
      };
    }
  }
}