import { t } from '../../base/helpers';
import { action, computed, observable, reaction } from 'mobx';
import { property, PropertyHandler, sortArrayByBoolean } from '@ppg/common';
import { ISelectOption } from '@ppg/styled';
import { CustomField } from '../../modelsMobx/CustomField';
import { ISubscriberHeaderDTO, SubscriberHeader } from '../../modelsMobx/subscriber/SubscriberHeader';
import { lsSettings } from '../../base';
import { lsPropertyKeys } from '../../base/lsSettings';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DAY, MONTH_DAYS_RANGE } from '../../constants';

dayjs.extend(utc);

export class SubscriberFilterStore extends PropertyHandler {

  @property()
  public dateRangeFrom: Date;

  @property()
  public dateRangeTo: Date = dayjs().utc().endOf('day').toDate();

  @property()
  public rangeSelect: SubscriberRangeType = SubscriberRangeType.ALL;

  @observable
  public headers: SubscriberHeader[] = [];

  @observable
  public customFields: CustomField[] = [];

  @property()
  public searchHeader: string = '';

  @observable
  public isHeadersLoading: boolean = true;

  @observable
  public isFilterByTagsEnabled: boolean = false;

  @observable
  public isFilterByCustomIdEnabled: boolean = false;

  @observable
  public isColumnsManagerEnabled: boolean = false;

  @property()
  public customIdSearch: string = '';

  private reactOnSelectChange = reaction(() => this.rangeSelect, () => this.onRangeSelectChange());
  private reactOnFiltersChange = reaction(() => this.filtersChangeFactors, () => this.subscribersStore.debouncedSubscribersFetch());

  constructor(private readonly subscribersStore) {
    super();
    this.dateRangeFrom = dayjs(this.subscribersStore.projectCreatedDate).utc().toDate();
  }

  @computed
  public get filtersChangeFactors() {
    return {
      dateFrom: this.dateRangeFrom,
      dateTo: this.dateRangeTo,
      customId: this.customIdSearch
    };
  }

  setDefaultValues() {
    this.dateRangeFrom = dayjs(this.subscribersStore.projectCreatedDate).utc().toDate();
    this.dateRangeTo = dayjs().utc().endOf('day').toDate();
    this.isColumnsManagerEnabled = false;
    this.isFilterByCustomIdEnabled = false;
    this.isFilterByTagsEnabled = false;
  }

  @action
  public toggleFilterByTags(): void {
    this.isFilterByTagsEnabled = !this.isFilterByTagsEnabled;
    this.isFilterByCustomIdEnabled = false;
  }

  @action
  public toggleFilterByCustomId(): void {
    this.isFilterByCustomIdEnabled = !this.isFilterByCustomIdEnabled;
    this.isFilterByTagsEnabled = false;
  }

  @action
  public toggleColumnsManager(): void {
    this.isColumnsManagerEnabled = !this.isColumnsManagerEnabled;
  }

  public get isRangeVisible(): boolean {
    return this.rangeSelect === SubscriberRangeType.DATE_RANGE;
  }

  @action
  public listHeaders = (): SubscriberHeader[] => {
    return sortArrayByBoolean(this.headers, 'enabled').filter(header => {
      return header.name.toLowerCase().includes(this.searchHeader.toLowerCase());
    });
  };

  @action
  public setCustomFields = (customFields: CustomField[]) => {
    this.customFields = customFields;
  };

  public get defaultCustomFieldsHeaders(): ISubscriberHeaderDTO[] {
    return this.customFields.map(cf => {
      return { name: cf.prettyName || cf.name, value: cf.name, enabled: false };
    });
  }

  public get defaultHeaders(): ISubscriberHeaderDTO[] {
    return [
      { value: HeadersType.STATUS, name: t('Status'), enabled: true },
      { value: HeadersType.CUSTOM_ID, name: t('Custom ID'), enabled: false },
      { value: HeadersType.BROWSER, name: t('Browser'), enabled: true },
      { value: HeadersType.OS, name: t('System'), enabled: true },
      { value: HeadersType.GEO, name: t('Localization'), enabled: true },
      { value: HeadersType.TZ_OFFSET, name: t('Timezone'), enabled: false },
      { value: HeadersType.CREATED_AT, name: t('Created at'), enabled: true },
      { value: HeadersType.TAGS, name: t('Tags'), enabled: true },
    ];
  }

  @action
  public setHeaders = async (): Promise<void> => {
    const defaultHeaders = this.defaultHeaders.concat(this.defaultCustomFieldsHeaders);

    this.headers = defaultHeaders.map(header => {
      return new SubscriberHeader(header);
    });

    this.isHeadersLoading = false;
  };

  public isHeaderEnabled = (value): boolean => {
    return this.headers.some(header => {
      return header.value === value && header.enabled;
    });
  };

  @computed
  public get enabledHeadersValues(): string[] {
    return this.headers
      .map((header) => header.enabled && header['value'])
      .filter(value => value ?? null);
  }

  public setHeadersToLocalStorage = () => {
    lsSettings.setValue(lsPropertyKeys.HEADERS, this.enabledHeadersValues);
  };

  get rangeOptions(): ISelectOption[] {
    return [
      { value: [SubscriberRangeType.ALL], name: t('All') },
      { value: [SubscriberRangeType.TODAY], name: t('Today') },
      { value: [SubscriberRangeType.CURRENT_WEEK], name: t('Current week') },
      { value: [SubscriberRangeType.CURRENT_MONTH], name: t('Current month') },
      { value: [SubscriberRangeType.DATE_RANGE], name: t('Date range') },
    ];
  }

  @action
  public onRangeSelectChange(): void {
    switch (this.rangeSelect) {
      case SubscriberRangeType.ALL: {
        this.dateRangeFrom = dayjs(this.subscribersStore.projectCreatedDate).utc().startOf('day').toDate();
        this.dateRangeTo = dayjs().utc().endOf('day').toDate();
        break;
      }
      case SubscriberRangeType.TODAY: {
        this.dateRangeFrom = dayjs().utc().startOf('day').toDate();
        this.dateRangeTo = dayjs().utc().endOf('day').toDate();
        break;
      }
      case SubscriberRangeType.CURRENT_WEEK: {
        this.dateRangeFrom = dayjs().utc().startOf('week').add(DAY, 'day').toDate();
        this.dateRangeTo = dayjs().utc().endOf('week').add(DAY, 'day').toDate();
        break;
      }
      case SubscriberRangeType.CURRENT_MONTH: {
        this.dateRangeFrom = dayjs().utc().startOf('month').toDate();
        this.dateRangeTo = dayjs().utc().endOf('month').toDate();
        break;
      }
      case SubscriberRangeType.DATE_RANGE: {
        this.dateRangeFrom = dayjs().utc().subtract(MONTH_DAYS_RANGE, 'days').startOf('day').toDate();
        this.dateRangeTo = dayjs().utc().endOf('day').toDate();
      }
    }
  };
}

export enum SubscriberRangeType {
  ALL = 'all',
  TODAY = 'today',
  CURRENT_WEEK = 'current week',
  CURRENT_MONTH = 'current month',
  DATE_RANGE = 'date range'
}

export enum HeadersType {
  STATUS = 'status',
  CUSTOM_ID = 'customId',
  EMAIL = 'email',
  BROWSER = 'browser',
  OS = 'os',
  GEO = 'geo',
  TZ_OFFSET = 'tzOffset',
  CREATED_AT = 'createdAt',
  TAGS = 'tags'
}

