import { property } from "@ppg/common";
import { computed, observable } from "mobx";
import { CurrencyType } from "../../modelsMobx/CurrencyType";
import { Invoice } from "../../modelsMobx/Invoice";
import { PaidInvoices, UnpaidInvoices } from "../../modelsMobx/InvoiceList";
import { Transaction } from "../../modelsMobx/Transaction";
import {
  confirmPaymentUseCase,
  createPaylanePaypalOrderUseCase,
  createPayuOrderUseCase,
  getAccountListUseCase,
  getInvoicePDFUseCase,
} from "../../useCases/accounting";
import { UserRelatedStore } from "../user/UserRelatedStore";

export type PaymentsType = "stripe" | "payu" | "paypal";

interface IAccountDetails {
  id: string;
  balance: number;
  currency: string;
}

export class PaymentsStore extends UserRelatedStore {
  @property() public paymentsType: PaymentsType[] = ["stripe", "payu"];
  @property() public transaction: Transaction = new Transaction();
  @property() public unpaidInvoices: UnpaidInvoices = new UnpaidInvoices();
  @property() public paidInvoices: PaidInvoices = new PaidInvoices();
  @property() public accountDetails: IAccountDetails[] = [];

  @observable isLoading: boolean = true;

  public async fetchAccountList() {
    this.accountDetails = await getAccountListUseCase.exec({
      organization: this.userStore.user.organization,
    });
  }

  public async fetchInvoices() {
    if (this.accountDetails === []) {
      await this.fetchAccountList();
    }
    this.isLoading = true;
    await this.paidInvoices.fetchInvoices();
    await this.unpaidInvoices.fetchInvoices();
    this.isLoading = false;
  }

  public async setInitialData(): Promise<void> {
    await this.unpaidInvoices.setInitialData(
      this.initialCurrency,
      this.userStore.user.organization
    );
    await this.paidInvoices.setInitialData(
      this.initialCurrency,
      this.userStore.user.organization
    );
  }

  private get initialCurrency(): CurrencyType {
    let unpaidAccount = this.accountDetails.find(
      (account) => account.balance < 0
    );
    if (!!unpaidAccount) {
      return CurrencyType[unpaidAccount.currency];
    }
    let paidAccount = this.accountDetails.find(
      (account) => account.balance > 0
    );
    if (!!paidAccount) {
      return CurrencyType[paidAccount.currency];
    }
    return CurrencyType[this.accountDetails[0].currency];
  }

  public setTransactionPaymentType(type: PaymentsType) {
    this.transaction.paymentType = type;
  }

  @computed
  public get currencyOptions() {
    return Invoice.currencyOptions().filter((option) =>
      this.accountDetails.some((account) => account.currency === option.name)
    );
  }

  public get hasMoreThanOneAccount() {
    return this.accountDetails.length > 1;
  }

  public get hasNegativeBalance() {
    return this.accountDetails.some((account) => account.balance < 0);
  }

  public resetInvoices() {
    this.unpaidInvoices = new UnpaidInvoices();
    this.paidInvoices = new PaidInvoices();
  }

  public resetTransation() {
    this.transaction = new Transaction();
  }

  @computed
  public get unpaidInvoicesList() {
    return this.unpaidInvoices && this.unpaidInvoices.invoices;
  }

  @computed
  public get payedInvoicesList() {
    return this.paidInvoices && this.paidInvoices.invoices;
  }

  public isPaymentTypeActive(type: PaymentsType): boolean {
    return this.transaction.paymentType === type;
  }

  public async downloadInvoice(invoice: Invoice, correction: boolean) {
    await getInvoicePDFUseCase.exec({
      organization: this.userStore.user.organization,
      invoice: invoice,
      correction,
    });
  }

  public async confirmPayment(intentId) {
    await confirmPaymentUseCase.exec({
      organization: this.userStore.user.organization,
      transaction: intentId,
      invoices: this.transaction.invoicesId,
    });
  }

  public setInfinity(infinity: boolean) {
    this.unpaidInvoices.setInfinity(infinity);
    this.paidInvoices.setInfinity(infinity);
  }

  public prepareToTransaction() {
    this.transaction.invoices = [...this.unpaidInvoices.selectedInvoices];
    this.transaction.invoicesId = this.unpaidInvoices.selectedInvoices.map(
      (invoice) => invoice.id
    );
    this.transaction.amount = this.unpaidInvoices.getTotal();
    this.transaction.currency = this.transaction.invoices[0].currency;
  }

  public async createPayUOrder() {
    return createPayuOrderUseCase.exec({
      organization: this.userStore.user.organization,
      language: this.userStore.lang,
      invoices: this.transaction.invoicesId,
      amount: this.transaction.amount,
      currency: this.transaction.currency,
    });
  }

  public async createPaypalOrder() {
    return createPaylanePaypalOrderUseCase.exec({
      organization: this.userStore.user.organization,
      amount: this.transaction.amount,
      invoices: this.transaction.invoicesId,
      currency: this.transaction.currency,
    });
  }

  public async refreshView() {
    this.reset();
    await this.fetchAccountList();
  }

  public clearAndRefetch(): void {
    this.transaction.paymentType = null;
    this.unpaidInvoices.clearSelectedInvoices();
    this.fetchInvoices();
  }

  public reset() {
    this.resetInvoices();
    this.accountDetails = [];
    this.transaction = new Transaction();
  }

  public clearView(): void {
    this.resetInvoices();
    this.resetTransation();
  }
}
