import * as React from 'react';
import { observer } from 'mobx-react';
import { t } from '../../../../../base/helpers';
import UnderlinedNavigationBar from '../../../../User/Navigations/UnderlinedNavigationBar/UnderlinedNavigationBar';
import { organizationDashboardStore } from '../../../../../stores';
import { ProjectComparisonSubscribers } from './ProjectComparisonSubscribers';
import { ProjectComparisonCampaigns } from './ProjectComparisonCampaigns';
import { ProjectComparisonAutomation } from './ProjectComparisonAutomation';
import { ProjectComparisonInfo } from './ProjectComparisonInfo';

type ComparisonTabsTypes = {
  SUBSCRIBERS: string,
  CAMPAIGNS: string,
  AUTOMATION: string,
}

export const comparisonTabs: ComparisonTabsTypes = {
  SUBSCRIBERS: t('organization/project-comparison::Subscribers'),
  CAMPAIGNS: t('organization/project-comparison::Campaigns'),
  AUTOMATION: t('organization/project-comparison::Automation'),
}

export interface IProjectComparisonInfo {
  showComparisonInfo: boolean;
}

export const ProjectComparisonDetails = observer(({ showComparisonInfo }: IProjectComparisonInfo) => {
  const { activeTab } = organizationDashboardStore;
  const renderDetailsTab = (): JSX.Element | null => {
    switch (activeTab) {
      case comparisonTabs.SUBSCRIBERS:
        return <ProjectComparisonSubscribers/>
      case comparisonTabs.CAMPAIGNS:
        return <ProjectComparisonCampaigns/>
      case comparisonTabs.AUTOMATION:
        return <ProjectComparisonAutomation/>
      default:
        return null;
    }
  }

  return <>
    <UnderlinedNavigationBar tabs={ Object.values(comparisonTabs) }
                             activeTab={ organizationDashboardStore.getProperty('activeTab') }
                             activeViewType={ organizationDashboardStore.getProperty('activeViewType') }
    />

    <>
      <ProjectComparisonInfo showComparisonInfo={ showComparisonInfo }/>
      { renderDetailsTab() }
    </>
  </>
})

