import { property, PropertyHandler } from '@ppg/common';
import { ABVariant } from './ABVariant';
import { action, computed } from 'mobx';
import { cancelABTestUseCase, deleteABTestUseCase } from '../useCases/core';
import { IABTestDTO } from '../useCases/core/abTest/ListABTestsUseCase';
import { ABTestState } from "./ABTestState";

export enum ActionsUseCases {
  UPDATE = 'UPDATE',
  ACCEPT_AUTO = 'ACCEPT_AUTO',
  ACCEPT_MANUAL = 'ACCEPT_MANUAL',
  CHOOSE_WINNER = 'CHOOSE_WINNER',
  DELETE = 'DELETE',
  CANCEL = 'CANCEL',
  SHOW_REPORT = 'SHOW_REPORT',
  SHOW_DETAILS = 'SHOW_DETAILS',
  SHOW_WINNER_REPORT = 'SHOW_WINNER_REPORT',
  SHOW_WINNER_DETAILS = 'SHOW_WINNER_DETAILS',
}

export interface IABTest {
  id: string;
  name: string;
  state: ABTestState;
  variants: ABVariant[];
  startTestDate: Date;
  endTestDate: Date;
  availableActions: ActionsUseCases[];

  deleteABTest: () => Promise<void>;
  cancelABTest: () => Promise<void>;

  canBeRemoved: boolean;
  canBeEdited: boolean;
  canBeResend: boolean;
  canBeCanceled: boolean;
  canShowDetails: boolean;
  canSelectWinner: boolean;
}

export class ABTest extends PropertyHandler implements IABTest {
  @property() public id: string;
  @property() public name: string;
  @property() public state: ABTestState;
  @property() public variants: ABVariant[] = [];
  @property() public startTestDate: Date;
  @property() public endTestDate: Date;
  @property() public availableActions: ActionsUseCases[];

  public constructor(abTest: IABTestDTO) {
    super();
    this.id = abTest.id;
    this.name = abTest.name;
    this.state = abTest.state;
    this.variants = abTest.variants.map(v => new ABVariant(v)) || [];
    this.startTestDate = abTest.testStartDate ? abTest.testStartDate : null;
    this.endTestDate = abTest.testEndDate ? abTest.testEndDate : null;
    this.availableActions = abTest.availableActions || [];
  }

  @action
  public deleteABTest = async (): Promise<void> => {
    await deleteABTestUseCase.exec({
      abTestId: this.id
    });
  };

  @action
  public cancelABTest = async (): Promise<void> => {
    await cancelABTestUseCase.exec({ abTestId: this.id });
  };

  @computed
  public get canBeRemoved(): boolean {
    return this.availableActions.includes(ActionsUseCases.DELETE);
  }

  @computed
  public get canBeEdited(): boolean {
    return this.state === ABTestState.DRAFT;
  }

  @computed
  public get canBeResend(): boolean {
    return [
      ABTestState.FAILED,
      ABTestState.FINISHED,
      ABTestState.CANCELED,
    ].includes(this.state);
  }

  @computed
  public get canShowDetails(): boolean {
    return this.availableActions.includes(ActionsUseCases.SHOW_DETAILS) && this.state !== ABTestState.MANUAL_WINNER;
  }

  @computed
  public get canSelectWinner(): boolean {
    return this.state === ABTestState.MANUAL_WINNER;
  }

  @computed
  public get canBeCanceled(): boolean {
    return this.availableActions.includes(ActionsUseCases.CANCEL);
  }
}
