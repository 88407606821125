import { API, UseCase } from '@ppg/common';
import { IUpdateFallback } from '../../interfaces';

export class UpdateFallbackUseCase extends UseCase<IUpdateFallback, void> {
  protected async execute({ websiteIntegrationId, fallback }: IUpdateFallback) {
    await API.HTTP.put(`wi/projects/${ API.HTTP.currentProjectId }/website-integrations/${ websiteIntegrationId }/plugins/web-push/fallback`, { fallback })
  }
}

export const updateFallbackUseCase = new UpdateFallbackUseCase();
