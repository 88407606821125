import { API, UseCase } from "@ppg/common";
import { ICampaignId, ICampaignIdResponse } from '../interfaces';

interface ICopyLabelPushRequest extends ICampaignId {}
interface ICopyLabelPushResponse extends ICampaignIdResponse {}


export class CopyLabelPushUseCase extends UseCase<ICopyLabelPushRequest, ICopyLabelPushResponse> {
  protected async execute({ campaignId }: ICopyLabelPushRequest) {
    return API.HTTP.post<ICopyLabelPushResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/labels/${ campaignId }/copy`);
  }
}

export const copyLabelPushUseCase = new CopyLabelPushUseCase();
