import { API, UseCase } from "@ppg/common";

interface IGetAppSettingCommand {
  project: string;
}

interface IGetAppSettingDTO {
  project: string;
  mobileFeaturesEnabled: boolean;
  showSentBy: boolean;
  forcePoweredByPPGEnabled: boolean;
  isDemoProject: boolean;
  enableSendingDelay: boolean;
  isCappingEnabled: boolean;
  defaultTitleEnabled: boolean;
}

export class GetAppSettingUseCase extends UseCase<IGetAppSettingCommand, IGetAppSettingDTO> {
  protected async execute({ project }: IGetAppSettingCommand) {
    return API.HTTP.get<IGetAppSettingDTO>(`ah/projects/${ project }/settings`);
  }
}

export const getAppSettingUseCase = new GetAppSettingUseCase();
