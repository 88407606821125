import * as React from 'react';
import { observer } from 'mobx-react';
import { DefaultRawDatum, PieSvgProps, ResponsivePie } from '@nivo/pie'
import { OptionalLegendProps } from './__types__';
import { DEFAULT_LEGENDS_PROPERTIES, DEFAULT_PIE_CHART_PROPERTIES } from './defaultValues';
import { PieChartTooltip } from '../Tooltips/PieChartTooltip/PieChartTooltip';

export interface IPieChartProps extends Partial<PieSvgProps<DefaultRawDatum> & OptionalLegendProps> {
  percentValue?: boolean;
}

export const PieChart = observer((
  {
    percentValue,
    data,
    innerRadius = DEFAULT_PIE_CHART_PROPERTIES.innerRadius,
    padAngle = DEFAULT_PIE_CHART_PROPERTIES.padAngle,
    cornerRadius = DEFAULT_PIE_CHART_PROPERTIES.cornerRadius,
    borderWidth = DEFAULT_PIE_CHART_PROPERTIES.borderWidth,
    borderColor = DEFAULT_PIE_CHART_PROPERTIES.borderColor,
    margin = DEFAULT_PIE_CHART_PROPERTIES.margin,
    arcLinkLabel = percentValue ? DEFAULT_PIE_CHART_PROPERTIES.arcLinkPercentLabel : DEFAULT_PIE_CHART_PROPERTIES.arcLinkLabel,
    arcLinkLabelsColor = DEFAULT_PIE_CHART_PROPERTIES.arcLinkLabelsColor,
    arcLinkLabelsTextColor = DEFAULT_PIE_CHART_PROPERTIES.arcLinkLabelsTextColor,
    arcLinkLabelsSkipAngle = DEFAULT_PIE_CHART_PROPERTIES.arcLinkLabelsSkipAngle,
    arcLinkLabelsThickness = DEFAULT_PIE_CHART_PROPERTIES.arcLinkLabelsThickness,
    arcLinkLabelsStraightLength = DEFAULT_PIE_CHART_PROPERTIES.arcLinkLabelsStraightLength,
    arcLinkLabelsDiagonalLength = DEFAULT_PIE_CHART_PROPERTIES.arcLinkLabelsDiagonalLength,
    activeOuterRadiusOffset = DEFAULT_PIE_CHART_PROPERTIES.activeOuterRadiusOffset,
    activeInnerRadiusOffset = DEFAULT_PIE_CHART_PROPERTIES.activeInnerRadiusOffset,
    colors = DEFAULT_PIE_CHART_PROPERTIES.colors,
    anchor = DEFAULT_LEGENDS_PROPERTIES.anchor,
    direction = DEFAULT_LEGENDS_PROPERTIES.direction,
    symbolShape = DEFAULT_LEGENDS_PROPERTIES.symbolShape,
    translateY = DEFAULT_LEGENDS_PROPERTIES.translateY,
    translateX = DEFAULT_LEGENDS_PROPERTIES.translateX,
    itemWidth = DEFAULT_LEGENDS_PROPERTIES.itemWidth,
    itemHeight = DEFAULT_LEGENDS_PROPERTIES.itemHeight,
    symbolSize = DEFAULT_LEGENDS_PROPERTIES.symbolSize,
    itemsSpacing = DEFAULT_LEGENDS_PROPERTIES.itemsSpacing,

  }: IPieChartProps) => {
    return <ResponsivePie
      tooltip={ ({ datum }) => <PieChartTooltip datum={ datum } percentValue={ percentValue }/> }
      data={ data }
      margin={ margin }
      activeOuterRadiusOffset={ activeOuterRadiusOffset }
      activeInnerRadiusOffset={ activeInnerRadiusOffset }
      innerRadius={ innerRadius }
      padAngle={ padAngle }
      cornerRadius={ cornerRadius }
      borderWidth={ borderWidth }
      borderColor={ borderColor }
      colors={colors}
      arcLinkLabelsDiagonalLength={ arcLinkLabelsDiagonalLength }
      arcLinkLabelsSkipAngle={ arcLinkLabelsSkipAngle }
      arcLinkLabelsTextColor={ arcLinkLabelsTextColor }
      arcLinkLabelsStraightLength={ arcLinkLabelsStraightLength }
      arcLinkLabelsThickness={ arcLinkLabelsThickness }
      arcLinkLabelsColor={ arcLinkLabelsColor }
      arcLinkLabel={ arcLinkLabel }
      legends={ [
        {
          anchor: anchor,
          direction: direction,
          translateY: translateY,
          itemWidth: itemWidth,
          itemHeight: itemHeight,
          symbolSize: symbolSize,
          symbolShape: symbolShape,
          translateX: translateX,
          itemsSpacing: itemsSpacing,
        }
      ] }
      enableArcLabels={ false }
    />
  }
)
