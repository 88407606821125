import * as React from 'react';
import { Link, NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { t } from '../../base/helpers';
import RenderRoutes from '../../routes/RenderRoutes';
import { paymentsRoutes } from '../../routes/paymentsRoutes';
import { Loader } from '@ppg/styled';
import { inject, observer } from 'mobx-react';
import { PaymentDataStore } from '../../stores/payment/PaymentDataStore';
import { PaymentsStore } from '../../stores/payment/PaymentsStore';
import './Payments.scss';
import { PaymentsBalanceDetails } from './PaymentsBalanceDetails';
import { PlanDetails } from './PlanDetails';
import { PPGLite, PPGLiteRole } from '../../components/PPGLite/PPGLite';
import { observable } from 'mobx';
import { UserHeader } from '../User/Layouts/UserLayout/UserHeader/UserLayoutHeader';
import { ProjectRoutesTypes } from '../../routes/moduleProjectRoutes';
import { PaymentsRoutesTypes } from '../../routes/paymentsRoutes';

interface IPaymentsLayoutProps extends RouteComponentProps {
  paymentDataStore?: PaymentDataStore;
  paymentsStore?: PaymentsStore;
}

@inject('paymentDataStore', 'paymentsStore')
@observer
class PaymentsLayoutComponent extends React.Component<IPaymentsLayoutProps, {}> {
  @observable isLoading: boolean = true;

  async componentDidMount() {
    await this.props.paymentDataStore.fetchPaymentData().then(() => this.isLoading = false);
  }

  private isInvoiceDetails(): boolean {
    return this.props.location.pathname.includes('invoice-details');
  }

  componentWillUnmount(): void {
    this.props.paymentsStore.clearView();
  }

  public render() {
    if (this.isLoading) {
      return <Loader/>;
    }

    if (this.isInvoiceDetails()) {
      return <RenderRoutes routes={ paymentsRoutes.InvoiceDataFree }/>;
    }

    return (
      <div>
        {
          this.props.paymentDataStore.hasPaymentData
            ?
            <div>
              <Header { ...this.props }/>
              <div>
                <RenderRoutes routes={ paymentsRoutes.InvoiceDataRequired }/>
              </div>
            </div>
            : <Blocked/>
        }
      </div>
    );
  }
}

class Blocked extends React.Component<{}, any> {

  public render() {
    return (
      <div>
        <div className="alert-block alert--error">
          { t('Your payment is blocked right now.') } <br/>
          <Link to={ PaymentsRoutesTypes.INVOICE_DETAILS }>{ t('Go to company info') }</Link>
          &nbsp;
          { t('and') }
          &nbsp;
          <Link to={ ProjectRoutesTypes.USER_ACCOUNT_SETTINGS }>{ t('profile') }</Link>
          &nbsp;
          { t('to complete required fields and unlock this section.') }
        </div>
      </div>
    );
  }
}

class Header extends React.Component {

  public render() {
    return <>
      <div className="payments-header-wrapper row">
        <div className="column">
          <UserHeader title={ t('Payments') } subtitle={ t('Look into your invoice list, control your account balance and current plan.') }/>
          <PaymentsBalanceDetails/>
        </div>
      </div>
      <PPGLite role={ PPGLiteRole.DISPLAY }>
        <div className="row">
          <div className="column">
            <PlanDetails/>
          </div>
        </div>
      </PPGLite>
      <div className="button-group m20b">
        <NavLink activeClassName="active" className="button button--medium button--default" to={ PaymentsRoutesTypes.INVOICES_LIST }>{ t('Invoice list') }</NavLink>
        <NavLink activeClassName="active" className="button button--medium button--default" to={ PaymentsRoutesTypes.INVOICES_CARDS }>{ t('Cards') }</NavLink>
      </div>
    </>;
  }
}

export const PaymentsLayout = withRouter(PaymentsLayoutComponent);


export default PaymentsLayout;

