import * as React from 'react';
import { Button, CheckBox } from '@ppg/styled';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { redirect, t } from '../../../base/helpers';
import { CardsStore } from '../../../stores/payment/CardsStore';
import { PaymentsStore } from '../../../stores/payment/PaymentsStore';
import { UserStore } from '../../../stores/user/UserStore';
import { requestToAddCardUseCase } from '../../../useCases/accounting/RequestToAddCardUseCase';
import { requestToPayInvoicesUseCase } from '../../../useCases/accounting/RequestToPayInvoicesUseCase';
import { PaymentsRoutesTypes } from '../../../routes/paymentsRoutes';

interface ICheckoutForm {
  onSuccessfulCheckout?: any;
  userStore?: UserStore;
  paymentsStore?: PaymentsStore;
  cardsStore?: CardsStore;
  stripe: Stripe;
  elements?: StripeElements;
  CARD_OPTIONS?: any;
}

@inject('cardsStore', 'paymentsStore', 'userStore')
@observer
class CheckoutFormComponent extends React.Component<ICheckoutForm, {}> {
  @observable checkoutError: string = '';
  @observable isProcessing: boolean = false;

  public componentDidMount() {
    this.props.cardsStore.fetchCards();
  }

  private getLink(lang): string {
    switch (lang) {
      case 'pl':
        return 'https://pushpushgo.com/pl/pages/regulamin/';
      case 'en':
      case 'pt':
      case 'es':
        return 'https://pushpushgo.com/en/pages/rules/';
      default:
        return 'https://pushpushgo.com/en/pages/rules/';
    }
  }

  public handleFormSubmit = async e => {
    e.preventDefault();
    const { stripe, elements, paymentsStore, cardsStore } = this.props;

    if (!stripe || !elements) {
      return;
    }

    this.isProcessing = true;

    if (cardsStore.showCards) {

      const { secret, action } = await requestToPayInvoicesUseCase.exec({
        invoices: paymentsStore.transaction.invoicesId,
        card: paymentsStore.transaction.card.id,
        currency: paymentsStore.transaction.currency,
        organization: paymentsStore.unpaidInvoices._organizationId
      });

      if (action) {
        const cardAction = await stripe.handleCardAction(secret);
        await paymentsStore.confirmPayment(cardAction.paymentIntent.id);
      }

      this.isProcessing = false;
      redirect(PaymentsRoutesTypes.SUCCESS);
    } else {
      const billingDetails = {
        name: e.target.name.value
      };

      const cardElement = elements.getElement('card');

      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: billingDetails,
      });

      if (error) {
        // redirect to error view
        console.log('error', error);
        this.isProcessing = false;
      }

      if (paymentMethod) {

        const { secret, action } = await requestToPayInvoicesUseCase.exec({
          invoices: paymentsStore.transaction.invoicesId,
          card: paymentMethod.id,
          currency: paymentsStore.transaction.currency,
          organization: paymentsStore.unpaidInvoices._organizationId
        });

        if (action) {
          const cardAction = await stripe.handleCardAction(secret);
          await paymentsStore.confirmPayment(cardAction.paymentIntent.id);
        }

        if (paymentsStore.transaction.saveNewCard) {
          const { secretKey } = await requestToAddCardUseCase.exec({
            organization: paymentsStore.unpaidInvoices._organizationId
          });

          await stripe.confirmCardSetup(secretKey, {
            payment_method: {
              card: cardElement,
              billing_details: billingDetails
            }
          })
        }

        this.isProcessing = false;
        redirect(PaymentsRoutesTypes.SUCCESS);
      }
    }
  };

  public handleCardDetailsChange = e => {
    this.checkoutError = e.error ? e.error.message : '';
  };

  public handleCardSelect(card) {
    this.props.paymentsStore.transaction.card = card;
  }

  public render() {
    const { stripe, cardsStore, CARD_OPTIONS, paymentsStore, userStore } = this.props;

    const noCardChosen = cardsStore.showCards && paymentsStore.transaction.card === null;
    const termsLink = this.getLink(userStore.lang);
    const termsAccepted = paymentsStore.transaction.acceptTerms === true;

    return <form id={ 'stripe-checkout-form' } onSubmit={ this.handleFormSubmit }>
      { cardsStore.hasSavedCards
      && <CheckBox property={ cardsStore.getProperty('showCards') }
                   label={ t('Choose one of your saved cards') }/>
      }
      { cardsStore.showCards
        ? this.renderSavedCards()
        : <div className='card-biling-details'>
          <label>{ t('Name on card') }
            <input name='name'
                   type='text'
                   placeholder={ t('Adam Kowalski') } required/>
          </label>
          <CardElement options={ CARD_OPTIONS }
                       onChange={ this.handleCardDetailsChange }/>
          <div className="error-wrapper">
            { this.checkoutError && <div id="card-errors" className='error-container' role="alert"><span
              className='icon-cancel-badge'/>{ this.checkoutError }</div> }
          </div>

          <div className="card-terms">
            <CheckBox
              safeLessLabel={ t('I accept <a target="_blank" href="%{rules}">terms of service.',
                { rules: termsLink }) }
              property={ paymentsStore.transaction.getProperty('acceptTerms') }/>
          </div>

          <div className="save-new-card-wrapper">
            <CheckBox property={ paymentsStore.transaction.getProperty('saveNewCard') }
                      label={ t('Add this card to my card list') }/>
          </div>

        </div> }

      <div className='checkout-button-wrapper'>
        <Button disabled={ this.isProcessing || !stripe || noCardChosen || !termsAccepted }
                id={ 'submit' }
                confirmButtonFormId={ 'stripe-checkout-form' }
                content={ this.isProcessing ? t('Processing...') : t('Make a payment') }/>
      </div>
    </form>
  }

  private renderSavedCards() {
    const { cardsStore, paymentsStore, userStore } = this.props;
    const termsLink = this.getLink(userStore.lang);

    return <>
      { cardsStore.hasSavedCards && cardsStore.cards.map(card => {
        return <div key={ card.id }
                    className={ `card-list--item ${ paymentsStore.transaction.card && paymentsStore.transaction.card.id === card.id ? 'selected-card' : '' } ${ paymentsStore.transaction.card && paymentsStore.transaction.card.id !== card.id ? 'not-selected-card' : '' }` }
                    onClick={ () => this.handleCardSelect(card) }>
          <div className={ `card card-${ card.brand }` }/>
          <span>**** **** **** { card.number }</span>
          <span>{ card.expireMonth }/{ card.expireYear }</span>
        </div>
      }) }
      <div className="card-terms">
        <CheckBox
          safeLessLabel={ t('I accept <a target="_blank" href="%{rules}">terms of service.',
            { rules: termsLink }) }
          property={ paymentsStore.transaction.getProperty('acceptTerms') }/>
      </div>
    </>
  }
}

const CheckoutForm = (props) => {
  return <ElementsConsumer>
    { ({ stripe, elements }) => (
      <CheckoutFormComponent stripe={ stripe } elements={ elements } { ...props } />
    ) }
  </ElementsConsumer>
};

export default CheckoutForm;
