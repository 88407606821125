import { API, UseCase } from '@ppg/common';

interface ICreatePaylanePayapalOrder {
  organization: string;
  invoices: string[];
  amount: number;
  currency: string;
}

export class CreatePaylanePaypalOrderUseCase extends UseCase<ICreatePaylanePayapalOrder, string> {
  protected async execute({organization, invoices, amount, currency }) {
    return API.HTTP.post<string>(`acc/organizations/${ organization }/orders/paylane`, {
      invoices, amount, currency
    })
  }
}

export const createPaylanePaypalOrderUseCase = new CreatePaylanePaypalOrderUseCase();
