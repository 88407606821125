import { property, PropertyHandler } from "@ppg/common";
import { SystemLabelWrapper } from "../../../modelsMobx/SystemLabelWrapper";
import { t } from "../../../base/helpers";
import { LabelStrategyEnum } from "../../../modelsMobx/LabelStrategyEnum";
import { SimpleType } from "../../../modelsMobx/CustomField";
import { formatLabelUseCase } from "../../../useCases/automation";
import { action } from "mobx";

export interface IAutomationLabel {
  key: string;
  value: string;
  ttl: number;
  strategy: LabelStrategyEnum;
  type: SimpleType;
}

export class AutomationLabel extends PropertyHandler implements IAutomationLabel {

  static createLabel(props: Partial<IAutomationLabel>) {
    return new AutomationLabel(props.key, props.value, props.ttl, props.strategy, props.type);
  }

  @property() public key: string;
  @property() public value: string;
  @property() public ttl: number;
  @property() public strategy: LabelStrategyEnum;
  @property() public type: SimpleType | null;

  constructor(
    key: string,
    value: string,
    ttl: number = 0,
    strategy: LabelStrategyEnum = LabelStrategyEnum.APPEND,
    type: SimpleType = null
  ) {
    super();

    this.key = key;
    this.value = value;
    this.ttl = ttl;
    this.strategy = strategy;
    this.type = type;
  }

  public setType(type: SimpleType): void {
    this.type = type;
  }

  public getType(): SimpleType {
    return this.type;
  }

  public isKeyDefault(): boolean {
    return this.key.toLowerCase() === 'default';
  }

  public getLabelKey(): string {
    return new SystemLabelWrapper(this).labelKey;
  }

  public getLabelIdentity(): string {
    return this.serialize() + this.getDetails();
  }

  public serialize(): string {
    return `${ this.key }:${ this.value }`;
  }

  public getDetails(): string {
    const ttl = this.ttl && ` exp. ${ this.getHumanReadableTTL() }` || '';
    return `${ this.strategy }${ ttl }`;
  }

  @action
  public async formatValues(): Promise<boolean> {
    let wasFormatted = false;

    if (this.key) {
      const keyFormatted = await formatLabelUseCase.exec({ key: this.key });

      wasFormatted = wasFormatted || keyFormatted.result !== this.key;
      this.key = keyFormatted.result;
    }

    if (typeof this.value !== 'undefined') {
      const valueFormatted = await formatLabelUseCase.exec({ value: this.value });

      wasFormatted = wasFormatted || valueFormatted.result !== this.value;
      this.value = valueFormatted.result;
    }

    return wasFormatted;
  }

  public validateCfLabel(): string | null {
    const validateLabelName = /^[A-Za-z0-9\-_ ]+$/.test(this.key);

    if (!this.value && !this.key) {
      return t('Name and value are required');
    } else if (!this.key) {
      return t('Name is required');
    } else if (!this.value) {
      return t('Value is required');
    } else if (this.key && !validateLabelName) {
      return t('Special characters are not allowed in the name field')
    }

    return null
  }

  public validateLabels(): string | null {
    const validateLabelName = /^[A-Za-z0-9\-._ ]+$/.test(this.key);

    if (!this.value) {
      return t('Value is required')
    } else if (this.key && !validateLabelName) {
      return t('Special characters are not allowed in the name field')
    }

    return null
  }

  public clearValues(): void {
    this.key = "";
    this.value = "";
    this.ttl = 0;
    this.strategy = LabelStrategyEnum.APPEND;
  }

  private getHumanReadableTTL(): string {
    const patterns: [Function, string][] = [
      [(value: number) => value / (24 * 60 * 60), 'd.'],
      [(value: number) => value / (60 * 60), 'h.'],
      [(value: number) => value / 60, 'm.'],
      [(value: number) => value, 's.'],
    ];

    for (let [pattern, unit] of patterns) {
      const result = pattern(this.ttl);

      if (result > 0 && Number.isInteger(result)) {
        return `${ result } ${ unit }`;
      }
    }
  }

}