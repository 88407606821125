import { getDateAndTimeFromStringDate, property, PropertyHandler } from '@ppg/common';
import { IChannel, IMeta, ISubscriberCustomField, ISubscriberDTO, SubscriberContext } from './interfaces';
import { ChannelType } from '../../models/ChannelType';
import { ISubscriberEventTypes } from '../../models/ISubscriberEvent';
import { SubscriberLabel } from './SubscriberLabel';
import { ISubscriberLabels } from '../../useCases/core/subscriber/GetSubscribersLabelsUseCase';
import { IInfinityScrollPaginationGetMore, toast } from '@ppg/styled';
import { action } from 'mobx';
import {
  createSubscriberLabelsUseCase,
  deleteSubscriberLabelsUseCase,
  getSubscribersLabelsUseCase
} from '../../useCases/core';
import { SubscribersStore } from '../../stores/project/SubscribersStore';
import { AutomationProjectDataStore } from "../../stores/project/AutomationProjectDataStore";
import { t } from '../../base/helpers';

export interface ILabelCreateDTO {
  key: string;
  value: string | number | boolean;
  expireDate: string;
}

export interface ILabelDeleteDTO {
  key: string;
  value: string | number | boolean;
}

export interface ISubscriberSelector {
  name: string;
  value: any;
}

enum LastSessionSelectors {
  LAST_VISIT_DATE = 'lastVisitDate',
  PAGE_VISIT_TIME = 'pageVisitTime',
  ORIGIN = 'origin',
  REFERRER = 'referrer',
  URL = 'url',
  TAGS = 'tags',
  GEOLOCATION = 'geoLocation',
  TEST_MODE = 'testMode',
  SERIALIZED_TAGS = 'serializedTags',
  CUSTOM_ID = 'customId',
  TAGS_TO_DELETE = 'tagsToDelete'
}

export type ISelectorsType = Record<string, string | number>[];

interface ISubscriber {
  id: string;
  customId: string;
  tzOffset: number;
  channels: IChannel[];
  labels: SubscriberLabel[];
  customFields: ISubscriberCustomField[];
  meta: IMeta;
  createdAt: string;
  subscriberContext: SubscriberContext;
  selectors: ISubscriberSelector[];
}

export class Subscriber extends PropertyHandler implements ISubscriber {
  @property() public id: string;
  @property() public customId: string;
  @property() public tzOffset: number;
  @property() public channels: IChannel[];
  @property() public labels: SubscriberLabel[];
  @property() public customFields: ISubscriberCustomField[];
  @property() public meta: IMeta;
  @property() public createdAt: string;
  @property() public subscriberContext: SubscriberContext;
  @property() public selectors: ISubscriberSelector[];

  @property() public labelsToCreate: SubscriberLabel[] = [];
  @property() public labelsToDelete: SubscriberLabel[] = [];

  constructor(subscriber: ISubscriber, private readonly automationProjectDataStore: AutomationProjectDataStore) {
    super();
    this.id = subscriber.id;
    this.customId = subscriber.customId;
    this.tzOffset = subscriber.tzOffset;
    this.channels = subscriber.channels;
    this.labels = subscriber.labels;
    this.customFields = subscriber.customFields;
    this.meta = subscriber.meta;
    this.createdAt = subscriber.createdAt;
    this.subscriberContext = subscriber.subscriberContext;
    this.selectors = subscriber.selectors;
  }

  static createSubscriber(subscriber: ISubscriberDTO, automationProjectDataStore: AutomationProjectDataStore) {
    return new Subscriber({ ...subscriber, labels: [], selectors: [] }, automationProjectDataStore);
  }

  public get createdAtDate(): string {
    return getDateAndTimeFromStringDate(this.createdAt);
  }

  public isFromMobileChannel = (): boolean => {
    const channel = this.channels.find(channel => {
      channel.type = ChannelType.MPUSH;
    });

    if (!channel) {
      return false;
    }

    return !!channel;
  };

  public isSubscribed = (): boolean => {
    const channel = this.channels.find(channel => {
      return channel.status === ISubscriberEventTypes.STATUS_SUBSCRIBED;
    });

    if (!channel) {
      return false;
    }

    return !!channel;
  };

  public get statusIcon(): string {
    return this.isFromMobileChannel() ? 'icon-phone' : 'icon-bell';
  }

  public get statusColor(): string {
    return this.isSubscribed() ? 'text-green' : 'text-grey';
  }

  public async fetchSubscriberLabels(limit: number, offset: number): Promise<ISubscriberLabels[]> {
    return await getSubscribersLabelsUseCase.exec({
      offset,
      limit,
      subscribers: [this.id]
    });
  }

  public appendLabels(labels: SubscriberLabel[]): void {

    for (let label of labels) {
      this.automationProjectDataStore.appendUserLabel(label.key, `${ label.value }`);
    }

    this.labels.push(...labels);
  }

  @action
  public async createSubscriberLabels(): Promise<void> {
    const labels: SubscriberLabel[] = this.labelsToCreate.filter(label => !this.labelsToDelete.includes(label));

    await createSubscriberLabelsUseCase.exec({
      subscriberId: this.id,
      labels: labels.map(label => label.convertToCreateDTO())
    });

    this.appendLabels(labels);
  }

  @action
  public async deleteSubscriberLabels(): Promise<void> {
    const labels: SubscriberLabel[] = this.labelsToDelete.filter(label => !this.labelsToCreate.includes(label));

    await deleteSubscriberLabelsUseCase.exec({
      subscriberId: this.id,
      labels: labels.map(label => label.convertToDeleteDTO())
    });

    const idsToRemove = labels.map(item => item.getKey);

    for (let index in this.labels) {
      const element = this.labels[index];
      const toRemoveKey = element.getKey;

      if (idsToRemove.some(key => key === toRemoveKey)) {
        this.labels.splice(~~index, 1);
      }
    }
  }

  public async getMoreLabels(params: IInfinityScrollPaginationGetMore): Promise<number> {
    const [label] = await this.fetchSubscriberLabels(params.limit, params.offset);

    this.appendLabels(label.labels);

    return label.count;
  }

  public get mappedSelectors(): ISelectorsType {
    return this.selectors.reduce((result, selector) => {
      result[selector.name] = selector.value;
      return result;
    }, {} as ISelectorsType);
  }

  public setSelectors(selectors: ISubscriberSelector[]): void {
    this.selectors = selectors;
  }

  public async fetchSelectors(): Promise<void> {
    const selectors = await this.getSubscriberSelectors();
    this.setSelectors(selectors);
  }

  private async getSubscriberSelectors(): Promise<ISubscriberSelector[]> {
    const excludedSelectors: LastSessionSelectors[] = Object.values(LastSessionSelectors);
    const subscriberContext = this.subscriberContext;

    const allSelectors = Object.keys(subscriberContext).map(key => {
      return { name: key, value: subscriberContext[key] };
    });

    return allSelectors.filter((selector) => {
      return excludedSelectors.every((excludedSelector) => excludedSelector !== selector.name);
    });
  }

  @action
  public async handleCreateLabel(label: SubscriberLabel): Promise<boolean> {
    if (label.validate()) {
      toast.error(label.validate);
      return;
    }

    const wasFormatted = await label.formatValues();
    if (wasFormatted) {
      toast.success(t('We reformat label and value that you enter to allowed characters'));
    }

    label.key = label.key ? label.key : "default";
    label.expiresAt = label.expiresAt ? label.expiresAt : null;

    const sameLabelIsAddToCreate = this.labelsToCreate.some(labelToCreate => labelToCreate.serialize() === label.serialize());
    const sameLabelIsAddToSubscriber = this.labels.some(subLabel => subLabel.serialize() === label.serialize());

    if (sameLabelIsAddToCreate || sameLabelIsAddToSubscriber) {
      toast.error(t('Tag with this name and value already was added'));
      return false;
    }

    this.labelsToCreate.push(label);
    return true;
  }

  @action
  public handleDeleteLabel(label: SubscriberLabel): void {
    this.labelsToDelete.push(label);
  }

  @action
  public async confirmLabelsEditorModal(): Promise<void> {
    await this.deleteSubscriberLabels();
    await this.createSubscriberLabels();
  };

  public async refreshGetMoreLabels(): Promise<void> {
    this.clearLabels();
    await this.getMoreLabels({ limit: SubscribersStore.SUBSCRIBERS_LABELS_LIMIT, offset: 0 });
  }

  public get disabledConfirmLabelsEditorModal(): boolean {
    return this.labelsToCreate.length === 0 && this.labelsToDelete.length === 0;
  }

  public get labelsBeforeCommit(): SubscriberLabel[] {
    const idsToFilter = this.labelsToDelete.map(item => item.getKey);
    const isNotInDeleted = label => !idsToFilter.some(item => label.getKey === item);
    return this.labelsToCreate.concat(this.labels).filter(isNotInDeleted);
  }

  public get availableLabels(): SubscriberLabel[] {
    return this.labels;
  }

  @action
  public clearEditLabels(): void {
    this.labelsToDelete = [];
    this.labelsToCreate = [];
  }

  @action
  private clearLabels(): void {
    this.labels = [];
  }
}
