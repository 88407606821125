import { API, UseCase } from "@ppg/common";
import { getPlatformTypeParam, getRangeDateParam, getTimezoneParam } from '../../../helpers';
import { IGetSubscribedActivityResult, IOrganizationDashboardRequestBody } from '../../../interfaces';

export class GetSubscribedActivityUseCase extends UseCase<IOrganizationDashboardRequestBody, IGetSubscribedActivityResult> {
  protected async execute({ organization, platform, from, to, timezone }: IOrganizationDashboardRequestBody) {
    const endpoint = `statistics/organizations/${ organization }/subscribers/activity`
      + getPlatformTypeParam(platform) + getRangeDateParam(from, to) + getTimezoneParam(timezone);

    return API.HTTP.get<IGetSubscribedActivityResult>(endpoint);
  }
}

