import * as React from 'react';
import { Redirect, RouteProps } from 'react-router-dom';

import { t } from '../base/helpers';
import { PaymentsCheckout } from '../pages/Payments/PaymentsCheckout/PaymentsCheckout';
import { PaymentsSuccess } from '../pages/Payments/PaymentsCheckout/PaymentsSuccess';
import { PaymentsConfirm } from '../pages/Payments/PaymentsCheckout/PaymentsConfirm';
import { InvoiceDetails } from '../pages/User/Payments/InvoiceDetails/InvoiceDetails';
import { Payments } from '../pages/User/Payments/Payments';

const lazy = (React as any).lazy;

const PaymentsInvoiceList = lazy(() => import(/* webpackChunkName: 'PaymentsInvoiceList' */ '../pages/Payments/PaymentsInvoiceList'));
const PaymentsCards = lazy(() => import(/* webpackChunkName: 'PaymentsCards' */ '../pages/Payments/PaymentsCards'));

export enum PaymentsRoutesTypes {
  PAYMENTS = '/user/payments',
  CHECKOUT = '/user/payments/checkout',
  SUCCESS = '/user/payments/success',
  INVOICES = '/user/payments/invoices',
  CONFIRM = '/user/payments/confirm',
  INVOICE_DETAILS = '/user/payments/invoice-details',
  INVOICES_LIST = '/user/payments/invoices/list',
  INVOICES_CARDS = '/user/payments/invoices/cards',
  PLAN = '/user/payments/plan'
}

export const paymentsRoutes: IPaymentsRoutesTypes  = {
  InvoiceDataFree: [
    { title: t('Invoice details'), path: PaymentsRoutesTypes.INVOICE_DETAILS, component: InvoiceDetails },
  ],
  InvoiceDataRequired: [
    { exact: true, title: t('Payments'), path: PaymentsRoutesTypes.PAYMENTS, component: () => <Redirect to={PaymentsRoutesTypes.INVOICES_LIST}/> },
    { exact: true, title: t('Invoices'), path: PaymentsRoutesTypes.INVOICES, component: () => <Redirect to={PaymentsRoutesTypes.INVOICES_LIST}/> },
    { title: t('Cards'), path: PaymentsRoutesTypes.INVOICES_CARDS, component: PaymentsCards },
    { title: t('Invoice List'), path: PaymentsRoutesTypes.INVOICES_LIST, component: PaymentsInvoiceList },
    { title: t('Checkout'), path: PaymentsRoutesTypes.CHECKOUT, component: PaymentsCheckout },
    { title: t('Success'), path: PaymentsRoutesTypes.SUCCESS, component: PaymentsSuccess },
    { title: t('Payment Confirm'), path: PaymentsRoutesTypes.CONFIRM, component: PaymentsConfirm },
  ]
}

interface IRoutesTypes extends RouteProps {
  title?: string;
}

interface IPaymentsRoutesTypes {
  InvoiceDataFree: IRoutesTypes[],
  InvoiceDataRequired: IRoutesTypes[]
}
