import * as React from 'react';
import { observer } from 'mobx-react';
import { IPieChartProps, PieChart } from '../../../../components/Charts/PieChart/PieChart';
import './PieChartCard.scss'
import { DEFAULT_LEGENDS_PROPERTIES, DEFAULT_PIE_CHART_PROPERTIES } from '../../../../components/Charts/PieChart/defaultValues';
import { observable } from 'mobx';
import { LegendAnchor } from '@nivo/legends';
import { DefaultRawDatum } from '@nivo/pie';
import { ChartLoader, EmptyDataComponent, LineLoader } from '@ppg/styled';
import { organizationDashboardStore } from '../../../../stores';
import { isArrayEmpty } from '@ppg/common';
import { t } from '../../../../base/helpers';

interface IPieChartCardProps extends IPieChartProps{
  title: string
  data: DefaultRawDatum[],
  isLoading: boolean,
  initialLoader: boolean,
}

@observer
export class PieChartCard extends React.Component<IPieChartCardProps, {}> {
  @observable public isMobile: boolean = false;
  @observable public isLaptop: boolean = false;

  public componentDidMount(): void {
    this.checkDeviceWidth();
    window.addEventListener('resize', this.checkDeviceWidth);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.checkDeviceWidth)
  }

  checkDeviceWidth = () => {
    const MOBILE_BREAKPOINT = 480;
    const LAPTOP_BREAKPOINT = 1430;
    this.isMobile = window.innerWidth < MOBILE_BREAKPOINT;
    this.isLaptop = window.innerWidth < LAPTOP_BREAKPOINT;
  }

  public render() {
    const { title, data, isLoading } = this.props;
    const MOBILE_ANCHOR: LegendAnchor = 'bottom-left'

    const customPieChartValues = {
      margin: this.isMobile ? { "top": 20, "bottom": 80 } : DEFAULT_PIE_CHART_PROPERTIES.margin,
      radialLabelsLinkDiagonalLength: this.isMobile ? 5 : 10,
      radialLabelsLinkHorizontalLength: this.isMobile ? 5 : 10,
      anchor: this.isLaptop ? MOBILE_ANCHOR : DEFAULT_LEGENDS_PROPERTIES.anchor,
      cornerRadius: this.isMobile ? 0 : DEFAULT_PIE_CHART_PROPERTIES.cornerRadius,
      arcLinkLabel: DEFAULT_PIE_CHART_PROPERTIES.arcLinkPercentValue
    }

    const showEmptyComponent = (): boolean => {
      return !isLoading && (isArrayEmpty(data) || !organizationDashboardStore.isPlatformSelected());
    }

    const renderCardContent = (): JSX.Element => {
      if (isLoading && !this.props.initialLoader) {
        return <div className={ 'well chartCard__card' }>
          <LineLoader/>
          <ChartLoader/>
        </ div>
      }

      if (showEmptyComponent()) {
        return <>
          <EmptyDataComponent icon={ 'stat-lined' } content={ t('Sorry, we don\'t have data to show for the filters you\'ve chosen') } hideButton/>
        </>
      }

      return <div className={ 'well chartCard__card' }>
        { isLoading && <LineLoader/> }
        <PieChart data={ data }
                  defs={ DEFAULT_PIE_CHART_PROPERTIES.defs }
                  margin={ customPieChartValues.margin }
                  arcLinkLabelsDiagonalLength={ customPieChartValues.radialLabelsLinkDiagonalLength }
                  arcLinkLabelsStraightLength={ customPieChartValues.radialLabelsLinkHorizontalLength }
                  cornerRadius={ customPieChartValues.cornerRadius }
                  arcLinkLabel={ customPieChartValues.arcLinkLabel }
                  {...this.props}
        />
      </ div>
    }



    return <div className={ 'chartCard' }>
      <span className={ 'chartCard__title' }>{ title }</span>
        { renderCardContent() }
    </div>
  }
}
