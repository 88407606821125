import { API, UseCase } from "@ppg/common";
import { ILabelCreateDTO } from "../../../modelsMobx/subscriber/Subscriber";

interface ICreateSubscriberLabelsRequest {
  subscriberId: string;
  labels: ILabelCreateDTO[];
}

export class CreateSubscriberLabelsUseCase extends UseCase<ICreateSubscriberLabelsRequest, void> {
  protected async execute({subscriberId, labels}: ICreateSubscriberLabelsRequest) {
    return API.HTTP.post<void>(`core/projects/${ API.HTTP.currentProjectId }/segmentation/subscribers/${subscriberId}`, {labels});
  }
}

export const createSubscriberLabelsUseCase = new CreateSubscriberLabelsUseCase();
