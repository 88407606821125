import { API, UseCase } from '@ppg/common';

interface IChargePPGLiteUser {
  organization: string
}

export class ChargePPGLiteUser extends UseCase<IChargePPGLiteUser, void> {
  protected async execute({organization}) {
    return API.HTTP.post<void>(
      `acc/organizations/${organization}/payments/cards/ppg-lite/charge`
    )
  }
}

export const chargePPGLiteUser = new ChargePPGLiteUser();
