import { UseCase, API } from '@ppg/common';

interface RegisterData {
  email: string;
  password: string;
  repeatedPassword: string;
  organizationName: string;
  acceptRules: boolean;
  language: string;
  privacyPolicyCheckDate: string;
}

interface RegisterResult {
  token: string;
}

export class RegisterFullUserUseCase extends UseCase<RegisterData, RegisterResult> {
  protected async execute({ email, password, repeatedPassword, organizationName, acceptRules, language, privacyPolicyCheckDate }) {
    return API.HTTP.post<RegisterResult>('aai/user/full-register',
      { email, password, repeatedPassword, organizationName, acceptRules, language, privacyPolicyCheckDate });
  }
}

export const registerFullUserUseCase = new RegisterFullUserUseCase();
