import * as React from 'react';
import { UserStore } from '../../stores/user/UserStore';
import { t } from '../../base/helpers';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Input } from '@ppg/styled';
import './Authorization.scss';
import { observable } from 'mobx';
import { SaveButtonWrapped } from '../../assets/wrapped';

interface IResetPasswordProps {
  userStore?: UserStore;
}

@inject('userStore')
@observer
export class ResetPassword extends React.Component<IResetPasswordProps> {
  @observable showConfirmation: boolean = false;

  public async handleChangePasswordClick() {
    const { userStore } = this.props;
    userStore.user.validate();

    return userStore.authorization
      .firstStepPasswordReset()
      .then(() => this.showConfirmation = true)
      .catch(error => console.error(error.message))
  }

  public render() {
    return (
      <div className='reset-page-wrapper' data-cy={'reset-password--wrapper'}>
        <h1>{ t('Reset password') }</h1>
        {
          !this.showConfirmation
            ? <>
              <form className="auth-form app-form-region">
                <p>{ t("Forgot your password? Don't worry, it can happen to anyone.") }</p>
                <p>{ t("Enter the e-mail associated with your account and we'll send you a link to reset your password.") }</p>

                <Input
                  label={ t('E-mail') }
                  dataCy={ 'reset-input--email' }
                  property={ this.props.userStore.user.getProperty('username') }/>

                <div className="bottom-actions">
                  <SaveButtonWrapped preventToast={ true }
                                     content={ t('Send reset link') }
                                     dataCy={ 'reset-button--submit' }
                                     callback={ () => this.handleChangePasswordClick() }/>
                </div>
              </form>
            </>
            : <div className='feedback-message'>
              <span className='icon-msg feedback-icon'/>
              <p>{ t("We've sent you an e-mail with the instructions. Please click the link when you get it, it will be active for 20 minutes.") }</p>
            </div>
        }
        <div className="switch-to-login">
                <span className="app-reset">
                  { t('Back to') }
                  <Link className='app-register-button' to="/login">{ t('Login') }</Link>
                </span>
        </div>
      </div>
    );
  }
}
