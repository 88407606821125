import { API, UseCase } from '@ppg/common';
import { getListQueryParam, getPlatformTypeParam, getRangeDateParam, getTimezoneParam } from '../../../helpers';
import { IOrganizationDashboardRequestBody } from '../../../interfaces';

export interface IComparatorSubscriberHistogramItem {
  project: string;
  subscriberDays: {
    day: string;
    subscribed: number;
    unsubscribed: number;
  }[]
}

interface GetSubscriberComparatorHistogramRequest extends IOrganizationDashboardRequestBody {
  limit?: number;
  offset?: number;
}

export interface IGetSubscriberComparatorHistogramResult {
  subscribersHistogram: IComparatorSubscriberHistogramItem[];
  total: number;
}

export class GetSubscriberComparatorHistogramUseCase extends UseCase<GetSubscriberComparatorHistogramRequest, any> {
  protected async execute({
                            organization,
                            platform,
                            from,
                            to,
                            timezone,
                            limit,
                            offset
                          }: GetSubscriberComparatorHistogramRequest) {
    let endpoint = `statistics/organizations/${ organization }/subscribers/comparator/histogram`
      + getPlatformTypeParam(platform) + getRangeDateParam(from, to) + getTimezoneParam(timezone);

    if (limit !== undefined && offset !== undefined) {
      endpoint += getListQueryParam(offset, limit)
    }

    return API.HTTP.get<any>(endpoint);
  }
}

