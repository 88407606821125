import { API, UseCase } from "@ppg/common";

type IGetLabelsKeysResponse = string[];

export class GetLabelsKeysUseCase extends UseCase<null, IGetLabelsKeysResponse> {
  protected async execute() {
    return API.HTTP.get<IGetLabelsKeysResponse>(`core/projects/${ API.HTTP.currentProjectId }/segmentation/label-keys`);
  }
}

export const getLabelsKeysUseCase = new GetLabelsKeysUseCase();
