import { API, UseCase } from '@ppg/common';

interface ICreateEmailChannel {
  organization: string;
  receiverEmail: string;
  tags: string[];
  language: string;
  organizationType: string;
}

interface ICreateEmailChannelResult {
  id: string;
  language: string;
  payload: string;
  status: string;
  tags: string[];
  channelType: string;
}

export class CreateEmailChannelUseCase extends UseCase<ICreateEmailChannel, ICreateEmailChannelResult> {
  protected async execute({ organization, receiverEmail, tags, language, organizationType }) {
    return API.HTTP.post<ICreateEmailChannelResult>(`notification/organizations/${ organization }/channels/`,
      { receiverEmail, tags, language, organizationType });
  }
}

export const createEmailChannelUseCase = new CreateEmailChannelUseCase();
