import { UseCase, API } from '@ppg/common';
import { IUpdateColorTracking } from '../interfaces';

export class UpdateColorTrackingPluginUseCase extends UseCase<IUpdateColorTracking, void> {
  protected async execute({ websiteIntegrationId, enabled, selector }: IUpdateColorTracking) {
    await API.HTTP.put(`wi/projects/${ API.HTTP.currentProjectId }/website-integrations/${ websiteIntegrationId }/plugins/color-tracking`, { enabled, selector })
  }
}

export const updateColorTrackingPluginUseCase = new UpdateColorTrackingPluginUseCase();
