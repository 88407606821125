import * as React from 'react';
import { observer } from 'mobx-react';
import { SimpleProject } from '../../../../../modelsMobx/project/SimpleProject';
import * as Styled from './CampaignCopyToProjectsModal.styled';
import { CheckBox } from '@ppg/styled';
import { TypeOfProjects } from './CampaignCopyToProjectsModal';
import { CreatePushStore } from '../../../../../stores/project/CreatePushStore';
import { t } from '../../../../../base/helpers';

interface IProjectsList {
  typeOfProjects: TypeOfProjects;
  projectsList: SimpleProject[];
  createPushStore: CreatePushStore;
}

interface IProject {
  project: SimpleProject;
  onSelectProject: (project: SimpleProject) => void;
  onDeselectProject: (project: SimpleProject) => void;
  projectIsSelected: (project: SimpleProject) => boolean;
}

export const ProjectsList = observer(({ typeOfProjects, projectsList, createPushStore }: IProjectsList): JSX.Element => {

  const checkboxLabel: string =
    typeOfProjects === TypeOfProjects.OWNED ?
      t('campaign/list/pushes/all::Select all owned projects') :
      t('campaign/list/pushes/all::Select all shared projects');

  const checkboxProperty: string =
    typeOfProjects === TypeOfProjects.OWNED ?
      'isSelectedAllOwnedProjects' :
      'isSelectedAllSharedProjects';

  return <>
    <CheckBox
      property={ createPushStore.getProperty(checkboxProperty) }
      label={ checkboxLabel }
      onChange={ () => createPushStore.onSelectAllProjects(typeOfProjects) }
    />

    <Styled.ProjectsListBox>
      <Styled.ProjectsList>{ projectsList.map(project =>
        <ProjectItem
          key={ project.id }
          project={ project }
          onSelectProject={ (project: SimpleProject) => createPushStore.onSelectProject(project) }
          onDeselectProject={ (project: SimpleProject) => createPushStore.onDeselectProject(project) }
          projectIsSelected={ (project: SimpleProject) => createPushStore.projectIsSelected(project) }/>) }
      </Styled.ProjectsList>
    </Styled.ProjectsListBox>
  </>;
});

const ProjectItem = observer(({ project, onSelectProject, onDeselectProject, projectIsSelected }: IProject): JSX.Element => {
  const { name, siteUrl } = project;

  const isSelected: boolean = projectIsSelected(project);

  const handleSelectProject = (): void => {
    !isSelected && onSelectProject(project);
  };

  return <Styled.ProjectItem onClick={ () => handleSelectProject() } isSelected={ isSelected }>
    { isSelected && <Styled.DeselectProjectIcon onClick={ () => onDeselectProject(project) }>
      <span className="icon-close" />
    </Styled.DeselectProjectIcon> }

    <Styled.ProjectItemName>{ name }</Styled.ProjectItemName>
    <Styled.ProjectItemUrl>{ siteUrl }</Styled.ProjectItemUrl>
  </Styled.ProjectItem>;
});