import * as React from 'react';
import './Authorization.scss';
import { Loader } from '@ppg/styled';
import { UserStore } from '../../stores/user/UserStore';
import { inject, observer } from 'mobx-react';
import { redirect, t } from '../../base/helpers';
import { observable } from 'mobx';
import { ProjectRoutesTypes } from '../../routes/moduleProjectRoutes';

interface ILoginOnetConfirm {
  userStore: UserStore;
}

@inject('userStore')
@observer
export class LoginOnetConfirm extends React.Component<ILoginOnetConfirm> {
  @observable isLoading: boolean = true;
  @observable hasError: boolean = false;

  public async componentDidMount(): Promise<void> {
    const { userStore } = this.props;
    const { authorization } = userStore;

    userStore.logoutCleanData();

    const url = location.href;
    const urlWrapped = new URL(url);
    return authorization.confirmOauthUser(urlWrapped)
      .then(() => redirect(ProjectRoutesTypes.DASHBOARD))
      .catch(() => {
        this.hasError = true;
        this.isLoading = false;
      })
  }

  public render(): JSX.Element | false | null {
    if (this.isLoading) {
      return <div className={ 'login-page-wrapper login-page-wrapper--onet' }>
        <Loader/>
      </div>;
    }

    if (this.hasError) {
      return <div className={ 'login-page-wrapper login-page-wrapper--onet' }>
        <h1>{ t('Login') }</h1>
        <p>{ t('There was a problem while processing the request') }.</p>
      </div>
    }
  }
}
