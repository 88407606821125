import * as React from 'react';
import { Button, ButtonTypes, Help, Modal, toast, ConfirmIconButton } from '@ppg/styled';
import { inject, observer } from 'mobx-react';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { t } from '../../base/helpers';
import { CardsStore } from '../../stores/payment/CardsStore';
import CardPaymentModal from '../../components/CardPaymentModal/CardPaymentModal';
import { PPGLite, PPGLiteRole } from '../../components/PPGLite/PPGLite';
import ReactTooltip from 'react-tooltip';
import { PaymentCard as Card } from '../../modelsMobx/PaymentCard';

interface IPaymentsCards {
  cardsStore?: CardsStore;
  CARD_OPTIONS?: any;
  stripe?: Stripe;
  elements?: StripeElements;
  hideAddCardButton?: boolean;
}

@inject('cardsStore')
@observer
class PaymentsCardsComponent extends React.Component<IPaymentsCards, {}> {
  public async componentDidMount() {
    await this.props.cardsStore.fetchCards();
  }

  public async handleAddCard() {
    await this.props.cardsStore.requestToAddCard();
    this.props.cardsStore.enableCardModal();
  }

  private async handleDeleteCard(card: Card): Promise<void> {
    await this.props.cardsStore.removeCard(card.id)
  }

  public componentWillUnmount() {
    this.props.cardsStore.reset();
  }

  public render() {
    const { cardsStore, hideAddCardButton } = this.props;
    return (
      <div className="payments-cards-wrapper">
        { !hideAddCardButton && <div className="payments-cards-header">
          <Button size={ 'medium' }
                  icon={ 'plus-badge' }
                  onClick={ () => this.handleAddCard() }
                  content={ t('Add card') }/>
        </div> }

        {
          cardsStore.hasSavedCards
            ? <table className="payments-cards-table">
              { this.renderHeader() }
              <tbody>
              { this.renderCards() }
              </tbody>
            </table>
            : <>
              <table className="empty-cards-table">
                { this.renderHeader() }
              </table>
              <div className="empty-cards-table-info">
                { t('You don\'t have any cards added yet.') }
              </div>
            </>
        }

        <CardPaymentModal/>
      </div>
    );
  }

  private renderHeader() {
    return <thead className="borderb p10">
    <tr>
      <th className="card-img-header-cell">{ t('Card') }</th>
      <th className={ 'text-center' }>{ t('Number') }</th>

      <th className={ 'text-center' }> { t('Recurring payment') }
        <PPGLite role={ PPGLiteRole.HIDE }>
          <Help content={ t('If you do not want to remember to pay invoices regularly, activate recursive payments and select the default card from which we will collect payments.') }/>
        </PPGLite>
        <PPGLite role={ PPGLiteRole.DISPLAY }>
          <Help content={ t('@lite/user/payments/cards::Card from which we will collect payments for subscription.') }/>
        </PPGLite>
      </th>
      <th/>
    </tr>
    </thead>;
  }

  private setRecurringCardHandler = async (card): Promise<void> => {
    await this.props.cardsStore.setRecurringCard(card);
    await this.props.cardsStore.fetchCards();
    toast.success(t('Recurring payment card has been set up successfully'));
  };

  private renderCards() {
    const { cardsStore } = this.props;

    return cardsStore.cards.map(card => {
      return <tr key={ card.id } className="p10 borderb">
        <td data-label={ t('Card') } className="card-img-cell">
          <div className={ `card card-${ card.brand }` }/>
        </td>
        <td data-label={ t('Number') } className={ 'text-center' }> *** *** *** { card.number }</td>
        <td data-label={ t('Recurring payment') } className={ 'text-center' }>{ card.recurring ? <span className="icon-tick size12"/> : <span className="icon-close size14"/> }</td>
        <td className="cards-buttons-cell">
          <ConfirmIconButton icon={ 'trash' }
                             typeBtn="white"
                             positionBtn="row"
                             dataTip={ t('Delete card') }
                             dataTipConfirmBtn={ t('Confirm delete card') }
                             dataTipCancelBtn={ t('Cancel delete card') }
                             onConfirm={ () => this.handleDeleteCard(card) }/>

          <PPGLite role={ PPGLiteRole.HIDE }>
            <Button icon={ 'refresh' }
                    isSquare
                    dataTip={ t('Show recurring payments info') }
                    onClick={ () => cardsStore.onRecurringClicked(card) }
                    type={ ButtonTypes.DEFAULT }/>
          </PPGLite>
          <PPGLite role={ PPGLiteRole.DISPLAY }>
            <Button icon={ 'refresh' }
                    isSquare
                    dataTip={ t('Activate recurring payment on this card ') }
                    onClick={ () => this.setRecurringCardHandler(card) }
                    type={ ButtonTypes.DEFAULT }/>
          </PPGLite>
          <ReactTooltip type="light" effect="solid" place="top"/>
        </td>

        <Modal title={ <h1>{ card.recurring
          ? t('Recurring payment card')
          : t('Activate recurring payment with this card')
        }
        </h1> }
               content={ <div>
                 <p>{ card.recurring
                   ? t('You can always stop the recurring payments')
                   : t('Do you want to set recurring payments using this card?')
                 }</p>
                 <div key={ card.id }
                      className={ `row card-list--item` }>
                   <div className={ `column card-img-cell` }>
                     <span className={ `column card card-${ card.brand }` }/>
                   </div>
                   <span className={ 'column' }>**** **** **** { card.number }</span>
                   <span className={ 'column' }>{ card.expireMonth }/{ card.expireYear }</span>
                 </div>
               </div> }
               show={ cardsStore.cardForRecurringPayment === card }
               onConfirm={ () => cardsStore.onRecurringCardConfirmed() }
               confirmButton={ card.recurring ? t('Stop recurring payments') : t('Confirm') }
               cancelButton={ t('Cancel') }
               onCancel={ () => cardsStore.onRecurringCancel() }/>
      </tr>;
    });
  }
}

interface IPaymentsCardsProps {
  hideAddCardButton?: boolean;
}

const PaymentsCards = (props: IPaymentsCardsProps) => {
  return <ElementsConsumer>
    { ({ stripe, elements }) => (
      <PaymentsCardsComponent stripe={ stripe } elements={ elements } { ...props } />
    ) }
  </ElementsConsumer>;
};

export default PaymentsCards;
