import { FunnelSvgProps, ResponsiveFunnel } from '@nivo/funnel';
import { observer } from 'mobx-react';
import * as React from 'react';
import { FC } from 'react';
import { FunnelDatum } from '@nivo/funnel/dist/types/types';
import { DEFAULT_FUNNEL_CHART_PROPERTIES } from './defaultValues';
import { FunnelChartTooltip } from '../Tooltips/FunnelChartTooltip/FunnelChartTooltip';

export const FunnelChart: FC<Partial<FunnelSvgProps<FunnelDatum>>> = observer(
  ({
     data,
     margin = DEFAULT_FUNNEL_CHART_PROPERTIES.margin,
     colors = DEFAULT_FUNNEL_CHART_PROPERTIES.colors,
     borderWidth = DEFAULT_FUNNEL_CHART_PROPERTIES.borderWidth,
     labelColor = DEFAULT_FUNNEL_CHART_PROPERTIES.labelColor,
     beforeSeparatorLength = DEFAULT_FUNNEL_CHART_PROPERTIES.beforeSeparatorLength,
     beforeSeparatorOffset = DEFAULT_FUNNEL_CHART_PROPERTIES.beforeSeparatorOffset,
     afterSeparatorLength = DEFAULT_FUNNEL_CHART_PROPERTIES.afterSeparatorLength,
     afterSeparatorOffset = DEFAULT_FUNNEL_CHART_PROPERTIES.afterSeparatorOffset,
     currentPartSizeExtension = DEFAULT_FUNNEL_CHART_PROPERTIES.currentPartSizeExtension,
     currentBorderWidth = DEFAULT_FUNNEL_CHART_PROPERTIES.currentBorderWidth,
     motionConfig = DEFAULT_FUNNEL_CHART_PROPERTIES.motionConfig,
     animate = DEFAULT_FUNNEL_CHART_PROPERTIES.animate,
     valueFormat = DEFAULT_FUNNEL_CHART_PROPERTIES.valueFormat
   }) => {
    return <ResponsiveFunnel
      tooltip={ (point) => <FunnelChartTooltip part={ point.part }/> }
      data={ data }
      margin={ margin }
      colors={ colors }
      borderWidth={ borderWidth }
      labelColor={ labelColor }
      beforeSeparatorLength={ beforeSeparatorLength }
      beforeSeparatorOffset={ beforeSeparatorOffset }
      afterSeparatorLength={ afterSeparatorLength }
      afterSeparatorOffset={ afterSeparatorOffset }
      currentPartSizeExtension={ currentPartSizeExtension }
      currentBorderWidth={ currentBorderWidth }
      motionConfig={ motionConfig }
      animate={ animate }
      valueFormat={ valueFormat }
    />;
  });
