import styled from '@emotion/styled';
import { MainTheme } from '../../../../assets/themes/MainTheme';

export const PieTooltipContainer = styled.div`
  background: #fff;
  padding: 10px;
  box-shadow: ${MainTheme.shadows.base};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

export const PieTooltipColorBox = styled.div<{color: string}>`
  width: 10px;
  height: 10px;
  background: ${({color}) => color};
`
