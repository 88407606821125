import { API, UseCase } from "@ppg/common";
import { ABTestState, ActionsUseCases } from '../../../modelsMobx';
import { ABVariant } from '../../../modelsMobx';
import { LabelCountStrategy } from '../../../modelsMobx/Label';
import { SenderStrategyType } from '../../../stores/PushSettingStore';
import { ILabelsWithStrategyCommand, ISegmentDTO } from '../campaigns/interfaces';

interface IGetABTest {
  abTestId: string;
}

export interface IGetABTestResponse {
  id: string;
  name: string;
  sample: number;
  state: ABTestState;
  testStartDate: string;
  testEndDate: string;
  winner: ABVariant;
  policy: string;
  queryCriteria: {
    tags: string[];
    tagsCountStrategy: LabelCountStrategy;
    excludedTags: string[];
    excludedTagsCountStrategy: LabelCountStrategy;
    polygon: [Number, Number][][];
    senderStrategy: SenderStrategyType;
    excludedLabels: ILabelsWithStrategyCommand;
    labels: ILabelsWithStrategyCommand;
    segment: ISegmentDTO;
    labelsStrategy: LabelCountStrategy;
    excludedLabelsStrategy: LabelCountStrategy;
  };
  variants: ABVariant[];
  winnerSendDate: string;
  winnerExpireDate: string;
  availableActions: ActionsUseCases[];
}

export class GetABTestUseCase extends UseCase<IGetABTest, IGetABTestResponse> {
  protected async execute({ abTestId }: IGetABTest) {
    return API.HTTP.get<IGetABTestResponse>(`core/projects/${ API.HTTP.currentProjectId }/ab-tests/${ abTestId }`);
  }
}

export const getABTestUseCase = new GetABTestUseCase();
