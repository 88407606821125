import * as React from 'react';
import { PaymentDetails } from './PaymentDetails';
import { UserDetails } from './UserDetails';
import { ProjectList } from './ProjectList';
import { inject } from 'mobx-react';
import { ProjectStore } from '../../../../stores/project/ProjectStore';
import './start.scss'
import { UserHeader } from '../../Layouts/UserLayout/UserHeader/UserLayoutHeader';
import { t } from '../../../../base/helpers';
import ReactTooltip from 'react-tooltip';

interface IStart {
  projectStore?: ProjectStore
}

@inject('projectStore')
export class UserInformation extends React.Component<IStart, {}> {
  public render() {
    return (
      <section>
        <UserHeader title={ t('Account information') }/>

        <div className="user-details">
          <UserDetails/>
          {
            this.props.projectStore.userOwnedProjects.length > 0
            && <PaymentDetails/>
          }
        </div>

        <ProjectList/>

        <ReactTooltip type="light" effect="solid"/>
      </section>
    );
  }
}
