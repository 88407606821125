import { API, UseCase } from "@ppg/common";
import { ICampaignId, ICampaignIdResponse } from '../interfaces';

interface ISendDraftRocketPushRequest extends ICampaignId {}
interface ISendDraftRocketPushResponse extends ICampaignIdResponse {}

export class SendDraftRocketPushUseCase extends UseCase<ISendDraftRocketPushRequest, ISendDraftRocketPushResponse> {
  protected async execute({ campaignId }: ISendDraftRocketPushRequest) {
    return API.HTTP.post<ISendDraftRocketPushResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/rocket/${ campaignId }/accept`);
  }
}

export const sendDraftRocketPushUseCase = new SendDraftRocketPushUseCase();
