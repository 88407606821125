import { debounce, property } from '@ppg/common';
import { action, computed, observable, reaction } from 'mobx';
import { DASHBOARD_INITIAL_DAYS, DAY, MILLISECOND } from '../../constants';
import { ICampaignsData, IDashboardCampaign, IParsedAutomation } from '../../models/IDashboardDay';
import { getDashboardAutomation } from '../../useCases/dashboard/GetDashboardAutomation';
import { ProjectRelated } from '../ProjectRelatedStore';
import { PlatformType } from '../../useCases/interfaces';
import { ComparisonActivity } from '../../modelsMobx/ComparisonActivity';
import dayjs from 'dayjs';
import {
  getActiveSubscribersUseCase,
  getCampaignActivityComparisonUseCase,
  getSubscribersActivityComparisonUseCase,
  getSubscribersActivityHistogramDataUseCase
} from '../../useCases/statistics/project';
import { IGetCampaignActivityComparisonResult } from '../../useCases/statistics/project/GetCampaignActivityComparisonUseCase';
import { SubscribersActivityComparison } from '../../useCases/statistics/project/GetSubscribersActivityComparisonUseCase';
import { getDashboardMassCampaigns } from '../../useCases/dashboard/GetDashboardMassCampaigns';
import { Image } from '../../modelsMobx';
import { getCampaignTypeUseCase } from '../../useCases/core/campaigns/GetCampaignTypeUseCase';
import { CampaignType } from '../../modelsMobx/campaigns/PushCampaign';

export type dashboardCampaignType = 'clicked' | 'delivered' | 'sent' | 'subscribed' | 'unsubscribed' | 'executed';

export interface IActivityHistogram {
  day: string,
  subscribed: number,
  unregistered: number
}

export class DashboardStore extends ProjectRelated {
  @property() public webPlatformEnabled: boolean = true;
  @property() public mobilePlatformEnabled: boolean = true;
  @property() public dateRangeFrom: Date = dayjs().subtract(DASHBOARD_INITIAL_DAYS, 'days').startOf('day').toDate();
  @property() public dateRangeTo: Date = dayjs().startOf('day').add(DAY, 'day').startOf('day').subtract(MILLISECOND, 'millisecond').toDate();
  @observable public parsedAutomations: IParsedAutomation[] = [];
  @observable public activeAutomations: number = 0;
  @observable public averageAutomationCtr: number = 0;
  @observable public campaignsData: ICampaignsData;
  @observable public activeSubscribers: number = 0;
  @observable public activityHistogram: IActivityHistogram[] = [];
  @observable public campaignActivityComparison: IGetCampaignActivityComparisonResult = null;
  @observable public subscribersActivityComparison: SubscribersActivityComparison = null;

  @observable public isActiveSubscribersLoading: boolean = true;
  @observable public isaActivityHistogramLoading: boolean = true;
  @observable public isCampaignActivityComparisonLoading: boolean = true;
  @observable public isSubscribersActivityComparisonLoading: boolean = true;
  @observable public isAutomationLoading: boolean = true;
  @observable public isCampaignLoading: boolean = true;

  private reactOnRangeChange = reaction(() => this.rangeChangeFactors, () => this.debouncedStatisticsFetch());
  private reactOnPlatformChange = reaction(() => this.platformChangeFactors, () => this.fetchStatistics());

  public debouncedStatisticsFetch = debounce(() => this.fetchStatistics(), 500);

  public showAutomationStats(): boolean {
    return !(!this.webPlatformEnabled && this.mobilePlatformEnabled);
  }

  @computed
  public get platformType(): PlatformType | null {
    if (this.webPlatformEnabled && this.mobilePlatformEnabled) {
      return null;
    }

    if (this.webPlatformEnabled) {
      return PlatformType.WEB;
    }

    if (this.mobilePlatformEnabled) {
      return PlatformType.MOBILE;
    }
  }

  @computed
  get rangeChangeFactors() {
    return {
      from: this.dateRangeFrom.toISOString(),
      to: this.dateRangeTo.toISOString()
    };
  }

  @computed
  get platformChangeFactors() {
    return {
      web: this.webPlatformEnabled,
      mobile: this.mobilePlatformEnabled,
    };
  }

  @computed
  private get fromDate() {
    return dayjs(this.dateRangeFrom).startOf('day').toISOString();
  }

  @computed
  private get toDate() {
    return dayjs(this.dateRangeTo).endOf('day').toISOString();
  }

  @computed
  private get timezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  setDefaultValues() {
    this.dateRangeFrom = dayjs().subtract(DASHBOARD_INITIAL_DAYS, 'days').startOf('day').utc().toDate();
    this.dateRangeTo = dayjs().startOf('day').add(DAY, 'day').startOf('day').subtract(MILLISECOND, 'millisecond').toDate();
    this.enableLoaders();
    this.clearDashboardStats();
  }


  @action
  public fetchStatistics = async (): Promise<void> => {
    if (!this.isPlatformSelected()) {
      this.clearDashboardStats();
      return;
    }

    this.enableLoaders();

    await Promise.all([
      this.fetchCampaignsData(),
      this.fetchActiveSubscribers(),
      this.fetchActivityHistogram(),
      this.fetchCampaignActivityComparison(),
      this.fetchSubscribersActivityComparison(),
      this.fetchAutomationData(),
    ]);
  };

  @action
  public fetchActiveSubscribers = async (): Promise<void> => {
    if (!this.projectId) return;
    const { activeSubscribers } = await getActiveSubscribersUseCase.exec({
      platform: this.platformType,
    });

    this.activeSubscribers = activeSubscribers;
    this.isActiveSubscribersLoading = false;
  };

  @action
  public fetchActivityHistogram = async (): Promise<void> => {
    if (!this.projectId) return;
    const { histogram } = await getSubscribersActivityHistogramDataUseCase.exec({
      platform: this.platformType,
      from: this.fromDate,
      to: this.toDate,
      timezone: this.timezone
    });

    this.activityHistogram = histogram;
    this.isaActivityHistogramLoading = false;
  };

  @action
  public fetchCampaignActivityComparison = async (): Promise<void> => {
    if (!this.projectId) return;
    const { delivered } = await getCampaignActivityComparisonUseCase.exec({
      platform: this.platformType,
      from: this.fromDate,
      to: this.toDate
    });

    this.campaignActivityComparison = {
      delivered: ComparisonActivity.createComparisonActivity(delivered)
    };

    this.isCampaignActivityComparisonLoading = false;
  };

  @action
  public fetchSubscribersActivityComparison = async (): Promise<void> => {
    if (!this.projectId) return;
    const { subscribersActivityComparison } = await getSubscribersActivityComparisonUseCase.exec({
      from: this.fromDate,
      to: this.toDate,
      platform: this.platformType,
    });

    this.subscribersActivityComparison = {
      subscribed: ComparisonActivity.createComparisonActivity(subscribersActivityComparison.subscribed),
      unregistered: ComparisonActivity.createComparisonActivity(subscribersActivityComparison.unregistered)
    };

    this.isSubscribersActivityComparisonLoading = false;
  };

  @action
  public async fetchAutomationData(): Promise<void> {
    if (!this.projectId) return;
    const { bestAutomations, activeAutomations, meanCtr } = await getDashboardAutomation.exec({
      from: this.fromDate,
      to: this.toDate
    });

    this.parsedAutomations = bestAutomations;
    this.activeAutomations = activeAutomations;
    this.averageAutomationCtr = meanCtr;
    this.isAutomationLoading = false;
  }

  private async fetchCampaignType(campaignId: string): Promise<CampaignType> {
    const campaignType = await getCampaignTypeUseCase.exec({ campaignId: campaignId });
    return campaignType.type;
  }

  @action
  public async fetchCampaignsData(): Promise<void> {
    if (!this.projectId) return;

    const { sentCampaigns, ctr, campaignRanking } = await getDashboardMassCampaigns.exec({
      projectId: this.projectId,
      platform: this.platformType,
      from: this.fromDate,
      to: this.toDate,
    });

    const campaigns: IDashboardCampaign[] = await Promise.all(campaignRanking.map(async campaign => {
      return {
        _id: campaign.id,
        clicked: campaign.statistics.clicked,
        delivered: campaign.statistics.delivered,
        ctr: Number((campaign.statistics.ctr * 100).toFixed(2)),
        sentDate: new Date(campaign.sendDate),
        title: campaign.title,
        content: campaign.content,
        redirectLink: campaign.redirectLink,
        icon: Image.createNewImage(campaign.icon),
        campaignType: await this.fetchCampaignType(campaign.id)
      };
    }));

    this.campaignsData = {
      allSent: sentCampaigns,
      averageCtr: Number((ctr * 100).toFixed(2)),
      bestCampaigns: campaigns
    };

    !this.isPlatformSelected() && this.clearDashboardStats();

    this.isCampaignLoading = false;
  }

  public isPlatformSelected = (): boolean => {
    return this.webPlatformEnabled || this.mobilePlatformEnabled;
  };

  @action
  public enableLoaders = (): void => {
    this.isAutomationLoading = true;
    this.isCampaignLoading = true;
    this.isActiveSubscribersLoading = true;
    this.isaActivityHistogramLoading = true;
    this.isSubscribersActivityComparisonLoading = true;
    this.isCampaignActivityComparisonLoading = true;
  };

  @action
  public clearDashboardStats = (): void => {
    this.activeSubscribers = 0;
    this.activeAutomations = 0;
    this.averageAutomationCtr = 0;
    this.parsedAutomations = [];
    this.campaignsData = {
      bestCampaigns: [],
      allSent: 0,
      averageCtr: 0,
    };

    this.subscribersActivityComparison = {
      subscribed: ComparisonActivity.createComparisonActivity(),
      unregistered: ComparisonActivity.createComparisonActivity()
    };

    this.campaignActivityComparison = {
      delivered: ComparisonActivity.createComparisonActivity()
    };
  };

  @action
  public clearPlatformEnabled(): void {
    this.webPlatformEnabled = true;
    this.mobilePlatformEnabled = true;
  }
}
