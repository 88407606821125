import { Model } from '../base';
import { property } from '../base/decorators';
import { IProject, IProjectAccountOwner, IProjectResource } from './IProject';
import { IWebhook } from "../modelsMobx/Webhook";
import { IUtm } from '../modelsMobx/helpers/Utm';
import { userStore } from '../stores';

//@ts-ignore
export class Project extends Model<IProject, IProjectResource> implements IProject {
  // website details
  @property() public name: string;
  @property() public siteUrl: string;
  @property() public enabled: boolean;
  @property() public createdAt: Date;

  @property() public selectors: string[];
  @property() public accountOwner: IProjectAccountOwner;

  // features
  @property() public webhooks: IWebhook[];
  @property() public utm: IUtm;
  @property() public swPath: string;

  // helper
  @property() public subscribersCount: number;

  public canEditPoweredBy(): boolean {
    return userStore.user.organization === this.getAccountOwnerId();
  }

  public static resourceUrl(): string {
    return `project`;
  }

  public getAccountOwnerId() {
    return this.accountOwner && this.accountOwner.id || this.accountOwner;
  }

  public defaults(): IProject {
    return {
      accountOwner: null,
      webhooks: [],
      utm: {
        source: 'pushpushgo',
        medium: 'push',
        campaign: 'CampaignName',
        disabled: false,
        isCampaignOn: true,
        isSourceOn: true,
        isMediumOn: true,
        customUtms: []
      },
    };
  }
}
