import { API, UseCase } from '@ppg/common';
import { IGetActiveSubscribersHistogramResult, IProjectDashboardRequestBody } from '../../../interfaces';
import { getListQueryParam, getPlatformTypeParam, getRangeDateParam, getTimezoneParam } from '../../../helpers';
import { IListQueryOptions } from '../../../../interfaces/IListQueryOptions';

interface IGetProjectActiveSubscriberHistogramRequest extends IProjectDashboardRequestBody, IListQueryOptions {
}

export class GetProjectActiveSubscriberHistogramUseCase extends UseCase<IGetProjectActiveSubscriberHistogramRequest, IGetActiveSubscribersHistogramResult> {
  protected async execute({
                            platform,
                            from,
                            to,
                            limit,
                            offset,
                            timezone
                          }: IGetProjectActiveSubscriberHistogramRequest) {

    const endpoint = `statistics/v2/projects/${ API.HTTP.currentProjectId }/subscribers/dashboard/active/histogram`
      + getPlatformTypeParam(platform) + getRangeDateParam(from, to) + getTimezoneParam(timezone) + getListQueryParam(offset, limit);

    return API.HTTP.get<IGetActiveSubscribersHistogramResult>(endpoint);
  }
}

export const getProjectActiveSubscriberHistogramUseCase = new GetProjectActiveSubscriberHistogramUseCase();
