import * as React from 'react';
import { AuthorizationLayoutLanguages } from './AuthorizationLayoutLanguages';
import './Authorization.scss';
import PPGLogo from '../../assets/images/ppg-logo.svg';
import PPGLiteLogo from '../../assets/images/ppglite-logo.svg';
import { EnterpriseLiteSwitch } from '../../components/EnterpriseLiteSwitch/PricingSwitch';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { t } from '../../base/helpers';
import { userStore } from '../../stores';
import { useWindowSize } from '../../hooks';

interface AuthorizationLayoutProps {
  children: JSX.Element | JSX.Element[];
}

export const AuthorizationLayout: FC<AuthorizationLayoutProps> = ({ children }): JSX.Element => {
  const location = useLocation();
  const { width } = useWindowSize();

  const isPPGLite = (): boolean => location.pathname.includes("lite");
  const isRegisterPage = (): boolean => location.pathname.includes("register");
  const showSwitch = (): boolean => isRegisterPage() || location.pathname.includes("login");
  const showSwitchOnMobile = width <= 850 && showSwitch();
  return (
    <div className="container">
      <div className="app-main">
        <div className="auth-background">
          <div className="auth-container">
            <img className="auth-logo"
                 alt={ isPPGLite() ? "PushPushGoLite" : "PushPushGo" }
                 src={ isPPGLite() ? PPGLiteLogo : PPGLogo }/>

            { showSwitchOnMobile ?
              <div className="mobile-switch__wrapper">
                <EnterpriseLiteSwitch isLite={ isPPGLite() }
                                      isRegister={ isRegisterPage() }/>
              </div> : null
            }

            <div className={ isPPGLite() ? "auth-panel-light" : "auth-panel" }>
              <div className={ isPPGLite() ? "auth-panel-column--left--lite" : "auth-panel-column--left" }>

                { showSwitch() && <div className={ `background-lite-btn${ isPPGLite() ? '-lite' : '' }` }>
                  <EnterpriseLiteSwitch isLite={ isPPGLite() }
                                        isRegister={ isRegisterPage() }/>
                </div> }

                { isRegisterPage() && <RegisterDescription isLite={ isPPGLite() } lang={ userStore.lang }/> }
              </div>
              <div className="auth-panel-column--right">
                { children }
              </div>
            </div>

            <AuthorizationLayoutLanguages/>
          </div>
        </div>
      </div>
    </div>
  );
}

const RegisterDescription = ({ isLite, lang }: IDescription): JSX.Element => {
  const isLangPl = lang === 'pl';
  const blogpostUrl = 'https://pushpushgo.com/pl/blog/pushpushgo-lite-idealne-narzedzie-dla-malych-i-srednich-firm/';
  if (isLite) {
    return <div className="auth-panel__description">
      <p>{ t('Do you own a small business or you are not sure if push notifications are for you? Try it out with ') }
        <span className="auth-panel__description--product">{ t('PushPushGo Lite') }</span>!</p>
      <p>{ t('You can cancel your subscription anytime.') }</p>
      { isLangPl && <a className={ 'auth-panel__post-link' } href={ blogpostUrl } target={ '_blank' }>{ t('Learn more') }
        <span className="icon-arrow-right size10"/></a> }
    </div>
  }

  return <div className="auth-panel__description">
    <p>{ t('The best option for Enterprise business, the plan is carefully tailored to your needs.') }</p>
    <p>{ t('PS. Did you just joined a new company and need access to PushPushGo? You are in the right place :)') }</p>
  </div>
}

interface IDescription {
  lang: string;
  isLite: boolean;
}