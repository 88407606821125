import { FunnelSvgProps } from '@nivo/funnel';
import { FunnelDatum } from '@nivo/funnel/dist/types/types';

const green_blue = [
  '#cffae1',
  '#6de2b5',
  '#45c5a0',
  '#16a085',
  '#10897d',
  '#0b7372',
  '#07555c',
  '#043f4c',
  '#10336e',
  '#1b4985',
  '#2b67a5',
  '#3f89c5',
  '#57aee6',
];

export const DEFAULT_FUNNEL_CHART_PROPERTIES: Partial<FunnelSvgProps<FunnelDatum>> = {
  margin: { top: 20, right: 20, bottom: 20, left: 20 },
  valueFormat: '.2%',
  colors: green_blue,
  borderWidth: 20,
  labelColor: {
    from: 'color',
    modifiers: [['darker', 3]]
  },
  beforeSeparatorLength: 100,
  beforeSeparatorOffset: 20,
  afterSeparatorLength: 100,
  afterSeparatorOffset: 20,
  currentPartSizeExtension: 10,
  currentBorderWidth: 40,
  motionConfig: 'gentle',
  animate: true,
};
