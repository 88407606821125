import * as React from "react";
import { IPushAction } from '../../../modelsMobx/PushAction';
import { Button, ButtonTypes, CheckBox, Input, UrlInput } from '@ppg/styled';
import { t } from '../../../base/helpers';
import "./PushActionComponent.scss";
import { observer } from 'mobx-react';
import { PreviewCompiled } from '../PreviewCompiled';
import { DRAFT_LINK } from '../../../constants';
import { ErrorList } from '../validators';

interface IPushActionComponent {
  schemas: string[];
  actions: IPushAction[];
  scope: Object;
  isDirectionRtl: boolean;
  siteProtocol: string;
  utm: any;
  canAddAction: boolean;

  onAddAction(): void;

  onDelete: any;
  onPaste?: (link: string) => string;
  title?: string;
  addDefaultUtmToPreview?: boolean;
  characterLimitActionTitle?: number;
}

export const PushActionComponent = observer(({
                                               actions,
                                               scope,
                                               title,
                                               isDirectionRtl,
                                               siteProtocol,
                                               canAddAction,
                                               onDelete,
                                               onAddAction,
                                               utm,
                                               onPaste,
                                               characterLimitActionTitle,
                                               schemas,
                                               addDefaultUtmToPreview = false,
                                             }: IPushActionComponent): JSX.Element => {

  return <div className="push-actions__component">
    { title && <h4>{ title }</h4> }

    <div className="push-actions__wrapper">
      { actions.map((action, idx) => {
          return <PushActionItem key={ idx }
                                 utm={ utm }
                                 onPaste={ onPaste }
                                 schemas={schemas}
                                 scope={ scope }
                                 isDirectionRtl={ isDirectionRtl }
                                 siteProtocol={ siteProtocol }
                                 characterLimitActionTitle={characterLimitActionTitle}
                                 showDefaultUtm={ addDefaultUtmToPreview }
                                 onDelete={ onDelete }
                                 action={ action }/>
        })
      }

      {
        canAddAction && <Button isOutline
                                size={ 'small' }
                                onClick={ onAddAction }
                                dataCy={ 'push-action-button--add' }
                                content={ t('campaign/new/push::Add action') }
                                icon={ 'plus-badge' }
                                type={ ButtonTypes.DEFAULT }/>
      }
    </div>
  </div>
})

const PushActionItem = observer(({ schemas, action, isDirectionRtl, siteProtocol, onDelete, utm, scope, onPaste, showDefaultUtm, characterLimitActionTitle }): JSX.Element => {

  const previewRendered = (action: IPushAction): JSX.Element => {
    const isUtmDisabled = utm.disabled;
    const isRedirectLinkSet = action.useRedirectLink;

    let render = action.link || DRAFT_LINK;

    if (isRedirectLinkSet && !isUtmDisabled && showDefaultUtm) {
      render = addUtmToPreviewLink(render);
    }

    return <PreviewCompiled text={ render }
                            scope={ scope }/>
  }

  const addUtmToPreviewLink = (renderText: string): string => {

    const getSeparator = (text) => text.includes('?') ? "&" : "?";

    if (utm.getProperty('isSourceOn').getValue()) {
      renderText += `${ getSeparator(renderText) }utm_source=${ utm.getProperty('source').getValue() }`;
    }

    if (utm.getProperty('isMediumOn').getValue()) {
      renderText += `${ getSeparator(renderText) }utm_medium=${ utm.getProperty('medium').getValue() }`;
    }

    if (utm.getProperty('isCampaignOn').getValue()) {
      renderText += `${ getSeparator(renderText) }utm_campaign=${ utm.getProperty('campaign').getValue() }`;
    }

    if (utm.customUtms.length > 0) {
      utm.customUtms.forEach(utm => {
        renderText += `${ getSeparator(renderText) }utm_${ utm.parameter }=${ utm.value }`;
      })
    }

    return renderText;
  }

  const onPasteHandle = (): void => {
    if (onPaste) action.link = onPaste(action.link);
  }

  const showEmoji: boolean = characterLimitActionTitle ? action.title.length < characterLimitActionTitle - 1 : true;
  const limitCharacters: number = characterLimitActionTitle ? characterLimitActionTitle : null

  return <div className="single-action__wrapper">
    <div className="single-action__title">
      <Input property={ action.getProperty('title') }
             placeholder={ t('campaign/new/push::See more') }
             emoji={ showEmoji }
             maxLength={ limitCharacters }
             characterLimit={ limitCharacters }
             dataCy={ 'push-action--title' }
             onBlur={ () => action.getProperty('title').validate() }
             isDirectionRtl={ isDirectionRtl }
             label={ t('campaign/new/push::Title') }>
        <ErrorList label={ t('Title') }
                   property={ action.getProperty('title') }/>
      </Input>
    </div>

    <div className="single-action__link">
      {
        action.useRedirectLink
          ? <Input property={ action.getProperty('link') }
                   label={ t('campaign/new/push::Link') }
                   dataCy={ 'push-action--link' }
                   disabled>
            { previewRendered(action) }
          </Input>
          : <UrlInput urlProperty={ action.getProperty('link') }
                      schemas={schemas}
                      label={ t('campaign/new/push::Link') }
                      onPaste={ onPasteHandle }
                      dataCy={ 'push-action--link' }
                      defaultScheme={ siteProtocol }>
            { previewRendered(action) }
          </UrlInput>
      }
      <CheckBox property={ action.getProperty('useRedirectLink') }
                onChange={ () => action.setRedirectLink() }
                dataCy={ 'push-action--useRedirectLink' }
                label={ t('campaign/new/push::Set redirect link as default') }/>

    </div>
    <Button isSquare
            icon={ "trash" }
            dataCy={ 'push-action-button--delete' }
            onClick={ () => onDelete(action) }
            type={ ButtonTypes.DEFAULT }/>
  </div>
});
