import { UseCase, API } from '@ppg/common';

interface LoginData {
  username: string;
  password: string;
  remember: boolean;
}

interface LoginResult {
  token: string;
}

export class LoginUseCase extends UseCase<LoginData, LoginResult> {
  protected async execute({ username, password, remember }) {
    return API.HTTP.post<LoginResult>('aai/auth', { username, password, remember });
  }
}

export const loginUseCase = new LoginUseCase();
