import { API, UseCase } from '@ppg/common';
import { IProjectId, IGetAllCustomFieldsResult } from '../interfaces';

export class GetAllCustomFieldsUseCase extends UseCase<IProjectId, IGetAllCustomFieldsResult> {
  protected async execute({ projectId }: IProjectId) {
    return API.HTTP.get<IGetAllCustomFieldsResult>(`core/projects/${ projectId }/custom-fields/all`)
  }
}

export const getAllCustomFieldsUseCase = new GetAllCustomFieldsUseCase();
