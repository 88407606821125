import { API, UseCase } from '@ppg/common';
import { getPlatformTypeParam, getTimezoneParam } from '../../../helpers';
import { IGetActiveSubscribersResult, PlatformType } from '../../../interfaces';

interface IGetOrganizationActiveSubscribersRequest {
  organization: string;
  platform: PlatformType
  timezone: string;
}

export class GetOrganizationActiveSubscribersUseCase extends UseCase<IGetOrganizationActiveSubscribersRequest, IGetActiveSubscribersResult> {
  protected async execute({ organization, platform, timezone }: IGetOrganizationActiveSubscribersRequest) {
    const endpoint = `statistics/organizations/${ organization }/subscribers/active`
      + getPlatformTypeParam(platform) + '?' + getTimezoneParam(timezone)

    return API.HTTP.get<IGetActiveSubscribersResult>(endpoint);
  }
}
