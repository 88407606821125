import * as React from "react";
import "./CampaignCardWrapper.scss";

interface ICampaignCardWrapper {
  children: React.ReactNode;
  isOrganization?: boolean;
}

export const CampaignCardWrapper = ({ children, isOrganization = false }: ICampaignCardWrapper): JSX.Element => {
  return <div className={ isOrganization ? 'campaign-stats-cards' : 'campaign-stats-cards__project' }>
    { children }
  </div>
}