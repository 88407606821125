import { property, PropertyHandler } from '@ppg/common';

export interface ActivityItemDiff {
  isLower: boolean;
  percent: number;
}

interface IComparisonActivity {
  current: number,
  previous: number,
}

export class ComparisonActivity extends PropertyHandler {

  @property()
  public current: number;

  @property()
  public previous: number;

  constructor(activityItem?: IComparisonActivity) {
    super();

    if(!activityItem) {
      this.current = 0
      this.previous = 0
    }

    this.current = activityItem.current;
    this.previous = activityItem.previous;
  }

  static createComparisonActivity(activityItem?: IComparisonActivity) {

    if(!activityItem){
      return new ComparisonActivity({
        current: 0,
        previous: 0,
      })
    }

    return new ComparisonActivity(activityItem);
  }

  public get diff(): ActivityItemDiff {
    if (this.current === 0 && this.previous === 0) {
      return { isLower: false, percent: 0 };
    }

    let percent;

    if (this.current !== 0) {
      percent = this.previous !== 0 ? (this.current - this.previous) / this.previous * 100 : 100;
    } else {
      percent = 100;
    }

    return { isLower: this.current === 0 ? true : percent < 0, percent: Math.round(Math.abs(percent)) };
  }

  public clear = ():void => {
    this.current = 0;
    this.previous = 0;
  }
}
