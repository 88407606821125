import dayjs from 'dayjs';
import * as React from 'react';
import './LineChartTooltip.scss'
import { FC } from 'react';
import { fixedPercentValue } from '@ppg/common';
import { t } from '../../../../base/helpers';

interface ILineChartTooltip {
  data: {
    data: {
      x: string,
      y: number
    }
    serie: {
      id: string,
      color: string
    }
  }[],
  id: string
  valueInPercent?: boolean;
}

export const LineChartTooltip: FC<ILineChartTooltip> = ({ data, id, valueInPercent }) => {
  return <div className="lineTooltip">
    { data
      .sort((a, b) => b.data.y - a.data.y)
      .map((item, idx) => {
        const value = valueInPercent ? fixedPercentValue(item.data.y) : item.data.y;
        return <div key={ idx } className="lineTooltip__item">
          <div className={ 'lineTooltip__color' } style={ { backgroundColor: item.serie.color } }/>
          <span className={ 'text-light' }>{ t(item.serie.id) }:</span>
          <strong>{ value }</strong>
        </div>
      }) }
    <span className="m10t text-light">{ dayjs(id).format('YYYY-MM-DD') }</span>
  </div>
}