import { UseCase, API } from '@ppg/common';
import { IGetCurrentValidators, IIntegrationValidators } from '../interfaces';

export class GetCurrentIntegrationValidatorsUseCase extends UseCase<IGetCurrentValidators, IIntegrationValidators[]> {
  protected async execute({ websiteIntegrationId, checkID }: IGetCurrentValidators) {
    return API.HTTP.get<IIntegrationValidators[]>(`wi/projects/${ API.HTTP.currentProjectId }/website-integrations/${ websiteIntegrationId }/integration-validators/check/${ checkID }`)
  }
}

export const getCurrentIntegrationValidatorsUseCase = new GetCurrentIntegrationValidatorsUseCase();
