import { API, UseCase } from '@ppg/common';

interface ICreatePolygon {
  project: string;
  name: string;
  coordinates: [number, number][];
}

interface ICreatePolygonResult {
  id: string;
  coordinates: [number, number][];
  name: string;
}

export class CreatePolygonUseCase extends UseCase<ICreatePolygon, ICreatePolygonResult> {
  protected async execute({ project, name, coordinates }: ICreatePolygon) {
    return API.HTTP.post<ICreatePolygonResult>(`ah/projects/${ project }/polygons`, { name, coordinates })
  }
}

export const createPolygonUseCase = new CreatePolygonUseCase();
