import * as React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { ApplicationRoutes } from './routes/applicationRoutes';
import { observable } from 'mobx';
import { UserStore } from './stores/user/UserStore';
import { LoginAs } from './pages/Authorization/LoginAs';
import { Authorization } from './pages/Authorization/Authorization';
import { DeleteUserConfirm } from './pages/User/Account/Settings/DeleteUserConfirm';
import { Config } from '@ppg/common';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Unauthorized } from './pages/Authorization/Unauthorized';
import { AccountActivation } from './pages/Authorization/AccountActivation';
import { AcceptChannelRequest } from './pages/User/Account/NotificationChannels/AcceptChannelRequest';
import { Global, ThemeProvider } from "@emotion/react";
import { GlobalStyles } from "./assets/globalStyles/GlobalStyles"
import { MainTheme } from './assets/themes/MainTheme';

interface IAppProps extends RouteComponentProps {
  userStore?: UserStore;
}

@inject('userStore')
@observer
class App extends React.Component<IAppProps> {
  private stripePromise = null;

  @observable isLoading = true;

  constructor(props: IAppProps) {
    super(props);
    const config = Config.getConfig();
    this.stripePromise = loadStripe(config.stripe)
  }

  public async componentDidMount() {
    const { pathname } = this.props.location;

    const isNotLoginAs = !pathname.includes('/login/as');

    if (isNotLoginAs) {
      await this.props.userStore.checkIsAuthorized();
    }

    this.isLoading = false;
  }

  public render() {
    if (this.isLoading) {
      return null;
    }

    return (
      <Elements stripe={ this.stripePromise }>
        <ThemeProvider theme={ MainTheme }>
          <Global styles={ GlobalStyles } />
          <Switch>
            <Route path='/login/as/:token' component={ LoginAs }/>
            <Route path='/delete-account/confirm/:token' component={ DeleteUserConfirm }/>
            <Route path='/notification/channels/accept/:token' component={ AcceptChannelRequest }/>
            <Route path='/activate-account/:token' component={ AccountActivation }/>
            <Route path='/unauthorized' component={ Unauthorized }/>
            { this.props.userStore.isAuthorized ? <ApplicationRoutes/> : <Authorization/> }
          </Switch>
        </ThemeProvider>
      </Elements>
    );
  }
}

export default withRouter(App);
