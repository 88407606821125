import { UseCase, API } from '@ppg/common';
import { IGetColorTrackingResult, IGetPlugin } from '../interfaces';

export class GetColorTrackingPluginUseCase extends UseCase<IGetPlugin, IGetColorTrackingResult> {
  protected async execute({ websiteIntegrationId }: IGetPlugin) {
    return API.HTTP.get<IGetColorTrackingResult>(`wi/projects/${ API.HTTP.currentProjectId }/website-integrations/${ websiteIntegrationId }/plugins/color-tracking`);
  }
}

export const getColorTrackingPluginUseCase = new GetColorTrackingPluginUseCase();
