import { API, UseCase } from "@ppg/common";
import { IGetAppleDataResponse, IProvider } from '../interfaces';

interface IUpdateIOSCertificates extends IProvider {
  p12: string;
  passphrase: string;
  production: boolean;
}

export class UpdateIOSCertificatesUseCase extends UseCase<IUpdateIOSCertificates, IGetAppleDataResponse> {
  protected async execute({ passphrase, projectId, p12, production, organizationId }: IUpdateIOSCertificates) {
    return API.HTTP.put<IGetAppleDataResponse>(`core/organizations/${ organizationId }/projects/${ projectId }/providers/ios/certificates`, {
      p12, production, passphrase
    });
  }
}

export const updateIOSCertificatesUseCase = new UpdateIOSCertificatesUseCase();
