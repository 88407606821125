import { observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { LoginPage } from './LoginPage';
import { redirect } from '../../base/helpers';

@observer
export class Login extends React.Component<RouteComponentProps> {

  componentDidMount() {
    window.location.pathname.includes("lite") && redirect('/lite/login')
  }

  render() {
    const { search } = this.props.location;
    const { params } = this.props.match;

    return <LoginPage search={ search } params={params} />

  }
}
