import { UseCase, API } from "@ppg/common";
import { ICampaignId, ICampaignIdResponse } from '../interfaces';

interface IRetryRocketPushRequest extends ICampaignId {}
interface IRetryRocketPushResponse extends ICampaignIdResponse {}

export class RetryRocketPushUseCase extends UseCase<IRetryRocketPushRequest, IRetryRocketPushResponse> {
  protected async execute({  campaignId }: IRetryRocketPushRequest) {
    return API.HTTP.post<IRetryRocketPushResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/rocket/${ campaignId }/retry`);
  }
}

export const retryRocketPushUseCase = new RetryRocketPushUseCase();
