import { getTranslationsUseCase } from '../useCases/I18NUseCase';
import { User } from './User';
import { IUserResource } from './IUser';
import { lsProperty, property } from '../base/decorators';
import { apiManager, connector, polyglot } from '../base';
import { IMap } from '../interfaces';
import { Project } from './Project';
import * as lodash from 'lodash';
import { Collection } from 'backbone';
import dayjs from 'dayjs';
import { redirect } from '../base/helpers';
import { LANGUAGES } from '@ppg/common';

export class WebUser extends User {
  private _selectedProject: Project = null;

  @property()
  public roles: string[];

  @property()
  public gravatar: string;

  @lsProperty({ defaults: false })
  public showNav: boolean;

  @lsProperty({ defaults: false })
  public infinity: boolean;

  @lsProperty({ defaults: null })
  public currentProject: string;

  @lsProperty({ defaults: '' })
  public temporaryEmail: string;

  @lsProperty({ defaults: 'en' })
  public lang: string;

  @lsProperty({ defaults: false })
  public projectSelectorToggle: boolean;

  @lsProperty({ defaults: false })
  public developerAdvancedSettings: boolean;

  @lsProperty({ defaults: true })
  public sidebarOpened: boolean;

  @lsProperty({ defaults: 'all' })
  public subscribersRange: string;

  @lsProperty({ defaults: false })
  private blockPush: boolean;

  @lsProperty({ defaults: [] })
  public onboardingSteps: string[];

  @lsProperty({ defaults: null })
  public onboardingTimestamp: Date;
//@ts-ignore
  public projects: Collection<Project> = new Collection<Project>([]);

  public static resourceUrl() {
    return 'me';
  }

  private static readonly LANG_TO_LOCALE: IMap<string> = {
    'ua': 'uk',
  };

  constructor(attributes?: any, options?: any) {
    super(attributes, options);
    this.listenTo(this.projects, 'add remove', () => this.trigger('change', this, this.collection));
    this.listenTo(this, 'change:lang', this.onChangeLang);
    this.lang = this.lang && Object.keys(LANGUAGES).includes(this.lang) ? this.lang : 'en';
    this.setLang(this.lang).catch(console.error);
  }

  private onChangeLang(model: WebUser, lang: string) {
    this.setLang(this.lang).catch(console.error);
  }

  public async setLang(lang: string) {
    let translations = {};

    try {
      const response = await getTranslationsUseCase.exec({ lang });
      response.translations.map((t) => translations[t.key] = t.value);
    } catch (error) {
      console.warn(error.message);
    }

    polyglot.replace(lodash.omitBy(translations, lodash.isEmpty));
    dayjs.locale(this.getLocale());
    this.trigger('change', this, {});
    // @todo
    redirect(location.pathname);
  }

  public getLocale(): string {
    return WebUser.LANG_TO_LOCALE[this.lang] || this.lang;
  }

  public savePartial(...attributes: string[]): Promise<this> {
    return apiManager
      .post(this.createUrl('update'), {
        ...this.serverAttributes,
        ...lodash.pick(this.toJSON(), attributes)
      })
      .then(attrs => this.setFromResponse(attrs as IUserResource));
  }

  protected update(): Promise<this> {
    return apiManager
      .post(this.createUrl('update'), this.toJSON())
      .then(attrs => this.setFromResponse(attrs as IUserResource));
  }

  public selectProject(project: Project) {
    if (project) {
      project.on('change', this.propagateChange);

      if (this._selectedProject) {
        this._selectedProject.off('change', this.propagateChange);
      }

      this._selectedProject = project;
      connector.resetForms({ silent: true });
    }

    this.projectSelectorToggle = false;
    this.currentProject = lodash.result(project, 'id', null);
  }

  public get selectedProject(): Project {
    return this._selectedProject;
  }

  public logout() {
    if (this._selectedProject) {
      this._selectedProject.off('change', this.propagateChange);
      this._selectedProject = null;
      this.currentProject = null;
    }

    this.reset();
    connector.reset({ silent: true });
  }


  public hasRole(role?: string) {
    return true;
  }

  private propagateChange = (...args: any[]) => this.trigger('change', ...args);
}
