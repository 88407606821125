import { API, UseCase } from '@ppg/common';

interface ShareProjectDataRequest {
  projects: string[];
  usernames: string[];
  organization: string;
}

interface IShareManyProjectsResponse {
  results: {
    project: string;
    error: string;
    organization: string;
    user: string
  }[]
}

export class ShareManyProjectsToManyUsersUseCase extends UseCase<ShareProjectDataRequest, any> {
  protected async execute({organization, projects, usernames}: ShareProjectDataRequest): Promise<IShareManyProjectsResponse> {
    return await API.HTTP.post(`aai/organizations/${organization}/shared-projects/many-to-many`, {
      usernames,
      projects
    });
  }
}

export const shareManyProjectsToManyUsersUseCase = new ShareManyProjectsToManyUsersUseCase();
