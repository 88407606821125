import { API, UseCase } from '@ppg/common';

interface IUpdatePolygon {
  project: string;
  polygon: string;
  name: string;
  coordinates: [number, number][];
}

interface IUpdatePolygonResult {
  id: string;
  name: string;
  coordinates: [number, number][];
}

export class UpdatePolygonUseCase extends UseCase<IUpdatePolygon, IUpdatePolygonResult> {
  protected async execute({ project, polygon, name, coordinates }: IUpdatePolygon) {
    return API.HTTP.put<IUpdatePolygonResult>(`ah/projects/${ project }/polygons/${ polygon }`, { name, coordinates });
  }
}

export const updatePolygonUseCase = new UpdatePolygonUseCase();
