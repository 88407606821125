import { Selector } from "./Selector";
import { LabelStrategyEnum } from "../LabelStrategyEnum";

export interface ISelectorDTO {
  id?: string,
  selector: string,
  event: string,
  bindAll: boolean,
  prettyName: string,
  name: string,
  group: string | null,
  /**
   * @deprecated
   */
  tagLabel?: string | null,
  labelKey: string;
  labelTTL: number;
  labelStrategy: LabelStrategyEnum;
  project: string,
  reducer: string,
  valueType: string,
  settingsType: string,
  attribute: string;
}

export class SelectorDTO {
  bindAll: boolean;
  event: string;
  group: string | null;
  id: string;
  name: string;
  prettyName: string;
  project: string;
  reducer: string;
  selector: string;
  settingsType: string;
  tagLabel: string;
  valueType: string;
  attribute: string;
  labelKey: string;
  labelTTL: number;
  labelStrategy: LabelStrategyEnum;

  constructor(selector: Selector) {
    if(this.id) {
      this.id = selector.id;
    }
    this.selector = selector.selector;
    this.name = selector.name;
    this.prettyName = selector.prettyName;
    this.group = selector.group;
    this.reducer = selector.reducer;
    this.valueType = selector.valueType;
    this.event = selector.event;
    this.bindAll = selector.bindAll;
    this.settingsType = selector.settingsType;
    this.attribute = selector.attribute;
    this.tagLabel = selector.tagLabel;
    this.labelKey = selector.labelKey;
    this.labelTTL = ~~selector.labelTTL;
    this.labelStrategy = selector.labelStrategy;
  }
}
