import { API, UseCase } from '@ppg/common';
import { IGetSubscribersActivityHistogramResult, IProjectDashboardRequestBody } from '../../../interfaces';
import { getListQueryParam, getPlatformTypeParam, getRangeDateParam, getTimezoneParam } from '../../../helpers';
import { IListQueryOptions } from '../../../../interfaces/IListQueryOptions';

export interface IProjectGetSubscribersActivityHistogramRequest extends IProjectDashboardRequestBody, IListQueryOptions {
}
export class GetProjectSubscribersActivityHistogramUseCase extends UseCase<IProjectGetSubscribersActivityHistogramRequest, IGetSubscribersActivityHistogramResult> {
  protected async execute({
                            platform,
                            from,
                            to,
                            limit,
                            offset,
                            timezone
                          }: IProjectGetSubscribersActivityHistogramRequest) {
    const endpoint = `statistics/v2/projects/${ API.HTTP.currentProjectId }/subscribers/dashboard/activity/histogram`
      + getPlatformTypeParam(platform) + getRangeDateParam(from, to) + getTimezoneParam(timezone) + getListQueryParam(offset, limit);

    return API.HTTP.get<IGetSubscribersActivityHistogramResult>(endpoint);
  }
}

export const getProjectSubscribersActivityHistogramUseCase = new GetProjectSubscribersActivityHistogramUseCase();
