import * as React from 'react';
import { useEffect, useState } from 'react';
import { t } from '../../../../../base/helpers';
import { organizationDashboardStore } from '../../../../../stores';
import { observer } from 'mobx-react';
import {
  ChartTableLayout,
  ChartCard,
  EmptyComponentDashboardStatsType,
  customComparatorLineChartProperties,
  customComparatorLineChartPropertiesInPercent
} from '../../../common';
import { ChartType, IExternalLoaders } from '../../../common/ChartCard/__types__';
import { ActiveViewType } from '../../../../User/Navigations/UnderlinedNavigationBar/UnderlinedNavigationBar';
import { ProjectComparatorTable } from './ProjectComparatorTable';

export const ProjectComparisonCampaigns = observer(() => {
  const { platformChangeFactors, rangeChangeFactors, projectComparisonStore, isPlatformSelected } = organizationDashboardStore;
  const [isLoading, setLoader] = useState<boolean>(true)
  const [initialLoader, setInitialLoader] = useState<boolean>(false);

  const campaignLoaders = (): IExternalLoaders => {
    return {
      isLoading,
      isInitialLoader: initialLoader
    }
  }

  const {
    campaignsCtrChartData,
    campaignsClickedChartData,
    campaignsSentChartData,
    comparatorCampaignTables,
    fetchCampaignsHistogram,
    fetchComparisonCampaignTable,
    cleanCampaignsData,
  } = projectComparisonStore;

  useEffect(() => {
    if (organizationDashboardStore.activeViewType === ActiveViewType.CHART) {
      setLoader(true)
      fetchCampaignsHistogram()
        .then(() => {
          setLoader(false)
          setInitialLoader(true);
        })
    }
  }, [platformChangeFactors, rangeChangeFactors])

  useEffect(() => {
    return cleanCampaignsData;
  }, []);

  return <ChartTableLayout activeViewType={ organizationDashboardStore.activeViewType }
                           chart={ <>
                             <ChartCard disablePlatformFetch
                                        size={ 'large' }
                                        platformChangeFactors={ platformChangeFactors }
                                        isPlatformSelected={ isPlatformSelected }
                                        rangeChangeFactors={ rangeChangeFactors }
                                        externalLoaders={ campaignLoaders() }
                                        data={ { line: campaignsClickedChartData } }
                                        customProperties={ { line: customComparatorLineChartProperties } }
                                        chartType={ ChartType.LINE }
                                        title={ t('organization/project-comparison::Clicked') }/>

                             <ChartCard disablePlatformFetch
                                        size={ 'large' }
                                        externalLoaders={ campaignLoaders() }
                                        platformChangeFactors={ platformChangeFactors }
                                        isPlatformSelected={ isPlatformSelected }
                                        rangeChangeFactors={ rangeChangeFactors }
                                        data={ { line: campaignsSentChartData } }
                                        customProperties={ { line: customComparatorLineChartProperties } }
                                        chartType={ ChartType.LINE }
                                        title={ t('organization/project-comparison::Number of campaigns') }/>

                             <ChartCard disablePlatformFetch
                                        size={ 'large' }
                                        platformChangeFactors={ platformChangeFactors }
                                        isPlatformSelected={ isPlatformSelected }
                                        rangeChangeFactors={ rangeChangeFactors }
                                        externalLoaders={ campaignLoaders() }
                                        data={ { line: campaignsCtrChartData } }
                                        chartType={ ChartType.LINE }
                                        customProperties={ { line: customComparatorLineChartPropertiesInPercent } }
                                        title={ t('organization/project-comparison::CTR') }
                                        valueInPercent/>

                           </> }
                           table={ <>
                             <ProjectComparatorTable title={ t('organization/project-comparison::Clicked') }
                                                     emptyComponentType={ EmptyComponentDashboardStatsType.CAMPAIGN }
                                                     comparatorTable={ comparatorCampaignTables.clicked }
                                                     emptyComponentHeight={ 505 }
                                                     fetchTableData={ () => fetchComparisonCampaignTable('clicked') }/>

                             <ProjectComparatorTable title={ t('organization/project-comparison::Number of campaigns') }
                                                     emptyComponentType={ EmptyComponentDashboardStatsType.CAMPAIGN }
                                                     comparatorTable={ comparatorCampaignTables.campaignCount }
                                                     emptyComponentHeight={ 505 }
                                                     fetchTableData={ () => fetchComparisonCampaignTable('campaignCount') }/>

                             <ProjectComparatorTable title={ t('organization/project-comparison::CTR') }
                                                     percentage
                                                     emptyComponentType={ EmptyComponentDashboardStatsType.CAMPAIGN }
                                                     comparatorTable={ comparatorCampaignTables.ctr }
                                                     emptyComponentHeight={ 505 }
                                                     fetchTableData={ () => fetchComparisonCampaignTable('ctr') }/>

                           </> }/>
})
