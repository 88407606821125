import { API, UseCase } from '@ppg/common';
import { ICampaignSentHourStats, IOrganizationDashboardRequestBody } from '../../../interfaces';
import { DashboardCampaignType } from '../../../../pages/Dashboard/common/enums';

interface IGetCampaignsSendHoursRequest extends IOrganizationDashboardRequestBody {
  campaignType: DashboardCampaignType;
  timezone: string;
}

interface IGetCampaignsSendHoursResult {
  campaignSentHourStats: ICampaignSentHourStats[];
}

export class GetCampaignsSendHoursUseCase extends UseCase<IGetCampaignsSendHoursRequest, IGetCampaignsSendHoursResult> {
  protected async execute({ organization, platform, from, to, timezone, campaignType }: IGetCampaignsSendHoursRequest) {
    const platformType = platform ? `/${ platform }` : '';
    let query = '?';
    query += `&from=${ from }&to=${ to }`;
    query += `&timezone=${ timezone }`;

    return API.HTTP.get<IGetCampaignsSendHoursResult>(
      `statistics/organizations/${ organization }/campaigns/statistics/${ campaignType }/hours/sent${ platformType }${ query }`
    );
  };
}


