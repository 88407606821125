import { observer } from 'mobx-react';
import { BarSvgProps, ResponsiveBar } from '@nivo/bar';

import * as React from 'react';
import { DEFAULT_BAR_CHART_PROPERTIES } from './defaultValues';

export const BarChart = observer((
    {
      colors,
      data,
      keys,
      indexBy,
      tooltip,
      margin = DEFAULT_BAR_CHART_PROPERTIES.margin,
      groupMode = DEFAULT_BAR_CHART_PROPERTIES.groupMode,
      layout = DEFAULT_BAR_CHART_PROPERTIES.layout,
      defs = DEFAULT_BAR_CHART_PROPERTIES.defs,
      fill = DEFAULT_BAR_CHART_PROPERTIES.fill,
      axisTop = DEFAULT_BAR_CHART_PROPERTIES.axisTop,
      axisRight = DEFAULT_BAR_CHART_PROPERTIES.axisRight,
      axisBottom = DEFAULT_BAR_CHART_PROPERTIES.axisBottom,
      axisLeft = DEFAULT_BAR_CHART_PROPERTIES.axisLeft,
      enableGridX = DEFAULT_BAR_CHART_PROPERTIES.enableGridX,
      enableLabel = DEFAULT_BAR_CHART_PROPERTIES.enableLabel,
      labelSkipWidth = DEFAULT_BAR_CHART_PROPERTIES.labelSkipWidth,
      labelSkipHeight = DEFAULT_BAR_CHART_PROPERTIES.labelSkipHeight,
      labelTextColor = DEFAULT_BAR_CHART_PROPERTIES.labelTextColor,
      animate = DEFAULT_BAR_CHART_PROPERTIES.animate,
      motionStiffness = DEFAULT_BAR_CHART_PROPERTIES.motionStiffness,
      motionDamping = DEFAULT_BAR_CHART_PROPERTIES.motionDamping,
      legends = DEFAULT_BAR_CHART_PROPERTIES.legends
    }: Partial<BarSvgProps>) => {

    return <ResponsiveBar
      colors={ colors }
      data={ data }
      keys={ keys }
      tooltip={ tooltip }
      indexBy={ indexBy }
      margin={ margin }
      groupMode={ groupMode }
      layout={ layout }
      defs={ defs }
      fill={ fill }
      axisTop={ axisTop }
      axisRight={ axisRight }
      axisBottom={ axisBottom }
      axisLeft={ axisLeft }
      enableGridX={ enableGridX }
      enableLabel={ enableLabel }
      labelSkipWidth={ labelSkipWidth }
      labelSkipHeight={ labelSkipHeight }
      labelTextColor={ labelTextColor }
      animate={ animate }
      motionStiffness={ motionStiffness }
      motionDamping={ motionDamping }
      legends={ legends }
    />
  }
)
