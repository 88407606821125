import * as React from 'react';
import { observer } from 'mobx-react';
import './Authorization.scss';
import { RouteComponentProps } from 'react-router-dom';
import { LoginPage } from './LoginPage';

@observer
export class LoginLite extends React.Component<RouteComponentProps> {
  render() {
    const { search } = this.props.location;
    const { params } = this.props.match;

    return <LoginPage search={ search } params={params} lite />

  }
}
