import { API, UseCase } from '@ppg/common';
import { IGetHMSResponse, IProvider } from '../interfaces';

export class GetHMSProviderUseCase extends UseCase<IProvider, IGetHMSResponse> {
  protected async execute({ projectId, organizationId }: IProvider) {
    return API.HTTP.get<IGetHMSResponse>(`core/organizations/${ organizationId }/projects/${ projectId }/providers/hms`);
  }
}

export const getHMSProviderUseCase = new GetHMSProviderUseCase();
