import { UseCase, API } from "@ppg/common";

interface IAcceptABTest {
  abTestId: string;
  testStartDate: Date;
  testEndDate: Date;
  winnerStartDate: Date;
  winnerEndDate: Date;
}

export class AcceptABTestWithAutoWinnerUseCase extends UseCase<IAcceptABTest, void> {
  protected async execute({ testStartDate, testEndDate, abTestId, winnerEndDate, winnerStartDate }: IAcceptABTest) {
    return API.HTTP.post<void>(`core/projects/${ API.HTTP.currentProjectId }/ab-tests/${ abTestId }/accept/auto`, {
      testStartDate,
      testEndDate,
      winnerSendDate: winnerStartDate,
      winnerExpireDate: winnerEndDate,
    });
  }
}


export const acceptABTestWithAutoWinnerUseCase = new AcceptABTestWithAutoWinnerUseCase();
