import { API, UseCase } from '@ppg/common';
import { getRangeDateParam, getTimezoneParam } from '../../../helpers';

interface IGetActiveSubscribersRatioRequest {
  organization: string;
  from: string;
  to: string;
  timezone: string;
}

export interface IGetActiveSubscribersRatioResult {
  deliveredActiveSubscribersRatios: {day: string; deliveredRatio: number}[]
}

export class GetActiveSubscribersRatioUseCase extends UseCase<IGetActiveSubscribersRatioRequest, IGetActiveSubscribersRatioResult> {
  protected async execute({ organization, from, to, timezone }: IGetActiveSubscribersRatioRequest) {
    const endpoint = `statistics/organizations/${ organization }/subscribers/active/ratio`
      + getRangeDateParam(from, to) + getTimezoneParam(timezone)

    return API.HTTP.get<IGetActiveSubscribersRatioResult>(endpoint)
  }
}

