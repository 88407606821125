import * as React from 'react';
import { t } from '../../base/helpers';
import cs from 'classnames';
import { Loader } from '@ppg/styled';
import { inject, observer } from 'mobx-react';
import { SelectorStore } from '../../stores/project/SelectorStore';
import ReactTooltip from 'react-tooltip';

export interface IWizardSelector {
  selector: string;
  value: string;
  type: string;
}

interface IWizardState {
  hasConnected: boolean;
  interval: any;
  wizardSelector: IWizardSelector;
  selectionMode: boolean;
  connectionState?: ConnectionType;
}

type ConnectionType = 'error' | 'initializing' | 'connected';

interface ISelectorsProps {
  selectorStore?: SelectorStore;
}

@inject('selectorStore')
@observer
export class SelectorsWizard extends React.Component<ISelectorsProps, IWizardState> {

  public interval: any;

  public state = {
    hasConnected: false,
    interval: null,
    connectionState: 'initializing' as ConnectionType,
    selectionMode: false,
    wizardSelector: {
      selector: '',
      value: '',
      type: ''
    },
  };

  public componentWillUnmount() {
    window.removeEventListener('message', this.receiveMessageBind);
  }

  private setSelectionMode() {
    this.setState({ selectionMode: true });
    return this.sendToIframe('selectorMode');
  }

  private setNavigationMode() {
    this.setState({ selectionMode: false });
    return this.sendToIframe('navigationMode');
  }

  public renderWizard() {
    const showIframe = this.state.connectionState === 'connected';
    switch (this.state.connectionState) {
      case 'error':
        return <div className="alert-block alert--error">
          <span className="m5r">
            { t("Unfortunately your website doesn't want to cooperate with our wizard. You need to create a selector in an old way - just close a wizard and click new selector button.") }
            </span>
        </div>;
      case 'connected':
      case 'initializing':
        return <div>
          { !showIframe && <Loader/> }
          <iframe id="ppg-wizard"
                  style={ { border: 0, display: showIframe ? 'block' : 'none' } }
                  src={ this.props.selectorStore.wizardUrl }
                  onLoad={ () => this.wizardConnector() }
                  height={ 600 } width={ '100%' }/>
        </div>;
    }
  }

  public render() {
    const { wizardSelector, selectionMode } = this.state;

    return <section>
      <button className={ cs('button m5r', { 'button-primary': !selectionMode, 'button-outline': selectionMode }) }
              onClick={ () => this.setNavigationMode() }>{ t('Navigate') }</button>
      <button className={ cs('button m5r button--icon', { 'button-primary': selectionMode, 'button-outline': !selectionMode }) }
              onClick={ () => this.setSelectionMode() }>
        <span>{ t('Select') }</span>
        <span className="icon-plus-badge size14"/>
      </button>

      <div className='well m5b m10t' style={{height: 80}}>
        <div className='row break-on-medium'>
          <div className='column'>
            <div className='row'>
              <div>{ t('Selector') }:</div>
              <div className='m5l'>{ wizardSelector && wizardSelector.selector }</div>
            </div>
          </div>
          <div className='column'>
            <div className='row'>
              <div>{ t('Value') }:</div>
              <div className='m5l'>{ wizardSelector && wizardSelector.value }</div>
            </div>
          </div>
          <div className='column'>
            <div className='row'>
              <div>{ t('Type') }:</div>
              <div className='m5l'>{ wizardSelector && wizardSelector.type }</div>
            </div>
          </div>
        </div>
      </div>
      { this.renderWizard() }
      <ReactTooltip type="light" effect="solid"/>
    </section>;
  }

  private receiveMessage = (event: MessageEvent) => {
    const { data } = event;
    let parsedData;

    try {
      if (typeof data === 'object') {
        parsedData = data;
      } else {
        parsedData = JSON.parse(data);
      }
    } catch (error) {
      parsedData = {};
      console.error(error);
    }

    const { task, payload } = parsedData;
    switch (task) {
      case 'connected':
        return this.connectedMessageHandler();
      case 'reconnect':
        return this.reconnectMessageHandler();
      case 'view':
        return this.viewMessageHandler(payload);
      case 'selector':
        return this.selectorMessageHandler(payload);
    }
  };

  private selectorMessageHandler(payload: IWizardSelector) {
    this.props.selectorStore.onWizardAdd(payload);
  }

  private connectedMessageHandler() {
    window.clearInterval(this.state.interval);
    this.setState({ connectionState: 'connected', hasConnected: true });
    this.sendToIframe('navigationMode');
    clearInterval(this.interval);
  }

  private reconnectMessageHandler() {
    this.initializeConnectionStateHandler();
    window.removeEventListener('message', this.receiveMessageBind);
    setTimeout(() => this.wizardConnector(), 500);
  }

  private viewMessageHandler(payload: IWizardSelector) {
    this.setState({ wizardSelector: payload });
  }

  private receiveMessageBind = this.receiveMessage.bind(this);

  private initializeConnectionStateHandler() {
    if (!this.state.hasConnected) {
      const interval = setTimeout(() => {
        this.setState({
          connectionState: 'error'
        });
      }, 5000);

      this.setState({
        interval: interval,
        connectionState: 'initializing'
      });
    }
  }

  private wizardConnector() {
    this.initializeConnectionStateHandler();
    window.removeEventListener('message', this.receiveMessageBind);
    window.addEventListener('message', this.receiveMessageBind, false);

    this.interval = setInterval(() => {
      this.sendToIframe('connect');
    }, 250);
  }

  private sendToIframe(message: any) {
    const iframe: any = document.getElementById('ppg-wizard');
    if (iframe) {
      iframe.contentWindow.postMessage(message, '*');
    }
  }
}
