import cs from 'classnames';
import { t } from '../../base/helpers';
import * as React from 'react';
import { selectorEventTypes, selectorSettingsTypes } from '../../modelsMobx/selector/ISelector';
import { Selector } from '../../modelsMobx/selector/Selector';
import { observer } from 'mobx-react';
import {CheckBox, Help, Input, InputDataList, ISelectOption, Select} from '@ppg/styled';
import { DynamicFieldTypeTypes } from '../../modelsMobx/helpers/DynamicField';
import {TTLInput} from "../../components/TTLInput/TTLInput";
import "./Selectors.scss"

interface ISelectorsProps {
  selector: Selector;
  labelStrategyOptions: ISelectOption[];
  availableLabels: string[];
}

@observer
export class SelectorsFormView extends React.Component<ISelectorsProps, {}> {

  public render() {
    const { selector, labelStrategyOptions } = this.props;
    return (
      <>
        <Input property={ selector.getProperty('prettyName') }
               label={ t('Pretty name') }
               type="text"
               placeholder={ t('e. g Category taken from breadcrumbs') }/>

        { !selector.isMagic &&
        <div className="m10b">
          { t('Variable name') }: <b>{ selector.name }</b>
        </div>
        }

        <Input property={ selector.getProperty('selector') }
               label={ selector.isJS ? t('JavaScript window path') : t('HTML selector') }
               type="text"
               placeholder={ selector.isJS ? t('e.g window.dataLayer[0].event.product.id') : t('e.g #basket > a.buy') }/>

        { selector.isMagic &&
        <div>
            <Input property={ selector.getProperty('labelKey') }
                   label={ t('Define label key') }
                   helperContent={ t('In this field you can use this characters: a-z, 0-9, . , - , _') }
                   helperPlace='right'
                   list={ "available_labels" }
                   type="text"
                   placeholder={ 'default' }/>
            <InputDataList id={ "available_labels" } values={ this.props.availableLabels }/>

            {selector.labelKey && selector.labelKey !== 'default' &&
              <>
                <div className="selectors-form-view__expire-date">
                  <h3>
                    { t('Expire date') }
                    <Help place="right" content={ t('Time to live label value, after this time label value will expire. If you don\'t want to expire label value just set 0') }/>
                  </h3>
                  <TTLInput disabled={false} initialTTLValue={selector.getProperty('labelTTL').getValue()} onChange={ (ttl) => selector.getProperty('labelTTL').setValue(ttl) }/>
                </div>

                <Select
                  property={selector.getProperty('labelStrategy')}
                  options={labelStrategyOptions}
                  helperContent={t('Rewrite - strategy will replace all values contains "label". Append - strategy will append value to "label". Default label cannot have "rewrite" option.')}
                  label={t('Label strategy append / rewrite')}/>
              </>
            }
        </div>
        }

        { selector.isNew
          ? <>
            { t('Select selector type') }
            <div className="box-options">
              { Selector.settingsTypeOptions.map((option, index) =>
                <div key={ index }
                     className={ cs("box-options__item box-options__item--50", { "active": selector.settingsType === option.value }) }
                     onClick={ () => selector.isNew && selector.getProperty('settingsType').setValue(option.value) }>{ option.name }</div>
              ) }
            </div>
          </>
          : <div className="input-wrapper">
            <label>{ t('Selector type') }</label>
            <div className={ "box options box-options__item box-options__item--50 active" }>
              { selector.settingsTypeDescription }
            </div>
          </div>
        }

        { selector.settingsType === selectorSettingsTypes.CUSTOM &&
        <div>
          <div className="row m10t">
            <div className="column">
              <Select property={ selector.getProperty('valueType') }
                      disabled={ !selector.isNew }
                      options={ Selector.typeOptions }
                      label={ t('Type') }/>
            </div>
            <div className="column">
              <Select property={ selector.getProperty('event') }
                      options={ Selector.eventOptions }
                      label={ t('Event') }/>
            </div>
            <div className="column">
              <Select property={ selector.getProperty('reducer') }
                      options={ selector.reducerOptions }
                      label={ t('Reducer') }/>
            </div>
          </div>
          { selector.valueType !== DynamicFieldTypeTypes.BOOLEAN &&
          <Input property={ selector.getProperty('attribute') }
                 label={ t('Element property') }
                 type="text"
                 placeholder={ t('e.g data-id, href') }/>
          }

          { selector.event === selectorEventTypes.CLICK &&
          <CheckBox property={ selector.getProperty('bindAll') }
                    label={ t('Watch every element click') }/>
          }
        </div>
        }

      </>
    );
  }
}
