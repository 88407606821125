import { API, UseCase } from "@ppg/common";
import { Label, LabelState } from "../../../modelsMobx/Label";

interface IGetResolveIdsToLabelsRequest {
  ids: string[];
}

interface IGetResolveIdsToLabelsResponseItem {
  key: string;
  value: string;
  approxCount: number;
}

export class GetResolveIdsToLabels extends UseCase<IGetResolveIdsToLabelsRequest, Label[]> {
  protected async execute({ ids }: IGetResolveIdsToLabelsRequest) {

    if (!ids || ids.length === 0) {
      return [];
    }

    let params = new URLSearchParams();

    for (let id of ids) {
      params.append("ids", id);
    }

    const response = await API.HTTP.get<IGetResolveIdsToLabelsResponseItem[]>(`core/projects/${ API.HTTP.currentProjectId }/segmentation/labels/resolve?${ params }`);

    return response.map(labelDTO => new Label({ ...labelDTO, state: LabelState.IDLE }));
  }
}

export const getResolveIdsToLabels = new GetResolveIdsToLabels();
