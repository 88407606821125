export interface IFontSize {
  xs: string,
  s:  string,
  m:  string,
  l: string,
  xl:  string,
  xxl:  string,
}

export const size:IFontSize = {
  xs: '0.8rem',
  s: '1.1rem',
  m: '1.3rem',
  l: '1.6rem',
  xl: '1.8rem',
  xxl: '2rem',
}
