import * as React from 'react';
import { observer } from 'mobx-react';
import { t } from '../../../base/helpers';
import * as Styled from './Validators.styled';
import { Property } from '@ppg/common';

interface IErrorList {
  property: Property;
  label?: string;
  max?: number;
  customMessage?: string;
}

export const ErrorList = observer(({ label, max, property, customMessage = null }: IErrorList): JSX.Element => {
  if (property.isValid) return null;

  const getErrorMessage = (): string => {
    if (label && property.getValue().length === 0) {
      return t('%{fieldName} is required', { fieldName: label });
    }

    if (label && max && property.getValue().length > max) {
      return t(`%{fieldName} must be shorter then %{max} characters`, { fieldName: label, max: max });
    }

    if (customMessage) {
      return customMessage;
    }

    return null;
  }

  return getErrorMessage() ? <Styled.ErrorMessage>{ getErrorMessage() }</Styled.ErrorMessage> : null;
})