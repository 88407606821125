export interface IAccident {
  info: string;
  root: string;
  level: string;
  url: string;
  redirect: string;
}

export class Accident {
  public info: string;
  public root: string;
  public level: string;
  public url: string;
  public redirect: string;

  constructor(accident: IAccident) {
    this.info = accident.info;
    this.root = accident.root;
    this.level = accident.level;
    this.url = accident.url;
    this.redirect = accident.redirect
  }

  public occurredHere(location: string) {
    if (this.root === '/') {
      return true;
    }
    const locationParts = location.split('/');
    const root = this.root.split('/');

    return root.every(rootPart => locationParts.includes(rootPart));
  }

  get alertLevel() {
    return AlertLevelMapTypes[this.level];
  }
}

export enum AlertLevelMapTypes {
  info = 'success',
  warning = 'warning',
  error = 'error',
  critical = 'error',
}
