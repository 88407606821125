import { property, ValueValidator } from '@ppg/common';
import { PaymentData } from '../../modelsMobx/PaymentData';
import { UserRelatedStore } from '../user/UserRelatedStore';
import { computed } from 'mobx';
import { getPaymentDataUseCase, saveInvoiceDetailsUseCase } from '../../useCases/accounting';

export class PaymentDataStore extends UserRelatedStore {
  @property()
  public paymentData = new PaymentData();

  @computed
  public get hasPaymentData() {
    const { city, country, zip, street, taxNumber, name } = this.paymentData;
    return [city, country, zip, street, taxNumber, name].every(item => {
      return ValueValidator.isNotEmpty(item);
    });
  }

  @computed
  public get hasUserData() {
    const { firstName, lastName } = this.userStore.user;
    return [firstName, lastName].every(item => { return ValueValidator.isNotEmpty(item);});
  }

  public async fetchPaymentData(): Promise<void> {
    const data = await getPaymentDataUseCase.exec({ organizationId: this.userStore.user.organization });

    this.paymentData.parsePaymentData(data as object);
  }

  public async savePaymentData(): Promise<void> {
    await saveInvoiceDetailsUseCase.exec({
      paymentData: this.paymentData,
      organizationId: this.userStore.user.organization
    });
  }
}
