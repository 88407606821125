import { property, PropertyHandler } from '@ppg/common';
import { action, computed, observable } from 'mobx';
import { t } from '../../base/helpers';
import { createSegmentDraftUseCase, createSegmentUseCase, deleteSegmentUseCase, getLabelsKeysUseCase, getOperatorsUseCase, updateSegmentDraftUseCase, updateSegmentUseCase } from '../../useCases/core';
import { SegmentCondition } from './SegmentCondition';
import { ISelectOption } from '@ppg/styled';
import dayjs from 'dayjs';
import { Utils } from '../../stores/utils';
import { IGetOperatorsResponse } from '../../useCases/core/segments/GetOperatorsUseCase';
import { ISegmentDTO } from '../../useCases/core/campaigns/interfaces';
import { ISegmentConditionPlainObject } from '../../useCases/core/segments/CreateSegmentUseCase';

export type ISegmentConditions = SegmentCondition[]

export enum SegmentState {
  READY = "READY",
  BUILDING = "BUILDING",
  DRAFT = "DRAFT"
}

export interface ISegment {
  id: string;
  name: string;
  conditions: ISegmentConditions[];
  audience: number;
  state: SegmentState;
}

export class Segment extends PropertyHandler implements ISegment {
  @property() public id: string;
  @property() public name: string;
  @property() public conditions: ISegmentConditions[];
  @property() public audience: number;
  @property() public state: SegmentState;

  @property() public conditionKeys: string[] = [];
  @property() public conditionOperators: IGetOperatorsResponse = {};
  @observable public isConditionFieldsLoading: boolean = true;

  constructor(segment) {
    super();
    this.id = segment.id;
    this.name = segment.name;
    this.conditions = segment.conditions;
    this.audience = segment.audience;
    this.state = segment.state;
  }

  public static createSegment(segment): Segment {

    const getConditionEditValue = (value): string => {
      if (SegmentCondition.DATE_ISO_FORMAT.test(value)) {
        const localDate: Date = dayjs(value).utc().toDate();
        return dayjs(localDate).format("YYYY-MM-DD");
      }
      return value;
    };

    const conditions: ISegmentConditions[] = segment.conditions.map(segmentOR => segmentOR.map(segmentAND => new SegmentCondition({
      key: segmentAND.key,
      value: getConditionEditValue(segmentAND.value),
      operator: segmentAND.operator
    })));

    return new Segment({
      id: segment.id,
      name: segment.name,
      conditions: conditions,
      audience: segment.audience,
      state: segment.state
    });
  }

  private static schemaNewSegmentCondition(): SegmentCondition {
    return SegmentCondition.createSegmentCondition({
        key: "",
        value: "",
        operator: ""
      }
    );
  }

  private static schemaCopySegmentCondition(condition: SegmentCondition): SegmentCondition {
    return SegmentCondition.createSegmentCondition({
        key: condition.key,
        value: "",
        operator: condition.operator
      }
    );
  }

  public async onDeleteSegment(): Promise<void> {
    await deleteSegmentUseCase.exec({
      segment: this.id
    });
  }

  public convertSegmentConditions(conditions): ISegmentConditionPlainObject {
    return {
      operator: "OR",
      conditions: conditions.map(conditionGroup => ({
        operator: "AND",
        conditions: conditionGroup.map(condition => ({
          key: condition.key, operator: condition.operator, value: condition.value
        }))
      }))
    }
  }

  public async createNewSegment(segment: Segment): Promise<void> {
    await createSegmentUseCase.exec({
      name: segment.name,
      conditions: this.convertSegmentConditions(segment.conditions)
    });
  }

  public async updateSegment(segment: Segment): Promise<void> {
    await updateSegmentUseCase.exec({
      segment: segment.id,
      name: segment.name,
      conditions: this.convertSegmentConditions(segment.conditions)
    });
  }

  public async createSegmentDraft(segment: Segment): Promise<void> {
    await createSegmentDraftUseCase.exec({
      name: segment.name,
      conditions: this.convertSegmentConditions(segment.conditions)
    });
  }

  public async updateSegmentDraft(segment: Segment): Promise<void> {
    await updateSegmentDraftUseCase.exec({
      segment: segment.id,
      name: segment.name,
      conditions: this.convertSegmentConditions(segment.conditions)
    });
  }

  public async pasteSegment(segment: Segment): Promise<Segment> {
    const segmentPaste = {
      id: this.id,
      name: segment.name,
      conditions: segment.conditions,
      audience: segment.audience,
      state: segment.state
    };

    return Segment.createSegment(segmentPaste);
  }

  // state

  @computed
  public get isSegmentDraft(): boolean {
    return this.state === SegmentState.DRAFT;
  }

  @computed
  public get isSegmentBuilding(): boolean {
    return this.state === SegmentState.BUILDING;
  }

  // handle conditions

  @action
  public addConditionOR(): void {
    this.conditions.push([Segment.schemaNewSegmentCondition()]);
  }

  @action
  public addConditionAND(idConditionOR: number): void {
    this.conditions.map((condition: ISegmentConditions, id: number) => id === idConditionOR && condition.push(Segment.schemaNewSegmentCondition()));
  }

  @action
  public deleteConditionOR(conditionORToDelete: SegmentCondition[]): void {
    this.conditions = this.conditions.filter(conditionOR => conditionOR !== conditionORToDelete);
  }

  @action
  public deleteConditionAND(conditionANDToDelete: SegmentCondition): void {
    const conditionsList = this.conditions.map(conditionOR => {
      return conditionOR.filter(conditionAND => conditionAND !== conditionANDToDelete);
    });

    this.conditions = conditionsList.filter(conditionOR => conditionOR.length !== 0);
  }

  @action
  public copyConditionOR(conditionORToCopy: SegmentCondition[]): void {
    const conditionToCopy = conditionORToCopy.map(conditionAND => Segment.schemaCopySegmentCondition(conditionAND));
    this.conditions.push(conditionToCopy);
  }

  public getConditionsDetails(): string {
    let conditionsDetails = "";

    this.conditions.forEach((conditionOR: ISegmentConditions, id: number) => {
      let conditionsAndDetails = "";

      conditionOR.forEach(conditionAND => {
        const { key, operator, value } = conditionAND;

        const secondFormatData: string = conditionAND.valueTypeIsDate ? "s" : "";
        const operatorFormat = operator.split('_').join(' ').toLowerCase();

        conditionsAndDetails += `
        <p class="condition-and">
        <span class="condition-type-and">key:</span> ${ key } ,
        <span class="condition-type-and">operator:</span> ${ operatorFormat } ,
        <span class="condition-type-and">value:</span> ${ value } ${ secondFormatData }
        </p>`;
      });

      const addOr = id + 1 !== this.conditions.length ? "<p class='condition-type-or'>or</p>" : "";
      conditionsDetails += `${ conditionsAndDetails } ${ addOr }`;
    });

    return conditionsDetails;
  }

  // handle segment fields

  @computed
  public get hasConditionKeys(): boolean {
    return this.conditionKeys.length !== 0;
  }

  @computed
  public get hasConditionOperators(): boolean {
    return Object.keys(this.conditionOperators).length !== 0;
  }

  @action
  public generateDefaultSegmentName(): void {
    const localDate: Date = dayjs().utc().toDate();
    const currentDate: string = dayjs(localDate).format("LLL");
    this.name = `${ t(`Segment - ${ currentDate }`) }`;
  }

  @action
  public async fetchConditionKeys(): Promise<void> {
    await this.executeWithConditionFieldsLoader(async () => {
      this.conditionKeys = await getLabelsKeysUseCase.exec();
    });
  }

  @action
  public async fetchConditionOperators(): Promise<void> {
    await this.executeWithConditionFieldsLoader(async () => {
      this.conditionOperators = await getOperatorsUseCase.exec();
    });
  }

  public getConditionValuesOptions(items): ISelectOption[] {
    const options: ISelectOption[] = items.map(item => {
      return { value: `${ item }`, name: t(`${ item }`) };
    }) ?? [];

    return [{ value: "", name: "", disabled: true, hidden: true }, ...options];
  }

  public getConditionOperatorsOptions(): ISelectOption[] {
    const operators = Object.keys(this.conditionOperators);

    const options: ISelectOption[] = operators.map(operator => {
      const formatOperator = operator.split('_').join(' ').toLowerCase();
      return { value: `${ operator }`, name: t(`segments/new::${ formatOperator }`) };
    }) ?? [];

    return [{ value: "", name: "", disabled: true, hidden: true }, ...options];
  }

  public getConditionValueType(condition: SegmentCondition, operator: string): void {
    condition.setConditionValueType(this.conditionOperators[operator]);
  }

  // validation when save segment

  @action
  public validateSegmentFields(): string | null {

    const incorrectConditions = this.conditions.map(conditionOR => conditionOR.filter((conditionAND) => conditionAND.checkEmptyAllConditionFields));

    if (!this.name) {
      return t('Segment name is required');
    } else if (incorrectConditions) {
      let errorMessage = "";

      for (let conditionOR of this.conditions) {
        for (let conditionAND of conditionOR) {
          if (!errorMessage && conditionAND.validate()) {
            errorMessage = conditionAND.validate();
          }
        }
      }
      return errorMessage;
    }

    return null;
  }

  private executeWithConditionFieldsLoader<T = any>(callback: () => Promise<T>) {
    return Utils.executeWithLoader(
      callback,
      () => this.isConditionFieldsLoading = true,
      () => this.isConditionFieldsLoading = false,
      (err) => {
        console.error(err);
        this.isConditionFieldsLoading = false;
      }
    );
  }

  public convertToSegmentDTO(): ISegmentDTO {
    return {
      id: this.id,
      name: this.name,
      audience: this.audience,
      exists: true
    };
  }
}
