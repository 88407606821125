import { API, UseCase } from "@ppg/common";
import { IGetFCMResponse, IGetHMSResponse, IProvider } from "../interfaces";

export enum IIntegrationType {
  HMS = "android_hms",
  FCM = "android_fcm",
  SAFARI = "apns_web",
  IOS = "apns_ios",
  VAPID = "unknown",
}

export enum IProviderAction {
  GET = "get",
  MIGRATE_TO_V1 = "migrateToV1",
  UPDATE_CERTIFICATES = "updateCertificates",
  TOGGLE_PROD = "toggleProductionMode",
  CREATE = "create",
}

export interface IGetProvider {
  actions: IProviderAction[];
}

export interface IGetVapid {
  privateKey: string;
  publicKey: string;
}

export interface IGetIOSResponse {
  appBundleId: string;
  production: boolean;
  notAfter?: string;
  notBefore?: string;
}

export interface IGetSafariResponse {
  websitePushId: string;
  siteUrl: string;
  name: string;
  notAfter?: string;
  notBefore?: string;
}

export interface IGetProvidersResponse {
  integrations: [
    IVapidIntegration,
    IHMSIntegration,
    IFCMIntegration,
    ISafariIntegration,
    IIOSIntegration
  ];
}

interface IVapidIntegration extends IGetProvider {
  type: IIntegrationType.VAPID;
  payload: IGetVapid;
}

interface IFCMIntegration extends IGetProvider {
  type: IIntegrationType.FCM;
  payload: IGetFCMResponse;
}

interface IHMSIntegration extends IGetProvider {
  type: IIntegrationType.HMS;
  payload: IGetHMSResponse;
}

interface IIOSIntegration extends IGetProvider {
  type: IIntegrationType.IOS;
  payload: IGetIOSResponse;
}

interface ISafariIntegration extends IGetProvider {
  type: IIntegrationType.SAFARI;
  payload: IGetSafariResponse;
}

export class GetProjectProviderUseCase extends UseCase<
  IProvider,
  IGetProvidersResponse
> {
  protected async execute({ projectId, organizationId }: IProvider) {
    return API.HTTP.get<IGetProvidersResponse>(
      `core/organizations/${organizationId}/projects/${projectId}/providers`
    );
  }
}

export const getProjectProviderUseCase = new GetProjectProviderUseCase();
