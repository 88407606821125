import * as React from 'react';
import { LANGUAGES } from '@ppg/common';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { connector } from '../../base';
import { WebUser } from '../../models';
import { UserStore } from '../../stores/user/UserStore';
import './Authorization.scss';

interface IAuthorizationLayoutLanguagesProps {
  userStore?: UserStore;
}

@inject('userStore')
@observer
export class AuthorizationLayoutLanguages extends React.Component<IAuthorizationLayoutLanguagesProps> {

  private async selectLanguage(lang: string): Promise<void> {
    this.props.userStore.lang = lang;

    // @todo
    const user = connector.get<WebUser>('user');
    user.lang = lang;
    await user.setLang(lang);
  }

  private getClassName(lang: string): string {
    return classNames({
      'app-lang': true,
      'active': this.isLangSelected(lang)
    });
  }

  private isLangSelected(lang: string): boolean {
    return this.props.userStore.lang === lang;
  }

  public render() {
    return (
      <nav className="language-options">
        <ul>
          {
            Object.keys(LANGUAGES).map(lang => (
              <li key={ lang }
                  className={ this.getClassName(lang) }
                  lang={ lang }
                  onClick={ () => this.selectLanguage(lang) }>{ lang }</li>
            ))
          }
        </ul>
      </nav>
    );
  }
}
