import * as React from 'react';
import { IAbstractFieldProps } from './IAbstractFieldProps';
import * as cs from 'classnames';
import * as lodash from 'lodash';
import { v4 } from 'uuid';

export abstract class AbstractField<TProps extends IAbstractFieldProps, TState> extends React.Component<TProps, TState> {
  protected inputId: string = v4();

  protected getClassName(): string {
    const { attribute } = this.props;
    if (attribute.hasErrors()) return 'error';
    if (attribute.hasWarnings()) return 'warning';
    if (attribute.hasInfoMessages()) return 'info';
    return attribute.getValue() && attribute.getValue().length > 0 ? 'success' : '';
  }

  protected renderErrors() {
    const messages = this.props.attribute.getMessages();

    if (lodash.isEmpty(messages)) return null;

    return (
      <ul className="errors-list">
        {messages.map((msg, key) => <li key={ key }> {msg.message} </li>)}
      </ul>
    )
  }
}