import { API, UseCase } from '@ppg/common';

export interface IFormatLabelReq {
  value?: string;
  key?: string;
}

export interface IFormatLabelRes {
  result: string;
}

export class FormatLabelUseCase extends UseCase<IFormatLabelReq, IFormatLabelRes> {
  protected async execute({ key, value }: IFormatLabelReq) {
    const searchParams = new URLSearchParams();
    searchParams.set(key ? 'key' : 'value', key || value);
    return API.HTTP.get<IFormatLabelRes>(`core/projects/${API.HTTP.currentProjectId}/segmentation/labels/format?${searchParams.toString()}`);
  }
}
