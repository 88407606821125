import { API, UseCase } from '@ppg/common';
import { IInvoice } from '../../modelsMobx/Invoice';

interface IGetPaidInvoices {
  organization: string;
  limit: number;
  offset: number;
  currency: string;
}

interface IGetPaidInvoicesResult {
  data: IInvoice[];
  metadata: {
    total: number,
  }
}

export class GetPaidInvoiceListUseCase extends UseCase<IGetPaidInvoices, IGetPaidInvoicesResult> {
  protected async execute({ organization, limit, offset, currency }: IGetPaidInvoices) {
    return API.HTTP.get<IGetPaidInvoicesResult>(`acc/organizations/${ organization }/invoices/paid/${currency}/?offset=${ offset }&limit=${ limit }`);
  }
}

export const getPaidInvoiceListUseCase = new GetPaidInvoiceListUseCase();
