import { API, UseCase } from '@ppg/common';

interface IDeleteImage {
  imageOwnerID: string;
}

export class DeleteImageUseCase extends UseCase<IDeleteImage, void> {
  protected async execute({ imageOwnerID }: IDeleteImage) {
    await API.HTTP.delete<IDeleteImage>(`core/projects/${ API.HTTP.currentProjectId }/images/owner/${ imageOwnerID }`)
  }
}

export const deleteImageUseCase = new DeleteImageUseCase();
