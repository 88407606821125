import * as React from 'react';
import { t } from '../../../base/helpers';
import { inject, observer } from 'mobx-react';
import { PartnershipStore } from '../../../stores/PartnershipStore';
import { observable } from 'mobx';
import { Help, Input } from '@ppg/styled';
import './Partnership.scss'
import { PartnerList } from './PartnerList';

interface IPartnershipProps {
  partnershipStore: PartnershipStore
}

@inject('partnershipStore')
@observer
export class Partnership extends React.Component<IPartnershipProps, {}> {
  @observable public isLoading:boolean = true

  componentDidMount() {
    this.props.partnershipStore.fetchPartnership()
      .then(() => this.isLoading = false)
  }

  public render() {

    if(this.isLoading){
      return null;
    }

    return (
      <div>
        <header className="page-header">
          <h2>{ t('@lite/user/partnership::Partnership') }</h2>
          <p>{ t('@lite/user/partnership::Using partnership you can earn money by recommending the application to others.') }</p>
        </header>

        <div className="well">
          <h4 className="form-title">{ t('@lite/user/partnership::Your partnership code') }
            <Help content={ t('@lite/user/partnership::If a user signs up using your partnership code they will get 30 free days of chosen plan.')}/>
          </h4>
          <Input property={this.props.partnershipStore.getProperty('code')} disabled/>
        </div>

        <PartnerList />
      </div>
    );
  }
}
