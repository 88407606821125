import { API, UseCase } from "@ppg/common";
import { ICopyToProjectRequest } from '../interfaces';

export class CopyToProjectsRocketPushUseCase extends UseCase<ICopyToProjectRequest, void> {
  protected async execute({ campaignId, projects }: ICopyToProjectRequest) {
    return API.HTTP.post<void>(`core/projects/${ API.HTTP.currentProjectId }/pushes/rocket/${ campaignId }/copy-transfer`, { projects });
  }
}

export const copyToProjectsRocketPushUseCase = new CopyToProjectsRocketPushUseCase();
