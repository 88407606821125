import { debounce, property, PropertyHandler } from '@ppg/common';
import { t } from '../../base/helpers';
import { action, computed } from 'mobx';
import { ISelectOption } from '@ppg/styled';
import dayjs from 'dayjs';
import { getLabelsUseCase } from '../../useCases/core';
import { SortTypeOptions } from '../../stores/project/SegmentationStore';
import { ConditionValueType } from '../../pages/Segmentation/SegmentCreator/SegmentConditionValues';

export enum PeriodDate {
  MINUTES = "minutes",
  HOURS = "hours",
  DAYS = "days",
  MONTHS = "months"
}

export interface IConditionItem {
  key: string;
  value: string;
  operator: string;
}

export class SegmentCondition extends PropertyHandler implements IConditionItem {
  @property() public key: string;
  @property() public value: string;
  @property() public operator: string;

  @property() public conditionValuesHints: string[] = [];
  @property() public conditionValueType: string = "";

  @property() public valueDateTtl: number = 0;
  @property() public valueDatePeriod: PeriodDate = PeriodDate.MINUTES;

  static LABELS_LIMIT = 20;
  static LABELS_OFFSET = 0;
  static DATE_ISO_FORMAT = /^(\d{4})-(\d{2})-(\d{2})(|T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?))$/;
  static DATE_FORMAT = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/;

  constructor(condition: IConditionItem) {
    super();
    this.key = condition.key;
    this.value = condition.value;
    this.operator = condition.operator;
  }

  public static createSegmentCondition(condition: IConditionItem): SegmentCondition {
    return new SegmentCondition(condition);
  }

  @action
  private async fetchLabels(selectKey: string): Promise<void> {
    const { data } = await getLabelsUseCase.exec({
      offset: SegmentCondition.LABELS_OFFSET,
      limit: SegmentCondition.LABELS_LIMIT,
      sortBy: SortTypeOptions.POPULAR,
      query: `${ this.value }`,
      key: selectKey
    });

    const labelValues: string[] = [...new Set(data.map(label => `${ label.value }`))];
    const hints = [...new Set(labelValues.map(labelValue => this.getLabelValue(labelValue)))];
    this.conditionValuesHints = this.hintsAreDates(hints) ? hints.sort() : hints;
  }

  private getLabelValue(labelValue: string): string {
    if (SegmentCondition.DATE_ISO_FORMAT.test(labelValue)) {
      const localDate: Date = dayjs(labelValue).utc().toDate();
      return dayjs(localDate).format("YYYY-MM-DD");
    }

    return labelValue;
  }

  private hintsAreDates(hints: string[]): boolean {
    return hints.every(hint => SegmentCondition.DATE_FORMAT.test(hint));
  }

  @action
  public async getHintsConditionValues(selectKey: string): Promise<void> {
    const getLabels = await this.fetchLabels(selectKey);
    const debounceGetLabels = debounce(() => getLabels, 2000);

    this.value ? debounceGetLabels() : getLabels;
  }

  @computed
  public get valueTypeIsDate(): boolean {
    return this.operator === "BEFORE_OR_EQUAL_TIME_PERIOD" || this.operator === "AFTER_OR_EQUAL_TIME_PERIOD";
  }

//  condition value type

  @action
  public setConditionValueType(type: string): void {
    this.conditionValueType = type;
  }

  public get isStringConditionValueType(): boolean {
    return this.conditionValueType === ConditionValueType.STRING;
  }

  // value of condition

  @action
  public setValueDateTtl(ttl: number): void {
    this.valueDateTtl = ttl;
  }

  @action
  public setValueDatePeriod(period: PeriodDate): void {
    this.valueDatePeriod = period;
  }

  @action
  public clearValueDate(): void {
    this.valueDateTtl = 0;
    this.valueDatePeriod = PeriodDate.MINUTES;

    this.valueTypeIsDate && this.setConditionValueForDate();
  }

  @action
  public setConditionValueForDate(): void {
    this.value = `${ this.convertTimeToSeconds(this.valueDateTtl, this.valueDatePeriod) }`;
  }

  public get previewDate(): string {
    const localDate: Date = dayjs().utc().toDate();
    return dayjs(localDate).subtract(this.valueDateTtl, this.valueDatePeriod).format('YYYY-MM-DD  HH:mm');
  }

  public convertTimeToSeconds(value: number, period: PeriodDate): number {
    switch (period) {
      case PeriodDate.MINUTES:
        return Math.floor(value * 60);
      case PeriodDate.HOURS:
        return Math.floor(value * 60 * 60);
      case PeriodDate.DAYS:
        return Math.floor(value * 24 * 60 * 60);
      case PeriodDate.MONTHS:
        return Math.floor(value * 24 * 60 * 60 * 30);
    }
  };

  public convertSecondsToTime(seconds: number): [number, string] {
    const patterns: [Function, string][] = [
      [(value: number) => value / (24 * 60 * 60 * 30), PeriodDate.MONTHS],
      [(value: number) => value / (24 * 60 * 60), PeriodDate.DAYS],
      [(value: number) => value / (60 * 60), PeriodDate.HOURS],
      [(value: number) => value / 60, PeriodDate.MINUTES],
    ];

    for (let [pattern, period] of patterns) {
      const ttl = pattern(seconds);

      if (Math.floor(ttl) > 0 && Number.isInteger(ttl)) {
        return [ttl, period];
      }
    }

    return [0, PeriodDate.MINUTES];
  };

  public get periodDateOptions(): ISelectOption[] {
    return [
      { name: "", value: "", disabled: true, hidden: true },
      { name: t('minutes'), value: PeriodDate.MINUTES },
      { name: t("hours"), value: PeriodDate.HOURS },
      { name: t("days"), value: PeriodDate.DAYS },
      { name: t("months"), value: PeriodDate.MONTHS }
    ];
  }

//  validation

  public checkValueIsEmpty(): number {
    const value = `${ this.value }`;
    return value.trim().length;
  }

  public get checkEmptyAllConditionFields(): boolean {
    return !this.key || !this.operator || !this.checkValueIsEmpty();
  }

  public validate(): string | null {
    if (!this.key) {
      return t('Field of label key in condition is required');
    } else if (!this.operator) {
      return t('Field of operator in condition is required');
    } else if (!this.checkValueIsEmpty()) {
      return t('Field of label value in condition is required');
    }

    return null;
  }

  public get valueContainsSpecialCharacters(): boolean {
    return this.key && !/^[A-Za-z0-9\-._ ]+$/.test(this.key);
  }
}
