import { UseCase, API } from '@ppg/common';

interface RegisterWithPlan {
  email: string;
  password: string;
  repeatedPassword: string;
  organizationName: string;
  acceptRules: boolean;
  currencyType: string;
  planType: string;
  privacyPolicyCheckDate: string;
}

interface RegisterWithPlanResult {
  token: string;
}

export class RegisterFullUserWithPlanUseCase extends UseCase<RegisterWithPlan, RegisterWithPlanResult> {
  protected async execute({ email, password, repeatedPassword, organizationName, acceptRules, currencyType, planType, privacyPolicyCheckDate }) {
    return API.HTTP.post<RegisterWithPlanResult>(`aai/user/full-register-with-plan?plan=${ planType }&currency=${ currencyType }`,
      { email, password, repeatedPassword, organizationName, acceptRules, privacyPolicyCheckDate });
  }
}

export const registerFullUserWithPlanUseCase = new RegisterFullUserWithPlanUseCase();
