export enum ABTestState {
  ALL = 'all',
  DRAFT = 'draft',
  ACCEPTED = 'accepted',
  PREPARING = 'preparing',
  READY_TO_SEND = 'test_ready_to_send',
  TEST_SENDING = 'test_sending',
  TEST_SENT = 'test_sent',
  MANUAL_WINNER = 'manual_winner',
  WINNER_READY_TO_SEND = 'winner_ready_to_send',
  WINNER_SENDING = 'winner_sending',
  FINISHED = 'finished',
  FAILED = 'failed',
  CANCELED = 'canceled',
}
