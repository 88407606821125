import { redirect, t } from '../../../../base/helpers';
import * as React from 'react';
import { observer } from 'mobx-react';
import { EmptyDataComponent, LinkButton, SearchInput } from '@ppg/styled';
import { PPGLite, PPGLiteRole } from '../../../../components/PPGLite/PPGLite';
import { ProjectRoutesTypes } from '../../../../routes/moduleProjectRoutes';

interface IProjectListHeaderProps {
  hasProjects: boolean;
  handleSearchProjectChange: (event: React.FormEvent<HTMLInputElement>) => void;
  value: string;
  hasOwnedProject: boolean;
}

@observer
export class ProjectListHeader extends React.Component<IProjectListHeaderProps> {
  public render() {
    if (this.props.hasOwnedProject) {
      return (
        <div className="project-list-header">
          <div className="project-list-title">
            <h4 className="form-title">
              { t('Your Projects') }:
            </h4>
          </div>
          <PPGLite role={ PPGLiteRole.HIDE }>
            <div className="column project-list-panel">
              <SearchInput handleSearch={ this.props.handleSearchProjectChange }
                           value={ this.props.value }
                           placeholder={ t('Search project') }/>
              <LinkButton
                path={ ProjectRoutesTypes.PROJECT_NEW }
                content={ t('New project') }
                size="small"
                icon="plus-badge"
              />
            </div>
          </PPGLite>
        </div>
      );
    }

    if (!this.props.hasProjects) {
      return <>
        <PPGLite role={ PPGLiteRole.HIDE }>
          <EmptyDataComponent
            content={ t('You don\'t have any projects') }
            buttonContent={ t('New project') }
            icon={ 'browser' }
            buttonIcon={ 'plus-badge' }
            onClick={ () => redirect(ProjectRoutesTypes.PROJECT_NEW) }
          />
        </PPGLite>

        <PPGLite role={ PPGLiteRole.DISPLAY }>
          <EmptyDataComponent
            content={ t('You don\'t have any projects') }
            buttonContent={ t('Complete your account configuration') }
            icon={ 'browser' }
            buttonIcon={ 'arrow-right' }
            onClick={ () => redirect(ProjectRoutesTypes.HOME) }
          />
        </PPGLite>
      </>;
    }

    return null;
  }
}
