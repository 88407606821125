import * as React from 'react';
import PPGLogo from '../../../../assets/images/ppg-logo.svg';
import { t } from '../../../../base/helpers';
import '../Settings/Settings.scss';
import { acceptChannelUseCase } from '../../../../useCases/notification/AcceptChannelUseCase';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

@observer
export class AcceptChannelRequest extends React.Component<any, any> {
  @observable isSuccess: boolean = false;
  @observable isLoading: boolean = true;

  async componentDidMount() {
    const { token } = this.props.match.params;
    await acceptChannelUseCase.exec({ token })
      .then(() => {
        this.isLoading = false;
        this.isSuccess = true;
      })
      .catch(() => {
        this.isLoading = false;
        this.isSuccess = false;
      })
  }

  render() {
    if (this.isLoading) {
      return null;
    }

    return (
        <div className='channel-request-wrapper'>
          <div className='channel-request-background'>
            <div className="channel-request-container">
              <img src={ PPGLogo } alt="PushPushGo" className="ppg-logo"/>
              {
                this.isSuccess
                  ? <div className="channel-request-pannel">
                    <p className="channel-feedback-message">{ t('Thank yor for accepting the request!') }</p>
                    <svg className="confirmation-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                      <circle className="confirmation-icon__circle" cx="26" cy="26" r="25" fill="none"/>
                      <path className="confirmation-icon__tick" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                    </svg>
                  </div> : <div className='channel-request-pannel-error'>
                    <p>
                      { t('There was a problem processing your request.') }
                    </p>
                  </div>
              }
            </div>
          </div>
        </div>
    );
  }
}
