import * as React from 'react';
import RenderRoutes from '../../../routes/RenderRoutes';
import { observer } from 'mobx-react';
import { UserLayout } from '../Layouts/UserLayout/UserLayout/UserLayout';
import { accountRoutes, accountNavigationTabs } from '../../../routes/projectRoutes';

@observer
export class Account extends React.Component<{}, {}> {
  public render() {
    return (
      <UserLayout navigationTabs={ accountNavigationTabs.account }>
        <RenderRoutes routes={ accountRoutes }/>
      </UserLayout>
    );
  }
}
