import { property, PropertyHandler } from '@ppg/common';

export class PaymentData extends PropertyHandler {
  @property()
  public name: string = '';

  @property()
  public street: string = '';

  @property()
  public house: string = '';

  @property()
  public flat: string = '';

  @property()
  public zip: string = '';

  @property()
  public country: string = 'pl';

  @property()
  public city: string = '';

  @property()
  public taxNumber: string = '';


  public parsePaymentData(data: object) {
    Object
      .keys(data)
      .forEach(item => {
        if (this.hasOwnProperty(item)) {
          this[item] = data[item];
        }
      });
  }
}
