import { ProjectRelated } from '../ProjectRelatedStore';
import { debounce, property } from '@ppg/common';
import { ITEMS_PER_PAGE } from '../../constants';
import { action, computed, observable, reaction } from 'mobx';
import { t } from '../../base/helpers';
import { Image, ImageType } from '../../modelsMobx';
import { deleteImageUseCase, getImagesByTypeUseCase, updateImageNameUseCase } from '../../useCases/core';

export class PhotoLibraryStore extends ProjectRelated {
  @property() public offset: number = 0;
  @property() public infinity: boolean;
  @property() public perPage: number = ITEMS_PER_PAGE;
  @property() public total: number;

  @observable public selectedImage: Image = null;
  @property() public files: Image[] = [];
  @property() public isLoading: boolean = true;

  @property() public selectedImageBase: string = '';

  @property() public imageType: ImageType = ImageType.PHOTO_LIBRARY_PUSH_IMAGE;
  @property() public searchQuery: string = '';

  @property() public addModal: boolean = false;
  @property() public editModal: boolean = false;
  @property() public previewModal: boolean = false;

  private reactOnImageTypeChange = reaction(() => this.imageType, () => this.fetchOnTypeChange());
  private reactOnSkipChange = reaction(() => this.doFetch, () => this.fetchItems());
  private reactOnSearchQueryChange = reaction(() => this.searchQuery, () => this.debouncedOnSearchField());

  private debouncedOnSearchField = debounce(() => this.fetchOnSearchChange(), 300);
  private debouncedFetch = debounce(() => this.fetchItems(), 300);

  setDefaultValues() {
    this.clearFiles();
    this.clearSelectedFile();
    this.offset = 0;
    this.infinity = false;
    this.perPage = ITEMS_PER_PAGE;
    this.addModal = false;
    this.editModal = false;
    this.searchQuery = '';
    this.imageType = ImageType.PHOTO_LIBRARY_PUSH_IMAGE;
    this.isLoading = true;
    this.total = 0;
    this.files = [];
  }

  @computed
  get doFetch() {
    return {
      offset: this.offset,
      perPage: this.perPage,
    };
  }

  @action
  public async fetchOnSearchChange(): Promise<void> {
    this.files = [];
    this.isLoading = true;
    this.offset === 0 ? this.debouncedFetch() : this.offset = 0;
  }

  @action
  public async fetchOnTypeChange(): Promise<void> {
    this.files = [];
    this.offset === 0 ? await this.fetchItems() : this.offset = 0;
  }

  @computed
  public get isIcon(): boolean {
    return this.imageType === ImageType.PHOTO_LIBRARY_PUSH_ICON;
  }

  @computed
  public get hasFiles(): boolean {
    return this.files.length > 0;
  }

  @computed
  public get getInfinity(): boolean {
    return this.infinity;
  }

  @computed
  public get getPlaceholder(): string {
    return this.imageType === ImageType.PHOTO_LIBRARY_PUSH_IMAGE ? t('photo-library::Search image by name') : t('photo-library::Search icon by name');
  }

  @computed
  public get getContentEmptyData(): string {
    return this.imageType === ImageType.PHOTO_LIBRARY_PUSH_IMAGE ?  t('photo-library::You don\'t have any images that meet the criteria') :  t('photo-library::You don\'t have any icons that meet the criteria');
  }

  @computed
  public get getImageType(): string {
    return this.imageType === ImageType.PHOTO_LIBRARY_PUSH_IMAGE ? 'image' : 'icon';
  }

  @action
  public setInfinity(infinity: boolean) {
    this.infinity = infinity;
  }

  @action
  private clearFiles(): void {
    this.files = [];
  }

  @action
  public setImageType(type: ImageType): void {
    this.imageType = type;
  }

  @action
  public handleSearchQueryChange(value: string): void {
    this.searchQuery = value;
  }

  @action
  public clearSelectedFile(): void {
    this.selectedImage = null;
    this.selectedImageBase = '';
  }

  public async listImages(): Promise<void> {
    if (this.files.length > 0) {
      return;
    }

    await this.fetchItems();
  }

  public async fetchItems(): Promise<void> {
    this.isLoading = true;
    const { data, metadata } = await getImagesByTypeUseCase.exec({
      projectID: this.projectId,
      limit: this.perPage,
      offset: this.offset,
      imageType: this.imageType,
      searchQuery: this.searchQuery
    });

    this.total = metadata.total;
    const images = data.map(i => new Image(i));
    this.files = this.infinity ? this.files.concat(images) : images;
    this.isLoading = false;
  }

  @action
  public showAddModal(): void {
    this.addModal = true;
    this.onNewImage();
  }

  @action
  public onNewImage(): void {
    this.selectedImage = Image.createNewImage();
  }

  @action
  public closeAddModal(): void {
    this.addModal = false;
    this.clearSelectedFile();
  }

  @action
  public showDeleteModal(file: Image): void {
    this.setSelectedFile(file);
  }

  @action
  public closeDeleteModal(): void {
    this.clearSelectedFile();
  }

  @action
  public showEditModal(file: Image): void {
    this.setSelectedFile(file);
    this.editModal = true;
  }

  @action
  public closeEditModal(): void {
    this.selectedImage.restore();
    this.clearSelectedFile();
    this.editModal = false;
  }

  @action
  public showPreviewModal(file: Image): void {
    this.setSelectedFile(file);
    this.previewModal = true;
  }

  @action closePreviewModal(): void {
    this.previewModal = false;
    this.clearSelectedFile();
  }

  @action
  public setSelectedFile(file: Image): void {
    this.selectedImage = file;
  }

  public async clearFilesAndRefetch(): Promise<void> {
    this.files = [];
    if (this.infinity) {
      this.offset === 0 ? await this.fetchItems() : this.offset = 0;
    } else {
      await this.fetchItems();
    }
  }

  public async onSave(): Promise<void> {
    await this.clearFilesAndRefetch();
    this.closeAddModal();
  }

  public async onEdit(): Promise<void> {
    if (!this.selectedImage) {
      return;
    }

    await this.updateImageName(this.selectedImage.owner)
      .then(() => this.clearFilesAndRefetch())
      .finally(() => this.closeEditModal());
  }

  private updateImageName = async (imageOwnerID: string): Promise<void> => {
    await updateImageNameUseCase.exec({
      projectID: this.projectId,
      imageName: this.selectedImage.name,
      imageOwnerID,
    })
  }

  public async onDeleteConfirm(): Promise<void> {
    await deleteImageUseCase.exec({
      imageOwnerID: this.selectedImage.owner
    }).then(() => this.clearFilesAndRefetch())
      .finally(() => this.closeDeleteModal());
  }
}
