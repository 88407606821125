import { API, UseCase } from "@ppg/common";
import { IProvider } from '../interfaces';

interface ISetLegacyVapid extends IProvider {
  privateKey: string;
  publicKey: string;
}

export class SetLegacyVapidUseCase extends UseCase<IProvider, void> {
  protected async execute({ projectId, privateKey, publicKey, organizationId }: ISetLegacyVapid) {
    await API.HTTP.post(`core/organizations/${ organizationId }/projects/${ projectId }/providers/legacy`, {
      privateKey, publicKey
    });
  }
}

export const setLegacyVapidUseCase = new SetLegacyVapidUseCase();
