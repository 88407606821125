import { API, UseCase } from "@ppg/common";

export interface IGetOperatorsResponse {
  [key: string]: string
}

export class GetOperatorsUseCase extends UseCase<null, IGetOperatorsResponse> {
  protected async execute() {
    return API.HTTP.get<IGetOperatorsResponse>(`core/projects/${ API.HTTP.currentProjectId }/segment/operators`);
  }
}

export const getOperatorsUseCase = new GetOperatorsUseCase();
