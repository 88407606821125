import { Image, ImageFormatType, ImageType } from "../../modelsMobx/Image";
import { observable } from "mobx";
import { ProjectRelated } from "../ProjectRelatedStore";
import { getProjectImagesUseCase } from "../../useCases/core";
import * as DUMMY_SCREENSHOT from "../../assets/images/dummy-screenshot.png";
import { DUMMY_ICON_CDN } from "../../constants";

export class ProjectImageStore extends ProjectRelated {
  @observable
  public images: Image[] = [];

  public async fetchImages(): Promise<void> {
    this.images = await getProjectImagesUseCase.exec({
      project: this.currentProject.id as string,
    });
  }

  public getImageByType(imageType: ImageType): Image | null {
    const image = this.images.find((image) => {
      return image.type === imageType;
    });

    if (!image) {
      return null;
    }

    return image;
  }

  public getLogoURL(formatType: ImageFormatType): string {
    const image = this.getImageByType(ImageType.LOGO);

    if (!image) {
      return DUMMY_ICON_CDN;
    }

    return image.getURL(formatType);
  }

  public getScreenshotURL(formatType: ImageFormatType): string {
    const image = this.getImageByType(ImageType.SCREENSHOT);

    if (!image) {
      return DUMMY_SCREENSHOT;
    }

    return image.getURL(formatType);
  }

  public setDefaultValues() {
    this.images = [];
  }
}
