import { property, once, isCorrectRedirectLink } from '@ppg/common';
import { action, computed, observable } from 'mobx';
import { getWebsiteIntegrationDetailsUseCase, updateWebPushEnabledUseCase } from '../useCases/website-integration';
import { WebsiteIntegrationRelated } from './project/WebsiteIntegrationRelated';

export interface IWebsiteDetailsStore {
  name: string;
  siteUrl: string;
  integrationCode: string;
  hostName: string;
  qrCodeURL: string;
  enabled: boolean;
  confirmDeleteProjectModal: boolean;
}

export class WebsiteDetailStore extends WebsiteIntegrationRelated implements IWebsiteDetailsStore {

  @property() public name: string;
  @property([ isCorrectRedirectLink()]) public siteUrl: string;
  @property() public integrationCode: string;
  @property() public hostName: string;
  @property() public qrCodeURL: string;
  @property() public enabled: boolean;
  @observable public isLoading: boolean = true;
  @observable public confirmDeleteProjectModal: boolean;

  @action
  public fetchDetails = once(async (): Promise<void> => {
    const { name, siteUrl, hostName, integrationCode, qrCodeURL } = await getWebsiteIntegrationDetailsUseCase.exec({
      websiteIntegrationId: this.websiteIntegrationId
    });

    this.name = name;
    this.siteUrl = siteUrl;
    this.integrationCode = integrationCode;
    this.hostName = hostName;
    this.qrCodeURL = qrCodeURL;

    this.isLoading = false;
  })

  @computed
  get canSave(): boolean {
    return this.getProperty('siteUrl').isValid;
  }

  @computed
  public get embedCode(): string {
    return `<script charset="UTF-8" src="${ this.integrationCode }" async="async"></script>`;
  }

  public get embedManifest(): string {
    return '<link rel="manifest" href="/manifest.json">';
  }

  public get isEnabledLoaded(): boolean {
    return this.enabled !== undefined;
  }

  @action
  public async updateWebPushEnabled(): Promise<void> {
    await updateWebPushEnabledUseCase.exec({
      websiteIntegrationId: this.websiteIntegrationId,
      enabled: this.enabled
    })
  }
}
