import { apiManager, connector, Model } from '../base';
import { property } from '../base/decorators';
import { PushAction, WebUser } from './index';
import { IPushCampaign, IPushCampaignResource } from './IPushCampaign';
import { t } from '../base/helpers';
import * as lodash from 'lodash';
import { toast } from '@ppg/styled';
import * as DummyIcon from '../assets/images/dummy-icon.png';
import { Monitor } from '../components/common';
import { colorEnums } from '../components/Campaign/ReportColors';
import { getCampaignStatisticsSummary, } from "../useCases/core/push/GetCampaignStatisticsSummary";
import { getPushCampaignUseCase } from '../useCases/core/push/GetPushCampaignUseCase';
import { Image, ImageFormatType } from '../modelsMobx/Image';
import { projectImageStore } from '../stores';
import { Label } from "../modelsMobx/Label";
import { getResolveIdsToLabels } from "../useCases/core/label/GetResolveIdsToLabels";
import { action, computed } from "mobx";

export interface IPieStats {
  sent: number;
  delivered: number;
  clicked: number;
  lost: number;
  actionPrimary: number;
  actionSecondary: number;
}

export enum CampaignType {
  READY = 'ready',
  ABX = 'abx',
  DRAFT = 'draft',
  SENDING = 'sending',
  GLOBAL = 'global',
  SENT = 'sent',
  FAILED = 'failed',
  INQUEUE = 'inqueue',
  SUSPENDED = 'suspended',
  TRIGGERED = 'triggered',
  STOPPED = 'stopped'
}

export enum CampaignDirectionType {
  AUTO = 'auto',
  LTR = 'ltr',
  RTL = 'rtl',
}

export enum TagCountStrategyType {
  'AND' = 'and',
  'OR' = 'or'
}

//@ts-ignore
export class PushCampaign extends Model<IPushCampaign, IPushCampaignResource> implements IPushCampaign {

  /**
   * Method is used for fetching data about push campaign with all details currently
   * @param projectId
   * @param pushId
   */
  public static async getPush(projectId: string, pushId: string): Promise<PushCampaign> {
    const {campaignResponse, statisticsResponse} = await getPushCampaignUseCase.exec({projectId: projectId, pushId: pushId});

    // Backbone hack to trigger event change
    const campaign = new PushCampaign();

    // Hack (same fields name in DTO and in model reassign)
    const includedLabels = campaignResponse.labels;
    campaignResponse.labels = [];

    campaign.setFromResponse(campaignResponse as any);
    campaign.setStats(statisticsResponse);
    campaign.parseImages();

    if(!campaignResponse.segment) {
      if (includedLabels.length !== 0 || campaignResponse.excludedLabels.length !== 0) {
        await campaign.parseLabels(includedLabels, campaignResponse.excludedLabels);
      } else {
        /**
         * @deprecated - remove after migration all to labels
         */
        await campaign.resolveLabels(campaignResponse.tags, campaignResponse.excludedTags);
      }
    }

    return campaign;
  }

  private static async getPushWithoutLabels(projectId: string, pushId: string): Promise<PushCampaign> {
    const {campaignResponse, statisticsResponse} = await getPushCampaignUseCase.exec({projectId: projectId, pushId: pushId});
    // Backbone hack to trigger event change
    const campaign = new PushCampaign();

    campaign.setFromResponse(campaignResponse as any);
    campaign.setStats(statisticsResponse);

    return campaign;
  }

  @connector.bindWith('user')
  private user: WebUser;

  @property() public sample: number;
  @property() public owner: string;
  @property() public project: string;
  @property() public category: string;
  @property() public title: string;
  @property() public content: string;
  @property() public redirectLink: string;
  @property() public actionPrimary: number;
  @property() public actionSecondary: number;
  @property() public lost: number;
  @property() public sent: number;
  @property() public clicked: number;
  @property() public delivered: number;
  @property() public cappedDelivered: number;
  @property() public cappedSent: number;
  @property() public state: CampaignType;
  @property() public requireInteraction: boolean;
  @property() public deleted: boolean;
  @property() public finished: boolean;
  @property({ type: 'date' }) public createdAt: Date;
  @property({ type: 'date' }) public expireDate: Date;
  @property({ type: 'date' }) public sendDate: Date;
  @property({ type: 'date' }) public sentDate: Date;
  @property({ type: 'date' }) public updatedAt: Date;
  @property() public delayTime: number;
  @property() public tagsCountStrategy: TagCountStrategyType;
  @property() public excludedTagsCountStrategy: TagCountStrategyType;
  @property() public pieStats: IPieStats;
  @property() public actions: PushAction[];
  @property() public direction: CampaignDirectionType;
  @property() public polygons: [Number, Number][][];
  @property() public sentBy: string;
  @property() public icon: Image;
  @property() public image: Image;
  @property() public omitCapping: boolean;
  @property() public segment: string;

  @computed
  public get capped(): number {
    return this.cappedSent + this.cappedDelivered;
  }

  @computed
  public get hasCapped(): boolean {
    return this.capped !== 0;
  }

  @property() public labels: Label[] = [] as Label[];

  @computed private get includedLabels(): string[] {
    return this.labels.filter(label => label.isIncluded()).map(label => label.serialize());
  }

  @computed private get excludedLabels(): string[] {
    return this.labels.filter(label => label.isExcluded()).map(label => label.serialize());
  }

  /**
   * @deprecated - please use .labels instead in new components
   */
  @computed public get tags(): string[] {
    return this.labels.filter(label => label.isIncluded()).map(label => label.getLegacyId())
  }

  /**
   * @deprecated - please use .labels instead in new components
   */
  @computed public get excludedTags(): string[] {
    return this.labels.filter(label => label.isExcluded()).map(label => label.getLegacyId())
  }

  @action
  public async resolveLabels(tags: string[], excludedTags: string[]) {
    const included = await getResolveIdsToLabels.exec({ ids: tags });

    for(const label of included) {
      await label.resolveLegacyId()
      label.toggleIncluded()
      this.labels.push(label)
    }

    const excluded = await getResolveIdsToLabels.exec({ ids: excludedTags });

    for(const label of excluded) {
      await label.resolveLegacyId()
      label.toggleExcluded()
      this.labels.push(label)
    }
  }

  @action
  public async parseLabels(labels: string[], excludedLabels: string[]) {
    const inclLabels = [].concat(labels
      .map(label => {
        const entity = Label.deserialize(label);
        entity.toggleIncluded()
        return entity;
      }));

    const exclLabels = [].concat(excludedLabels
      .map(label => {
        const entity = Label.deserialize(label);
        entity.toggleExcluded();
        return entity;
      }));

    this.labels.push(...inclLabels, ...exclLabels);

    /**
     * Code part is deprecared - will be removed
     */
    for (let label of this.labels) {
      await label.resolveLegacyId();
    }
  }

  private _text: string;
  private reportInterval: Monitor;

  public static resourceUrl(): string {
    const user = connector.get<WebUser>('user');

    if (user.currentProject === null) {
      return;
    }

    return `project/${ user.currentProject }/push`;
  }

  public async fetchSummaryStatsData() {
    if (!this.id || !this.project) {
      return;
    }

    const summaryData = await getCampaignStatisticsSummary.exec({
      projectID: this.project,
      campaignID: this.id as string
    });

    this.setStats(summaryData);
  }

  public parse(resource: any) {

    resource.clicked = 0;
    resource.delivered = 0;
    resource.sent = 0;
    resource.actionPrimary = 0;
    resource.actionSecondary = 0;
    resource.lost = 0;

    return resource;
  }

  public toCopy(): any {
    return {
      copiedProjectId: this.user.selectedProject.id,
      modelName: "pushCampaign",
      direction: this.direction,
      insideProject: {
        sample: this.sample,
        title: this.title,
        content: this.content,
        polygons: this.polygons,
        redirectLink: this.redirectLink,
        requireInteraction: this.requireInteraction,
        delayTime: this.delayTime,
        tagsCountStrategy: this.tagsCountStrategy,
        excludedTagsCountStrategy: this.excludedTagsCountStrategy,
        actions: this.actions,
        image: this.image,
        icon: this.icon,
        labels: this.labels
      },
      outsideProject: {
        title: this.title,
        content: this.content,
        redirectLink: this.redirectLink,
        actions: this.actions,
        requireInteraction: this.requireInteraction,
      }
    };
  }

  static async fetchPushesById(ids: string[], project: string): Promise<PushCampaign[]> {
    return Promise.all(ids.map(id => PushCampaign.getPush(project, id)));
  }

  public find(args: any) {
    throw new Error('Deprecated func!');
  }

  get allClicked() {
    return lodash.sum([this.actionSecondary, this.actionPrimary, this.clicked]);
  }

  get modelName() {
    return 'pushCampaign';
  }

  get getIcon(): string {
    return this.iconURL || DummyIcon;
  }

  get iconURL(): string {
    return this.icon?.url;
  }

  get getImage(): string {
    return this.image?.url;
  }

  public getDefaultIcon(): string {
    const logo = projectImageStore.getLogoURL(ImageFormatType.MEDIUM);

    return logo || DummyIcon;
  }

  get sentCalculated() {
    return this.sent || 0;
  }

  get waiting() {
    return this.sentCalculated - this.delivered;
  }

  get ltr() {
    return this.lost / this.sent;
  }

  get ctr() {
    return this.clicked / this.sent;
  }

  get dtr() {
    return this.delivered / this.sent;
  }

  public defaults() {
    return {
      tagsCountStrategy: TagCountStrategyType.OR,
      excludedTagsCountStrategy: TagCountStrategyType.OR,
      pieStats: {
        sent: 0,
        delivered: 0,
        clicked: 0,
        lost: 0,
        actionPrimary: 0,
        actionSecondary: 0,
        capped: 0,
      },
      redirectLink: this.user.selectedProject.siteUrl,
      content: '',
      title: '',
      tags: [],
      excludedTags: [],
      labels: [],
      excludedLabels: [],
      segment: null,
      direction: CampaignDirectionType.AUTO,
      icon: null,
      image: null
    };
  }

  public setNewIcon(icon: Image): void {
    this.icon = icon;
  }

  public setNewImage(image: Image): void {
    this.image = image;
  }

  public fetchReportData() {
    return this.fetchSummaryStatsData();
  }

  private setStats(pie: IPieStats) {
    this.set(pie);
    this.pieStats = pie;
  }

  public getText(): string {
    if (!this._text) {
      this._text = lodash
        .chain([this.title, this.content, this.redirectLink])
        .map((text: string) => lodash.toString(text).toLowerCase())
        .union()
        .thru((text: string[]) => text.join(' '))
        .value();
    }

    return this._text;
  }

  public sendTest(tags: string[]) {
    return apiManager
      .post(`/project/${ this.project }/push/${ this.id }/test`, { tags: tags })
      .then(() => toast.success(t('Test campaign was sent')));
  }

  public hasExpireDate(): boolean {
    return this.expireDate !== null;
  }

  public removeExpireDate() {
    this.set({
      expireDate: null,
    });
  }

  public disableFetch = (): void => {
    if (this.reportInterval) {
      this.reportInterval.clear();
    }
  };

  public enableFetch = (): void => {
    this.reportInterval = new Monitor(async () => {
      if (!this.canShowReports()) {
        await PushCampaign.getPushWithoutLabels(this.project, this.id as string).then(() => this);
      } else {
        await this.fetchReportData();
      }
    }, 5000, 1000);
  };

  public canShowReports = (): boolean => {
    const statusSet = new Set(['sent', 'triggered', 'global', 'sending']);
    return statusSet.has(this.state);
  };

  public getPieData = () => {
    const { delivered } = this.pieStats;

    const data = [
      { name: t('Received'), value: delivered, color: colorEnums.DELIVERED },
      { name: t('Waiting'), value: this.waiting, color: colorEnums.WAITING },
    ];

    return data.filter(d => d.value > 0);
  };

  public parseImages() {
    if (this.icon !== null) this.icon = new Image(this.icon);
    if (this.image !== null) this.image = new Image(this.image);
  }

  public static readonly READY: CampaignType = CampaignType.READY;
  public static readonly DRAFT: CampaignType = CampaignType.DRAFT;
  public static readonly SENDING: CampaignType = CampaignType.SENDING;
  public static readonly GLOBAL: CampaignType = CampaignType.GLOBAL;
  public static readonly SENT: CampaignType = CampaignType.SENT;
  public static readonly FAILED: CampaignType = CampaignType.FAILED;
  public static readonly INQUEUE: CampaignType = CampaignType.INQUEUE;
  public static readonly SUSPENDED: CampaignType = CampaignType.SUSPENDED;
  public static readonly TRIGGERED: CampaignType = CampaignType.TRIGGERED;
}
