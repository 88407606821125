import { property } from '@ppg/common';
import { toast } from '@ppg/styled';
import { computed, observable, reaction } from 'mobx';
import { Monitor } from '../components/common';
import { ITEMS_PER_PAGE, MONTH } from '../constants';
import { ExportCollectionType, ExportFile } from '../modelsMobx/ExportFile';
import { createExportUseCase } from '../useCases/exports/CreateExportUseCase';
import { deleteExportUseCase } from '../useCases/exports/DeleteExportUseCase';
import { downloadExportUseCase } from '../useCases/exports/DownloadExportUseCase';
import { ExportResponse, getExportUseCase } from '../useCases/exports/GetExportUseCase';
import { listExportsUseCase } from '../useCases/exports/ListExportsUseCase';
import { ProjectRelated } from './ProjectRelatedStore';
import dayjs from 'dayjs';
import { createCampaignActivityExportUseCase } from '../useCases/statistics/project';
import { t } from '../base/helpers';

export class ExportStore extends ProjectRelated {

  @observable
  public exportByCreatedAt: boolean;

  fetchOnPaginationChange = reaction(
    () => this.doFetch,
    () => this.fetchItems(),
  );

  @property() public exports: ExportFile[] = [];
  @property() public isLoading: boolean = true;
  @property() public collectionName: ExportCollectionType;
  @property() public from: Date = dayjs().subtract(MONTH, 'month').startOf('day').toDate();
  @property() public to: Date = dayjs().endOf('day').toDate();
  @property() public offset: number = 0;
  @property() public perPage: number = ITEMS_PER_PAGE;
  @property() private infinity: boolean;

  @observable public total: number = 0;
  @observable public monitors: Monitor[] = [];

  setDefaultValues() {
    this.exports = [];
    this.isLoading = true;
    this.from = dayjs().subtract(MONTH, 'month').startOf('day').toDate();
    this.to = dayjs().endOf('day').toDate();
    this.offset = 0;
    this.perPage = ITEMS_PER_PAGE;
    this.infinity = false;
    this.total = 0;
    this.clearAllMonitors();
  }

  prepareToDateString(): string {
    return dayjs().diff(this.to) > 0 ?
      dayjs(this.to).endOf('day').toISOString() :
      dayjs().toISOString();
  }

  @computed
  get doFetch() {
    return {
      offset: this.offset,
      perPage: this.perPage,
    };
  }

  public setInfinity(infinity: boolean) {
    this.infinity = infinity;
  }

  public getInfinity() {
    return this.infinity;
  }

  async fetchItems() {
    listExportsUseCase.exec({
        projectID: this.projectId,
        offset: this.offset,
        limit: this.perPage,
      })
      .then(({ data, metadata }) => {
        const exports = data.map(file => new ExportFile(file));
        this.total = metadata.total;
        this.exports = this.infinity ? this.exports.concat(exports) : exports;
        this.setMonitors();
        this.isLoading = false;
      })
      .catch((err) => {
        return err;
      });
  }

  public async removeExport(file: ExportFile) {
    if (file) {
      this.isLoading = true;
      await deleteExportUseCase.exec({ projectID: this.projectId, exportID: file.id })
        .then(() => toast.success(t('Your export has been deleted successfully')))
        .then(() => {
          this.exports = [];
          if (this.infinity) {
            this.offset === 0 ? this.fetchItems() : this.offset = 0;
          } else {
            this.fetchItems();
          }
        });
    }
  }

  public async downloadExport(file: ExportFile) {
    const { downloadUrl } = await downloadExportUseCase.exec({
      projectID: this.projectId,
      exportID: file.id
    });

    const temporaryElement = document.createElement('a');
    temporaryElement.href = downloadUrl;
    temporaryElement.download = downloadUrl.substring(downloadUrl.lastIndexOf('/') + 1, downloadUrl.lastIndexOf('?'));
    temporaryElement.click();
  }

  public async onExportStart() {
    let data;

    if (this.collectionName === ExportCollectionType.CAMPAIGN_DAYS) {
      data = await createCampaignActivityExportUseCase.exec({
        project: this.projectId.toString(),
        from: dayjs(this.from).startOf('day').toISOString(),
        to: this.prepareToDateString()
      });
    } else {
      data = await createExportUseCase.exec({
        projectID: this.projectId,
        from: dayjs(this.from).startOf('day').toISOString(),
        to: this.prepareToDateString(),
        type: this.collectionName,
        exportBy: this.getExportBy(),
      });
    }

    const model = new ExportFile(data);
    this.exports = [model].concat(this.exports);
    this.total++;
    this.setMonitor(model);
  }

  private getExportBy = () => {
    return this.exportByCreatedAt ? 'createdAt' : 'sendDate';
  };

  public getTTLRange(maxDaysSetting) {
    const maxDays = maxDaysSetting || 90;
    return {
      from: dayjs().subtract(maxDays, 'days').toDate(),
      to: dayjs().toDate()
    };
  }

  private clearAllMonitors() {
    if (!this.monitors) {
      return this.monitors = [];
    }

    this.monitors.forEach((monitor: Monitor) => monitor.clear());
    this.monitors = [];
  }

  private setMonitors() {
    if (!this.exports) {
      return;
    }

    this.clearAllMonitors();
    this.exports.forEach(exportFile => this.setMonitor(exportFile));
  }

  private setMonitor(exportFile: ExportFile) {
    if (exportFile.needsMonitor) {

      const monitor = new Monitor(() => getExportUseCase.exec({
        projectID: this.projectId,
        exportID: exportFile.id.toString()
      }).then(({ state }: ExportResponse) => {
        exportFile.setState(state);
        if (!exportFile.needsMonitor) {
          toast.success('Export finished');
          monitor.clear();
        }
      }), 2500);

      this.monitors.push(monitor);
    }
  }

}
