import { API, UseCase } from '@ppg/common';
import { IGetActiveSubscribersHistogramResult, IOrganizationDashboardRequestBody } from '../../../interfaces';
import { getListQueryParam, getPlatformTypeParam, getRangeDateParam, getTimezoneParam } from '../../../helpers';
import { IListQueryOptions } from '../../../../interfaces/IListQueryOptions';

interface GetActiveSubscribersHistogramRequest extends IOrganizationDashboardRequestBody, IListQueryOptions {
}

export class GetActiveSubscribersHistogramUseCase extends UseCase<GetActiveSubscribersHistogramRequest, IGetActiveSubscribersHistogramResult> {
  protected async execute({
                            organization,
                            platform,
                            from,
                            to,
                            limit,
                            offset,
                            timezone
                          }: GetActiveSubscribersHistogramRequest) {

    const endpoint = `statistics/organizations/${ organization }/subscribers/active/histogram`
      + getPlatformTypeParam(platform) + getRangeDateParam(from, to) + getTimezoneParam(timezone) + getListQueryParam(offset, limit);

    return API.HTTP.get<IGetActiveSubscribersHistogramResult>(endpoint);
  }
}

