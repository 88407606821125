import { API, UseCase } from '@ppg/common';
import { getListQueryParam, getPlatformTypeParam, getRangeDateParam } from '../../../helpers';
import { IOrganizationDashboardRequestBody } from '../../../interfaces';

export interface IGetCampaignsComparatorHistogramRequest extends IOrganizationDashboardRequestBody {
  type: string;
  timezone: string;
}

export interface IGetCampaignsComparatorTableRequest extends IOrganizationDashboardRequestBody {
  statsType: StatsType;
  offset: number;
  limit: number;
  type: string;
}

export type StatsType = 'clicked' | 'ctr' | 'delivered' | 'campaignCount';

export interface ICampaignComparatorHistogramItem {
  project: string;
  campaignDays: [
    {
      day: string;
      clicked: number;
      delivered: number;
      ctr: number;
      campaignCount: number;
    }
  ]
}

export interface IGetCampaignsComparatorHistogramResult {
  campaignComparatorHistogram: ICampaignComparatorHistogramItem[];
}

export class GetCampaignsComparatorHistogramUseCase extends UseCase<IGetCampaignsComparatorHistogramRequest, IGetCampaignsComparatorHistogramResult> {
  protected async execute({
                            organization,
                            platform,
                            from,
                            to,
                            timezone,
                            type
                          }: IGetCampaignsComparatorHistogramRequest) {
    return API.HTTP.get<IGetCampaignsComparatorHistogramResult>(`statistics/organizations/${ organization }/campaigns/comparator/histogram/${ type }${ getPlatformTypeParam(platform) }${ getRangeDateParam(from, to) }&timezone=${ timezone }`);
  }
}

export interface CampaignComparatorStatistics {
  campaignStatistics: {
    total: number;
    histogram: IComparatorStatisticsDay[];
  }
}

export interface IComparatorStatisticsDay {
  day: string;
  data: ComparatorStatisticsDay[];
}

interface ComparatorStatisticsDay {
  project: string;
  value: number;
}

export class GetCampaignsComparatorTableUseCase extends UseCase<IGetCampaignsComparatorTableRequest, CampaignComparatorStatistics> {
  protected async execute({
                            organization,
                            platform,
                            from,
                            to,
                            timezone,
                            statsType,
                            offset,
                            limit,
                            type,
                          }: IGetCampaignsComparatorTableRequest) {
    return API.HTTP.get<CampaignComparatorStatistics>(
      `statistics/organizations/${ organization }/campaigns/comparator/histogram/${ type }/${ statsType }${ getPlatformTypeParam(platform) }${ getRangeDateParam(from, to) }&timezone=${ timezone }${ getListQueryParam(offset, limit) }`
    );
  }
}
