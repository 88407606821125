import { API, UseCase } from '@ppg/common';

export interface IPartner {
  organization: string;
  invoices: string[];
  usedCode: string;
  haveAllPossiblePayments: boolean;
  haveAllPossiblePaymentsWithdraw: boolean;
  createdAt: string;
  projectUrl: string;
}

export interface IGetPartnershipPartnersRequest {
  limit: number;
  offset: number;
  partnershipOrganization: string
}

export interface IGetPartnershipPartnersResult {
  metadata: {
    total: number
  }
  data: IPartner[]
}

export class GetPartnershipPartners extends UseCase<IGetPartnershipPartnersRequest, IGetPartnershipPartnersResult> {
  protected async execute({ partnershipOrganization }: IGetPartnershipPartnersRequest) {
    return API.HTTP.get<IGetPartnershipPartnersResult>(
      `acc/organizations/${ partnershipOrganization }/partnerships/partners`,
    )
  }
}

export const getPartnershipPartners = new GetPartnershipPartners();
