import { API, UseCase } from "@ppg/common";
import { ICampaignId } from './interfaces';
import { CampaignType } from '../../../modelsMobx/campaigns/PushCampaign';

interface IGetCampaignTypeRequest extends ICampaignId {}
interface IGetCampaignTypeResponse {
  type: CampaignType;
}

export class GetCampaignTypeUseCase extends UseCase<IGetCampaignTypeRequest, IGetCampaignTypeResponse> {
  protected async execute({ campaignId }: IGetCampaignTypeRequest) {
    return API.HTTP.get<IGetCampaignTypeResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/${ campaignId }/type`);
  }
}

export const getCampaignTypeUseCase = new GetCampaignTypeUseCase();
