import { API, UseCase } from "@ppg/common";
import { ABTestState } from "../../../modelsMobx/ABTestState";
import { ActionsUseCases } from '../../../modelsMobx';
import { SenderStrategyType } from '../../../stores/PushSettingStore';
import { LabelCountStrategy } from '../../../modelsMobx/Label';

interface ICreateABTest {
  name: string;
  sample: number;
  variants: IVariantDTO[];
  polygons: [Number, Number][][];
  senderStrategy: SenderStrategyType;
  tags: string[];
  excludedTags: string[];
  excludedTagStrategy: LabelCountStrategy;
  tagStrategy: LabelCountStrategy;
  segment?: string;
  labels?: string[];
  labelsStrategy?: string;
  excludedLabels?: string[];
  excludedLabelsStrategy?: string;
}

export interface IVariantDTO {
  name: string;
  title: string;
  content: string;
  redirectLink: string;
  iconOwnerID: string;
  imageOwnerID: string;
  requireInteraction: boolean;
  actions: {
    title: string;
    link: string;
  }[];
  direction: string;
}

export interface ICreateABResponse {
  id: string;
  name: string;
  state: ABTestState;
  variants: IVariantDTO[];
  testEndDate: string;
  testStartDate: string;
  availableActions: ActionsUseCases[];
  sample: number;
  winner: IVariantDTO;
  winnerExpireDate: string;
  winnerSendDate: string;
  errorTolerancePercent: number;
  queryCriteria: {
    excludedTags: string[];
    excludedTagsCountStrategy: LabelCountStrategy;
    tags: string[];
    tagsCountStrategy: LabelCountStrategy;
  };
}

export class CreateABTestUseCase extends UseCase<ICreateABTest, ICreateABResponse> {
  protected async execute({ name, sample, variants, excludedTags, polygons, senderStrategy, tags, excludedTagStrategy, tagStrategy, segment, labels, excludedLabels, labelsStrategy, excludedLabelsStrategy }: ICreateABTest) {
    return API.HTTP.post<ICreateABResponse>(`core/projects/${ API.HTTP.currentProjectId }/ab-tests`,
      { name, sample, variants, excludedTags, polygons, senderStrategy, tags, excludedTagStrategy, tagStrategy, segment, labels, excludedLabels, labelsStrategy, excludedLabelsStrategy });
  }
}

export const createABTestUseCase = new CreateABTestUseCase();
