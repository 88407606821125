import { API, UseCase } from '@ppg/common';

interface GetOwnedProjectsByUserData {
  userID: string;
}

interface GetOwnedProjectsResponse {
  projects: string[];
}

export class GetOwnedProjectIdsUseCase extends UseCase<GetOwnedProjectsByUserData, GetOwnedProjectsResponse> {
  protected async execute({ userID }) {
    return API.HTTP.get<GetOwnedProjectsResponse>(`aai/user/${ userID }/owned-projects`);
  }
}

export const getOwnedProjectIdsUseCase = new GetOwnedProjectIdsUseCase();
