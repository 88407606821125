import styled from "@emotion/styled";
import { MainTheme } from '../../../../../assets/themes/MainTheme';

export const InfoWrapper = styled.div`
  background: ${ MainTheme.colors.functional.white };
  border-radius: ${ MainTheme.border.radius };
  padding: 1.6rem;
  margin-bottom: 2rem;
`;

export const InfoIcon = styled.span`
  margin-right: .5rem;
  color: ${ MainTheme.colors.tertiary.blue600 };

  &::before {
    background-color: ${ MainTheme.colors.tertiary.blue100 };
    border-radius: 50%;
  }
`;
