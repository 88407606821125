import { API, UseCase } from "@ppg/common";
import { IUtm } from '../../../modelsMobx/helpers/Utm';

interface UpdateUtmRequest extends IUtm {
  projectId: string;
}

export class UpdateUtmUseCase extends UseCase<UpdateUtmRequest, void> {
  protected async execute({ projectId, medium, source, campaign, disabled, isMediumOn, isSourceOn, isCampaignOn, customUtms }: UpdateUtmRequest) {
    return API.HTTP.put<void>(`core/projects/${ projectId }/utm`, {
      medium, source, campaign, disabled, isMediumOn, isSourceOn, isCampaignOn, customUtms
    });
  }
}

export const updateUtmUseCase = new UpdateUtmUseCase();
