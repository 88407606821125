import { LineChartData } from '../../../components/Charts/LineChart/LineChart';
import { CustomBarDatum, IAutomationRankOptionsType } from '../../../pages/Dashboard/common/interfaces';
import { TimeUnit } from '@ppg/common';
import { FunnelDatum } from '@nivo/funnel/dist/types/types';
import { SubscriberLocalizationBuckets } from '../../organizationDashboard/SubscriberLocalizationBuckets';
import { IAutomationRankItem } from '../../../pages/Dashboard/common';
import {
  IActiveSubscribersHistogramItem,
  ICampaignClickedDeliveredHourStats,
  ICampaignSentHourStats,
  IDashboardCampaignsStatsWithCount,
  IGetSubscribersStructureResult,
} from '../../../useCases/interfaces';
import { ActiveViewType } from '../../../pages/User/Navigations/UnderlinedNavigationBar/UnderlinedNavigationBar';
import { ProjectDashboardSubscriberStore } from './ProjectDashboardSubscriberStore';
import { ProjectDashboardCampaignStore } from './ProjectDashboardCampaignStore';
import { ProjectDashboardAutomationStore } from './ProjectDashboardAutomationStore';
import {
  IAutomationStatisticsHistogramItem, IAutomationStats,
  IProjectGetSubscribersActivityHistogramRequest,
  IStatisticsHistogramItem
} from '../../../useCases/statistics/project';

export interface IHistogramData {
  x: string,
  y: number
}

export interface IBarDatumKeysColors {
  keys: string[],
  colors: string[]
}

export interface IProjectDashboardCampaignStore {
  isSummaryCampaignsEnabled: boolean;
  isTargetingCampaignsEnabled: boolean;
  isGeolocationCampaignsEnabled: boolean;
  isMassCampaignsEnabled: boolean;
  isLoading: boolean;
  initialLoader: boolean;
  isClickedDeliveredLoading: boolean;
  isClickedDeliveredInitialLoading: boolean;
  targetingCampaigns: IDashboardCampaignsStatsWithCount;
  summaryCampaigns: IDashboardCampaignsStatsWithCount;
  geolocationCampaigns: IDashboardCampaignsStatsWithCount;
  massCampaigns: IDashboardCampaignsStatsWithCount;
  sentCampaignsChartData: LineChartData;
  statisticsHistogramsTableData: IStatisticsHistogramItem[];
  total: number;
  limit: number;
  offset: number;
  sentChartData: LineChartData;
  clickedChartData: LineChartData;
  deliveredChartData: LineChartData;
  ctrChartData: LineChartData;
  averageCtrChartData: LineChartData;
  dspChartData: LineChartData;
  averageDspChartData: LineChartData;
  clickedHoursChartData: CustomBarDatum[];
  deliveredHoursChartData: CustomBarDatum[];
  clickedDeliveredHoursTableData: ICampaignClickedDeliveredHourStats[];
  shippingHoursTableData: ICampaignSentHourStats[];
  shippingHoursChartData: CustomBarDatum[];
}

export interface IProjectDashboardSubscriberStore {
  activeSubscribers: number;
  iosActiveSubscribers?: number;
  androidActiveSubscribers?: number;
  huaweiActiveSubscribers?: number;
  subscribed: number;
  unsubscribed: number;
  subscribersAverageLifetime: number;
  subscribersAverageLifetimeUnit: TimeUnit;

  isSubscribersAverageLifetimeLoading: boolean;
  isActiveSubscribersLoading: boolean;
  isSubscribedActivityLoading: boolean;

  generalChartData: LineChartData;
  subscribersCountChartData: LineChartData;
  subscriberLifecycleChartData: FunnelDatum[];
  subscriberLocalizationBuckets: SubscriberLocalizationBuckets;

  limit: number;
  offset: number;
  total: number;
  activeSubscribersOffset: number;
  activeSubscribersTotal: number;
  subscriberActivityOffset: number;
  subscriberActivityTotal: number;
  generalSubscriberActivityTableData: IProjectGetSubscribersActivityHistogramRequest[];
  generalActiveSubscribersTableData: IActiveSubscribersHistogramItem[];
  subscriberLifecycleTableData: FunnelDatum[];
  subscribersStructureChartData: IGetSubscribersStructureResult;
}

export interface IProjectDashboardAutomationStore {
  automationStats: IAutomationStats;
  activeAutomations: number;
  isStatsLoading: boolean;
  automationRankSelect: IAutomationRankOptionsType;
  automationRank: IAutomationRankItem[];
  initialLoader: boolean;
  isActiveAutomationsLoading: boolean;
  generalAutomationChartData: LineChartData;
  automationTableData: IAutomationStatisticsHistogramItem[];
  total: number;
  limit: number;
  offset: number;
  automationCtrChartData: LineChartData;
}

export interface IProjectDashboardStore {
  dateRangeFrom: Date;
  dateRangeTo: Date;
  mobilePlatformEnabled: boolean;
  webPlatformEnabled: boolean;
  activeTab: string;
  activeViewType: ActiveViewType;
  timezone: string;

  subscriber: ProjectDashboardSubscriberStore;
  campaign: ProjectDashboardCampaignStore;
  automation: ProjectDashboardAutomationStore;
}

export interface IDefaultRange {
  from: Date;
  to: Date;
}

export interface IAutomationHistogram {
  id: string,
  data: Array<{ x: string, y: any }>
}

export interface ISubscriberCount {
  max: number,
  min: number
}

export enum DisplayType {
  TABLE = 'table',
  CHART = 'chart',
}
