import * as React from "react";
import { canCompileTest, t } from '../../base/helpers';
import { Render } from './Render';
import styled from "@emotion/styled";
import { MainTheme } from '../../assets/themes/MainTheme';

export const PreviewCompiled = ({ text, scope }) => {
  if (canCompileTest(text)) {
    return <PreviewStyled><span>{ t('Preview compiled: ') }</span> { Render.renderString(text, scope, true) }</PreviewStyled>
  }

  return null;
}

const PreviewStyled = styled.span`
  overflow-wrap: anywhere;
  margin-bottom: 15px;
  margin-top: 5px;
  > span {
    font-weight: ${ MainTheme.font.weight.bold };
  }
`;