import { API, UseCase } from "@ppg/common";
import { ICampaignId, ICampaignIdResponse } from '../interfaces';

interface IRetryLabelPushRequest extends ICampaignId {}
interface IRetryLabelPushResponse extends ICampaignIdResponse {}

export class RetryLabelPushUseCase extends UseCase<IRetryLabelPushRequest, IRetryLabelPushResponse> {
  protected async execute({ campaignId }: IRetryLabelPushRequest) {
    return API.HTTP.post<IRetryLabelPushResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/labels/${ campaignId }/retry`);
  }
}

export const retryLabelPushUseCase = new RetryLabelPushUseCase();
