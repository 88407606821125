import { API, UseCase } from "@ppg/common";
import { ICampaignId, IGetLabelsPushCampaign } from '../interfaces';

interface IGetLabelPushRequest extends ICampaignId {}
interface IGetLabelPushResponse extends IGetLabelsPushCampaign {}

export class GetLabelPushUseCase extends UseCase<IGetLabelPushRequest, IGetLabelPushResponse> {
  protected async execute({ campaignId }: IGetLabelPushRequest) {
    return API.HTTP.get<IGetLabelPushResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/labels/${ campaignId }`);
  }
}

export const getLabelPushUseCase = new GetLabelPushUseCase();
