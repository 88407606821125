import { API, UseCase } from '@ppg/common';
import { getPlatformTypeParam, getRangeDateParam, getTimezoneParam } from '../../../helpers';
import { IGetSubscribersLifetimeStructureResult, IProjectDashboardRequestBody } from '../../../interfaces';

export class GetProjectSubscribersLifetimeStructureUseCase extends UseCase<IProjectDashboardRequestBody, IGetSubscribersLifetimeStructureResult> {
  protected async execute({ platform, from, to, timezone }: IProjectDashboardRequestBody) {
    const endpoint = `statistics/v2/projects/${ API.HTTP.currentProjectId }/subscribers/dashboard/inactive/lifetime/structure`
      + getPlatformTypeParam(platform) + getRangeDateParam(from, to) + getTimezoneParam(timezone);

    return API.HTTP.get<IGetSubscribersLifetimeStructureResult>(endpoint);
  }
}

export const getProjectSubscribersLifetimeStructureUseCase = new GetProjectSubscribersLifetimeStructureUseCase();
