import { API, UseCase } from '@ppg/common';

interface IUpdatePushSettingCommand {
  project: string;
  pushActions: IPushActionDTO[];
  expireTime: number;
  isExpireTimeEnabled: boolean;
  senderStrategy: string;
  requireInteraction: boolean;
  isDirectionRtl: boolean;
  maxTTL: number;
  defaultTitle: string;
  isDefaultTitleEnabled: boolean;
  schemaProtocols: string[];
}

interface IPushActionDTO {
  title: string;
  link: string;
  useRedirectLink: boolean;
}

export class UpdatePushSettingUseCase extends UseCase<IUpdatePushSettingCommand, void> {
  protected async execute({ schemaProtocols, project, pushActions, expireTime, isExpireTimeEnabled, senderStrategy, requireInteraction, isDirectionRtl, maxTTL, defaultTitle, isDefaultTitleEnabled }: IUpdatePushSettingCommand) {
    return API.HTTP.post<void>(`core/projects/${ project }/settings`, {
      pushActions,
      expireTime,
      isExpireTimeEnabled,
      senderStrategy,
      requireInteraction,
      isDirectionRtl,
      defaultTitle,
      isDefaultTitleEnabled,
      maxTTL,
      schemaProtocols,
    });
  }
}

export const updatePushSettingUseCase = new UpdatePushSettingUseCase();
