import { getOptionsFromEnum, property } from '@ppg/common';
import { action, computed, observable } from 'mobx';
import { IOfferCappingPlan, IOfferPlan, ISettlementPlans, Offer } from '../modelsMobx/Offer';
import { getCurrentAndNextSettlementPolicyPlan, ISettlementPolicyPlan } from '../useCases/accounting/GetCurrentAndNextSettlementPolicyPlanUseCase';
import { changeNextOfferPlan } from '../useCases/accounting/ChangeNextOfferPlanUseCase';
import { getNextOfferPlan } from '../useCases/accounting/GetNextOfferPlanUseCase';
import { UserRelatedStore } from './user/UserRelatedStore';
import { getOfferByName } from '../useCases/accounting/getOfferByNameUseCase';
import { CurrencyType } from '../modelsMobx/CurrencyType';

export class OfferStore extends UserRelatedStore {
  @observable public currentSettlementPolicyPlan: ISettlementPolicyPlan;
  @observable public nextSettlementPolicyPlan: ISettlementPolicyPlan;
  @observable public nextOfferPlan: ISettlementPolicyPlan;
  @observable public activeRegisterPlan: IOfferPlan;
  @observable public offer: Offer = new Offer();
  @observable public changeNextPlanMode: boolean;
  @observable public nextPlan: string = null;
  @property() public referralCode: string = '';
  @property() public hasReferralCode: boolean;
  @property() public currencySelect: CurrencyType = CurrencyType.PLN;
  @observable public isLoading: boolean = true;

  @action
  public fetchOffer = async (): Promise<void> => {
    this.offer = await getOfferByName.exec({
      name: this.currencyOfferName
    })
  }

  @computed
  public get currencyOfferName() {
    switch (this.currencySelect){
      case CurrencyType.PLN: {
        return Offers.LITE_OFFER
      }
      case CurrencyType.EUR: {
        return Offers.LITE_OFFER_EUR
      }
      case CurrencyType.USD: {
        return Offers.LITE_OFFER_USD
      }
      default: return Offers.LITE_OFFER
    }
  }
  @action
  public setInitialOfferPlan = (plan:string):void => {
    if(plan){
      this.isPlanParamValid(plan) ? this.setRegisterPlanByName(plan) : this.setDefaultRegisterPlan()
    } else {
      this.setDefaultRegisterPlan()
    }
  }

  public isPlanParamValid = (paramPlan:string):boolean => {
    return !!this.offer.plans.find(plan => {
      return plan.name.toLowerCase() === paramPlan.toLowerCase();
    });
  }

  @action
  public setDefaultRegisterPlan = ():void => {
    this.activeRegisterPlan = this.offer.plans[0];
  };

  @action
  public setRegisterPlanById = (selectedPlanId: string):void => {
    this.activeRegisterPlan = this.offer.plans.find(plan => {
      return plan.id === selectedPlanId
    });
  }

  @action
  public setRegisterPlanByName = (planName: string):void => {
    this.activeRegisterPlan = this.offer.plans.find(plan => {
      return plan.name.toLowerCase() === planName.toLowerCase()
    });
  }

  @action
  public fetchCurrentAndNextPlans = async ():Promise<void | null> => {
    const SettlementPolicyPlans = await getCurrentAndNextSettlementPolicyPlan.exec({
      organization: this.userStore.user.organization
    })

    if(SettlementPolicyPlans.length <= 0){
      return null;
    }

    const { currentPlan, nextPlan } = SettlementPolicyPlans[0];
    this.currentSettlementPolicyPlan = currentPlan;
    this.nextSettlementPolicyPlan = nextPlan;
    this.isLoading = false;
  }

  @action
  public fetchNextOfferPlan = async (): Promise<void> => {
    if (!this.userStore.isLite) {
      return;
    }

    const { next } = await getNextOfferPlan.exec({
      organization: this.userStore.user.organization
    })

    this.nextOfferPlan = next;
  }

  @computed
  public get planCurrencyOptions(){
    return getOptionsFromEnum(CurrencyType).slice(0,3)
  }

  @action
  public setNextPlan = (plan: IOfferPlan):void => {
    this.nextPlan = plan.id
  };

  @action
  public clearNextPlan = ():void => {
    this.nextPlan = null
  }

  public changeNextPlan = async ():Promise<void> => {
    await changeNextOfferPlan.exec({
      organization: this.userStore.user.organization,
      offerPlan: this.nextPlan,
      password: this.userStore.password.password
    })
    this.clearNextPlan();
  }

  public isOfferCappingPlan(plan: IOfferCappingPlan | ISettlementPlans): plan is IOfferCappingPlan {
    return (plan as IOfferCappingPlan).type !== undefined;
  }

  @action
  public enableChangeNextPlanMode = ():void => {
    this.changeNextPlanMode = true;
  }

  @action
  public disableChangeNextPlanMode = ():void => {
    this.changeNextPlanMode = false;
  }

  @computed
  public get trailDaysLeft():number {
    return Math.round(( new Date(this.nextSettlementPolicyPlan.startFrom).getTime() - new Date().getTime()) / (1000 * 3600 * 24))
  }

  public isStartPlanActive = () => {
    return this.currentSettlementPolicyPlan.name.toUpperCase() === PlanType.START_PLAN ||
           this.currentSettlementPolicyPlan.name.toUpperCase() === PlanType.START_PLAN_LITE
  }

  public isCurrentPlanStandard(): boolean {
    return this.currentSettlementPolicyPlan.name.toUpperCase() === PlanType.STANDARD || (this.isStartPlanActive() && this.nextSettlementPolicyPlan.name.toUpperCase() === PlanType.STANDARD);
  }
}

export const enum PlanType {
  STANDARD = 'STANDARD',
  PREMIUM = "PREMIUM",
  START_PLAN = "START PLAN",
  START_PLAN_LITE = "START PLAN LITE",
  START_TRIAL = "START TRIAL"
}

const enum Offers {
  LITE_OFFER = 'ppg_lite',
  LITE_OFFER_EUR = 'ppg_lite_eur',
  LITE_OFFER_USD = 'ppg_lite_usd'
}

export enum OfferPlanPrototypeType {
  SETTLEMENT_POLICY = 'SETTLEMENT_POLICY',
  CAPPING_POLICY = 'CAPPING_POLICY'
}
