import * as React from 'react';
import './Authorization.scss';
import { RouteComponentProps } from 'react-router';
import { UserStore } from '../../stores/user/UserStore';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { t } from '../../base/helpers';
import { Link } from 'react-router-dom';
import { activateAccountUseCase } from '../../useCases/auth-and-identity/ActivateAccountUseCase';
import { AuthorizationLayout } from './AuthorizationLayout';

interface IAccountActivation extends RouteComponentProps<{ token: string }> {
  userStore?: UserStore;
}

@inject('userStore')
@observer
export class AccountActivation extends React.Component<IAccountActivation> {
  @observable public isLoading: boolean = true;
  @observable public isSuccess: boolean = true;

  public async componentDidMount(): Promise<void> {
    const { match, userStore } = this.props;

    userStore.logoutCleanData();
    await activateAccountUseCase.exec({ token: match.params.token })
      .then(() => this.isSuccess = true)
      .catch(() => this.isSuccess = false)
      .finally(() => this.isLoading = false);
  }

  public render() {
    if (this.isLoading) {
      return null;
    }

    if (this.isSuccess) {
      return <AuthorizationLayout>
        <div className='confirm-registration-wrapper'>
          <div>
            <p className='confirm-feedback-message'>
              <span className='feedback-title'>{ t('activate-account::You have successfully created your account.') }</span>
            </p>
            <div className="switch-to-login">
                    <span className="app-back">
                      { t('activate-account::Go to application and') }
                      <Link className='app-register-button' to="/login">{ t('activate-account::Login') }</Link>
                    </span>
            </div>
            <svg className="confirmation-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle className="confirmation-icon__circle" cx="26" cy="26" r="25" fill="none"/>
              <path className="confirmation-icon__tick" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
            </svg>
          </div>
        </div>
      </AuthorizationLayout>
    }

    return <AuthorizationLayout>
      <div className='confirm-registration-wrapper'>
        <p className='confirm-feedback-message'>
          <span className='feedback-title'>{ t('activate-account::There was a problem with your request.') }</span>
          <span className={ 'icon-search size40' }/>
          <span className='feedback-desc'>{ t('activate-account::Please try to register again.') }</span>
        </p>
        <div className="switch-to-register">
              <span className="app-register">
                { t('activate-account::Go back to') }
                <Link to="/register" className='app-register-button'>{ t('activate-account::Registration') }</Link>
              </span>
        </div>
      </div>
    </AuthorizationLayout>
  }
}

