import { GetOrganizationMobileFeaturesEnabled } from './common/GetOrganizationMobileFeaturesEnabled';
import { GetSubscriberComparatorTableDataUseCase } from './subscribers/GetSubscriberComparatorTableDataUseCase';
import { GetSubscribersAverageLifetimeUseCase } from './subscribers/GetSubscribersAverageLifetimeUseCase';
import { GetOrganizationSubscribersStructureUseCase } from './subscribers/GetOrganizationSubscribersStructureUseCase';
import { GetOrganizationActiveSubscribersUseCase } from './subscribers/GetOrganizationActiveSubscribersUseCase';
import { GetSubscribedActivityUseCase } from './subscribers/GetSubscribedActivityUseCase';
import { GetActiveSubscribersInRangeUseCase } from './subscribers/GetActiveSubscribersInRangeUseCase';
import { GetSubscribersLocalisationUseCase } from './subscribers/GetSubscribersLocalisationUseCase';
import { GetSubscribersLifetimeStructureUseCase } from './subscribers/GetSubscribersLifetimeStructureUseCase';
import { GetCampaignsStatisticsUseCase } from './campaigns/GetCampaignsStatisticsUseCase';
import { GetActiveSubscribersRatioUseCase } from './subscribers/GetActiveSubscribersRatioUseCase';
import { GetCampaignsSendHoursUseCase } from './campaigns/GetCampaignsSentHoursUseCase';
import { GetCampaignClickedDeliveredHourUseCase } from './campaigns/GetCampaignClickedDeliveredHourUseCase';
import { GetTriggeredCampaignsUseCase } from './campaigns/GetTriggeredCampaignsUseCase';
import { GetActiveTriggeredCampaignsCountUseCase } from './campaigns/GetActiveTriggeredCampaignsCountUseCase';
import { GetSubscribedComparatorUseCase } from './subscribers/GetSubscribedComparatorUseCase';
import { GetActiveSubscribersComparatorUseCase } from './subscribers/GetActiveSubscribersComparatorUseCase';
import { GetCampaignsComparatorStatisticsUseCase } from './campaigns/GetCampaignsComparatorStatisticsUseCase';
import { GetCampaignsComparatorHistogramUseCase, GetCampaignsComparatorTableUseCase } from './campaigns/GetCampaignsComparatorHistogramUseCase';
import { GetSubscriberComparatorHistogramUseCase } from './subscribers/GetSubscriberComparatorHistogramUseCase';
import { GetComparatorProjectRankUseCase } from './campaigns/GetComparatorProjectRankUseCase';
import { GetAutomationRankUseCase } from './automation/GetAutomationRank';
import { GetCampaignsStatisticsHistogramUseCase } from './campaigns/GetCampaignsStatisticsHistogramUseCase';
import { GetActiveSubscribersHistogramUseCase } from './subscribers/GetActiveSubscribersHistogramUseCase';
import { GetSubscribersActivityHistogramUseCase } from './subscribers/GetSubscribersActivityHistogramUseCase';
import { GetActiveSubscribersRatioHistogramUseCase } from './subscribers/GetActiveSubscribersRatioHistogramUseCase';
import { GetAutomationsStatisticsHistogramUseCase } from './automation/GetAutomationsStatisticsHistogramUseCase';
import { GetAutomationsStatisticsUseCase } from './campaigns/GetAutomationsStatisticsUseCase';
import { GetAutomationsComparatorHistogramUseCase } from './automation/GetAutomationsComparatorHistogramUseCase';
import { GetAutomationComparatorTableUseCase } from './automation/GetAutomationComparatorTableUseCase';
import { GetAutomationsComparatorStatsUseCase } from './automation/GetAutomationsComparatorStatsUseCase';

/**
 * Subscribers
 */
export const getSubscribersAverageLifetimeUseCase = new GetSubscribersAverageLifetimeUseCase();
export const getOrganizationSubscribersStructureUseCase = new GetOrganizationSubscribersStructureUseCase();
export const getOrganizationActiveSubscribersUseCase = new GetOrganizationActiveSubscribersUseCase();
export const getSubscribedActivityUseCase = new GetSubscribedActivityUseCase();
export const getActiveSubscribersInRangeUseCase = new GetActiveSubscribersInRangeUseCase();
export const getSubscribersLocalisationUseCase = new GetSubscribersLocalisationUseCase();
export const getSubscribersLifetimeStructureUseCase = new GetSubscribersLifetimeStructureUseCase();
export const getSubscribedComparatorUseCase = new GetSubscribedComparatorUseCase();
export const getActiveSubscribersComparatorUseCase = new GetActiveSubscribersComparatorUseCase();
export const getSubscriberComparatorHistogramUseCase = new GetSubscriberComparatorHistogramUseCase();
export const getActiveSubscribersRatioUseCase = new GetActiveSubscribersRatioUseCase();
export const getActiveSubscribersHistogramUseCase = new GetActiveSubscribersHistogramUseCase();
export const getSubscribersActivityHistogramUseCase = new GetSubscribersActivityHistogramUseCase();
export const getSubscriberComparatorTableDataUseCase = new GetSubscriberComparatorTableDataUseCase();
export const getActiveSubscribersRatioHistogramUseCase = new GetActiveSubscribersRatioHistogramUseCase();

/**
 * Campaigns
 */
export const getCampaignsStatisticsUseCase = new GetCampaignsStatisticsUseCase();
export const getCampaignsSendHoursUseCase = new GetCampaignsSendHoursUseCase();
export const getCampaignClickedDeliveredHourUseCase = new GetCampaignClickedDeliveredHourUseCase();
export const getActiveTriggeredCampaignsCountUseCase = new GetActiveTriggeredCampaignsCountUseCase();
export const getCampaignsComparatorStatisticsUseCase = new GetCampaignsComparatorStatisticsUseCase();
export const getCampaignsComparatorHistogramUseCase = new GetCampaignsComparatorHistogramUseCase();
export const getCampaignsComparatorTableUseCase = new GetCampaignsComparatorTableUseCase();
export const getCampaignsStatisticsHistogramUseCase = new GetCampaignsStatisticsHistogramUseCase();
export const getAutomationsStatisticsHistogramUseCase = new GetAutomationsStatisticsHistogramUseCase();
export const getAutomationsStatisticsUseCase = new GetAutomationsStatisticsUseCase();
export const getAutomationsComparatorHistogramUseCase = new GetAutomationsComparatorHistogramUseCase();
export const getAutomationsComparatorHistogramListUseCase = new GetAutomationComparatorTableUseCase();
export const getAutomationsComparatorStatsUseCase = new GetAutomationsComparatorStatsUseCase();

/**
 * Automation
 */
export const getAutomationRankUseCase = new GetAutomationRankUseCase();

/**
 * Mixed
 */
export const getComparatorProjectRankUseCase = new GetComparatorProjectRankUseCase();

/**
 * Common
 */
export const getOrganizationMobileFeaturesEnabled = new GetOrganizationMobileFeaturesEnabled();
