import { action, computed, observable, reaction } from 'mobx';
import { ProjectRelated } from "../ProjectRelatedStore";
import { Selector } from '../../modelsMobx/selector/Selector';
import { ISelectorDTO } from '../../modelsMobx/selector/SelectorDTO';
import { property } from '@ppg/common';
import { listSelectorsUseCase } from '../../useCases/core/selector/ListSelectorsUseCase';
import { deleteSelectorUseCase } from '../../useCases/core/selector/DeleteSelectorUseCase';
import { createSelectorUseCase } from '../../useCases/core/selector/CreateSelectorUseCase';
import { updateBasicSelectorUseCase } from '../../useCases/core/selector/UpdateBasicSelectorUseCase';
import { selectorSettingsTypes } from '../../modelsMobx/selector/ISelector';
import { updateCustomSelectorUseCase } from '../../useCases/core/selector/UpdateCustomSelectorUseCase';
import { IListSelectorsResult } from '../../useCases/core/selector/interfaces';
import { ITEMS_PER_PAGE } from '../../constants';
import { listAllSelectorsUseCase } from "../../useCases/core/selector/ListAllSelectorsUseCase";
import { ISelectOption, toast } from '@ppg/styled';
import { t } from '../../base/helpers';
import { LabelStrategyOptions } from "./AutomationLabelsStore";

export class SelectorStore extends ProjectRelated {

  @observable public allSelectors: Selector[] = [];
  @observable public selectors: Selector[] = [];
  @observable public selectorToEdit: Selector = null;
  @observable public isWizardMode: boolean = false;
  @observable public isLoading: boolean = false;
  @observable public total: number = 0;

  @property() public offset: number = 0;
  @property() public perPage: number = ITEMS_PER_PAGE;
  @property() private infinity: boolean;

  fetchOnPaginationChange = reaction(
    () => this.doFetch,
    () => this.fetchSelectors()
  );

  @computed
  get doFetch() {
    return {
      offset: this.offset,
      perPage: this.perPage,
    };
  }

  @computed
  public get getInfinity(): boolean {
    return this.infinity;
  }

  @computed
  public get canSave(): boolean {
    return this.selectorToEdit.getProperty('selector').isValid
      && this.selectorToEdit.getProperty('prettyName').isValid
      && this.selectorToEdit.getProperty('labelKey').isValid;
  }

  public get canOpenWizard(): boolean {
    return this.projectSiteUrl && this.projectSiteUrl.includes('https');
  }

  public get wizardUrl(): string {
    return this.projectSiteUrl + '/?ppg_wizard=true';
  }

  public get hasAutomationSelectors(): boolean {
    return this.automationSelectors.length > 0;
  }

  public get subscriberSelectors(): Selector[] {
    return this.notMagicSelectors;
  }

  public get selectorTestData(): Object {
    let testData = {};
    this.selectors.map(selector => testData[selector.name] = '');
    return testData;
  }

  public get automationSelectors(): Selector[] {
    return this.notMagicSelectors;
  }

  private get notMagicSelectors(): Selector[] {
    return this.allSelectors.filter(selector => !selector.isMagic);
  }

  setDefaultValues() {
    this.selectors = [];
    this.clearState();
  }

  public async getSelectors(): Promise<IListSelectorsResult> {
    return await listSelectorsUseCase.exec({
      projectID: this.projectId,
      limit: this.perPage,
      offset: this.offset
    });
  }

  public async getAllSelectors(): Promise<IListSelectorsResult> {
    return listAllSelectorsUseCase.exec({
      projectID: this.projectId,
    });
  }

  @action
  public async fetchSelectors(): Promise<void> {
    this.isLoading = true;
    const { data, metadata } = await this.getSelectors();

    const selectors = data.map(selector => Selector.createFromSelectorDTO(selector));
    this.total = metadata.total;
    this.selectors = this.infinity ? this.selectors.concat(selectors) : selectors;

    this.isLoading = false;
  }

  @action
  public async fetchAllSelectors(): Promise<void> {
    this.isLoading = true;
    const { data } = await this.getAllSelectors();

    this.allSelectors = data.map(selector => Selector.createFromSelectorDTO(selector));
    this.isLoading = false;
  }

  @action
  public async onDeleteConfirm(selector: Selector): Promise<void> {
    await deleteSelectorUseCase.exec({
      projectID: this.projectId,
      selectorID: selector.id
    }).then(() => toast.success(t('Your selector has been deleted successfully')))
      .then(() => {
        this.selectors = [];
        if (this.infinity) {
          this.offset === 0 ? this.fetchSelectors() : this.offset = 0;
        } else {
          this.fetchSelectors();
        }
      });
    this.clearState();
  }

  @action
  public async onSelectorSave(): Promise<void> {
    this.selectorToEdit.validateAndRewriteLabelSettings();
    this.selectorToEdit.isNew
      ? await this.createSelector()
      : await this.updateSelector();

    !this.infinity && await this.fetchSelectors();
  }

  private getToEditDTO() {
    return { project: this.projectId, ...this.selectorToEdit.toSelectorDTO(this.projectId) };
  }

  @action
  public async createSelector(): Promise<void> {
    await createSelectorUseCase.exec(this.getToEditDTO());

    if (this.infinity) {
      const { data } = await this.getSelectors();
      const newSelector = data[0];
      this.total++;
      this.selectors.push(Selector.createNewSelector(newSelector));
    }
  }

  @action
  public async updateSelector(): Promise<void> {
    this.selectorToEdit.settingsType === selectorSettingsTypes.CUSTOM
      ? await this.updateCustomSelector()
      : await this.updateBasicSelector();
  }

  @action
  public async updateCustomSelector(): Promise<void> {
    await updateCustomSelectorUseCase.exec({
      projectID: this.projectId,
      selectorID: this.selectorToEdit.id,
      ...this.getToEditDTO()
    });
  }

  @action
  public async updateBasicSelector(): Promise<void> {
    await updateBasicSelectorUseCase.exec({
      projectID: this.projectId,
      selectorID: this.selectorToEdit.id,
      ...this.getToEditDTO()
    });
  }

  public onCancel(): void {
    if (this.selectorToEdit) {
      this.selectorToEdit.restore();
    }
    this.clearState();
  }

  @action
  public setInfinity(infinity: boolean): void {
    this.infinity = infinity;
  }

  @action
  public onEdit(selector): void {
    this.selectorToEdit = selector;
  }

  @action
  public onPaste(selector: ISelectorDTO): void {
    this.selectorToEdit = Selector.createFromSelectorDTO(selector);
  }

  @action
  public onNewSelector(): void {
    this.selectorToEdit = Selector.createNewSelector();
  }

  @action
  public toggleWizardMode(): void {
    this.isWizardMode = !this.isWizardMode;
  }

  @action
  public onWizardAdd(wizardData: any): void {
    this.selectorToEdit = Selector.createWizardSelector(wizardData);
  }

  @action
  public clearState(): void {
    this.selectorToEdit = null;
  }

  public get labelStrategyOptions(): ISelectOption[] {
    return [
      { value: "", name: "", disabled: true, hidden: true },
      { value: [LabelStrategyOptions.REWRITE], name: t('rewrite') },
      { value: [LabelStrategyOptions.APPEND], name: t('append') },
    ];
  }
}
