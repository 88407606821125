import * as React from 'react';
import { t } from "../../../base/helpers";
import { Button, EmptyDataComponent, Loader, Switch, ConfirmIconButton, TypeSizeBtn, ButtonTypes } from '@ppg/styled';
import { observer } from 'mobx-react';
import { WebhookStore } from '../../../stores/project/WebhooksStore';
import { WrappedPagination } from '../../../assets/wrapped';
import { Webhook } from '../../../modelsMobx/Webhook';
import ReactTooltip from 'react-tooltip';

interface IWebhooksTableProps {
  webhookStore: WebhookStore;
}

@observer
export class WebhooksTable extends React.Component<IWebhooksTableProps> {

  public async componentDidMount() {
    await this.props.webhookStore.fetchWebhooks();
  }

  private async handleDeleteWebhook(webhook: Webhook): Promise<void> {
    await this.props.webhookStore.onDeleteConfirmed(webhook);
  }

  render() {
    const { webhookStore } = this.props;

    if (webhookStore.isLoading) {
      return <Loader/>;
    }

    return (
      <>
        { webhookStore.webhooks.length > 0 ?
          <table className="webhooks-table">
            <thead>
            <tr>
              <th>{ t('Event') }</th>
              <th>{ t('Url') }</th>
              <th>{ t('Headers') }</th>
              <th>{ t('Toggle webhook') } </th>
              <th/>
            </tr>
            </thead>
            <tbody>

            { webhookStore.webhooks.map((webhook, idx) => {
              return <tr key={ webhook.id }>
                <td data-label={ t('Event') }>{ webhook.type }</td>
                <td data-label={ t('Url') }>{ webhook.url }</td>
                <td data-label={ t('Headers') }>{ JSON.stringify(webhook.headers || {}, null, 2) }</td>
                <td data-label={ t('Toggle webhook') }>
                  <Switch property={ webhook.getProperty('enabled') }
                          name={ webhook.id }
                          onChange={ () => webhookStore.onWebhookToggle(webhook) }
                          className="enable-webhook-switch"
                  />
                </td>
                <td>
                  <div className="action-buttons">
                    <ConfirmIconButton icon={ 'trash' }
                                       typeBtn="white"
                                       positionBtn="row"
                                       size={ TypeSizeBtn.MEDIUM }
                                       dataTip={ t('Delete webhook') }
                                       dataTipConfirmBtn={ t('Confirm delete webhook') }
                                       dataTipCancelBtn={ t('Cancel delete webhook') }
                                       onConfirm={ () => this.handleDeleteWebhook(webhook) }/>

                    <Button size={ 'medium' }
                            isSquare={ true }
                            icon={ 'pencil' }
                            type={ ButtonTypes.DEFAULT }
                            dataTip={ t('Edit webhook') }
                            onClick={ () => webhookStore.onEdit(webhook) }/>
                  </div>
                </td>
              </tr>;
            }) }

            </tbody>
          </table> :
          <EmptyDataComponent
            content={ t('You don\'t have any added webhooks') }
            buttonContent={ t('new webhook') }
            icon={ 'condition' }
            buttonIcon={ 'plus-badge' }
            onClick={ () => webhookStore.onNewWebhook() }
          />
        }

        <WrappedPagination total={ webhookStore.getProperty('total') }
                           offsetProperty={ webhookStore.getProperty('offset') }
                           itemsPerPage={ webhookStore.getProperty('perPage') }
                           perPageSelector={ true }
                           infinityScroll={ webhookStore.getInfinity() }
        />
        <ReactTooltip type="light" effect="solid" place="top"/>
      </>
    );
  }
}