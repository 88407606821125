import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { redirect } from '../../base/helpers';
import { UserStore } from '../../stores/user/UserStore';
import { ProjectRoutesTypes } from '../../routes/moduleProjectRoutes';

interface ILoginAsProps extends RouteComponentProps<{ token: string }> {
  userStore?: UserStore;
}

@inject('userStore')
@observer
export class LoginAs extends React.Component<ILoginAsProps> {
  public async componentDidMount() {
    // @todo
    this.props.userStore.logoutCleanData();

    const { token } = this.props.match.params;

    const searchParams = new URLSearchParams(this.props.location.search);

    let redirectUrl: string = searchParams.has('redirect')
      ? searchParams.get('redirect')
      : ProjectRoutesTypes.DASHBOARD;

    if (searchParams.has('project')) {
      redirectUrl = redirectUrl.concat(`?project=${ searchParams.get('project') }`)
    }

    return this.props.userStore.authorization
      .loginByToken(token)
      .then(() => redirect(redirectUrl));
  }

  public render() {
    return null;
  }
}
