import { action, computed, observable, reaction } from 'mobx';
import { ProjectRelated } from "../ProjectRelatedStore";
import { Webhook } from "../../modelsMobx/Webhook";
import { createWebhookUseCase, deleteWebhookUseCase, getWebhookListUseCase, updateWebhookUseCase } from '../../useCases/webhooks/WebhookUseCases';
import { property } from '@ppg/common';
import { ITEMS_PER_PAGE } from '../../constants';
import { toast } from '@ppg/styled';
import { t } from '../../base/helpers';

export class WebhookStore extends ProjectRelated {

  @property() public perPage: number = ITEMS_PER_PAGE;
  @property() public offset: number = 0;
  @property() public infinity: boolean;
  @property() public isLoading: boolean = true;
  @observable public total: number = 0;

  @observable public webhooks: Webhook[] = [];
  @observable public webhookToEdit: Webhook;
  @observable public webhookToEnable: Webhook;
  @observable public isNewWebhook: boolean;

  fetchOnPaginationChange = reaction(
    () => this.doFetch,
    () => this.listWebhooks(),
  );

  @computed
  get doFetch() {
    return {
      offset: this.offset,
      perPage: this.perPage,
    };
  }

  @action
  public setDefaultValues(): void {
    this.perPage = ITEMS_PER_PAGE;
    this.offset = 0;
    this.infinity = false;
    this.isLoading = true;
    this.total = 0;
    this.webhooks = [];
    this.isNewWebhook = false;
    this.clearState();
  }

  @action
  public fetchWebhooks = async (): Promise<void> => {
    if (this.webhooks.length > 0) {
      return;
    }
    this.listWebhooks();
  };

  @action
  public listWebhooks = async (): Promise<void> => {
    await getWebhookListUseCase.exec({
        project: this.currentProject.id as string,
        limit: this.perPage,
        offset: this.offset
      }
    ).then(({ data, metadata }) => {
      const webhooks = this.parseWebhooks(data);
      this.total = metadata.total;
      this.webhooks = this.infinity ? this.webhooks.concat(webhooks) : webhooks;
      this.isLoading = false;
    }).catch((error) => console.error(error));
  };

  @action
  public parseWebhooks = (Webhooks: Webhook[]): Webhook[] => {
    return Webhooks.map(webhook => Webhook.createFromWebhookDTO(webhook));
  };

  public get disableSave(): boolean {
    if (!this.webhookToEdit) {
      return false;
    }
    return !this.webhookToEdit.getProperty('url').isValid;
  }

  @action
  public onNewWebhook(): void {
    this.webhookToEdit = Webhook.createDefaultWebhook();
    this.isNewWebhook = true;
  }

  @action
  public onEdit(webhook: Webhook): void {
    this.webhookToEdit = webhook;
  }

  @action
  public onCancel(): void {
    this.clearState();
  }

  @action
  public async onWebhookToggle(webhook: Webhook): Promise<void> {
    this.webhookToEnable = webhook;
    await updateWebhookUseCase.exec({
      id: this.webhookToEnable.id,
      project: this.currentProject.id as string,
      headers: this.webhookToEnable.headers,
      url: this.webhookToEnable.url,
      type: this.webhookToEnable.type,
      enabled: this.webhookToEnable.enabled
    });
    this.clearState();
  }

  @action
  public clearState(): void {
    this.webhookToEnable = null;
    this.webhookToEdit = null;
    this.isNewWebhook = false;
  }

  @action
  public async createWebhook(): Promise<void> {
    await createWebhookUseCase.exec({
      project: this.currentProject.id as string,
      headers: this.webhookToEdit.headers,
      url: this.webhookToEdit.url,
      type: this.webhookToEdit.type
    });
  }

  @action
  public async onSave(): Promise<void> {
    this.isNewWebhook ?
      await createWebhookUseCase.exec({
        project: this.currentProject.id as string,
        headers: this.webhookToEdit.headers,
        url: this.webhookToEdit.url,
        type: this.webhookToEdit.type
      }).then((res) => this.webhooks.unshift(
        Webhook.createFromWebhookDTO({
          id: res.id,
          enabled: true,
          type: this.webhookToEdit.type,
          headers: this.webhookToEdit.headers,
          url: this.webhookToEdit.url,
        })
      ))
      : await updateWebhookUseCase.exec({
        id: this.webhookToEdit.id,
        project: this.currentProject.id as string,
        headers: this.webhookToEdit.headers,
        url: this.webhookToEdit.url,
        type: this.webhookToEdit.type,
        enabled: this.webhookToEdit.enabled
      });
    this.clearState();
  }

  @action
  public async onDeleteConfirmed(webhook: Webhook): Promise<void> {
    this.isLoading = true;
    await deleteWebhookUseCase.exec({
        project: this.currentProject.id as string,
        id: webhook.id
      })
      .then(() => toast.success(t('Your webhook has been deleted successfully')))
      .then(() => {
        this.webhooks = [];
        if (this.infinity) {
          this.offset === 0 ? this.listWebhooks() : this.offset = 0;
        } else {
          this.listWebhooks();
        }
      });
    this.clearState();
  }

  @action
  public setInfinity(infinity: boolean): void {
    this.infinity = infinity;
  }

  public getInfinity(): boolean {
    return this.infinity;
  }
}
