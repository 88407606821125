import { UseCase, API } from '@ppg/common';
import { IUpdateGA } from '../interfaces';

export class UpdateGAPluginUseCase extends UseCase<IUpdateGA, void> {
  protected async execute({ websiteIntegrationId, enabled, tracker }: IUpdateGA) {
    await API.HTTP.put(`wi/projects/${ API.HTTP.currentProjectId }/website-integrations/${ websiteIntegrationId }/plugins/google-analytics`, { enabled, tracker })
  }
}

export const updateGAPluginUseCase = new UpdateGAPluginUseCase();
