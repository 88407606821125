import { FunnelDatum } from '@nivo/funnel/dist/types/types';
import * as React from 'react';
import './FunnelChartTooltip.scss'
import { FC } from 'react';
import { fixedPercentValue } from '@ppg/common';

interface IFunnelChartPoint {
  part: {
    data: FunnelDatum;
    color: any;
  }
}

export const FunnelChartTooltip: FC<IFunnelChartPoint> = ({ part }: IFunnelChartPoint) => {
  const { data, color } = part;
  return <div className="lineTooltip">
    <div key={ data.id } className="lineTooltip__item">
      <div className={ 'lineTooltip__color' } style={ { backgroundColor: color } }/>
      <strong>{ fixedPercentValue(data.value) }</strong>
      <span className={ 'text-light' }>{ data.label }</span>
    </div>
  </div>
}
