import { property, PropertyHandler } from "@ppg/common";

export interface ICustomUtm {
  parameter: string;
  value: string;
}

export class CustomUtm extends PropertyHandler {
  @property() public parameter: string;
  @property() public value: string;

  constructor(utm: ICustomUtm) {
    super();
    this.parameter = utm.parameter;
    this.value = utm.value;
  }

  static createCustomUtm(utm?: ICustomUtm): CustomUtm {
    if (utm) {
      return new CustomUtm(utm);
    } else {
      return new CustomUtm({
        parameter: '',
        value: ''
      })
    }
  }

  public serialize(): ICustomUtm {
    return {
      parameter: this.parameter,
      value: this.value,
    }
  }
}
