import { UseCase, API } from '@ppg/common';
import { IUpdateSubscriptionForm } from '../interfaces';

export class UpdateSubscriptionFormUseCase extends UseCase<IUpdateSubscriptionForm, void> {
  protected async execute({ websiteIntegrationId, template, styles, type }: IUpdateSubscriptionForm) {
    return API.HTTP.post<void>(`wi/projects/${ API.HTTP.currentProjectId }/website-integrations/${ websiteIntegrationId }/subscription-forms`, {
      template, styles, type
    })
  }
}

export const updateSubscriptionFormUseCase = new UpdateSubscriptionFormUseCase();
