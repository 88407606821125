import { API, UseCase } from '@ppg/common';
import { IListSelectors, IListSelectorsResult } from './interfaces';

export class ListSelectorsUseCase extends UseCase<IListSelectors, IListSelectorsResult> {
  protected async execute({ projectID, limit, offset }) {
    return API.HTTP.get<IListSelectorsResult>(`core/projects/${ projectID }/selectors?offset=${ offset }&limit=${ limit }`)
  }
}

export const listSelectorsUseCase = new ListSelectorsUseCase();
