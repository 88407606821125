import { API, UseCase } from "@ppg/common";
import { ICampaignId, IGetRocketPushCampaign } from '../interfaces';

interface IGetRocketPushRequest extends ICampaignId {}
interface IGetRocketPushResponse extends IGetRocketPushCampaign {}

export class GetRocketPushUseCase extends UseCase<IGetRocketPushRequest, IGetRocketPushResponse> {
  protected async execute({ campaignId }: IGetRocketPushRequest) {
    return API.HTTP.get<IGetRocketPushResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/rocket/${ campaignId }`);
  }
}

export const getRocketPushUseCase = new GetRocketPushUseCase();
