import { API, UseCase } from '@ppg/common';
import { IOfferCappingPlan } from '../../modelsMobx/Offer';

interface IGetCappingLimitsAndActualValueRequest {
  organization: string;
  project: string;
}

export interface ICapping extends IOfferCappingPlan {
  project: string;
  actual: number;
}

export class GetCappingLimitsAndActualValue
  extends UseCase<IGetCappingLimitsAndActualValueRequest, ICapping[]> {
  protected async execute({ organization, project }) {
    return API.HTTP.get<ICapping[]>(
      `cappings/organizations/${ organization }/projects/${ project }`,
    );
  }
}

export const getCappingLimitsAndActualValue = new GetCappingLimitsAndActualValue();
