import { API, UseCase } from '@ppg/common';

interface IListPolygons {
  project: string;
  limit: number;
  offset: number;
}

interface IPolygonsResponse {
  id: string;
  name: string;
  coordinates: [number, number][];
}

interface IListPolygonsResult {
  data: IPolygonsResponse[],
  metadata: { total: number },
}

export class ListPolygonsUseCase extends UseCase<IListPolygons, IListPolygonsResult> {
  protected async execute({ project, offset, limit }: IListPolygons) {
    return API.HTTP.get<IListPolygonsResult>(`ah/projects/${ project }/polygons?offset=${ offset }&limit=${ limit }`);
  }
}

export const listPolygonsUseCase = new ListPolygonsUseCase();
