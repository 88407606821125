import { UseCase, API } from '@ppg/common';
import { IDeleteSelector } from './interfaces';

export class DeleteSelectorUseCase extends UseCase<IDeleteSelector, void> {
  protected async execute({ projectID, selectorID }) {
    await API.HTTP.delete<void>(`core/projects/${ projectID }/selectors/${ selectorID }`)
  }
}

export const deleteSelectorUseCase = new DeleteSelectorUseCase();
