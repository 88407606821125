import { computed, observable, reaction, action } from 'mobx';
import { ProjectRelated } from '../ProjectRelatedStore';
import { Polygon } from '../../modelsMobx';
import { createPolygonUseCase, listPolygonsUseCase, removePolygonUseCase, updatePolygonUseCase } from '../../useCases/app-helper';
import { property } from '@ppg/common';
import { ITEMS_PER_PAGE, POLYGON_LIMIT } from '../../constants';
import { toast } from '@ppg/styled';
import { t } from '../../base/helpers';

export class GeolocationStore extends ProjectRelated {
  @observable public polygons: Polygon[] = [];
  @observable public allPolygons: Polygon[] = [];
  @observable public total: number = 0;

  @property() public offset: number = 0;
  @property() public perPage: number = ITEMS_PER_PAGE;
  @property() private infinity: boolean;
  @property() public isLoading: boolean = true;
  @property() public isLoadingAllPolygons: boolean = false;

  /**
   * Each coordinates can be compares by stringify (sorted set)
   * @param coordinates
   * @param toCompare
   */
  static compareCoordinates(coordinates, toCompare) {
    return JSON.stringify(coordinates) === JSON.stringify(toCompare);
  }

  /**
   * Iterate by each polygons with check toCheck item includes by compare
   * @param coordinatesSet
   * @param toCheck
   */
  static isInCoordinatesSet(coordinatesSet, toCheck) {
    for (let coordinates of coordinatesSet) {
      if (GeolocationStore.compareCoordinates(coordinates, toCheck)) {
        return true;
      }
    }

    return false;
  }

  /**
   * Iterate by each polygons with check toCheck item includes by compare
   * @param coordinatesSet
   * @param toCheck
   */
  static indexOf(coordinatesSet, toCheck) {
    for (let i = 0; i < coordinatesSet.length; i++) {
      if (GeolocationStore.compareCoordinates(coordinatesSet[i], toCheck)) {
        return i;
      }
    }

    return -1;
  }

  fetchOnPaginationChange = reaction(
    () => this.doFetch,
    () => this.listPolygons()
  );

  public validate() {
    return this.polygonToEdit.validate();
  }

  setDefaultValues() {
    this.polygons = [];
    this.allPolygons = [];
    this.offset = 0;
    this.perPage = ITEMS_PER_PAGE;
    this.total = 0;
    this.infinity = false;
    this.isLoading = true;
  }

  @computed
  get doFetch() {
    return {
      offset: this.offset,
      perPage: this.perPage,
    };
  }

  @action
  public async fetchAllPolygons() {
    this.isLoadingAllPolygons = true;
    const items = await listPolygonsUseCase.exec({
      project: this.projectId,
      offset: 0,
      limit: POLYGON_LIMIT
    });

    this.allPolygons = items.data.map(polygon => {
      return new Polygon({
        id: polygon.id,
        name: polygon.name,
        coordinates: polygon.coordinates,
      });
    });
    this.isLoadingAllPolygons = false;
  }

  public async fetchPolygons() {
    if (this.polygons.length > 0) {
      return;
    }
    await this.listPolygons();
  }

  public async listPolygons() {
    return listPolygonsUseCase.exec({
      project: this.projectId,
      offset: this.offset,
      limit: this.perPage
    }).then(({ data, metadata }) => {
        this.total = metadata.total;
        const polygons = data.map(polygon => {
          return new Polygon({
            id: polygon.id,
            name: polygon.name,
            coordinates: polygon.coordinates,
          });
        });
        this.polygons = this.infinity ? this.polygons.concat(polygons) : polygons;
        this.isLoading = false;
      })
      .catch(err => err);
  }

  public getInfinity() {
    return this.infinity;
  }

  public setInfinity(infinity: boolean) {
    this.infinity = infinity;
  }

  public get selectedPolygon() {
    return this.polygons.find(polygon => polygon.isSelected);
  }

  public get polygonToEdit() {
    return this.polygons.find(polygon => polygon.isEdited);
  }

  public get availablePolygons() {
    return this.allPolygons.filter(polygon => !polygon.isSelected);
  }

  public get selectedPolygons() {
    return this.allPolygons.filter(polygon => polygon.isSelected);
  }

  public get hasNoPolygons() {
    return this.allPolygons.length === 0;
  }

  public getCampaignPolygons(polygons: [Number, Number][][]) {
    return this.allPolygons.filter(polygon => {
      return GeolocationStore.isInCoordinatesSet(polygons, polygon.coordinates);
    });
  }

  public onNewPolygon() {
    this.restoreState();
    this.polygons.push(Polygon.createPolygon());
  }

  public onPolygonSave(polygon: google.maps.Polygon) {
    this.polygonToEdit.getProperty('coordinates').setValue(Polygon.parseCoordinates(polygon));
  }

  public onEdit(polygon: Polygon) {
    polygon.toggleEdited();
  }

  public onCancel() {
    this.restoreState();
  }

  public async onSave() {
    if (this.polygonToEdit.isNew) {
      await createPolygonUseCase.exec({
        project: this.projectId,
        name: this.polygonToEdit.name,
        coordinates: this.polygonToEdit.coordinates
      }).then((res) => {
        const polygon = Polygon.createPolygon(res);
        this.polygons = [polygon].concat(this.polygons);
        this.total++;
      });
    } else {
      await updatePolygonUseCase.exec({
        project: this.projectId,
        name: this.polygonToEdit.name,
        polygon: this.polygonToEdit.id,
        coordinates: this.polygonToEdit.coordinates
      }).then((res) => {
        const editedNew = Polygon.createPolygon(res);
        const editedPolygonIndex = this.polygons.find(polygon => polygon.id === this.polygonToEdit.id);
        const index = this.polygons.indexOf(editedPolygonIndex);
        this.polygons[index] = editedNew;
      });
    }

    this.restoreState();
  }

  public async onDeleteConfirm(polygon: Polygon) {
    this.isLoading = true;
    await removePolygonUseCase.exec({
        project: this.projectId,
        polygon: polygon.id
      })
      .then(() => toast.success(t('Your polygon has been deleted successfully')))
      .then(() => {
        this.polygons = [];
        if (this.infinity) {
          this.offset === 0 ? this.listPolygons() : this.offset = 0;
        } else {
          this.listPolygons();
        }
      });
    this.restoreState();
  }

  public restoreState() {
    this.polygons = this.polygons.filter(polygon => !polygon.isNew);
    this.polygons.forEach(polygon => polygon.restore());
  }

  public polygonToEditIsValid(): boolean {
    return !!this.polygonToEdit.coordinates.length;
  }

  @action
  public clearPolygonToEdit(): void {
    this.polygons = this.polygons.filter(polygon => !polygon.isEdited);
  }
}
