import { IMap } from '../interfaces';
import { api, redirect, t } from './helpers';
import { IResponseError } from '../interfaces';
import * as $ from 'jquery';
import * as lodash from 'lodash';
import { toast } from '@ppg/styled';
import { AccessError, AuthError } from '@ppg/common';

export interface IApiManagerResponse<TResponseData> {
  res: Response;
  data: TResponseData;
}

export class ApiManager {
  public get<TResponseData>(url: string, data: IMap<any> = {}, headers: IMap<any> = {}): Promise<IApiManagerResponse<TResponseData>> {
    return this.internalRequest<TResponseData>(url, 'GET', data, headers);
  }

  public post<TResponseData>(url: string, data: IMap<any> = {}, headers: IMap<any> = {}): Promise<IApiManagerResponse<TResponseData>> {
    return this.internalRequest<TResponseData>(url, 'POST', data, headers);
  }

  public put<TResponseData>(url: string, data: IMap<any> = {}, headers: IMap<any> = {}): Promise<IApiManagerResponse<TResponseData>> {
    return this.internalRequest<TResponseData>(url, 'PUT', data, headers);
  }

  public del<TResponseData>(url: string, data: IMap<any> = {}, headers: IMap<any> = {}): Promise<IApiManagerResponse<TResponseData>> {
    return this.internalRequest<TResponseData>(url, 'DELETE', data, headers);
  }

  private internalRequest<TResponseData>(url: string, method: string, data: IMap<any> = {}, headers: IMap<any> = {}): Promise<IApiManagerResponse<TResponseData>> {
    const isGet = method.toUpperCase() === 'GET';
    const requestURL: string = !isGet ? api(url) : api(url).concat(!lodash.isEmpty(data) ? `?${ $.param(data) }` : '');
    const body: string = isGet ? undefined : JSON.stringify(data);

    return fetch(requestURL, {
      method: method,
      body: body,
      headers: lodash.extend({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }, this._defaultHeaders, headers) as any,
    })
      .then(this.responseHandler) as any;
  }

  private parseResponse(res: Response) {
    const contentType = res.headers.get('Content-Type');
    return contentType && contentType.indexOf('application/json') !== -1 ? res.json() : res.text();
  }

  private parseErrorAsString(err: string): void {
    if (err) {
      toast.error(err, { autoClose: 3000 });
    }
  }

  private parseErrorAsObject(err: IResponseError): void {
    if (err.errors) {
      // Select only first error with deepest validation problem
      let errors = Object.keys(err.errors || []);

      if (errors.length) {
        errors.sort((e1, e2) => e1.length > e2.length ? -1 : 1);
        const error = errors[0];

        if (error && err.errors[error]) {
          toast.error(err.errors[error], { autoClose: 3000 });
        }
      }

    } else {
      if (typeof err === 'string') {
        toast.error(err, { autoClose: 3000 });
      } else {
        if (err.message) {
          toast.error(err.message, { autoClose: 3000 });
        }
      }
    }
  }

  private responseHandler = async (res: Response) => {
    if(res.status === 401){
      redirect("/unauthorized");
      throw new AuthError(res);
    }

    if( res.status === 403 ){
      toast.error(t('You cannot perform this action'));
      throw new AccessError(res);
    }

    if (res.ok) {
      const data = await this.parseResponse(res);
      return { data, res };
    }
    return this.parseResponse(res)
      .then((err: IResponseError | string) => {
        if (lodash.isString(err)) {
          this.parseErrorAsString(err as string);
        }
        if (lodash.isObject(err)) {
          this.parseErrorAsObject(err as IResponseError);
        }
        return Promise.reject(err);
      });
  }

  public fetch<TResponse>(url: string, init?: RequestInit): Promise<IApiManagerResponse<TResponse>> {
    return fetch(api(url), lodash.defaultsDeep(init, {
      headers: this._defaultHeaders
    }))
      .then(this.responseHandler) as any;
  }

  public setHeader(key: string, value: string): this {
    this._defaultHeaders[key] = value;
    return this;
  }

  public removeHeader(key: string): this {
    delete this._defaultHeaders[key];
    return this;
  }

  private _defaultHeaders: IMap<string> = {};
}

export const apiManager = new ApiManager();
