import { ElementIdType } from '../../SubscriptionFormEnums';
import { SubscriptionFormBuilder, SubscriptionFormLayer } from './index';
import { tinyStylesMap, tinyRtlStylesMap, tinyAnimations, poweredByProperties } from './index';

export class TinyFormBuilder extends SubscriptionFormBuilder {

  public createLayer(type: string, id: string) {
    const stylesMap = this.formProperties.isDirectionRtl ? tinyRtlStylesMap : tinyStylesMap;
    return new SubscriptionFormLayer(type, id, this.formProperties.color, stylesMap, this.formProperties.picture);
  }

  public getTemplate = () => [
    this.createLayer('div', ElementIdType.BACKDROP)
      .withAnimation(tinyAnimations),
    this.createLayer('div', ElementIdType.FORM)
      .withChild(
        this.createLayer('div', ElementIdType.PICTURE)
          .withDefaultPicture())
      .withChild(
        this.createLayer('div', ElementIdType.CONTENT)
          .withChild(
            this.createLayer('div', ElementIdType.HEADER)
              .withText(this.header)
          )
          .withChild(
            this.createLayer('div', ElementIdType.DESCRIPTION)
              .withText(this.description)
          )
          .withChild(
            this.createLayer('div', ElementIdType.BUTTONS)
              .withChild(
                this.createLayer('button', ElementIdType.DENY)
                  .withText(this.formProperties.denyText)
                  .withDefaultColor()
              )
              .withChild(
                this.createLayer('button', ElementIdType.ALLOW)
                  .withText(this.formProperties.allowText)
                  .withDefaultBackgroundColor()
              )
          )
          .withChild(
            this.createLayer('div', ElementIdType.POWERED_BY)
              .withChild(
                this.createLayer('a', ElementIdType.POWERED_BY_TEXT)
                  .withText('Powered by PushPushGo')
                  .withProperties(poweredByProperties)
              )
          )
      )
  ]
}
