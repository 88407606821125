import * as React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { t } from '../../base/helpers';
import { inject, observer } from 'mobx-react';
import { UserStore } from '../../stores/user/UserStore';
import { debounce, keyCode } from '@ppg/common';
import { ProjectStore } from '../../stores/project/ProjectStore';
import { DropdownRefs } from './NavBarUser';
import { PPGLite, PPGLiteRole } from '../PPGLite/PPGLite';
import { partnershipStore, paymentDataStore } from '../../stores';
import { observable } from 'mobx';
import { ProjectRoutesTypes } from '../../routes/moduleProjectRoutes';
import { PaymentsRoutesTypes } from '../../routes/paymentsRoutes';

interface INavBatUserMenuProps {
  isUserMenuOpened: boolean;

  toggleUserMenu(): void;

  userStore?: UserStore;
  projectStore?: ProjectStore;
  dropdownRefs: DropdownRefs;
}

@inject('userStore', 'projectStore')
@observer
export class NavBarUserMenu extends React.Component<INavBatUserMenuProps> {
  @observable isLoading: boolean = true;

  private readonly dropdown;

  private handleLogoutClick = debounce(() => this.props.userStore.logout(), 300);

  constructor(props) {
    super(props);
    this.dropdown = (React as any).createRef();
  }

  public currentRef(ref: any): any {
    return ref && ref.current;
  }

  public handleCloseDropdown = (event): void => {
    if (this.currentRef(this.dropdown) && !this.dropdown.current.contains(event.target)
      && (event.target !== this.currentRef(this.props.dropdownRefs.dropdownArrow))
      && (event.target !== this.currentRef(this.props.dropdownRefs.dropdownAvatar))
      && (event.target !== this.currentRef(this.props.dropdownRefs.dropdownButton))) {
      this.props.toggleUserMenu();
    }
  };

  private closeWindowByEsc = (event): void => {
    if (event.keyCode === keyCode.ESCAPE) {
      this.handleCloseDropdown(event);
    }
  };

  public componentDidMount() {
    paymentDataStore.fetchPaymentData()
      .then(() => partnershipStore.fetchPartnership())
      .finally(() => this.isLoading = false);
    document.addEventListener('click', this.handleCloseDropdown);
    document.addEventListener('keydown', this.closeWindowByEsc);
  }


  public componentWillUnmount() {
    document.removeEventListener('click', this.handleCloseDropdown);
    document.removeEventListener('keydown', this.closeWindowByEsc);
  }

  public render() {
    const { userStore, projectStore, isUserMenuOpened } = this.props;

    if (!isUserMenuOpened || this.isLoading) {
      return null;
    }

    const paymentsLink = projectStore.hasProjects ? PaymentsRoutesTypes.INVOICES_LIST : PaymentsRoutesTypes.INVOICE_DETAILS;
    
    return (
      <div className="dropdown" ref={ this.dropdown }>
        <ul>
          <li>
            <Link to={ ProjectRoutesTypes.USER_ACCOUNT_SETTINGS } onClick={ () => this.props.toggleUserMenu() }>
              <div className={ "size16" }>{ userStore.user.firstName } { userStore.user.lastName }</div>

              <div className={ "m5t" }><span className="icon-settings size14 m5r"/>
                { t('Account settings') }
                { !paymentDataStore.hasUserData && <span className="badge variant-badge--4 m10l ">!</span> }
              </div>
            </Link>
          </li>
          <div className="borderb m5t m5b"/>
          { <li>
            <Link to={ paymentsLink } onClick={ () => this.props.toggleUserMenu() }>
              <span className="icon-wallet"/>
              { t('Payments') }
              { !paymentDataStore.hasPaymentData && <span className="badge red m10l">!</span> }
            </Link>
          </li> }

          <PPGLite role={ PPGLiteRole.HIDE }>
            { <li>
              <Link to={ ProjectRoutesTypes.USER_ACCESS_MANAGER } onClick={ () => this.props.toggleUserMenu() }>
                <span className="icon-access"/>
                <span>{ t('Access manager') }</span>
              </Link>
            </li> }
          </PPGLite>

          { partnershipStore.partnership.enabled && <li>
            <NavLink to={ ProjectRoutesTypes.USER_PARTNERSHIP } activeClassName="active" onClick={ () => this.props.toggleUserMenu() }>
              <span className="icon-handshake"/>
              <span>{ t('Partnership') }</span>
            </NavLink>
          </li> }

          {/*todo @Basia - comment if sth goes wrong */}
          <li><div className="borderb m5t m5b"/>
            <NavLink to={ ProjectRoutesTypes.ORGANIZATION_DASHBOARD_SUBSCRIBERS } activeClassName="active" onClick={ () => this.props.toggleUserMenu() }>
              <span className="icon-stat-lined"/>
              <span>{ t('Organization analytics') }</span>
            </NavLink>
          </li>

          <div className="borderb m5t m5b"/>
          <PPGLite role={ PPGLiteRole.HIDE }>
            <li>
              <Link to={ ProjectRoutesTypes.PROJECT_NEW } onClick={ () => this.props.toggleUserMenu() }>
                <span className="icon icon-plus-badge"/>
                <span>{ t('New project') }</span>
              </Link>
              <div className="borderb m5t"/>
            </li>
          </PPGLite>
          <li>
          <span onClick={ () => this.handleLogoutClick() }>
            <span className="icon-logout-badge"/>
            <span>{ t('Logout') }</span>
          </span>
          </li>
        </ul>
      </div>
    );
  }
}
