import { observer } from 'mobx-react';
import { ResponsiveLine } from '@nivo/line';
import * as React from 'react';
import { DEFAULT_LINE_CHART_PROPERTIES } from './defaultValues';
import { LineChartTooltip } from '../Tooltips/LineChartTooltip/LineChartTooltip';

export type LineChartData = {
  id: string,
  color?: string,
  data: {
    x: string,
    y: number,
  }[]
}[];

export interface ILineChartProps {
  data: LineChartData;
  axisLeft?: object;
  customMargin?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  }
  legends?: Record<string, string | number | boolean>[];
  valueInPercent?: boolean;
  customY?: {
    min: number;
    max: number;
  }
}

export const LineChart = observer(({
                                     data,
                                     axisLeft: customAxisLeft,
                                     legends: customLegends,
                                     valueInPercent,
                                     customY,
                                     customMargin,
                                   }: ILineChartProps) => {
    const {
      margin,
      xScale,
      yScale,
      minY,
      maxY,
      stacked,
      axisTop,
      axisRight,
      axisBottom,
      axisLeft,
      dotSize,
      dotSizeSmall,
      dotColor,
      dotBorderWidth,
      dotBorderWidthSmall,
      smallDotsDaysAmountLimit,
      smallDotsLinesAmountLimit,
      dotBorderColor,
      enableDotLabel,
      dotLabel,
      dotLabelYOffset,
      animate,
      motionStiffness,
      motionDamping,
      legends,
      colors,
      maxLineAreaItems,
    } = DEFAULT_LINE_CHART_PROPERTIES;

    const showArea = (): boolean => {
      return data.length <= maxLineAreaItems;
    };

    const getMargin = () => {
      return {
        top: customMargin?.top || margin.top,
        right: customMargin?.right || margin.right,
        bottom: customMargin?.bottom || margin.bottom,
        left: customMargin?.left || margin.left,
      }
    }

    const useSmallDots = (): boolean => {
      const overDaysLimit = data[0].data.length > smallDotsDaysAmountLimit;
      const overLinesLimit = data.length > smallDotsLinesAmountLimit;
      return overDaysLimit || overLinesLimit;
    };

    const useTickValues = (): boolean => {
      return data[0].data.length < 10;
    }

    const getColors = (): string[] => {
      if (data.every(d => !!d.color)) {
        return data.map(d => d.color)
      }
      return colors;
    }

    return <ResponsiveLine
      tooltip={ point => <LineChartTooltip { ...point } valueInPercent={ valueInPercent }/> }
      enableArea={ showArea() }
      areaBaselineValue={ customY?.min }
      data={ data }
      colors={ getColors() }
      margin={ getMargin() }
      xScale={ xScale }
      yScale={ { ...yScale, ...customY } }
      minY={ minY }
      maxY={ maxY }
      stacked={ stacked }
      axisTop={ axisTop }
      axisRight={ axisRight }
      axisBottom={ { ...axisBottom, tickValues: useTickValues() ? data[0].data.length : undefined } }
      axisLeft={ customAxisLeft ?? axisLeft }
      dotSize={ useSmallDots() ? dotSizeSmall : dotSize }
      dotBorderWidth={ useSmallDots() ? dotBorderWidthSmall : dotBorderWidth }
      dotColor={ dotColor }
      dotBorderColor={ dotBorderColor }
      enableDotLabel={ enableDotLabel }
      dotLabel={ dotLabel }
      dotLabelYOffset={ dotLabelYOffset }
      animate={ animate }
      motionStiffness={ motionStiffness }
      motionDamping={ motionDamping }
      legends={ customLegends ?? legends }
    />;
  }
);
