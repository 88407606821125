import { UseCase, API } from "@ppg/common";

interface IGetABTestStatistic {
  projectId: string;
  abTestId: string;
}

export interface IStatisticVariant {
  id: number;
  sent: number;
  clicked: number;
  totalClicked: number;
  delivered: number;
  actionPrimary: number;
  actionSecondary: number;
  lost: number;
  ctr: number;
  dsp: number;
  cappedSent: number;
  cappedDelivered: number;
}

export interface IGetABTestStatisticResponse {
  abTestID: string;
  variants: IStatisticVariant[];
  winner: IStatisticVariant;
}

export class GetABTestStatisticUseCase extends UseCase<IGetABTestStatistic, IGetABTestStatisticResponse> {
  protected async execute({ projectId, abTestId }: IGetABTestStatistic) {
    return API.HTTP.get<IGetABTestStatisticResponse>(`statistics/projects/${ projectId }/ab-tests/${ abTestId }`);
  }
}

export const getABTestStatisticUseCase = new GetABTestStatisticUseCase();
