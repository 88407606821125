import * as React from 'react';
import { observer } from 'mobx-react';
import { t } from '../../../../base/helpers';
import { EmptyDataComponent, ISelectOption, LineLoader, Select } from '@ppg/styled';
import { AutomationItem } from './AutomationRankItem';
import './AutomationRank.scss'
import { isArrayEmpty, Property } from '@ppg/common';
import { ChartLoader } from '@ppg/styled';

interface IAutomationRankProps {
  automations: IAutomationRankItem[];
  isLoading?: boolean;
  initialLoader?: boolean;
  showSelect?: boolean;
  selectProperty?: Property;
  selectOptions?: ISelectOption[]
}

export interface IAutomationRankItem {
  automationName: string,
  campaignsNumber: number
  ctr: number;
  delivered: number;
  clicked: number;
  projectName?: string;
}


export const AutomationRank = observer(({ automations, isLoading, initialLoader, selectProperty, showSelect, selectOptions }: IAutomationRankProps) => {
  const showRank = automations.length > 2;

  const renderAutomationRankTitle = (): JSX.Element => {
    if (showRank) {
      return <> <span className="icon-winner-cup"/>
        { t('organization/dashboard/automation::Automation Rank') }
      </>
    }

    return <>
      <span className="icon-stat-stacked"/>
      { t('organization/dashboard/automation::Results of your automation') }
    </>
  }

  const showEmptyComponent = (): boolean => {
    return isArrayEmpty(automations) && !isLoading
  }

  const showInitialLoader = (): boolean => {
    return isLoading && !initialLoader
  }

  if (showEmptyComponent()) {
    return <EmptyDataComponent icon={ 'stat-lined' }
                               content={ t('organization/dashboard/automation::Sorry, we don\'t have data to show for the filters you\'ve chosen') }
                               hideButton
                               isLoading={ isLoading }/>
  }

  if (showInitialLoader()) {
    return <div className="automationsRank__initialLoader well">
      <LineLoader/>
      <ChartLoader/>
    </div>
  }

  return <div className="automationsRank well">
    { isLoading && <LineLoader/> }

    <div className="automationsRank__header">
      <h3 className="automationsRank__title">
        { renderAutomationRankTitle() }
      </h3>
      { showSelect && showRank && <div className={ "automationsRank__select" }>
        <span className={ 'automationsRank__selectLabel' }>{ t('organization/dashboard/automation::Sort by') }</span>
        <Select property={ selectProperty } options={ selectOptions }/>
      </div>
      }
    </div>

    { !isLoading && <table className="automationsRank__table">
      <thead>
      <tr>
        <th/>
        <th>{ t('organization/dashboard/automation::Number of campaigns') }</th>
        <th>{ t('organization/dashboard/automation::CTR') }</th>
        <th>{ t('organization/dashboard/automation::Delivered') }</th>
        <th>{ t('organization/dashboard/automation::Clicked') }</th>
      </tr>
      </thead>
      <tbody>
      { automations.map((automation, idx) => <AutomationItem key={ idx } automation={ automation }/>) }
      </tbody>
    </table> }
  </div>;
});

/**
 * Select automation rank translations
 * t('Clicked')
 * t('CTR')
 * */