export interface ITiming {
  baseTransition: string,
  navTransition: string,
  shortTransition: string,
}

export const timing = {
  baseTransition: '0.4s',
  navTransition: '0.4s',
  shortTransition: '0.3s',
};
