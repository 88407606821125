import { API, UseCase } from "@ppg/common";

interface IGetCountSubscribersRequest {
  projectId: string;
  includedLabels: string[];
  excludedLabels: string[];
  includedStrategy: string;
  excludedStrategy: string;
}

interface IGetCountSubscribersResponse {
  data: number;
}

export class GetCountSubscribersUseCase extends UseCase<IGetCountSubscribersRequest, IGetCountSubscribersResponse> {
  protected async execute({projectId, includedLabels, excludedLabels, includedStrategy, excludedStrategy }: IGetCountSubscribersRequest) {

    let paramsIncludedLabelsList = new URLSearchParams();

    for (let includedLabel of includedLabels) {
      paramsIncludedLabelsList.append("includedLabels", includedLabel)
    }

    let paramsExcludedLabelsList = new URLSearchParams();

    for (let excludedLabel of excludedLabels) {
      paramsExcludedLabelsList.append("excludedLabels", excludedLabel)
    }

    const paramsIncludedLabels = includedLabels.length !== 0 ? paramsIncludedLabelsList.toString() : 'includedLabels='
    const paramsExcludedLabels = excludedLabels.length !== 0 ? paramsExcludedLabelsList.toString() : 'excludedLabels='

    return API.HTTP.get<IGetCountSubscribersResponse>(`core/projects/${ projectId}/segmentation/labels/count?${paramsIncludedLabels}&${paramsExcludedLabels}&includedStrategy=${includedStrategy}&excludedStrategy=${excludedStrategy}`)
  }
}

export const getCountSubscribersUseCase = new GetCountSubscribersUseCase();
