import * as lodash from 'lodash';
import { v1 } from 'uuid';
import { Attribute } from '../../base';
import { IMap } from '../../interfaces';
import { AutomationFlow } from './AutomationFlow';
import { IAutomationFlowStep } from './IAutomationFlowStep';
import { AutomationFlowStepType } from './AutomationFlowStepType';

interface ISymulateCTX {
  sessionData: any;
  customFields: any;
  tags: any;
}

export abstract class AbstractAutomationStep<TPayload> implements IAutomationFlowStep<TPayload> {
  public readonly resolvers: string[];
  public readonly stepId: string = v1();
  public readonly type: AutomationFlowStepType = null;
  public readonly payload: TPayload = {} as TPayload;
  public active: boolean;
  private _attributesMap: IMap<Attribute<any>> = {};
  private _onChangeCallback: Function;
  public parent: AbstractAutomationStep<any>;

  public static AutomationStepTypes: {[k: string]: AutomationFlowStepType} = {
    DELAY: 'delay',
    CONDITION: 'condition',
    SENDPUSH: 'sendPush',
    TAG: 'tag',
    UPDATE: 'update',
    GET: 'get',
    AB: 'ab',
  }
  /**
   * Step simulation for Automation test feature
   * @returns {Promise<any>}
   */
  public static Simulate: (payload: any) => Promise<ISymulateCTX>;

  public constructor(
    private automationFlow: AutomationFlow,
    obj: Partial<IAutomationFlowStep<TPayload>> = {},
  ) {
    this.stepId = obj.stepId || this.stepId;
    this.type = obj.type || this.type;
    this.payload = obj.payload || this.payload;
  }

  /**
   * Updates step's payload, of by mutation payload of given step;
   * @param obj
   */
  public set(obj: Partial<TPayload>): this;
  public set<TKey extends keyof TPayload>(key: TKey, value: TPayload[TKey]): this;
  public set(obj: any, value?: any): this {
    if (lodash.isObject(obj)) {
      lodash.extend(this.payload, obj as Partial<TPayload>);
    } else if (lodash.isString(obj)) {
      (this.payload as any)[obj] = value;
    }

    this.triggerChange();
    return this;
  }

  /**
   * Micro implementation of BBModel on function
   * @param what
   * @param cb
   */
  public on(what: string, cb: Function) {
    if (what === 'change' && cb) {
      this._onChangeCallback = cb;
    }
  }

  public setParent(step: AbstractAutomationStep<any>){
    this.parent = step;
  }

  /**
   * Resolve all parent steps to check if stepType is in the path
   * @param stepType
   * @param recurrenceItem
   */
  public isAfterStep(stepType: AutomationFlowStepType, recurrenceItem?: AbstractAutomationStep<any>) {
    recurrenceItem = recurrenceItem || this.parent;

    if (!recurrenceItem) {
      return false;
    }

    if (recurrenceItem.type === stepType) {
      return true;
    }

    if (recurrenceItem === this.parent) {
      return false;
    }

    return this.isAfterStep(stepType, recurrenceItem.parent);
  }

  /**
   * Micro implementation of BBModel off function
   * @param what
   * @param cb
   */
  public off(what: string) {
    if (what === 'change') {
      this._onChangeCallback = lodash.noop;
    }
  }

  /**
   * Implementation of IAttribute onChangeSetter
   * @param attribute
   */
  public onChangeSetter(attribute: string): (e: React.FormEvent<HTMLInputElement>) => void {
    return (e: React.FormEvent<HTMLInputElement>): void => {
      this.set(attribute as any, e.currentTarget.value as any);
    };
  }

  /**
   * Get value of payload step;
   * @param key
   */
  public get(key: string): any {
    return lodash.result(this.payload as TPayload, key);
  }

  /**
   * Dummy mocks method for Attribute
   */
  public getMessages(attribute: string): any[] {
    return null;
  }

  /**
   * Get attribute as separate object.
   */
  public getAttribute<T>(attribute: string): Attribute<T> {
    if (!this._attributesMap[attribute]) {
      this._attributesMap[attribute] = new Attribute<T>(this as any, attribute, false);
    }
    return this._attributesMap[attribute];
  }
  /**
   * Trigger change on automationFlow model
   */
  public triggerChangeOnParent() {
    this.automationFlow.trigger('change', this.automationFlow, {});
  }

  /**
   * Trigger change on automationFlow model
   */
  public triggerChange() {
    if (this._onChangeCallback) {
      this._onChangeCallback();
    }
  }
  /**
   * Appends step after refered step and link them
   * @param step
   * @param type
   */
  public appendStep(type: AutomationFlowStepType, resolver: string): AbstractAutomationStep<any> {
    const newStepId = v1();
    (this.payload as any)[resolver] = newStepId;
    this.automationFlow.addStep({
      stepId: newStepId,
      type: type,
      payload: {}
    });

    this.triggerChangeOnParent();
    return null;
  }

  /**
   * Remove step with childrens
   */
  public remove(toRemoveIds?: string[]): void {
    const { automationFlow } = this;
    toRemoveIds = [this.stepId].concat(toRemoveIds);

    lodash.each(toRemoveIds.reverse(), stepId => {
      const idx = lodash.findIndex(automationFlow.steps, { stepId });
      if (idx !== -1) automationFlow.steps.splice(idx, 1);
    });

    this.triggerChangeOnParent();
  }

  /**
   * Returns children
   */
  public getChildren(getNested: boolean = false): string[] {
    let childrenIds: string[] = [];

    this.resolvers.forEach((resolver: string) => {
      let stepId;
      stepId = this.payload[resolver];

      if (stepId) {
        childrenIds.push(stepId);
      }

      if (getNested) {
        const step = lodash.find(this.automationFlow.steps, { stepId: stepId });
        if (step) {
          const nested = this.automationFlow.getStep(step.stepId);
          if (nested) {
            childrenIds = childrenIds.concat(nested.getChildren());
          }
        }
      }
    });

    return childrenIds;
  }

  public toObject(): IAutomationFlowStep<TPayload> {
    return {
      stepId: this.stepId,
      type: this.type,
      payload: this.payload,
    };
  }

  public getOrigin() {
    return lodash.find(this.automationFlow.getServerAttribute('steps') as any, { stepId: this.stepId });
  }

  public wasChanged() {
    return !lodash.isEqual(this.getOrigin(), this.toObject());
  }
}
