import { property } from '@ppg/common';
import { action, computed, observable, reaction } from 'mobx';
import { IAutomationItemOptionKeyType, IAutomationRankOptionsType } from '../../../pages/Dashboard/common/interfaces';
import { ISelectOption } from '@ppg/styled';
import { t } from '../../../base/helpers';
import { IAutomationRankItem } from '../../../pages/Dashboard/common';
import { IExternalLoaders } from '../../../pages/Dashboard/common/ChartCard/__types__';
import { ProjectDashboardRelated } from './ProjectDashboardRelated';
import { IAutomationHistogram, IProjectDashboardAutomationStore } from './interfaces';
import {
  getProjectAutomationCampaignsUseCase,
  getProjectAutomationStatsHistogramUseCase,
  getProjectAutomationStatsUseCase,
  getProjectAutomtionRankUseCase,
  IAutomationRankResultItem,
  IAutomationsCampaignsItem,
  IAutomationStats
} from '../../../useCases/statistics/project';

export class ProjectDashboardAutomationStore extends ProjectDashboardRelated implements IProjectDashboardAutomationStore {
  private static AUTOMATION_RANK_LIMIT: number = 10;
  private static REDUCED_ITEMS_PER_PAGE: number = 10;

  // Stats

  @observable
  public automationRankSelect = IAutomationRankOptionsType.NUMBER_OF_CLICKED;

  @observable
  public automationStats = this.defaultAutomationStats;

  @observable
  public activeAutomations = 0;

  @observable
  public isStatsLoading = true;

  @observable
  public isActiveAutomationsLoading: boolean = true;

  @observable
  public automationRank = [];

  // Charts

  @observable
  public initialLoader = false;

  @observable
  public generalAutomationChartData = [];

  @observable
  public automationCtrChartData = [];

  // Tables

  @property()
  public total = 0;

  @property()
  public offset = 0;

  @property()
  public limit = ProjectDashboardAutomationStore.REDUCED_ITEMS_PER_PAGE;

  @observable
  public automationTableData = [];

  reactOnRangeChange = reaction(
    () => this.rangeChangeFactors,
    () => this.clearOffset(),
  );

  reactOnTabChange = reaction(
    () => this.activeTab,
    () => this.clearOffset(),
  );

  @computed
  public get getAutomationRankFilterOptions(): ISelectOption[] {
    return [
      { name: t('CTR'), value: IAutomationRankOptionsType.BEST_CTR },
      { name: t('Clicked'), value: IAutomationRankOptionsType.NUMBER_OF_CLICKED }
    ];
  }

  @computed
  public get automationRankList(): IAutomationRankItem[] {
    return this.automationRank
      .slice(0, ProjectDashboardAutomationStore.AUTOMATION_RANK_LIMIT)
      .sort((a, b) => b[this.automationRankSortKey] - a[this.automationRankSortKey]);
  }

  @computed
  public get automationRankSortKey(): string {
    switch (this.automationRankSelect) {
      case IAutomationRankOptionsType.BEST_CTR:
        return IAutomationItemOptionKeyType.CTR;
      case IAutomationRankOptionsType.NUMBER_OF_CLICKED:
        return IAutomationItemOptionKeyType.CLICKED;
      default:
        return IAutomationItemOptionKeyType.CLICKED;
    }
  }

  @computed
  public get loaders(): IExternalLoaders {
    return {
      isLoading: this.isStatsLoading,
      isInitialLoader: this.initialLoader,
    };
  }

  @action
  public fetchAutomationStatsTableData = async (): Promise<void> => {
    const { timezone, to, from, platform } = this.projectRequestBody;
    const { dashboardAutomationStatisticsHistogram: projectAutomationStatisticsHistogram } = await getProjectAutomationStatsHistogramUseCase.exec({
      offset: this.offset,
      limit: this.limit,
      timezone,
      to,
      from,
      platform
    });

    this.automationTableData = projectAutomationStatisticsHistogram.histogram;
    this.total = projectAutomationStatisticsHistogram.total;
  };

  @action
  public getActiveTriggeredCampaigns = async (): Promise<void> => {
    this.isActiveAutomationsLoading = true;

    const { enabledScenarios } = await getProjectAutomationCampaignsUseCase.exec();
    this.activeAutomations = enabledScenarios;
    this.isActiveAutomationsLoading = false;
  };

  @action
  public fetchAutomationRank = async (): Promise<void> => {
    const { automations } = await getProjectAutomationCampaignsUseCase.exec();
    const parsedAutomations = automations.map(a => ({ automation: a.id, campaigns: a.campaigns }));
    const { from, to, timezone } = this.projectRequestBody;
    const { ranking } = await getProjectAutomtionRankUseCase.exec({
      from,
      to,
      timezone,
      automations: parsedAutomations
    });

    this.automationRank = this.mergeAutomations(automations, ranking);
  };

  /**
   * Responsible for merging automation rank items and project automation campaigns into one object
   * @param automationsCampaigns
   * @param ranking
   */
  @action
  public mergeAutomations = (automationsCampaigns: IAutomationsCampaignsItem[], ranking: IAutomationRankResultItem[]): IAutomationRankItem[] => {
    let mergedAutomations: IAutomationRankItem[] = [];

    for (let rankingItem of ranking) {
      for (let automation of automationsCampaigns) {
        if (automation.id === rankingItem.automation) {
          mergedAutomations.push({
            ctr: rankingItem.ctr,
            clicked: rankingItem.clicked,
            delivered: rankingItem.delivered,
            campaignsNumber: automation.campaigns.length,
            automationName: automation.name,
          });
        }
      }
    }

    return mergedAutomations;
  };

  @action
  public fetchAutomationStats = async (): Promise<void> => {
    this.isStatsLoading = true;
    await this.fetchCampaignStatistics();
    this.isStatsLoading = false;
    this.initialLoader = true;
  };

  @action
  public fetchCampaignStatistics = async (): Promise<void> => {
    const { from, to, timezone, platform } = this.projectRequestBody;
    const { automationStats } = await getProjectAutomationStatsUseCase.exec({
      from,
      timezone,
      to,
      platform
    });

    this.automationStats = {
      ...automationStats,
    };

    this.setAutomationStatsCharts();
  };

  @action
  public setAutomationStatsCharts = (): void => {
    const { from, to } = this.requestDateRange;

    this.setAutomationGeneralStatsChartData(from, to);
    this.setAutomationCtrStatsChartData(from, to);
  };

  @action
  public setAutomationCtrStatsChartData = (from: Date, to: Date): void => {
    this.automationCtrChartData = [this.getAutomationHistogramItem('ctr', t('Ctr'), from, to)];
  };

  @action
  public setAutomationGeneralStatsChartData = (from: Date, to: Date): void => {
    this.generalAutomationChartData = [
      this.getAutomationHistogramItem('clicked', t('Clicked'), from, to),
      this.getAutomationHistogramItem('delivered', t('Delivered'), from, to),
    ];
  };

  /**
   * Responsible for returning specific field from automation stats result
   * @param key
   * @param id - visible data name on chart
   * @param from
   * @param to
   */
  @action
  public getAutomationHistogramItem = (key: string, id: string, from: Date, to: Date): IAutomationHistogram => {
    const data = this.automationStats.automationHistogram.map(histogramItem => {
      return {
        x: histogramItem.day,
        y: histogramItem[key.toLowerCase()]
      };
    });

    return {
      id: id,
      data: this.parseAndAddMissingDays(data, from, to, 'x', { y: 0 }, 'y')
    };
  };

  public get defaultAutomationStats(): IAutomationStats {
    return {
      clicked: 0,
      delivered: 0,
      ctr: 0,
      automationHistogram: []
    };
  }

  @action
  public clearOffset = (): void => {
    this.offset = 0;
  };
}
