import { PropertyHandler } from "@ppg/common";
import { action, computed, observable } from 'mobx';
import { ProjectDashboardStore } from './ProjectDashboardStore';
import { PlatformType } from '../../../useCases/interfaces';
import dayjs from 'dayjs';
import { IDefaultRange } from './interfaces';
import { IRangeFactors } from '../../../pages/Dashboard/common/interfaces';

interface IProjectRequestBody {
  platform: PlatformType;
  to: string;
  from: string;
  timezone: string;
}

export abstract class ProjectDashboardRelated extends PropertyHandler {
  @observable
  private projectDashboardStore: ProjectDashboardStore;

  constructor(projectDashboardStore: ProjectDashboardStore) {
    super();
    this.projectDashboardStore = projectDashboardStore;
  }

  @computed
  public get isPlatformSelected(): boolean {
    return this.projectDashboardStore.isPlatformSelected();
  }

  @computed
  public get rangeChangeFactors(): IRangeFactors {
    return this.projectDashboardStore.rangeChangeFactors;
  }

  @computed
  public get isOnlyMobilePlatformSelected(): boolean {
    return this.projectDashboardStore.mobilePlatformEnabled && !this.projectDashboardStore.webPlatformEnabled;
  }

  @computed
  public get bothPlatformsEnabled(): boolean {
    return this.projectDashboardStore.bothPlatformsEnabled;
  }

  @computed
  public get requestDateRange(): IDefaultRange {
    return {
      from: new Date(this.projectRequestBody.from),
      to: new Date(this.projectRequestBody.to),
    };
  }

  @computed
  public get projectRequestBody(): IProjectRequestBody {
    return {
      platform: this.projectDashboardStore.platformType,
      from: this.projectDashboardStore.dateRangeFrom.toISOString(),
      to: this.projectDashboardStore.dateRangeTo.toISOString(),
      timezone: this.projectDashboardStore.timezone
    };
  }

  @computed
  public get activeTab(): string {
    return this.projectDashboardStore.activeTab;
  }

  @action
  public parseAndAddMissingDays<T>(initData: Array<T>, from: Date, to: Date, dayKey: string, emptyObj: object, valueName: string): Array<T> {
    let result = [];
    for (let date = dayjs(from); date.isBefore(to); date = dayjs(date).add(1, 'days')) {
      let dayDate = date.format('YYYY-MM-DD');
      const dayItem = initData.find(day => dayjs(day[dayKey]).format('YYYY-MM-DD') === dayDate);
      if (!dayItem) {
        result = result.concat({ [dayKey]: dayDate, ...emptyObj });
        continue;
      }
      result = result.concat({ [dayKey]: dayDate, y: valueName ? dayItem[valueName] : dayItem });
    }
    return result;
  }

  protected calculateListRange(from: Date, to: Date, limit: number, offset: number): IDefaultRange {
    const calculatedFrom = dayjs(from).add(offset, 'days').toDate();
    const calculatedTo = dayjs(calculatedFrom).add(limit, 'days').isAfter(to) ? new Date(to) : dayjs(calculatedFrom).add(limit, 'days').toDate();
    return {
      from: calculatedFrom,
      to: calculatedTo,
    };
  }

  @computed
  public get undifferentiatedPlatforms() {
    return this.projectDashboardStore.undifferentiatedPlatforms;
  }
}
