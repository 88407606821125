import { isNotEmpty, property, PropertyHandler } from '@ppg/common';
import { observable, set } from 'mobx';

export interface IPolygon {
  name: string;
  coordinates: [number, number][];
  isEdited?: boolean;
  isSelected?: boolean;
  id?: string;
}

export class Polygon extends PropertyHandler {
  private initialValues: IPolygon;
  public readonly id: string;
  public readonly identity: number;

  @property([isNotEmpty()])
  public readonly name: string;

  @property([isNotEmpty()])
  public readonly coordinates: [number, number][];

  @observable
  public isSelected: boolean;

  @observable
  public isEdited: boolean;

  constructor(polygon: IPolygon) {
    super();
    this.name = polygon.name;
    this.coordinates = polygon.coordinates;
    this.id = polygon.id;
    this.isSelected = polygon.isSelected || false;
    this.isEdited = polygon.isEdited || false;
    this.identity = Math.random();

    this.setInitialValues();
  }

  static createPolygon(polygon?: IPolygon) {
    if (polygon) {
      return new Polygon(polygon)
    } else {
      return new Polygon({
        name: '',
        coordinates: [],
        isSelected: true,
        isEdited: true,
        id: null,
      })
    }
  }

  public static parseCoordinates(polygon: google.maps.Polygon): [number, number][] {
    return polygon
      .getPath()
      .getArray()
      .map(point => [point.lng(), point.lat()]) as [number, number][];
  }

  public static toGooglePath(coordinates: [number, number][]) {
    return coordinates.map(([lng, lat]) => ({ lat, lng }));
  }

  public canShow() {
    return this.isSelected;
  }

  public toGooglePath() {
    return Polygon.toGooglePath(this.coordinates);
  }

  public setIsSelected(isSelected: boolean) {
    this.isSelected = isSelected;
  }

  public toggleEdited() {
    this.isEdited = true;
    this.isSelected = true;
  }

  public restore() {
    this.isEdited = false;
    this.isSelected = false;
    set(this, this.initialValues);
  }

  public getCorners() {
    let lat = [];
    let long = [];
    this.coordinates.map(point => {
      const [longitude, latitude] = point;
      long.push(longitude);
      lat.push(latitude);
    });

    let n = lat.sort()[lat.length - 1];
    let s = lat.sort()[0];
    let e = long.sort()[long.length - 1];
    let w = long.sort()[0];

    return { s,n,w,e };
  }

  public get canSave() {
    return this.getProperty('coordinates').isValid && this.getProperty('name').isValid
  }

  private setInitialValues() {
    this.initialValues = {
      name: this.name,
      coordinates: this.coordinates,
    }
  }

  public toSave() {
    this.setInitialValues();
    return {
      name: this.name,
      coordinates: this.coordinates,
    }
  }

  public get isNew() {
    return !this.id;
  }
}
