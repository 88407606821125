import * as React from 'react';
import dayjs from 'dayjs';
import { Nunjucks } from '../../services';

interface IRenderPreviewProps {
  scope: {};
  fallback: string;
  silent?: boolean;
}

export interface IDateVariables {
  dateFull: string;
  date: string;
  year: string;
  month: string;
  day: string;
}

export class Render extends React.Component<IRenderPreviewProps, {}> {

  static renderString(toRender, scope, silent) {
    return Nunjucks.render(toRender, Render.getExtendedScope(scope), silent);
  }

  static getExtendedScope(scopeToExtend) {
    return {
      title: "Title will be generated after you enter data",
      id: "Id will be generated after save",
      ...this.getDateVariablesScope(),
      ...scopeToExtend || {}
    };
  }

  static getDateVariablesScope(): IDateVariables {
    return {
      dateFull: dayjs().format('YYYY-MM-DD'),
      date: dayjs().format('YYYY-MM'),
      year: dayjs().format('YYYY'),
      month: dayjs().format('MM'),
      day: dayjs().format('DD'),
    }
  }

  private nunjucks() {
    const { children, scope, silent } = this.props;
    return Render.renderString(children, scope, silent)
  }

  public render() {
    return <span>{this.nunjucks()}</span>;
  }
}
