import { API, UseCase } from "@ppg/common";
import { ICampaignId, ICampaignIdResponse } from '../interfaces';

interface IRetryGeolocationPushRequest extends ICampaignId {}
interface IRetryGeolocationPushResponse extends ICampaignIdResponse {}

export class RetryGeolocationPushUseCase extends UseCase<IRetryGeolocationPushRequest, IRetryGeolocationPushResponse> {
  protected async execute({ campaignId }: IRetryGeolocationPushRequest) {
    return API.HTTP.post<IRetryGeolocationPushResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/geolocation/${ campaignId }/retry`);
  }
}

export const retryGeolocationPushUseCase = new RetryGeolocationPushUseCase();
