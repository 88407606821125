import { API, UseCase } from "@ppg/common";
import { ISendPushCampaign, ICampaignIdResponse } from '../interfaces';

interface ISendSegmentedPushRequest extends ISendPushCampaign {}
interface ISendSegmentedPushResponse extends ICampaignIdResponse {}

export class SendSegmentedPushUseCase extends UseCase<ISendSegmentedPushRequest, ISendSegmentedPushResponse> {
  protected async execute({ message, options }: ISendSegmentedPushRequest) {
    return API.HTTP.post<ISendSegmentedPushResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/segmented`, {message, options});
  }
}

export const sendSegmentedPushUseCase = new SendSegmentedPushUseCase();
