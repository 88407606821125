import { API, UseCase } from "@ppg/common";

interface IGetActiveTriggeredCampaignsCountRequest {
  organization: string,
}

export interface IGetActiveTriggeredCampaignsCountResult {
  count: number
}

export class GetActiveTriggeredCampaignsCountUseCase extends UseCase<IGetActiveTriggeredCampaignsCountRequest, IGetActiveTriggeredCampaignsCountResult> {
  protected async execute({ organization }: IGetActiveTriggeredCampaignsCountRequest) {
    return API.HTTP.get<IGetActiveTriggeredCampaignsCountResult>(`statistics/organizations/${ organization }/automations/active`);
  }
}