import { UseCase, API } from '@ppg/common';
import { IGetPlugin, IGetWebPushResult } from '../../interfaces';

export class GetWebPushPluginUseCase extends UseCase<IGetPlugin, IGetWebPushResult> {
  protected async execute({ websiteIntegrationId }: IGetPlugin) {
    return API.HTTP.get<IGetWebPushResult>(`wi/projects/${ API.HTTP.currentProjectId }/website-integrations/${ websiteIntegrationId }/plugins/web-push`);
  }
}

export const getWebPushPluginUseCase = new GetWebPushPluginUseCase();
