import { UserStore } from './user/UserStore';
import { ProjectStore } from './project/ProjectStore';
import { CampaignDayStore } from './CampaignDayStore';
import { CountryStore } from './CountryStore';
import { PaymentDataStore } from './payment/PaymentDataStore';
import { PaymentsStore } from './payment/PaymentsStore';
import { VideoTutorialStore } from './VideoTutorialStore';
import { AccidentStore } from './AccidentStore';
import { AccessManagerStore } from './AccessManagerStore';
import { reaction } from 'mobx';
import { CardsStore } from './payment/CardsStore';
import { OfferStore } from './OfferStore';
import { CappingStore } from './CappingStore';
import { PartnershipStore } from './PartnershipStore';
import { ImageStore } from './project/ImageStore';
import { OrganizationDashboardStore } from './organizationDashboard/OrganizationDashboardStore';
import { ProjectComparisonStore } from './organizationDashboard/ProjectComparisonStore';
import { ProjectDashboardStore } from './project/projectDashboard/ProjectDashboardStore';

class RootStore {
  public userStore: UserStore;
  public projectStore: ProjectStore;
  public accidentStore: AccidentStore;
  public campaignDayStore: CampaignDayStore;
  public countryStore: CountryStore;
  public videoTutorialStore: VideoTutorialStore;
  public accessManagerStore: AccessManagerStore;
  public cappingStore: CappingStore;
  public offerStore: OfferStore;
  public partnershipStore: PartnershipStore;
  public imageStore: ImageStore;
  public organizationDashboardStore: OrganizationDashboardStore;
  public projectComparisonStore: ProjectComparisonStore;
  public projectDashboardStore: ProjectDashboardStore;

  /**
   * Payments
   */
  public paymentDataStore: PaymentDataStore;
  public paymentsStore: PaymentsStore;
  public cardsStore: CardsStore;

  constructor() {
    this.userStore = new UserStore();
    this.projectStore = new ProjectStore();
    this.accidentStore = new AccidentStore();
    this.countryStore = new CountryStore();
    this.videoTutorialStore = new VideoTutorialStore();
    this.accessManagerStore = new AccessManagerStore(this.userStore);
    this.cappingStore = new CappingStore(this.userStore);
    this.offerStore = new OfferStore(this.userStore);
    this.partnershipStore = new PartnershipStore(this.userStore);
    this.imageStore = new ImageStore();
    this.organizationDashboardStore = new OrganizationDashboardStore();
    this.projectDashboardStore = new ProjectDashboardStore();
    /**
     * Payments
     */
    this.paymentDataStore = new PaymentDataStore(this.userStore);
    this.paymentsStore = new PaymentsStore(this.userStore);
    this.cardsStore = new CardsStore(this.userStore);

    reaction(
      () => this.userStore.isAuthorized,
      () => this.projectStore = new ProjectStore()
    );
  }
}

export default new RootStore();
