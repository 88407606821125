import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import './Settings.scss';
import { RouteComponentProps } from 'react-router-dom';
import PPGLogo from '../../../../assets/images/ppg-logo.svg';
import { UserStore } from '../../../../stores/user/UserStore';
import { t } from '../../../../base/helpers';

interface IDeleteUserConfirm extends RouteComponentProps<{ token: string }> {
  userStore?: UserStore;
}

@inject('userStore')
@observer
export class DeleteUserConfirm extends React.Component<IDeleteUserConfirm> {
  @observable public isSuccess: boolean;
  @observable public isLoading: boolean = true;

  public async componentDidMount(): Promise<boolean> {
    const { userStore } = this.props;
    const { token } = this.props.match.params;

    return userStore.settings.secondStepAccountDelete(token)
      .then(() => this.isSuccess = true)
      .catch(() => this.isSuccess = false)
      .finally(() => this.isLoading = false);
  }

  public render() {
    if (this.isLoading) {
      return null;
    }

    return <div className='delete-user-wrapper'>
      <div className='delete-user-background'>
        <div className='delete-user-container'>
          <img className='ppg-logo' src={ PPGLogo } alt='PushPushGo'/>
          <div className='delete-user-panel'>
            {
              this.isSuccess
                ? <>
                  <p className='delete-feedback-message'>{ t('delete-account/confirm::You have successfully deleted your account.') }</p>
                  <svg className="confirmation-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle className="confirmation-icon__circle" cx="26" cy="26" r="25" fill="none"/>
                    <path className="confirmation-icon__tick" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                  </svg>
                </>
                : <>
                  <p className='delete-feedback-message'>{ t('delete-account/confirm::There was a problem with your request') }</p>
                  <span className={ 'icon-cancel-badge delete-error-icon' }/>
                </>
            }
          </div>
        </div>
      </div>
    </div>
  }
}

