import { API, UseCase } from "@ppg/common";

interface IGetLabelCountRequest {
  key: string;
  value: string;
}

export interface IGetLabelCountResponse {
  count: number;
}

export class GetLabelCountUseCase extends UseCase<IGetLabelCountRequest, IGetLabelCountResponse> {
  protected async execute({ key, value }:IGetLabelCountRequest) {
    return API.HTTP.get<IGetLabelCountResponse>(`core/projects/${ API.HTTP.currentProjectId }/segmentation/label/count?key=${ key }&value=${ value }`)
  }
}

export const getLabelCountUseCase = new GetLabelCountUseCase()
