import { action, computed, observable, reaction } from 'mobx';
import { LineChartData } from '../../components/Charts/LineChart/LineChart';
import { ISelectOption } from '@ppg/styled';
import * as UseCase from '../../useCases/statistics/organization';
import { getAutomationsStatisticsHistogramUseCase } from '../../useCases/statistics/organization';
import { t } from '../../base/helpers';
import { IExternalLoaders } from '../../pages/Dashboard/common/ChartCard/__types__';
import { OrganizationDashboardRelated } from './OrganizationDashboardRelated';
import { getAutomationsCampaignsUseCase } from '../../useCases/automation';
import { IAutomationCampaign, IAutomationRankResultItem } from '../../useCases/statistics/organization/automation/GetAutomationRank';
import { IOrganizationAutomationsCampaignsItem } from '../../useCases/automation/GetAutomationsCampaignsUseCase';
import { IAutomationRankItem } from '../../pages/Dashboard/common/AutomationRank/AutomationRank';
import { projectStore } from '..';
import { AUTOMATION_RANK_LIMIT, REDUCED_ITEMS_PER_PAGE } from '../../constants';
import { property } from '@ppg/common';
import { DashboardCampaignType } from '../../pages/Dashboard/common/enums';
import { IAutomationStats } from '../../useCases/statistics/organization/campaigns/GetAutomationsStatisticsUseCase';
import { IAutomationStatisticsHistogramItem } from '../../useCases/statistics/organization/automation/GetAutomationsStatisticsHistogramUseCase';
import { IAutomationItemOptionKeyType, IAutomationRankOptionsType } from '../../pages/Dashboard/common/interfaces';

export class OrganizationDashboardAutomationStore extends OrganizationDashboardRelated {

  /*
   * Stats
   **/

  @observable
  public automationRankSelect: IAutomationRankOptionsType = IAutomationRankOptionsType.NUMBER_OF_CLICKED;

  @observable
  public automationStats: IAutomationStats = this.defaultAutomationStats;

  @observable
  public activeAutomations: number = 0;

  @observable
  public isStatsLoading: boolean = true;

  @observable
  public isActiveAutomationsLoading: boolean = true;

  @observable
  public automationRank: IAutomationRankItem[] = [];

  /*
   * Charts
   **/

  @observable
  public initialLoader: boolean = false;

  @observable
  public generalAutomationChartData: LineChartData = [];

  @observable
  public automationCtrChartData: LineChartData = [];

  /*
   * Tables
   **/

  @property()
  public total: number = 0;

  @property()
  public offset: number = 0;

  @property()
  public limit: number = REDUCED_ITEMS_PER_PAGE;

  @observable
  public automationTableData: IAutomationStatisticsHistogramItem[] = [];

  public setDefaultValues(): void {
    this.automationStats = this.defaultAutomationStats;
  }

  /*
   * Stats
   **/

  reactOnRangeChange = reaction(
    () => this.rangeChangeFactors,
    () => this.clearOffset(),
  );

  reactOnTabChange = reaction(
    () => this.activeTab,
    () => this.clearOffset(),
  );

  @action
  public fetchAutomationStats = async (): Promise<void> => {
    this.isStatsLoading = true;
    await this.fetchCampaignStatistics(
      DashboardCampaignType.TRIGGERED,
      this.timezone
    );
    this.isStatsLoading = false;
    this.initialLoader = true;
  };

  @action
  public getActiveTriggeredCampaigns = async (): Promise<void> => {
    this.isActiveAutomationsLoading = true;
    const { count } = await UseCase.getActiveTriggeredCampaignsCountUseCase.exec({ ...this.organizationRequestBody });
    this.activeAutomations = count;
    this.isActiveAutomationsLoading = false;
  };

  @action
  public fetchCampaignStatistics = async (
    campaignType: DashboardCampaignType,
    timezone: string
  ): Promise<void> => {

    const { automationStats } = await UseCase.getAutomationsStatisticsUseCase.exec({
      ...this.organizationRequestBody,
      timezone: timezone
    });

    this.automationStats = {
      ...automationStats,
    };

    this.setAutomationStatsCharts();
  };

  public get defaultAutomationStats(): IAutomationStats {
    return {
      clicked: 0,
      delivered: 0,
      ctr: 0,
      automationHistogram: []
    };
  }

  /**
   * Responsible for returning specific field from automation stats result
   * @param key
   * @param id - visible data name on chart
   * @param from
   * @param to
   */
  @action
  public getAutomationHistogramItem = (key: string, id: string, from: Date, to: Date) => {
    const data = this.automationStats.automationHistogram.map(histogramItem => {
      return {
        x: histogramItem.day,
        y: histogramItem[key.toLowerCase()]
      };
    });

    return {
      id: id,
      data: this.parseAndAddMissingDays(data, from, to, 'x', { y: 0 }, 'y')
    };
  };

  /*
   * Charts
   **/

  @action
  public setAutomationStatsCharts = (): void => {
    const { from, to } = this.requestDateRange;

    this.setAutomationGeneralStatsChartData(from, to);
    this.setAutomationCtrStatsChartData(from, to);
  };

  @action
  public fetchAutomationStatsTableData = async (): Promise<void> => {
    const { dashboardAutomationStatisticsHistogram } = await getAutomationsStatisticsHistogramUseCase.exec({
      ...this.organizationRequestBody,
      offset: this.offset,
      limit: this.limit,
      timezone: this.timezone,
    });

    this.automationTableData = dashboardAutomationStatisticsHistogram.histogram;
    this.total = dashboardAutomationStatisticsHistogram.total;
  };

  @action
  public setAutomationGeneralStatsChartData = (from: Date, to: Date): void => {
    this.generalAutomationChartData = [
      this.getAutomationHistogramItem('clicked', t('Clicked'), from, to),
      this.getAutomationHistogramItem('delivered', t('Delivered'), from, to),
    ];
  };

  @action
  public setAutomationCtrStatsChartData = (from: Date, to: Date): void => {
    this.automationCtrChartData = [this.getAutomationHistogramItem('ctr', t('Ctr'), from, to)];
  };

  /*
   * Automation Rank
   **/

  @action
  public fetchAutomationRank = async (): Promise<void> => {
    const { organizationAutomationsCampaigns } = await getAutomationsCampaignsUseCase.exec({ ...this.organizationRequestBody });

    const { ranking } = await UseCase.getAutomationRankUseCase.exec({
      ...this.organizationRequestBody,
      automations: organizationAutomationsCampaigns
    });

    this.automationRank = this.mergeAutomations(organizationAutomationsCampaigns, ranking);
  };

  @computed
  public get automationRankSortKey(): string {
    switch (this.automationRankSelect) {
      case IAutomationRankOptionsType.BEST_CTR:
        return IAutomationItemOptionKeyType.CTR;
      case IAutomationRankOptionsType.NUMBER_OF_CLICKED:
        return IAutomationItemOptionKeyType.CLICKED;
      default:
        return IAutomationItemOptionKeyType.CLICKED;
    }
  }

  @computed
  public get automationRankList(): IAutomationRankItem[] {
    return this.automationRank
      .slice(0, AUTOMATION_RANK_LIMIT)
      .sort((a, b) => b[this.automationRankSortKey] - a[this.automationRankSortKey]);
  }

  /**
   * Responsible for merging automation rank items and organization automation campaigns into one object
   * @param organizationAutomationsCampaigns
   * @param ranking
   */
  @action
  public mergeAutomations = (organizationAutomationsCampaigns: IOrganizationAutomationsCampaignsItem[], ranking: IAutomationRankResultItem[]): IAutomationRankItem[] => {
    let mergedAutomations: IAutomationRankItem[] = [];

    for (let rankingItem of ranking) {
      for (let automation of organizationAutomationsCampaigns) {
        if (automation.automation === rankingItem.automation) {
          const project = projectStore.projects.find(project => project.id === automation.project); //Todo: use project related
          if (project) {
            mergedAutomations.push({
              ctr: rankingItem.ctr,
              clicked: rankingItem.clicked,
              delivered: rankingItem.delivered,
              campaignsNumber: automation.campaigns.length,
              automationName: automation.name,
              projectName: project.name,
            });
          }
        }
      }
    }

    return mergedAutomations;
  };

  @action
  public parsedAutomationCampaigns = (organizationAutomationsCampaigns: IOrganizationAutomationsCampaignsItem[]): IAutomationCampaign[] => {
    return organizationAutomationsCampaigns.map(organizationAutomation => {
      return {
        automation: organizationAutomation.automation,
        campaigns: organizationAutomation.campaigns,
      };
    });
  };

  @computed
  public get getAutomationRankFilterOptions(): ISelectOption[] {
    return [
      { name: t('organization/dashboard/automation::CTR'), value: IAutomationRankOptionsType.BEST_CTR },
      { name: t('organization/dashboard/automation::Clicked'), value: IAutomationRankOptionsType.NUMBER_OF_CLICKED }
    ];
  }

  @computed
  public get loaders(): IExternalLoaders {
    return {
      isLoading: this.isStatsLoading,
      isInitialLoader: this.initialLoader,
    };
  }

  @action
  public clearOffset = () => {
    this.offset = 0;
  };
}
