export enum SystemTypes {
  ANDROID = 'android',
  IOS = 'ios',
  HUAWEI = 'hms',
  LINUX = 'linux',
  WINDOWS = 'windows',
  WINDOWS_PHONE = 'windows phone',
  AMIGA_OS = 'Amiga Os',
  CENT_OS = 'centOS',
  CHROMIUM_OS = 'chromium OS',
  FEDORA = 'fedora',
  FIREFOX_OS = 'firefox OS',
  DEBIAN = 'debian',
  MAC = 'mac',
  MAC_OS = 'mac os',
  MINIX = 'minix',
  MINT = 'mint',
  UBUNTU = 'ubuntu',
  WEBOS = 'webOS',
  FREEBSD = 'freebsd',
  GENTOO = 'gentoo',
  OPENBSD = 'openbsd',
  OPENSUSE = 'opensuse',
  PLAYSTATION = 'playstation',
  SOLARIS = 'solaris',
  SYMBIAN = 'symbian',
  TIZEN = 'tizen',

}

export enum BrowserTypes {
  CHROME = 'chrome',
  CHROME_WEBVIEW = 'chrome WebView',
  CHROMIUM = 'chromium',
  CHROMIUM_OS = 'chromium os',
  SAFARI = 'safari',
  MOBILE_SAFARI = 'mobile safari',
  EDGE = 'edge',
  FIREFOX = 'firefox',
  OPERA = 'opera',
  OPERA_MINI = 'opera mini',
  OPERA_MOBI = 'opera mobi',
  MOZILLA = 'mozilla',
  IE = 'ie',
  IE_MOBILE = 'IEMobile',
  SAMSUNG_BROWSER = 'samsung browser',
  ANDROID_BROWSER = 'android browser',
  AVG_SECURE_BROWSER = 'avg secure browser',
  AVAST_SECURE_BROWSER = 'avast secure browser',
  BAIDUBOXAPP = 'baiduboxapp',
  BRAVE = 'brave',
  ICEDRAGON = 'IceDragon',
  ICEWEASEL = 'IceWeasel',
  IRIDIUM = 'iridium',
  LBBROWSER = 'lbbrowser',
  MIUI_BROWSER = 'miui browser',
  MAXTHON = 'maxthon',
  METASR = 'MetaSr',
  MIDORI = 'midori',
  NETFRONT = 'NetFront',
  PUFFIN = 'puffin',
  QQBROWSER = 'QQBrowser',
  QUARK = 'quark',
  QUPZILLA = 'QupZilla',
  SEAMONKEY = 'SeaMonkey',
  SILK = 'silk',
  SLIM = 'slim',
  TIZENBROWSER = 'TizenBrowser',
  UCBROWSER = 'UCBrowser',
  VIVALDI = 'vivaldi',
  WATERFOX = 'waterfox',
  WEBKIT = 'WebKit',
  YANDEX = 'yandex'
}

export enum DeviceTypes {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
  TABLET = 'tablet',
  CONSOLE = 'console',
  SMARTTV = 'smarttv'
}

export enum SystemLabels {
  DEVICE = 'Device',
  BROWSER = 'Browser',
  SYSTEM = 'System',
  LANGUAGE = 'Language'
}

export enum LanguagesTypes {
  af = "Afrikaans",
  ar = "Arabic",
  az = "Azerbaijani",
  be = "Belarusian",
  bg= "Bulgarian",
  bn = "Bengali",
  bs = "Bosnian",
  ca = "Catalan",
  cs = "Czech",
  da = "Danish",
  de= "German",
  el = "Greek",
  en = 'English',
  eo = "Esperanto",
  es = 'Español',
  et = "Estonian",
  eu = "Basque",
  fa = "Persian",
  fi = "Finnish",
  fr = "French",
  gl = "Galician",
  gu = "Gujarati",
  he = "Hebrew",
  hi = "Hindi",
  hr = "Hrvatski",
  hu = "Hungarian",
  id = "Indonesian",
  is = "Icelandic",
  it = "Italiano",
  ja = "Japanese",
  jv = "Javanese",
  ka = "Georgian",
  ko = "Korean",
  lt= "Lithuanian" ,
  lv= "Latvian",
  mk = "Macedonian",
  ml = "Malayalam",
  mr = "Marathi",
  nb = "Norwegian",
  nl = "Dutch",
  no = "Norwegian",
  pl = 'Polski',
  pt = "Português",
  ro = "Română",
  ru = "Russian",
  sk= "Slovak",
  sl = "Slovenski",
  sq = "Albanian",
  sr = "Српски",
  sv = "Swedish",
  th = "Thai",
  tr = "Turkish",
  uk = "Ukrainian",
  ur = "Urdu",
  vi = "Vietnamese",
  zh = "Chinese",
  zz = "Unknown"
}
