import { API, UseCase } from '@ppg/common';
import { ISubscriberDTO } from '../../../modelsMobx/subscriber/interfaces';

interface GetSubscribersRequest  {
  subscriber: string
}

interface GetSubscribersResponse extends ISubscriberDTO {}

export class GetSubscribersUseCase extends UseCase<GetSubscribersRequest, GetSubscribersResponse> {
  protected async execute({ subscriber }: GetSubscribersRequest) {
    return API.HTTP.get<GetSubscribersResponse>(`core/projects/${ API.HTTP.currentProjectId }/subscribers/${subscriber}`);
  }
}

export const getSubscriberUseCase = new GetSubscribersUseCase();
