import { isNotEmpty, isUrlWithProtocol, property, PropertyHandler } from '@ppg/common';

export interface IWebhook {
  enabled: boolean;
  headers: Record<string, string>;
  id: string;
  type: eventTypes;
  url: string;
}

export type eventTypes =
  'subscriber:update'
  | 'subscriber:subscribe'
  | 'subscriber:unsubscribe'
  | 'transaction:message:delivered'
  | 'transaction:message:clicked'
  | 'transaction:message:rejected';

export class Webhook extends PropertyHandler implements IWebhook {

  @property()
  public id: string;

  @property()
  public enabled: boolean;

  @property()
  public type: eventTypes;

  @property()
  public headers: Record<string, string>;

  @property([isUrlWithProtocol(), isNotEmpty()])
  public url: string;

  constructor(webhook: IWebhook) {
    super();

    this.id = webhook.id;
    this.enabled = webhook.enabled;
    this.type = webhook.type;
    this.headers = webhook.headers;
    this.url = webhook.url;
  }

  static createFromWebhookDTO(webhookDTO: IWebhook) {
    return new Webhook({
      id: webhookDTO.id,
      enabled: webhookDTO.enabled,
      type: webhookDTO.type,
      headers: webhookDTO.headers,
      url: webhookDTO.url,
    })
  }

  static createDefaultWebhook() {
    return new Webhook({
      id: null,
      enabled: true,
      type: 'subscriber:update',
      headers: {},
      url: 'https://www.example.com/webhook'
    })
  }

  get hasHeaders() {
    return this.headers && Object.entries(this.headers).length > 0;
  }

  get eventOptions() {
    return [
      {value: 'subscriber:update', name: 'subscriber:update'},
      {value: 'subscriber:subscribe', name: 'subscriber:subscribe'},
      {value: 'subscriber:unsubscribe', name: 'subscriber:unsubscribe'},
      {value: 'transaction:message:delivered', name: 'transaction:message:delivered'},
      {value: 'transaction:message:clicked', name: 'transaction:message:clicked'},
      {value: 'transaction:message:rejected', name: 'transaction:message:rejected'},
      {value: 'transaction:message:error', name: 'transaction:message:error'},
    ];
  }
}
