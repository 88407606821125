import * as React from 'react';
import { t } from '../../base/helpers';
import { inject, observer } from 'mobx-react';
import { SelectorStore } from '../../stores/project/SelectorStore';
import { Button, CopyButton, EmptyDataComponent, Loader, ConfirmIconButton, ButtonTypes } from '@ppg/styled';
import ReactTooltip from 'react-tooltip';
import { WrappedPagination } from '../../assets/wrapped';
import { Selector } from '../../modelsMobx/selector/Selector';

interface ISelectorsProps {
  selectorStore?: SelectorStore;
}

@inject('selectorStore')
@observer
export class SelectorsTable extends React.Component<ISelectorsProps, any> {
  public componentDidUpdate(): void {
    ReactTooltip.rebuild();
  }

  private async handleDeleteSelector(selector: Selector): Promise<void> {
    await this.props.selectorStore.onDeleteConfirm(selector)
  }

  public render() {
    const { selectorStore } = this.props;

    if (selectorStore.isLoading) {
      return <Loader/>;
    }

    return <>
      { selectorStore.selectors.length > 0 ? (
          <table className="selectors-table">
            <thead>
            <tr>
              <th>{ t('Pretty name') }</th>
              <th>{ t('Variable name') }</th>
              <th>{ t('HTML selector / JS Path') }</th>
              <th>{ t('Event') } </th>
              <th>{ t('Type') }</th>
              <th>{ t('Reducer') }</th>
              <th/>
            </tr>
            </thead>
            <tbody>

            { selectorStore.selectors.map((selector, idx) =>
              <tr key={ idx }>
                <td data-label={ t('Pretty name') }>{ selector.prettyName }</td>
                <td data-label={ t('Variable name') }>{ selector.name }</td>
                <td data-label={ t('HTML selector / JS Path') }>{ selector.selector }</td>
                <td data-label={ t('Event') }>{ t(selector.event) } </td>
                <td data-label={ t('Type') }>{ t(selector.valueType) } </td>
                <td data-label={ t('Reducer') }>{ t(selector.correctedReducer) } </td>
                <td className="text-right">
                  <div className="selectors-table__buttons">
                  <ConfirmIconButton icon={ 'trash' }
                                     typeBtn="white"
                                     positionBtn="row"
                                     dataTip={ t('Delete selector') }
                                     dataTipConfirmBtn={ t('Confirm delete selector') }
                                     dataTipCancelBtn={ t('Cancel delete selector') }
                                     onConfirm={ () => this.handleDeleteSelector(selector) }/>

                  <CopyButton toCopy={ selector }
                              dataTipText={ t('Copy') }
                              type={ ButtonTypes.DEFAULT }
                              toastSuccessMessage={ t('Selector copied to the clipboard') }/>
                  <Button isSquare={ true }
                          dataTip={ t('Edit') }
                          icon={ 'pencil' }
                          type={ ButtonTypes.DEFAULT }
                          onClick={ () => selectorStore.onEdit(selector) }/>
                  </div>
                </td>
              </tr>
            ) }
            </tbody>
          </table>
        ) :
        <EmptyDataComponent
          content={ t('You don\'t have any added selectors') }
          buttonContent={ t('new selector') }
          icon={ 'folder' }
          buttonIcon={ 'plus-badge' }
          onClick={ () => selectorStore.onNewSelector() }
        />
      }

      <WrappedPagination total={ selectorStore.getProperty('total') }
                         offsetProperty={ selectorStore.getProperty('offset') }
                         itemsPerPage={ selectorStore.getProperty('perPage') }
                         perPageSelector={ true }
                         infinityScroll={ selectorStore.getInfinity }/>

      <ReactTooltip type="light" effect="solid"/>
    </>;
  }
}
