import { API, UseCase } from '@ppg/common';
import { IInvoice } from '../../modelsMobx/Invoice';

interface IGetUnpaidInvoices {
  organization: string;
  currency: string;
  limit: number;
  offset: number;
}

interface IGetUnpaidInvoicesResult {
  data: IInvoice[];
  metadata: {
    total: number,
  }
}

export class GetUnpaidInvoiceListUseCase extends UseCase<IGetUnpaidInvoices, IGetUnpaidInvoicesResult> {
  protected async execute({ organization, limit, currency, offset }) {
    return API.HTTP.get<IGetUnpaidInvoicesResult>(`acc/organizations/${ organization }/invoices/unpaid/${currency}/?offset=${ offset }&limit=${ limit }`);
  }
}

export const getUnpaidInvoiceListUseCase = new GetUnpaidInvoiceListUseCase();
