import { ABTestState, ActionsUseCases, IABVariant, Label } from '../../modelsMobx';
import { LabelCountStrategy } from '../../modelsMobx/Label';
import { IStatisticVariant } from '../../useCases/statistics/project';
import { IStartExpireTimeValues, ITTLRange, ITTLRangeResponse } from './ICreateABTestStore';
import { ILabelDTO, ISegmentDTO } from '../../useCases/core/campaigns/interfaces';

export interface IABTestReportStore {
  id: string;
  name: string;
  sample: number;
  state: ABTestState;
  testStartDate: Date;
  testEndDate: Date;
  winnerSendDate: Date;
  winnerExpireDate: Date;
  winner: IABVariant;
  policy: string;
  tagsCountStrategy: LabelCountStrategy;
  excludedTagsCountStrategy: LabelCountStrategy;
  polygons: [Number, Number][][];
  variants: IABVariant[];
  selectedVariant: IABVariant;
  labels: Label[];
  availableActions: ActionsUseCases[];
  variantDataType: DataType;
  segment: ISegmentDTO | null;
  includedLabels: ILabelDTO[];
  excludedLabels: ILabelDTO[];

  fetchABTest: ({ abTestId }: IFetchTest) => Promise<void>;
  getABTestReport: () => Promise<void>;
  getTTLRange: (props: ITTLRange) => ITTLRangeResponse;
  setStatisticsFromResponse: ({ variants }: IStatisticsFromResponse) => void;
  chooseWinner: (variantId: string) => Promise<void>;
  setSelectedVariant: (variant: IABVariant) => void;
  isAudienceTargetted: boolean;
  hasLabels: boolean;
  hasPolygons: boolean;
  hasWinner: boolean;
  showSelectWinner: boolean;
  showSortIcons: boolean;
  showReports: boolean;
  getWinner: IABVariant;
  getIncludedLabels: Label[];
  getExcludedLabels: Label[];
  getLabelsFromId: ({ tags, excludedTags }) => Promise<void>;
  adjustWinnerExpireDate: (props: IStartExpireTimeValues) => Date;
}

export enum DataType {
  CLICKED = 'clicked',
  DELIVERED = 'delivered',
  SENT = 'sent',
  DSP = 'dsp',
  CTR = 'ctr',
}

export interface IFetchTest {
  abTestId: string;
}

export interface IStatisticsFromResponse {
  variants: IStatisticVariant[];
  winner: IStatisticVariant;
}

export enum SortDirection {
  ASC = 'ascending',
  DESC = 'descending'
}

export interface IGetSortedVariants {
  direction: SortDirection;
  sortType: DataType;
}
