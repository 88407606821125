import { UseCase, API } from '@ppg/common';
import { IUpdateServiceWorker } from '../../interfaces';

export class UpdateServiceWorkerUseCase extends UseCase<IUpdateServiceWorker, void> {
  protected async execute({ websiteIntegrationId, serviceWorkerPath, serviceWorkerScope }: IUpdateServiceWorker) {
    await API.HTTP.put(`wi/projects/${ API.HTTP.currentProjectId }/website-integrations/${ websiteIntegrationId }/plugins/web-push/service-worker`, { serviceWorkerPath, serviceWorkerScope })
  }
}

export const updateServiceWorkerUseCase = new UpdateServiceWorkerUseCase();
