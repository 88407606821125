import * as React from 'react';
import RenderRoutes from '../../routes/RenderRoutes';
import { authorizationRoutes } from '../../routes/authorizationRoutes';
import { AuthorizationLayout } from './AuthorizationLayout';

export class Authorization extends React.Component {
  public render() {
    return <AuthorizationLayout>
      <RenderRoutes routes={ authorizationRoutes }/>
    </AuthorizationLayout>
  }
}

