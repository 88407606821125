import * as React from 'react';
import { IAbstractFieldProps } from './IAbstractFieldProps';
import * as cs from 'classnames';
import * as lodash from 'lodash';
import { v4 } from 'uuid';

export abstract class AbstractFiled<TProps extends IAbstractFieldProps, TState> extends React.Component<TProps, TState> {
  protected inputId: string = v4();

  protected getClassName(): string {
    const { attribute } = this.props;
    if (attribute.hasErrors()) return 'error';
    if (attribute.hasWarnings()) return 'warning';
    if (attribute.hasInfoMessages()) return 'info';
    return attribute.getValue() && attribute.getValue().length > 0 ? 'success' : '';
  }

  protected renderErrors() {
    const messages = this.props.attribute.getMessages() || [];
    const errorMessages = messages.filter(message => message.type === 'ERROR');

    if (lodash.isEmpty(errorMessages)) return null;

    return (
      <ul className="errors-list">
        {errorMessages.map((msg, key) => <li key={ key }> {msg.message} </li>)}
      </ul>
    )
  }

  protected renderWarnings() {
    const messages = this.props.attribute.getMessages() || [];
    const warningMessages = messages.filter(message => message.type === 'WARNING');

    if (lodash.isEmpty(warningMessages)) return null;

    return (
      <ul className='warnings-list'>
        {warningMessages.map((warning, key) => <li key={key}>{warning.message}</li>)}
      </ul>
    )
  }
}
