import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { redirect, t } from '../../base/helpers';
import { UserStore } from '../../stores/user/UserStore';
import { CheckBox, Input, ShowPasswordButton } from '@ppg/styled';
import { observable } from 'mobx';
import { SaveButtonWrapped } from '../../assets/wrapped';
import { ProjectRoutesTypes } from '../../routes/moduleProjectRoutes';
import { ID_LENGTH } from '../../constants';

interface ILoginProps {
  userStore?: UserStore;
  search: string;
  params: Record<string, string>;
  lite?: boolean;
}

@inject('userStore')
@observer
export class LoginPage extends React.Component<ILoginProps> {
  @observable isPasswordHidden: boolean = true;
  @observable redirectLink: string = '';

  public componentDidMount() {
    const { search } = this.props;
    if (search) {
      this.redirectLink = search;
    }
  }

  public componentWillUnmount(): void {
    this.props.userStore.password.clear();
  }

  private includesId = (search: string) => {
    let result = false;

    if (search.includes('/')) {
      const splitedParts = search.split('/');
      for (let part of splitedParts) {
        if (part.length === ID_LENGTH) result = true;
      }
      return result;
    }
    return result;
  }

  private async handleLoginClick(): Promise<void> {
    const { userStore, search } = this.props;

    return userStore.authorization
      .login()
      .finally(() => {
        const searchValue = this.redirectLink || search;
        const searchParams = new URLSearchParams(searchValue);

        let redirectUrl: string = searchParams.has('redirect') && !this.includesId(searchValue)
          ? searchParams.get('redirect')
          : ProjectRoutesTypes.DASHBOARD;

        return userStore.isAuthorized && redirect(redirectUrl)
      });
  }

  private toggleShowPassword(e): void {
    e.preventDefault();
    this.isPasswordHidden = !this.isPasswordHidden;
  }

  public render() {
    const { userStore, lite } = this.props;

    const registerLink = lite ? '/lite/register' : '/register';

    return (
      <div className="login-page-wrapper" data-cy={'login-page--wrapper'}>
        <h1>{ t('Welcome back!') }</h1>
        <form className="auth-form app-form-region">

          <Input
            id="t-login-username"
            label={ t("E-mail") }
            autoFocus
            dataCy={ 'login-input--email' }
            property={ userStore.user.getProperty('username') }
          />

          <div className="password-input-wrapper">
            <Input
              id="t-login-password"
              type={ this.isPasswordHidden ? 'password' : 'text' }
              dataCy={ 'login-input--password' }
              label={ t("Password") }
              property={ userStore.password.getProperty('password') }
            />

            <ShowPasswordButton hidePassword={ this.isPasswordHidden }
                                onClickHandler={ this.toggleShowPassword.bind(this) }/>
          </div>

          <CheckBox
            property={ userStore.authorization.getProperty('remember') }
            dataCy={'login-checkbox--remember'}
            label={ t('Remember me for one week') }
          />

          <div className="bottom-actions">

            <SaveButtonWrapped content={ t('Login') }
                               preventToast
                               dataCy={'login-button--submit'}
                               className="button-primary app-login-submit"
                               callback={ () => this.handleLoginClick() }/>

            <div className="app-remind">
              <Link to="/reset-password">{ t('Forgot password?') }</Link>
            </div>

          </div>

        </form>
        <div className="switch-to-register">
              <span className="app-register">
                { t('Don’t have an account yet?') }
                <Link to={ registerLink } className="app-register-button">{ t('Register') }</Link>
              </span>
        </div>
      </div>
    );
  }

}
