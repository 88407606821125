export class Utils {
  public static async executeWithLoader<T = any>(
    callback: () => Promise<T>,
    onStart: () => void,
    onFinish: () => void,
    onError: (err: Error) => void
  ): Promise<T> {
    onStart()
    let res: T;
    try {
      res = await callback();
    } catch (err) {
      onError(err)
      return
    }
    return Promise.resolve(res).finally(() => onFinish());
  }
}
