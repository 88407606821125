import { IProjectRelated } from "../ProjectRelatedStore";
import {
  IABVariant,
  Image,
  ImageType,
  Label,
  Polygon,
  Utm,
} from "../../modelsMobx";
import { LabelCountStrategy } from "../../modelsMobx/Label";
import { SegmentationStore } from "./SegmentationStore";
import { ICreateABResponse } from "../../useCases/core";
import { ReactText } from "react";
import { ISegmentDTO } from "../../useCases/core/campaigns/interfaces";

export interface ISetMetadataImage {
  url: string;
  type: ImageType;
}

export interface IStartABTest {
  isDirectionRtl: boolean;
  requireInteraction: boolean;
  pushActions: {
    title: string;
    link: string;
  }[];
  isDefaultTitleEnabled: boolean;
  defaultTitle: string;
  utm: Utm;
  projectLogo: Image | null;
}

export enum ABWinnerSelection {
  AUTO = "auto",
  MANUAL = "manual",
}

export enum ABSendType {
  NOW = "now",
  SCHEDULE = "schedule",
}

export interface IStartExpireTimeValues {
  expireTime: number;
  isExpireTimeEnabled: boolean;
}

export interface ICreateABTest extends IStartExpireTimeValues {
  migrateToSegmentation: boolean;
}

export interface ITTLRange {
  projectMaxTTL: number;
  date: Date;
  minTTL?: number;
}

export interface ITTLRangeResponse {
  from: Date;
  to: Date;
  maxTTL?: number;
}

export interface ITogglePolygon {
  polygon: Polygon;
}

export interface IToggleLabel {
  label: Label;
  isIncluded: boolean;
}

export interface IAcceptWinner extends IStartExpireTimeValues {
  ABTestId: string;
}

export interface IGetLabels {
  tags: string[];
  excludedTags: string[];
}

export interface IStartValues {
  labels?: Label[];
  includedStrategy?: LabelCountStrategy;
  excludedStrategy?: LabelCountStrategy;
}

export enum TargetType {
  SEGMENT = "segment",
  POLYGONS = "polygons",
  LABELS = "labels",
}

export interface ICreateABTestStore extends IProjectRelated {
  id: string;
  name: string;
  sample: number;

  variants: IABVariant[];
  selectedVariant: IABVariant;

  sendType: ABSendType;
  startTestDate: Date;
  endTestDate: Date;

  winnerExpireDate: Date;
  winnerSendDate: Date;

  defaultVariantsNames: string[];
  polygons: [Number, Number][][];
  selectedPolygons: [Number, Number][][];
  labelStrategy: LabelCountStrategy;
  excludedLabelStrategy: LabelCountStrategy;
  tags: string[];
  excludedTags: string[];
  labels: Label[];

  segment: ISegmentDTO | null;
  includedLabels: Label[];
  excludedLabels: Label[];
  labelsStrategy: LabelCountStrategy;
  excludedLabelsStrategy: LabelCountStrategy;

  winnerSelection: ABWinnerSelection;

  segmentationLabels: SegmentationStore;

  isStartTestDateValid: boolean;

  setMetadataForCurrentVariant: () => Promise<void>;
  setImageFromMetadata: (props: ISetMetadataImage) => Promise<Image>;
  createABTest: (migrateToSegmentation: boolean) => Promise<ICreateABResponse>;
  setStartABTestValues: (props: IStartABTest) => void;
  setScheduleDefaultDates: (props: IStartExpireTimeValues) => void;
  acceptABTest: (props: IAcceptWinner) => Promise<void>;
  acceptAutoWinner: (props: IAcceptWinner) => Promise<void>;
  acceptManualWinner: (ABTestId: string) => Promise<void>;
  getTestDate: (date: Date) => Date;
  calculateWinnerExpireDate: (expireTime: number) => Date;
  getWinnerExpireDate: (props: IStartExpireTimeValues) => Date;
  createAndAcceptABTest: (props: IStartExpireTimeValues) => Promise<void>;
  updateDraftAndAcceptABTest: (props: IStartExpireTimeValues) => Promise<void>;
  canAddVariant: boolean;
  hasSelectedVariant: boolean;
  hasVariants: boolean;
  setSelectedVariant: (variant: IABVariant) => void;
  getStartVariants: () => void;
  deleteVariant: () => void;
  addVariant: () => void;
  getStartExpireTimeValues: (props: IStartExpireTimeValues) => number;
  adjustEndTestDate: () => Date;
  setWinnerSendSameAsTestEnd: () => void;
  syncSelectedPolygons: () => void;
  clearSelectedPolygons: () => void;
  onConfirmSync: () => void;
  adjustWinnerExpireDate: () => Date;
  changeSendType: (type: ABSendType) => void;
  isTestScheduled: boolean;
  isSendTypeNow: boolean;
  togglePolygon: (props: ITogglePolygon) => void;
  toggleSelectedPolygon: (props: ITogglePolygon) => void;
  syncTags: () => Promise<void>;
  hasPolygons: boolean;
  hasLabels: boolean;
  isAudienceTargetted: boolean;
  isWinnerSelectionManual: boolean;
  isWinnerSelectionAuto: boolean;
  isStartDateValid: () => boolean | ReactText;
  areVariantsValid: () => boolean | ReactText;
  syncPolygonStates: (props: Polygon[]) => void;
  syncSelectedPolygonStates: (props: Polygon[]) => void;
}
