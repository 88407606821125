import { API, UseCase } from '@ppg/common';
import { Image } from '../../../modelsMobx/Image';

export interface UploadImageUseCasePayload {
  formData: FormData;
}

export class UploadImageUseCase extends UseCase<UploadImageUseCasePayload, Image> {
  protected async execute(payload: UploadImageUseCasePayload): Promise<Image> {
    const result = await API.HTTP.post<Image>(`core/projects/${ API.HTTP.currentProjectId }/images`, payload.formData);

    return new Image(result);
  }
}

export const uploadImageUseCase = new UploadImageUseCase();
