import { API, UseCase } from '@ppg/common';

interface ListApiKeyRequest {
  organization: string;
  offset: number,
  limit: number
}

interface ListApiKeyResult {
  data: IApiKey[];
  metadata: {
    total: number
  }
}

export interface IApiKey {
  expiredAt: string,
  projects: string[],
  token: string,
}

export class ListApiKeyUseCase extends UseCase<ListApiKeyRequest, ListApiKeyResult> {
  protected async execute({organization, offset, limit}: ListApiKeyRequest) {
    return API.HTTP.get<ListApiKeyResult>(`aai/organizations/${organization}/api-keys/?offset=${ offset }&limit=${ limit }`);
  }
}

export const listApiKeyUseCase = new ListApiKeyUseCase();
