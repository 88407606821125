import { API, UseCase } from '@ppg/common';
import { getPlatformTypeParam, getRangeDateParam, getTimezoneParam } from '../../../helpers';
import { IOrganizationDashboardRequestBody } from '../../../interfaces';

export interface IProjectsActivityItem {
  project: string;
  subscribed: number;
  unsubscribed: number;
}

export interface ISubscribersActivity {
  subscribedSum: number;
  unsubscribedSum: number;
  projectsActivity: IProjectsActivityItem[]
}

export interface IGetSubscribedComparatorResult {
  subscribersActivity: ISubscribersActivity
}

export class GetSubscribedComparatorUseCase extends UseCase<IOrganizationDashboardRequestBody, IGetSubscribedComparatorResult> {
  protected async execute({ organization, platform, from, to, timezone }: IOrganizationDashboardRequestBody) {
    const endpoint = `statistics/organizations/${ organization }/subscribers/comparator/activity`
      + getPlatformTypeParam(platform) + getRangeDateParam(from, to) + getTimezoneParam(timezone);

    return API.HTTP.get<IGetSubscribedComparatorResult>(endpoint);
  }
}

