import { API, UseCase } from '@ppg/common';
import { getListQueryParam, getPlatformTypeParam, getRangeDateParam, getTimezoneParam } from '../../../helpers';
import { IOrganizationDashboardRequestBody } from '../../../interfaces';

interface GetSubscriberComparatorHistogramRequest extends IOrganizationDashboardRequestBody {
  limit?: number;
  offset?: number;
}

export interface SubscribersActivityStatisticsByDay {
  subscribersActivityStatistics: {
    subscribed: SubscribersActivityStatisticsDayData[];
    unsubscribed: SubscribersActivityStatisticsDayData[];
  }
}

interface SubscribersActivityStatisticsDayData {
  day: string;
  data: DayData[];
}

interface DayData {
  project: string;
  value: number;
}

export class GetSubscriberComparatorTableDataUseCase extends UseCase<GetSubscriberComparatorHistogramRequest, SubscribersActivityStatisticsByDay> {
  protected async execute({
                            organization,
                            platform,
                            from,
                            to,
                            timezone,
                            limit,
                            offset
                          }: GetSubscriberComparatorHistogramRequest) {
    let endpoint = `statistics/organizations/${ organization }/subscribers/comparator/activity/histogram`
      + getPlatformTypeParam(platform) + getRangeDateParam(from, to) + getTimezoneParam(timezone) + getListQueryParam(offset, limit)

    return API.HTTP.get<SubscribersActivityStatisticsByDay>(endpoint);
  }
}
