import { ReactText } from "react";
import { maxLength, minLength, property } from "@ppg/common";
import { ProjectRelated } from "../ProjectRelatedStore";
import {
  ABVariant,
  IABVariant,
  Image,
  ImageType,
  Label,
  Polygon,
} from "../../modelsMobx";
import { action, computed, observable } from "mobx";
import {
  acceptABTestWithAutoWinnerUseCase,
  acceptABTestWithManualWinnerUseCase,
  createABTestUseCase,
  getLabelCountUseCase,
  ICreateABResponse,
  IGetABTestResponse,
  readMetaDataUseCase,
  updateABTestUseCase,
} from "../../useCases/core";
import { GeolocationStore } from "./GeolocationStore";
import { LabelCountStrategy, LabelState } from "../../modelsMobx/Label";
import dayjs from "dayjs";
import { imageStore } from "../index";
import { api, t } from "../../base/helpers";
import { CampaignDirectionType } from "../../modelsMobx/PushCampaign";
import { SegmentationStore } from "./SegmentationStore";
import { getResolveIdsToLabels } from "../../useCases/core/label/GetResolveIdsToLabels";
import {
  ABSendType,
  ABWinnerSelection,
  IAcceptWinner,
  ICreateABTest,
  ICreateABTestStore,
  IGetLabels,
  ISetMetadataImage,
  IStartABTest,
  IStartExpireTimeValues,
  IStartValues,
  ITogglePolygon,
  ITTLRangeResponse,
  TargetType,
} from "./ICreateABTestStore";
import { toast } from "@ppg/styled";
import { IStrategyChange } from "../../components/SegmentationLabelsFilter";
import {
  ILabelDTO,
  ISegmentDTO,
} from "../../useCases/core/campaigns/interfaces";

export class CreateABTestStore
  extends ProjectRelated
  implements ICreateABTestStore
{
  @property()
  public id;

  @property()
  public name;
  @property()
  public sample = 10;

  @property([minLength(2), maxLength(10)])
  public variants = [];
  @property()
  public selectedVariant = this.variants[0];

  @property()
  public sendType = ABSendType.NOW;
  @property()
  public startTestDate;
  @property()
  public endTestDate;

  @property()
  public winnerExpireDate = null;
  @property()
  public winnerSendDate = null;

  @property()
  public defaultVariantsNames = [...CreateABTestStore.DEFAULT_VARIANT_NAMES];

  @property()
  public polygons = [];

  @property()
  public selectedPolygons = [];

  @property()
  public tags = [];

  @property()
  public excludedTags = [];

  @property()
  public labels = [];

  @property()
  public labelStrategy = LabelCountStrategy.OR;

  @property()
  public excludedLabelStrategy = LabelCountStrategy.OR;

  @property()
  public includedLabels: Label[] = [];

  @property()
  public excludedLabels: Label[] = [];

  @property()
  public labelsStrategy = LabelCountStrategy.OR;

  @property()
  public excludedLabelsStrategy = LabelCountStrategy.OR;

  @property()
  public segment: ISegmentDTO | null = null;

  @property()
  public selectedSegment: ISegmentDTO | null = null;

  @property()
  public winnerSelection = ABWinnerSelection.AUTO;

  public segmentationLabels;

  @observable
  public isStartTestDateValid = true;

  public static MIN_TTL = 3600;
  public static MAX_TTL = 259200;
  public static MAX_SAMPLE_VALUE = 50;
  public static MIN_SAMPLE_VALUE = 1;
  public static MIN_VARIANTS = 2;
  public static DEFAULT_TEST_DURATION = 900;
  public static TEST_NAME_MAX_LENGTH = 64;
  public static DEFAULT_VARIANT_NAMES = [
    t("Variant A"),
    t("Variant B"),
    t("Variant C"),
    t("Variant D"),
    t("Variant E"),
    t("Variant F"),
    t("Variant G"),
    t("Variant H"),
    t("Variant I"),
    t("Variant J"),
  ];

  @action
  private initializeStore = ({
    labels = [],
    includedStrategy = LabelCountStrategy.OR,
    excludedStrategy = LabelCountStrategy.OR,
  }: IStartValues): void => {
    this.segmentationLabels = new SegmentationStore(
      this.projectId,
      labels,
      includedStrategy,
      excludedStrategy
    );
  };

  @action
  public async setMetadataForCurrentVariant(): Promise<void> {
    const linkWithoutUtm = this.selectedVariant.redirectLink.split("?")[0];
    const data = await readMetaDataUseCase.exec({ url: linkWithoutUtm });
    this.selectedVariant.title =
      this.selectedVariant.title === ""
        ? data["title"]
        : this.selectedVariant.title;
    this.selectedVariant.content =
      this.selectedVariant.content === ""
        ? data["description"]
        : this.selectedVariant.content;

    if (!this.selectedVariant.image) {
      // fetch image only if there was none before
      await this.setImageFromMetadata({
        url: data["image"],
        type: ImageType.PUSH_ICON,
      });
    }
  }

  @action
  public async getDataFromUrl(): Promise<void> {
    const linkWithoutUtm = this.selectedVariant.redirectLink.split("?")[0];
    const data = await readMetaDataUseCase.exec({ url: linkWithoutUtm });
    this.selectedVariant.title = data["title"];
    this.selectedVariant.content = data["description"];

    await this.setImageFromMetadata({
      url: data["image"],
      type: ImageType.PUSH_IMAGE,
    });
  }

  @action
  public setImageFromMetadata = async ({
    url,
    type,
  }: ISetMetadataImage): Promise<Image> => {
    if (!url) return;
    const image = await this.fetchImage(url);
    if (!image) return;

    const uploadedImage = await imageStore.uploadImage({
      blob: await image,
      name: type,
      type: type,
    });

    this.selectedVariant.image = uploadedImage;

    return uploadedImage;
  };

  private fetchImage = async (url: string): Promise<Blob> => {
    try {
      const req = await fetch(api(`/proxy?url=${encodeURIComponent(url)}`));
      if (!req) return;
      return req.blob();
    } catch (error) {
      return;
    }
  };

  public createABTest = async (
    migrateToSegmentation: boolean
  ): Promise<ICreateABResponse> => {
    !migrateToSegmentation && (await this.syncTags());
    const strategyTag = migrateToSegmentation
      ? this.labelsStrategy
      : this.labelStrategy;
    const strategyExcludedTag = migrateToSegmentation
      ? this.excludedLabelsStrategy
      : this.excludedLabelStrategy;

    return createABTestUseCase.exec({
      name: this.name || t("AB Test"),
      variants: this.variants.map((variant) => variant.serialize()),
      sample: Number(this.sample),
      polygons: this.polygons,
      excludedTags: this.excludedTags,
      tags: this.tags,
      senderStrategy: "all",
      tagStrategy: strategyTag,
      excludedTagStrategy: strategyExcludedTag,
      labelsStrategy: this.labelsStrategy,
      excludedLabelsStrategy: this.excludedLabelsStrategy,
      segment: this.segment && this.segment.id,
      labels: this.includedLabels.map((label) => label.serialize()),
      excludedLabels: this.excludedLabels.map((label) => label.serialize()),
    });
  };

  public updateABTest = async (
    migrateToSegmentation: boolean
  ): Promise<void> => {
    !migrateToSegmentation && (await this.syncTags());
    const strategyTag = migrateToSegmentation
      ? this.labelsStrategy
      : this.labelStrategy;
    const strategyExcludedTag = migrateToSegmentation
      ? this.excludedLabelsStrategy
      : this.excludedLabelStrategy;

    await updateABTestUseCase.exec({
      abTestId: this.id,
      excludedTags: this.excludedTags,
      tags: this.tags,
      tagStrategy: strategyTag,
      name: this.name,
      polygons: this.polygons,
      senderStrategy: "all",
      sample: Number(this.sample),
      variants: this.variants.map((variant) => variant.serialize()),
      excludedTagStrategy: strategyExcludedTag,
      labelsStrategy: this.labelsStrategy,
      excludedLabelsStrategy: this.excludedLabelsStrategy,
      segment: this.segment && this.segment.id,
      labels: this.includedLabels.map((label) => label.serialize()),
      excludedLabels: this.excludedLabels.map((label) => label.serialize()),
    });
  };

  @action
  public setStartABTestValues = ({
    isDirectionRtl,
    requireInteraction,
    pushActions,
    isDefaultTitleEnabled,
    defaultTitle,
    utm,
    projectLogo,
  }: IStartABTest): void => {
    this.getStartVariants();
    this.setSelectedVariant(this.variants[0]);

    this.variants.forEach((variant) =>
      variant.setProjectValues({
        redirectLink: this.projectSiteUrl,
        utm,
        isDirectionRtl,
        requireInteraction,
        pushActions,
        isDefaultTitleEnabled,
        defaultTitle,
        projectLogo,
      })
    );
  };

  @action
  public setScheduleDefaultDates = ({
    expireTime,
    isExpireTimeEnabled,
  }: IStartExpireTimeValues): void => {
    this.isStartTestDateValid = true;
    this.startTestDate = dayjs().add(15, "minute").toDate();
    this.adjustEndTestDate();
    this.setWinnerSendSameAsTestEnd();
    // set project's expire time or default 72h
    const expireTimeInHours = this.getStartExpireTimeValues({
      expireTime,
      isExpireTimeEnabled,
    });
    this.winnerExpireDate = dayjs(this.winnerSendDate)
      .add(expireTimeInHours, "hour")
      .toDate();
  };

  @action
  public validateStartTestDate(): void {
    this.isStartTestDateValid = dayjs(this.startTestDate).isSameOrAfter(
      dayjs()
    );
  }

  public isStartDateValid = (): boolean | ReactText => {
    if (!this.isTestScheduled) return true;
    this.validateStartTestDate();

    if (!this.isStartTestDateValid) {
      toast.error(t("Test start date is in the past, please correct it."));
      return false;
    }
    return true;
  };

  @action
  public acceptABTest = async ({
    ABTestId,
    isExpireTimeEnabled,
    expireTime,
  }: IAcceptWinner): Promise<void> => {
    this.winnerSelection === ABWinnerSelection.AUTO
      ? await this.acceptAutoWinner({
          ABTestId,
          expireTime,
          isExpireTimeEnabled,
        })
      : await this.acceptManualWinner(ABTestId);
  };

  @action
  public acceptAutoWinner = async ({
    ABTestId,
    expireTime,
    isExpireTimeEnabled,
  }: IAcceptWinner): Promise<void> => {
    return acceptABTestWithAutoWinnerUseCase.exec({
      abTestId: ABTestId,
      testStartDate: this.isTestScheduled ? this.startTestDate : new Date(),
      testEndDate: this.getTestDate(this.endTestDate),
      winnerStartDate: this.getTestDate(this.winnerSendDate),
      winnerEndDate: this.getWinnerExpireDate({
        expireTime,
        isExpireTimeEnabled,
      }),
    });
  };

  @action
  public acceptManualWinner = async (ABTestId: string): Promise<void> => {
    return acceptABTestWithManualWinnerUseCase.exec({
      abTestId: ABTestId,
      testStartDate: this.isTestScheduled ? this.startTestDate : new Date(),
      testEndDate: this.getTestDate(this.endTestDate),
    });
  };

  public getTestDate = (date: Date): Date => {
    return this.isTestScheduled
      ? date
      : dayjs()
          .add(CreateABTestStore.DEFAULT_TEST_DURATION / 60, "minute")
          .toDate();
  };

  public calculateWinnerExpireDate = (expireTime: number): Date => {
    const endDate = dayjs().add(
      CreateABTestStore.DEFAULT_TEST_DURATION / 60,
      "minute"
    );
    // subtract 1 sec to avoid exceeding time limits (72h)
    return this.isTestScheduled
      ? dayjs(this.winnerExpireDate).subtract(1, "second").toDate()
      : endDate.add(expireTime, "hour").subtract(1, "second").toDate();
  };

  public getWinnerExpireDate = ({
    expireTime,
    isExpireTimeEnabled,
  }: IStartExpireTimeValues): Date => {
    const testExpireTime = this.getStartExpireTimeValues({
      expireTime,
      isExpireTimeEnabled,
    });
    return this.calculateWinnerExpireDate(testExpireTime);
  };

  @action
  public createAndAcceptABTest = async ({
    expireTime,
    isExpireTimeEnabled,
    migrateToSegmentation,
  }: ICreateABTest): Promise<void> => {
    const { id } = await this.createABTest(migrateToSegmentation);
    await this.acceptABTest({ ABTestId: id, expireTime, isExpireTimeEnabled });
  };

  @action
  public updateDraftAndAcceptABTest = async ({
    expireTime,
    isExpireTimeEnabled,
    migrateToSegmentation,
  }: ICreateABTest): Promise<void> => {
    await this.updateABTest(migrateToSegmentation).then(
      async () =>
        await this.acceptABTest({
          ABTestId: this.id,
          expireTime,
          isExpireTimeEnabled,
        })
    );
  };

  @computed
  public get canAddVariant(): boolean {
    return this.variants.length < 10;
  }

  @computed
  public get hasSelectedVariant(): boolean {
    return !!this.selectedVariant;
  }

  @computed
  public get hasVariants(): boolean {
    return this.variants.length > 0;
  }

  @action
  public setSelectedVariant = (variant: IABVariant): void => {
    this.selectedVariant = variant;
  };

  @action
  public getStartVariants(): void {
    this.variants = [this.createDefaultVariant(), this.createDefaultVariant()];
  }

  private createDefaultVariant(): IABVariant {
    return new ABVariant({
      id: null,
      name: this.defaultVariantsNames.shift(),
      title: "",
      content: "",
      icon: null,
      image: null,
      actions: [],
      direction: CampaignDirectionType.AUTO,
      requireInteraction: false,
      redirectLink: "",
    });
  }

  @action
  public deleteVariant = (): void => {
    this.variants = this.variants.filter((v) => {
      if (v.name !== this.selectedVariant.name) {
        return true;
      }
      this.defaultVariantsNames.push(v.name);
      return false;
    });
    this.setSelectedVariant(this.variants[0]);
  };

  @action
  public addVariant = (): void => {
    this.variants.push(this.createDefaultVariant());
    this.setSelectedVariant(this.variants[this.variants.length - 1]);
  };

  @action
  public getStartExpireTimeValues({
    expireTime,
    isExpireTimeEnabled,
  }: IStartExpireTimeValues): number {
    return isExpireTimeEnabled
      ? expireTime
      : CreateABTestStore.MAX_TTL / 60 / 60;
  }

  @action
  public adjustEndTestDate(): Date {
    if (
      this.endTestDate &&
      this.startTestDate &&
      this.endTestDate.getTime() - this.startTestDate.getTime() > 1000 * 3600
    ) {
      return;
    }

    const initialDate = this.startTestDate
      ? dayjs(this.startTestDate)
      : dayjs();

    this.endTestDate = initialDate
      .add(CreateABTestStore.DEFAULT_TEST_DURATION / 60, "minute")
      .toDate();
    return this.endTestDate;
  }

  @action
  public setWinnerSendSameAsTestEnd(): void {
    if (
      this.endTestDate &&
      this.winnerSendDate &&
      this.endTestDate.getDate() < this.winnerSendDate.getDate()
    ) {
      return;
    }
    this.winnerSendDate = this.endTestDate;
  }

  @action
  public adjustWinnerExpireDate(): Date {
    if (
      this.winnerExpireDate &&
      this.winnerSendDate &&
      this.winnerExpireDate.getTime() - this.winnerSendDate.getTime() >
        1000 * 3600
    ) {
      return;
    }

    this.winnerExpireDate = dayjs(this.winnerSendDate)
      .add(CreateABTestStore.MIN_TTL / 60 / 60, "hour")
      .toDate();
    return this.winnerExpireDate;
  }

  @computed
  public get getStartTestDateRange(): ITTLRangeResponse {
    return {
      from: dayjs().toDate(),
      to: dayjs().add(30, "day").toDate(),
    };
  }

  @computed
  public get getEndTestDateRange(): ITTLRangeResponse {
    return {
      from: dayjs(this.startTestDate).add(15, "minute").toDate(),
      to: dayjs(this.startTestDate).add(72, "hour").toDate(),
    };
  }

  @computed
  public get getWinnerSendTTLRange(): ITTLRangeResponse {
    return {
      from: this.endTestDate,
      to: dayjs(this.endTestDate)
        .add(CreateABTestStore.MAX_TTL / 60 / 60, "hour")
        .toDate(),
    };
  }

  @computed
  public get getWinnerExpireTTLRange(): ITTLRangeResponse {
    return {
      from: dayjs(this.winnerSendDate)
        .add(CreateABTestStore.MIN_TTL / 60 / 60, "hour")
        .toDate(),
      to: dayjs(this.winnerSendDate)
        .add(CreateABTestStore.MAX_TTL / 60 / 60, "hour")
        .toDate(),
    };
  }

  @action
  public changeSendType = (type: ABSendType): void => {
    this.sendType = type;
  };

  @computed
  public get isTestScheduled(): boolean {
    return this.sendType === ABSendType.SCHEDULE;
  }

  @computed
  public get isSendTypeNow(): boolean {
    return this.sendType === ABSendType.NOW;
  }

  @computed
  public get isWinnerSelectionManual(): boolean {
    return this.winnerSelection === ABWinnerSelection.MANUAL;
  }

  @computed
  public get isWinnerSelectionAuto(): boolean {
    return this.winnerSelection === ABWinnerSelection.AUTO;
  }

  // target push

  @computed
  public get hasPolygons(): boolean {
    return this.polygons.length > 0;
  }

  @computed
  public get hasLabels(): boolean {
    return (
      this.segmentationLabels.includedLabels.length > 0 ||
      this.segmentationLabels.excludedLabels.length > 0
    );
  }

  @computed
  public get isAudienceTargetted(): boolean {
    return this.hasLabels || this.hasPolygons || this.hasSegment;
  }

  public validOneTargetAudience(targetType: TargetType): boolean {
    switch (targetType) {
      case TargetType.SEGMENT:
        return this.hasLabels || this.hasPolygons;
      case TargetType.LABELS:
        return this.hasSegment || this.hasPolygons;
      case TargetType.POLYGONS:
        return this.hasSegment || this.hasLabels;
    }
  }

  // segment

  @computed
  public get hasSegment(): boolean {
    return !!this.segment;
  }

  @computed
  public get hasSelectedSegment(): boolean {
    return !!this.selectedSegment;
  }

  @action
  public selectSegment(segment: ISegmentDTO): void {
    if (this.segmentIsSelected(segment)) {
      this.selectedSegment = null;
    } else {
      this.selectedSegment = segment;
    }
  }

  public segmentIsSelected(segment: ISegmentDTO): boolean {
    if (!this.selectedSegment) {
      return false;
    }

    return this.selectedSegment.id === segment.id;
  }

  @action
  public deselectSelectedSegment(): void {
    this.selectedSegment = null;
  }

  @action
  public deselectSegment(): void {
    this.segment = null;
  }

  @action
  public onSaveSegment(): void {
    this.segment = this.selectedSegment;
    this.deselectSelectedSegment();
  }

  @action
  public onSetSegment(): void {
    this.selectedSegment = this.segment;
  }

  //polygon

  @action
  public togglePolygon = ({ polygon }: ITogglePolygon): void => {
    const polygonIndex = GeolocationStore.indexOf(
      this.polygons,
      polygon.coordinates
    );
    const isInSet = polygonIndex !== -1;

    if (isInSet) {
      this.polygons.splice(polygonIndex, 1);
      polygon.setIsSelected(false);
    } else {
      this.polygons.push(polygon.coordinates);
      polygon.setIsSelected(true);
    }
  };

  @action
  public toggleSelectedPolygon = ({ polygon }: ITogglePolygon): void => {
    const polygonIndex = GeolocationStore.indexOf(
      this.selectedPolygons,
      polygon.coordinates
    );
    const isInSet = polygonIndex !== -1;
    if (isInSet) {
      this.selectedPolygons.splice(polygonIndex, 1);
      polygon.setIsSelected(false);
    } else {
      this.selectedPolygons.push(polygon.coordinates);
      polygon.setIsSelected(true);
    }
  };

  public syncPolygonStates(polygons: Polygon[]): void {
    polygons.forEach((polygon) => {
      if (
        GeolocationStore.isInCoordinatesSet(this.polygons, polygon.coordinates)
      ) {
        polygon.setIsSelected(true);
      }
    });
  }

  public syncSelectedPolygonStates(polygons: Polygon[]): void {
    polygons.forEach((polygon) => {
      if (
        GeolocationStore.isInCoordinatesSet(
          this.selectedPolygons,
          polygon.coordinates
        )
      ) {
        polygon.setIsSelected(true);
      } else {
        polygon.setIsSelected(false);
      }
    });
  }

  public syncSelectedPolygons = (): void => {
    this.selectedPolygons = this.polygons.map((polygon) =>
      JSON.parse(JSON.stringify(polygon))
    );
  };

  public clearSelectedPolygons = (): void => {
    this.selectedPolygons = [];
  };

  public onConfirmSync = (): void => {
    this.polygons = this.selectedPolygons.map((polygon) =>
      JSON.parse(JSON.stringify(polygon))
    );
    this.clearSelectedPolygons();
  };

  // labels

  @computed
  public get hasIncludedLabels(): boolean {
    return this.includedLabels.length !== 0;
  }

  @computed
  public get hasExcludedLabels(): boolean {
    return this.excludedLabels.length !== 0;
  }

  @action
  public async toggleLabelSelected(label: Label): Promise<void> {
    switch (label.state) {
      case LabelState.INCLUDED:
        this.includedLabels.push(label);
        break;

      case LabelState.EXCLUDED:
        this.excludedLabels.push(label);
        break;

      case LabelState.IDLE:
        if (this.includedLabelExist(label)) {
          this.includedLabels = this.includedLabels.filter(
            (item) => item.getLabelIdentity() !== label.getLabelIdentity()
          );
        } else if (this.excludedLabelExist(label)) {
          this.excludedLabels = this.excludedLabels.filter(
            (item) => item.getLabelIdentity() !== label.getLabelIdentity()
          );
        }
        break;
    }
  }

  private includedLabelExist(label: Label): boolean {
    return !!this.includedLabels.find(
      (actualLabel) =>
        actualLabel.getLabelIdentity() === label.getLabelIdentity()
    );
  }

  private excludedLabelExist(label: Label): boolean {
    return !!this.excludedLabels.find(
      (actualLabel) =>
        actualLabel.getLabelIdentity() === label.getLabelIdentity()
    );
  }

  @action
  public async onStrategyChange(strategy: IStrategyChange): Promise<void> {
    this.labelsStrategy = strategy.includedStrategy;
    this.excludedLabelsStrategy = strategy.excludedStrategy;
  }

  public async onLabelRemoveSelected(label: Label): Promise<void> {
    label.setState(LabelState.IDLE);
    await this.toggleLabelSelected(label);
  }

  @action
  public syncTags = async (): Promise<void> => {
    for (let label of this.segmentationLabels.includedLabels) {
      await label.resolveLegacyId();
      this.tags.push(label.getLegacyId());
    }

    for (let label of this.segmentationLabels.excludedLabels) {
      await label.resolveLegacyId();
      this.excludedTags.push(label.getLegacyId());
    }

    this.labelStrategy = this.segmentationLabels
      .includedStrategy as unknown as LabelCountStrategy;
    this.excludedLabelStrategy = this.segmentationLabels
      .excludedStrategy as unknown as LabelCountStrategy;
  };

  // edit ab test

  @action
  public setFetchedTestValues = async (
    abTest: IGetABTestResponse,
    migrateToSegmentation: boolean
  ): Promise<void> => {
    const { id, name, sample, variants, queryCriteria } = abTest;
    const {
      polygon,
      tagsCountStrategy,
      excludedTagsCountStrategy,
      segment,
      tags,
      excludedTags,
      labelsStrategy,
      excludedLabelsStrategy,
      labels,
      excludedLabels,
    } = queryCriteria;
    this.id = id;
    this.name = name;
    this.sample = sample;

    this.variants = variants.map(
      (variant) =>
        new ABVariant({
          name: this.defaultVariantsNames.shift(),
          ...variant,
        })
    );
    this.selectedVariant = this.variants[0];

    migrateToSegmentation
      ? await this.setPolygons(polygon)
      : this.setPolygonsNoMigrate(polygon);

    const setSegment = migrateToSegmentation && segment;
    setSegment && this.setSegment(segment);

    this.labelStrategy = tagsCountStrategy;
    this.excludedLabelStrategy = excludedTagsCountStrategy;
    this.labelsStrategy = labelsStrategy;
    this.excludedLabelsStrategy = excludedLabelsStrategy;

    await this.setLabels(
      labels.labels,
      excludedLabels.labels,
      tags,
      excludedTags,
      migrateToSegmentation
    );

    this.initializeStore({
      labels: this.labels,
      includedStrategy: this.labelStrategy,
      excludedStrategy: this.excludedLabelStrategy,
    });
  };

  @action
  private setSegment(segment: ISegmentDTO): void {
    if (segment.exists) {
      this.segment = segment;
    } else {
      toast.error(
        t(
          `Test included segment named ${segment.name}, which not exists, please choose new segment`
        )
      );
    }
  }

  @action
  private setPolygonsNoMigrate(polygons: [Number, Number][][]): void {
    this.polygons = polygons || [];
  }

  @action
  private async setPolygons(polygons: [Number, Number][][]): Promise<void> {
    const existPolygons = this.fetchPolygonsByCoordinates(polygons);
    if (polygons.length !== existPolygons.length) {
      toast.error(
        t(
          `Campaign included polygon, which not exists, please choose new polygon`
        )
      );
    }

    this.polygons = existPolygons.length !== 0 ? existPolygons : [];
  }

  private fetchPolygonsByCoordinates(
    polygons: [Number, Number][][]
  ): [Number, Number][][] {
    const fetchedPolygons = this.projectStore.geolocationStore.allPolygons;
    const results: Polygon[] = [];

    for (let fetchedPolygon of fetchedPolygons) {
      if (
        GeolocationStore.isInCoordinatesSet(
          polygons,
          fetchedPolygon.coordinates
        )
      ) {
        fetchedPolygon.setIsSelected(true);
        results.push(fetchedPolygon);
      }
    }

    return results.map((polygon) => polygon.coordinates);
  }

  private async deserializeLabels(
    labels: ILabelDTO[],
    labelState: LabelState
  ): Promise<void> {
    let labelsNotExists: string[] = [];

    for (let label of labels) {
      if (label.exists) {
        const editLabel: Label = Label.createLabel(label);
        editLabel.setState(labelState);

        const { count } = await getLabelCountUseCase.exec({
          key: editLabel.key,
          value: `${editLabel.value}`,
        });
        editLabel.approxCount = count;

        this.labels.push(editLabel);
        labelState === LabelState.INCLUDED
          ? this.includedLabels.push(editLabel)
          : this.excludedLabels.push(editLabel);
      } else {
        labelsNotExists.push(label.label);
      }
    }

    for (let labelNotExist of labelsNotExists) {
      toast.error(
        t(
          `Test included label named ${labelNotExist}, which not exists, please choose new label`
        )
      );
    }
  }

  @action
  private async setLabels(
    includedLabels: ILabelDTO[],
    excludedLabels: ILabelDTO[],
    tags: string[],
    excludedTags: string[],
    migrateToSegmentation: boolean
  ): Promise<void> {
    if (migrateToSegmentation) {
      await this.deserializeLabels(includedLabels, LabelState.INCLUDED);
      await this.deserializeLabels(excludedLabels, LabelState.EXCLUDED);
    } else {
      await this.getLabelsFromId({ tags: tags, excludedTags: excludedTags });
    }
  }

  @action
  public getLabelsFromId = async ({
    tags,
    excludedTags,
  }: IGetLabels): Promise<void> => {
    const included = await getResolveIdsToLabels.exec({ ids: tags });

    for (const label of included) {
      await label.resolveLegacyId();
      label.toggleIncluded();
      this.labels.push(label);
      this.includedLabels.push(label);
    }

    const excluded = await getResolveIdsToLabels.exec({ ids: excludedTags });

    for (const label of excluded) {
      await label.resolveLegacyId();
      label.toggleExcluded();
      this.labels.push(label);
      this.excludedLabels.push(label);
    }
  };

  @action
  public resetID(): void {
    this.id = null;
  }

  public areVariantsValid = (): boolean | ReactText => {
    // property validation not working properly but we validate variants to mark inputs with red border
    this.variants.forEach((variant) => variant.validate());

    if (
      this.variants.some(
        (variant) => !variant.isTitleValid || !variant.isContentValid
      )
    ) {
      toast.error(t("Some of your variants are missing title and/or message."));
      return false;
    }
    return true;
  };

  public setDefaultValues(): void {
    this.resetID();
    this.name = "";
    this.sample = 10;

    this.variants = [];
    this.selectedVariant = this.variants[0];

    this.sendType = ABSendType.NOW;
    this.startTestDate = null;
    this.endTestDate = null;

    this.winnerExpireDate = null;
    this.winnerSendDate = null;
    this.defaultVariantsNames = [...CreateABTestStore.DEFAULT_VARIANT_NAMES];

    this.polygons = [];
    this.clearSelectedPolygons();
    this.labelStrategy = LabelCountStrategy.OR;
    this.excludedLabelStrategy = LabelCountStrategy.OR;

    this.winnerSelection = ABWinnerSelection.AUTO;

    this.tags = [];
    this.excludedTags = [];
    this.labels = [];
    this.segment = null;
    this.includedLabels = [];
    this.excludedLabels = [];
    this.labelsStrategy = LabelCountStrategy.OR;
    this.excludedLabelsStrategy = LabelCountStrategy.OR;

    this.isStartTestDateValid = true;
    this.initializeStore({});
  }
}
