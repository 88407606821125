import { API, UseCase } from "@ppg/common";
import { IGetAppleDataResponse, IProvider } from '../interfaces';

interface ICreateSafariIntegration extends IProvider {
  name: string;
  siteUrl: string;
  passphrase: string;
  p12: string;
}

export class CreateSafariIntegrationUseCase extends UseCase<ICreateSafariIntegration, IGetAppleDataResponse> {
  protected async execute({ projectId, organizationId, passphrase, siteUrl, name, p12 }: ICreateSafariIntegration) {
    return API.HTTP.post<IGetAppleDataResponse>(`core/organizations/${ organizationId }/projects/${ projectId }/providers/safari`, {
      passphrase, p12, siteUrl, name
    });
  }
}
 
export const createSafariIntegrationUseCase = new CreateSafariIntegrationUseCase();

