import { inject, observer } from 'mobx-react';
import * as React from 'react';
import './Payments.scss'
import { t } from '../../base/helpers';
import { Button, Input, ShowPasswordButton, toast } from '@ppg/styled';
import { ChoosePlanModal } from '../../components/ChoosePlanModal/ChoosePlanModal';
import { observable } from 'mobx';
import { OfferStore } from '../../stores/OfferStore';
import { UserStore } from '../../stores/user/UserStore';
import { UserHeader } from '../User/Layouts/UserLayout/UserHeader/UserLayoutHeader';

interface IPlanDetailsProps {
  offerStore?: OfferStore
  userStore?: UserStore
}

@inject('offerStore', 'userStore')
@observer
export class PlanDetails extends React.Component<IPlanDetailsProps,{}> {
  @observable isCurrentPasswordHidden: boolean = true;
  @observable isPlansModalOpen: boolean = false;
  @observable isLoading: boolean = true;

  componentDidMount() {
    this.props.offerStore.fetchCurrentAndNextPlans()
      .then(() => this.props.offerStore.fetchNextOfferPlan())
      .then(() => this.isLoading = false);
  }

  componentWillUnmount() {
    this.props.offerStore.clearNextPlan();
  }

  private toggleCurrentPassword():void  {
    this.isCurrentPasswordHidden = !this.isCurrentPasswordHidden;
  }

  private onChangePlanConfirm = (event):void => {
    event.preventDefault();
    this.props.offerStore.changeNextPlan()
      .then(() => toast.success(t('Plan changed successfully')))
      .then(() => {
        this.props.offerStore.fetchCurrentAndNextPlans()
        this.props.offerStore.fetchNextOfferPlan()
      })
  }

  private openPlanModal = (e): void => {
    e.preventDefault()
    this.props.offerStore.enableChangeNextPlanMode();
    this.isPlansModalOpen = true;
  }

  private closePlanModal = (): void => {
    this.isPlansModalOpen = false;
  }

  public render() {
    const { currentSettlementPolicyPlan, nextSettlementPolicyPlan, nextOfferPlan, nextPlan} = this.props.offerStore;

    if(!currentSettlementPolicyPlan || !nextSettlementPolicyPlan ) {
      return null;
    }

    return (
      <>
        <UserHeader title={ t('Plans') } subtitle={ t('Control your active and future plans.') }/>
        { this.isPlansModalOpen && <ChoosePlanModal onClose={ () => this.closePlanModal() }/> }
        { !this.isLoading && <>
          <div className='well plan-details-item'>
            <div>
              <div className="size18 primary">{ currentSettlementPolicyPlan.name.toUpperCase() }</div>
              <div className="plan-status">
                <div className="status-dot-active"/>
                <div className="subtitle">{ t('Active Plan') }</div>
              </div>
            </div>
            <Button content={ t('CHANGE PLAN').toUpperCase() } isOutline onClick={ (e) => this.openPlanModal(e) }/>
          </div>

          {
          <div className='well plan-details-item'>
              <div>
                <div className="size18 primary">{ nextOfferPlan ? nextOfferPlan.name.toUpperCase() : nextSettlementPolicyPlan.name.toUpperCase()} </div>
                <div className="plan-status">
                  <div className="status-dot"/>
                  <div className="subtitle">{ t('Next Plan') }</div>
                </div>
              </div>
              <div className="plan-starts-date-wrapper">
                <span className="primary">{ t('Starts from') }</span>
                { new Date( nextOfferPlan ? nextOfferPlan.startFrom : nextSettlementPolicyPlan.startFrom).toDateString() }
              </div>
            </div>
          }

          { nextPlan && <div className="change-plan-confirm-window">
              <div className='well'>
                <h4 className="form-title">{ t('Confirm your plan change ') }</h4>
                <form className="password-change-wrapper" autoComplete="off" onSubmit={this.onChangePlanConfirm }>
                  <div className='password-inputs-container'>
                    <div className="password-input-wrapper">
                      <Input
                        property={ this.props.userStore.password.getProperty('password') }
                        type={ this.isCurrentPasswordHidden ? 'password' : 'text' }
                        label={ t('Current password') }/>
                      <ShowPasswordButton hidePassword={ this.isCurrentPasswordHidden }
                                          onClickHandler={ this.toggleCurrentPassword.bind(this) }/>
                    </div>
                  </div>
                  <Button content={ t('Save changes') } onClick={this.onChangePlanConfirm} />
                </form>
              </div>
            </div>
          }
        </>
        }
      </>
    )
  }
}
