import { IMap } from '../interfaces';
import { IAnyModel } from './IAnyModel';
import { Model } from './Model';
import { IModelConstructor } from './IModelConstructor';
import { IRelation } from './IRelation';
import { apiManager, IApiManagerResponse } from './apiManager';
import * as lodash from 'lodash';

type QueryOperations = '$ne' | '$in' | '$nin' | '$gt' | '$gte' | '$lt' | '$lte' | '$regex' | '$all' | '$notAll' | '$notIn';

interface ISuccessCallback {
  (res: any): any;
}

interface IFailCallback {
  (err?: Error): any;
  (err?: any): any;
}

export abstract class BaseQueryBuilder<TModel extends IAnyModel, TResult> {
  public abstract exec(): Promise<IApiManagerResponse<TResult>>;

  public then<U>(success: ISuccessCallback, err?: IFailCallback): Promise<TResult | U> {
    return this.exec().then<U>(success, err) as any;
  }

  public endpoint(url: string) {
    this._endpoint = url;
    return this;
  }

  public all(): Promise<TResult> {
    return this.exec().then(res => res.data);
  }

  public thru(fn: (query: BaseQueryBuilder<TModel, TResult>) => void) {
    fn(this);
    return this;
  }

  public raw(path: string, data: any) {
    lodash.set(this.requestParams, path, data);
    return this;
  }

  public where(attribute: string): QueryBuilderOperation<TModel, TResult>;
  public where(attribute: string, value: any): this;
  public where(attribute: string, operations: QueryOperations, value: any): this;
  public where(attribute: string, operation?: QueryOperations|string, value?: any): this|QueryBuilderOperation<TModel, TResult> {
    if (lodash.isUndefined(operation) && lodash.isUndefined(value)) {
      return new QueryBuilderOperation<TModel, TResult>(this, attribute);
    }

    if (lodash.isUndefined(value)) {
      this.requestParams[attribute] = operation as string;
      return this;
    }

    // add merge strategy by operation
    this.requestParams[attribute] = lodash.extend(this.requestParams[attribute] || {}, {
      [operation]: value
    });

    return this;
  }

  constructor(protected ModelConstructor: IModelConstructor<TModel>) {}

  protected requestParams: IMap<any> = {};
  protected _endpoint: string;

  public static readonly NOT_EQUAL: QueryOperations   = '$ne';
  public static readonly IN: QueryOperations          = '$in';
  public static readonly NIN: QueryOperations         = '$nin';
  public static readonly GT: QueryOperations          = '$gt';
  public static readonly GTE: QueryOperations         = '$gte';
  public static readonly LT: QueryOperations          = '$lt';
  public static readonly LTE: QueryOperations         = '$lte';
  public static readonly REGEX: QueryOperations       = '$regex';
  public static readonly ALL: QueryOperations         = '$all';
  public static readonly NOT_ALL: QueryOperations     = '$notAll';
  public static readonly NOT_IN: QueryOperations      = '$notIn';
}

export class FindQueryBuilder<TModel extends Model<any, any>> extends BaseQueryBuilder<TModel, TModel[]> {
  public with(...relations: string[]): this {
    this.relations = relations;
    return this;
  }

  public limit(limit: number): this {
    this._limit = limit;
    return this;
  }

  public skip(skip: number): this {
    this._skip = skip;
    return this;
  }

  public sort(attribute: string, direction?: 'asc' | 'desc'): this {
    const sign = direction === 'desc' ? '-' : '';
    this._sort = sign + attribute;
    return this;
  }

  public exec(): Promise<IApiManagerResponse<TModel[]>> {
    const { ModelConstructor } = this;

    return apiManager
      .post(`${this._endpoint || ModelConstructor.resourceUrl()}/search`, {
        where: this.requestParams,
        limit: this._limit,
        skip: this._skip,
        sort: this._sort,
      })
      .then((res: IApiManagerResponse<any[]>) => {
        const models: TModel[] = res.data.map(attrs => new ModelConstructor().setFromResponse(attrs));
        return BatchFetcher
          .exec(ModelConstructor.getRelations(...this.relations), models)
          .then(() => ({
            data: models,
            res: res.res
          }));
      });
  }

  private _limit: number = 250;
  private _skip: number = 0;
  private _sort: string = '-_id';

  private relations: string[] = [];
}

export class BatchFetcher {
  private constructor() {}

  public static exec(relations: IRelation[], models: IAnyModel[]): Promise<any[]> {
    return Promise
      .all( relations.map(r => BatchFetcher.internalExec(r, models)) );
  }

  private static internalExec(relation: IRelation, models: IAnyModel[]): Promise<any> {
    switch (relation.type) {
      case 'has_many': return BatchFetcher.internalFetchMany(relation, models);
    }

    return Promise.reject('Shit happens... something went wrong!');
  }

  private static internalFetchMany({ ModelConstructor, remoteKey, localProperty, assignFn, assignToProperty }: IRelation, baseModels: IAnyModel[]): Promise<any> {
    const keys = lodash
      .chain(baseModels)
      .map(m => m.get(localProperty) as string[])
      .flatten()
      .uniq()
      .value() as string[];

    return ModelConstructor
      .find()
      .where(remoteKey)
      .in(keys)
      .all()
      .then((relatedModels: IAnyModel[]) => {
        const relatedModelsMap: IMap<IAnyModel> = relatedModels.reduce((res: IMap<IAnyModel>, model: IAnyModel) => {
          res[model.id] = model;
          return res;
        }, {});

        lodash.each(baseModels, (baseModel: IAnyModel) => {
          const relatedRecords = lodash
            .chain([].concat(baseModel.get(localProperty)))
            .uniq()
            .thru((internalKeys: string[]) => {
              return internalKeys.reduce((res: IAnyModel[], key: string) => res.concat(relatedModelsMap[key]), []);
            })
            .compact()
            .value() as IAnyModel[];

          // @todo: extract to separate method
          if (assignFn) {
            return assignFn(baseModel, relatedRecords);
          }

          lodash.assign(baseModel, {
            [assignToProperty]: relatedRecords
          });
        });
      });
  }
}

export class CountQueryBuilder<TModel extends Model<any, any>> extends BaseQueryBuilder<TModel, number> {
  public exec(): Promise<IApiManagerResponse<number>> {
    const { ModelConstructor } = this;

    return apiManager
      .post<{count: number}>(`${ModelConstructor.resourceUrl()}/count`, {
        where: this.requestParams,
      })
      .then(result => ({
        data: result.data.count,
        res: result.res
      }));
  }

  private relations: string[] = [];
}

class QueryBuilderOperation<TModel extends IAnyModel, TResult> {
  constructor(private qb: BaseQueryBuilder<TModel, TResult>, private attribute: string) {}

  public eq(value: string|number): BaseQueryBuilder<TModel, TResult> {
    return this.qb.where(this.attribute, value);
  }

  public neq(value: string|number): BaseQueryBuilder<TModel, TResult> {
    return this.qb.where(this.attribute, BaseQueryBuilder.NOT_EQUAL, value);
  }

  public in(items: Array<string|number>): BaseQueryBuilder<TModel, TResult> {
    return this.qb.where(this.attribute, BaseQueryBuilder.IN, items);
  }

  public nin(items: Array<string|number>): BaseQueryBuilder<TModel, TResult> {
    return this.qb.where(this.attribute, BaseQueryBuilder.NIN, items);
  }

  public gt(value: number|Date|string): BaseQueryBuilder<TModel, TResult> {
    return this.qb.where(this.attribute, BaseQueryBuilder.GT, value);
  }

  public gte(value: number|Date|string): BaseQueryBuilder<TModel, TResult> {
    return this.qb.where(this.attribute, BaseQueryBuilder.GTE, value);
  }

  public lt(value: number|Date|string): BaseQueryBuilder<TModel, TResult> {
    return this.qb.where(this.attribute, BaseQueryBuilder.LT, value);
  }

  public lte(value: number|Date|string): BaseQueryBuilder<TModel, TResult> {
    return this.qb.where(this.attribute, BaseQueryBuilder.LTE, value);
  }

  public regex(value: string): BaseQueryBuilder<TModel, TResult> {
    return this.qb.where(this.attribute, BaseQueryBuilder.REGEX, value);
  }

  public all(items: Array<string|number>): BaseQueryBuilder<TModel, TResult> {
    return this.qb.where(this.attribute, BaseQueryBuilder.ALL, items);
  }

  public notAll(items: Array<string|number>): BaseQueryBuilder<TModel, TResult> {
    return this.qb.where(this.attribute, BaseQueryBuilder.NOT_ALL, items);
  }

  public notIn(items: Array<string|number>): BaseQueryBuilder<TModel, TResult> {
    return this.qb.where(this.attribute, BaseQueryBuilder.NOT_IN, items);
  }

  public raw(path: string, data): BaseQueryBuilder<TModel, TResult> {
    return this.raw(path, data);
  }

  public like(value: string): BaseQueryBuilder<TModel, TResult> {
    return this.regex(value);
  }
}
