import { property, PropertyHandler } from '@ppg/common';
import { action, computed } from 'mobx';
import { Project } from "../models";

export class AccessProject extends PropertyHandler {
  @property()
  public id: string;

  @property()
  public username: string;

  @property()
  public userID: string;

  @property()
  public project: string;

  @property()
  private _selectedProjects = new Map<string, Project>();

  @property()
  private _selectedUsers = new Map<string, Record<string, string>>();

  constructor(accessProject: any) {
    super();

    this.id = accessProject.id;
    this.username = accessProject.sharedTo?.username;
    this.userID = accessProject.sharedTo?.id;
    this.project = accessProject.project;
  }

  @action
  public addProject(project: Project) {
    this._selectedProjects.set(project.id as string, project);
  }

  @action
  public removeProject(project: Project) {
    this._selectedProjects.delete(project.id as string);
  }

  @action
  public addUser(user: string) {
    this._selectedUsers.set(user, {username: user});
  }

  @action
  public removeUser(user: string) {
    this._selectedUsers.delete(user);
  }

  @computed
  public get selectedUsers(): string[] {
    return Array.from(this._selectedUsers.values() ?? []).map(item => item.username);
  }

  @computed
  public get selectedUsersAutocomplete(): Record<string, any>[] {
    return Array.from(this._selectedUsers.values() ?? []);
  }

  @computed
  public get selectedProjects(): Project[] {
    return Array.from(this._selectedProjects.values() ?? []);
  }

  @computed
  public get projectIDS(): string[] {
    return Array.from(this._selectedProjects.keys() ?? []);
  }

  @action
  public selectAllProjects(projects: Project[]) {
    for (const project of projects) {
      this.addProject(project);
    }
  }

  @action
  public deselectAllProjects(projects: Project[]) {
    for (const project of projects) {
      this.removeProject(project);
    }
  }
}
