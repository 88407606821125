import * as React from 'react';
import { Attribute } from '../../base';
import { canCompileTest, t } from '../../base/helpers';
import { InputField, SelectField } from './index';
import { InputTypeType } from './IInputFieldProps';
import { Render } from '../Render/Render';
import dayjs from 'dayjs';
import { OpUnitType } from 'dayjs'

interface ITimeUnitProps<T> {
  sample?: boolean;
  periods?: OpUnitType[];
  time: Attribute<T>;
  period?: Attribute<T>;
  limit?: Attribute<T>;
  disabled?: boolean;
  timeInputType?: InputTypeType;
  timeInputPlaceholder?: string;
  timeInputLabel?: string;
}

export class TimeInput extends React.Component<ITimeUnitProps<any>, any> {

  public render() {
    const { sample, time, period, periods, disabled, timeInputType, timeInputPlaceholder, timeInputLabel, limit } = this.props;

    return (
      <div>
        <div className="row">
          { limit && <div className="column">
            <InputField
              attribute={ limit }
              placeholder={ '1' }
              label={ t('Repetition times') }
              disabled={ disabled || false }
              type={ 'number' }
              min={ 1 }
              max={ 10 }
            />
          </div> }

          <div className="column">
            <InputField
              attribute={ time }
              placeholder={ timeInputPlaceholder || '0' }
              label={ timeInputLabel || t('Value') }
              disabled={ disabled || false }
              type={ timeInputType || 'number' }
              min={ 0 }
            />
          </div>

          { period && <div className="column">
            <SelectField
              attribute={ period }
              placeholder={ t('Period') }
              disabled={ disabled || false }
              options={ periods.map(p => {
                return {
                  name: t(p),
                  value: p
                }
              }) }
            />
          </div> }

        </div>
        <div className="row">
          { canCompileTest(time.getValue()) &&
          <span className='p10l'><b>{ t('Preview compiled:') }&nbsp;</b>
            <Render scope={ {} } fallback="">{ time.getValue() }</Render>
            </span> }
          { sample && <p> { t('Example today will compare with: ') } { dayjs().subtract(time.getValue(), period.getValue()).format('YYYY-MM-DD  hh:mm') }</p> }
        </div>
      </div>
    )
  }
}
