import { API, UseCase } from '@ppg/common';

interface ChangeUserLanguagePayload {
  language: string;
}

export class ChangeUserLanguageUseCase extends UseCase<ChangeUserLanguagePayload, void> {
  protected async execute({ language }) {
    await API.HTTP.patch('ah/user-details/languages', { language });
  }
}

export const changeUserLanguageUseCase = new ChangeUserLanguageUseCase();
