import { ProjectRelated } from '../ProjectRelatedStore';
import { property } from '@ppg/common';
import { action } from 'mobx';
import { Label, Polygon, Utm } from '../../modelsMobx';
import { LabelCountStrategy, LabelState } from '../../modelsMobx/Label';
import { SegmentationStore } from './SegmentationStore';
import { CampaignType, PushCampaign } from '../../modelsMobx/campaigns/PushCampaign';
import { PushCampaignContent } from '../../modelsMobx/campaigns/PushCampaignContent';
import { PushCampaignOptions } from '../../modelsMobx/campaigns/PushCampaignOptions';
import { CampaignDirectionType, IProjectValuesEditCampaign, ISaveCampaignValues, ISaveDraftCampaignValues, IStartExpireTimeValues } from '../../modelsMobx/campaigns/interfaces';
import {
  copyGeolocationPushUseCase,
  copyLabelPushUseCase,
  copyRocketPushUseCase,
  copySegmentedPushUseCase,
  createGeolocationPushDraftUseCase,
  createLabelPushDraftUseCase,
  createRocketPushDraftUseCase,
  createSegmentedPushDraftUseCase,
  getGeolocationPushUseCase,
  getLabelPushUseCase,
  getRocketPushUseCase,
  getSegmentedPushUseCase,
  sendDraftGeolocationPushUseCase,
  sendDraftLabelPushUseCase,
  sendDraftRocketPushUseCase,
  sendDraftSegmentedPushUseCase,
  sendGeolocationPushUseCase,
  sendLabelPushUseCase,
  sendRocketPushUseCase,
  sendSegmentedPushUseCase,
  updateGeolocationPushUseCase,
  updateLabelPushUseCase,
  updateRocketPushUseCase,
  updateSegmentedPushUseCase,
  copyToProjectsRocketPushUseCase,
  copyToProjectsLabelPushUseCase,
  copyToProjectsSegmentedPushUseCase,
  copyToProjectsGeolocationPushUseCase
} from '../../useCases/core/campaigns';
import {
  ICopyToProjectRequest,
  IGetEditPushCampaign,
  ILabelDTO,
  ILabelsPushCampaignOptions,
  IPolygonsPushCampaignOptions,
  ISegmentPushCampaignOptions,
  ISendPushCampaign,
  IUpdateSendPushCampaign
} from '../../useCases/core/campaigns/interfaces';
import { redirect, t } from '../../base/helpers';
import { ProjectRoutesTypes } from '../../routes/moduleProjectRoutes';
import { toast } from '@ppg/styled';
import { IPolygonsType } from '../../modelsMobx/campaigns/PolygonsPushCampaignOptions';
import { GeolocationStore } from './GeolocationStore';
import { getCampaignTypeUseCase } from '../../useCases/core/campaigns/GetCampaignTypeUseCase';
import { getLabelCountUseCase } from '../../useCases/core';
import { SimpleProject } from '../../modelsMobx/project/SimpleProject';
import { TypeOfProjects } from '../../pages/Campaigns/PushCampaignListNew/CampaignItem/CampaignActionCopyToProjects/CampaignCopyToProjectsModal';

export interface IStartValues {
  labels?: Label[];
  includedStrategy?: LabelCountStrategy;
  excludedStrategy?: LabelCountStrategy;
}

export interface IPushSuggestions {
  description: string;
  condition: boolean;
  scrollToSection: string;
}

export enum TypeTarget {
  SEGMENT = "segment",
  LABELS = "labels",
  POLYGONS = "polygons"
}

export interface IStartPushValues {
  isDirectionRtl: boolean;
  requireInteraction: boolean;
  pushActions: {
    title: string;
    link: string;
  }[];
  utm: Utm;
  omitCappingEnabled: boolean;
  isDefaultTitleEnabled: boolean;
  defaultTitle: string;
}

export interface ICreatePushStore {
  push: PushCampaign;
  selectCampaignId: string;
  projectsToCopyCampaign: SimpleProject[];
  isSelectedAllOwnedProjects: boolean;
  isSelectedAllSharedProjects: boolean;
  segmentationLabels: SegmentationStore;
}

export class CreatePushStore extends ProjectRelated implements ICreatePushStore {

  @property()
  public push: PushCampaign;

  @property()
  public selectCampaignId: string = "";

  @property()
  public projectsToCopyCampaign: SimpleProject[] = [];

  @property()
  public isSelectedAllOwnedProjects: boolean = false;

  @property()
  public isSelectedAllSharedProjects: boolean = false;

  public segmentationLabels: SegmentationStore;

  @action
  private initializeStore = ({ labels = [], includedStrategy = LabelCountStrategy.OR, excludedStrategy = LabelCountStrategy.OR }: IStartValues): void => {
    this.segmentationLabels = new SegmentationStore(this.projectId, labels, includedStrategy, excludedStrategy);
  };

  private get createDefaultSchemaPush(): PushCampaign {
    return new PushCampaign(
      new PushCampaignContent(
        '',
        '',
        null,
        null,
        '',
        [],
        CampaignDirectionType.AUTO,
        true,
        false,
        null,
        null,
        0),
      new PushCampaignOptions('', true),
      ""
    );
  }

  @action
  public setStartPushValues = ({ isDirectionRtl, requireInteraction, pushActions, utm, omitCappingEnabled, isDefaultTitleEnabled, defaultTitle }: IStartPushValues): void => {
    this.initializeStore({});

    if (!location.pathname.includes("send-to-segment")) {
      this.push = this.createDefaultSchemaPush;
    }

    this.push.content.setProjectValues({
      redirectLink: this.projectSiteUrl,
      utm,
      isDirectionRtl,
      requireInteraction,
      pushActions,
      omitCappingEnabled,
      isDefaultTitleEnabled,
      defaultTitle
    });
  };

  @action
  public setSelectCampaign(id: string): void {
    this.selectCampaignId = id;
  }

  // handle send campaign and save draft

  private get getMessageAndOptions(): ISendPushCampaign {
    const { content, options } = this.push;

    return {
      message: content.getDTO(),
      options: { ...options.getOptionsDTO(), ...content.getDefaultOptionsDTO() }
    };
  }

  public async sendCampaign(): Promise<string> {
    const { message, options } = this.getMessageAndOptions;
    let campaignSendId;

    switch (this.push.campaignType) {
      case CampaignType.ROCKET:
        campaignSendId = await sendRocketPushUseCase.exec({ message, options });
        break;
      case CampaignType.SEGMENTATION:
        campaignSendId = await sendSegmentedPushUseCase.exec({ message, options });
        break;
      case CampaignType.TEMPORARY_LABELS:
        campaignSendId = await sendLabelPushUseCase.exec({ message, options });
        break;
      case CampaignType.GEOLOCATION:
        campaignSendId = await sendGeolocationPushUseCase.exec({ message, options });
        break;
    }

    return campaignSendId.id;
  }

  public async sendDraftCampaign(): Promise<string> {
    const campaignId = this.selectCampaignId;
    let campaignSendId;

    switch (this.push.campaignType) {
      case CampaignType.ROCKET:
        campaignSendId = await sendDraftRocketPushUseCase.exec({ campaignId });
        break;
      case CampaignType.SEGMENTATION:
        campaignSendId = await sendDraftSegmentedPushUseCase.exec({ campaignId });
        break;
      case CampaignType.TEMPORARY_LABELS:
        campaignSendId = await sendDraftLabelPushUseCase.exec({ campaignId });
        break;
      case CampaignType.GEOLOCATION:
        campaignSendId = await sendDraftGeolocationPushUseCase.exec({ campaignId });
        break;
    }

    return campaignSendId.id;
  }

  public async createDraftCampaign(): Promise<void> {
    const { message, options } = this.getMessageAndOptions;

    switch (this.push.campaignType) {
      case CampaignType.ROCKET:
        await createRocketPushDraftUseCase.exec({ message, options });
        break;
      case CampaignType.SEGMENTATION:
        await createSegmentedPushDraftUseCase.exec({ message, options });
        break;
      case CampaignType.TEMPORARY_LABELS:
        await createLabelPushDraftUseCase.exec({ message, options });
        break;
      case CampaignType.GEOLOCATION:
        await createGeolocationPushDraftUseCase.exec({ message, options });
        break;
    }
  }

  public async updateEditDraftCampaign(): Promise<void> {
    const { message, options } = this.getMessageAndOptions;
    const campaignId = this.selectCampaignId;

    const updateEditDraftRequest: IUpdateSendPushCampaign = { campaignId, message, options };

    switch (this.push.campaignType) {
      case CampaignType.ROCKET:
        await updateRocketPushUseCase.exec(updateEditDraftRequest);
        break;
      case CampaignType.SEGMENTATION:
        await updateSegmentedPushUseCase.exec(updateEditDraftRequest);
        break;
      case CampaignType.TEMPORARY_LABELS:
        await updateLabelPushUseCase.exec(updateEditDraftRequest);
        break;
      case CampaignType.GEOLOCATION:
        await updateGeolocationPushUseCase.exec(updateEditDraftRequest);
        break;
    }
  }

  private campaignIsValid({ expireTime, isExpireTimeEnabled }: IStartExpireTimeValues): boolean {
    if (!this.push.contentInSendCampaignIsValid()) return false;
    if (!this.push.actionsInSendCampaignIsValid()) return false;
    if (!this.push.sendDateInSendCampaignIsValid()) return false;
    if (!this.push.delayTimeInSendCampaignIsValid()) return false;

    this.push.content.setTimeForSendTypeNow({ expireTime, isExpireTimeEnabled });

    return true;
  }

  private redirectToCampaignList(campaignId: string, campaignType: CampaignType): void {
    const { content } = this.push;

    if (content.isSendTypeScheduled) {
      redirect(`${ ProjectRoutesTypes.CAMPAIGN_LIST_PUSHES_READY }`);
      toast.success(t('Your campaign has been plan successfully'));
      return;
    }

    redirect(`${ ProjectRoutesTypes.CAMPAIGN_TARGETED_PUSH }/${ campaignType }/${ campaignId }/report`);
    toast.success(t('Your campaign has been send successfully'));
  }

  private redirectToCampaignListDraft(): void {
    redirect(ProjectRoutesTypes.CAMPAIGN_LIST_PUSHES_DRAFT);
    toast.success(t('Your campaign draft has been saved'));
  }

  private showCampaignsErrors(e): void {
    e.errors.map(err => toast.error(t(`${ err }`)));
  }

  public async handleSendCampaign({ isEditView, expireTime, isExpireTimeEnabled, campaignType }: ISaveCampaignValues): Promise<void> {
    if (!this.campaignIsValid({ expireTime, isExpireTimeEnabled })) return;

    const sendDraftCampaign = async (): Promise<void> => {
      try {
        await this.updateEditDraftCampaign();
        const campaignId = await this.sendDraftCampaign();
        this.redirectToCampaignList(campaignId, campaignType);
      } catch (e) {
        this.showCampaignsErrors(e);
      }
    };

    const sendCampaign = async (): Promise<void> => {
      try {
        const campaignId = await this.sendCampaign();
        this.redirectToCampaignList(campaignId, campaignType);
      } catch (e) {
        this.showCampaignsErrors(e);
      }
    };

    isEditView ? await sendDraftCampaign() : await sendCampaign();
  }

  public async handleSaveDraftCampaign({ isEditView, expireTime, isExpireTimeEnabled }: ISaveDraftCampaignValues): Promise<void> {
    if (!this.campaignIsValid({ expireTime, isExpireTimeEnabled })) return;

    const updateEditDraftCampaign = async (): Promise<void> => {
      try {
        await this.updateEditDraftCampaign();
        this.redirectToCampaignListDraft();
      } catch (e) {
        this.showCampaignsErrors(e);
      }
    };

    const createDraftCampaign = async (): Promise<void> => {
      try {
        await this.createDraftCampaign();
        this.redirectToCampaignListDraft();
      } catch (e) {
        this.showCampaignsErrors(e);
      }
    };


    isEditView ? await updateEditDraftCampaign() : await createDraftCampaign();
  }

  // close audience modal

  public clearLabelsModal(): void {
    const { labels, excludedLabels } = this.push.labelsOption;

    for (const label of labels) {
      label.setState(LabelState.INCLUDED);
    }

    for (const excludedLabel of excludedLabels) {
      excludedLabel.setState(LabelState.EXCLUDED);
    }

    this.segmentationLabels.setLabels([...labels, ...excludedLabels]);
    this.clearStrategyLabelsModal();
  }

  public clearStrategyLabelsModal(): void {
    const { labelsStrategy, excludedLabelsStrategy } = this.push.labelsOption;

    this.segmentationLabels.setIncludedStrategy(labelsStrategy);
    this.segmentationLabels.setExcludedStrategy(excludedLabelsStrategy);
  }

  public clearAllPolygonsModal(): void {
    const allPolygons = this.projectStore.geolocationStore.allPolygons;
    const polygons = this.push.polygonsOption.getCoordinates();

    for (let polygon of allPolygons) {
      if (GeolocationStore.isInCoordinatesSet(polygons, polygon.coordinates)) {
        polygon.setIsSelected(true);
      } else {
        polygon.setIsSelected(false);
      }
    }
  }

  // get campaign type

  public async getCampaignType(): Promise<CampaignType> {
    const { type } = await getCampaignTypeUseCase.exec({ campaignId: this.selectCampaignId });
    return type;
  }

  // handle edit campaign

  @action
  public async handleEditCampaign({ id, utm, omitCappingEnabled }: IProjectValuesEditCampaign): Promise<void> {
    this.selectCampaignId = id;

    const { type } = await getCampaignTypeUseCase.exec({ campaignId: this.selectCampaignId });
    const campaign = await this.getCampaign(type);

    this.initializeStore({});

    this.setCampaign(campaign, utm);
    await this.setCampaignOptionsByCampaignType(type, campaign.options);
    this.push.content.setProjectValuesEditCampaign(omitCappingEnabled);
  }

  public async getCampaign(campaignType: string): Promise<any> {
    let campaign;

    switch (campaignType) {
      case CampaignType.ROCKET:
        campaign = await getRocketPushUseCase.exec({ campaignId: this.selectCampaignId });
        break;
      case CampaignType.SEGMENTATION:
        campaign = await getSegmentedPushUseCase.exec({ campaignId: this.selectCampaignId });
        break;
      case CampaignType.TEMPORARY_LABELS:
        campaign = await getLabelPushUseCase.exec({ campaignId: this.selectCampaignId });
        break;
      case CampaignType.GEOLOCATION:
        campaign = await getGeolocationPushUseCase.exec({ campaignId: this.selectCampaignId });
        break;
    }

    return campaign;
  }

  public setCampaign(campaign: IGetEditPushCampaign, utm: Utm): void {
    const { message, metadata, options, state } = campaign;
    const { title, content, icon, image, redirectLink, actions, direction, sendDate, expireDate, delayTime } = message;
    const { requireInteraction, omitCapping } = options;
    const { sentBy, fromAPI } = metadata;

    this.push = new PushCampaign(
      new PushCampaignContent(title, content, icon, image, "", actions, direction, requireInteraction, omitCapping, sendDate, expireDate, delayTime),
      new PushCampaignOptions(sentBy, fromAPI),
      state
    );

    this.push.content.convertLinkToRedirectLink(utm, redirectLink);
  }

  public async setCampaignOptionsByCampaignType(campaignType: string, options): Promise<void> {
    switch (campaignType) {
      case CampaignType.SEGMENTATION:
        await this.setSegmentOptions(options);
        break;

      case CampaignType.TEMPORARY_LABELS:
        await this.setLabelsOptions(options);
        break;

      case CampaignType.GEOLOCATION:
        await this.setPolygonsOptions(options);
        break;
    }
  }

  public async setSegmentOptions(options: ISegmentPushCampaignOptions): Promise<void> {
    const { segment } = options;

    if (!segment.exists) {
      toast.error(t(`Campaign included segment named %{segmentName}, which not exists, please choose new segment`, { segmentName: segment.name }));
      return;
    }

    this.push.setSegment(segment);
  }

  private async deserializeLabels(labels: ILabelDTO[], labelState: LabelState): Promise<Label[]> {
    let results: Label[] = [];
    let labelsNotExists: string[] = [];

    for (let label of labels) {
      if (label.exists) {
        const editLabel: Label = Label.createLabel(label);
        editLabel.setState(labelState);

        const { count } = await getLabelCountUseCase.exec({
          key: editLabel.key,
          value: `${ editLabel.value }`
        });
        editLabel.approxCount = count;

        results.push(editLabel);
      } else {
        labelsNotExists.push(label.label);
      }
    }

    for (let labelNotExist of labelsNotExists) {
      toast.error(t(`Campaign included label named %{labelName}, which not exists, please choose new label`, { labelName: labelNotExist }));
    }

    return results;
  }

  @action
  public async setLabelsOptions(options: ILabelsPushCampaignOptions): Promise<void> {
    const includedLabels: Label[] = await this.deserializeLabels(options.labels.labels, LabelState.INCLUDED);
    const excludedLabels: Label[] = await this.deserializeLabels(options.excludedLabels.labels, LabelState.EXCLUDED);

    if (includedLabels.length === 0 && excludedLabels.length === 0) {
      this.push.removeAllTargets();
      return;
    }

    this.push.setLabels(includedLabels, excludedLabels, options.labels.strategy, options.excludedLabels.strategy);

    const allLabels: Label[] = [...includedLabels, ...excludedLabels];
    this.segmentationLabels = new SegmentationStore(this.projectId, allLabels, options.labels.strategy, options.excludedLabels.strategy);
  }

  public async setPolygonsOptions(options: IPolygonsPushCampaignOptions): Promise<void> {
    const polygons = this.fetchPolygonsByCoordinates(options.polygons);
    if (options.polygons.length !== polygons.length) {
      toast.error(t(`Campaign included polygon, which not exists, please choose new polygon`));
    }

    polygons.length !== 0 && this.push.setPolygons(polygons);
  }

  private fetchPolygonsByCoordinates(polygons: IPolygonsType): Polygon[] {
    const fetchedPolygons = this.projectStore.geolocationStore.allPolygons;
    const results = [];

    for (let fetchedPolygon of fetchedPolygons) {
      if (GeolocationStore.isInCoordinatesSet(polygons, fetchedPolygon.coordinates)) {
        fetchedPolygon.setIsSelected(true);
        results.push(fetchedPolygon);
      }
    }

    return results;
  }

  // handle copy

  public async handleCopyCampaign(copyCampaignId: string): Promise<string> {
    this.selectCampaignId = copyCampaignId;

    const { type } = await getCampaignTypeUseCase.exec({ campaignId: this.selectCampaignId });
    const { id } = await this.getCopyCampaignId(type);

    return id;
  }

  public async getCopyCampaignId(campaignType: string): Promise<any> {
    let campaign;

    switch (campaignType) {
      case CampaignType.ROCKET:
        campaign = await copyRocketPushUseCase.exec({ campaignId: this.selectCampaignId });
        break;
      case CampaignType.SEGMENTATION:
        campaign = await copySegmentedPushUseCase.exec({ campaignId: this.selectCampaignId });
        break;
      case CampaignType.TEMPORARY_LABELS:
        campaign = await copyLabelPushUseCase.exec({ campaignId: this.selectCampaignId });
        break;
      case CampaignType.GEOLOCATION:
        campaign = await copyGeolocationPushUseCase.exec({ campaignId: this.selectCampaignId });
        break;
    }

    return campaign;
  }

  // handle copy to projects

  public get hasMoreThanOneProject(): boolean {
    const { userOwnedProjects, sharedProjects } = this.projectStore;
    const allProjects = [...userOwnedProjects, ...sharedProjects];
    return allProjects.length > 1;
  }

  public get hasSelectedProjects(): boolean {
    return this.projectsToCopyCampaign.length !== 0;
  }

  public projectIsSelected(project: SimpleProject): boolean {
    return this.projectsToCopyCampaign.includes(project);
  }

  public getTypeOfProject(project: SimpleProject): TypeOfProjects {
    const { userOwnedProjects } = this.projectStore;
    return userOwnedProjects.includes(project) ? TypeOfProjects.OWNED : TypeOfProjects.SHARED;
  }

  public getUserProjects(searchProject: string, typeOfProjects: TypeOfProjects): SimpleProject[] {
    const { userOwnedProjects, sharedProjects } = this.projectStore;

    const projectsList: SimpleProject[] = typeOfProjects === TypeOfProjects.OWNED ? userOwnedProjects : sharedProjects;
    const projects: SimpleProject[] = projectsList.filter(project => project.id !== this.currentProject.id)

    if (!searchProject) {
      return projects;
    }

    return projects.filter(project => project.name.toLowerCase().includes(searchProject));
  }

  @action
  public onSelectAllProjects(typeOfProjects: TypeOfProjects): void {
    const allProjects: SimpleProject[] = this.getUserProjects("", typeOfProjects);

    const isSelected: boolean =
      typeOfProjects === TypeOfProjects.OWNED ?
        this.isSelectedAllOwnedProjects :
        this.isSelectedAllSharedProjects;

    if (isSelected) {
      const projectsToSelect: SimpleProject[] = allProjects.filter(project => !this.projectsToCopyCampaign.includes(project));
      this.projectsToCopyCampaign = [...this.projectsToCopyCampaign, ...projectsToSelect];
    } else {
      this.projectsToCopyCampaign = this.projectsToCopyCampaign.filter(project => !allProjects.includes(project));
    }
  }

  @action
  public onSelectProject(selectProject: SimpleProject): void {
    this.projectsToCopyCampaign.push(selectProject);

    const typeOfProject: TypeOfProjects = this.getTypeOfProject(selectProject);
    const allProjects: SimpleProject[] = this.getUserProjects("", typeOfProject);
    const allProjectsAreSelected: boolean = allProjects.every(project => this.projectsToCopyCampaign.includes(project));

    switch (typeOfProject) {
      case TypeOfProjects.OWNED:
        if (this.isSelectedAllOwnedProjects || !allProjectsAreSelected) return;
        this.isSelectedAllOwnedProjects = true;
        break;

      case TypeOfProjects.SHARED:
        if (this.isSelectedAllSharedProjects || !allProjectsAreSelected) return;
        this.isSelectedAllSharedProjects = true;
        break;
    }
  }

  @action
  public onDeselectProject(selectProject: SimpleProject): void {
    this.projectsToCopyCampaign = this.projectsToCopyCampaign.filter(project => project.id !== selectProject.id);

    const typeOfProject: TypeOfProjects = this.getTypeOfProject(selectProject);

    switch (typeOfProject) {
      case TypeOfProjects.OWNED:
        if (!this.isSelectedAllOwnedProjects) return;
        this.isSelectedAllOwnedProjects = false;
        break;

      case TypeOfProjects.SHARED:
        if (!this.isSelectedAllSharedProjects) return;
        this.isSelectedAllSharedProjects = false;
        break;
    }
  }

  public async handleCopyCampaignToProjects(): Promise<void> {
    const campaignType: CampaignType = await this.getCampaignType();

    const projectToCopyCampaignIds: string[] = this.projectsToCopyCampaign.map(project => project.id);

    const copyToProjectRequest: ICopyToProjectRequest = {
      campaignId: this.selectCampaignId,
      projects: projectToCopyCampaignIds
    };

    switch (campaignType) {
      case CampaignType.ROCKET:
        await copyToProjectsRocketPushUseCase.exec(copyToProjectRequest);
        break;
      case CampaignType.SEGMENTATION:
        await copyToProjectsSegmentedPushUseCase.exec(copyToProjectRequest);
        break;
      case CampaignType.TEMPORARY_LABELS:
        await copyToProjectsLabelPushUseCase.exec(copyToProjectRequest);
        break;
      case CampaignType.GEOLOCATION:
        await copyToProjectsGeolocationPushUseCase.exec(copyToProjectRequest);
        break;
    }

    this.clearCopyCampaignData()
  }

  @action
  public clearCopyCampaignData(): void {
    this.projectsToCopyCampaign = [];
    this.isSelectedAllOwnedProjects = false;
    this.isSelectedAllSharedProjects = false;
    this.selectCampaignId = "";
  }

  public setDefaultValues(): void {
    this.push = this.createDefaultSchemaPush;
    this.selectCampaignId = "";
    this.clearCopyCampaignData();
  }
}
