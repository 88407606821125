import { API , UseCase } from "@ppg/common";
interface IUpdateAutomation {
  automationId: string;
  additionalFields: any[];
  createdAt: string;
  deleted: boolean;
  expireDate: string;
  firstStepId: string;
  meta_offer_type: string;
  name: string;
  priority: number;
  resetTime: number;
  resetTimePeriod: string;
  state: string;
  steps: any[];
  testMode: boolean;
  trigger: string;
  triggerType: string;
  updatedAt: string;
}

export class UpdateAutomationUseCase extends UseCase<IUpdateAutomation, void> {
  protected async execute({ automationId,
                            additionalFields,
                            createdAt,
                            deleted,
                            expireDate,
                            firstStepId,
                            meta_offer_type,
                            name,
                            priority,
                            resetTime,
                            resetTimePeriod,
                            state,
                            steps,
                            testMode,
                            trigger,
                            triggerType,
                            updatedAt }: IUpdateAutomation) {
    await API.HTTP.put<void>(`project/${ API.HTTP.currentProjectId }/automation/${ automationId }`,
      {
        project: API.HTTP.currentProjectId,
        id: automationId,
        additionalFields,
        createdAt,
        deleted,
        expireDate,
        firstStepId,
        meta_offer_type,
        name,
        priority,
        resetTime,
        resetTimePeriod,
        state,
        steps,
        testMode,
        trigger,
        triggerType,
        updatedAt
      });
  }
}

export const updateAutomationUseCase = new UpdateAutomationUseCase();
