import { ProjectRelated } from '../ProjectRelatedStore';
import { debounce, property } from '@ppg/common';
import { t } from '../../base/helpers';
import { iconCodes } from '../../constants';
import { ISelectOption } from '@ppg/styled';
import { action, computed, observable, reaction } from 'mobx';
import { ABTest } from '../../modelsMobx';
import { listABTestsUseCase } from '../../useCases/core';
import { IListQueryOptions } from '../../interfaces/IListQueryOptions';
import { ABTestState } from "../../modelsMobx/ABTestState";

export enum ABTestSortType {
  CREATED_AT_ASC = 'created_at_asc',
  CREATED_AT_DESC = 'created_at_desc',
  TEST_START_DATE_ASC = 'test_start_date_asc',
  TEST_START_DATE_DESC = 'test_start_date_desc',
  TEST_END_DATE_ASC = 'test_end_date_asc',
  TEST_END_DATE_DESC = 'test_end_date_desc',
}

export interface IABTestListStore {
  state: ABTestState;
  sort: ABTestSortType;
  searchQuery: string;

  offset: number;
  limit: number;
  total: number;
  infinity: boolean;

  ABTests: ABTest[];

  isLoading: boolean;

  fetchTests: () => Promise<void>;
  hasABTests: boolean;
  sortOptions: ISelectOption[];
  stateOptions: ISelectOption[];
  getInfinity: boolean;
  setInfinity: (infinity: boolean) => void;
}

export class ABTestListStore extends ProjectRelated implements IABTestListStore {

  @property() public state = ABTestState.ALL;
  @property() public sort = ABTestSortType.CREATED_AT_DESC;
  @property() public searchQuery = '';

  @property() public offset = 0;
  @property() public limit = ABTestListStore.ITEMS_PER_PAGE;
  @property() public total = 0;
  @property() public infinity = false;

  @property() public ABTests = [];

  @observable isLoading = true;

  private static ITEMS_PER_PAGE = 20;

  reactOnSortChange = reaction(
    () => this.sort,
    () => this.fetchOnChange()
  );

  reactOnQueryChange = reaction(
    () => this.searchQuery,
    () => this.debouncedOnSearch()
  );

  reactOnPagination = reaction(
    () => this.doFetch,
    () => this.fetchTests()
  );

  private debouncedOnSearch = debounce(() => this.fetchOnChange(), 300);

  @computed
  get doFetch(): IListQueryOptions {
    return {
      offset: this.offset,
      limit: this.limit,
    };
  }

  @action
  public fetchTests = async (): Promise<void> => {
    this.isLoading = true;

    const { data, metadata } = await listABTestsUseCase.exec({
      offset: this.offset,
      limit: this.limit,
      query: this.searchQuery,
      sort: this.sort,
      state: this.state
    });
    this.total = metadata.total;
    const ABTests = data.map(t => new ABTest(t));
    this.ABTests = this.infinity ? this.ABTests.concat(ABTests) : ABTests;
    this.isLoading = false;
  };

  @action
  public fetchOnChange = async (): Promise<void> => {
    this.isLoading = true;
    this.cleanTests();
    this.offset === 0 ? await this.fetchTests() : this.offset = 0;
  };

  @computed
  public get hasABTests(): boolean {
    return this.ABTests.length > 0;
  }

  public get sortOptions(): ISelectOption[] {
    return [
      { name: t('Newest'), value: ABTestSortType.CREATED_AT_DESC },
      { name: t('Oldest'), value: ABTestSortType.CREATED_AT_ASC },
      { name: t('Test start date') + iconCodes.arrowDown, value: ABTestSortType.TEST_START_DATE_DESC },
      { name: t('Test start date') + iconCodes.arrowUp, value: ABTestSortType.TEST_START_DATE_ASC },
      { name: t('Test end date') + iconCodes.arrowDown, value: ABTestSortType.TEST_END_DATE_DESC },
      { name: t('Test end date') + iconCodes.arrowUp, value: ABTestSortType.TEST_END_DATE_ASC },
    ];
  }

  public get stateOptions(): ISelectOption[] {
    return [
      { name: t('All'), value: ABTestState.ALL },
      { name: t('Draft'), value: ABTestState.DRAFT },
      { name: t('Accepted'), value: ABTestState.ACCEPTED },
      { name: t('Preparing'), value: ABTestState.PREPARING },
      { name: t('Test ready to send'), value: ABTestState.READY_TO_SEND },
      { name: t('Test sending'), value: ABTestState.TEST_SENDING },
      { name: t('Test sent'), value: ABTestState.TEST_SENT },
      { name: t('Manual winner'), value: ABTestState.MANUAL_WINNER },
      { name: t('Winner sending'), value: ABTestState.WINNER_SENDING },
      { name: t('Winner ready to send'), value: ABTestState.WINNER_READY_TO_SEND },
      { name: t('Finished'), value: ABTestState.FINISHED },
      { name: t('Canceled'), value: ABTestState.CANCELED },
      { name: t('Failed'), value: ABTestState.FAILED },
    ];
  }

  @action
  public handleOnSearchChange = (event: React.FormEvent<HTMLInputElement>): void => {
    this.searchQuery = event.currentTarget.value;
  };

  @computed
  public get getInfinity(): boolean {
    return this.infinity;
  }

  @action
  public cleanTests(): void {
    this.ABTests = [];
  }

  @action
  public setInfinity = (infinity: boolean): void => {
    this.infinity = infinity;
  };

  public setDefaultValues(): void {
    this.state = ABTestState.ALL;
    this.sort = ABTestSortType.CREATED_AT_DESC;
    this.searchQuery = '';
    this.offset = 0;
    this.limit = ABTestListStore.ITEMS_PER_PAGE;
    this.total = 0;
    this.infinity = false;
    this.ABTests = [];
    this.isLoading = true;
  }
}
