import { action, observable } from 'mobx';
import { PopupRule } from '../modelsMobx/helpers/PopupRule';
import { property } from '@ppg/common';
import { getWebPushPluginUseCase } from '../useCases/website-integration';
import { updatePopupRulesUseCase } from '../useCases/website-integration';
import { WebsiteIntegrationRelated } from './project/WebsiteIntegrationRelated';

interface IPopupRuleStore {
  popupRules: PopupRule[];
  popupRuleToEdit: PopupRule;
  popupRuleToDelete: PopupRule;
  popupRulesTestingURL: string;
  showOnTestingUrl: boolean;
}

export class PopupRuleStore extends WebsiteIntegrationRelated implements IPopupRuleStore {
  @observable public popupRules: PopupRule[];
  @property() public popupRuleToEdit: PopupRule;
  @property() public popupRuleToDelete: PopupRule;
  @property() public popupRulesTestingURL: string;
  @property() public showOnTestingUrl: boolean;

  private async savePopupRules(): Promise<void> {
    await updatePopupRulesUseCase.exec({
      websiteIntegrationId: this.websiteIntegrationId,
      popupRules: this.popupRules.map(rule => rule.toProjectDTO())
    });
    this.clearState();
  }

  @action
  public async fetchPopupRules(): Promise<void> {
    const { payload } = await getWebPushPluginUseCase.exec({ websiteIntegrationId: this.websiteIntegrationId })
    this.popupRules = payload.popupRules.map(rule => new PopupRule(rule));
  }

  public onEditSave(): void {
    this.popupRuleToEdit.onEditSave();
    this.savePopupRules();
  }

  @action
  public async onDeleteConfirmed(): Promise<void> {
    this.popupRules = this.popupRules.filter(rule => rule !== this.popupRuleToDelete);
    this.popupRuleToDelete = null;
    await this.savePopupRules();
  }

  @action
  public onRuleEdit(rule: PopupRule): void {
    this.popupRuleToEdit = rule;
  }

  @action
  public onRuleDelete(rule: PopupRule): void {
    this.popupRuleToDelete = rule;
  }

  @action
  public onRuleCancel(): void {
    this.popupRules = this.popupRules.filter(rule => !rule.isNew);
    this.clearState();
  }

  @action
  private clearState(): void {
    this.popupRuleToDelete = null;
    this.popupRuleToEdit = null;
    this.popupRules.map(rule => rule.restore());
  }

  public get hasNew(): boolean {
    return this.popupRules.some(rule => rule.isNew);
  }

  private get haveAtLeastOneAllowed(): boolean {
    return this.popupRules.some(item => item.isEnable)
  }

  public get hasData(): boolean {
    return this.popupRules && this.popupRules.length > 0;
  }

  @action
  public checkPopupRules(): boolean {
    const popupRulesSorted = this.popupRules.sort(rule => rule.isEnable ? 1 : -1);

    for (let rule of popupRulesSorted) {
      let regExp = new RegExp(rule.url);

      if (rule.re && Object.keys(rule.re).length === 2) {
        regExp = new RegExp(rule.re.source, rule.re.flags);
      }

      if (regExp.test(this.popupRulesTestingURL)) {
        return this.showOnTestingUrl = rule.isEnable;
      }
    }
    return this.showOnTestingUrl = !this.haveAtLeastOneAllowed;
  }

}
