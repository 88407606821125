import { API, UseCase } from '@ppg/common';

interface OldCountSubscribersUseCaseResponse {
  count: number
}

export class LegacyCountSubscribersUseCase extends UseCase<any, OldCountSubscribersUseCaseResponse> {
  protected async execute(...body: any) {
    return API.HTTP.post<OldCountSubscribersUseCaseResponse>(`project/${ API.HTTP.currentProjectId }/subscriber/count`, ...body);
  }
}

export const legacyCountSubscribersUseCase = new LegacyCountSubscribersUseCase();
