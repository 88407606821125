import cs from 'classnames';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react'
import * as React from 'react';
import { redirect } from '../../base/helpers';
import { Accident } from '../../modelsMobx/Accident';
import { AccidentStore } from '../../stores/AccidentStore';

interface IAccidentProps {
  accidentStore?: AccidentStore;
}

@inject('accidentStore')
@observer
export class AccidentHandler extends React.Component<IAccidentProps> {

  @observable private isLoading: boolean = true;

  public async componentDidMount() {
    this.props.accidentStore.fetchItems()
      .finally(() => this.isLoading = false);
  }

  private onClickHandler(accident) {
    if (accident.url) {
      window.open(accident.url)
    }
    if (accident.redirect) {
      redirect(accident.redirect);
    }
  }

  public render() {
    const { accidentStore } = this.props;

    if (this.isLoading) {
      return null;
    }

    return <div>
      { accidentStore.currentAccidents(location.pathname).map((accident: Accident, index) => (
        <div key={ `accident_${ index }` } onClick={ () => this.onClickHandler(accident) }
             className={ cs(`alert-block alert--${ accident.alertLevel } m5b`) }>
          { accident.info }
        </div>
      )) }
    </div>;
  }
}
