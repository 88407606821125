import { API, UseCase } from '@ppg/common';

export type GetActiveAccidentsResult = IAccidentResult[];

export interface IAccidentResult {
  readonly id: string;
  readonly level: string;
  readonly root: string;
  readonly url: string;
  readonly info: string;
  readonly redirect: string;
};

export class GetActiveAccidentsUseCase extends UseCase<null, GetActiveAccidentsResult> {
  protected async execute() {
    return API.HTTP.get<GetActiveAccidentsResult>('ah/accidents/active');
  }
}

export const getActiveAccidentsUseCase = new GetActiveAccidentsUseCase();
