import { API, UseCase } from '@ppg/common';
import { getPlatformTypeParam, getRangeDateParam, getTimezoneParam } from '../../../helpers';
import { ICampaignClickedDeliveredHourStats, IProjectDashboardRequestBody } from '../../../interfaces';
import { DashboardCampaignType } from '../../../../pages/Dashboard/common/enums';

interface IGetCampaignClickedDeliveredHourRequest extends IProjectDashboardRequestBody {
  campaignType: DashboardCampaignType;
}

interface IGetCampaignClickedDeliveredHourResult {
  campaignClickedDeliveredHourStats: ICampaignClickedDeliveredHourStats[];
}

export class GetProjectClickedAndDeliveredUseCase extends UseCase<IGetCampaignClickedDeliveredHourRequest, IGetCampaignClickedDeliveredHourResult> {
  protected async execute({ from, to, platform, campaignType, timezone }: IGetCampaignClickedDeliveredHourRequest) {
    let endpoint = `statistics/v2/projects/${ API.HTTP.currentProjectId }/campaigns/dashboard/statistics`
      + `/${ campaignType }`
      + `/hours/clicked-delivered`
      + getPlatformTypeParam(platform)
      + getRangeDateParam(from, to)
      + getTimezoneParam(timezone)
      + `&timezoneOffset=${ new Date().getTimezoneOffset() }`;

    return API.HTTP.get<IGetCampaignClickedDeliveredHourResult>(endpoint);
  }
}

export const getProjectClickedAndDeliveredUseCase = new GetProjectClickedAndDeliveredUseCase();
