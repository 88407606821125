import { API, UseCase } from '@ppg/common';
import { IAutomationScenario } from '../../../modelsMobx/AutomationScenario';

interface IGetAutomations {
  projectId: string;
  query: string;
}

export class GetAutomationListUseCase extends UseCase<IGetAutomations, IAutomationScenario[]> {
  protected async execute({ projectId, query }: IGetAutomations) {
    return API.HTTP.post<IAutomationScenario[]>(`project/${ projectId }/automation/search`, {
      where: {
        name: { $or: { $regex: query } }
      }
    })
  }
}

export const getAutomationListUseCase = new GetAutomationListUseCase();
