import * as React from 'react';
import RenderRoutes from '../../../routes/RenderRoutes';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from '../../../stores/project/ProjectStore';
import { AccessManagerStore } from '../../../stores/AccessManagerStore';
import { UserStore } from '../../../stores/user/UserStore';
import { UserLayout } from '../Layouts/UserLayout/UserLayout/UserLayout';
import { accessManagerRoutes, accountNavigationTabs } from '../../../routes/projectRoutes';

interface IAccessKeyManagerProps {
  projectStore?: ProjectStore;
  accessManagerStore?: AccessManagerStore;
  userStore?: UserStore;
}

@inject('projectStore', 'accessManagerStore', 'userStore')
@observer
export class AccessManager extends React.Component<IAccessKeyManagerProps> {

  public componentDidMount() {
    this.props.accessManagerStore.getProperty('infinity').setValue((this.props.userStore.settings.infinity))
  }

  public render() {
    return (
      <UserLayout navigationTabs={ accountNavigationTabs.accessManager }>
        <section>
          <RenderRoutes routes={ accessManagerRoutes }/>
        </section>
      </UserLayout>
    );
  }
}
