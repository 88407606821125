import { property, isSelector } from "@ppg/common";
import { WebsiteIntegrationRelated } from '../../stores/project/WebsiteIntegrationRelated';
import { action } from 'mobx';
import { getColorTrackingPluginUseCase, updateColorTrackingPluginUseCase } from '../../useCases/website-integration';

export interface IColorTracking {
  enabled: boolean;
  productSelector: string;
}

export class ColorTracking extends WebsiteIntegrationRelated implements IColorTracking {
  @property() enabled: boolean;
  @property([isSelector()]) productSelector: string;

  @action
  public async fetchData(): Promise<void> {
    const { payload } = await getColorTrackingPluginUseCase.exec({ websiteIntegrationId: this.websiteIntegrationId });
    this.enabled = payload.enabled;
    this.productSelector = payload.selector;
  }

  async save(): Promise<void> {
    await updateColorTrackingPluginUseCase.exec({
      websiteIntegrationId: this.websiteIntegrationId,
      enabled: this.enabled,
      selector: this.productSelector
    })
  }
}
