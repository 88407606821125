import * as lodash from 'lodash';
import * as React from 'react';
import { t } from '../../base/helpers';
import { IS_PRODUCTION } from '../../constants';
import { IErrorProps } from './IErrorProps';

export class ErrorComponent extends React.Component<IErrorProps, {}> {
  public render() {
    return (
      <div>
        { t('Something went wrong') }
        { this.renderStack() }
      </div>
    )
  }

  private renderStack() {
    const { err } = this.props;
    if (!IS_PRODUCTION) {
      console.error(err);
    }
    return <pre>{ !IS_PRODUCTION && lodash.result(err, 'stack') }</pre>;
  }
}