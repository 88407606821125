import {Model} from '../base';
import {property} from '../base/decorators';
import {apiManager} from '../base';
import {ISelectOption} from '../components/common/ISelectFieldProps';
import {ICountry, ICountryResource} from './ICountry';
import * as lodash from 'lodash';

export class Country extends Model<ICountry, ICountryResource> implements ICountry {
  @property()
  public name: string;

  @property()
  public code: string;

  @property()
  public ue: boolean;

  @property()
  public block: boolean;

  public getCountriesList(): Promise<Country[]> {
    return apiManager.get('/country').then(c => c.data as Country[]);
  }

  public getCountriesListAsOptions(): Promise<ISelectOption[]> {
    return this
      .getCountriesList()
      .then(countries => countries.filter(country => !country.block || country.ue))
      .then(countries => countries.map(({code, name}) => ({value: code, name: `${name} (${code === 'pl' ? 'PLN' : 'EUR'})`})))
      .then(countries => lodash.sortBy(countries, country => country.name));
  }

  public getCountriesListAsVATOptions(): Promise<ISelectOption[]> {
    return this
      .getCountriesList()
      .then(countries => countries.filter(country => !country.block))
      .then(countries => countries.map(({code, name}) => ({value: code, name: `${name} (${code === 'pl' ? 'VAT' : 'REVERSE CHARGE'})`})))
      .then(countries => lodash.sortBy(countries, country => country.name));
  }

  public defaults() {
    return {
      name: '',
      code: '',
      block: true,
      ue: false,
    };
  }

  public static resourceUrl(): string {
    return 'country';
  }
}