import { property, PropertyHandler } from "@ppg/common";
import { action, computed } from 'mobx';
import { CustomUtm, ICustomUtm } from './CustomUtm';

export interface IUtm {
  source: string;
  campaign: string;
  medium: string;
  disabled: boolean;
  isMediumOn?: boolean;
  isSourceOn?: boolean;
  isCampaignOn?: boolean;
  customUtms?: ICustomUtm[];
}

export class Utm extends PropertyHandler {
  @property() disabled: boolean;
  @property() medium: string;
  @property() source: string;
  @property() campaign: string;

  @property() isMediumOn: boolean;
  @property() isSourceOn: boolean;
  @property() isCampaignOn: boolean;
  @property() customUtms: CustomUtm[];

  private constructor(utm: IUtm) {
    super();
    this.disabled = utm.disabled;
    this.medium = utm.medium;
    this.source = utm.source;
    this.campaign = utm.campaign;
    this.isMediumOn = utm.isMediumOn;
    this.isSourceOn = utm.isSourceOn;
    this.isCampaignOn = utm.isCampaignOn;
    this.customUtms = utm.customUtms.map(utm => new CustomUtm(utm)) || [];
  }

  static createUtm(utm?: IUtm): Utm {
    if (!utm) {
      return Utm.createDefaultUtm();
    } else {
      return new Utm(utm);
    }
  }

  static createDefaultUtm(): Utm {
    return new Utm({
      disabled: false,
      medium: 'push',
      source: 'pushpushgo',
      campaign: 'CampaignName',
      isMediumOn: true,
      isSourceOn: true,
      isCampaignOn: true,
      customUtms: [],
    });
  }

  @computed
  public get hasCustomUtms(): boolean {
    return this.customUtms.length > 0;
  }

  @action
  public deleteCustomUtm(utmIdx: number): void {
    this.customUtms.splice(utmIdx, 1);
  }

  public serializeCustomUtms(): ICustomUtm[] {
    return this.customUtms.map(utm => utm.serialize());
  }
}

