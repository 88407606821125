import * as React from 'react';
import '../UserLayout/UserLayout.scss'
import { Button } from '@ppg/styled';
import './UserHeader.scss'

interface UserHeaderProps {
  title: string,
  subtitle?: string
  button?: {
    icon: string,
    size: string,
    onClick(): void;
    content: string;
  }
}

export class UserHeader extends React.Component<UserHeaderProps, {}> {
  public render() {
    const { title, button, subtitle } = this.props;

    return <header className={'user-layout-header-wrapper'}>
      <h2 className={'user-layout-header-mobile-title'}>{title}</h2>
      {subtitle && <span className={'user-layout-subtitle'}>{subtitle}</span>}
      {button && <Button icon={button.icon} size={button.size} onClick={button.onClick} content={button.content}/>}
    </header>
  }
}
