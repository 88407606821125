import { property, PropertyHandler } from '@ppg/common';
import { toast } from '@ppg/styled';
import { action, computed, observable, reaction } from 'mobx';
import { t } from '../base/helpers';
import { ITEMS_PER_PAGE } from '../constants';
import { getPaidInvoiceListUseCase } from '../useCases/accounting/GetPaidInvoiceListUseCase';
import { getUnpaidInvoiceListUseCase } from '../useCases/accounting/GetUnpaidInvoiceListUseCase';
import { CurrencyType } from './CurrencyType';
import { Invoice } from './Invoice';

export class UnpaidInvoices extends PropertyHandler {
  @property() public invoices: Invoice[] = [];
  @property() public perPage: number = ITEMS_PER_PAGE;
  @property() public offset: number = 0;
  @property() public infinity: boolean = false;
  @observable public total: number = 0;

  public _organizationId: string = '';
  @property() public selectedInvoices: Invoice[] = [];
  @property() public currency: CurrencyType;

  fetchOnPaginationChange = reaction(
    () => this.doFetch,
    () => this.fetchInvoices(),
  );

  @computed
  get doFetch() {
    return {
      offset: this.offset,
      perPage: this.perPage,
    }
  }

  public async setInitialData(currency: CurrencyType, organizationId: string) {
    this._organizationId = organizationId;
    this.currency = currency;
    await this.fetchByCurrency();
  }

  public selectForPayment(invoice: Invoice) {
    const invoiceSet = new Set(this.selectedInvoices);
    const hasInvoice = invoiceSet.has(invoice);

    if (!hasInvoice) {
      invoiceSet.add(invoice);
    }

    if (hasInvoice) {
      const isNewest = this.sortedSelected.indexOf(invoice) === this.sortedSelected.length - 1;

      isNewest
        ? invoiceSet.delete(invoice)
        : toast.error(t('You can select invoices only in chronological order, starting with the one issued first'));
    }

    invoice.selected = invoiceSet.has(invoice);
    this.selectedInvoices = Array.from(invoiceSet);
  }

  public getInfinity() {
    return this.infinity;
  }

  public get hasSelectedInvoices() {
    return this.selectedInvoices.length > 0;
  }

  public setInfinity(infinity: boolean) {
    this.infinity = infinity;
  }

  public async fetchInvoices() {
    if (!this._organizationId) {
      return;
    }

    const { metadata, data } = await getUnpaidInvoiceListUseCase.exec({
      organization: this._organizationId,
      limit: this.perPage,
      offset: this.offset,
      currency: this.currency
    });

    this.total = metadata.total;
    const invoices = data
      .filter(item => !this.invoices.some(invoice => invoice.id === item.id))
      .map(invoice => Invoice.createInvoice(invoice));

    invoices.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
    this.invoices = this.infinity ? this.invoices.concat(invoices) : invoices;
  }

  @action
  public async fetchByCurrency() {
    if (!this._organizationId) {
      return;
    }

    this.offset = 0;
    const { metadata, data } = await getUnpaidInvoiceListUseCase.exec({
      organization: this._organizationId,
      limit: this.perPage,
      offset: this.offset,
      currency: this.currency
    });

    this.total = metadata.total;
    this.invoices = data.map(invoice => Invoice.createInvoice(invoice))
      .sort((a, b) => b.createdAt.localeCompare(a.createdAt));
  }

  public sortChronologically(invoices: Invoice[]): Invoice[] {
    return invoices.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
  }

  public get sortedSelected() {
    return this.sortChronologically(this.selectedInvoices);
  }

  public get sortedInvoices(): Invoice[] {
    return this.sortChronologically(this.invoices);
  }

  public isInvoiceEnabled(invoice: Invoice) {
    if (this.invoices.length === 1) {
      return true;
    }

    if (this.selectedInvoices.includes(invoice)) {
      return true;
    }

    return this.sortedInvoices
      .filter(i => !this.selectedInvoices.includes(i))
      .indexOf(invoice) === 0;
  }

  public getTotal() {
    return this.hasSelectedInvoices && this.selectedInvoices.reduce((a, b) => a + b.amount, 0);
  }

  public clearSelectedInvoices(): void {
    this.selectedInvoices = [];
    this.invoices.forEach(i => i.selected = false);
  }
}

export class PaidInvoices extends PropertyHandler {
  @property() public invoices: Invoice[] = [];
  @property() public perPage: number = ITEMS_PER_PAGE;
  @property() public offset: number = 0;
  @property() public infinity: boolean = false;
  @property() public currency: CurrencyType;

  @observable public total: number = 0;

  private _organizationId: string = '';

  fetchOnPaginationChange = reaction(
    () => this.doFetch,
    () => this.fetchInvoices(),
  );

  @computed
  get doFetch() {
    return {
      offset: this.offset,
      perPage: this.perPage,
    }
  }

  @action
  public async setInitialData(currency: CurrencyType, organizationId: string) {
    this._organizationId = organizationId;
    this.currency = currency;
    await this.fetchByCurrency();
  }

  public getInfinity() {
    return this.infinity;
  }

  public setInfinity(infinity: boolean) {
    this.infinity = infinity;
  }

  public async fetchInvoices() {
    if (!this._organizationId) {
      return;
    }
    const { metadata, data } = await getPaidInvoiceListUseCase.exec({
      organization: this._organizationId,
      limit: this.perPage,
      offset: this.offset,
      currency: this.currency,
    });

    this.total = metadata.total;
    const invoices = data
      .filter(item => !this.invoices.some(invoice => invoice.id === item.id))
      .map(invoice => Invoice.createInvoice(invoice));

    this.invoices = this.infinity ? this.invoices.concat(invoices) : invoices;
  }

  public async fetchByCurrency() {
    if (!this._organizationId) {
      return;
    }
    this.offset = 0;
    const { metadata, data } = await getPaidInvoiceListUseCase.exec({
      organization: this._organizationId,
      limit: this.perPage,
      offset: this.offset,
      currency: this.currency,
    });

    this.total = metadata.total;
    this.invoices = data.map(invoice => Invoice.createInvoice(invoice));
  }

}
