import { localProperty, property, ValueValidator } from '@ppg/common';
import { action, computed, reaction } from 'mobx';
import { getWebPushPluginUseCase, getWebsiteIntegrationByProjectIdUseCase, } from '../../useCases/website-integration';
import { ColorTracking } from '../../modelsMobx/helpers/ColorTracking';
import { AnalyticsSupport } from '../../modelsMobx/helpers/AnalyticsSupport';
import { FallbackNotification } from '../../modelsMobx/helpers/FallbackNotification';
import { IProviderGCM } from '../../useCases/website-integration/interfaces';
import { PopupDisplaySettings } from '../../modelsMobx/helpers/PopupDisplaySettings';
import { SidebarFab } from '../../modelsMobx/helpers/SidebarFab';
import { SwWorker } from '../../modelsMobx/helpers/SwWorker';
import { WebsiteDetailStore } from '../WebsiteDetailStore';
import { ProjectRelated } from '../ProjectRelatedStore';
import { GeolocationPlugin } from '../../modelsMobx/helpers/GeolocationPlugin';
import { ConfirmWindowPlugin } from '../../modelsMobx/helpers/ConfirmWindowPlugin';
import { PopupRuleStore } from '../PopupRuleStore';
import { IntegrationStore } from '../IntegrationStore';
import { SubscriptionFormStoreCreator } from '../SubscriptionFormCreator/SubscriptionFormStoreCreator';
import { FormType, SubscriptionFormStore } from '../SubscriptionFormCreator';
import { SubscriptionFormBuilder } from '../SubscriptionFormCreator/helpers';
import { projectImageStore, pushSettingStore } from '../index';
import { DEFAULT_PRIMARY_COLOR } from '../../constants';
import { BeaconTrackerPlugin } from '../../modelsMobx/helpers/BeaconTrackerPlugin';
import { PopupRule } from '../../modelsMobx/helpers/PopupRule';
import { ImageFormatType } from '../../modelsMobx/Image';

interface IConfirmWindowUpdate {
  logo: string;
  prevLogo: string;
}

export class WebsiteIntegrationStore extends ProjectRelated {
  @localProperty() public websiteIntegrationId: string;

  @property() public colorTracking: ColorTracking;
  @property() public analyticsSupport: AnalyticsSupport;
  @property() public geolocation: GeolocationPlugin;
  @property() public fallback: FallbackNotification;
  @property() public popupDisplaySettings: PopupDisplaySettings;
  @property() public confirmWindow: ConfirmWindowPlugin;
  @property() public serviceWorker: SwWorker;
  @property() public sidebarFab: SidebarFab;
  @property() public beaconTracker: BeaconTrackerPlugin;

  public websiteDetails: WebsiteDetailStore;
  public popupRules: PopupRuleStore;
  public integration: IntegrationStore;
  public subscriptionFormCreator: SubscriptionFormStoreCreator;

  reactOnProjectChange = reaction(
    () => this.projectId,
    () => this.setDefaultValues());

  private reactOnNativeChange = reaction(
    () => this.hasCurrentProject && this.isNativeIntegration,
    () => this.canUpdateDefaultTemplate && this.setNativeDefaultTemplate());

  async setDefaultValues(): Promise<void> {
    this.websiteDetails = new WebsiteDetailStore();
    this.integration = new IntegrationStore();
    this.subscriptionFormCreator = new SubscriptionFormStoreCreator();
    this.subscriptionFormCreator.subscriptionFormTemplates = this.getFormTemplates();
    this.subscriptionFormCreator.setDefaultValues();
    this.colorTracking = new ColorTracking();
    this.analyticsSupport = new AnalyticsSupport();
    this.geolocation = new GeolocationPlugin();
    this.beaconTracker = new BeaconTrackerPlugin();
    this.sidebarFab = new SidebarFab();
    this.popupRules = new PopupRuleStore();
    this.popupDisplaySettings = PopupDisplaySettings.createSettings();
    this.confirmWindow = ConfirmWindowPlugin.createPlugin();
    this.serviceWorker = SwWorker.createPlugin();
    this.fallback = FallbackNotification.createPlugin();
  }

  public get showDisabledGeolocationWarning(): boolean {
    return !this.geolocation.isLoading && !this.geolocation.enabled;
  }

  public get canUpdateDefaultTemplate(): boolean {
    return this.hasCurrentProject && this.isNativeIntegration;
  }

  public onNewPopupRule(): void {
    if (this.popupRules.hasNew) {
      return;
    }
    let rule = new PopupRule({
      enable: true,
      newRule: true,
      url: this.projectSiteUrl,
      re: null,
    });
    this.popupRules.popupRules = this.popupRules.popupRules.concat(rule);
    this.popupRules.popupRuleToEdit = rule;
  }

  @computed
  public get getPackageUrl(): string {
    return `/wi/artifacts/${this.websiteIntegrationId}`;
  }

  @computed
  public get canSetPopupReopen(): boolean {
    return !(this.isNativeIntegration && this.subscriptionFormCreator.formType === FormType.DEFAULT);
  }

  public get isNativeIntegration(): boolean {
    return this.integration && this.integration.isNativeSelected;
  }

  public get isSimpleIntegration(): boolean {
    return this.integration && this.integration.isSimpleSelected;
  }

  public getConfirmWindowImage(): string {
    return this.confirmWindow.hasImage ? this.confirmWindow.logo : this.projectLogo;
  }

  @action
  public async getWebsiteIntegrationId(): Promise<void> {
    if (!this.projectId) {

      return;
    }
    this.websiteIntegrationId = await getWebsiteIntegrationByProjectIdUseCase.exec({
      projectID: this.projectId
    }).catch(err => err);
  }

  public shouldUpdateConfirmWindow({logo, prevLogo}: IConfirmWindowUpdate): boolean {
    return this.areImagesTheSame({logo, prevLogo}) || this.areBothImagesDummyIcons({logo, prevLogo});
  }

  public areImagesTheSame({logo, prevLogo}: IConfirmWindowUpdate): boolean {
    const prevLogoName = this.getImageName(prevLogo);
    const logoName = this.getImageName(logo);

    return logoName?.includes(prevLogoName) || !logoName;
  }

  public areBothImagesDummyIcons({logo, prevLogo}: IConfirmWindowUpdate): boolean {
    return this.isDummyIcon(logo) && this.isDummyIcon(prevLogo);
  }

  public getImageName(imgUrl: string): string {
    if (!imgUrl.includes('/images/')) {
      return;
    }

    const [resource, path] = imgUrl.split('/images/');
    const name = path.match(/^([^.]+)/) || [''];
    if (name[0].includes('_medium')) {
      name[0] = name[0].replace('_medium', '');
    }
    return name[0];
  }

  private isDummyIcon(imgUrl: string): boolean {
    return imgUrl.includes('/dummy-icon.png');
  }

  public get siteUrlProtocol(): string {
    if (!this.projectSiteUrl) return;
    const siteUrl = new URL(this.projectSiteUrl);
    return siteUrl.protocol.slice(0, -1);
  }

  @computed
  public get siteHasHttps(): boolean {
    return this.projectSiteUrl && ValueValidator.isUrlWithHttps(this.projectSiteUrl);
  }

  public createDefaultFormBuilder = (): SubscriptionFormBuilder => {
    return new SubscriptionFormBuilder({
      siteUrl: this.projectSiteUrl,
      color: DEFAULT_PRIMARY_COLOR,
      picture: this.projectLogo,
      headerText: SubscriptionFormBuilder.defaultHeaderText(),
      descriptionText: SubscriptionFormBuilder.defaultContentText(this.projectSiteUrl),
      allowText: SubscriptionFormBuilder.defaultAllowText(),
      denyText: SubscriptionFormBuilder.defaultDenyText(),
      isNative: this.isNativeIntegration,
      isDirectionRtl: pushSettingStore.getProperty('isDirectionRtl').getValue()
    });
  };

  @action
  public async loadSubscriptionForms(): Promise<void> {
    const subscriptionForms = await this.subscriptionFormCreator.fetchSubscriptionForms();
    await this.setFetchedSubscriptionFormTemplates(subscriptionForms);

    if (!this.subscriptionFormCreator.formStore) {
      this.subscriptionFormCreator.changeFormType(FormType.DEFAULT);
    }
  }

  public setNativeDefaultTemplate() {
    this.subscriptionFormCreator.subscriptionFormTemplates[FormType.DEFAULT] = this.createDefaultFormBuilder().getTypeTemplate(FormType.DEFAULT);
    if (this.subscriptionFormCreator.formType === FormType.DEFAULT) {
      this.subscriptionFormCreator.formStore = new SubscriptionFormStore(this.subscriptionFormCreator.subscriptionFormTemplates[this.subscriptionFormCreator.formType], this.subscriptionFormCreator.formType);
    }
  }

  @computed
  get projectLogo() {
    return projectImageStore.getLogoURL(ImageFormatType.MEDIUM);
  }

  public async setFetchedSubscriptionFormTemplates(formTemplates: any): Promise<void> {
    const {payload} = await this.subscriptionFormCreator.getCurrentSubscriptionForm();
    const currentForm = payload.subscriptionForm;

    const data = [].concat(formTemplates);

    data.forEach(item => {
      if (item) {
        this.subscriptionFormCreator.subscriptionFormTemplates[item.type] = JSON.parse(item.template);
        if (item.id === currentForm) {
          this.subscriptionFormCreator.changeFormType(item.type);
        }
      }

      if (this.isNativeIntegration) {
        this.setNativeDefaultTemplate();
      }
    });
  }

  private getFormTemplates() {
    const formBuilder = this.createDefaultFormBuilder();

    return {
      [FormType.CUSTOM]: formBuilder.getTypeTemplate(FormType.CUSTOM),
      [FormType.TOP]: formBuilder.getTypeTemplate(FormType.TOP),
      [FormType.DEFAULT]: formBuilder.getTypeTemplate(FormType.DEFAULT),
      [FormType.TINY]: formBuilder.getTypeTemplate(FormType.TINY),
    };
  }

  @action
  public async getWebPush(): Promise<void> {
    const {payload} = await getWebPushPluginUseCase.exec({websiteIntegrationId: this.websiteIntegrationId});
    const gcmProvider = payload.providers.find(provider => provider.hasOwnProperty('gcmSenderId'));

    this.popupDisplaySettings = PopupDisplaySettings.createSettings({
      delay: payload.delayTime,
      exitPopup: payload.exitPopup,
      reopenTime: payload.resubscribeTime
    });
    this.confirmWindow = ConfirmWindowPlugin.createPlugin({
      customHTML: payload.doubleOptInCustomHTML,
      description: payload.doubleOptInDescription,
      logo: payload.doubleOptInProjectLogoURL,
      customButton: payload.doubleOptInCustomButton
    });
    this.serviceWorker = SwWorker.createPlugin({
      swScope: payload.serviceWorkerScope,
      swPath: payload.serviceWorkerPath
    });
    this.fallback = FallbackNotification.createPlugin(payload.fallback);
    this.websiteDetails.enabled = payload.enabled;

    this.integration.strategy = payload.strategy;
    this.integration.gcm = gcmProvider as IProviderGCM;
  };

  @action
  public async saveWebsiteDetails(): Promise<void> {
    const {name, siteUrl} = this.websiteDetails;
    this.currentProject.set({name, siteUrl});
    await this.currentProject.savePartial('name', 'siteUrl');
    await this.websiteDetails.updateWebPushEnabled();
    await this.refreshCurrentProject();
  }
}
