import * as React from 'react';
import postcss from 'postcss';
import autoPreFixer from 'autoprefixer';
import jss from './jss';
import { ISubscriptionForm, ISubscriptionFormElement } from '../index';

export class FormCSSBuilder {
  private sheet = jss.createStyleSheet({});

  constructor(private template: ISubscriptionForm[]) {
  }

  private static minify(css: string) {
    return css.replace(/\n/g, '').replace(/\s\s/g, '');
  }

  private static createStyleElement() {
    const styleElement = document.createElement('style');

    styleElement.setAttribute('type', 'text/css');
    styleElement.setAttribute('ppg-form', '');

    return styleElement;
  }

  private addStyle({ child, uuid, styles, commonStyles }: ISubscriptionFormElement) {
    if (child) {
      child.forEach(element => this.addStyle(element));
    }

    if (commonStyles) {
      this.sheet.addRules(commonStyles);
    }

    this.sheet.addRules({ [uuid]: styles });
  }

  public processStyles() {
    this.template.forEach((element) => this.addStyle(element));

    return postcss([autoPreFixer({ browsers: ['last 3 versions', '> 1%'] })])
      .process(this.sheet.toString())
      .then(result => FormCSSBuilder.minify(result.css));
  }

  public buildStyles(domElement: HTMLElement) {
    this.processStyles()
      .then((result) => {
        const styleElement = domElement.ownerDocument.querySelector('style[ppg-form]');

        if (styleElement) {
          return styleElement.innerHTML = result;
        }

        const newStyleElement = FormCSSBuilder.createStyleElement();

        newStyleElement.innerHTML = result;
        domElement.ownerDocument.head.appendChild(newStyleElement);
      });
  }
}
