import { property, PropertyHandler } from '@ppg/common';
import { ISubscriberLabelDTO } from '../../useCases/core/subscriber/GetSubscribersLabelsUseCase';
import { ILabelCreateDTO, ILabelDeleteDTO } from "./Subscriber";
import { t } from '../../base/helpers';
import { action } from "mobx";
import { formatLabelUseCase } from "../../useCases/automation";
import dayjs from 'dayjs';

type ValueSubscriberLabelType = string | number | boolean;

export interface ISubscriberLabel {
  key: string;
  value: ValueSubscriberLabelType;
  expiresAt: string | null;
}

export class SubscriberLabel extends PropertyHandler implements ISubscriberLabel {
  @property() public key: string;
  @property() public value: ValueSubscriberLabelType;
  @property() public expiresAt: string | null;

  static DATE_FORMAT: RegExp = /^(\d{4})-(\d{2})-(\d{2})(|T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?))$/;

  constructor(subscriberLabel: ISubscriberLabel) {
    super();
    this.key = subscriberLabel.key;
    this.value = subscriberLabel.value;
    this.expiresAt = subscriberLabel.expiresAt;
  }

  static createSubscriberLabel(subscriberLabel: ISubscriberLabel) {
    return new SubscriberLabel(subscriberLabel);
  }

  static createSubscriberLabelsFromDTO(subscriberLabels: ISubscriberLabelDTO[]): SubscriberLabel[] {
    const result: SubscriberLabel[] = [];

    for (let label of subscriberLabels) {
      for (let value of label.values) {
        result.push(SubscriberLabel.createSubscriberLabel({
          key: label.key,
          value: value.valueLabel,
          expiresAt: value.expiresAt
        }));
      }
    }

    return result;
  }

  static getKey(key: string, value: any, expiresAt: string) {
    return key + value + expiresAt;
  }

  public get getKey(): string {
    return SubscriberLabel.getKey(this.key, this.value, this.expiresAt);
  }

  public get isSystemKey(): boolean {
    return this.key.includes("sys.");
  }

  public convertToCreateDTO(): ILabelCreateDTO {
    return {
      key: this.key,
      value: this.value,
      expireDate: this.expiresAt
    };
  }

  public convertToDeleteDTO(): ILabelDeleteDTO {
    return {
      key: this.key,
      value: this.value,
    };
  }

  @action
  public async formatValues(): Promise<boolean> {
    let wasFormatted = false;

    if (this.key) {
      const keyFormatted = await formatLabelUseCase.exec({ key: this.key });

      wasFormatted = wasFormatted || keyFormatted.result !== this.key;
      this.key = keyFormatted.result;
    }

    if (typeof this.value !== 'undefined') {
      const valueFormatted = await formatLabelUseCase.exec({ value: `${ this.value }` });

      wasFormatted = wasFormatted || valueFormatted.result !== this.value;
      this.value = valueFormatted.result;
    }

    return wasFormatted;
  }

  public serialize(): string {
    return `${ this.key }:${ this.value }`;
  }

  public get getValue(): ValueSubscriberLabelType {
    if (SubscriberLabel.DATE_FORMAT.test(`${ this.value }`)) {
      const localDate: Date = dayjs(`${ this.value }`).utc().toDate();
      return dayjs(localDate).format("LLL");
    }

    return `${ this.value }`;
  }

  public getExpireDate(): string {
    if (!this.expiresAt) return "";

    const localDate: Date = dayjs(this.expiresAt).utc().toDate();
    const date = dayjs(localDate).format('LLL');

    return `${ t('expire date:') } ${ date }`;
  }

  public validate = (): string | null => {
    const validateLabelName = /^[A-Za-z0-9\-._ ]+$/.test(this.key);

    if (!this.value) {
      return t('Value is required');
    } else if (this.key && !validateLabelName) {
      return t('Special characters are not allowed in the name field');
    }

    return null;
  };

  public validateNew = (): string | null => {
    const validateLabelName = /^[A-Za-z0-9\-._ ]+$/.test(this.key);

    if (!`${ this.value }`.trim()) {
      return t('Value is required');
    } else if (this.key && !validateLabelName) {
      return t('Special characters are not allowed in the label key field');
    }

    return null;
  };


}
