import { ProjectRelated } from './ProjectRelatedStore';
import { property } from '@ppg/common';
import { action } from 'mobx';
import { PushAction } from '../modelsMobx/PushAction';
import { getPushSettingUseCase, updatePushSettingUseCase } from '../useCases/core';

interface IGetPushSettingDTO {
  project: string;
  pushActions: IPushActionDTO[];
  expireTime: number;
  senderStrategy: string;
  requireInteraction: boolean;
  isDirectionRtl: boolean;
  maxTTL: number;
  defaultTitle: string;
}

interface IPushActionDTO {
  title: string;
  link: string;
  useRedirectLink: boolean;
}

export type SenderStrategyType = 'all' | 'mpush' | 'push';

export class PushSettingStore extends ProjectRelated {
  @property() public readonly pushActions: IPushActionDTO[];
  @property() public readonly expireTime: number;
  @property() public readonly isExpireTimeEnabled: boolean;
  @property() public readonly senderStrategy: SenderStrategyType;
  @property() public readonly requireInteraction: boolean;
  @property() public readonly isDirectionRtl: boolean;
  @property() public readonly maxTTL: number;
  @property() public readonly maxAllowedExportDays: number;
  @property() public isDefaultTitleEnabled: boolean;
  @property() public defaultTitle: string;
  @property() public schemaProtocols: string[];

  static DEFAULT_TITLE_LIMIT = 50;
  static CHARACTER_LIMIT_ACTION_TITLE = 50;

  @action
  public async setDefaultValues(): Promise<void> {
    if (!this.projectId) {
      return;
    }

    const pushSettings: IGetPushSettingDTO = await this.getPushSetting();
    this.setSettings(pushSettings);
    await this.checkDefaultTitle();
  }

  public async getPushSetting(): Promise<IGetPushSettingDTO> {
    return getPushSettingUseCase.exec({ project: this.projectId });
  }

  public setSettings(pushSettings: IGetPushSettingDTO): void {
    for (let [key, value] of Object.entries(pushSettings)) {
      if (key !== "project") {
        this.getProperty(`${ key }`).setValue(value);
      }
    }

    /**
     * Default value due to migrations
     */
    if (Array.isArray(this.schemaProtocols) && this.schemaProtocols.length === 0) {
      this.getProperty('schemaProtocols').setValue(['https', 'http', 'app']);
    }
  }

  @action
  public async checkDefaultTitle(): Promise<void> {
    const {defaultTitleEnabled}= this.projectStore.appSettingStore

    const isNotDefaultTitleEnabled = defaultTitleEnabled === false;
    const fieldsAreFill = this.defaultTitle.length !== 0 || this.isDefaultTitleEnabled;

    if (isNotDefaultTitleEnabled && fieldsAreFill) {
      this.defaultTitle = "";
      this.isDefaultTitleEnabled = false;
      await this.saveSettings();
    }
  }

  public getDefaultPushActions(): PushAction[] {
    return this.pushActions
      ? this.pushActions.map(action => PushAction.createPushAction(action))
      : [];
  }

  @action
  public clearStatusDefaultTitleEnabled(): void {
    if (!this.isDefaultTitleEnabled) {
      this.defaultTitle = "";
    }
  }

  public async saveSettings(): Promise<void> {
    await updatePushSettingUseCase.exec({
      project: this.projectId,
      pushActions: this.pushActions,
      expireTime: Number(this.expireTime),
      isExpireTimeEnabled: this.isExpireTimeEnabled,
      senderStrategy: 'all',
      requireInteraction: this.requireInteraction,
      isDirectionRtl: this.isDirectionRtl,
      maxTTL: this.maxTTL,
      defaultTitle: this.defaultTitle,
      isDefaultTitleEnabled: this.isDefaultTitleEnabled,
      schemaProtocols: this.schemaProtocols,
    });
  }
}
