import * as React from 'react';
import { t } from '../../base/helpers';
import { SelectorsWizard } from './SelectorsWizard';
import { SelectorsTable } from './SelectorsTable';
import { SelectorsModal } from './SelectorsModal';
import { inject, observer } from 'mobx-react';
import { SelectorStore } from '../../stores/project/SelectorStore';
import { SectionHeader, IHeaderButton, toast } from '@ppg/styled';
import './Selectors.scss';
import { UserStore } from '../../stores/user/UserStore';
import { ProjectEmptyData } from '../../components/common';
import { AutomationProjectDataStore } from "../../stores/project/AutomationProjectDataStore";

interface ISelectorsProps {
  selectorStore?: SelectorStore;
  userStore?: UserStore;
  automationProjectDataStore?: AutomationProjectDataStore;
}

@ProjectEmptyData.wrap()
@inject('selectorStore', 'userStore', 'automationProjectDataStore')
@observer
export class SelectorsView extends React.Component<ISelectorsProps, any> {

  public async componentDidMount(): Promise<void> {
    const { selectorStore, userStore, automationProjectDataStore } = this.props;
    selectorStore.setInfinity(userStore.settings.infinity);
    await automationProjectDataStore.fetchAutomationUsedData();
    await selectorStore.fetchSelectors();
  }

  public componentWillUnmount(): void {
    this.props.selectorStore.setDefaultValues();
  }

  private handleConfirmModal = async (): Promise<void> => {
    const { selectorStore } = this.props;

    if (selectorStore.selectorToEdit.validate()) {
      toast.error(selectorStore.selectorToEdit.validate());
      return;
    }

    await selectorStore.onSelectorSave();
    this.props.automationProjectDataStore.appendUserLabel(selectorStore.selectorToEdit.labelKey, '');
    selectorStore.clearState();
  };

  public render() {
    const { selectorStore } = this.props;

    const newButton: IHeaderButton = {
      onClick: () => selectorStore.onNewSelector(),
      content: t('New selector'),
      icon: 'plus-badge',
      size: 'medium',
    };
    const wizardOpenButton: IHeaderButton = {
      onClick: () => selectorStore.toggleWizardMode(),
      content: t('Open wizard'),
      icon: 'wand',
      size: 'medium',
    };
    const wizardCloseButton: IHeaderButton = {
      onClick: () => selectorStore.toggleWizardMode(),
      content: t('Close wizard'),
      icon: 'wand',
      size: 'medium',
    };

    return (
      <section className="selectors-section">
        { selectorStore.isWizardMode
          ? <>
            <SectionHeader title={ t('Selector Wizard') }
                           subtitle={ t('Navigate to desired element and create a selector by clicking on it in Select mode.') }
                           buttons={ [wizardCloseButton] }/>
            <SelectorsWizard { ...this.props }/>
          </>

          : <>
            <SectionHeader title={ t('Your Selectors') }
                           subtitle={ t('Selector collects information about subscribers\' behaviour on your page. Values gathered by selectors can be used to update subscriber’s custom fields in automation, or to add tags based on the content of chosen element.') }
                           buttons={ selectorStore.canOpenWizard ? [newButton, wizardOpenButton] : [newButton] }/>
            <SelectorsTable/>
          </>
        }

        <SelectorsModal availableLabels={ this.props.automationProjectDataStore.labelsKeysWithoutSys }
                        modalConfirmHandler={ this.handleConfirmModal }/>
      </section>
    );
  }
}
