import * as lodash from 'lodash';
import * as React from 'react';
import DayPicker, { RangeModifier } from 'react-day-picker';
import cs from 'classnames';
import { t } from '../../base/helpers';
import { AbstractFiled } from './AbstractFiled';
import { IDateTimePickerProps } from './IDateTimePickerProps';
import { TimePicker } from './TimePicker';
import { ITimeRange } from './ITimePickerProps';
import dayjs from 'dayjs';
import { Dayjs } from 'dayjs'
const { DateUtils } = DayPicker;
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);

interface IDateTimePickerState {
  calendarVisible: boolean;
  maxDate: any;
  minDate: any;
}

const BASE_MAX_TIME_RANGE: ITimeRange = {
  hours: 23,
  minutes: 59,
};

const BASE_MIN_TIME_RANGE: ITimeRange = {
  hours: 0,
  minutes: 0,
};

export class DateTimePicker extends AbstractFiled<IDateTimePickerProps, IDateTimePickerState> {

  public state = {
    calendarVisible: false,
    maxDate: null,
    minDate: null,
  };

  public componentWillMount(): void {
    if (this.props.parse) {this.props.attribute.setValue(new Date(this.props.attribute.getValue()))}
    if (!this.props.attribute.getValue() || isNaN(this.props.attribute.getValue())) {
      this.props.attribute.setValue(new Date());
    }

    if (this.props.dateRange) {
      this.setState({
        minDate: this.props.dateRange.from,
        maxDate: this.props.dateRange.to,
      });
    }
  }

  public componentWillReceiveProps(nextProps: Readonly<IDateTimePickerProps>): void {
    if (!nextProps.dateRange) return null;
    this.setState({
      minDate: nextProps.dateRange.from,
      maxDate: nextProps.dateRange.to,
    })
  }

  public render() {
    const { attribute, showTime, displayBelow, disabled, feedBackCallback } = this.props;
    const { calendarVisible } = this.state;

    return (
      <>
        <div className="inline-block m20r">
          <div className={ cs("ppg-calendar", { "ppg-calendar--relative": displayBelow }) }>
            <div className="nowrap" onClick={ this.toggleCalendar }>
              <span className="icon-calendar m10r"/>
              <input type="text" disabled={disabled || false} className="input--medium" value={ dayjs(attribute.getValue()).format("DD/MM/YYYY") } readOnly/>
            </div>
            { calendarVisible && <div className="ppg-calendar__wrapper">
              <DayPicker
                disabledDays={ (day) => this.getDisabledDays(day) }
                selectedDays={ day => DateUtils.isSameDay(day, attribute.getValue()) }
                onDayClick={ (day) => this.onDayClick(day) }/>
              <div className="m10r m10l">
                <button className="button button--small" onClick={ this.toggleCalendar }>{ t('Apply') }</button>
              </div>
            </div> }
          </div>
        </div>
        <div className="inline-block">
          { showTime !== false &&
          <TimePicker disabled={ disabled || false }
                      date={ attribute.getValue() }
                      onChange={ this.props.onChange }
                      calculatedMax={ this.calculatedMaxRange(dayjs(attribute.getValue())) }
                      calculatedMin={ this.calculatedMinRange(dayjs(attribute.getValue())) }
                      setNewDate={ this.setNewDate }
                      feedbackCallback={ feedBackCallback }
          />
          }
        </div>
      </>
    );
  }

  public onDayClick = (day: Date) => {
    if (this.getDisabledDays(day)) {
      return;
    }
    const maxDate = dayjs(this.state.maxDate);
    const minDate = dayjs(this.state.minDate);

    const hour = dayjs(this.props.attribute.getValue()).hour();
    const minutes = dayjs(this.props.attribute.getValue()).minute();

    let newDate = dayjs(day).hour(hour).minute(minutes);

    if (minDate && newDate.isBefore(minDate)) {
      newDate = minDate;
    }

    if (maxDate && newDate.isAfter(maxDate)) {
      newDate = maxDate;
    }

    this.setNewDate(newDate);
    if (this.props.feedBackCallback) {
      this.props.feedBackCallback(true);
    }
  };

  public setNewDate = (date: Dayjs) => {
    if (date.isValid()) {
      const selectedDate = dayjs(date);

      if (this.isInDateRange(selectedDate)) {
        this.props.attribute.setValue(selectedDate.toDate());
        lodash.invoke(this.props, 'onChange', null);
      } else {
        if (this.props.feedBackCallback) {
          this.props.feedBackCallback(false);
        }
      }
    }
  };

  public isInDateRange = (selectedDate: Dayjs) => {
    if (!this.props.dateRange) {
      return true;
    }

    const maxDate = dayjs(this.state.maxDate);
    const minDate = dayjs(this.state.minDate);

    const isInRange = !selectedDate.isBefore(minDate, 'minute') && !selectedDate.isAfter(maxDate, 'minute');

    if (this.props.feedBackCallback) {
      this.props.feedBackCallback(isInRange);
    }

    return isInRange;
  };

  public calculatedMaxRange(selectedDate: Dayjs): ITimeRange {
    if (!this.props.dateRange || !this.props.dateRange.to) {
      return BASE_MAX_TIME_RANGE;
    }
    const rangeTo = dayjs(this.props.dateRange.to);
    const isSameDay = selectedDate.isSame(rangeTo, 'day');

    if (!isSameDay) {
      return BASE_MAX_TIME_RANGE;
    }

    const isSameHour = rangeTo.hour() === selectedDate.hour();

    return {
      minutes: isSameHour ? rangeTo.minute() : BASE_MAX_TIME_RANGE.minutes,
      hours: rangeTo.hour(),
    }
  }

  public calculatedMinRange(selectedDate: Dayjs): ITimeRange {
    if (!this.props.dateRange || !this.props.dateRange.from) {
      return BASE_MIN_TIME_RANGE;
    }
    const rangeFrom = dayjs(this.props.dateRange.from);
    const isSameDay = selectedDate.isSame(rangeFrom, 'day');

    if (!isSameDay) {
      return BASE_MIN_TIME_RANGE;
    }

    const isSameHour = rangeFrom.hour() === selectedDate.hour();

    return {
      minutes: isSameHour ? rangeFrom.minute() : BASE_MIN_TIME_RANGE.minutes,
      hours: rangeFrom.hour(),
    };
  }

  public getDisabledDays = (day: Date) => {
    const { dateRange, disablePastDays } = this.props;
    return (dateRange && !DateUtils.isDayInRange(day, dateRange as RangeModifier)) || (disablePastDays && DateUtils.isPastDay(day));
  };

  public toggleCalendar = () => {
    if (this.props.disabled) return;
    this.setState({ calendarVisible: !this.state.calendarVisible });
  }
}
