import { PlatformType } from './interfaces';

/**
 * Returns platform type path
 * @param platform - platform type
 */

export function getPlatformTypeParam(platform: PlatformType) {
  return platform ? `/${ platform }` : '';
}

/**
 * Returns date range params path
 * @param from - string date
 * @param to - string date
 */

export function getRangeDateParam(from: string, to: string) {
  return `?from=${ from }&to=${ to }`;
}

export function getTimezoneParam(timezone: string) {
  return `&timezone=${ timezone }`;
}

/**
 * Return pagination params path
 * @param offset
 * @param limit
 */
export function getListQueryParam(offset: number, limit: number){
  return `&offset=${ offset }&limit=${ limit }`
}