import { PushCampaignContent } from './PushCampaignContent';
import { SegmentPushCampaignOptions } from './SegmentPushCampaignOptions';
import { PushCampaignOptions } from './PushCampaignOptions';
import { PolygonsPushCampaignOptions } from './PolygonsPushCampaignOptions';
import { LabelsPushCampaignOptions } from './LabelsPushCampaignOptions';
import { Label, LabelCountStrategy } from '../Label';
import { property } from '@ppg/common';
import { action } from 'mobx';
import { t } from '../../base/helpers';
import { IPushSuggestions } from '../../stores/project/CreatePushStore';
import { Polygon } from '../Polygon';
import { toast } from '@ppg/styled';
import { ISegmentDTO } from '../../useCases/core/campaigns/interfaces';

export enum CampaignType {
  ROCKET = "rocket",
  SEGMENTATION = "segmentation",
  GEOLOCATION = "geolocation",
  TEMPORARY_LABELS = "temporary_labels",
}

interface IPushCampaign {
  content: PushCampaignContent;
  options: PushCampaignOptions | SegmentPushCampaignOptions | PolygonsPushCampaignOptions | LabelsPushCampaignOptions;
  state: string;
}

export class PushCampaign implements IPushCampaign {
  @property() public content: PushCampaignContent;
  @property() public options: PushCampaignOptions | SegmentPushCampaignOptions | PolygonsPushCampaignOptions | LabelsPushCampaignOptions;
  @property() public state: string;

  constructor(content, options, state) {
    this.content = content;
    this.options = options;
    this.state = state;
  }

  public get campaignType(): CampaignType {
    if (this.options instanceof SegmentPushCampaignOptions) {
      return CampaignType.SEGMENTATION;
    }

    if (this.options instanceof PolygonsPushCampaignOptions) {
      return CampaignType.GEOLOCATION;
    }

    if (this.options instanceof LabelsPushCampaignOptions) {
      return CampaignType.TEMPORARY_LABELS;
    }

    return CampaignType.ROCKET;
  }

  public get segmentOption(): SegmentPushCampaignOptions {
    return this.options as SegmentPushCampaignOptions;
  }

  public get labelsOption(): LabelsPushCampaignOptions {
    return this.options as LabelsPushCampaignOptions;
  }

  public get polygonsOption(): PolygonsPushCampaignOptions {
    return this.options as PolygonsPushCampaignOptions;
  }

  @action
  public removeAllTargets(): void {
    this.options = new PushCampaignOptions(this.options.sentBy, this.options.fromAPI);
  }

  @action
  public setSegment(segment: ISegmentDTO): void {
    this.options = new SegmentPushCampaignOptions(this.options.sentBy, this.options.fromAPI, segment);
  }

  @action
  public setLabels(labels: Label[], excludedLabels: Label[], labelsStrategy: LabelCountStrategy, excludedLabelsStrategy: LabelCountStrategy): void {
    this.options = new LabelsPushCampaignOptions(this.options.sentBy, this.options.fromAPI, labels, excludedLabels, labelsStrategy, excludedLabelsStrategy);
  }

  @action
  public setPolygons(polygons: Polygon[]): void {
    this.options = new PolygonsPushCampaignOptions(this.options.sentBy, this.options.fromAPI, polygons);
  }

  public suggestions(geolocationEnabled: boolean, utmDisabled: boolean): IPushSuggestions[] {
    const items = [
      {
        description: t('How about targeting the campaign?'), condition: !this
          .audienceIsTargeted, scrollToSection: "audience"
      },
      { description: t('How about enabling the require interaction option?'), condition: !this.content.requireInteraction, scrollToSection: "content" },
      {
        description: t('You have enabled require interaction option but didn\'t specify any actions - how about adding some ?'),
        condition: this.content.requireInteraction && this.content.actions.length === 0,
        scrollToSection: "content"
      },
      {
        description: t(`You are targeting your campaign by geolocation but geolocation option is disabled in your project settings - how about enabling it?`),
        condition: this.polygonsOption.polygons && this.polygonsOption.polygons.length !== 0 && !geolocationEnabled,
        scrollToSection: "audience"
      },
      {
        description: t('In UTMs your campaign\'s name equals "CampaignName" - how about changing it?'), condition: this.content.hasDefaultUTMCampaignName(utmDisabled),
        scrollToSection: "content"
      },
    ];

    return items.filter(item => item.condition);
  }

  // validation

  public get audienceIsTargeted(): boolean {
    return this.campaignType !== CampaignType.ROCKET;
  }

  public validOneTargetAudience(targetType): boolean {
    switch (targetType) {
      case CampaignType.SEGMENTATION:
        return this.labelsOption.hasLabels || this.polygonsOption.hasPolygons;
      case CampaignType.TEMPORARY_LABELS:
        return this.segmentOption.hasSegment || this.polygonsOption.hasPolygons;
      case CampaignType.GEOLOCATION:
        return this.segmentOption.hasSegment || this.labelsOption.hasLabels;
      case CampaignType.ROCKET:
        break;
    }
  }

  public contentInSendCampaignIsValid(): boolean {
    this.content.validate();

    if (!this.content.isTitleValid || !this.content.isContentValid) {
      toast.error(t('The title and message are required in the campaign'));
      return false;
    }

    return true;
  }

  public actionsInSendCampaignIsValid(): boolean {
    if(!this.content.isActionsValid) {
      toast.error(t('Push action title and link are required in the campaign'));
      return false;
    }

    return true;
  }

  public sendDateInSendCampaignIsValid(): boolean {
    if (this.content.isSendTypeNow) return true;
    this.content.validateSendDate();

    if (!this.content.isSendDateValid) {
      toast.error(t('Campaign send date is in the past, please correct it'));
      return false;
    }

    return true;
  }

  public delayTimeInSendCampaignIsValid(): boolean {
    if (this.content.isSendTypeNow) return true;
    this.content.validateDelayTime();

    if (!this.content.isDelayTimeValid) {
      toast.error(t('Max campaign delay time is 10 hours'));
      return false;
    }

    return true;
  }
}
