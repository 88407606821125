import { property, PropertyHandler } from '@ppg/common';
import { action, computed } from 'mobx';
import { CAMPAIGN_MONITOR_TIME, DUMMY_ICON_CDN } from '../constants';
import dayjs from 'dayjs';
import { Image } from './Image';
import { CampaignType } from './campaigns/PushCampaign';
import { t } from '../base/helpers';

export interface IPushCampaign {
  id: string;
  title: string;
  content: string;
  redirectLink: string;
  state: CampaignState;
  sendDate: string;
  expireDate: string;
  sent: number;
  delivered: number;
  clicked: number;
  ctr: number;
  sentBy: string;
  icon: Image;
  cappedSent: number;
  cappedDelivered: number;
  campaignType: string;
}

export enum CampaignState {
  READY = 'ready',
  ABX = 'abx',
  DRAFT = 'draft',
  SENDING = 'sending',
  GLOBAL = 'global',
  SENT = 'sent',
  FAILED = 'failed',
  INQUEUE = 'inqueue',
  SUSPENDED = 'suspended',
  TRIGGERED = 'triggered',
  STOPPED = 'stopped',
  BLOCKED = 'blocked'
}

export enum CampaignDirectionType {
  AUTO = 'auto',
  LTR = 'ltr',
  RTL = 'rtl',
}

/**
 * This model is used for listing pushCampaigns
 */
export class PushCampaign extends PropertyHandler {
  @property() public id: string;
  @property() public title: string;
  @property() public content: string;
  @property() public redirectLink: string;
  @property() public state: CampaignState;
  @property() public sendDate: string;
  @property() public expireDate: string;
  @property() public sent: number;
  @property() public delivered: number;
  @property() public clicked: number;
  @property() public ctr: number;
  @property() public sentBy: string;
  @property() public icon: Image;

  @property() public cappedSent: number;
  @property() public cappedDelivered: number;

  @property() public campaignType: string;

  constructor(campaign: IPushCampaign) {
    super();
    this.id = campaign.id;
    this.title = campaign.title;
    this.content = campaign.content;
    this.redirectLink = campaign.redirectLink;
    this.state = campaign.state;
    this.sendDate = campaign.sendDate;
    this.expireDate = campaign.expireDate;
    this.sent = campaign.sent;
    this.delivered = campaign.delivered;
    this.clicked = campaign.clicked;
    this.ctr = campaign.ctr;
    this.sentBy = campaign.sentBy;
    this.icon = campaign.icon ? new Image(campaign.icon) : null;
    this.cappedSent = campaign.cappedSent;
    this.cappedDelivered = campaign.cappedDelivered;
    this.campaignType = campaign.campaignType;
  }

  @computed
  public get capped(): number {
    return this.cappedDelivered + this.cappedSent;
  }

  @computed
  public get hasCapped(): boolean {
    return this.capped > 0;
  }

  @computed
  public get isNormal(): boolean {
    return this.state !== CampaignState.TRIGGERED;
  }

  @computed
  public get needsMonitor(): boolean {
    return this.state === CampaignState.SENDING || this.willBeSentSoon;
  }

  @action
  public setCampaignState(state: CampaignState): void {
    this.state = state;
  }

  @computed
  public get willBeSentSoon(): boolean {
    if (this.state !== CampaignState.READY) {
      return false;
    }
    const now = dayjs();
    const sendDateWrapped = dayjs(this.sendDate);
    const timeDifference = sendDateWrapped.diff(now);
    return timeDifference <= CAMPAIGN_MONITOR_TIME;
  }

  @computed
  public get hasExpireDate(): boolean {
    return (this.expireDate !== null) && (this.expireDate !== undefined);
  }

  public get timeToBeSent(): number {
    const now = dayjs();
    const sendDateWrapped = dayjs(this.sendDate);
    return sendDateWrapped.diff(now);
  }

  public getIconURL(): string {
    return this.icon?.url || DUMMY_ICON_CDN;
  }

  public get getCampaignType(): string {
    switch (this.campaignType) {
      case CampaignType.SEGMENTATION:
        return t('campaign/list/pushes/all::segment');
      case CampaignType.TEMPORARY_LABELS:
        return t('campaign/list/pushes/all::labels');
      case CampaignType.GEOLOCATION:
        return t('campaign/list/pushes/all::geolocations');
    }
  }
}
