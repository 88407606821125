import { Model } from '../base';
import { property, validate } from '../base/decorators';
import { t } from '../base/helpers';

export interface IPushAction {
  title: string;
  link: string;
  useRedirectLink: boolean;
}

export class PushAction extends Model<IPushAction, IPushAction> implements IPushAction {

  @validate('required', { label: () => t('Title') })
  @property()
  public title: string;

  @validate('required', { label: () => t('Link') })
  @property()
  public link: string;

  @property()
  public useRedirectLink: boolean;

  private previousLink: string;

  public defaults(): IPushAction {
    return {
      title: '',
      link: '',
      useRedirectLink: false,
    };
  }

  get actionLink() {
    return this.link;
  }

  public setRedirectLink() {
    this.setPreviousLink();
    this.link = this.useRedirectLink ? '{{redirectLink}}' : this.previousLink || '';
  }

  public setPreviousLink() {
    if (!!this.useRedirectLink) {
      this.previousLink = this.link;
    }
  }

  public appendNewCampaignName(url: string, newCampaignName: string) {
    const newUrl = new URL(url);
    newUrl.searchParams.set('utm_campaign', newCampaignName);
    return newUrl.toString();
  }

  public fromActionLink(link: string) {
    try {
      this.link = link.toString();
    } catch (error) {
    }
  }

  public parseAction() {
    return {
      title: this.title,
      link: this.actionLink
    };
  }

  public toTest() {
    return {
      title: this.title,
      action: this.actionLink
    };
  }
}
