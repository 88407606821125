import { API, UseCase } from '@ppg/common';

export interface IAutomationsCampaignsItem {
  id: string;
  name: string;
  campaigns: string[];
}

interface IGetProjectAutomationsCampaignsResult {
  automations: IAutomationsCampaignsItem[];
  enabledScenarios: number
}

export class GetProjectAutomationCampaignsUseCase extends UseCase<void, IGetProjectAutomationsCampaignsResult> {
  protected async execute() {
    return API.HTTP.get<IGetProjectAutomationsCampaignsResult>(`statistics/projects/${ API.HTTP.currentProjectId }/automations/active`);
  }
}
export const getProjectAutomationCampaignsUseCase = new GetProjectAutomationCampaignsUseCase();
