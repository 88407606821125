import { API, UseCase } from '@ppg/common';
import { ISendPushCampaign, ICampaignIdResponse } from '../interfaces';

interface ISendLabelPushRequest extends ISendPushCampaign {}
interface ISendLabelPushResponse extends ICampaignIdResponse {}

export class SendLabelPushUseCase extends UseCase<ISendLabelPushRequest, ISendLabelPushResponse> {
  protected async execute({ message, options }: ISendLabelPushRequest) {
    return API.HTTP.post<ISendLabelPushResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/labels`, { message, options });
  }
}

export const sendLabelPushUseCase = new SendLabelPushUseCase();
