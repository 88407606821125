import { action, computed, observable } from 'mobx';
import { UserRelatedStore } from './user/UserRelatedStore';
import { getPartnershipUseCase, IPartnership } from '../useCases/accounting/getPartnershipUseCase';
import { property } from '@ppg/common';
import { getPartnershipPartners, IPartner } from '../useCases/accounting/getPartnershipPartnersUseCase';
import { ITEMS_PER_PAGE } from '../constants';

export class PartnershipStore extends UserRelatedStore {
  @observable public partnership: IPartnership;
  @observable public partners: IPartner[];
  @observable public limit: number = ITEMS_PER_PAGE;
  @observable public offset: number = 0;
  @observable public total: number = 0;
  @observable public maxWithdrawMonths: number = 3;
  @property() public code: string;
  @property() public infinity: boolean;

  @action
  public fetchPartnership = async (): Promise<void> => {
    this.partnership = await getPartnershipUseCase.exec({
      organization: this.userStore.user.organization
    })
    this.code = this.partnership.code
  }

  @action
  public fetchPartners = async (): Promise<void> => {
     await getPartnershipPartners.exec({
      partnershipOrganization: this.userStore.user.organization,
      limit: this.limit,
      offset: this.offset,
    }).then(({ metadata, data }) => {
       this.total = metadata.total;
       this.partners = this.infinity ? this.partners.concat(data) : data;
    });

  };

  @action
  public getInvoicesStatus = (invoicesLength:number): IInvoiceStatus[] => {
    const invoicesStatus:IInvoiceStatus[] = [];

    for(let i = 1; i <= this.maxWithdrawMonths; i++) invoicesStatus.push({paid: i <= invoicesLength})
    return invoicesStatus;
  }

  @computed
  public get paginatedPartnersList(): IPartner[] {
    return this.partners.slice(this.offset, Math.floor((this.offset / this.limit) + 1) * this.limit);
  }
}

interface IInvoiceStatus {
  paid: boolean
}
