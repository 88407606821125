import { API, UseCase } from '@ppg/common';
import { IUpdatePlugin } from '../interfaces';

export class UpdateGeolocationPluginUseCase extends UseCase<IUpdatePlugin, void> {
  protected async execute({ websiteIntegrationId, enabled }: IUpdatePlugin) {
    await API.HTTP.put(`wi/projects/${ API.HTTP.currentProjectId }/website-integrations/${ websiteIntegrationId }/plugins/geolocation`, { enabled });
  }
}

export const updateGeolocationPluginUseCase = new UpdateGeolocationPluginUseCase();
