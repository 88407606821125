import { API, UseCase } from '@ppg/common';

export const enum OrganizationTypes {
  LITE = 'lite',
  STANDARD = 'standard'
}

interface GetUserDetailsRequest {
}

interface GetUserDetailsResponse {
  readonly username: string;
  readonly role: string;
  readonly organization: string;
  readonly organizationType: string;
  readonly blocked: boolean;
  readonly id: string;
}

class GetUserDetailsUseCase extends UseCase<GetUserDetailsRequest, GetUserDetailsResponse> {
  protected async execute() {
    return API.HTTP.get<GetUserDetailsResponse>(`aai/user`);
  }
}

export const getUserDetailsAAIUseCase = new GetUserDetailsUseCase();
