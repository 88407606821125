import { DefaultOptionType, merge, property, TimeUnit } from '@ppg/common';
import { t } from '../../base/helpers';
import { ISelectOption } from '@ppg/styled';
import { BrowserTypes, DeviceTypes, SystemTypes } from '../../modelsMobx/SystemLabelWrapperEnums';
import { action, computed, observable, reaction } from 'mobx';
import { LineChartData } from '../../components/Charts/LineChart/LineChart';
import * as UseCase from '../../useCases/statistics/organization';
import dayjs from 'dayjs';
import { FunnelDatum } from '@nivo/funnel/dist/types/types';
import { OrganizationDashboardRelated } from './OrganizationDashboardRelated';
import { REDUCED_ITEMS_PER_PAGE } from '../../constants';
import {
  IActiveSubscribersRatioHistogramItem
} from '../../useCases/statistics/organization/subscribers/GetActiveSubscribersRatioHistogramUseCase';
import { SubscriberLocalizationBuckets } from './SubscriberLocalizationBuckets';
import { IActiveSubscribersHistogramItem, IGetSubscribersStructureResult, ILifecycleStructure, ISubscriberActivityHistogramItem, ISubscribersActivityHistogram } from '../../useCases/interfaces';

export enum DisplayType {
  TABLE = 'table',
  CHART = 'chart',
}

export class OrganizationDashboardSubscriberStore extends OrganizationDashboardRelated {
  @property()
  public systemSelect: SystemTypes | DefaultOptionType = DefaultOptionType.ALL;

  @property()
  public browserSelect: BrowserTypes | DefaultOptionType = DefaultOptionType.ALL;

  @property()
  public deviceSelect: DeviceTypes | DefaultOptionType = DefaultOptionType.ALL;

  /*
   * Charts
   * */

  @observable
  public generalChartData: LineChartData = [];

  @observable
  public subscribersCountChartData: LineChartData = [];

  @observable
  public activeSubscribersChartData: LineChartData = [];

  @observable
  public subscriberLifecycleChartData: FunnelDatum[] = [];

  @observable
  public subscribersStructureChartData: IGetSubscribersStructureResult = null;

  @observable
  public subscriberLocalizationBuckets: SubscriberLocalizationBuckets = new SubscriberLocalizationBuckets();

  cleanGeneralSubscribersData = (): void => {
    this.generalChartData = [];
    this.subscribersCountChartData = [];
    this.generalSubscriberActivityTableData = [];
  };

  cleanLocalizationMapData = (): void => {
    this.subscriberLocalizationBuckets = new SubscriberLocalizationBuckets();
  };

  cleanLifecycleData = (): void => {
    this.subscriberLifecycleChartData = [];
    this.subscriberLifecycleTableData = [];
  };

  cleanRatioData = (): void => {
    this.activeSubscribersRatioTableData = [];
    this.activeSubscribersChartData = [];
  };

  /*
   * Tables
   * */

  @property()
  public limit: number = REDUCED_ITEMS_PER_PAGE;

  @property()
  public offset: number = 0;

  @property()
  public total: number = 0;

  @observable
  public generalSubscriberActivityTableData: ISubscribersActivityHistogram[] = [];

  @property()
  public subscriberActivityOffset: number = 0;

  @property()
  public subscriberActivityTotal: number = 0;

  @observable
  public generalActiveSubscribersTableData: IActiveSubscribersHistogramItem[] = [];

  @property()
  public activeSubscribersOffset: number = 0;

  @property()
  public activeSubscribersTotal: number = 0;

  @observable
  public activeSubscribersRatioTableData: IActiveSubscribersRatioHistogramItem[] = [];

  @observable
  public subscriberLifecycleTableData: FunnelDatum[] = [];

  /*
   * Stats
   * */

  @observable
  public subscribersAverageLifetime: number = 0;

  @observable
  public subscribersAverageLifetimeUnit: TimeUnit = TimeUnit.MINUTE;

  @observable
  public activeSubscribers: number = 0;

  @observable
  public iosActiveSubscribers: number = 0;

  @observable
  public androidActiveSubscribers: number = 0;

  @observable
  public huaweiActiveSubscribers: number = 0;


  @observable
  public subscribed: number = 0;

  @observable
  public unsubscribed: number = 0;

  @observable
  public maxProjectsCount: number = 0;

  /*
   * Loaders
   * */

  @observable
  public isSubscribersAverageLifetimeLoading: boolean = true;

  @observable
  public isActiveSubscribersLoading: boolean = true;

  @observable
  public isSubscribedActivityLoading: boolean = true;

  @observable
  public isMaxProjectsCountLoading: boolean = true;

  setDefaultValues() {
    this.clearSelectOptions();
    this.clearTables();
    this.clearCharts();
    this.clearLoaders();
    this.clearStats();
    this.clearOffsets();
  }

  reactOnRangeChange = reaction(
    () => this.rangeChangeFactors,
    () => this.clearOffsets(),
  );

  reactOnTabChange = reaction(
    () => this.activeTab,
    () => this.clearOffsets(),
  );

  /*
   * Subscribers stats
   * */

  @action
  public onPlatformAndRangeDateChangeHandler = async (): Promise<void> => {
    if (!this.isPlatformSelected) {
      this.clearStats();
      return;
    }

    await Promise.all([
      this.fetchSubscribedActivity(),
      this.fetchMaxProjectsCount(),
    ]);
  };

  @action
  public onPlatformChangeHandler = async (): Promise<void> => {
    if (!this.isPlatformSelected) {
      this.clearStats();
      return;
    }

    await Promise.all([
      this.fetchOrganizationActiveSubscribers(),
      this.fetchSubscribersAverageLifetime()
    ]);
  };

  @action
  public fetchOrganizationSubscribersStructure = async (): Promise<void> => {
    if (!this.bothPlatformsEnabled) {
      return this.subscribersStructureChartData = null;
    }
    this.subscribersStructureChartData = await UseCase.getOrganizationSubscribersStructureUseCase.exec({ ...this.organizationRequestBody });
  };

  @action
  public fetchSubscribersAverageLifetime = async (): Promise<void> => {
    this.isSubscribersAverageLifetimeLoading = true;

    const {
      subscribersAverageLifetime,
      unit
    } = await UseCase.getSubscribersAverageLifetimeUseCase.exec({ ...this.organizationRequestBody });

    this.subscribersAverageLifetime = subscribersAverageLifetime;
    this.subscribersAverageLifetimeUnit = unit;
    this.isSubscribersAverageLifetimeLoading = false;
  };

  @action
  public fetchOrganizationActiveSubscribers = async (): Promise<void> => {
    this.isActiveSubscribersLoading = true;

    const {
      activeSubscribers,
      iosActiveSubscribers = 0,
      huaweiActiveSubscribers = 0,
      androidActiveSubscribers = 0
    } = await UseCase.getOrganizationActiveSubscribersUseCase.exec({ ...this.organizationRequestBody });

    this.activeSubscribers = activeSubscribers;
    this.iosActiveSubscribers = iosActiveSubscribers;
    this.huaweiActiveSubscribers = huaweiActiveSubscribers;
    this.androidActiveSubscribers = androidActiveSubscribers;
    this.isActiveSubscribersLoading = false;
  };

  @action
  public fetchSubscribedActivity = async (): Promise<void> => {
    this.isSubscribedActivityLoading = true;

    const { subscribersActivity } = await UseCase.getSubscribedActivityUseCase.exec({ ...this.organizationRequestBody });

    this.subscribed = subscribersActivity.subscribed;
    this.unsubscribed = subscribersActivity.unsubscribed;
    this.isSubscribedActivityLoading = false;
  };

  @action
  public fetchMaxProjectsCount = async (): Promise<void> => {
    this.isMaxProjectsCountLoading = true;

    const { maxProjectCount } = await UseCase.getActiveSubscribersInRangeUseCase.exec({ ...this.organizationRequestBody });

    this.maxProjectsCount = maxProjectCount;
    this.isMaxProjectsCountLoading = false;
  };

  /*
   * General
   * */

  @action
  public fetchGeneralSubscriberLineChartData = async (): Promise<void> => {
    const { subscribersActivity } = await UseCase.getSubscribedActivityUseCase.exec({ ...this.organizationRequestBody });
    const { from, to } = this.requestDateRange;
    const subscribed = this.parseSubscriberActivityHistogramToLineChartData(subscribersActivity.subscribedHistogram, t('Subscribed'), from, to);
    const unsubscribed = this.parseSubscriberActivityHistogramToLineChartData(subscribersActivity.unsubscribedHistogram, t('Unsubscribed'), from, to);
    this.generalChartData = subscribed.concat(unsubscribed);
  };

  @action
  public parseSubscriberActivityHistogramToLineChartData = (histogram: ISubscriberActivityHistogramItem[], id: string, from: Date, to: Date): LineChartData => {
    let data = [];
    if (histogram.length === 0) {
      return [{ id, data }];
    }

    for (let date = dayjs(from); date.isBefore(to); date = dayjs(date).add(1, 'days')) {
      let dayDate = date.format('YYYY-MM-DD');
      const dayItem = histogram.find(day => day.day === dayDate);
      data = data.concat({ x: dayDate, y: dayItem?.activity ?? 0 });
    }

    return [{ id, data }];
  };

  @action
  public fetchGeneralSubscriberCountLineChartData = async (): Promise<void> => {
    const { data } = await UseCase.getActiveSubscribersInRangeUseCase.exec({ ...this.organizationRequestBody });
    const { from, to } = this.requestDateRange;
    let countData = this.parseAndAddMissingDays(data, from, to, 'x', { y: 0 }, 'y');
    this.subscribersCountChartData = [{ id: t('organization/dashboard/subscribers::Count'), data: countData }];
  };

  @computed
  public get subscribersCountCustomY(): { max: number, min: number } | null {
    const countChart = this.subscribersCountChartData.find(d => d.id === t('organization/dashboard/subscribers::Count'));
    if (!countChart) {
      return null;
    }

    const y: number[] = countChart.data.map(d => d.y);
    const max = Math.max(...y);
    const min = Math.min(...y);

    let maxMargin = (10 - this.restFromTen(max)) || 10;
    let minMargin = 10 + (max - min);

    const maxValue = max + maxMargin;

    let minValue = (min - minMargin < 0) ? 0 : min - minMargin;

    minValue -= this.restFromTen(minValue);

    return {
      max: maxValue,
      min: minValue || 0,
    };
  }

  private restFromTen(value: number): number {
    return value % 10;
  }

  @action
  public fetchGeneralSubscriberActivityTableData = async (): Promise<void> => {
    const { subscribersActivity } = await UseCase.getSubscribersActivityHistogramUseCase.exec({
      ...this.organizationRequestBody,
      limit: this.limit,
      offset: this.subscriberActivityOffset,
    });

    this.subscriberActivityTotal = dayjs(this.organizationRequestBody.to).diff(this.organizationRequestBody.from, 'days') + 1;
    const data = merge([...subscribersActivity.subscribedHistogram, ...subscribersActivity.unsubscribedHistogram], 'day');

    if (data.length === 0) {
      this.generalSubscriberActivityTableData = [];
      return;
    }

    const { from, to } = this.calculateListRange(
      this.requestDateRange.from,
      this.requestDateRange.to,
      this.limit,
      this.subscriberActivityOffset,
    );

    let result = [];
    for (let date = dayjs(from); date.isBefore(to); date = dayjs(date).add(1, 'days')) {
      let dayDate = date.format('YYYY-MM-DD');
      const dayItem = data.find(day => day.day === dayDate);
      result = result.concat({
        day: dayDate,
        subscribed: dayItem?.subscribed ?? 0,
        unsubscribed: dayItem?.unsubscribed ?? 0
      });
    }

    this.generalSubscriberActivityTableData = result;
  };

  @action
  public fetchGeneralActiveSubscribersTableData = async (): Promise<void> => {
    const { activeSubscribers } = await UseCase.getActiveSubscribersHistogramUseCase.exec({
      ...this.organizationRequestBody,
      limit: this.limit,
      offset: this.activeSubscribersOffset
    });

    this.activeSubscribersTotal = activeSubscribers.total;
    this.generalActiveSubscribersTableData = activeSubscribers.histogram;
  };

  /*
   * Active subscribers ratio
   * */

  @action
  public fetchSubscribersRatioChartData = async (): Promise<void> => {
    return this.undifferentiatedPlatforms(this.getActiveSubscribersRatioLineChartData, this.getProperty('activeSubscribersChartData'));
  };

  @action
  public fetchActiveSubscribersRatioTableData = async (): Promise<void> => {
    return this.undifferentiatedPlatforms(this.getActiveSubscribersRatioTableData, this.getProperty('activeSubscribersRatioTableData'));
  };

  @action
  public getActiveSubscribersRatioLineChartData = async (): Promise<void> => {
    const { deliveredActiveSubscribersRatios } = await UseCase.getActiveSubscribersRatioUseCase.exec({ ...this.organizationRequestBody });

    this.activeSubscribersChartData = [{
      id: t('organization/dashboard/subscribers::Ratio'),
      data: deliveredActiveSubscribersRatios.map(activeLog => {
        return {
          x: activeLog.day,
          y: activeLog.deliveredRatio
        };
      })
    }
    ];
  };

  @action
  public getActiveSubscribersRatioTableData = async (): Promise<void> => {
    const { deliveredActiveSubscribersRatio } = await UseCase.getActiveSubscribersRatioHistogramUseCase.exec({
      ...this.organizationRequestBody,
      offset: this.offset,
      limit: this.limit
    });

    this.total = deliveredActiveSubscribersRatio.total;
    this.activeSubscribersRatioTableData = deliveredActiveSubscribersRatio.histogram;
  };

  /*
   * Lifecycle
   * */

  @action
  public fetchSubscriberLifecycle = async (): Promise<void> => {
    const { subscribersLifetimeStructure } = await UseCase.getSubscribersLifetimeStructureUseCase.exec({ ...this.organizationRequestBody });

    this.subscriberLifecycleChartData = this.parseLifetimeStructureToDatum(subscribersLifetimeStructure, DisplayType.CHART);
    this.subscriberLifecycleTableData = this.parseLifetimeStructureToDatum(subscribersLifetimeStructure, DisplayType.TABLE);
  };

  @action
  public parseLifetimeStructureToDatum = (subscribersLifetimeStructure: ILifecycleStructure[], type: DisplayType): FunnelDatum[] => {
    const MAX_BUCKET = '1440';
    return subscribersLifetimeStructure
      .sort((a, b) => parseInt(a.bucket) - parseInt(b.bucket))
      .map((lifecycleItem, i) => {
        let nextBucket = subscribersLifetimeStructure[i + 1] ? subscribersLifetimeStructure[i + 1].bucket : '';

        let labelDefault = type === DisplayType.CHART
          ? t(`organization/dashboard/subscribers::of subscribers live from %{bucket} to %{nextBucket} days`, {
            bucket: lifecycleItem.bucket,
            nextBucket: nextBucket
          })
          : t(`organization/dashboard/subscribers::from %{bucket} to %{nextBucket} days`, {
            bucket: lifecycleItem.bucket,
            nextBucket: nextBucket
          });
        let labelOver = type === DisplayType.CHART
          ? t(`organization/dashboard/subscribers::of subscribers live over %{bucket} days`, { bucket: lifecycleItem.bucket })
          : t(`organization/dashboard/subscribers::over %{bucket} days`, { bucket: lifecycleItem.bucket });
        let labelMax = type === DisplayType.CHART
          ? t('organization/dashboard/subscribers::of subscribers live over %{bucket}', { bucket: lifecycleItem.bucket })
          : t('organization/dashboard/subscribers::% of subscribers live over %{bucket}', { bucket: lifecycleItem.bucket });

        let fromToBucketLabel = !!nextBucket
          ? labelDefault
          : labelOver;
        const bucketLabel = lifecycleItem.bucket === MAX_BUCKET
          ? labelMax
          : fromToBucketLabel;
        return {
          id: `${ lifecycleItem.bucket }`,
          label: bucketLabel,
          value: lifecycleItem.total
        };
      })
      .filter(lifecycleItem => lifecycleItem.value > 0);
  };

  /*
   * Localization
   * */

  @action
  public fetchLocalizationChartData = async (): Promise<void> => {
    return this.undifferentiatedPlatforms(this.getLocalizationChartData, this.getProperty('subscriberLocalizationBuckets'), new SubscriberLocalizationBuckets());
  };

  @action
  public getLocalizationChartData = async (): Promise<void> => {
    this.subscriberLocalizationBuckets.isLoading = true;
    const { averageSubscribersLocalisations } = await UseCase.getSubscribersLocalisationUseCase.exec({ ...this.organizationRequestBody });
    this.subscriberLocalizationBuckets.setFromLocalization(averageSubscribersLocalisations.localisations);
  };

  /*
   * Options
   * */

  @computed
  public get systemOptions(): ISelectOption[] {
    return [
      { value: [DefaultOptionType.ALL], name: t('All systems') },
      { value: [SystemTypes.WINDOWS], name: t('Windows') },
      { value: [SystemTypes.MAC_OS], name: t('Mac Os') },
      { value: [SystemTypes.LINUX], name: t('Linux') },
    ];
  }

  @computed
  public get browserOptions(): ISelectOption[] {
    return [
      { value: [DefaultOptionType.ALL], name: t('All browsers') },
      { value: [BrowserTypes.CHROME], name: t('Chrome') },
      { value: [BrowserTypes.FIREFOX], name: t('Firefox') },
    ];
  }

  @computed
  public get deviceOptions(): ISelectOption[] {
    return [
      { value: DefaultOptionType.ALL, name: t('All devices') },
      { value: [DeviceTypes.MOBILE], name: t('Mobile') },
      { value: [DeviceTypes.DESKTOP], name: t('Desktop') },
      { value: [DeviceTypes.TABLET], name: t('Tablet') },
    ];
  }

  /*
   * Clears
   * */

  @action
  public clearTables = (): void => {
    this.subscriberLifecycleTableData = [];
    this.generalSubscriberActivityTableData = [];
    this.activeSubscribersRatioTableData = [];
  };

  @action
  public clearOffsets = (): void => {
    this.activeSubscribersOffset = 0;
    this.subscriberActivityOffset = 0;
    this.offset = 0;
  };

  @action
  public clearCharts = (): void => {
    this.subscriberLifecycleChartData = null;
    this.generalChartData = null;
    this.activeSubscribersChartData = null;
    this.subscribersStructureChartData = null;
  };

  @action
  public clearLoaders = (): void => {
    this.isSubscribersAverageLifetimeLoading = true;
    this.isActiveSubscribersLoading = true;
    this.isSubscribedActivityLoading = true;
    this.isMaxProjectsCountLoading = true;
  };

  @action
  public clearStats = (): void => {
    this.subscribersAverageLifetime = 0;
    this.activeSubscribers = 0;
    this.iosActiveSubscribers = 0;
    this.androidActiveSubscribers = 0;
    this.huaweiActiveSubscribers = 0;
    this.subscribed = 0;
    this.unsubscribed = 0;
    this.maxProjectsCount = 0;

  };

  @action
  private clearSelectOptions = (): void => {
    this.systemSelect = DefaultOptionType.ALL;
    this.browserSelect = DefaultOptionType.ALL;
    this.deviceSelect = DefaultOptionType.ALL;
  };
}
