import * as React from 'react';
import { Property } from '@ppg/common';
import { observer } from 'mobx-react';
import './UnderlinedNavigationBar.scss'
import { Button, ButtonTypes } from '@ppg/styled';
import { t } from '../../../../base/helpers';

export enum ActiveViewType {
  CHART = 'chart',
  TABLE = 'table'
}

export enum SwitchButtonBreakSizeType {
  SMALL = 'small',
}

export interface IUnderlinedNavigationBarProps {
  tabs: string[];
  activeTab: Property;
  activeViewType: Property;
  switchButtonBreakSize?: SwitchButtonBreakSizeType;
}

@observer
export default class UnderlinedNavigationBar extends React.Component<IUnderlinedNavigationBarProps, {}> {

  public componentDidMount(): void {
    this.props.activeTab.setValue(this.props.tabs[0])
  }

  public render() {
    const { tabs, activeTab, activeViewType } = this.props;

    return <div className={ `navigationBar${ this.props.switchButtonBreakSize ? `-${ this.props.switchButtonBreakSize }` : '' }` }>
      <div className={ 'navigationBar__tabs' }>
        { tabs.map((tab, idx) => <div key={ idx }
                                      className={ `navigationBar__item ${ activeTab.getValue() === tab ? 'active' : '' }` }
                                      onClick={ () => activeTab.setValue(tab) }>{ t(tab) }</div>) }
      </div>

      <div className={ `navigationBar__buttons button-group m20l` }>
        <Button type={ ButtonTypes.DEFAULT_WHITE }
                iconLeft
                icon={ 'stat-stacked' }
                size={ 'medium' }
                onClick={ () => activeViewType.setValue(ActiveViewType.CHART) }
                isActive={ activeViewType.getValue() === ActiveViewType.CHART }
        />
        <Button type={ ButtonTypes.DEFAULT_WHITE }
                iconLeft
                icon={ 'menu' }
                size={ 'medium' }
                onClick={ () => activeViewType.setValue(ActiveViewType.TABLE) }
                isActive={ activeViewType.getValue() === ActiveViewType.TABLE }
        />
      </div>
    </div>
  }
}
