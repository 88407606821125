import { isNotEmpty, property, PropertyHandler } from '@ppg/common';
import { observable, reaction } from 'mobx';

export interface IPushAction {
  title: string;
  link: string;
  useRedirectLink?: boolean;
}

export class PushAction extends PropertyHandler {
  @property([isNotEmpty()]) public title: string;

  @property([isNotEmpty()]) public link: string;

  @property() public useRedirectLink: boolean;

  @observable private previousLink: string = '';

  private reactOnLinkChange = reaction(
    () => this.link,
    () => this.setPreviousLink()
  )

  constructor(pushAction: IPushAction) {
    super();
    this.title = pushAction.title;
    this.link = pushAction.link;
    this.useRedirectLink = pushAction.useRedirectLink || false;
  }

  public setRedirectLink = (): void => {
    this.link = this.useRedirectLink ? '{{redirectLink}}' : this.previousLink;
  }

  public setPreviousLink() {
    if (!this.useRedirectLink) {
      this.previousLink = this.link === '{{redirectLink}}' ? '' : this.link;
    }

    if (this.useRedirectLink) {
      this.previousLink = this.previousLink.includes('undefined') ? '' : this.previousLink;
    }
  }

  public get isTitleBlank(): boolean {
    this.getProperty('title').validate();
    return !this.getProperty('title').isValid;
  }

  public get isLinkBlank() {
    return this.link.match(/^(http:\/\/|app:\/\/|https:\/\/)$/) || this.link.endsWith('undefined');
  }

  public actionIsValid(): boolean {
    return !this.isTitleBlank && !this.isLinkBlank;
  }

  public serialize() {
    return {
      title: this.title,
      link: this.link,
      useRedirectLink: this.useRedirectLink
    }
  }

  public parseAction() {
    return {
      title: this.title,
      link: this.link
    }
  }

  static createPushAction(pushAction?: IPushAction) {
    if (pushAction) {
      return new PushAction(pushAction);
    } else {
      return new PushAction({
        title: '',
        link: '',
        useRedirectLink: false,
      });
    }
  }
}
