import { API, UseCase } from '@ppg/common';
import { Offer } from '../../modelsMobx/Offer';

interface IGetOfferByNameRequest {
  name: string
}

export class GetOfferByName extends UseCase<IGetOfferByNameRequest, Offer> {
  protected async execute({name}) {
    return API.HTTP.get<Offer>(
      `acc/offers/name/${name}`
    )
  }
}

export const getOfferByName= new GetOfferByName();
