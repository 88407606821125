import { API, UseCase } from '@ppg/common';

interface IChangeNextOfferPlanRequest {
  organization: string
  password: string
  offerPlan: string
}

export class ChangeNextOfferPlan extends UseCase<IChangeNextOfferPlanRequest, void> {
  protected async execute({organization, offerPlan, password}) {
    return API.HTTP.post<void>(
      `acc/organizations/${organization}/offers`, {
        offerPlan, password
      }
    )
  }
}

export const changeNextOfferPlan = new ChangeNextOfferPlan();
