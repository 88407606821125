import { API, UseCase } from "@ppg/common";
import { PlatformType } from '../../../interfaces';
import { getPlatformTypeParam, getRangeDateParam } from '../../../helpers';

interface IGetAverageCtrRequest {
  from: string;
  to: string;
  platformType: PlatformType;
  campaignType?: string;
}

interface IGetAverageCtrResult {
  averageCtr: number;
}

export class GetProjectCampaignAverageCtrUseCase extends UseCase<IGetAverageCtrRequest, IGetAverageCtrResult> {
  protected async execute({ platformType, campaignType, from, to }: IGetAverageCtrRequest) {
    let endpoint = `statistics/v2/projects/${ API.HTTP.currentProjectId }/campaigns/dashboard/averageCtr`
    + `/${ campaignType }`
    + getPlatformTypeParam(platformType)
    + getRangeDateParam(from, to)

    return API.HTTP.get<IGetAverageCtrResult>(endpoint);
  }
}

export const getProjectCampaignAverageCtrUseCase = new GetProjectCampaignAverageCtrUseCase();
