import { API, UseCase } from '@ppg/common';
import { getPlatformTypeParam, getRangeDateParam, getTimezoneParam } from '../../../helpers';
import { IOrganizationDashboardRequestBody } from '../../../interfaces';

export interface IProjectActiveSubscribers {
  project: string;
  activeSubscribers: number;
}

export interface IActiveSubscribers {
  activeSubscribersSum: number;
  activeSubscribers: IProjectActiveSubscribers[]
}

export interface IGetActiveSubscribersComparatorResult {
  activeSubscribers: IActiveSubscribers;
}

export class GetActiveSubscribersComparatorUseCase extends UseCase<IOrganizationDashboardRequestBody, IGetActiveSubscribersComparatorResult> {
  protected async execute({ organization, platform, from, to, timezone }: IOrganizationDashboardRequestBody) {
    const endpoint = `statistics/organizations/${ organization }/subscribers/comparator/active`
      + getPlatformTypeParam(platform) + getRangeDateParam(from, to) + getTimezoneParam(timezone);

    return API.HTTP.get<IGetActiveSubscribersComparatorResult>(endpoint);
  }
}

