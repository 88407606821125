import { API, UseCase } from '@ppg/common';
import { getRangeDateParam } from '../../../helpers';
import { IOrganizationDashboardRequestBody } from '../../../interfaces';

export interface IGetAutomationsComparatorHistogramRequest extends IOrganizationDashboardRequestBody {}

export interface IAutomationDay {
  day: string;
  ctr: number;
}

export interface ICampaignComparatorHistogramItem {
  project: string;
  automationDays: IAutomationDay[];
}

export interface IGetCampaignsComparatorHistogramResult {
  automationComparatorHistogram: ICampaignComparatorHistogramItem[];
}

export class GetAutomationsComparatorHistogramUseCase extends UseCase<IGetAutomationsComparatorHistogramRequest, IGetCampaignsComparatorHistogramResult> {
  protected async execute({
                            organization,
                            from,
                            to,
                            timezone,
                          }: IGetAutomationsComparatorHistogramRequest) {
    return API.HTTP.get<IGetCampaignsComparatorHistogramResult>(`statistics/organizations/${ organization }/campaigns/statistics/automation/comparator/histogram${ getRangeDateParam(from, to) }&timezone=${ timezone }`);
  }
}
