import { Form } from '../base';
import { AutomationFlowType } from '../models';
import * as React from "react";
import { property } from "../base/decorators";
import { t } from "../base/helpers";

export interface IAutomationsViewForm {
  filterEnabled?: boolean;
  filterTestMode?: boolean;
  filterName?: string;
  filterTriggerTypes?: Set<AutomationFlowType>;
}

export class AutomationsViewForm extends Form<IAutomationsViewForm, IAutomationsViewForm> implements IAutomationsViewForm {
  @property() public filterEnabled: boolean;
  @property() public filterTestMode: boolean;
  @property() public filterName: string;
  @property() public filterTriggerTypes: Set<AutomationFlowType>;

  public get filterOptions() {
    return [
      { name: t('All'), value: '*' },
      { name: t('Enabled'), value: 'enabled' },
      { name: t('Disabled'), value: 'disabled' },
      { name: t('Test mode'), value: 'testMode' }
    ];
  }

  public clear = () => this.set(this.defaults());

  public clearFlags() {
    this.filterEnabled = null;
    this.filterTestMode = null;
  }

  public hasActiveFilters() {
    return !!(this.filterEnabled || this.filterTestMode || this.filterName || this.filterTriggerTypes.size)
  }

  public toggleTriggerType(type: AutomationFlowType) {
    if (this.filterTriggerTypes.has(type)) {
      this.filterTriggerTypes.delete(type);
    } else {
      this.filterTriggerTypes.add(type);
    }
  }

  public defaults() {
    return {
      filterEnabled: null,
      filterTestMode: null,
      filterName: null,
      filterTriggerTypes: new Set<AutomationFlowType>(),
    }
  }
}

