import { Model } from '../base';
import { property } from '../base/decorators';
import { IExternalField, IExternalFieldResource } from './IExternalField';
import { KeyValue } from "./KeyValue";
//@ts-ignore
export class ExternalField extends Model<IExternalField, IExternalFieldResource> implements IExternalField {
  @property()
  public name: string;

  @property()
  public prettyName: string;

  @property()
  public type: string;

  @property()
  public __source: string = 'external';

  public get fieldIdentity() {
    return `${this.__source}:${this.name}:${this.type}`
  }

  public static generateExternalFields(additionalFields: KeyValue[]) {
    return additionalFields.map((model) => new ExternalField({
      name: model.key,
      prettyName: model.key,
      type: 'string'
    }))
  }
}
