import { UseCase, API } from '@ppg/common';
import { IGetGAResult, IGetPlugin } from '../interfaces';

export class GetGAPluginUseCase extends UseCase<IGetPlugin, IGetGAResult> {
  protected async execute({ websiteIntegrationId }: IGetPlugin) {
    return API.HTTP.get<IGetGAResult>(`wi/projects/${ API.HTTP.currentProjectId }/website-integrations/${ websiteIntegrationId }/plugins/google-analytics`)
  }
}

export const getGAPluginUseCase = new GetGAPluginUseCase();
