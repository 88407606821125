import { property } from "@ppg/common";
import { WebsiteIntegrationRelated } from '../../stores/project/WebsiteIntegrationRelated';
import { action } from 'mobx';
import { getGeolocationPluginUseCase, updateGeolocationPluginUseCase } from '../../useCases/website-integration';

export interface IGeolocation {
  enabled: boolean;
}

export class GeolocationPlugin extends WebsiteIntegrationRelated implements IGeolocation {
  @property() enabled: boolean;
  @property() isLoading: boolean = true;

  @action
  public async fetchData(): Promise<void> {
    const { payload } = await getGeolocationPluginUseCase.exec({ websiteIntegrationId: this.websiteIntegrationId });
    this.enabled = payload.enabled;
    this.isLoading = false;
  }

  @action
  public async save(): Promise<void> {
    await updateGeolocationPluginUseCase.exec({
      websiteIntegrationId: this.websiteIntegrationId,
      enabled: this.enabled
    });
  }
}
