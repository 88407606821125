import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { redirect, t } from '../../base/helpers';
import './LiteProjectEmptyData.scss'
import { InvoiceDetailsForms } from '../../pages/User/Payments/InvoiceDetails/InvoiceDetailsForms';
import PaymentsCards from '../../pages/Payments/PaymentsCards';
import { Button } from '@ppg/styled';
import { PaymentDataStore } from '../../stores/payment/PaymentDataStore';
import { CountryStore } from '../../stores/CountryStore';
import { CardsStore } from '../../stores/payment/CardsStore';
import { LiteProjectEmptyDataStep } from './LiteProjectEmptyDataStep';
import { ProjectRoutesTypes } from '../../routes/moduleProjectRoutes';

interface ILiteWelcomeViewProps {
  paymentDataStore?: PaymentDataStore;
  countryStore?: CountryStore;
  cardsStore?: CardsStore;
}

@inject('paymentDataStore', 'countryStore', 'cardsStore')
@observer
export class LiteProjectEmptyData extends React.Component<ILiteWelcomeViewProps, any> {

  public onNewProjectHandler = async (): Promise<void> => {
    this.props.paymentDataStore.savePaymentData().then(() => redirect(ProjectRoutesTypes.PROJECT_NEW));
  };

  public render() {
    return <div>
      <div className="lite-welcome">
        <div className="lite-welcome-header">
          <h2 className="size26">{ t('Welcome in PushPushGo Lite !') }</h2>
          <span className="lite-welcome-header-separator"/>
          <p className="lite-welcome-header-subtitle size16 m10t">
            { t('Follow the simple 2 steps to complete your account configuration') }
          </p>
        </div>

        <LiteProjectEmptyDataStep number={ 1 }
                                  title={ t('Complete company info') }
                                  subtitle={ t('We need your company info for issuing invoices.') }>
          <InvoiceDetailsForms hideSave/>
        </LiteProjectEmptyDataStep>

        <LiteProjectEmptyDataStep number={ 2 }
                                  title={ t('Add Card') }
                                  subtitle={ t('Fill in the details of the card you wish to use for monthly payments.') }>
          <div className="lite-payments-cards-wrapper">
            <PaymentsCards/>
          </div>
          <span className={ 'charge-info text-light size18 m40b' }>
            <span><b> { t('@lite/welcome::We won\'t charge your credit card during the 14-day trial. ') }</b></span>
            <span>  { t('@lite/welcome::The fees will be charged after this period, but only if you integrate your website with PushPushGo Lite. We will inform you about the trial progress in our app. You can resign at any time. ') }</span>
            <a href={ 'https://docs.pushpushgo.company/pushpushgo-lite/regulamin#_kbmre8nwwagd' } target='_blank'>
              { t("@lite/welcome::Click here to read more about subscription terms and conditions") }</a>
          </span>
        </LiteProjectEmptyDataStep>
        <div className="lite-welcome-integration">
          <Button onClick={ this.onNewProjectHandler } disabled={ !this.props.paymentDataStore.hasPaymentData || !this.props.cardsStore.hasSavedCards } content={ t("Create new project") }/>
        </div>
      </div>
    </div>
  }
}
