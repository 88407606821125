import * as React from 'react';
import { observer } from 'mobx-react';
import './PlanCard.scss';
import { Button } from '@ppg/styled';
import { t } from '../../../base/helpers';
import { IOfferPlan  } from '../../../modelsMobx/Offer';
import { offerStore } from '../../../stores';
import { OfferPlanPrototypeType, PlanType } from '../../../stores/OfferStore';

interface IPlanCardProps {
  plan: IOfferPlan;
  onClose(): void;
}

@observer
export class PlanCard extends React.Component<IPlanCardProps, {}> {

  public onPlanChooseHandler = (plan:string):void => {
    offerStore.changeNextPlanMode ? offerStore.setNextPlan(this.props.plan) : offerStore.setRegisterPlanById(plan);
    this.props.onClose();
  }

  render() {
    const {
      id,
      name,
      price,
      profits,
      title,
      footer
    } = this.props.plan;

    const isPremium = name.trim() === PlanType.PREMIUM;
    const circleIcon = isPremium ? "icon-winner-cup" : "icon-subscribers"
    const border = isPremium ? 'premium-border' : '';
    const separator = isPremium ? "separator-premium" : "separator";
    const separator100 = isPremium ? "separator100-premium" : "separator100";


    return <div className={`card-wrapper ${border}`}>
      {isPremium && <div className="best-seller-label" />}
      <div className="card-circle">
        <span className={`${circleIcon} size35`} />
      </div>

      <div className="card-price-wrapper ">
        <span className="card-price size40">
          {price}
          <span className="card-price size20"> {offerStore.offer.currency} </span>
        </span>
        <span className="plan-duration size20">/ {t('month')} </span>
      </div>

      <div className="plan-type-container">
        <span className="size30">
          {name.toUpperCase()}
        </span>
      </div>

      <span className="plan-title size16"> {title}</span>
      <span className={separator} />

      <ul className="plan-benefits">
        {profits.map( (benefit, idx) => {
          return <li className="size16" key={idx}>
            {benefit.includes
              ? <span className="icon-tick text-brand" />
              : <span className="icon-cancel-badge text-grey" /> }
            <span className={benefit.includes ? '' : "excluded-benefit" }>
              { benefit.value }
            </span>
          </li>
        })}
      </ul>

      <span className={separator100} />

      <div className="plan-details">
        <span className="footer size20 text-brand">{footer}</span>
      </div>
      <Button content={t('CHOOSE PLAN')} onClick={() => this.onPlanChooseHandler(id)} />
    </div>
  }
}

