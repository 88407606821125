import { API, UseCase } from '@ppg/common';
import { ICampaignId, ICampaignIdResponse } from '../interfaces';

interface ICancelLabelPushUseCaseRequest extends ICampaignId {}
interface ICancelLabelPushUseCaseResponse extends ICampaignIdResponse {}

export class CancelLabelPushUseCase extends UseCase<ICancelLabelPushUseCaseRequest, ICancelLabelPushUseCaseResponse> {
  protected async execute({ campaignId }: ICancelLabelPushUseCaseRequest) {
    return API.HTTP.post<ICancelLabelPushUseCaseResponse>(`core/projects/${API.HTTP.currentProjectId}/pushes/labels/${campaignId}/cancel`);
  }
}

export const cancelLabelPushUseCase = new CancelLabelPushUseCase();
