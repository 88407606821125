import { API, UseCase } from "@ppg/common";

interface ISaveInvoiceDetails {
  organizationId: string;
  paymentData: {
    city: string;
    country: string;
    flat: string;
    house: string;
    name: string;
    street: string;
    taxNumber: string;
    zip: string;
  };
}

export class SaveInvoiceDetailsUseCase extends UseCase<ISaveInvoiceDetails, void> {
  protected async execute({ organizationId, paymentData }: ISaveInvoiceDetails) {
    return API.HTTP.post<void>(`acc/organizations/${ organizationId }`, { ...paymentData });
  }
}

export const saveInvoiceDetailsUseCase = new SaveInvoiceDetailsUseCase();
