import { API, UseCase } from '@ppg/common';
import { ICampaignId, ICampaignIdResponse } from '../interfaces';

interface ICancelRocketPushUseCaseRequest extends ICampaignId {}
interface ICancelRocketPushUseCaseResponse extends ICampaignIdResponse {}

export class CancelRocketPushUseCase extends UseCase<ICancelRocketPushUseCaseRequest, ICancelRocketPushUseCaseResponse> {
  protected async execute({ campaignId }: ICancelRocketPushUseCaseRequest) {
    return API.HTTP.post<ICancelRocketPushUseCaseResponse>(`core/projects/${API.HTTP.currentProjectId}/pushes/rocket/${campaignId}/cancel`);
  }
}

export const cancelRocketPushUseCase = new CancelRocketPushUseCase();
