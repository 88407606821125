import * as React from 'react';
import { t } from '../../base/helpers';
import { Button, CheckBox, Input, ShowPasswordButton, toast } from '@ppg/styled';
import { inject, observer } from 'mobx-react';
import { UserStore } from '../../stores/user/UserStore';
import './Authorization.scss';
import { Link } from 'react-router-dom';
import { action, observable } from 'mobx';
import { assertPartnershipExistsUseCase } from '../../useCases/accounting';
import { OfferStore } from '../../stores/OfferStore';
import { RegisterConfirmation } from './RegisterConfirmation';
import { SaveButtonWrapped } from '../../assets/wrapped';
import { ChoosePlanModal } from '../../components/ChoosePlanModal/ChoosePlanModal';

interface IAgreementLinks {
  privacy: string;
  electronicServices: string;
  dataProcessing: string;
}

interface IRegisterProps {
  userStore?: UserStore;
  offerStore?: OfferStore;
  search: string;
  params: Record<string, string>;
  lite?: boolean;
}

@inject('userStore', 'offerStore')
@observer
export class RegistrationPage extends React.Component<IRegisterProps> {
  @observable isPasswordHidden: boolean = true;
  @observable isRepeatPasswordHidden: boolean = true;
  @observable currencyType: string;
  @observable planType: string;
  @observable isPlansModalOpen: boolean = false;
  @observable isLoading: boolean = true;
  @observable isFormSubmitted: boolean = false;

  @action
  private openPlanModal = (e): void => {
    e.preventDefault();
    this.isPlansModalOpen = true;
  };

  @action
  private closePlanModal = (): void => {
    this.isPlansModalOpen = false;
  };

  private validateReferralCode = async (code: string): Promise<void> => {
    await assertPartnershipExistsUseCase.exec({
      partnershipCode: code
    });
  };

  public componentDidMount(): void {
    this.props.userStore.cleanStateForRegistrationForm();

    const params = new URLSearchParams(this.props.search);

    if (this.props.lite) {
      this.props.offerStore.fetchOffer()
        .then(() => this.props.offerStore.setInitialOfferPlan(this.props.params.offerPlan))
        .then(() => this.isLoading = false);
    }

    if (!this.props.search) {
      return;
    }

    if (params.has('currency') && params.has('plan')) {
      this.currencyType = params.get('currency');
      this.planType = params.get('plan');
    }
  }

  public async handleRegisterClick() {
    const { userStore, lite } = this.props;

    const registerWithPlan = this.currencyType && this.planType;

    userStore.user.validate();
    userStore.password.validate();

    if (userStore.user.validateAgreement(lite)) {
      return toast.error(userStore.user.validateAgreement(lite));
    }

    if (!userStore.user.isValid()) {
      return toast.error(t('Fields cannot be blank'));
    }

    if (this.props.offerStore.getProperty('referralCode').getValue()) {
      await this.validateReferralCode(this.props.offerStore.getProperty('referralCode').getValue());
    }

    if (registerWithPlan) {
      return userStore.authorization
        .registerFullUserWithPlan({
          currencyType: this.currencyType,
          planType: this.planType
        })
        .then(() => this.isFormSubmitted = true)
        .catch((error) => {
          userStore.password.parseErrors(error);
          userStore.password.errors
            .filter(i => i)
            .map(error => toast.error(error));
        })
        .finally(() => userStore.password.errors = []);
    }

    if (this.props.lite) {
      return userStore.authorization
        .registerToPPGLite()
        .then(() => this.isFormSubmitted = true)
        .catch((error) => {
          userStore.password.parseErrors(error);
          userStore.password.errors
            .filter(i => i)
            .map(error => toast.error(error));
        })
        .finally(() => userStore.password.errors = []);
    }

    return userStore.authorization
      .registerFullUser()
      .then(() => this.isFormSubmitted = true)
      .catch((error) => {
        userStore.password.parseErrors(error);
        userStore.password.errors
          .filter(i => i)
          .map(error => toast.error(error));
      })
      .finally(() => userStore.password.errors = []);
  }

  private toggleShowPassword(e) {
    e.preventDefault();
    this.isPasswordHidden = !this.isPasswordHidden;
  }

  private toggleShowRepeatPassword(e) {
    e.preventDefault();
    this.isRepeatPasswordHidden = !this.isRepeatPasswordHidden;
  }

  private getLinks(lang: string): IAgreementLinks {
    switch (lang) {
      case 'pl':
        return {
          privacy: 'https://pushpushgo.com/pl/pages/polityka-prywatnosci/',
          electronicServices: 'https://www.datocms-assets.com/6307/1673437300-umowa-o-swiadczenie-uslug-droga-elektroniczna-lite.pdf',
          dataProcessing: 'https://www.datocms-assets.com/6307/1673437304-umowa-powierzenia_pushpushgo_lite_pl.pdf'
        };
      default:
        return {
          privacy: 'https://pushpushgo.com/en/pages/privacy/',
          electronicServices: 'https://www.datocms-assets.com/6307/1673437519-agreement-on-the-provision-of-electronic-services-lite.pdf',
          dataProcessing: 'https://www.datocms-assets.com/6307/1673437558-data-processing-agreement-lite.pdf'
        };
    }
  }

  private passwordHelper = () => (
    <div>
      { t('register::In order to protect your account, make sure your password:') }
      <ul>
        <li>{ t('register::has at least 8 characters') }</li>
        <li>{ t('register::doesn\'t contain sequence of 4 characters') }</li>
        <li>{ t('register::has a special character') }</li>
        <li>{ t('register::contains uppercase character') }</li>
        <li>{ t('register::contains lowercase character') }</li>
        <li>{ t('register::contains number') }</li>
      </ul>
    </div>
  );

  private referralCodeHelper = () => (
    <div>
      { t('register::Code received from the user who recommended our application to you') }
    </div>
  );

  public render() {
    const { userStore, offerStore, lite } = this.props;
    const termsLinks = this.getLinks(userStore.lang);

    const loginLink = lite ? '/lite/login' : '/login';

    if (this.isFormSubmitted) {
      return <RegisterConfirmation/>;
    }

    return <>
      { this.isPlansModalOpen && <ChoosePlanModal onClose={ this.closePlanModal }/> }
      <div className="register-page-wrapper" data-cy={ 'register-page--wrapper' }>
        <h1>{ t('register::Create account') }</h1>
        <form className="auth-form">

          <Input
            label={ t('register::E-mail') }
            autoFocus
            dataCy={ 'register-input--email' }
            property={ userStore.user.getProperty('username') }
            autoComplete={ 'off' }
          />

          <Input
            label={ t('register::Company or Organization name') }
            dataCy={ 'register-input--organization' }
            property={ userStore.user.getProperty('organization') }
          />

          <div className="password-input-wrapper">
            <Input
              label={ t('register::Password') }
              helperContent={ this.passwordHelper() }
              type={ this.isPasswordHidden ? 'password' : 'text' }
              dataCy={ 'register-input--password' }
              autoComplete={ 'new-password' }
              property={ userStore.password.getProperty('password') }/>

            <ShowPasswordButton hidePassword={ this.isPasswordHidden } onClickHandler={ this.toggleShowPassword.bind(this) }/>
          </div>

          <div className="password-input-wrapper">
            <Input
              label={ t('register::Repeat password') }
              dataCy={ 'register-input--repeat-password' }
              type={ this.isRepeatPasswordHidden ? 'password' : 'text' }
              property={ userStore.password.getProperty('confirmationPassword') }/>

            <ShowPasswordButton hidePassword={ this.isRepeatPasswordHidden }
                                onClickHandler={ this.toggleShowRepeatPassword.bind(this) }/>
          </div>


          { lite && <div className={ offerStore.hasReferralCode ? "referral-code-active" : "referral-code" }>
            <CheckBox property={ offerStore.getProperty('hasReferralCode') }
                      label={ t('register::I have referral code') }
                      dataCy={ 'register-checkbox--referral' }
                      helperContent={ this.referralCodeHelper()
                      }
            />

            { offerStore.getProperty('hasReferralCode').getValue() && <Input
              dataCy={ 'register-input--referral-code' }
              property={ offerStore.getProperty('referralCode') }
            /> }
          </div> }


          { !this.isLoading && lite && <div className="choose-plan-body">
            <div className="plan-card">
              <div className="account-plan-details">
                      <span className="size20">
                        { t('register::PLAN ') }
                        { offerStore.activeRegisterPlan.name.toUpperCase() }
                      </span>

                <span className="account-plan-price size18">
                    { offerStore.activeRegisterPlan.price }
                  { offerStore.offer.currency }
                  </span>

                <div className="more-details-link text-brand">
                  <a href="https://pushpushgo.com/pl/pages/cennik/lite" target="_blank">
                    { t('register::Click here to see plan details') }
                  </a>
                  <span className="icon-arrow-right size10"/>
                </div>
              </div>
            </div>
            <div className="change-plan-button" onClick={ (e) => this.openPlanModal(e) }>
              <Button isSquare dataTip={ t('register::Edit') } icon={ "pencil" }/>
              <span className="text-brand size14">{ t('register::CHANGE') }</span>
            </div>
          </div>
          }

          <div className="agreements-box">
            <CheckBox id="privacy" property={ userStore.user.getProperty('isPrivacyPolicyCheck') }
                      onChange={ () => userStore.user.setPrivacyCheckDate() }/>
            <label htmlFor="privacy">{ t('register::I hereby acknowledge that I have read the ') }
              <a href={ termsLinks.privacy } target="_blank">{ t('register::Privacy Policy of the PushPushGo') }</a>
            </label>
          </div>

          { lite && <div className="agreements-box agreements-box--terms">
            <CheckBox id="terms" property={ userStore.user.getProperty('isTermsOfAgreementCheck') }
                      onChange={ () => userStore.user.setTermsOfAgreementCheckDate() }/>
            <label htmlFor="terms">{ t('register::I agree to the terms of the ') }
              <a href={ termsLinks.electronicServices } target="_blank">{ t('register::Agreement on the provision of electronic services ') }</a>
              { t('register::and of the ') }
              <a href={ termsLinks.dataProcessing } target="_blank">{ t('register::Data Processing Agreement') }</a>
            </label>
          </div> }

          <div className="bottom-actions bottom-actions--register">
            <SaveButtonWrapped className="button-primary"
                               preventToast
                               dataCy={ 'register-button--submit' }
                               disabled={ userStore.password.password === '' }
                               content={ t('register::Create account') }
                               callback={ () => this.handleRegisterClick() }/>

          </div>
        </form>
        <div className="switch-to-login">
              <span className="app-remind">
                { t('register::Already have an account?') }
                <Link to={ loginLink } className="app-login-button">{ t('register::Login') }</Link>
              </span>
        </div>
      </div>
    </>;
  }
}
