import { API, UseCase } from '@ppg/common';

export interface DashboardAutomationResponse {
  automations: ProjectAutomationsCampaigns[],
  enabledScenarios: number;
}

interface ProjectAutomationsCampaigns {
  id: string;
  name: string;
  campaigns: string[];
}

interface AutomationStatsResponse {
  ranking: AutomationStats[];
  meanCtr: number;
}

interface AutomationStats {
  automation: string;
  clicked: number;
  delivered: number;
  ctr: number;
}

interface BestAutomation {
  id: string;
  name: string;
  clicked: number;
  delivered: number;
  ctr: number;
}

export interface GetDashboardAutomationCommand {
  from: string;
  to: string;
}

export interface GetDashboardAutomationResponse {
  activeAutomations: number;
  bestAutomations: BestAutomation[];
  meanCtr: number;
}

export class GetDashboardAutomation extends UseCase<GetDashboardAutomationCommand, GetDashboardAutomationResponse> {
  protected async execute({ from, to }: GetDashboardAutomationCommand
  ): Promise<GetDashboardAutomationResponse> {
    const project = API.HTTP.currentProjectId;
    if (project) {
      const automationResponse = await API.HTTP.get<DashboardAutomationResponse>(`statistics/projects/${ project }/automations/active`);
      const statsResponse = await API.HTTP.post<AutomationStatsResponse>(`statistics/v2/projects/${ project }/automations/rank?from=${ from }&to=${ to }`, {
        automations: automationResponse.automations.map(automation => {
          return {
            automation: automation.id,
            campaigns: automation.campaigns
          };
        })
      });

      return {
        activeAutomations: automationResponse.enabledScenarios,
        bestAutomations: this.mergeAutomationStatisticsWithContent(
          statsResponse,
          automationResponse
        ),
        meanCtr: Number((statsResponse.meanCtr * 100).toFixed(2)),
      };
    }
  }

  private mergeAutomationStatisticsWithContent(
    automationStats: AutomationStatsResponse,
    automationContent: DashboardAutomationResponse
  ): BestAutomation[] {
    const automationMap = new Map<string, Partial<BestAutomation>>();

    automationStats.ranking.forEach(stats => {
      automationMap.set(stats.automation, { clicked: stats.clicked, delivered: stats.delivered, ctr: Number((stats.ctr * 100).toFixed(2)), name: '' });
    });

    automationContent.automations.forEach(automation => {
      const automationData = automationMap.get(automation.id);
      if (automationData) {
        automationData.name = automation.name;
      }
    });

    return Array.from(automationMap.entries()).map(entry => {
      return {
        id: entry[0],
        name: entry[1].name,
        clicked: entry[1].clicked,
        delivered: entry[1].delivered,
        ctr: entry[1].ctr
      };
    });
  }
}

export const getDashboardAutomation = new GetDashboardAutomation();
