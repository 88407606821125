import { API, UseCase } from '@ppg/common';
import { t } from '../../base/helpers';
import { toast } from '@ppg/styled';

enum ChangePasswordCode {
  TOO_SHORT = 100,
  TOO_EASY = 200,
  NO_SPECIAL = 300,
  NO_UPPERCASE = 400,
  NO_LOWERCASE = 500,
  NO_NUMBER = 600,
}

type ChangePasswordErrorType = {
  code: ChangePasswordCode;
  message: string;
}

interface CheckPasswordUseData {
  password: string;
}

export class CheckPasswordUseCase extends UseCase<CheckPasswordUseData, void> {
  protected async execute({ password }) {
    await API.HTTP.post('aai/user/check-password', { password });
  }
}

export const checkPasswordUseCase = new CheckPasswordUseCase();

checkPasswordUseCase.registerErrorHandler((error) => {
  if (Array.isArray(error)) {
    const passwordErrors: string[] = Array
      .from(error)
      .map((passwordError: ChangePasswordErrorType) => {
        switch (passwordError.code) {
          case ChangePasswordCode.TOO_SHORT:
            return t('Password must have at least 8 chars');

          case ChangePasswordCode.TOO_EASY:
            return t('Password contains sequence of 4 characters');

          case ChangePasswordCode.NO_SPECIAL:
            return t('Password has to contain special character');

          case ChangePasswordCode.NO_UPPERCASE:
            return t('Password has to contain uppercase character');

          case ChangePasswordCode.NO_LOWERCASE:
            return t('Password has to contain lowercase character');

          case ChangePasswordCode.NO_NUMBER:
            return t('Password has to contain number');
        }
      });

    throw passwordErrors;
  }

  toast.error(t('Password is incorrect'));
});
