import { computed, observable, reaction } from 'mobx';
import { CampaignDayChartHelper, IChartItem } from '../helpers/CampaignDayChartHelper';
import { t } from '../base/helpers';
import { ISelectOption } from '@ppg/styled';
import { getCampaignDaysForCampaign } from '../useCases/core/push/GetCampaignDaysForCampaign';
import { getCampaignDaysForCampaignInDateRange } from '../useCases/core/push/GetCampaignDaysForCampaignInDateRange';
import { ProjectRelated } from './ProjectRelatedStore';
import { property } from '@ppg/common';
import { CampaignType } from '../models/PushCampaign';
import { userStore } from './index';
import dayjs from 'dayjs';

const MINUTE_AGGREGATOR = 1;
const HOUR_AGGREGATOR = 60;
const DAY_AGGREGATOR = 3600;
const MIN_CHART_EVENTS = 10;

export enum CampaignDayRangeType {
  TODAY = 'today',
  WEEK = 'week',
  MONTH = 'month',
  RANGE = 'range',
  HOURLY = 'hourly',
  ALL = 'all',
}

export interface ICampaignDay {
  project?: string;
  day: Date;
  campaign?: string;
  delivered: number[];
  clicked: number[];
}

export class CampaignDayStore extends ProjectRelated {

  private campaignId: string;
  private campaignType: string;

  @observable private campaignDaysData: ICampaignDay[];
  @property() public range: CampaignDayRangeType;
  @property() public dateRangeFrom: Date;
  @property() public dateRangeTo: Date;

  private fetchOnRangeChange = reaction(() => this.range, () => this.fetchCampaignDay());
  private fetchOnFromChange = reaction(() => this.dateRangeFrom, () => this.fetchCampaignDay());
  private fetchOnToChange = reaction(() => this.dateRangeTo, () => this.fetchCampaignDay());

  setDefaultValues() {
    this.campaignDaysData = [];
    this.dateRangeFrom = dayjs().subtract(1, 'month').startOf('day').toDate();
    this.dateRangeTo = dayjs().endOf('day').toDate();
  }

  public async fetchCampaignDay() {
    this.campaignDaysData = [];

    // logout issue
    // refactor Dashboard Component
    if (!userStore.isAuthorized || !this.projectId || !this.campaignId) {
      return;
    }

    if (this.campaignType === CampaignType.TRIGGERED) {
      const { stats } = await getCampaignDaysForCampaignInDateRange.exec({
        projectID: this.projectId,
        campaign: this.campaignId,
        from: this.dateRangeFrom,
        to: this.dateRangeTo,
      });
      this.campaignDaysData = stats.map(s => ({
        day: new Date(s.day),
        delivered: s.delivered,
        clicked: s.clicked,
      }));
      return;
    }

    const { stats } = await getCampaignDaysForCampaign.exec({
      projectID: this.projectId,
      campaign: this.campaignId,
    });

    this.campaignDaysData = stats.map(s => ({
      day: new Date(s.day),
      delivered: s.delivered,
      clicked: s.clicked,
    }));
  }

  @computed
  public get chartData(): IChartItem[] {
    if (!this.campaignDaysData) {
      return [];
    }

    return this.range === CampaignDayRangeType.HOURLY
      ? this.setHourlyCampaignDaysData(this.campaignDaysData)
      : this.setLinearCampaignDaysData(this.campaignDaysData);
  }

  public setInitial(campaignId, campaignType) {
    this.campaignId = campaignId;
    this.campaignType = campaignType;
    if (campaignType === CampaignType.TRIGGERED) {
      this.range = CampaignDayRangeType.HOURLY;
    } else {
      this.range = CampaignDayRangeType.ALL;
    }
  }

  @computed get hasData() {
    return this.campaignDaysData && this.chartData.length > MIN_CHART_EVENTS;
  }

  @computed
  public get isRangeVisible() {
    return this.range === CampaignDayRangeType.RANGE;
  }

  get isTriggeredCampaign() {
    return this.campaignType === CampaignType.TRIGGERED;
  }

  private setLinearCampaignDaysData(data: ICampaignDay[]) {
    return CampaignDayChartHelper.createCampaignDaysData(data, [], this.aggregateMap[this.range])
  }

  private setHourlyCampaignDaysData(data: ICampaignDay[]) {
    return CampaignDayChartHelper.createHourlyCampaignDaysData(data, [], this.aggregateMap[this.range])
  }

  // todo: from & to
  private rangeConditionMap(range) {
    switch (range) {
      case CampaignDayRangeType.TODAY:
        return { $gte: dayjs().startOf('day').toDate() };
      case CampaignDayRangeType.WEEK:
        return { $gte: dayjs().subtract(1, 'week').startOf('day').toDate() };
      case CampaignDayRangeType.MONTH:
        return { $gte: dayjs().subtract(1, 'month').startOf('day').toDate() };
      case CampaignDayRangeType.RANGE:
        return {
          $gte: dayjs(this.dateRangeFrom).startOf('day').toDate(),
          $lte: dayjs(this.dateRangeTo).add(1, 'day').startOf('day').toDate()
        }
    }
  };

  private aggregateMap = {
    [CampaignDayRangeType.TODAY]: MINUTE_AGGREGATOR,
    [CampaignDayRangeType.WEEK]: HOUR_AGGREGATOR,
    [CampaignDayRangeType.MONTH]: DAY_AGGREGATOR,
    [CampaignDayRangeType.HOURLY]: MINUTE_AGGREGATOR,
    [CampaignDayRangeType.RANGE]: MINUTE_AGGREGATOR,
    [CampaignDayRangeType.ALL]: MINUTE_AGGREGATOR,
  };

  get rangeOptions(): ISelectOption[] {
    return [
      { value: [CampaignDayRangeType.TODAY], name: t('today') },
      { value: [CampaignDayRangeType.WEEK], name: t('last week') },
      { value: [CampaignDayRangeType.MONTH], name: t('last month') },
      { value: [CampaignDayRangeType.HOURLY], name: t('hourly') },
      { value: [CampaignDayRangeType.RANGE], name: t('range') },
    ];
  }
}
