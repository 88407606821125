import * as React from 'react';
import { t } from '../../../base/helpers';
import { inject, observer } from 'mobx-react';
import { PartnershipStore } from '../../../stores/PartnershipStore';
import { observable } from 'mobx';
import { Help } from '@ppg/styled';
import { WrappedPagination } from '../../../assets/wrapped';

interface IPartnerListProps {
  partnershipStore?: PartnershipStore
}

@inject('partnershipStore')
@observer
export class PartnerList extends React.Component<IPartnerListProps, {}> {
  @observable public isLoading:boolean = true
  componentDidMount() {
    this.props.partnershipStore.fetchPartners()
      .then(() => this.isLoading = false)
  }

  public render() {
    const { partnershipStore } = this.props;

    if(this.isLoading){
      return null;
    }

    return <>
      <div className="well">

        <h4 className="form-title">{ t('Your partners') }
          <Help content={ t('@lite/user/partnership::Partners are users who have registered using your code.')}/>
        </h4>

        <table className="partners-table">
          <thead>
          <tr>
            <th>{ t('@lite/user/partnership::Partner Id') }</th>
            <th>{ t('@lite/user/partnership::Site address') }</th>
            <th>{ t('@lite/user/partnership::Created at') }</th>
            <th>{ t('@lite/user/partnership::Paid months') }</th>
          </tr>
          </thead>
          <tbody>
          { partnershipStore.partners.length > 0
            ? partnershipStore.paginatedPartnersList.map((partner) => {
              return <tr key={partner.organization}>
                <td data-label={ t('@lite/user/partnership::Partner Id') }>{partner.organization} </td>
                <td data-label={ t('@lite/user/partnership::Site address') }> {partner.projectUrl} </td>
                <td data-label={ t('@lite/user/partnership::Created at') }>{new Date(partner.createdAt).toDateString()} </td>
                <td data-label={ t('@lite/user/partnership::Paid months') }>
                  {partnershipStore.getInvoicesStatus(partner.invoices.length).map(status => {
                    return status.paid ? <span className="icon-tick size20 m20b text-brand"/> : <span className="icon-close size20 m20b text-grey"/>
                  })}
                </td>
              </tr>
            })
            : <tr>
              <td colSpan={4} className="empty-partners-info">
                {t('@lite/user/partnership::You do not have any partners yet')}<br/>
              </td>
            </tr>
          }
          </tbody>
        </table>
      </div>
      <WrappedPagination total={ partnershipStore.getProperty('total') }
                         offsetProperty={ partnershipStore.getProperty('offset') }
                         itemsPerPage={ partnershipStore.getProperty('limit') }
                         perPageSelector={ true }
                         infinityScroll={ partnershipStore.getProperty('infinity').getValue() } />
    </>
  }
}
