import { API, UseCase } from '@ppg/common';

interface IRequestToPayInvoices {
  organization: string;
  invoices: string[];
  currency: string;
  card: any;
}

interface IRequestToPayInvoicesResult {
  secret: string;
  amount: number;
  currency: string;
  action: boolean;
}

export class RequestToPayInvoicesUseCase extends UseCase<IRequestToPayInvoices, IRequestToPayInvoicesResult> {
  protected async execute({ organization, card, invoices, currency }) {
    return API.HTTP.post<IRequestToPayInvoicesResult>(`acc/organizations/${ organization }/payments/cards/pay/request`,
      { invoices, currency, card })
  }
}

export const requestToPayInvoicesUseCase = new RequestToPayInvoicesUseCase();
