import { API, UseCase } from '@ppg/common';
import { IGetAppleDataResponse, IProvider } from '../interfaces';

interface IUpdateSafari extends IProvider {
  p12: string;
  passphrase: string;
}

export class UpdateSafariCertificatesUseCase extends UseCase<IUpdateSafari, IGetAppleDataResponse> {
  protected async execute({ projectId, passphrase, p12, organizationId }: IUpdateSafari) {
    return API.HTTP.put<IGetAppleDataResponse>(`core/organizations/${ organizationId }/projects/${ projectId }/providers/safari/certificates`, {
      passphrase, p12
    });
  }
}

export const updateSafariCertificatesUseCase = new UpdateSafariCertificatesUseCase();
