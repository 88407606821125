import cs from 'classnames';
import * as React from 'react';
import { t } from '../../base/helpers';
import { toast } from '@ppg/styled';

interface ISaveButtonProps {
  callback: () => Promise<any>;
  preventStateChange?: boolean;
  label?: string;
  preventToast?: boolean;
  className?: string;
  msgSuccess?: string;
  disabled?: boolean;
  icon?: string;
  id?: string;
}

interface ISaveButtonState {
  isLoading?: boolean;
  isSucceed?: boolean;
}

export class SaveButton extends React.Component<ISaveButtonProps, ISaveButtonState> {
  private interval: number = null;

  public state: ISaveButtonState = {
    isLoading: false,
    isSucceed: false,
  };

  public componentWillUnmount() {
    return this.interval && clearTimeout(this.interval);
  }

  private onSave = (e) => {
    e.preventDefault();
    const { preventToast, msgSuccess, preventStateChange } = this.props;
    if (!preventStateChange) this.setState({ isLoading: true });

    this.props
      .callback()
      .then(() => {
        if (!preventStateChange) this.setState({ isLoading: false });

        if (!preventToast) {
          toast.success((msgSuccess || t('Changes are saved')));
          if (!preventStateChange) {
            this.setState({ isSucceed: true }, () => {
              this.interval = setTimeout(() => {
                if (!preventStateChange) {
                  this.setState({ isSucceed: false });
                }
              }, 3000) as any;
            });
          }
        }
      })
      .catch(() => {
        if (!preventStateChange) this.setState({ isLoading: false });
      });
  }

  public render() {
    const { isLoading, isSucceed } = this.state;
    const { label, className, disabled, icon, id } = this.props;

    return (
      <button type="submit"
              id={id}
              disabled={ isLoading || disabled }
              className={ cs(`button loading-button ${className}`, {
                'is-loading': isLoading,
                'is-success': isSucceed,
                'button--icon': icon !== undefined
              }) }
              onClick={ this.onSave }>
        { label || t('Save') }

        { !isLoading && !isSucceed && icon && <span className={ `m5l icon-${icon} no-spin` }/> }
        { isLoading && <span className="m5l icon-refresh size14"/> }
        { isSucceed && <span className="m5l icon-tick size14"/> }
      </button>
    );
  }
}
