import * as React from 'react';
import { userStore } from '../../stores/index'
import { Redirect } from 'react-router-dom';

export class Unauthorized extends React.Component {

  componentDidMount() {
    userStore.logout();
  }

  public render() {
    return <Redirect to="/login" />
  }
}
