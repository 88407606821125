import { API, UseCase } from "@ppg/common";
import { IListQueryOptions } from '../../../interfaces/IListQueryOptions';
import { IListResponse } from '../../../interfaces/IListResponse';
import { ISegmentDTO } from './GetSegmentsUseCase';

interface IGetReadySegmentsRequest extends IListQueryOptions {
  projectId: string;
  sortBy: string;
  name: string;
}

export interface IGetReadySegmentsResponse extends IListResponse<ISegmentDTO[]> {}

export class GetReadySegmentsUseCase extends UseCase<IGetReadySegmentsRequest, IGetReadySegmentsResponse> {
  protected async execute({ projectId, offset, limit, sortBy, name }: IGetReadySegmentsRequest) {
    const result = await API.HTTP.get<IGetReadySegmentsResponse>(`core/projects/${ projectId }/segment/ready?offset=${ offset }&limit=${ limit }&sortBy=${ sortBy }&name=${ name }`);

    const data: any[] = result.data.map(segment => {
      return {...segment, conditions: segment.conditions.conditions.map(conditionGroup => conditionGroup.conditions) }
    })

    return {metadata: result.metadata, data}
  }
}

export const getReadySegmentsUseCase = new GetReadySegmentsUseCase();
