import styled from '@emotion/styled';

interface ISegmentCondition {
  selectLabelKey: string;
}

export const SegmentCreatorBox = styled.section`
  position: relative;

  @media (max-width: 1000px) {
    .page-header {
      margin-bottom: 15px;
    }
  }
`;

export const SegmentCreatorButtons = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  button:last-child {
    margin-right: 0;
  }

  @media (max-width: 1000px) {
    margin: 0 auto 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    width: 65%;

    .button-paste {
      margin-right: 0;
      flex-basis: 60%;
    }

    button:not(.button-paste) {
      flex-basis: 45%;
    }
  }

  @media (max-width: 600px) {
    width: 90%;
  }

  @media (max-width: 400px) {
    flex-direction: column;
    width: 80%;
    
    button {
      margin-right: 0;
    }
  }
`;

export const SegmentCreator = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;

  & > .input-wrapper label {
    font-size: 15px;
    letter-spacing: 0;
    position: relative;
  }

  & > .input-wrapper label span {
    font-size: 13px;
    top: unset;
    bottom: 0;
  }

  & > .input-wrapper .input-field {
    min-width: 400px;
  }

  .input-field input {
    margin: 0;
    font-size: 15px;
  }

  @media (max-width: 1000px) {
    justify-content: center;

    & > .input-wrapper {
      order: 2;
      width: 100%;
    }

    & > .input-wrapper .input-field {
      min-width: auto;
    }
  }
`;

export const SegmentAudience = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  background-color: #ECFBF8;
  border-radius: 4px 0 0 4px;
  font-size: 16px;

  & span:nth-of-type(1) {
    margin-right: 15px;
  }

  & span:nth-of-type(2) {
    font-size: 20px;
    font-weight: 600;
    color: #16a085;
  }

  @media (max-width: 1000px) {
    order: 1;
    margin-bottom: 20px;
  }

  @media (max-width: 500px) {
    flex-direction: column;

    & span:nth-of-type(1) {
      font-size: 16px;
      margin-right: 0;
    }
  }
`;

export const SegmentConditionsCreator = styled.section`
  margin: 30px 0 0;
  width: 100%;

  h3 {
    font-weight: 300;
    font-size: 15px;
    letter-spacing: 0;
  }

  ul {
    margin-left: 0;
  }

  & > button:last-child {
    margin-top: 18px;
  }

  @media (max-width: 1000px) {
    margin-top: 20px;
    order: 3;
  }
`;

export const SegmentConditions = styled.li`
  margin: 0;
  list-style: none;

  &:last-of-type .condition-type-or {
    display: none;
  }

  .condition-type-or {
    margin: 8px 0;
    position: relative;
  }

  .condition-type-or span {
    padding: 2px 10px 0;
    display: block;
    position: relative;
    left: 18px;
    width: 40px;
    background-color: #f7f7f7;
    color: #16a085;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 4px;
    box-shadow: 0 0 5px 1px #d8e5e4;
    z-index: 1;
  }

  .condition-type-or::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 4px;
    background-color: #e7e7e7;
    border-radius: 4px;
    z-index: 0;
  }
`;

export const SegmentConditionsBox = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #CEE5E1;
  border-radius: 4px;

  & > button:last-child {
    margin-top: 28px;
  }

  @media (max-width: 500px) {
    padding: 10px;
  }
`;

export const SegmentConditionsButtons = styled.div`
  align-self: flex-end;
  display: flex;

  button:last-child {
    margin-right: 0;
  }
`;

export const SegmentConditionBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:last-of-type .condition-type-and {
    display: none;
  }

  .condition-type-and {
    margin: 20px 0 10px;
    position: relative;
  }

  .condition-type-and span {
    padding: 2px 5px;
    display: block;
    background-color: #f7f7f7;
    width: min-content;
    position: relative;
    color: #16a085;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 4px;
    box-shadow: 0 0 5px 1px #d8e5e4;
    z-index: 1;
  }

  .condition-type-and::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 4px;
    background-color: #e5f1ef;
    border-radius: 4px;
    z-index: 0;
  }

  @media (max-width: 1100px) {
    .condition-type-and {
      margin: 8px 0;
    }
  }
`;

export const SegmentCondition = styled.div<ISegmentCondition>`
  display: flex;
  align-items: flex-end;
  width: 100%;

  @media (max-width: 1100px) {
    padding: 0;
    align-items: ${ props => props.selectLabelKey ? "center" : "flex-end" };
  }
`;

export const SegmentConditionCancel = styled.div`
  margin-right: 10px;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 20px;
    color: #878787;
    cursor: pointer;
  }

  @media (max-width: 1100px) {
    margin-right: 15px;
  }

  @media (max-width: 400px) {
    margin-right: 5px;
    justify-content: flex-start;
  }
`;

export const SegmentConditionFields = styled.div`
  display: flex;
  align-items: center;
  width: 90%;

  & > div:nth-of-type(1) .input-wrapper {
    margin-right: 22px;
  }

  .select-wrapper {
    flex-basis: 25%;
    margin-right: 22px;
  }

  select {
    margin-bottom: 0;
    font-size: 14px;
  }

  label {
    margin: 0;
  }

  .input-field input {
    font-size: 14px;
  }

  @media (max-width: 1100px) {
    padding-right: 20px;
    flex-direction: column;
    align-items: stretch;
    width: 100%;

    & > div:nth-of-type(1) .input-wrapper,
    .select-wrapper {
      margin-right: 0;
    }

    & > div + div {
      margin-top: 10px;
    }
  }

  @media (max-width: 500px) {
    padding-right: 10px;
  }
`;

export const SegmentConditionField = styled.div`
  position: relative;
  flex-basis: 30%;

  .err-message {
    position: absolute;
    bottom: -24px;
    color: #e8575e;
    white-space: nowrap;
  }

  .help-wrapper {
    position: relative;
    top: -2px;
  }

  @media (max-width: 1100px) {
    .err-message {
      margin-top: 5px;
      position: relative;
      bottom: 0;
    }
  }
`;

export const SegmentConditionValueTypeDate = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;

  .input-wrapper {
    flex-basis: 40%;
  }

  .select-wrapper {
    margin-right: 0;
    flex-basis: 55%;
  }

  .preview-date {
    flex-basis: 100%;
    position: absolute;
    bottom: -24px;
    white-space: nowrap;
    color: #737373;
  }

  @media (max-width: 1100px) {
    .preview-date {
      margin-top: 5px;
      position: relative;
      bottom: 0;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;

    .input-wrapper,
    .select-wrapper {
      flex-basis: auto;
      width: 100%;
    }

    .select-wrapper {
      margin-top: 10px;
    }

    .preview-date {
      white-space: normal;
    }
  }
`;
