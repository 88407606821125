import { API, UseCase } from '@ppg/common';
import { ISubscriberNewDTO } from '../../../modelsMobx/subscriber/interfaces';

interface GetSubscribersRequest  {
  subscriber: string
}

interface GetSubscribersResponse extends ISubscriberNewDTO {
  subscriberContext: Record<string, any>;
}

export class GetSubscribersNewUseCase extends UseCase<GetSubscribersRequest, GetSubscribersResponse> {
  protected async execute({ subscriber }: GetSubscribersRequest): Promise<GetSubscribersResponse> {
    return API.HTTP.get<GetSubscribersResponse>(`core/projects/${ API.HTTP.currentProjectId }/subscribers/${subscriber}/details`);
  }
}

export const getSubscriberNewUseCase = new GetSubscribersNewUseCase();
