import { API, UseCase } from '@ppg/common';
import { getPlatformTypeParam, getTimezoneParam } from '../../../helpers';
import { IGetActiveSubscribersResult, PlatformType } from '../../../interfaces';

interface IGetProjectActiveSubscribersRequest {
  platform: PlatformType;
  timezone: string;
}

export class GetProjectActiveSubscribersUseCase extends UseCase<IGetProjectActiveSubscribersRequest, IGetActiveSubscribersResult> {
  protected async execute({ platform, timezone }: IGetProjectActiveSubscribersRequest) {
    const endpoint = `statistics/v2/projects/${ API.HTTP.currentProjectId }/subscribers/dashboard/active`
      + getPlatformTypeParam(platform) + '?' + getTimezoneParam(timezone);

    return API.HTTP.get<IGetActiveSubscribersResult>(endpoint);
  }
}

export const getProjectActiveSubscribersUseCase = new GetProjectActiveSubscribersUseCase();
