import { API, UseCase } from '@ppg/common';

interface ICreatePayuOrder {
  organization: string;
  invoices: string[];
  amount: number;
  currency: string;
  language: string;
}

export class CreatePayuOrderUseCase extends UseCase<ICreatePayuOrder, string> {
  protected async execute({ organization, invoices, amount, currency, language }) {
    return API.HTTP.post<string>(`acc/organizations/${ organization }/orders/payu`, {
      invoices, amount, currency, language
    })
  }
}

export const createPayuOrderUseCase = new CreatePayuOrderUseCase();
