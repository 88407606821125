import * as React from 'react';
import { EmptyDataComponent, Loader, Modal } from '@ppg/styled';
import { inject, observer } from 'mobx-react';
import { AccessManagerStore } from '../../../stores/AccessManagerStore';
import { t } from '../../../base/helpers';
import { observable } from 'mobx';
import { AccessManagerNewAccessKeyModal } from './AccessManagerNewAccessKeyModal';
import { AccessKey } from '../../../modelsMobx/AccessKey';
import { ProjectStore } from '../../../stores/project/ProjectStore';
import './AccessManager.scss';
import { WrappedPagination } from '../../../assets/wrapped';
import { UserHeader } from '../Layouts/UserLayout/UserHeader/UserLayoutHeader';
import { AccessManagerKey } from './AccessManagerKey';

import ReactTooltip from'react-tooltip';

interface IAccessManagerKeysProps {
  accessManagerStore?: AccessManagerStore;
  projectStore?: ProjectStore;
}

@inject('accessManagerStore', 'projectStore')
@observer
export class AccessManagerKeys extends React.Component<IAccessManagerKeysProps> {

  @observable private isNewAccessKeyModalOpen: boolean = false;

  public componentWillUnmount(): void {
    this.props.accessManagerStore.setDefaultValues();
  }

  public async componentDidMount() {
    await this.props.accessManagerStore.fetchAccessKeys();
  }

  public componentDidUpdate(): void {
    ReactTooltip.rebuild();
  }

  public render() {

    const NewAccessKeyButton = {
      size: "medium",
      icon: "plus-badge",
      content: t('user/access-manager/keys::New Key') ,
      onClick: () => this.openNewAccessKeyModal() ,
    }
    const { accessManagerStore, projectStore } = this.props;

    if(accessManagerStore.isLoading) {
      return <Loader />
    }

    return (
      <div className="section-access-key-wrapper">
        <UserHeader title={ t('API Keys') } subtitle={t('user/access-manager/keys::Manage access to projects and generate API keys') } button={NewAccessKeyButton}/>
        {
          accessManagerStore.accessKeys.length > 0 ?
            <table className="access-manager-keys-table">
              { this.renderTableHeader() }
              <tbody>
              {
                accessManagerStore.accessKeys.map((accessKey: AccessKey, id: number) => {
                  return (
                    <AccessManagerKey
                      key={id}
                      accessKey={accessKey}
                      accessManagerStore={accessManagerStore}
                      projectStore={projectStore}
                    />
                  );
                })
              }
              </tbody>
            </table> :
            <EmptyDataComponent
              content={ t('user/access-manager/keys::You don\'t have any defined keys ') }
              buttonContent={ t('user/access-manager/keys::new key') }
              icon={ 'access' }
              buttonIcon={ 'plus-badge' }
              onClick={ () => this.openNewAccessKeyModal() }
            />
        }

        <WrappedPagination total={ accessManagerStore.getProperty('accessKeysTotal') }
                           offsetProperty={ accessManagerStore.getProperty('accessKeysOffset') }
                           itemsPerPage={ accessManagerStore.getProperty('perPage') }
                           perPageSelector={ true }
                           infinityScroll={ accessManagerStore.getProperty('infinity').getValue() }/>

        <Modal show={ this.isNewAccessKeyModalOpen }
               title={ t('user/access-manager/keys::New API Key') }
               content={ <AccessManagerNewAccessKeyModal newAccessKey={ accessManagerStore.newAccessKey }/> }
               onCancel={ () => this.closeNewAccessKeyModal() }
               onConfirm={ () => this.saveNewAccessKey() }
               confirmButton={ t('user/access-manager/keys::Save') }
               cancelButton={ t('user/access-manager/keys::Cancel') }
               width={ 500 }
               showOverflow
        />
        <ReactTooltip type="light" effect="solid" place="top"/>
      </div>
    );
  }

  private openNewAccessKeyModal(): void {
    this.isNewAccessKeyModalOpen = true;
  }

  private closeNewAccessKeyModal(): void {
    this.isNewAccessKeyModalOpen = false;
  }

  private async saveNewAccessKey(): Promise<void> {
    this.props.accessManagerStore.saveAccessKey()
      .then(() => {
        this.isNewAccessKeyModalOpen = false;
        this.props.accessManagerStore.newAccessKey = new AccessKey({});
      });
  }

  private renderTableHeader() {
    return <thead className="borderb p10">
    <tr>
      <th className="primary">{ t('user/access-manager/keys::Key token') }</th>
      <th className="primary">{ t('user/access-manager/keys::Projects') }</th>
      <th className="primary">{ t('user/access-manager/keys::Expire date') }</th>
      <th className="primary"/>
    </tr>
    </thead>;
  }
}
