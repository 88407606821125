import { API, UseCase } from '@ppg/common';

export interface IPartnership {
  organization: string;
  code: string;
  enabled: boolean;
  createdAt: string;
  updatedAt: string;
}

interface IGetPartnershipRequest {
  organization: string
}

export class GetPartnershipUseCase extends UseCase<IGetPartnershipRequest, IPartnership> {
  protected async execute({ organization }) {
    return API.HTTP.get<IPartnership>(`acc/organizations/${ organization }/partnerships`)
  }
}

export const getPartnershipUseCase = new GetPartnershipUseCase();
