import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { isArrayEmpty, Property } from '@ppg/common';
import { ChartLoader, EmptyDataComponent, LineLoader } from '@ppg/styled';
import { t } from '../../../../base/helpers';
import './StatsTable.scss'
import { WrappedPagination } from '../../../../assets/wrapped';
import { IChangeFactors, IRangeFactors } from '../interfaces';

export enum EmptyComponentDashboardStatsType {
  CAMPAIGN = 'campaign',
  SUBSCRIBERS = 'subscribers',
  AUTOMATION = 'automation'
}

interface IStatsTableProps {
  fetchTable(): Promise<void>

  isPlatformSelected(): boolean;

  platformChangeFactors: IChangeFactors;
  rangeChangeFactors: IRangeFactors;

  tableData: any[];
  emptyComponentType: EmptyComponentDashboardStatsType
  initialLoaderHeight?: string,
  headers: (string | JSX.Element)[];
  body: JSX.Element;
  showPagination?: boolean;
  pagination?: {
    total: Property;
    offset: Property;
    limit: Property;
  };
  title?: string;
  emptyComponentHeight?: number;
  emptyComponentSubtitle?: string;

  clearTable?(): void;
}

export const StatsTable: FC<IStatsTableProps> = observer(
  ({
     fetchTable,
     tableData,
     headers,
     emptyComponentType,
     body,
     pagination,
     showPagination,
     clearTable,
     initialLoaderHeight,
     title,
     emptyComponentSubtitle,
     emptyComponentHeight,
     isPlatformSelected,
     platformChangeFactors,
     rangeChangeFactors
   }) => {

    const [initialLoader, setInitialLoader] = useState<boolean>(false);
    const [isLoading, setLoader] = useState<boolean>(true);

    useEffect(() => {
      if (!isPlatformSelected()) {
        clearTable && clearTable();
        return;
      }

      setLoader(true)
      fetchTable().then(() => {
        setInitialLoader(true)
        setLoader(false)
      })

    }, [rangeChangeFactors, platformChangeFactors, pagination && pagination.offset.getValue()])

    const showEmptyComponent = (): boolean => {
      if (!isPlatformSelected()) {
        return true;
      }
      return !isLoading && (isArrayEmpty(tableData));
    }

    const showInitialLoader = (): boolean => {
      return (isLoading && !initialLoader) || (isLoading && isArrayEmpty(tableData));
    }

    function isAtPagination(): boolean {
      return !!pagination && (pagination.total.getValue() > pagination.limit.getValue());
    }

    const renderEmptyComponent = (): JSX.Element => {
      if (isAtPagination()) {
        return <div className="empty-component__wrapper">
          { title && <h5 className={ 'chart-card__title m10t' }>{ title }</h5> }
          <EmptyDataComponent content={ t('No data available for this page ') }
                              subtitle={ emptyComponentSubtitle || t('Go to next page') }
                              icon={ 'calendar' }
                              height={ emptyComponentHeight }
                              hideButton/>
          { showPagination && <WrappedPagination total={ pagination.total }
                                                 offsetProperty={ pagination.offset }
                                                 itemsPerPage={ pagination.limit }
                                                 infinityScroll={ false }
                                                 hideGoToPageSelector
                                                 minItemsToShowPagination={ pagination.limit.getValue() }
          /> }
        </div>
      }

      switch (emptyComponentType) {
        case EmptyComponentDashboardStatsType.CAMPAIGN:
          return <div className="empty-component__wrapper">
            { title && <h5 className={ 'chart-card__title m10t' }>{ title }</h5> }
            <EmptyDataComponent
              content={ t('No campaigns available for a given time range or platform type') }
              icon={ 'bell' }
              height={ emptyComponentHeight }
              hideButton
            />
            { showPagination && <WrappedPagination total={ pagination.total }
                                                   offsetProperty={ pagination.offset }
                                                   itemsPerPage={ pagination.limit }
                                                   infinityScroll={ false }
                                                   hideGoToPageSelector
                                                   minItemsToShowPagination={ pagination.limit.getValue() }
            /> }
          </div>
        case EmptyComponentDashboardStatsType.SUBSCRIBERS:
          return <div className="empty-component__wrapper">
            { title && <h5 className={ 'chart-card__title m10t' }>{ title }</h5> }
            <EmptyDataComponent
              content={ t('No subscribers available for a given time range or platform type') }
              icon={ 'subscribers' }
              height={ emptyComponentHeight }
              hideButton
            />
            { showPagination && <WrappedPagination total={ pagination.total }
                                                   offsetProperty={ pagination.offset }
                                                   itemsPerPage={ pagination.limit }
                                                   infinityScroll={ false }
                                                   hideGoToPageSelector
                                                   minItemsToShowPagination={ pagination.limit.getValue() }
            /> }
          </div>
        case EmptyComponentDashboardStatsType.AUTOMATION:
          return <div className="empty-component__wrapper">
            { title && <h5 className={ 'chart-card__title m10t' }>{ title }</h5> }
            <EmptyDataComponent
              content={ t('No automations available for a given time range or platform type') }
              icon={ 'play' }
              hideButton/>
            { showPagination && <WrappedPagination total={ pagination.total }
                                                   offsetProperty={ pagination.offset }
                                                   itemsPerPage={ pagination.limit }
                                                   infinityScroll={ false }
                                                   hideGoToPageSelector
                                                   minItemsToShowPagination={ pagination.limit.getValue() }
            /> }
          </div>
      }
    }

    if (showEmptyComponent()) {
      return renderEmptyComponent();
    }

    if (showInitialLoader()) {
      return <>
        { title && <h5 className={ 'chart-card__title m10t' }>{ title }</h5> }
        <div className={ 'stats-table__loadersWrapper well' } style={ { height: initialLoaderHeight ?? 'auto' } }>
          <div className={ 'stats-table__loaders' }>
            <LineLoader/>
            <ChartLoader/>
          </div>
        </div>
      </>
    }

    return <>
      { title && <h5 className={ 'chart-card__title m10t' }>{ title }</h5> }
      <div className={ 'stats-table-container' }>
        { isLoading && <LineLoader/> }
        <table className="stats-table">
          <thead>
          <tr>{ headers.map((header, idx) => <th key={ idx }> { header }</th>) }</tr>
          </thead>
          <tbody>
          { body }
          </tbody>
        </table>

        { showPagination && <WrappedPagination total={ pagination.total }
                                               offsetProperty={ pagination.offset }
                                               itemsPerPage={ pagination.limit }
                                               infinityScroll={ false }
                                               hideGoToPageSelector
                                               minItemsToShowPagination={ pagination.limit.getValue() }
        /> }

      </div>
    </>

  })
