import { API, UseCase } from '@ppg/common';

interface IGetAccountList {
  organization: string;
}

interface IGetAccountListResult {
  id: string;
  balance: number;
  currency: string;
}

export class GetAccountListUseCase extends UseCase<IGetAccountList, IGetAccountListResult[]> {
  protected async execute({ organization }) {
    return API.HTTP.get<IGetAccountListResult[]>(`acc/organizations/${ organization }/accounts`)
  }
}

export const getAccountListUseCase = new GetAccountListUseCase();
