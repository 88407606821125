import { API, UseCase } from '@ppg/common';
import { ISettlementPolicyPlan } from './GetCurrentAndNextSettlementPolicyPlanUseCase';

interface IGetNextOfferPlanRequest {
  organization: string
}

interface IGetNextOfferPlanResponse{
  next: ISettlementPolicyPlan
}

export class GetNextOfferPlan
  extends UseCase<IGetNextOfferPlanRequest, IGetNextOfferPlanResponse> {
  protected async execute({organization}) {
    return API.HTTP.get<IGetNextOfferPlanResponse>(
      `acc/organizations/${organization}/offers/next`
    )
  }
}

export const getNextOfferPlan = new GetNextOfferPlan();
