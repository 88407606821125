import * as React from 'react';
import { observer } from 'mobx-react';

interface ILiteWelcomeStepProps {
  number: number;
  title: string;
  subtitle: string
  children: React.ReactNode;
}

@observer
export class LiteProjectEmptyDataStep extends React.Component<ILiteWelcomeStepProps, {}> {
  public render() {
    const { number, title, subtitle, children} = this.props;
    return <div>
        <div className="lite-welcome-step">
          <div className="lite-welcome-step-header">
            <span className="text-brand size60 bold">{number}.</span>
            <div className="lite-welcome-step-header-content">
              <h2 className="lite-welcome-step-header-content-title size26">{ title }</h2>
              <span className="lite-welcome-step-header-content-subtitle">{ subtitle }</span>
            </div>
          </div>
        </div>
      { children }
      </div>
  }
}