import { IPushCampaignOptions } from './PushCampaignOptions';
import { Label, LabelCountStrategy, LabelState } from '../Label';
import { isArrayEmpty, property } from '@ppg/common';
import { action, computed } from 'mobx';
import { IStrategyChange } from '../../components/SegmentationLabelsFilter';

interface ILabelsPushCampaignOptions extends IPushCampaignOptions {
  labels: Label[];
  excludedLabels: Label[];
  labelsStrategy: LabelCountStrategy;
  excludedLabelsStrategy: LabelCountStrategy;
}

export class LabelsPushCampaignOptions implements ILabelsPushCampaignOptions {

  @property() public sentBy: string;
  @property() public fromAPI: boolean;

  @property() public labels: Label[] = [];
  @property() public excludedLabels: Label[] = [];
  @property() public labelsStrategy: LabelCountStrategy = LabelCountStrategy.OR;
  @property() public excludedLabelsStrategy: LabelCountStrategy = LabelCountStrategy.OR;

  @property() public selectedLabels: Label[] = [];
  @property() public selectedExcludedLabels: Label[] = [];
  @property() public selectedLabelsStrategy: LabelCountStrategy = LabelCountStrategy.OR;
  @property() public selectedExcludedLabelsStrategy: LabelCountStrategy = LabelCountStrategy.OR;

  constructor(sentBy, fromAPI, labels, excludedLabels, labelsStrategy, excludedLabelsStrategy) {
    this.sentBy = sentBy;
    this.fromAPI = fromAPI;
    this.labels = labels;
    this.excludedLabels = excludedLabels;
    this.labelsStrategy = labelsStrategy;
    this.excludedLabelsStrategy = excludedLabelsStrategy;
  }

  public getOptionsDTO(): Record<string, any> {
    return {
      labels: {
        labels: this.labels ? this.labels.map(label => label.serialize()) : [],
        strategy: this.labelsStrategy
      },
      excludedLabels: {
        labels: this.excludedLabels ? this.excludedLabels.map(label => label.serialize()) : [],
        strategy: this.excludedLabelsStrategy
      }
    }
  }

  @computed
  public get hasLabels(): boolean {
    return this.labels?.length > 0 || this.excludedLabels?.length > 0;
  }

  private checkLabelExists(existLabel:Label, labels:Label[]): boolean {
    return !!labels.find(actualLabel => actualLabel.getLabelIdentity() === existLabel.getLabelIdentity());
  }

  @action
  public async toggleLabelSelected(label: Label): Promise<void> {
    switch (label.state) {
      case LabelState.INCLUDED:
        this.selectedLabels.push(label);
        break;

      case LabelState.EXCLUDED:
        this.selectedExcludedLabels.push(label);
        break;

      case LabelState.IDLE:
        if (this.checkLabelExists(label, this.selectedLabels)) {
          this.selectedLabels = this.selectedLabels.filter(item => item.getLabelIdentity() !== label.getLabelIdentity());

        } else if (this.checkLabelExists(label, this.selectedExcludedLabels)) {
          this.selectedExcludedLabels = this.selectedExcludedLabels.filter(item => item.getLabelIdentity() !== label.getLabelIdentity());
        }
        break;
    }
  }

  @action
  public async toggleLabel(label: Label): Promise<void> {
    switch (label.state) {
      case LabelState.INCLUDED:
        this.labels.push(label);
        break;

      case LabelState.EXCLUDED:
        this.excludedLabels.push(label);
        break;

      case LabelState.IDLE:
        if (this.checkLabelExists(label, this.labels)) {
          this.labels = this.labels.filter(item => item.getLabelIdentity() !== label.getLabelIdentity());
          if(isArrayEmpty(this.labels)) this.labelsStrategy = LabelCountStrategy.OR


        } else if (this.checkLabelExists(label, this.excludedLabels)) {
          this.excludedLabels = this.excludedLabels.filter(item => item.getLabelIdentity() !== label.getLabelIdentity());
          if(isArrayEmpty(this.excludedLabels)) this.excludedLabelsStrategy = LabelCountStrategy.OR
        }
        break;
    }
  }

  public async onLabelRemoveSelected(label: Label): Promise<void> {
    label.setState(LabelState.IDLE)
    await this.toggleLabel(label)
  }

  @action
  public async onStrategyChange(strategy: IStrategyChange): Promise<void> {
    this.selectedLabelsStrategy = strategy.includedStrategy;
    this.selectedExcludedLabelsStrategy = strategy.excludedStrategy;
  }

  @action
  public deselectSelectedLabels(): void {
    this.selectedLabels = []
    this.selectedExcludedLabels = []
    this.selectedLabelsStrategy = LabelCountStrategy.OR
    this.selectedExcludedLabelsStrategy = LabelCountStrategy.OR
  }

  @action
  public onSaveLabels(): void {
    this.labels = [...this.selectedLabels]
    this.excludedLabels = [...this.selectedExcludedLabels]
    this.labelsStrategy = this.selectedLabelsStrategy
    this.excludedLabelsStrategy = this.selectedExcludedLabelsStrategy
    this.deselectSelectedLabels()
  }

  @action
  public onSetSelectedLabels(): void {
    this.selectedLabels = [...this.labels]
    this.selectedExcludedLabels = [...this.excludedLabels]
    this.selectedLabelsStrategy = this.labelsStrategy
    this.selectedExcludedLabelsStrategy = this.excludedLabelsStrategy
  }
}
