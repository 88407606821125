import { UseCase, API } from '@ppg/common';
import { IGetPlugin, IGetWebsiteIntegrationResult } from './interfaces';

export class GetWebsiteIntegrationDetailsUseCase extends UseCase<IGetPlugin, IGetWebsiteIntegrationResult> {
  protected async execute({ websiteIntegrationId }: IGetPlugin) {
    return API.HTTP.get<IGetWebsiteIntegrationResult>(`wi/projects/${ API.HTTP.currentProjectId }/website-integrations/${ websiteIntegrationId }`)
  }
}

export const getWebsiteIntegrationDetailsUseCase = new GetWebsiteIntegrationDetailsUseCase();
