import { API, UseCase } from '@ppg/common';
import { IUpdateBasicSelector } from './interfaces';

export class UpdateBasicSelectorUseCase extends UseCase<IUpdateBasicSelector, {}> {
  protected async execute({ projectID, selectorID, labelKey, labelStrategy, labelTTL, selector, prettyName, tagLabel }) {
    return API.HTTP.put(`core/projects/${ projectID }/selectors/${ selectorID }/basic`, {
      selector,
      prettyName,
      tagLabel,
      labelKey,
      labelStrategy,
      labelTTL,
    });
  }
}

export const updateBasicSelectorUseCase = new UpdateBasicSelectorUseCase();
