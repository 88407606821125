import { API, UseCase } from '@ppg/common';

interface IGenerateConfirmationTokenForResetPasswordUseCase {
  email: string;
}

export class GenerateConfirmationTokenForResetPasswordUseCase extends UseCase<IGenerateConfirmationTokenForResetPasswordUseCase, void> {
  protected async execute({ email }) {
    await API.HTTP.post<void>('aai/user/password/reset', { email });
  }
}

export const generateConfirmationTokenForResetPasswordUseCase = new GenerateConfirmationTokenForResetPasswordUseCase();
