import { LoadingScreen } from '@ppg/styled';
import cs from 'classnames';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { projectRoutes } from '../../routes/projectRoutes';
import RenderRoutes from '../../routes/RenderRoutes';
import { ProjectStore } from '../../stores/project/ProjectStore';
import { AccidentHandler } from '../AccidentHandler';
import { NavBar } from '../navbar/NavBar';
import { ProjectSidebar } from '../Navigation/projectSidebar';
import { WebpSupportInfo } from './WebpSupportInfo';
import { UserStore } from '../../stores/user/UserStore';
import { AccountBlocker } from '../AccountBlocker/AccountBlocker';

interface IProjectLayoutComponentProps extends RouteComponentProps {
  projectStore?: ProjectStore;
  userStore?: UserStore;
}

@inject('projectStore', 'userStore')
@observer
class ProjectLayoutComponent extends React.Component<IProjectLayoutComponentProps> {
  @observable private isLoading: boolean = true;
  @observable public hideNavigation: boolean = false;

  public async componentDidMount() {
    // This a required data for correct application work
    const searchParams = new URLSearchParams(this.props.location.search);
    if (searchParams.has('project')) {
      this.props.projectStore.currentProjectID = searchParams.get('project');
    }

    await this.props.projectStore.fetchProjects();

    setTimeout(() => this.isLoading = false, 200);

    this.checkDeviceWidth();
    window.addEventListener('resize', this.checkDeviceWidth);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.checkDeviceWidth)
  }

  checkDeviceWidth = () => {
    this.hideNavigation = window.innerWidth < 721;
  }

  toggleShowNavigation = () => {
    this.hideNavigation = !this.hideNavigation;
  }

  toggleOnSmallDevice = () => {
    if (window.innerWidth < 721 && !this.hideNavigation) {
      this.toggleShowNavigation();
    }
  }

  public render() {
    if (this.isLoading) {
      return <LoadingScreen/>;
    }

    return (
      <>
        <AccountBlocker/>
        <NavBar hideNavigation={ !!this.hideNavigation }
                showShortLogo={ false }
                toggleNav={ this.toggleShowNavigation }/>
        <ProjectSidebar hideNavigation={ !!this.hideNavigation } toggleNav={ this.toggleOnSmallDevice }/>
        <section className={ cs('main-content') }>
          <WebpSupportInfo/>
          <AccidentHandler { ...this.props }/>
          <div className="container">
            <RenderRoutes routes={ projectRoutes }/>
          </div>
        </section>
      </>
    );
  }
}

export const ProjectLayout = withRouter(ProjectLayoutComponent);
