export class Message {
  public constructor(type: MessageType, message: string) {
    this.type = type;
    this.message = message;
  }

  public isError(): boolean {
    return this.type === Message.ERROR;
  }

  public isWarning(): boolean {
    return this.type === Message.WARNING;
  }

  public isInfo(): boolean {
    return this.type === Message.INFO;
  }

  public isSuccess(): boolean {
    return this.type === Message.SUCCESS;
  }

  public static Error(message: string): Message {
    return new Message(Message.ERROR, message);
  }

  public static Warning(message: string): Message {
    return new Message(Message.WARNING, message);
  }

  public static Info(message: string): Message {
    return new Message(Message.WARNING, message);
  }

  public static Success(message: string): Message {
    return new Message(Message.WARNING, message);
  }

  public static readonly ERROR: MessageType = 'ERROR';
  public static readonly WARNING: MessageType = 'WARNING';
  public static readonly INFO: MessageType = 'INFO';
  public static readonly SUCCESS: MessageType = 'SUCCESS';

  public readonly type: MessageType;
  public readonly message: string;
}

export type MessageType = 'ERROR' | 'WARNING' | 'INFO' | 'SUCCESS';