import { isNotEmpty, property } from "@ppg/common";
import { getFabButtonPluginUseCase, updateFabButtonPluginUseCase } from '../../useCases/website-integration';
import { action } from 'mobx';
import { WebsiteIntegrationRelated } from '../../stores/project/WebsiteIntegrationRelated';

export interface ISidebarFab {
  title?: string;
  disabledText?: string;
  enabledText?: string;
  enableButtonText?: string;
  disableButtonText?: string;
  fabIcon?: string;
  subscribeToggle: boolean;
  primaryColor: string;
  css: string;
  nativeBlockedText: string;
}

export class SidebarFab extends WebsiteIntegrationRelated implements ISidebarFab {

  @property([isNotEmpty()]) public title: string;
  @property([isNotEmpty()]) public disabledText: string;
  @property([isNotEmpty()]) public enabledText: string;
  @property([isNotEmpty()]) public enableButtonText: string;
  @property([isNotEmpty()]) public disableButtonText: string;
  @property() public fabIcon: string;
  @property() public isFabIconValid: boolean = true;
  @property() public subscribeToggle: boolean;
  @property() public primaryColor: string;
  @property() public css: string;
  @property() public nativeBlockedText: string;

  @action
  public async fetchData(): Promise<void> {
    const { payload } = await getFabButtonPluginUseCase.exec({ websiteIntegrationId: this.websiteIntegrationId });

    this.subscribeToggle = payload.enabled;
    this.title = payload.title;
    this.disableButtonText = payload.disableButtonText;
    this.disabledText = payload.disabledText;
    this.enableButtonText = payload.enableButtonText;
    this.enabledText = payload.enabledText;
    this.fabIcon = payload.fabIcon;
    this.primaryColor = payload.primaryColor;
    this.css = payload.css;
    this.nativeBlockedText = payload.nativeBlockedText;
  }

  public async save(): Promise<void> {
    await updateFabButtonPluginUseCase.exec({
      websiteIntegrationId: this.websiteIntegrationId,
      css: this.css,
      enabled: this.subscribeToggle,
      fabIcon: this.fabIcon,
      enabledText: this.enabledText,
      enableButtonText: this.enableButtonText,
      disabledText: this.disabledText,
      disableButtonText: this.disableButtonText,
      title: this.title,
      nativeBlockedText: this.nativeBlockedText,
      primaryColor: this.primaryColor,
    })
  }
}
