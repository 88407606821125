import { API, UseCase } from '@ppg/common';

interface IUnsuspendCampaign {
  projectID: string;
  push: string;
}

export class UnsuspendCampaignUseCase extends UseCase<IUnsuspendCampaign, void> {
  protected async execute({ projectID, push }) {
    return API.HTTP.post<void>(`core/projects/${ projectID }/pushes/${ push }/unsuspend`)
  }
}

export const unsuspendCampaignUseCase = new UnsuspendCampaignUseCase();
