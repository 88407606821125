import { observer } from 'mobx-react';
import { t } from '../../../../../base/helpers';
import * as React from 'react';
import { useEffect } from 'react';
import { organizationDashboardStore } from '../../../../../stores';
import { ChartType } from '../../../common/ChartCard/__types__';
import {
  customComparatorLineChartProperties,
  EmptyComponentDashboardStatsType,
  ChartTableLayout,
  ChartCard
} from '../../../common';
import { ProjectComparatorTable } from './ProjectComparatorTable';

export const ProjectComparisonSubscribers = observer(() => {
  const { projectComparisonStore, isPlatformSelected, platformChangeFactors, rangeChangeFactors } = organizationDashboardStore;
  const {
    subscribedChartData,
    unsubscribedChartData,
    comparatorSubscriberTables,
    fetchSubscribersHistogram,
    fetchSubscribersTableData,
    cleanSubscribersData,
  } = projectComparisonStore;

  useEffect(() => {
    return cleanSubscribersData;
  }, []);

  return <ChartTableLayout activeViewType={ organizationDashboardStore.activeViewType }
                           chart={ <>
                             <ChartCard fetchData={ fetchSubscribersHistogram }
                                        mountFetchData={ fetchSubscribersHistogram }
                                        customProperties={ { line: customComparatorLineChartProperties } }
                                        data={ { line: subscribedChartData } }
                                        platformChangeFactors={ platformChangeFactors }
                                        isPlatformSelected={ isPlatformSelected }
                                        rangeChangeFactors={ rangeChangeFactors }
                                        chartType={ ChartType.LINE }
                                        size={ 'large' }
                                        title={ t('organization/project-comparison::Subscribed') }/>

                             <ChartCard data={ { line: unsubscribedChartData } }
                                        customProperties={ { line: customComparatorLineChartProperties } }
                                        chartType={ ChartType.LINE }
                                        platformChangeFactors={ platformChangeFactors }
                                        isPlatformSelected={ isPlatformSelected }
                                        rangeChangeFactors={ rangeChangeFactors }
                                        size={ 'large' }
                                        title={ t('organization/project-comparison::Unsubscribed') }/>
                           </> }
                           table={ <>
                             <ProjectComparatorTable title={ t('organization/project-comparison::Subscribed') }
                                                     comparatorTable={ comparatorSubscriberTables.subscribed }
                                                     emptyComponentHeight={ 505 }
                                                     emptyComponentType={ EmptyComponentDashboardStatsType.SUBSCRIBERS }
                                                     fetchTableData={ () => fetchSubscribersTableData('subscribed') }/>

                             <ProjectComparatorTable title={ t('organization/project-comparison::Unsubscribed') }
                                                     comparatorTable={ comparatorSubscriberTables.unsubscribed }
                                                     emptyComponentHeight={ 505 }
                                                     emptyComponentType={ EmptyComponentDashboardStatsType.SUBSCRIBERS }
                                                     fetchTableData={ () => fetchSubscribersTableData('unsubscribed') }/>
                           </> }/>
})
