import { UseCase, API } from "@ppg/common";

interface IChooseWinner {
  abTestId: string;
  variantId: string;
  winnerSendDate: string;
  winnerExpireDate: string;
}

export class ChooseWinnerUseCase extends UseCase<IChooseWinner, void> {
  protected async execute({ winnerSendDate, winnerExpireDate, variantId, abTestId }: IChooseWinner) {
    return API.HTTP.post<void>(`core/projects/${ API.HTTP.currentProjectId }/ab-tests/${ abTestId }/accept/${ variantId }`, {
      winnerSendDate, winnerExpireDate
    });
  }
}

export const chooseWinnerUseCase = new ChooseWinnerUseCase();
