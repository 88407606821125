import { API, UseCase } from '@ppg/common';

interface IUpdateTags {
  organization: string;
  channel: string;
  tags: string[];
}

export class UpdateTagsUseCase extends UseCase<IUpdateTags, void> {
  protected async execute({ organization, channel, tags }) {
    await API.HTTP.put(`notification/organizations/${ organization }/channels/${ channel }/tags`, { tags });
  }
}

export const updateTagsUseCase = new UpdateTagsUseCase();
