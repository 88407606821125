import { API, UseCase } from "@ppg/common";
import { IOrganizationDashboardRequestBody } from '../../../interfaces';
import { IListQueryOptions } from '../../../../interfaces/IListQueryOptions';
import { getListQueryParam, getRangeDateParam, getTimezoneParam } from '../../../helpers';

interface IGetActiveSubscribersRatioHistogramRequest extends IOrganizationDashboardRequestBody, IListQueryOptions {}

export interface IActiveSubscribersRatioHistogramItem {
  day: string;
  deliveredRatio: number;
}

export interface IGetActiveSubscribersRatioHistogramResult {
  deliveredActiveSubscribersRatio: {
    total: number;
    histogram: IActiveSubscribersRatioHistogramItem[]
  }
}

export class GetActiveSubscribersRatioHistogramUseCase extends UseCase<IGetActiveSubscribersRatioHistogramRequest, IGetActiveSubscribersRatioHistogramResult> {
  protected async execute({ organization, from, to, limit, offset, timezone }: IGetActiveSubscribersRatioHistogramRequest) {
    let endpoint = `statistics/organizations/${ organization }/subscribers/active/ratio/histogram`
      + getRangeDateParam(from, to) + getTimezoneParam(timezone) + getListQueryParam(offset, limit);

    return API.HTTP.get<IGetActiveSubscribersRatioHistogramResult>(endpoint);
  }
}

