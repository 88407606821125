import { API, UseCase } from '@ppg/common';

export interface I18NUseCaseCommand {
  lang: string;
}

export interface I18NResponse {
  translations: {
    key: string;
    value: string;
  }[];
}

export class GetTranslationsUseCase extends UseCase<I18NUseCaseCommand, I18NResponse> {
  protected async execute({ lang }) {
    return API.HTTP.get<I18NResponse>(`i18n/${ lang }`);
  }
}

export const getTranslationsUseCase = new GetTranslationsUseCase();
