import { ActiveViewType } from '../../../User/Navigations/UnderlinedNavigationBar/UnderlinedNavigationBar';
import { observer } from 'mobx-react';
import * as React from 'react';
import { FC } from 'react';

interface IChartTableLayoutProps {
  chart: JSX.Element,
  table: JSX.Element
  activeViewType: ActiveViewType;
}
export const ChartTableLayout:FC<IChartTableLayoutProps> = observer(({chart, table, activeViewType}): JSX.Element | null => {
  switch (activeViewType) {
    case ActiveViewType.CHART:
      return chart;
    case ActiveViewType.TABLE:
      return table;
    default:
      return null;
  }
});

