import { colors } from '../colors/colors';

export interface IBorder {
  base: string,
  light: string,
  radius: string,
  color: string
}

export const border: IBorder = {
  base: `0.1rem solid ${ colors.functional.border }`,
  light: `0.1rem solid ${ colors.functional.background }`,
  radius: '0.4rem',
  color: colors.functional.border,
};
