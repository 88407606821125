import { API, UseCase } from '@ppg/common';

interface IActivateAccount {
  token: string;
}

export class ActivateAccountUseCase extends UseCase<IActivateAccount, void> {
  protected async execute({ token }: IActivateAccount) {
    return API.HTTP.post<void>(`aai/user/activate`, { token })
  }
}

export const activateAccountUseCase = new ActivateAccountUseCase();
