import * as React from 'react';
import * as lodash from 'lodash';

export function bind(propertyName: string): ClassDecorator {
  return function (TargetConstructor: typeof React.Component) {
    return class extends TargetConstructor<any, any> {
      private triggerUpdate = () => {
        this.forceUpdate();
      }

      public componentDidMount() {
        lodash.invoke(this.props[propertyName], 'on', 'change', this.triggerUpdate);
      }

      public componentWillUnmount() {
        lodash.invoke(this.props[propertyName], 'off', 'change', this.triggerUpdate);
      }

      public render() {
        return <TargetConstructor {...this.props} />;
      }
    };
  } as ClassDecorator;
}


