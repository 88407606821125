import { ProjectRelated } from '../ProjectRelatedStore';
import { debounce, property } from '@ppg/common';
import { iconCodes, ITEMS_PER_PAGE } from '../../constants';
import { action, computed, observable, reaction } from 'mobx';
import { listPushCampaignsUseCase, suspendCampaignUseCase, unsuspendCampaignUseCase } from '../../useCases/core';
import { t } from '../../base/helpers';
import { CampaignState, PushCampaign } from '../../modelsMobx/PushCampaign';
import { apiManager } from '../../base';
import { getPushStateUseCase } from '../../useCases/core/push/GetPushStateUseCase';
import { PushCampaign as PushCampaignModel } from '../../models';
import { Monitor } from '../../components/common';
import { toast } from '@ppg/styled';

export class PushCampaignStore extends ProjectRelated {
  @property() public infinity: boolean;
  @property() public offset: number = 0;
  @property() public perPage: number = ITEMS_PER_PAGE;

  @property() public searchQuery: string = '';

  @property() public pushCampaigns: PushCampaign[];
  @property() public state: string = 'all';
  @property() public sort: string = 'sendDate';

  @observable public total: number;
  @observable public isLoading: boolean = true;
  @observable public monitors: Monitor[] = [];

  reactOnChange = reaction(() => this.doFetch, () => this.fetchOnPagination());
  reactOnSelectChange = reaction(() => this.doFetchSelect, () => this.fetchOnSelect());
  reactOnQueryChange = reaction(() => this.searchQuery, () => this.debouncedOnSearchFetch());

  public debouncedFetch = debounce(() => this.fetchCampaigns(), 300);
  public debouncedOnSearchFetch = debounce(() => this.listOnQueryChange(), 300);

  public setDefaultValues(): void {
    this.offset = 0;
    this.perPage = ITEMS_PER_PAGE;
    this.searchQuery = '';
    this.resetPushCampaigns();
    this.total = 0;
    this.state = 'all';
    this.sort = 'sendDate';
    this.clearAllMonitors();
  }

  @computed
  get doFetch() {
    return {
      offset: this.offset,
      perPage: this.perPage,
    };
  }

  @computed
  get doFetchSelect() {
    return {
      sort: this.sort,
      state: this.state,
    };
  }

  public async listCampaigns(): Promise<void> {
    if (this.pushCampaigns && this.pushCampaigns.length > 0) {
      return;
    }
    await this.fetchCampaigns();
  }

  public listOnQueryChange(): void {
    this.resetPushCampaigns();
    this.isLoading = true;
    this.offset === 0 ? this.debouncedFetch() : this.offset = 0;
  }

  @action
  public async fetchCampaigns(): Promise<void> {
    this.isLoading = true;
    const { metadata, data } = await listPushCampaignsUseCase.exec({
      projectID: this.projectId,
      limit: this.perPage,
      offset: this.offset,
      search: this.searchQuery,
      state: this.state === 'all' ? '' : this.state,
      sort: this.sort,
    });

    const campaigns = data.map(campaign =>
      new PushCampaign(campaign));

    this.total = metadata.total;

    this.pushCampaigns = this.infinity ? this.pushCampaigns.concat(campaigns) : campaigns;
    this.isLoading = false;
    this.setMonitors();
    this.infinity && this.offset > 0 && window.scrollTo({ top: document.body.scrollHeight });
  }

  private setMonitors() {
    if (!this.pushCampaigns) {
      return;
    }

    this.clearAllMonitors();
    this.pushCampaigns.forEach(campaign => this.setMonitor(campaign));
  }

  @action
  public async fetchOnPagination(): Promise<void> {
    return this.fetchCampaigns();
  }

  @action
  public async fetchOnSelect(): Promise<void> {
    this.resetPushCampaigns();
    this.offset === 0 ? this.fetchCampaigns() : this.offset = 0;
  }

  @action
  public resetPushCampaigns(): void {
    this.pushCampaigns = [];
  }

  @action
  public async onDeleteConfirm(campaign: PushCampaign): Promise<void> {
    await apiManager.del<any>(`project/${ this.projectId }/push/${ campaign.id }`)
      .then(() => toast.success(t('Your campaign has been deleted successfully')))
      .then(() => {
        this.resetPushCampaigns();
        this.offset === 0 ? this.fetchCampaigns() : this.offset = 0;
      });
  }

  @action
  private clearAllMonitors() {
    if (!this.monitors) {
      return this.monitors = [];
    }

    this.monitors.forEach((monitor: Monitor) => monitor.clear());
    this.monitors = [];
  }

  private setMonitor(campaign: PushCampaign) {
    if (campaign.needsMonitor) {
      const time = campaign.timeToBeSent;
      const monitor = new Monitor(() => this.getState(campaign.id)
        .then(({ state }) => {
          campaign.setCampaignState(state);
          if (!campaign.needsMonitor) {
            monitor.clear();
          }
        }), 1000, time);
      this.monitors.push(monitor);
    }
  }

  public async getState(campaignId: string): Promise<any> {
    return await getPushStateUseCase.exec({
      projectId: this.projectId,
      pushId: campaignId
    });
  }

  public async getCampaignCopyObject(campaignId: string): Promise<any> {
    const campaign = await PushCampaignModel.getPush(this.projectId, campaignId);
    return campaign.toCopy();
  }

  public async onPauseConfirm(campaign: PushCampaign): Promise<CampaignState> {
    await suspendCampaignUseCase.exec({
      projectID: this.projectId,
      push: campaign.id
    });
    const updatedCampaign = await this.getState(campaign.id);
    return updatedCampaign.state;
  }

  public async onUnpauseConfirm(campaign: PushCampaign): Promise<CampaignState> {
    await unsuspendCampaignUseCase.exec({
      projectID: this.projectId,
      push: campaign.id,
    });
    const updatedCampaign = await this.getState(campaign.id);
    return updatedCampaign.state;
  }

  private get disableSendDate(): boolean {
    return this.disableExtendedSort && this.state !== 'ready';
  }

  public get disableExtendedSort(): boolean {
    return ['all', 'sent', 'triggered'].indexOf(this.state) === -1;
  }

  @action
  public handleSearch(event: React.FormEvent<HTMLInputElement>): void {
    this.searchQuery = event.currentTarget.value;
  }

  public get sortOptions() {
    return [
      { name: t('campaign/list/push::Created date') + iconCodes.arrowDown, value: 'createdAt' },
      { name: t('campaign/list/push::Created date') + iconCodes.arrowUp, value: '-createdAt' },
      { name: t('campaign/list/push::Send date') + iconCodes.arrowDown, value: 'sendDate', disabled: this.disableSendDate },
      { name: t('campaign/list/push::Send date') + iconCodes.arrowUp, value: '-sendDate', disabled: this.disableSendDate },
    ];
  }

  public get stateOptionsNewList() {
    return [
      { name: t('campaign/list/push::All'), value: 'all' },
      { name: t('campaign/list/push::Sent'), value: 'sent' },
      { name: t('campaign/list/push::Ready'), value: 'ready' },
      { name: t('campaign/list/push::Draft'), value: 'draft' },
      { name: t('campaign/list/push::Triggered'), value: 'triggered' },
      { name: t('campaign/list/push::Failed'), value: 'failed' },
      { name: t('campaign/list/push::Stopped'), value: 'stopped' },
    ];
  }

  public get stateOptions() {
    return [
      { name: t('campaign/list/push::All'), value: 'all' },
      { name: t('campaign/list/push::Sent'), value: 'sent' },
      { name: t('campaign/list/push::Ready'), value: 'ready' },
      { name: t('campaign/list/push::Draft'), value: 'draft' },
      { name: t('campaign/list/push::Triggered'), value: 'triggered' },
      { name: t('campaign/list/push::Failed'), value: 'failed' },
      { name: t('campaign/list/push::Stopped'), value: 'stopped' },
      { name: t('campaign/list/push::Suspended'), value: 'suspended' },
    ];
  }

  @action
  public setInfinity(infinity: boolean): void {
    this.infinity = infinity;
  }

  @computed
  public get getInfinity(): boolean {
    return this.infinity;
  }

  @computed
  public get hasCampaigns(): boolean {
    return this.pushCampaigns.length > 0;
  }

  @action
  public setCampaignState(state): void {
    this.state = state;
  }
}
