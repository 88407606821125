import { ProjectRelated } from '../ProjectRelatedStore';
import { property } from '@ppg/common';
import { action, computed } from 'mobx';
import { chooseWinnerUseCase, getABTestUseCase } from '../../useCases/core';
import { Label, LabelCountStrategy } from '../../modelsMobx/Label';
import { getResolveIdsToLabels } from '../../useCases/core/label/GetResolveIdsToLabels';
import { ABTestState, ABVariant, ActionsUseCases, IABVariant } from '../../modelsMobx';
import { getABTestStatisticUseCase } from '../../useCases/statistics/project';
import { DataType, IABTestReportStore, IFetchTest, IStatisticsFromResponse, SortDirection } from './IABTestReportStore';
import { IGetLabels, IStartExpireTimeValues, ITTLRange, ITTLRangeResponse } from './ICreateABTestStore';
import dayjs from 'dayjs';
import { ILabelDTO, ISegmentDTO } from '../../useCases/core/campaigns/interfaces';

export class ABTestReportStore extends ProjectRelated implements IABTestReportStore {
  @property() public id;
  @property() public name;
  @property() public sample;
  @property() public state;
  @property() public testStartDate;
  @property() public testEndDate;
  @property() public winnerSendDate;
  @property() public winnerExpireDate;
  @property() public winner;
  @property() public policy;
  @property() public tagsCountStrategy = LabelCountStrategy.AND;
  @property() public excludedTagsCountStrategy = LabelCountStrategy.AND;
  @property() public polygons;
  @property() public variants = [];
  @property() public selectedVariant = this.variants[0];
  @property() public labels = [];
  @property() public availableActions = [];
  @property() public segment: ISegmentDTO;
  @property() public includedLabels: ILabelDTO[];
  @property() public excludedLabels: ILabelDTO[];
  @property() public labelsStrategy: LabelCountStrategy;
  @property() public excludedLabelsStrategy: LabelCountStrategy;

  @property() public variantDataType = DataType.CLICKED;

  @property() public isLoading = true;

  public static MAX_TTL = 259200;
  public static MIN_TTL = 3600;

  @action
  public fetchABTest = async ({ abTestId }: IFetchTest): Promise<void> => {
    const abTest = await getABTestUseCase.exec({ abTestId });
    this.id = abTest.id;
    this.name = abTest.name;
    this.sample = abTest.sample;
    this.state = abTest.state;
    this.testStartDate = new Date(abTest.testStartDate);
    this.testEndDate = new Date(abTest.testEndDate);
    this.winner = new ABVariant({
      id: abTest.winner.id,
      name: abTest.winner.name || '',
      icon: abTest.winner.icon,
      image: abTest.winner.image,
      actions: abTest.winner.actions || [],
      requireInteraction: abTest.winner.requireInteraction,
      redirectLink: abTest.winner.redirectLink,
      title: abTest.winner.title,
      content: abTest.winner.content || '',
      direction: abTest.winner.direction
    });
    this.policy = abTest.policy;
    this.tagsCountStrategy = abTest.queryCriteria.tagsCountStrategy;
    this.excludedTagsCountStrategy = abTest.queryCriteria.excludedTagsCountStrategy;
    this.polygons = abTest.queryCriteria.polygon;
    this.availableActions = abTest.availableActions;
    this.winnerSendDate = abTest.winnerSendDate ? new Date(abTest.winnerSendDate) : null;
    this.winnerExpireDate = abTest.winnerExpireDate ? new Date(abTest.winnerExpireDate) : null;
    this.segment = abTest.queryCriteria.segment;
    this.labelsStrategy = abTest.queryCriteria.labelsStrategy;
    this.excludedLabelsStrategy = abTest.queryCriteria.excludedLabelsStrategy;
    this.includedLabels = abTest.queryCriteria.labels.labels;
    this.excludedLabels = abTest.queryCriteria.excludedLabels.labels;

    await this.getLabelsFromId({ tags: abTest.queryCriteria.tags, excludedTags: abTest.queryCriteria.excludedTags });
    this.variants = abTest.variants.map(variant => new ABVariant(variant));
    this.selectedVariant = this.hasWinner ? this.winner : this.variants[0];
  };

  @action
  public setIsLoading(value: boolean): void {
    this.isLoading = value;
  }

  @action
  public getABTestReport = async (): Promise<void> => {
    const { variants, winner } = await getABTestStatisticUseCase.exec({
      abTestId: this.id,
      projectId: this.projectId
    });
    this.setStatisticsFromResponse({ variants, winner });
  };

  @action
  public setStatisticsFromResponse = ({ variants, winner }: IStatisticsFromResponse): void => {
    this.variants.forEach(testVariant => {
      const variant = variants.find(v => v.id === testVariant.id);
      const { totalClicked, sent, delivered, dsp, ctr, lost } = variant;
      testVariant.setStatistics({ totalClicked, sent, delivered, dsp, ctr, clicked, lost });
    });

    const { totalClicked, sent, delivered, dsp, ctr, clicked, lost } = winner;
    this.winner.setStatistics({ totalClicked, sent, delivered, dsp, ctr, clicked, lost });
  };

  public getSortedVariants = ({ direction, sortType }: { direction: SortDirection, sortType: DataType }): ABVariant[] => {
    if (!direction || !sortType) {
      return this.variants;
    }
    const sortedVariants = [...this.variants];
    const sortBy = sortType === 'clicked' ? 'totalClicked' : sortType;
    const sortAscending = (a: ABVariant, b: ABVariant): number => a[sortBy] - b[sortBy];
    const sortDescending = (a: ABVariant, b: ABVariant): number => b[sortBy] - a[sortBy];
    sortedVariants.sort(direction === SortDirection.ASC ? sortAscending : sortDescending);
    return sortedVariants;
  };

  @action
  public chooseWinner = async (variantId: string): Promise<void> => {
    await chooseWinnerUseCase.exec({
      abTestId: this.id,
      winnerSendDate: this.winnerSendDate.toISOString(),
      // subtract 1 sec to avoid exceeding time limits (72h)
      winnerExpireDate: dayjs(this.winnerExpireDate).subtract(1, 'second').toISOString(),
      variantId
    });
  };

  @action
  public setSelectedVariant = (variant: IABVariant): void => {
    this.selectedVariant = variant;
  };

  @computed
  public get isAudienceTargetted(): boolean {
    return this.hasPolygons || this.hasLabels || this.hasSegment || this.hasIncludedOrExcludedLabels;
  }

  @computed
  public get hasIncludedOrExcludedLabels(): boolean {
    return this.includedLabels.length !== 0 || this.excludedLabels.length !== 0
  }

  @computed
  public get hasLabels(): boolean {
    return this.labels.length > 0;
  }

  @computed
  public get hasPolygons(): boolean {
    return this.polygons.length > 0;
  }

  @computed
  public get hasSegment(): boolean {
    return !!this.segment;
  }

  @computed
  public get showSelectWinner(): boolean {
    return this.state === ABTestState.MANUAL_WINNER;
  }

  @computed
  public get showSortIcons(): boolean {
    return this.variants.length > 2;
  }

  @computed
  public get getIncludedLabels(): Label[] {
    return this.labels.filter(label => label.isIncluded());
  }

  @computed
  public get getExcludedLabels(): Label[] {
    return this.labels.filter(label => label.isExcluded());
  }

  @computed
  public get showCollectingDataInfo(): boolean {
    // check if can show reports and if they are still being collected
    return !this.showReports && ![ABTestState.CANCELED,
      ABTestState.FAILED, ABTestState.FINISHED, ABTestState.PREPARING,
      ABTestState.ACCEPTED, ABTestState.READY_TO_SEND].includes(this.state);
  }

  @computed
  public get showReports(): boolean {
    return this.availableActions.includes(ActionsUseCases.SHOW_REPORT);
  }

  @computed
  public get hasWinnerReports(): boolean {
    return this.availableActions.includes(ActionsUseCases.SHOW_WINNER_REPORT);
  }

  @computed
  public get hasWinner(): boolean {
    //check if can show winner details tab
    return this.availableActions.includes(ActionsUseCases.SHOW_WINNER_DETAILS);
  }

  @computed
  public get getWinner(): IABVariant {
    return this.hasWinner ? this.winner : null;
  }

  @action
  public setWinnerDatesValues = ({ expireTime, isExpireTimeEnabled }: IStartExpireTimeValues): void => {
    this.winnerSendDate = dayjs().toDate();
    const expireTimeInHours = this.getStartExpireTimeValues({ expireTime, isExpireTimeEnabled });
    this.winnerExpireDate = dayjs(this.winnerSendDate).add(expireTimeInHours, 'hour').toDate();
  };

  @action
  public setMininmalWinnerExpireDate(): Date {
    return this.winnerExpireDate = dayjs(this.winnerSendDate).add(ABTestReportStore.MIN_TTL, 'second').toDate();
  }

  @action
  public getStartExpireTimeValues({ expireTime, isExpireTimeEnabled }: IStartExpireTimeValues): number {
    return isExpireTimeEnabled ? expireTime : ABTestReportStore.MAX_TTL / 60 / 60;
  }

  @action
  public getLabelsFromId = async ({ tags, excludedTags }: IGetLabels): Promise<void> => {
    const included = await getResolveIdsToLabels.exec({ ids: tags });

    for (const label of included) {
      await label.resolveLegacyId();
      label.toggleIncluded();
      this.labels.push(label);
    }

    const excluded = await getResolveIdsToLabels.exec({ ids: excludedTags });

    for (const label of excluded) {
      await label.resolveLegacyId();
      label.toggleExcluded();
      this.labels.push(label);
    }
  };

  @computed
  public get isWinnerExpireDateAfterSend(): boolean {
    return dayjs(this.winnerExpireDate).isSameOrAfter(dayjs(this.winnerSendDate).add(ABTestReportStore.MIN_TTL, 'second'));
  }

  @action
  public adjustWinnerExpireDate({ expireTime, isExpireTimeEnabled }: IStartExpireTimeValues): Date {
    if (this.winnerExpireDate && this.winnerSendDate && this.winnerExpireDate.getTime() - this.winnerSendDate.getTime() > 1000 * 3600) {
      return;
    }

    const expireTimeInHours = this.getStartExpireTimeValues({ expireTime, isExpireTimeEnabled });
    this.winnerExpireDate = dayjs(this.winnerSendDate).add(expireTimeInHours, 'hour').toDate();
    return this.winnerExpireDate;
  }

  public getTTLRange({ projectMaxTTL, date, minTTL }: ITTLRange): ITTLRangeResponse {
    let maxTTL = projectMaxTTL;
    const rangeMinTTL = minTTL || ABTestReportStore.MIN_TTL;
    const fromDate = date || new Date();

    return {
      from: dayjs(fromDate).add(rangeMinTTL, 'second').toDate(),
      to: dayjs(fromDate).add(maxTTL, 'second').toDate(),
      maxTTL
    };
  }

  public getTTLRangefromNow(projectMaxTTL: number): ITTLRangeResponse {
    let maxTTL = projectMaxTTL;
    const fromDate = new Date();

    return {
      from: dayjs(fromDate).toDate(),
      to: dayjs(fromDate).add(maxTTL, 'second').toDate(),
      maxTTL
    };
  }

  public setDefaultValues(): void {
    this.setIsLoading(true);
    this.id = '';
    this.name = '';
    this.sample = 0;
    this.state = null;
    this.testStartDate = null;
    this.testEndDate = null;
    this.winnerSendDate = null;
    this.winnerExpireDate = null;
    this.winner = null;
    this.policy = '';
    this.tagsCountStrategy = LabelCountStrategy.AND;
    this.labels = [];
    this.excludedTagsCountStrategy = LabelCountStrategy.AND;
    this.polygons = [];
    this.variants = [];
    this.selectedVariant = this.variants[0];
    this.variantDataType = DataType.CLICKED;
    this.availableActions = [];
    this.segment = null;
  }
}
