import { property, PropertyHandler } from "@ppg/common";
import { IProviderAction } from '../../useCases/core/providers/GetProjectProviderUseCase';
import { computed, observable } from 'mobx';
import { t } from '../../base/helpers';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

interface IIOSProvider {
  production: boolean;
  actions: IProviderAction[];
  passphrase?: string;
  p12?: string;
  notAfter?: Date;
  notBefore?: Date;
  fileName?: string;
}

export class IOSProvider extends PropertyHandler implements IIOSProvider {
  @property() public production;
  @property() public passphrase;
  @property() public p12;
  @property() public notAfter;
  @property() public notBefore;

  @observable public actions;
  @observable public fileName = '';

  constructor({ passphrase = '', production = false, actions = [], p12 = '', notBefore, notAfter }: IIOSProvider) {
    super();
    this.production = production;
    this.passphrase = passphrase;
    this.actions = actions;
    this.p12 = p12;
    this.notBefore = notBefore ? new Date(notBefore) : null;
    this.notAfter = notAfter ? new Date(notAfter) : null;
  }

  static createProvider(IOSProviderDTO?: IIOSProvider): IOSProvider {
    if (IOSProviderDTO) {
      return new IOSProvider(IOSProviderDTO);
    } else {
      return IOSProvider.createDefault();
    }
  }

  static createDefault(): IOSProvider {
    return new IOSProvider({
      passphrase: '',
      production: false,
      actions: [],
      notAfter: null,
      notBefore: null,
      fileName: ''
    });
  }

  @computed
  public get isIntegrated(): boolean {
    return this.actions.includes(IProviderAction.UPDATE_CERTIFICATES) && this.isInDateRange;
  }

  @computed
  public get hasCertDates(): boolean {
    return this.notBefore && this.notAfter;
  }

  @computed
  public get isInDateRange(): boolean {
    if (!this.notBefore || !this.notAfter) {
      return false;
    }

    const wrappedNotAfter = dayjs(this.notAfter);
    const wrappedNotBefore = dayjs(this.notBefore);
    const today = dayjs();
    return today.isSameOrAfter(wrappedNotBefore, 'date') && today.isSameOrBefore(wrappedNotAfter, 'date');
  }

  @computed
  public get willExpireSoon(): boolean {
    return !this.isFirstUpload && this.isCertificateToExpire;
  }

  @computed
  public get daysToCertExpiration(): number {
    if (!this.willExpireSoon) {
      return null;
    }

    return this.expirationDaysNumber;
  }

  @computed
  private get expirationDaysNumber(): number {
    const wrappedNotAfter = dayjs(this.notAfter);
    const today = dayjs();

    return wrappedNotAfter.diff(today, 'day');
  }

  @computed
  public get isCertificateToExpire(): boolean {
    const wrappedNotAfter = dayjs(this.notAfter);
    const today = dayjs();

    if(wrappedNotAfter.isBefore(today, 'date')) {
      return false;
    }

    const daysToExpire = this.expirationDaysNumber;

    return daysToExpire < 30;
  }

  @computed
  public get hasCertificateExpired(): boolean {
    return this.hasCertDates && this.isCertificateExpired;
  }

  @computed
  public get isFirstUpload(): boolean {
    return this.actions.includes(IProviderAction.CREATE);
  }

  @computed
  public get shouldToggleSwitchBeDisabled(): boolean {
    return this.isFirstUpload && !this.hasCertificateUploaded;
  }

  @computed
  public get canIntegrate(): boolean {
    return this.isFirstUpload ? this.p12 : true;
  }

  @computed
  public get hasCertificateUploaded(): boolean {
    return this.p12;
  }

  @computed
  public get isCertificateExpired(): boolean {
    const wrappedNotAfter = dayjs(this.notAfter);
    const today = dayjs();

    return today.isAfter(wrappedNotAfter);
  }

  public onKeyUpload = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = this.getFileAndValidate(event);

    if (!file) return;

    this.p12 = await this.readAsBase64(file);
    this.fileName = file.name;
  };

  private getFileAndValidate = (event: React.ChangeEvent<HTMLInputElement>): File => {
    const file = event.target.files[0];
    if (file.name.indexOf('.p12') === -1) {
      alert(t('Please upload .p12 file'));
      event.target.value = null;
      return null;
    }

    return file;
  };

  private readAsBase64 = async (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      const timer = setTimeout(reject, 2000);

      reader.addEventListener('load', function (event: ProgressEvent<FileReader>) {
        resolve(event.target.result as string);
        window.clearTimeout(timer);
      });

      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString() || '');
      reader.onerror = error => reject(error);
    });
  };
}
