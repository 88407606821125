import { API, UseCase } from "@ppg/common";
import { IUpdateSendPushCampaign, ICampaignIdResponse } from '../interfaces';

interface IUpdateRocketPushRequest extends IUpdateSendPushCampaign {}
interface IUpdateRocketPushResponse extends ICampaignIdResponse {}

export class UpdateRocketPushUseCase extends UseCase<IUpdateRocketPushRequest, IUpdateRocketPushResponse> {
  protected async execute({ campaignId, message, options }: IUpdateRocketPushRequest) {
    return API.HTTP.put<IUpdateRocketPushResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/rocket/${ campaignId }`, { message, options });
  }
}

export const updateRocketPushUseCase = new UpdateRocketPushUseCase();
