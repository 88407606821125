import { Events } from 'backbone';
import { IMap } from '../interfaces';
import { ErrorComponent } from '../components/common';
import { Loader } from '@ppg/styled';
import * as React from 'react';
import * as lodash from 'lodash';
import { Model } from "./Model";

type IInjectable = Function | Object | Model<any, any>;

export enum ConnectorMemberType {
  MODEL = 'model',
  COLLECTION = 'collection',
  FACTORY = 'factory',
}

type DependenciesGetterFunction = () => IConnectorMember[];

interface IConnectorMember {
  type: ConnectorMemberType;
  instance: IInjectable;
}

class Connector {
  private _items: IMap<IConnectorMember> = {};
  private static _instance: Connector = null;
  private _components: React.Component<any, any>[] = [];

  private constructor() {
  }

  public static create(): Connector {
    if (!this._instance) {
      this._instance = new Connector();
    }
    return this._instance;
  }

  public register(key: string, type: ConnectorMemberType, instance: IInjectable): this {
    if (this._items[key]) {
      throw new Error(`Key "${key}" is already registered`);
    }

    this._items[key] = { type, instance };

    return this;
  }

  public model(key: string, instance: IInjectable): this {
    return this.register(key, ConnectorMemberType.MODEL, instance);
  }

  public collection(key: string, instance: IInjectable): this {
    return this.register(key, ConnectorMemberType.COLLECTION, instance);
  }

  public factory(key: string, instance: IInjectable): this {
    return this.register(key, ConnectorMemberType.FACTORY, instance);
  }

  public get<T>(key: string): T {
    return this.getItem(key).instance as T;
  }

  public bindWith(propertyName: string): PropertyDecorator {
    return (target: Object, propertyKey: string) => {
      Object.defineProperty(target, propertyKey, {
        get: () => this.get(propertyName),
      });
    };
  }

  private getMapOfDependencies(...deps: string[]): IMap<IInjectable> {
    return deps.reduce((res: IMap<IInjectable>, key: string) => lodash.extend(res, { [key]: this.get(key) }), {});
  }

  private getItem(key: string): IConnectorMember {
    if (!this._items[key]) {
      throw new Error(`Component "${key}" not found`);
    }
    return this._items[key];
  }

  public inject(...deps: string[]): ClassDecorator {
    const getInjectable = lodash.memoize(() => this.getMapOfDependencies(...deps));
    const dependencies = lodash.memoize<DependenciesGetterFunction>(() => deps.map((dep: string) => this._items[dep]));

    return function(ComponentClass: typeof React.Component): typeof React.Component {

      return class extends React.Component<any, any> {
        private triggerUpdate = () => {
          this.forceUpdate();
        }

        public componentDidMount() {
          lodash.forEach(dependencies(), (item: IConnectorMember) => {
            switch (item.type) {
              case 'model':
                return (item.instance as Events).on('change', this.triggerUpdate, this);
              case 'collection':
                return (item.instance as Events).on('add remove sort', this.triggerUpdate, this);
            }
          });
        }

        public componentWillUnmount() {
          lodash.forEach(dependencies(), (item: IConnectorMember) => {
            switch (item.type) {
              case 'model':
                return (item.instance as Events).off('change', this.triggerUpdate, this);
              case 'collection':
                return (item.instance as Events).off('add remove sort', this.triggerUpdate, this);
            }
          });
        }

        public render() {
          return React.createElement(ComponentClass, {
            ...this.props,
            ...getInjectable()
          } as any);
        }
      } as any;
    } as any;
  }

  public registerComponent(component: React.Component<any, any>): void {
    this._components = lodash
      .chain(this._components.concat(component))
      .compact()
      .uniq()
      .value();
  }

  public unregisterComponent(component: React.Component<any, any>): void {
    this._components = lodash
      .chain(this._components)
      .filter((fComponent: React.Component<any, any>) => fComponent !== component)
      .compact()
      .uniq()
      .value();
  }

  public reset(opts: {silent: boolean}): void {
    lodash.each(lodash.filter(this._items, m => m.type === 'model'), m => {
      lodash.invoke(m.instance, 'reset', [{silent: opts.silent}]);
    });
  }

  public resetForms(opts: {silent: boolean}): void {
    lodash.each(lodash.filter(this._items, m => m.type === 'model'), (m: any) => {
      if (m.instance._shouldResetOnProjectChange) {
        lodash.invoke(m.instance, 'reset', [{silent: opts.silent}]);
      }
    });
  }

  public reload(): void {
    lodash.each(this._components, c => {
      c.setState({ fetching: true, asyncProps: {} }, () => {
        lodash.invoke(c, 'componentDidMount');
      });
    });
  }

  public async<TProps>(fn: (props: TProps) => Promise<void>): ClassDecorator;
  public async<TProps>(promiseMap: IMap<(props: TProps) => Promise<any>>): ClassDecorator;
  public async<TProps>(arg: any = {}): ClassDecorator {
    interface IAsyncComponentState {
      fetching?: boolean;
      err?: any;
      asyncProps?: IMap<any>;
    }

    // todo(shults): remove dummy key
    const __DUMMY__: string = '__DUMMY__';
    const self = this;

    const promiseMap: IMap<(props: TProps) => Promise<any>> = lodash.isFunction(arg) ? { [__DUMMY__]: arg } : arg;

    return function(ComponentClass: React.ComponentClass<{}>) {
      return class extends React.Component<Object, IAsyncComponentState> {
        public readonly state: IAsyncComponentState = {
          fetching: true,
          asyncProps: {},
        };

        public componentDidMount() {
          self.registerComponent(this);
          const asyncProps: IMap<any> = {};

          Promise
            .all(lodash.map(promiseMap, (fn: (props: TProps) => Promise<any>, property: string) => {
              return fn(this.props as TProps).then((res: any) => {
                if (property === __DUMMY__) return;
                asyncProps[property] = res;
              });
            }))
            .then(
              () => this.setState({ fetching: false, asyncProps }),
              err => this.setState({ err, fetching: false })
            );
        }

        public componentWillUnmount() {
          self.unregisterComponent(this);
        }

        public render() {
          const { err, fetching, asyncProps } = this.state;

          if (err) return <ErrorComponent err={ err }/>;

          if (fetching) return <Loader/>;

          try {
            return <ComponentClass { ...this.props } { ...asyncProps } />;
          } catch (err) {
            console.error(err);
            return <ErrorComponent err={ err }/>;
          }
        }

      };
    } as ClassDecorator;
  }
}

export const connector = Connector.create();

