import { computed, observable } from 'mobx';

interface IErrorMessage {
  message: string;
}

export interface IIntegrationValidator {
  id: string;
  messages: IErrorMessage[];
  state: string;
  type: string;
}

export class IntegrationValidators {
  @observable readonly validators: IIntegrationValidator[];

  private constructor(validators: IIntegrationValidator[]) {
    this.validators = validators;
  }

  static createFromValidators(validators: IIntegrationValidator[]): IntegrationValidators {
    return new IntegrationValidators(validators);
  }

  @computed
  get hasNoErrors(): boolean {
    return this.validators && this.validators.every(validator => validator.messages.length === 0) && !this.hasNotProcessedValidator;
  }

  @computed
  get hasNotProcessedValidator(): boolean {
    return this.validators && this.validators.some(checker => checker.state === 'processing' || checker.state === 'initialized');
  }

  @computed
  get errorMessages(): string[] {
    let result = [];

    Array
      .from(this.validators || [])
      .forEach(checker => checker.messages.forEach((message) => {
        if (!result.includes(message)) {
          result.push(message);
        }
      }));

    if (this.hasNotProcessedValidator) {
      result.push('Some validator has not been processed!');
    }

    return result;
  }
}
