import { API, UseCase } from '@ppg/common';
import { ICopyToProjectRequest } from '../interfaces';

export class CopyToProjectsSegmentedPushUseCase extends UseCase<ICopyToProjectRequest, void> {
  protected async execute({ campaignId, projects }: ICopyToProjectRequest) {
    return API.HTTP.post<void>(`core/projects/${ API.HTTP.currentProjectId }/pushes/segmented/${ campaignId }/copy-transfer`, { projects });
  }
}

export const copyToProjectsSegmentedPushUseCase = new CopyToProjectsSegmentedPushUseCase();
