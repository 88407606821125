import dayjs from 'dayjs';
import { ICampaignDay } from '../stores/CampaignDayStore';

export interface IChartItem {
  index: number;
  clicked: number;
  delivered: number;
  date: string;
}

interface ICampaignDayChartHelper {
}

export class CampaignDayChartHelper implements ICampaignDayChartHelper {

  private constructor(private chartData: IChartItem[] = [], private aggregatorFactor: number = 1) {
  }

  public static createCampaignDaysData(data: ICampaignDay[], chartData, aggregateFactor) {
    const chartContainerInstance = new CampaignDayChartHelper(chartData, aggregateFactor);
    for (let campaignDay of data) {
      for (let timeIndex in campaignDay.delivered) {
        if (campaignDay.delivered[timeIndex] !== 0 || campaignDay.clicked[timeIndex] !== 0) {
          chartContainerInstance.add(campaignDay.day, Number(timeIndex), campaignDay.clicked[timeIndex], campaignDay.delivered[timeIndex]);
        }
      }
    }
    return chartContainerInstance.getChartData();
  }

  public static createHourlyCampaignDaysData(data: ICampaignDay[], chartData, aggregateFactor) {
    const chartContainerInstance = new CampaignDayChartHelper(chartData, aggregateFactor);
    return chartContainerInstance.aggregateHourly(data);
  }

  public add(day: Date, index: number, clicked: number, delivered: number) {
    this.chartData.push({
      index: index,
      date: `${day}`,
      delivered: delivered,
      clicked: clicked,
    });
  }

  public getChartData(): IChartItem[] {
    const aggregatedChartData = [];
    let localIndex = -1;

    for (let chartItem of this.chartData) {
      if (chartItem.index % this.aggregatorFactor === 0) {
        localIndex += 1;
      }

      if (!aggregatedChartData[localIndex]) {
        aggregatedChartData[localIndex] = {
          date: dayjs(chartItem.date).startOf('day').add(dayjs().utcOffset() + chartItem.index, 'minutes').format('DD-MM-YY h:mm a'),
          delivered: chartItem.delivered,
          clicked: chartItem.clicked
        };
      } else {
        aggregatedChartData[localIndex].delivered += chartItem.delivered;
        aggregatedChartData[localIndex].clicked += chartItem.clicked;
      }
    }
    return aggregatedChartData;
  }

  private aggregateHourly(documents: any[]) {
    let resultArr = [];
    for (let document of documents) {
      let localIndex = 0;

      for (let i = 0; i < document.clicked.length; i++) {
        if (i % 60 === 0) {
          localIndex++;
        }

        if (!resultArr[localIndex]) {
          resultArr.push({
            clicked: document.clicked[i],
            delivered: document.delivered[i],
            date: `${localIndex}:00`,
          });
        } else {
          resultArr[localIndex].clicked += document.clicked[i];
          resultArr[localIndex].delivered += document.delivered[i];
        }
      }
    }
    return resultArr;
  }

}
