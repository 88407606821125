import * as React from "react";
import { useProperty } from "@ppg/common";
import { LabelPeriodTimeOptions } from "../../stores/project/AutomationLabelsStore";
import { Input, ISelectOption, Select } from "@ppg/styled";
import { t } from "../../base/helpers";
import * as Styled from "./TTLInput.styled";

interface ITTLInput {
  initialTTLValue?: number;
  onChange: (ttl: number) => void;
  disabled: boolean;
}

export class TTLInput extends React.Component<ITTLInput> {

  private expireDateTtl = useProperty(0);
  private expireDatePeriod = useProperty(LabelPeriodTimeOptions.DAYS);

  componentDidMount() {
    const ttl = ~~this.props.initialTTLValue || 0;
    const [result, option] = this.getHighestUnitOptionForTTL(ttl);
    this.expireDateTtl.setValue(result);
    this.expireDatePeriod.setValue(option);
  }

  private onChange(): void {
    this.props.onChange(this.convertLabelTimeToSeconds(this.expireDateTtl.getValue(), this.expireDatePeriod.getValue()));
  }

  private getHighestUnitOptionForTTL(ttlValue: number): [number, string] {

    if (ttlValue % 60 > 0) {
        return [ttlValue, LabelPeriodTimeOptions.SECONDS]
    }

    const patterns: [Function, string][] = [
      [(value: number) => value / (24 * 60 * 60), LabelPeriodTimeOptions.DAYS],
      [(value: number) => value / (60 * 60), LabelPeriodTimeOptions.HOURS],
      [(value: number) => value / 60, LabelPeriodTimeOptions.MINUTES],
      [(value: number) => value, LabelPeriodTimeOptions.SECONDS],
    ]

    for (let [pattern, unit] of patterns) {
      const result = pattern(ttlValue);
      if (Math.floor(result) > 0 && Number.isInteger(result)) {
        return [result, unit];
      }
    }

    return [0, LabelPeriodTimeOptions.DAYS]
  }

  private convertLabelTimeToSeconds(value: number, period: LabelPeriodTimeOptions): number {
    switch (period) {
      case LabelPeriodTimeOptions.SECONDS:
        return Math.floor(value);
      case LabelPeriodTimeOptions.MINUTES:
        return Math.floor(value * 60);
      case LabelPeriodTimeOptions.HOURS:
        return Math.floor(value * 60 * 60);
      case LabelPeriodTimeOptions.DAYS:
        return Math.floor(value * 24 * 60 * 60);
    }
  }

  public get ttlLabelOptions(): ISelectOption[] {
    return [
      {value: "", name: "", disabled: true, hidden: true},
      {value: [LabelPeriodTimeOptions.SECONDS], name: t('seconds')},
      {value: [LabelPeriodTimeOptions.MINUTES], name: t('minutes')},
      {value: [LabelPeriodTimeOptions.HOURS], name: t('hours')},
      {value: [LabelPeriodTimeOptions.DAYS], name: t('days')},
    ];
  }

  render() {
    return (
      <Styled.LabelsFormExpireDate>
        <Input
          type="number"
          min={0}
          disabled={this.props.disabled}
          property={this.expireDateTtl}
          onChange={() => this.onChange()}
        />

        <Select
          disabled={this.props.disabled}
          property={this.expireDatePeriod}
          options={this.ttlLabelOptions}
          onChange={() => this.onChange()}/>

      </Styled.LabelsFormExpireDate>
    );
  }
}