import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { PlanCard } from '../commonMobX/planCard/PlanCard';
import './ChoosePlanModal.scss'
import { offerStore } from '../../stores'
import { t } from '../../base/helpers';
import { Select } from '@ppg/styled';

interface IChoosePlanModalProps {
  onClose(): void;
}


export const ChoosePlanModal: FC<IChoosePlanModalProps> = observer(({ onClose }) => {
  const [isLoading, setLoader] = useState<boolean>(true)
  const { fetchOffer, currencySelect, offer } = offerStore;

  useEffect(() => {
    fetchOffer()
      .then(() => setLoader(false))
  }, [currencySelect])

  if (isLoading) {
    return null;
  }

  return <div className="plans-modal-wrapper">
    <div className="plans-modal-header">
      <div className={ 'plans-modal-header-content' }>
        <span className="plans-modal-title">{ t("Choose your plan") }</span>
        <div className={ 'header-content-select' }>
          <span> { t('Currency') }</span>
          <Select property={ offerStore.getProperty('currencySelect') } options={ offerStore.planCurrencyOptions }/>
        </div>
      </div>
      <div className="close-modal-button" onClick={ () => onClose() }>
        <span className="icon-cancel-badge size35"/>
      </div>
    </div>

    <div className="cards-wrapper">
      { offer.plans.map(plan => <PlanCard plan={ plan } onClose={ () => onClose() } key={ plan.id }/>) }
    </div>

    <div className="plans-modal-footer m30t text-center size16">
      { t('Want to learn more about plans?') } <br/>
      <a target="_blank" href="https://pushpushgo.com/pl/pages/cennik/lite">
        { (t('Click here to see more details.')) }
      </a>
    </div>
  </div>
})

