export interface ISecondaryColors {
  yellow100: string,
  yellow200: string,
  yellow300: string,
  yellow400: string,
  orange100: string,
  orange200: string,
  orange300: string,
  violet100: string,
  violet200: string,
  violet300: string,
  red: string,
  blue: string,
  turquoise: string,
  grassGreen: string,
}

export const secondary: ISecondaryColors = {
  yellow100: '#ebf680',
  yellow200: '#efcf51',
  yellow300: '#efb76b',
  yellow400: '#dcb443',
  orange100: '#fdb46d',
  orange200: '#eb6b56',
  orange300: '#f16f43',
  violet100: '#9B91E8',
  violet200: '#956dca',
  violet300: '#462446',
  red: '#e8575e',
  blue: '#61A5E0',
  turquoise: '#4dc5c5',
  grassGreen: '#88d085',
};
