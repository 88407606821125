import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { UserStore } from '../../stores/user/UserStore';
import './PPGLite.scss'

interface IPPGLiteWrapperProps {
  userStore?: UserStore;
  children?: React.ReactNode;
  role: PPGLiteRole
}

export enum PPGLiteRole {
  HIDE ,
  DISPLAY,
  WRAP
}

@inject('userStore')
@observer
export class PPGLite extends React.Component<IPPGLiteWrapperProps, {}> {
  public render() {
    const { children, userStore, role } = this.props;
    return <>
      { userStore.isLite ? <>
        { role ===  PPGLiteRole.DISPLAY && children}
        { role ===  PPGLiteRole.HIDE && null }
        { role ===  PPGLiteRole.WRAP && <div className="PPGLite-wrapper">{children}</div>}
      </> : <>
        { role ===  PPGLiteRole.DISPLAY && null}
        { role ===  PPGLiteRole.HIDE && children}
        { role ===  PPGLiteRole.WRAP && children}
      </>
      }
    </>;
  }
}

