import { API, UseCase } from "@ppg/common";
import { IGetAppleDataResponse, IProvider } from '../interfaces';

interface ICreateIOSIntegration extends IProvider {
  p12: string;
  passphrase: string;
  production: boolean;
}

export class CreateIOSIntegrationUseCase extends UseCase<ICreateIOSIntegration, IGetAppleDataResponse> {
  protected async execute({ projectId, organizationId, passphrase, production, p12 }: ICreateIOSIntegration) {
    return API.HTTP.post<IGetAppleDataResponse>(`core/organizations/${ organizationId }/projects/${ projectId }/providers/ios`, {
      passphrase, production, p12
    });
  }
}

export const createIOSIntegrationUseCase = new CreateIOSIntegrationUseCase();
