import * as React from 'react';
import { t } from '../../../../base/helpers';
import { LANGUAGES } from '@ppg/common';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { UserStore } from '../../../../stores/user/UserStore';
import { connector } from '../../../../base';
import { WebUser } from '../../../../models';

interface ISettingsLanguageProps {
  userStore?: UserStore;
}

@inject('userStore')
@observer
export class SettingsLanguage extends React.Component<ISettingsLanguageProps> {

  private async selectLanguage(lang: string) {
    this.props.userStore.lang = lang;

    await this.props.userStore.changeUserLanguage();

    // @todo
    const user = connector.get<WebUser>('user');
    user.lang = lang;
    await user.setLang(lang);
  }

  public render() {
    const { userStore } = this.props;

    return (
      <>
        <h4 className="form-title">{ t('Language version') }</h4>
        <div className={'languages-options-wrapper'}>
        {
          Object.entries(LANGUAGES).map(([code, lang]) => {
            const isActive = code === userStore.lang;

            const className = classNames('m5r', 'button', {
              'button-primary': isActive,
              'button-outline': !isActive
            });

            return <button
              onClick={ () => this.selectLanguage(code) }
              disabled={ isActive }
              key={ code }
              className={ className }>{ lang }</button>;
          })
        }
        </div>
      </>
    );
  }
}
