import { API, UseCase } from '@ppg/common';

interface IGetOptionsResult {
  languages: string[];
  tags: string[];
}

interface IGetOptions {
  organization: string;
}

export class GetAvailableOptionsUseCase extends UseCase<IGetOptions, IGetOptionsResult> {
  protected async execute({ organization }) {
    return API.HTTP.get<IGetOptionsResult>(`notification/organizations/${ organization }/channels/options`);
  }
}

export const getAvailableOptionsUseCase = new GetAvailableOptionsUseCase();
