import { isEmail, isNotEmpty, property, PropertyHandler } from '@ppg/common';
import { action } from 'mobx';
import dayjs from 'dayjs';
import { t } from '../base/helpers';

export enum AuthorizationType {
  STANDARD = 'STANDARD',
  OAUTH = 'OAUTH'
}

export class User extends PropertyHandler {
  @property()
  public id: string = '';

  @property([isEmail()])
  public username: string = '';

  @property()
  public firstName: string = '';

  @property()
  public lastName: string = '';

  @property()
  public phone: string = '';

  @property()
  public authorizationStrategy: AuthorizationType = null;

  @property()
  public createdAt: Date;

  @property([isNotEmpty()])
  public organization: string;

  @property([isNotEmpty()])
  public organizationType: string;

  @property([isNotEmpty()])
  public blocked: boolean;

  @property()
  public isPrivacyPolicyCheck: boolean = false;

  @property()
  public isTermsOfAgreementCheck: boolean = false;

  @property()
  public privacyPolicyCheckDate: string = "";

  @property()
  public termsOfAgreementCheckDate: string = "";

  @action
  public parseUser(data: object) {
    Object
      .keys(data)
      .forEach(item => {
        if (this.hasOwnProperty(item)) {
          this[item] = data[item] ?? '';
        }
      });
  }

  @action
  public setPrivacyCheckDate(): void {
    this.privacyPolicyCheckDate = this.isPrivacyPolicyCheck ? dayjs().format() : "";
  }

  @action
  public setTermsOfAgreementCheckDate(): void {
    this.termsOfAgreementCheckDate = this.isTermsOfAgreementCheck ? dayjs().format() : "";
  }

  public validateAgreement(lite: boolean): string {
    if (!this.isPrivacyPolicyCheck) {
      return t('register::In order to proceed with the registration process, you have to accept our Privacy Policy');
    } else if (!this.isTermsOfAgreementCheck && lite) {
      return t('register::In order to proceed with the registration process, you have to agree to the terms of the Agreement on the provision of electronic services and of the Data Processing Agreement.');
    }
  }
}
