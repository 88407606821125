import { API, UseCase } from "@ppg/common";
import { ISendPushCampaign, ICampaignIdResponse } from '../interfaces';

interface ISendGeolocationPushRequest extends ISendPushCampaign {}
interface ISendGeolocationPushResponse extends ICampaignIdResponse {}

export class SendGeolocationPushUseCase extends UseCase<ISendGeolocationPushRequest, ISendGeolocationPushResponse> {
  protected async execute({ message, options }: ISendGeolocationPushRequest) {
    return API.HTTP.post<ISendGeolocationPushResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/geolocation`, { message, options });
  }
}

export const sendGeolocationPushUseCase = new SendGeolocationPushUseCase();
