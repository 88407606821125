import { UseCase, API } from '@ppg/common';
import { IFabButtonResult, IGetPlugin } from '../interfaces';

export class GetFabButtonPluginUseCase extends UseCase<IGetPlugin, IFabButtonResult> {
  protected async execute({ websiteIntegrationId }: IGetPlugin) {
    return API.HTTP.get<IFabButtonResult>(`wi/projects/${ API.HTTP.currentProjectId }/website-integrations/${ websiteIntegrationId }/plugins/fab-button`);
  }
}

export const getFabButtonPluginUseCase = new GetFabButtonPluginUseCase();
