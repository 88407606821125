import { ProjectRelated } from '../ProjectRelatedStore';
import { property } from '@ppg/common';
import { action } from 'mobx';
import { CampaignType } from '../../modelsMobx/campaigns/PushCampaign';
import { getCampaignStatisticsSummary } from '../../useCases/core/push/GetCampaignStatisticsSummary';
import {
  cancelGeolocationPushUseCase,
  cancelLabelPushUseCase,
  cancelRocketPushUseCase, cancelSegmentedPushUseCase,
  getGeolocationPushUseCase,
  getLabelPushUseCase,
  getRocketPushUseCase,
  getSegmentedPushUseCase
} from '../../useCases/core/campaigns';
import { IPushCampaignReportData } from '../../pages/Reports/Push/PushCampaignReport/Interfaces';
import {
  IGetLabelsPushCampaign,
  IGetPolygonsPushCampaign,
  IGetRocketPushCampaign,
  IGetSegmentsPushCampaign
} from '../../useCases/core/campaigns/interfaces';
import { getCampaignDaysForCampaign } from "../../useCases/core/push/GetCampaignDaysForCampaign";
import { CampaignDayChartHelper } from "../../helpers/CampaignDayChartHelper";
import { redirect, t } from '../../base/helpers';
import { ProjectRoutesTypes } from '../../routes/moduleProjectRoutes';
import { toast } from '@ppg/styled';

interface IPushCampaignReportStore {
  isLoading: boolean;
}

interface IPushCampaignReport {
  actionPrimary: number;
  actionSecondary: number;
  cappedDelivered: number;
  cappedSent: number;
  clicked: number;
  ctr: number;
  delivered: number;
  lost: number;
  sent: number;
}

/**
 * Class responsible for "responsible" fetching data with limits
 * Logic is separated from "view". View have a self implemented monitor for fetching
 * Here is limiting logic
 */
class FetchCampaignReportDataLimiter {

  static REFRESH_STATES = ['ready', 'inqueue', 'sending'];

  private lastHistogramFetch: Date | null = null;
  private campaignState: string | null = null;

  private campaignData: any = {};
  private histogramData: any = {};
  private statisticsData: any = {};
  private fetchUntilDate: Date;

  constructor(
    private fetchUntilMs: number,
    private fetchHistogramAfterMs: number,
    private fetchCampaignFn: Function,
    private fetchStatisticsFn: Function,
    private fetchHistogramFn: Function,
    private parseDTO: Function,
  ) {
    this.fetchUntilDate = new Date(Date.now() + fetchUntilMs);
  }

  /**
   * Fetch campaign data only when state is not final
   */
  private canIFetchCampaignData() {
    if (!this.campaignState) {
      return true;
    }
    return FetchCampaignReportDataLimiter.REFRESH_STATES.includes(this.campaignState);
  }

  /**
   * Fetch report data only to final date from constructor (4hours?) and only when campaign is fetched
   */
  private canIFetchReportData() {
    return !this.canIFetchCampaignData() && this.fetchUntilDate > new Date();
  }

  /**
   * Fetch every some seconds defined in constructor (rarer then campaign data (heavy data)) and when reports can be still fetched
   */
  private canIFetchHistogramData() {
    if (!this.lastHistogramFetch) {
      return true;
    }

    const diffSeconds = (Date.now() - this.lastHistogramFetch.getTime());
    return this.canIFetchReportData() && diffSeconds >= this.fetchHistogramAfterMs;
  }

  private async fetchCampaignData() {
    if (this.canIFetchCampaignData()) {
      this.campaignData = await this.fetchCampaignFn();
      this.campaignState = this.campaignData.state;

      if (this.campaignData.message.sentDate) {
        this.fetchUntilDate = new Date(new Date(this.campaignData.message.sentDate).getTime() + this.fetchUntilMs);
      }
    }
  }

  private async fetchStatisticsData() {
    if (this.canIFetchReportData()) {
      this.statisticsData = await this.fetchStatisticsFn();
    }
  }

  private async fetchHistogramData() {
    if (this.canIFetchHistogramData()) {
      this.lastHistogramFetch = new Date();
      this.histogramData = await this.fetchHistogramFn();
    }
  }

  async fetchData() {
    await this.fetchCampaignData();
    await this.fetchStatisticsData();
    await this.fetchHistogramData();

    return this.parseDTO(this.campaignData, this.statisticsData, this.histogramData);
  }
}

export class PushCampaignReportStore extends ProjectRelated implements IPushCampaignReportStore {
  @property() public isLoading = true;

  @action
  public setIsLoading(value: boolean): void {
    this.isLoading = value;
  }

  public setDefaultValues(): void {
    this.isLoading = true;
  }

  public fetchSummaryStatsData(campaignId: string): Promise<IPushCampaignReport> {
    return getCampaignStatisticsSummary.exec({
      projectID: this.projectId,
      campaignID: campaignId
    });
  }

  public createFetcher(campaignId: string, campaignType: string): () => Promise<IPushCampaignReportData> {
    const instance = new FetchCampaignReportDataLimiter(
      3600_000,
      5 * 60_000,
      () => this.getCampaignByIdAndType(campaignId, campaignType),
      () => this.fetchSummaryStatsData(campaignId),
      () => this.fetchHistogramStatsData(campaignId),
      (campaignData, statisticsData, histogramData) => this.parseReportData(campaignData, statisticsData, histogramData)
    );
    return () => instance.fetchData();
  }

  /**
   * TODO: PREPARE ENDPOINT WITH AGGREGATION FACTOR - THIS IS STUPID TO SEND THIS DA OVER NETWORK!
   */
  public async fetchHistogramStatsData(campaignId: string): Promise<any[]> {

    const { stats } = await getCampaignDaysForCampaign.exec({
      projectID: this.projectId,
      campaign: campaignId,
    });

    const parsedData = CampaignDayChartHelper.createCampaignDaysData(stats.map(s => ({
      day: new Date(s.day),
      delivered: s.delivered,
      clicked: s.clicked,
    })), [], 1);

    return parsedData.map(item => ({
      date: item.date,
      index: item.index,
      clicked: item.clicked,
      received: item.delivered
    }));
  }

  public parseReportData(campaignData: IGetRocketPushCampaign & IGetSegmentsPushCampaign & IGetLabelsPushCampaign & IGetPolygonsPushCampaign, statsData: IPushCampaignReport, histogramData: any[]): IPushCampaignReportData {
    return {
      state: campaignData.state,
      direction: campaignData.message.direction,
      content: campaignData.message.content,
      icon: campaignData.message.icon,
      title: campaignData.message.title,
      actions: campaignData.message.actions,
      image: campaignData.message.image,
      redirectLink: campaignData.message.redirectLink,
      requireInteraction: campaignData.options.requireInteraction,
      sent: statsData.sent,
      received: statsData.delivered,
      clicked: statsData.clicked,
      clicked_1: statsData.actionPrimary,
      clicked_2: statsData.actionSecondary,
      waiting: statsData.sent - statsData.delivered,
      ctr: statsData.ctr,
      cappedSent: statsData.cappedSent,
      cappedReceived: statsData.cappedDelivered,
      sendDate: campaignData.message.sendDate,
      expireDate: campaignData.message.expireDate,
      sentBy: campaignData.metadata.sentBy,
      fromApi: campaignData.metadata.fromAPI,
      histogram: histogramData,
      omitCapping: campaignData.options.omitCapping,
      segment: campaignData.options.segment,
      includedLabels: campaignData.options.labels,
      excludedLabels: campaignData.options.excludedLabels,
      polygons: campaignData.options.polygons
    };
  }

  public async getCampaignByIdAndType(campaignId: string, campaignType: string): Promise<any> {
    let campaign;

    switch (campaignType) {
      case CampaignType.ROCKET:
        campaign = await getRocketPushUseCase.exec({ campaignId });
        break;
      case CampaignType.SEGMENTATION:
        campaign = await getSegmentedPushUseCase.exec({ campaignId });
        break;
      case CampaignType.TEMPORARY_LABELS:
        campaign = await getLabelPushUseCase.exec({ campaignId });
        break;
      case CampaignType.GEOLOCATION:
        campaign = await getGeolocationPushUseCase.exec({ campaignId });
        break;
    }

    return campaign;
  }

  public async handleCancelCampaign(campaignId, campaignType): Promise<void> {
    switch (campaignType) {
      case CampaignType.ROCKET:
        await cancelRocketPushUseCase.exec({ campaignId });
        break;
      case CampaignType.SEGMENTATION:
        await cancelSegmentedPushUseCase.exec({ campaignId });
        break;
      case CampaignType.TEMPORARY_LABELS:
        await cancelLabelPushUseCase.exec({ campaignId });
        break;
      case CampaignType.GEOLOCATION:
        await cancelGeolocationPushUseCase.exec({ campaignId });
        break;
    }

    redirect(`${ ProjectRoutesTypes.CAMPAIGN_LIST_PUSHES }/stopped`);
    toast.success(t('Your campaign has been stopped successfully'));
  }
}
