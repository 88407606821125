import * as React from 'react';
import { Pagination, IPagination } from '@ppg/styled';
import { t } from '../../base/helpers';


export const WrappedPagination = (props: IPagination) => {
  return <Pagination
    content={ {
      loadButton: t('Load more'),
      perPage: t('per page'),
      page: t('Go to page'),
      of: t('of'),
      btnContent: t('Go >'),
      loadedDescription: t('Loaded'),
      loadedFrom: t('from'),
      loadedItems: t('items'),
    } }
    { ...props }
  />
};
