import * as lodash from 'lodash';
import * as React from 'react';
import { t } from '../../base/helpers';
import { AbstractFiled } from './AbstractFiled';
import { IRadioButtonProps } from './IRadioButtonProps';
import { Help } from '@ppg/styled';

export class RadioButton extends AbstractFiled<IRadioButtonProps, {}> {

  private onChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.props.attribute.setValue(event.currentTarget.value);
    lodash.invoke(this.props, 'onChange', event);
  }

  public render() {
    const { attribute, options, disabled } = this.props;
    return (
      <div className="row">
        { lodash.map(options, (option) =>
          <div className="column" key={ `${this.inputId}_${option.name}` }>
            <label>
              <span className="radio">
                <input
                  type="radio"
                  checked={ option.value === attribute.getValue() }
                  name={ this.inputId }
                  value={ option.value }
                  disabled={ disabled || false }
                  onChange={ this.onChange }/>
                <span/>
              </span>
              <span className="m5l">{ this.props.t ? t(option.name) : option.name }</span>
              { option.helperContent && <Help content={ option.helperContent } /> }
            </label>
          </div>
        ) }
      </div>
    );
  }
}