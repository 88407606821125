import { property, PropertyHandler } from '@ppg/common';
import { observable } from 'mobx';
import { lsSettings } from '../../base';
import { lsPropertyKeys } from '../../base/lsSettings';

export interface ISubscriberHeaderDTO {
  value: string,
  name: string,
  enabled: boolean,
}

export class SubscriberHeader extends PropertyHandler {
  @observable
  public value: string;

  @observable
  public name: string;

  @property()
  public enabled: boolean;

  constructor(header: ISubscriberHeaderDTO) {
    super();
    this.value = header.value;
    this.name = header.name;
    this.enabled = this.getHeaderStatus(header);
  }

  private getHeaderStatus(header: ISubscriberHeaderDTO):boolean {
    const localHeaders:string[] = lsSettings.getValue(lsPropertyKeys.HEADERS);

    if(!localHeaders){
      return header.enabled;
    }

    return localHeaders.some(header => {
      return this.value.toLowerCase() === header.toLowerCase()
    });
  }
}
