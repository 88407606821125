import { API, UseCase } from '@ppg/common';
import { ISendPushCampaign, ICampaignIdResponse } from '../interfaces';

interface ICreateGeolocationPushDraftRequest extends ISendPushCampaign {}
interface ICreateGeolocationPushDraftResponse extends ICampaignIdResponse {}

export class CreateGeolocationPushDraftUseCase extends UseCase<ICreateGeolocationPushDraftRequest, ICreateGeolocationPushDraftResponse> {
  protected async execute({ message, options }: ICreateGeolocationPushDraftRequest) {
    return API.HTTP.post<ICreateGeolocationPushDraftResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/geolocation/draft`, { message, options });
  }
}

export const createGeolocationPushDraftUseCase = new CreateGeolocationPushDraftUseCase();
