import { API, UseCase } from '@ppg/common';

interface DataPayload {
  shareProjectID: string;
  organization: string
}

export class RevokeSharedProjectUseCase extends UseCase<DataPayload, void> {
  protected async execute({ organization, shareProjectID }) {
    await API.HTTP.delete(`aai/organizations/${organization}/shared-projects/${ shareProjectID }`);
  }
}

export const revokeSharedProjectUseCase = new RevokeSharedProjectUseCase();
