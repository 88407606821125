import { API, UseCase } from "@ppg/common";

interface IGetPushSettingCommand {
  project: string;
}

interface IGetPushSettingDTO {
  project: string;
  pushActions: IPushActionDTO[];
  expireTime: number;
  isExpireTimeEnabled: boolean;
  senderStrategy: string;
  requireInteraction: boolean;
  isDirectionRtl: boolean;
  maxTTL: number;
  defaultTitle: string;
  isDefaultTitleEnabled: boolean;
}

interface IPushActionDTO {
  title: string;
  link: string;
  useRedirectLink: boolean;
}

export class GetPushSettingUseCase extends UseCase<IGetPushSettingCommand, IGetPushSettingDTO> {
  protected async execute({ project }: IGetPushSettingCommand) {
    return API.HTTP.get<IGetPushSettingDTO>(`core/projects/${ project }/settings`);
  }
}

export const getPushSettingUseCase = new GetPushSettingUseCase();
