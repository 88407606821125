import * as React from 'react';
import { INunjucksScope, Nunjucks } from "../../services/Nunjucks";

interface IRenderPreviewProps {
  scope: INunjucksScope;
  fallback: string;
  silent?: boolean;
}

export class Render extends React.Component<IRenderPreviewProps, {}> {
  public render() {
    return <span>{ Nunjucks.render(
      `${this.props.children || this.props.fallback}`,
      this.props.scope, this.props.silent
    ) }</span>;
  }
}
