import { property, PropertyHandler } from '@ppg/common';

export interface IPaymentCard {
  id: string;
  brand: string;
  expireMonth: number;
  expireYear: number;
  number: string;
  recurring: boolean;
}

export class PaymentCard extends PropertyHandler implements IPaymentCard {
  @property() public id: string;
  @property() public brand: string;
  @property() public expireMonth: number;
  @property() public expireYear: number;
  @property() public number: string;
  @property() public recurring: boolean;

  constructor(card) {
    super();
    this.id = card.id;
    this.brand = card.brand;
    this.expireMonth = card.expireMonth;
    this.expireYear = card.expireYear;
    this.number = card.number;
    this.recurring = card.recurring;
  }

  static createCard(card: IPaymentCard) {
    return new PaymentCard({
      id: card.id,
      brand: card.brand,
      expireMonth: card.expireMonth,
      expireYear: card.expireYear,
      number: card.number,
      recurring: card.recurring,
    })
  }
}
