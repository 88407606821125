import * as React from 'react';
import { CheckoutPage } from './CheckoutPage';
import { Link } from 'react-router-dom';
import { t } from '../../../base/helpers';
import PPGLogo from '../../../assets/images/ppg-logo.svg';
import { PaymentsRoutesTypes } from '../../../routes/paymentsRoutes';

export class PaymentsCheckout extends React.Component<{}, {}> {
  public render() {
    return <div className='container'>
      <div className='page-background'>
        <div className="go-to-app">
          <span className="icon-arrow-left"/>
          <Link to={PaymentsRoutesTypes.INVOICES_LIST} className='go-to-app-button'>{ t('Return to application') }</Link>
        </div>

        <div className='checkout-page-wrapper'>
          <img src={ PPGLogo } alt="PushPushGo" className='ppg-logo'/>
          <div className="form-wrapper">
            <CheckoutPage/>
          </div>
        </div>
      </div>
    </div>;
  }
}

