import { property } from '@ppg/common';
import { t } from '../base/helpers';
import dayjs from 'dayjs';
import { DAY } from '../constants';

export type ProcessingExportStateType = 'initialized' | 'processing' | 'uploading';
export type FinishedExportStateType = 'ready' | 'failed';
export type ExportStateType = ProcessingExportStateType & FinishedExportStateType;

export type ExportFileState = 'ready' | 'fail' | 'process' | 'init';
export type ExportFileType = 'logo' | 'icon' | 'avatar' | 'screenshot' | 'popup' | 'image';

type ExportByType = 'createdAt' | 'sendDate';

export enum ExportCollectionType {
  CAMPAIGN = 'campaign',
  SUBSCRIBER = 'subscriber',
  SUBSCRIBER_SEGMENTATION = 'subscriberSegment',
  CAMPAIGN_DAYS = 'campaignDays',
}

/**
 * For translations
 * t('file_ready');
 * t('file_fail');
 * t('file_process');
 * t('file_init');
 */

export class ExportFile {
  @property() public id?: number;
  @property() public state: ExportStateType;
  @property() public collection: ExportCollectionType;
  @property() public from: Date;
  @property() public to: Date;
  @property() public exportBy: ExportByType;
  @property() public createdAt: Date;

  constructor(file) {
    this.id = file.id;
    this.state = file.state as ExportStateType;
    this.from = dayjs(file.from).toDate();
    this.to = dayjs(file.to).toDate();
    this.exportBy = file.rangeReference;
    this.collection = file.type;
    this.createdAt = file.createdAt;
  }

  get needsMonitor() {
    return ['initialized', 'processing', 'uploading'].indexOf(this.state) > -1;
  }

  setState(state: ExportStateType) {
    this.state = state;
  }

  /**
   * @deprecated
   */
  static collectionOptions() {
    return [
      { value: 'subscriber', name: t('export/list::Subscribers') },
      { value: 'campaign', name: t('export/list::Campaigns') },
      { value: 'campaignDays', name: t('export/list::Campaign Activity') },
    ];
  }

  static collectionOptionsSegmentation() {
    return [
      { value: 'subscriberSegment', name: t('export/list::Subscribers') },
      { value: 'campaign', name: t('export/list::Campaigns') },
      { value: 'campaignDays', name: t('export/list::Campaign Activity') },
    ];
  }
}
