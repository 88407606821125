import { API, UseCase } from "@ppg/common";
import { PlatformType } from '../../interfaces';

interface IGetCampaignBulkStatsUseCaseRequest {
  project: string;
  campaigns: string[];
  platform?: PlatformType;
}

interface IGetCampaignBulkStatsUseCaseResult {
   sentCampaigns: number;
   allDelivered: number;
   meanCtr: number;
   bestCampaigns: CampaignSummaryDTO[];
}

interface CampaignSummaryDTO {
  campaignId: string;
  sent: number;
  clicked: number;
  delivered: number;
  actionPrimary: number;
  actionSecondary: number ;
  lost: number;
  ctr: number;
}

export class GetCampaignBulkStatsUseCase extends UseCase<IGetCampaignBulkStatsUseCaseRequest,IGetCampaignBulkStatsUseCaseResult> {
  protected async execute({ platform, campaigns, project }: IGetCampaignBulkStatsUseCaseRequest) {
    return API.HTTP.post<IGetCampaignBulkStatsUseCaseResult>(`statistics/projects/${project}/pushes/dashboard/${platform ? platform : ''}`,
      campaigns
    );
  }
}
