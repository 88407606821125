import { property, PropertyHandler } from '@ppg/common';
import { SUBSCRIBER_HISTORY_ITEMS_LIMIT } from '../../constants';
import { action } from 'mobx';

export class SubscriberDetailsStore extends PropertyHandler {

  @property()
  public customFieldLimit: number = SUBSCRIBER_HISTORY_ITEMS_LIMIT;

  @property()
  public selectorsLimit: number = SUBSCRIBER_HISTORY_ITEMS_LIMIT;

  @property()
  public customFieldTotal: number = 0;

  @property()
  public selectorsTotal: number = 0;

  @property()
  public customFieldOffset: number = 0;

  @property()
  public selectorsOffset: number = 0;

  constructor(private readonly subscribersStore) {
    super();
  }

  setDefaultValues():void {
    this.clearCustomFieldsPagination();
    this.clearSelectorsPagination();
  }

  @action
  public setCustomFieldTotalNumber = (length: number):void => {
    this.customFieldTotal = length;
  }

  @action
  public setSelectorsTotalNumber = (length: number):void => {
    this.selectorsTotal = length;
  }

  @action
  public clearCustomFieldsPagination = ():void => {
    this.customFieldLimit = SUBSCRIBER_HISTORY_ITEMS_LIMIT;
    this.customFieldOffset = 0;
    this.customFieldTotal = 0;
  }

  @action
  public clearSelectorsPagination = ():void => {
    this.selectorsLimit = SUBSCRIBER_HISTORY_ITEMS_LIMIT;
    this.selectorsOffset = 0;
    this.selectorsTotal = 0;
  }

}
