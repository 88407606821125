import * as React from 'react';
import { action, observable, reaction } from 'mobx';
import { SubscriptionFormStore } from './SubscriptionFormStore';
import { ISubscriptionFormStoreCreator } from './SubscriptionFormInterfaces';
import { FormType } from './SubscriptionFormEnums';
import { FormCSSBuilder, SubscriptionFormBuilder } from './helpers';
import { WebsiteIntegrationRelated } from '../project/WebsiteIntegrationRelated';
import { getSubscriptionForms } from '../../useCases/website-integration';
import { getSubscriptionFormPluginUseCase, updateSubscriptionFormUseCase } from '../../useCases/website-integration';

export class SubscriptionFormStoreCreator extends WebsiteIntegrationRelated implements ISubscriptionFormStoreCreator {

  @observable public currentView: string;
  @observable public formType: FormType;
  @observable public formStore: SubscriptionFormStore;
  @observable public subscriptionFormTemplates;

  setDefaultValues() {
    this.currentView = 'description';
    this.formType = FormType.DEFAULT;
    this.formStore = null;
  }

  public async fetchSubscriptionForms() {
    return await getSubscriptionForms.exec({ websiteIntegrationId: this.websiteIntegrationId })
  }

  @action
  public async getCurrentSubscriptionForm() {
    return await getSubscriptionFormPluginUseCase.exec({ websiteIntegrationId: this.websiteIntegrationId })
  }

  @action
  public changeFormType(formType: FormType) {
    if (!this.subscriptionFormTemplates) {
      return;
    }

    this.formType = formType;
    this.formStore = new SubscriptionFormStore(this.subscriptionFormTemplates[formType], this.formType);
  }

  @action
  public toggleCurrentView(viewName: string) {
    this.currentView = this.currentView === viewName ? '' : viewName;
  }

  @action
  public revertDefaultTemplate(formBuilder: SubscriptionFormBuilder) {
    this.subscriptionFormTemplates[this.formType] = formBuilder.getTypeTemplate(this.formType);
    this.formStore = new SubscriptionFormStore(this.subscriptionFormTemplates[this.formType], this.formType);
  }

  @action
  public async publishSubscriptionForm(): Promise<any> {
    const formCSSBuilder = new FormCSSBuilder(this.formStore.toFinalTemplate());

    await updateSubscriptionFormUseCase.exec({
      websiteIntegrationId: this.websiteIntegrationId,
      styles: await formCSSBuilder.processStyles(),
      template: JSON.stringify(this.formStore.toFinalTemplate()),
      type: this.formType,
    })
  }

  public async initializeSubscriptionForm() {
    this.setDefaultValues();
    this.changeFormType(FormType.DEFAULT);
    await this.publishSubscriptionForm();
  }
}
