import { t } from '../../base/helpers';
import { action, computed, observable, reaction } from 'mobx';
import { property, PropertyHandler, sortArrayByBoolean } from '@ppg/common';
import { ISubscriberHeaderDTO, SubscriberHeader } from '../../modelsMobx/subscriber/SubscriberHeaderNew';
import { lsSettings } from '../../base';
import { lsPropertyKeys } from '../../base/lsSettings';

export class SubscriberFilterStoreNew extends PropertyHandler {

  @observable
  public headers: SubscriberHeader[] = [];

  @observable
  public labels: string[] = [];

  @property()
  public searchHeader: string = '';

  @observable
  public isHeadersLoading: boolean = true;

  @observable
  public isFilterBySegmentsEnabled: boolean = false;

  @observable
  public isFilterByCustomIdEnabled: boolean = false;

  @observable
  public isColumnsManagerEnabled: boolean = false;

  @property()
  public customIdSearch: string = '';

  private reactOnFiltersChange = reaction(() => this.filtersChangeFactors, () => this.subscribersStore.debouncedSubscribersFetch());

  constructor(private readonly subscribersStore) {
    super();
  }

  @computed
  public get filtersChangeFactors() {
    return {
      customId: this.customIdSearch
    };
  }

  setDefaultValues() {
    this.isColumnsManagerEnabled = false;
    this.isFilterByCustomIdEnabled = false;
    this.isFilterBySegmentsEnabled = false;
  }

  @action
  public toggleFilterBySegment(): void {
    this.isFilterBySegmentsEnabled = !this.isFilterBySegmentsEnabled;
    this.isFilterByCustomIdEnabled = false;
    this.customIdSearch = "";
  }

  @action
  public toggleFilterByCustomId(): void {
    this.isFilterByCustomIdEnabled = !this.isFilterByCustomIdEnabled;
    this.isFilterBySegmentsEnabled = false;
  }

  @action
  public toggleColumnsManager(): void {
    this.isColumnsManagerEnabled = !this.isColumnsManagerEnabled;
  }

  @action
  public listHeaders = (): SubscriberHeader[] => {
    return sortArrayByBoolean(this.headers, 'enabled').filter(header => {
      return header.name.toLowerCase().includes(this.searchHeader.toLowerCase());
    });
  };

  public get defaultHeaders(): ISubscriberHeaderDTO[] {
    return [
      { value: HeadersType.STATUS, name: t('subscribers::Status'), enabled: true },
      { value: HeadersType.TYPE, name: t('subscribers::Type'), enabled: true },
      { value: HeadersType.PROVIDER, name: t('subscribers::Provider'), enabled: true },
      { value: HeadersType.CUSTOM_ID, name: t('subscribers::Custom ID'), enabled: true },
      { value: HeadersType.CREATED_AT, name: t('subscribers::Created At'), enabled: false },
      { value: HeadersType.LABELS, name: t('subscribers::Labels'), enabled: true },
    ];
  }

  public get systemLabelsKeys(): ISubscriberHeaderDTO[] {
    return this.subscribersStore.labelsSysKeys.map(key => {
      return { value: key, name: key, enabled: false };
    });
  }

  @action
  public setHeaders = async (): Promise<void> => {
    let defaultHeaders = [...this.defaultHeaders];
    const labelHeader = defaultHeaders.pop();

    const allHeaders = [...defaultHeaders, ...this.systemLabelsKeys, labelHeader];

    this.headers = allHeaders.map(header => {
      return new SubscriberHeader(header);
    });

    this.isHeadersLoading = false;
  };

  public isHeaderEnabled = (value): boolean => {
    return this.headers.some(header => {
      return header.value === value && header.enabled;
    });
  };

  @computed
  public get enabledHeadersValues(): string[] {
    return this.headers
      .map((header) => header.enabled && header['value'])
      .filter(value => value ?? null);
  }

  public setHeadersToLocalStorage = () => {
    lsSettings.setValue(lsPropertyKeys.SUBSCRIBERS_LIST_HEADERS, this.enabledHeadersValues);
  };
}

export enum HeadersType {
  STATUS = 'status',
  PROVIDER = 'provider',
  TYPE = 'type',
  CUSTOM_ID = 'customId',
  LABELS = 'labels',
  CREATED_AT = 'createdAt',
}

