export const DEFAULT_LOCALIZATION_MAP_PROPERTIES = {
  type: 'scattergeo',
  showHoverLimit: 500,
  marker: {
    bucket: {
      xs: 100,
      s: 1000,
      m: 10000,
      l: 100000,
      xl: 500000,
      xxl: Infinity,
    },
    minSize: 2,
    color: {
      xs: '#73c6b5',
      s: '#51b8a3',
      m: '#16a085',
      l: '#10897d',
      xl: '#0b7372',
      xl_line: '#085c5b',
      xxl: '#043f4c',
      xxl_line: '#033844',
    },
    line: {
      width: 1,
    },
  },
  margin: {
    autoexpand: true,
    t: 10,
    r: 0,
    b: 0,
    l: 0,
  },
  geo: {
    showcountries: true,
    coastlinecolor: 'rgb(202, 202, 202)',
    coastlinewidth: 1,
    framecolor: '#fff',
    showsubunits: true,
    showland: true,
    landcolor: 'rgb(217, 217, 217)',
    subunitwidth: 0.5,
    countrywidth: 0.5,
    subunitcolor: 'rgb(255,255,255)',
    countrycolor: 'rgb(255,255,255)',
    center: {
      lat: 51.9194,
      lon: 19.1451,
    },
    projection: {
      type: 'cylindrical stereographic',
      scale: 4,
    }
  },
  width: '',
  modeBar: {
    remove: ['lasso', 'select'],
  },
  showLegend: true,
  legend: {
    orientation: 'h',
    y: 1,
  }
}
