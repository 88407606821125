interface IBreakpoints {
  mobileS: string,
  mobileM: string,
  mobileL: string,
  tabletS: string,
  tabletM: string,
  tabletL: string,
  laptopS: string,
  laptopM: string,
  laptopL: string,
  desktop: string
}

const size: IBreakpoints = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tabletS: '575px',
  tabletM: '625px',
  tabletL: '768px',
  laptopS: '1024px',
  laptopM: '1220px',
  laptopL: '1440px',
  desktop: '2560px'
};

export const minWidthMedia: IBreakpoints = {
  mobileS: `(min-width: ${ size.mobileS })`,
  mobileM: `(min-width: ${ size.mobileM })`,
  mobileL: `(min-width: ${ size.mobileL })`,
  tabletS: `(min-width: ${ size.tabletS })`,
  tabletM: `(min-width: ${ size.tabletM })`,
  tabletL: `(min-width: ${ size.tabletL })`,
  laptopS: `(min-width: ${ size.laptopS })`,
  laptopM: `(min-width: ${ size.laptopM })`,
  laptopL: `(min-width: ${ size.laptopL })`,
  desktop: `(min-width: ${ size.desktop })`,
};

export const maxWidthMedia: IBreakpoints = {
  mobileS: `(max-width: ${ size.mobileS })`,
  mobileM: `(max-width: ${ size.mobileM })`,
  mobileL: `(max-width: ${ size.mobileL })`,
  tabletS: `(max-width: ${ size.tabletS })`,
  tabletM: `(max-width: ${ size.tabletM })`,
  tabletL: `(max-width: ${ size.tabletL })`,
  laptopS: `(max-width: ${ size.laptopS })`,
  laptopM: `(max-width: ${ size.laptopM })`,
  laptopL: `(max-width: ${ size.laptopL })`,
  desktop: `(max-width: ${ size.desktop })`,
};

