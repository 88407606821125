export interface IBrandColors {
  green: string,
  blue: string,
  blueGray: string,
}

export const brand: IBrandColors = {
  green: '#16a085',
  blue: '#343646',
  blueGray: '#424454',
};
