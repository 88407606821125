import { isNotEmpty, isUrlWithProtocol, property, ValueValidator } from '@ppg/common';
import { Project } from '../../models';
import { action, computed, observable } from 'mobx';
import { ProjectRelated } from '../../stores/ProjectRelatedStore';
import { apiManager } from '../../base';
import { projectIntegrationTypes } from '../project/IProject';
import { getSectorsList } from '../../useCases/cs-and-sales/GetSectorsList';
import { ISelectOption } from '@ppg/styled';
import { t } from '../../base/helpers';

export class ProjectCreator extends ProjectRelated {
  @property([isNotEmpty()])
  public name: string = '';

  @property([isNotEmpty(), isUrlWithProtocol()])
  public siteUrl: string = 'https://your-domain.com';

  @property([isNotEmpty()])
  public sector: string = '';

  @property()
  lang: string = 'en';

  @observable
  public sectors: string[] = [];

  setDefaultValues() {
    this.name = '';
    this.siteUrl = 'https://your-domain.com';
    this.sector = '';
    this.lang = 'en';
  }

  async saveProject() {
    const { data } = await apiManager.post('/project', this.toProjectDTO());
    return new Project(data);
  }

  @computed
  get canSave() {
    return this.name !== ''
      && this.getProperty('siteUrl').isValid
      && this.getProperty('sector').isValid;
  }

  public get siteHasHttps() {
    return ValueValidator.isUrlWithHttps(this.siteUrl);
  }

  public toProjectDTO() {
    return {
      name: this.name,
      siteUrl: this.siteUrl,
      lang: this.lang,
      sector: this.sector,
      strategy: projectIntegrationTypes.SIMPLE,
    };
  }

  @action
  public getSectors = async (): Promise<void> => {
    const { originalSectors } = await getSectorsList.exec();
    this.sectors = originalSectors;
  };

  @computed
  public get getSectorsSelectOptions(): ISelectOption[] {
    const fetchOptions = this.sectors.map((sector) => {
      return {
        name: t(`project/new::${ sector }`),
        value: sector
      };
    });

    return [{ name: "", value: "", disabled: true, hidden: true }, ...fetchOptions];
  }

  get slug() {
    return this.siteUrl
      .replace(/[^A-Za-z0-9]/g, '-') // replace non char/number by dashes
      .replace(/-+/g, '-') // split dashes
      .replace(/^-+|-+$/g, '') + '.pushpushgo.com'; // trim dashes
  }
}

/**Translations
 * t('project/new::FINANCE')
 * t('project/new::TELECOM')
 * t('project/new::SERVICES_ONLINE')
 * t('project/new::TRAVEL')
 * t('project/new::E_COMMERCE')
 * t('project/new::OTHER')
 * t('project/new::PUBLISHER')
 */
