import * as React from 'react';
import { t } from '../../../../base/helpers';
import { inject, observer } from 'mobx-react';
import { PaymentDataStore } from '../../../../stores/payment/PaymentDataStore';
import { PageSection } from '../../Layouts/UserLayout/PageSection';
import { CountryStore } from '../../../../stores/CountryStore';
import { Input, Select } from '@ppg/styled';
import { observable } from 'mobx';
import './InvoiceDetails.scss'
import { SaveButtonWrapped } from '../../../../assets/wrapped';

interface IPaymentDataProps {
  paymentDataStore?: PaymentDataStore;
  countryStore?: CountryStore;
  hideSave?: boolean;
}

@inject('paymentDataStore', 'countryStore')
@observer
export class InvoiceDetailsForms extends React.Component<IPaymentDataProps, {}> {

  @observable private isLoading: boolean = true;

  public async componentDidMount() {
    Promise.all([
      this.props.paymentDataStore.fetchPaymentData(),
      this.props.countryStore.fetchCountries(),
    ]).finally(() => {
      this.isLoading = false;
    });
  }

  public render() {
    const { paymentData } = this.props.paymentDataStore;

    if (this.isLoading) {
      return null;
    }

    return <>
      <PageSection>
        <div className="company-info-wrapper">
            <Input
              property={ paymentData.getProperty('name') }
              type="text"
              dataCy={'invoce-details-input--company'}
              label={ t('Company name') + ' *' }
            />
            <Input
              property={ paymentData.getProperty('taxNumber') }
              type="text"
              dataCy={'invoce-details-input--tax'}
              label={ `${ t('Tax') } *` }
            />
        </div>
      </PageSection>

      <PageSection>
        <div className="company-location-wrapper">
            <Input
              property={ paymentData.getProperty('street') }
              type="text"
              dataCy={'invoce-details-input--street'}
              label={ t('Street') + ' *' }
            />
            <Input
              property={ paymentData.getProperty('house') }
              type="text"
              dataCy={'invoce-details-input--house'}
              label={ t('House') }
            />
            <Input
              property={ paymentData.getProperty('flat') }
              type="text"
              dataCy={'invoce-details-input--flat'}
              label={ t('Flat') }
            />
            <Input
              property={ paymentData.getProperty('zip') }
              type="text"
              dataCy={'invoce-details-input--zip'}
              label={ t('Postal Code') + ' *' }
            />
        </div>

        <div className="row m10b">
          <div className="column">
            <Select
              property={ paymentData.getProperty('country') }
              label={ `${ t('Country') } *` }
              options={ this.props.countryStore.getCountriesListAsVATOptions() }
            />
          </div>

          <div className="column">
            <Input
              property={ paymentData.getProperty('city') }
              type="text"
              dataCy={'invoce-details-input--city'}
              label={ t('City') + ' *' }
            />
          </div>
        </div>

        {this.props.hideSave ? null : <div className='company-info--save-button'>
          <SaveButtonWrapped dataCy={'invoce-details-button--save'}
                             callback={ () => this.props.paymentDataStore.savePaymentData()} />
        </div>}
      </PageSection>
    </>
  }
}

