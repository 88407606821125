import { API, UseCase } from '@ppg/common';
import { IUpdateStrategy } from '../../interfaces';

export class UpdateStrategyUseCase extends UseCase<IUpdateStrategy, void> {
  protected async execute({ websiteIntegrationId, strategy }: IUpdateStrategy) {
    await API.HTTP.put(`wi/projects/${ API.HTTP.currentProjectId }/website-integrations/${ websiteIntegrationId }/plugins/web-push/strategy`, { strategy })
  }
}

export const updateStrategyUseCase = new UpdateStrategyUseCase();
