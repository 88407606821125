import * as React from "react";
import { observer } from 'mobx-react';
import "./CampaignFilters.scss";
import { Property } from "@ppg/common";
import { CheckBox } from '@ppg/styled';

interface ICampaignFilters {
  summary: ISingleFilter;
  target: ISingleFilter;
  geo: ISingleFilter;
  mass: ISingleFilter;
}

interface ISingleFilter {
  property: Property;
  label: string;
}

export const CampaignFilters = observer(({ geo, target, mass, summary }: ICampaignFilters): JSX.Element => {
  return <div className={ 'well campaigns-filters' }>
    <CheckBox property={ summary.property } label={ summary.label }/>
    <CheckBox property={ target.property } label={ target.label }/>
    <CheckBox property={ geo.property } label={ geo.label }/>
    <CheckBox property={ mass.property } label={ mass.label }/>
  </div>
})