import * as React from 'react';
import { observer } from 'mobx-react';
import * as Styled from './Validators.styled';
import { t } from '../../../base/helpers';

interface IWarningList {
  label: string;
  value: string;
  max: number;
}

export const WarningList = observer(({ label, value, max }: IWarningList): JSX.Element => {
  if (value.length > max) {
    return  <Styled.WarningMessage>{ t(`Safari users will see only %{length} characters from field %{fieldName}`, { fieldName: label, length: max }) }</Styled.WarningMessage>;
  }
  return null;
});