import { SubscriptionFormBuilder, defaultStylesMap, defaultRtlStylesMap, svgDefaultProperties, path1, path2, path3 } from './index';
import { ElementIdType } from '../../SubscriptionFormEnums';
import { SubscriptionFormLayer } from './index';
import { poweredProperties } from './defaultFormStyles';

export class DefaultFormBuilder extends SubscriptionFormBuilder {

  public createLayer(type: string, id: string) {
    const stylesMap = this.formProperties.isDirectionRtl ? defaultRtlStylesMap : defaultStylesMap;
    return new SubscriptionFormLayer(type, id, this.formProperties.color, stylesMap);
  }

  public getTemplate = () => [
    this.createLayer('div', ElementIdType.FORM)
      .withChild(
        this.createLayer('div', ElementIdType.CONTENT)
          .withChild(
            this.createLayer('div', ElementIdType.ICON)
              .withChild(
                this.createLayer('svg', ElementIdType.SVG)
                  .withProperties(svgDefaultProperties)
                  .withChild(
                    this.createLayer('title', ElementIdType.SVG)
                      .withText('bell-native')
                  )
                  .withChild(
                    this.createLayer('path', ElementIdType.SVG)
                      .withProperties(path1)
                  )
                  .withChild(
                    this.createLayer('path', ElementIdType.SVG)
                      .withProperties(path2)
                  )
                  .withChild(
                    this.createLayer('path', ElementIdType.SVG)
                      .withProperties(path3)
                  )
              )
          )
          .withChild(
            this.createLayer('span', ElementIdType.HEADER)
              .withText(this.header)
          )
          .withChild(
            this.createLayer('div', ElementIdType.DESCRIPTION)
              .withText(this.description)
          )
          .withChild(
            this.createLayer('div', ElementIdType.BUTTONS)
              .withChild(
                this.createLayer('a', ElementIdType.DENY)
                  .withText(this.formProperties.denyText)
              )
              .withChild(
                this.createLayer('a', ElementIdType.ALLOW)
                  .withText(this.formProperties.allowText)
              )
          )
      )
      .withChild(
        this.createLayer('div', ElementIdType.POWERED_BY)
          .withChild(
            this.createLayer('a', ElementIdType.POWERED_BY_TEXT)
              .withText('Powered by PushPushGo')
              .withProperties(poweredProperties)
          )
      )
  ]
}
