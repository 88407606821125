import { ClipboardStore, localProperty, property, PropertyHandler } from '@ppg/common';
import { action, computed, observable, reaction } from 'mobx';
import { apiManager, connector } from '../../base';
import { Project, WebUser } from '../../models';
import { ProjectCreator } from '../../modelsMobx/helpers/ProjectCreator';
import { SimpleProject } from '../../modelsMobx/project/SimpleProject';
import { getOwnedProjectIdsUseCase } from '../../useCases/auth-and-identity/GetOwnedProjectIdsUseCase';
import { CampaignDayStore } from '../CampaignDayStore';
import { ExportStore } from '../ExportStore';
import { userStore } from '../index';
import { PushSettingStore } from '../PushSettingStore';
import { AppSettingStore } from '../AppSettingStore';
import { DashboardStore } from './DashboardStore';
import { FeatureStore } from './FeatureStore';
import { GeolocationStore } from './GeolocationStore';
import { SelectorStore } from './SelectorStore';
import { WebhookStore } from './WebhooksStore';
import { PhotoLibraryStore } from './PhotoLibraryStore';
import { WebsiteIntegrationStore } from './WebsiteIntegrationStore';
import { PushCampaignStore } from './PushCampaignStore';
import { ProjectImageStore } from './ProjectImageStore';
import { AutomationListStore } from './AutomationListStore';
import { ABTestListStore } from './ABTestListStore';
import { ABTestReportStore } from './ABTestReportStore';
import { SubscribersStore } from './SubscribersStore';
import { CreateABTestStore } from './CreateABTestStore';
import { NotificationPreviewStore } from './NotificationPreviewStore';
import { AutomationProjectDataStore } from "./AutomationProjectDataStore";
import { AutomationLabelsStore } from "./AutomationLabelsStore";
import { AutomationUpdateStepStore } from "../AutomationUpdateStepStore";
import { SegmentsStore } from './SegmentsStore';
import { SubscribersPageStore } from "./SubscribersPageStore";
import { CreatePushStore } from './CreatePushStore';
import { ProviderStore } from './ProviderStore';
import { SegmentsListStore } from './SegmentsListStore';
import { PushCampaignReportStore } from './PushCampaignReportStore';
import { toast } from '@ppg/styled';
import { t } from '../../base/helpers';

export class ProjectStore extends PropertyHandler {
  @property() public projects: SimpleProject[] = [];
  @property() public projectCreator: ProjectCreator;
  @observable private userOwnedProjectIds: string[] = [];
  @property() public projectToDelete: SimpleProject = null;
  @property() public currentProject: Project = null;

  @localProperty() public currentProjectID: string;

  private reactOnProjectsChange = reaction(() => this.projects.length, () => this.fetchOwnedProjects());

  public dashboardStore: DashboardStore;
  public webhookStore: WebhookStore;
  public campaignDayStore: CampaignDayStore;
  public selectorStore: SelectorStore;
  public automationUpdateStepStore: AutomationUpdateStepStore;
  public featureStore: FeatureStore;
  public pushSettingStore: PushSettingStore;
  public appSettingStore: AppSettingStore;
  public clipboardStore: ClipboardStore;
  public geolocationStore: GeolocationStore;
  public exportStore: ExportStore;
  public photoLibraryStore: PhotoLibraryStore;
  public pushCampaignStore: PushCampaignStore;
  public websiteIntegrationStore: WebsiteIntegrationStore;
  public projectImageStore: ProjectImageStore;
  public automationListStore: AutomationListStore;
  public abTestListStore: ABTestListStore;
  public abTestReportStore: ABTestReportStore;
  public createABTestStore: CreateABTestStore;
  public subscribersStore: SubscribersStore;
  public notificationPreviewStore: NotificationPreviewStore;
  public automationProjectDataStore: AutomationProjectDataStore;
  public automationLabelsStore: AutomationLabelsStore;
  public segmentsStore: SegmentsStore;
  public subscribersPageStore: SubscribersPageStore;
  public createPushStore: CreatePushStore;
  public providerStore: ProviderStore;
  public segmentsListStore: SegmentsListStore;
  public pushCampaignReportStore: PushCampaignReportStore;

  constructor() {
    super();
    this.pushSettingStore = new PushSettingStore(this);
    this.appSettingStore = new AppSettingStore(this);
    this.projectCreator = new ProjectCreator(this);
    this.dashboardStore = new DashboardStore(this);
    this.webhookStore = new WebhookStore(this);
    this.campaignDayStore = new CampaignDayStore(this);
    this.selectorStore = new SelectorStore(this);
    this.featureStore = new FeatureStore(this);
    this.clipboardStore = new ClipboardStore(this, 'currentProject');
    this.geolocationStore = new GeolocationStore(this);
    this.exportStore = new ExportStore(this);
    this.photoLibraryStore = new PhotoLibraryStore(this);
    this.websiteIntegrationStore = new WebsiteIntegrationStore(this);
    this.pushCampaignStore = new PushCampaignStore(this);
    this.projectImageStore = new ProjectImageStore(this);
    this.automationListStore = new AutomationListStore(this);
    this.abTestListStore = new ABTestListStore(this);
    this.abTestReportStore = new ABTestReportStore(this);
    this.createABTestStore = new CreateABTestStore(this);
    this.notificationPreviewStore = new NotificationPreviewStore(this);
    this.segmentsStore = new SegmentsStore(this);
    this.createPushStore = new CreatePushStore(this);
    this.providerStore = new ProviderStore(this);
    this.segmentsListStore = new SegmentsListStore(this);
    this.pushCampaignReportStore = new PushCampaignReportStore(this);

    /**
     * Automation, Selectors common used data
     */
    this.automationProjectDataStore = new AutomationProjectDataStore(this);
    this.automationLabelsStore = new AutomationLabelsStore(this, this.automationProjectDataStore);
    this.automationUpdateStepStore = new AutomationUpdateStepStore(this, this.automationProjectDataStore);

    this.subscribersStore = new SubscribersStore(this, this.automationProjectDataStore);
    this.subscribersPageStore = new SubscribersPageStore(this, this.automationProjectDataStore);
  }

  @action
  public async fetchProjects(): Promise<void> {
    const { data } = await apiManager.get<SimpleProject[]>('project?limit=1000');

    this.projects = data.map(project => new SimpleProject(project));
    const currentProject = this.getLocalCurrentProject();

    if (currentProject) {
      await this.setProject(currentProject.id);
      await this.websiteIntegrationStore.getWebsiteIntegrationId();
    } else if (this.projects.length > 0) {
      await this.setProject(this.projects[0].id);
      await this.websiteIntegrationStore.getWebsiteIntegrationId();
    } else {
      // @todo
      this.cleanCurrentProject();
    }
  }

  @computed
  public get userOwnedProjects(): SimpleProject[] {
    return this.projects.filter(p => this.userOwnedProjectIds.includes(p.id));
  }

  @computed
  get sharedProjects(): SimpleProject[] {
    return this.projects.filter(p => !this.userOwnedProjectIds.includes(p.id));
  }

  @computed
  public get organizationId(): string {
    return userStore.user.organization;
  }

  @action
  public async setProject(projectID: string): Promise<void> {
    this.projectImageStore.images = []; // todo

    const { data } = await apiManager.get<any>(`project/${ projectID }`);
    const project = await this.createProjectFromDto(data);

    this.currentProject = project;
    this.currentProjectID = this.currentProject?.id as string;

    // legacy models support (tags, campaign, automation)
    const user = connector.get<WebUser>('user');
    user.selectProject(project);

    await this.featureStore.fetchFeatureSwitches();
    await this.projectImageStore.fetchImages();
  }

  private async createProjectFromDto(data): Promise<Project> {
    const project = new Project(data);

    if (data.data && data.data.hasOwnProperty('swPath')) {
      project.swPath = data.data.swPath;
    }

    return project;
  }

  @action
  public cleanCurrentProject(): void {
    this.currentProjectID = null;
    this.currentProject = null;
    this.websiteIntegrationStore.websiteIntegrationId = null;
    const user = connector.get<WebUser>('user');
    user.currentProject = null;
  }

  @action
  public async saveNewProject(): Promise<void> {
    const project: Project = await this.projectCreator.saveProject();
    await this.setProject(project.id as string);
    await this.websiteIntegrationStore.getWebsiteIntegrationId();
    await this.websiteIntegrationStore.subscriptionFormCreator.initializeSubscriptionForm();

    this.getProperty('projects').setValue(this.projects.concat(new SimpleProject(project)));
  }

  @action
  public async deleteProject(projectId: string): Promise<void> {
    await apiManager.del<void>(`project/${ projectId }`)
      .then(() => toast.success(t('Your project has been deleted successfully')));

    this.projects = this.projects.filter(project => project.id !== projectId);
    if (this.currentProject.id === projectId) {
      if (this.projects.length === 0) {
        this.cleanCurrentProject();
        return;
      }
      await this.setProject(this.projects[0].id);
      await this.websiteIntegrationStore.getWebsiteIntegrationId();
    }
  }

  @action
  public async fetchOwnedProjects(): Promise<void> {
    // sometimes has wrong userId
    if (!userStore.user.id) {
      return;
    }

    const { projects } = await getOwnedProjectIdsUseCase.exec({ userID: userStore.user.id });
    this.userOwnedProjectIds = projects;
  }

  @computed
  public get hasProjects(): boolean {
    return this.projects.length > 0;
  }

  @computed
  public get hasOwnedProjects(): boolean {
    return this.userOwnedProjects.length > 0;
  }

  private getLocalCurrentProject(): SimpleProject {
    if (!this.currentProjectID) {
      return;
    }

    return this.projects.find(project => project.id === this.currentProjectID);
  }

  public getProjectNameByID(id: string): string {
    const project = this.projects.find((project) => project.id === id);

    return project?.name ?? id;
  }

  @action
  public setProjectToDelete(project: SimpleProject): void {
    this.projectToDelete = project;
  }

  @action
  public clearProjectToDelete(): void {
    this.projectToDelete = null;
  }

}
