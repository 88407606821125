import { Collection } from 'backbone';
import { AutomationFlow } from './index';
import { WebUser } from '../WebUser';
import { connector } from '../../base';
import * as lodash from 'lodash';

// @ts-ignore
export class AutomationCollection extends Collection<AutomationFlow> {
  private _fetchItems: Promise<void>;
  private _currentProject: string;
  public model = AutomationFlow;

  @connector.bindWith('user') private user: WebUser;

  public comparator = (model: AutomationFlow) => model.get('priority');

  public wasProjectChanged(): boolean {
    return this.user.currentProject !== this._currentProject;
  }

  public debounceSync = lodash.debounce(() => this.each(model => lodash.invoke(model, 'save')), 1000);

  public reorder(oldIndex: number, newIndex: number): void {
    const toAppend = this.at(oldIndex);
    if (!toAppend) return;

    this.remove(toAppend.id, { silent: true });
    this.add(toAppend, { at: newIndex });

    this.each((model, index) => {
      model.set('priority', index, { silent: true });
    });

    this.sort();
    this.debounceSync();
  }

  public fetchItems(): Promise<void> {
    if (!this._fetchItems || this.wasProjectChanged()) {
      this._currentProject = this.user.currentProject;
      if (!this._currentProject) {
        return Promise.resolve() as any;
      }

      this._fetchItems = AutomationFlow
        .find()
        .all()
        .then((automations: AutomationFlow[]) => {
          this.reset();

          lodash.each(automations, (automation, index) => {
            const priority = automation.get('priority');
            automation.set('priority', lodash.isNumber(priority) ? priority : index);
            this.add(automation);
          });
        });
    }
    return this._fetchItems;
  }
}
