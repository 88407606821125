import { API, UseCase } from '@ppg/common';

interface AssignTagsToSubscriberRequest  {
  subscriber: string;
  tags: string[];
}

export class AssignTagsToSubscriberUseCase extends UseCase<AssignTagsToSubscriberRequest, void> {
  protected async execute({ subscriber, tags }: AssignTagsToSubscriberRequest) {
    return API.HTTP.post<void>(`core/projects/${ API.HTTP.currentProjectId }/subscribers/${subscriber}/tags`, { tags });
  }
}

export const assignTagsToSubscriberUseCase = new AssignTagsToSubscriberUseCase();
