import { API, UseCase } from '@ppg/common';

interface ISuspendCampaign {
  projectID: string;
  push: string;
}

export class SuspendCampaignUseCase extends UseCase<ISuspendCampaign, void> {
  protected async execute({ projectID, push }) {
    return API.HTTP.post<void>(`core/projects/${ projectID }/pushes/${ push }/suspend`)
  }
}

export const suspendCampaignUseCase = new SuspendCampaignUseCase();
