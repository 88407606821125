import { Model } from '../base';
import { property } from '../base/decorators';
import { IUser, IUserResource } from './IUser';

export class User extends Model<IUser, IUserResource> {
  @property() public timezone: number;
  @property() public acceptAdditionalTerms: boolean;
  @property() public messages: string[];
  @property() public account: any;
  @property() public notificationPreferences: any;
  @property() public paymentSubscription: any;
  @property() public isPasswordExpired: boolean;
  @property() public lastLogin: Date;
  @property() public onboardingComplete: boolean;

  public static resourceUrl(): string {
    return 'user';
  }

  public defaults(): IUser {
    return {
      messages: []
    } as IUser;
  }
}
