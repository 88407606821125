import { API, UseCase } from '@ppg/common';
import { ICampaignId, ICampaignIdResponse } from '../interfaces';

interface ICancelSegmentedPushUseCaseRequest extends ICampaignId {}
interface ICancelSegmentedPushUseCaseResponse extends ICampaignIdResponse {}

export class CancelSegmentedPushUseCase extends UseCase<ICancelSegmentedPushUseCaseRequest, ICancelSegmentedPushUseCaseResponse> {
  protected async execute({ campaignId }: ICancelSegmentedPushUseCaseRequest) {
    return API.HTTP.post<ICancelSegmentedPushUseCaseResponse>(`core/projects/${API.HTTP.currentProjectId}/pushes/segmented/${campaignId}/cancel`);
  }
}

export const cancelSegmentedPushUseCase = new CancelSegmentedPushUseCase();
