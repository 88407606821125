class GeneratorID {
  private static characters = 'qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM_';
  private static numbers = '1234567890';

  private static generate(characters: string) {
    return characters[Math.floor(Math.random() * characters.length)];
  }

  private static generateFirstChar() {
    return GeneratorID.generate(GeneratorID.characters);
  }

  private static generateChar() {
    return GeneratorID.generate(GeneratorID.characters + GeneratorID.numbers);
  }

  public static generateID(length: number = 6) {
    const id = new Array(length).fill(GeneratorID.generateChar);
    id[0] = GeneratorID.generateFirstChar;

    return id.map(char => char()).join('');
  }
}

export default GeneratorID;
