import { isArrayEmpty, omit, Property, property } from "@ppg/common";
import { action, computed, observable, reaction } from 'mobx';
import { IExternalLoaders } from '../../../pages/Dashboard/common/ChartCard/__types__';
import { BarDatum } from '@nivo/bar';
import { campaignTypeColors } from '../../../components/Charts/LineChart/defaultValues';
import {
  IAssignBarChartItem,
  IAssignLineChartItem,
  IBarChartUpdateItem,
  IClickedDeliveredUpdateItem,
  IExtendedLineChartUpdateItem
} from '../../../pages/Dashboard/common/interfaces';
import { t } from '../../../base/helpers';
import { ProjectDashboardRelated } from './ProjectDashboardRelated';
import { IBarDatumKeysColors, IHistogramData, IProjectDashboardCampaignStore } from './interfaces';
import { DashboardCampaignType } from '../../../pages/Dashboard/common/enums';
import {
  getProjectCampaignAverageCtrUseCase,
  getProjectCampaignStatsHistogramUseCase,
  getProjectCampaignStatsUseCase,
  getProjectClickedAndDeliveredUseCase,
  getProjectSendHoursUseCase
} from "../../../useCases/statistics/project";
import {
  ICampaignClickedDeliveredHourStats,
  ICampaignHistogramItem,
  ICampaignSentHourStats,
  IDashboardCampaignsStatsWithCount,
} from '../../../useCases/interfaces';
import dayjs from 'dayjs';
import { campaignsTabs, dashboardFiltersCampaignTypes } from '../../../pages/Dashboard/common/helpers';

export class ProjectDashboardCampaignStore extends ProjectDashboardRelated implements IProjectDashboardCampaignStore {
  public static ITEMS_PER_PAGE: number = 20;
  private mapColor: Record<string, string> = {
    [DashboardCampaignType.SUMMARY]: campaignTypeColors[0],
    [DashboardCampaignType.TARGETED]: campaignTypeColors[1],
    [DashboardCampaignType.GEOLOCATION]: campaignTypeColors[2],
    [DashboardCampaignType.MASS]: campaignTypeColors[3],
    [DashboardCampaignType.TRIGGERED]: campaignTypeColors[4],
  };

  @property()
  public isSummaryCampaignsEnabled = true;

  @property()
  public isTargetingCampaignsEnabled = false;

  @property()
  public isGeolocationCampaignsEnabled = false;

  @property()
  public isMassCampaignsEnabled = false;

  // Stats

  @observable
  public summaryCampaigns = this.defaultProjectCampaignStats;

  @observable
  public targetingCampaigns = this.defaultProjectCampaignStats;

  @observable
  public geolocationCampaigns = this.defaultProjectCampaignStats;

  @observable
  public massCampaigns = this.defaultProjectCampaignStats;

  @observable
  public isLoading = true;

  @observable
  public initialLoader = false;

  // Charts

  @observable
  public sentChartData = [];

  @property()
  public clickedChartData = [];

  @observable
  public deliveredChartData = [];

  @observable
  public sentCampaignsChartData = [];

  @observable
  public ctrChartData = [];

  @observable
  public averageCtrChartData = [];

  @observable
  public dspChartData = [];

  @observable
  public averageDspChartData = [];

  @observable
  public shippingHoursChartData = [];


  @observable
  public isClickedDeliveredLoading = false;

  @observable
  public isClickedDeliveredInitialLoading = true;

  @observable
  public clickedHoursChartData = [];

  @observable
  public deliveredHoursChartData = [];

  // Tables

  @property()
  public offset = 0;

  @property()
  public limit = ProjectDashboardCampaignStore.ITEMS_PER_PAGE;

  @property()
  public total = 0;

  @observable
  public statisticsHistogramsTableData = [];

  @observable
  public clickedDeliveredHoursTableData = [];

  @observable
  public shippingHoursTableData = [];

  reactOnRangeChange = reaction(
    () => this.rangeChangeFactors,
    () => this.clearOffset(),
  );

  reactOnTabChange = reaction(
    () => this.activeTab,
    () => this.clearOffset(),
  );

  @computed
  public get showStatsTable(): boolean {
    const MIN_TABLE_OPTIONS_NUMBER = 1;

    const options = [
      this.isSummaryCampaignsEnabled,
      this.isGeolocationCampaignsEnabled,
      this.isMassCampaignsEnabled,
      this.isTargetingCampaignsEnabled
    ];

    const enabledOptions = options.filter(filterValue => {
      return filterValue === true;
    });

    return enabledOptions.length > MIN_TABLE_OPTIONS_NUMBER;
  }

  public getProjectAverageCtr = async (campaignType: string): Promise<number> => {
    const { from, to, platform } = this.projectRequestBody;
    const { averageCtr } = await getProjectCampaignAverageCtrUseCase.exec({
      from, to, campaignType,
      platformType: platform
    });

    return averageCtr;
  };

  @computed
  public get clickedDeliveredLoaders(): IExternalLoaders {
    return {
      isLoading: this.isClickedDeliveredLoading,
      isInitialLoader: this.isClickedDeliveredInitialLoading,
    };
  }

  @computed
  public get activeCampaignStatsData(): IDashboardCampaignsStatsWithCount {
    if (this.isSummaryCampaignsEnabled) {
      return this.summaryCampaigns;
    }

    if (this.isGeolocationCampaignsEnabled) {
      return this.geolocationCampaigns;
    }

    if (this.isMassCampaignsEnabled) {
      return this.massCampaigns;
    }

    if (this.isTargetingCampaignsEnabled) {
      return this.targetingCampaigns;
    }

    return this.defaultProjectCampaignStats;
  }

  @action
  public fetchCampaignsStats = async (): Promise<void> => {
    this.isClickedDeliveredInitialLoading = false;
    this.isClickedDeliveredLoading = true;

    if (!this.isPlatformSelected) {
      this.clearCampaignsStats();
      this.clearLoaders();
      return;
    }

    await Promise.all([
      this.fetchSummaryCampaigns(),
      this.fetchTargetingCampaigns(),
      this.fetchMassCampaigns(),
      this.fetchGeolocationCampaigns()
    ]);

    this.isClickedDeliveredLoading = false;
    this.isClickedDeliveredInitialLoading = true;
    this.clearLoaders();
  };

  /**
   * The way new data is added to a chart differs by the structure of the data that the various chart types (ex. line, bar, pie) receive so
   * this method is responsible for removing data from chart based on the currently active view type (each view can have different chart type)
   * @param type - used for identifying campaign types in bar charts data
   * @param id - used for identifying campaign types in line charts data
   */
  @action
  public removeCampaignFromActiveChart = (id: string, type: DashboardCampaignType): void => {
    switch (this.activeTab) {
      case campaignsTabs.SUBSCRIBERS_ACTIVITY:
        return this.removeCampaignFromClickedDeliveredHoursChart(type);
      case campaignsTabs.SENDING_HOURS:
        return this.removeCampaignFromShippingHoursChart(type);
      default:
        return this.removeCampaignTypesFromLineChart(t(id));
    }
  };

  /**
   * Removes bar chart item from bar chart array
   * @param property
   * @param key
   */
  @action
  public removeCampaignTypeFromBarChart = (property: Property, key: string): void => {
    property.setValue(property.getValue().map(shippingHourItem => omit(shippingHourItem, [key])));
  };

  @action
  public removeCampaignFromShippingHoursChart = (type: DashboardCampaignType): void => {
    const shippingHoursProperty = this.getProperty('shippingHoursChartData');
    this.removeCampaignTypeFromBarChart(shippingHoursProperty, type);
  };

  @action
  public removeCampaignTypesFromLineChart(id: string): void {
    this.removeCampaignTypesDataFromGeneralChart(id);
    this.removeCampaignTypesDataFromSentChart(id);
    this.removeCampaignTypesDataFromCtrChart(id);
    this.removeCampaignTypesDataFromDspChart(id);
  }

  @action
  public removeCampaignTypesDataFromSentChart = (id: string): void => {
    this.removeCampaignTypeFromLineChart(this.getProperty('sentCampaignsChartData'), id);
  };

  @action
  public removeCampaignTypesDataFromDspChart = (id: string): void => {
    this.removeCampaignTypeFromLineChart(this.getProperty('dspChartData'), id);
    this.removeCampaignTypeFromLineChart(this.getProperty('averageDspChartData'), id);
  };

  /**
   * Removes line chart item from line chart array
   * @param chartData
   * @param id
   */
  @action
  public removeCampaignTypeFromLineChart = (chartData: Property, id: string): void => {
    chartData.setValue(chartData.getValue()
      .filter(chartItem => chartItem.id !== id));
  };

  @action
  public removeCampaignTypesDataFromCtrChart = (id: string): void => {
    this.removeCampaignTypeFromLineChart(this.getProperty('ctrChartData'), id);
    this.removeCampaignTypeFromLineChart(this.getProperty('averageCtrChartData'), id);
  };

  /**
   * Removes line chart items from clicked, delivered and  sent charts
   * @param id
   */
  @action
  public removeCampaignTypesDataFromGeneralChart = (id: string): void => {
    this.removeCampaignTypeFromLineChart(this.getProperty('clickedChartData'), id);
    this.removeCampaignTypeFromLineChart(this.getProperty('deliveredChartData'), id);
    this.removeCampaignTypeFromLineChart(this.getProperty('sentChartData'), id);
  };

  @action
  public removeCampaignFromClickedDeliveredHoursChart = (type: DashboardCampaignType): void => {
    const clickedProperty = this.getProperty('clickedHoursChartData');
    const deliveredProperty = this.getProperty('deliveredHoursChartData');

    this.removeCampaignTypeFromBarChart(clickedProperty, type);
    this.removeCampaignTypeFromBarChart(deliveredProperty, type);
  };

  @action
  public fetchSummaryCampaigns = async (): Promise<void> => {
    if (!this.isSummaryCampaignsEnabled) {
      this.removeCampaignFromActiveChart(dashboardFiltersCampaignTypes.SUMMARY, DashboardCampaignType.SUMMARY);
      return;
    }
    this.isLoading = true;
    await this.assignCampaignToActiveChart(DashboardCampaignType.SUMMARY, this.getProperty('summaryCampaigns'), dashboardFiltersCampaignTypes.SUMMARY);

    this.checkPlatformIsSelected();
  };

  /**
   * The way new data is added to a chart differs by the structure of the data that the various chart types (ex. line, bar, pie) receive so
   * this method is responsible for assigning data to chart based on the currently active view type (each view can have different chart type)
   * @param campaignType - used for identifying campaign types in bar charts data
   * @param campaignsTypeProperty - used for modifying chart arrays
   * @param id - used for identifying campaign types in line charts data
   */
  @action
  public assignCampaignToActiveChart = async (campaignType: DashboardCampaignType, campaignsTypeProperty: Property, id: string): Promise<void> => {
    const campaigns = [];
    const assignBarChartItem: IAssignBarChartItem = { campaignType, campaignsTypeProperty, campaigns };
    const assignLineChartItem: IAssignLineChartItem = { id, campaignsTypeProperty, campaigns, campaignType };
    switch (this.activeTab) {
      case campaignsTabs.SUBSCRIBERS_ACTIVITY:
        return this.getAndAssignClickedDeliveredHoursToChart(assignBarChartItem);
      case campaignsTabs.SENDING_HOURS:
        return this.getAndAssignShippingHoursToChartData(assignBarChartItem);
      default:
        return this.getAndAssignCampaignStatsToChart(assignLineChartItem);
    }
  };

  @action
  public getAndAssignCampaignStatsToChart = async (assignLineChartItem: IAssignLineChartItem): Promise<void> => {
    const { campaignsTypeProperty, id, campaignType } = assignLineChartItem;

    const stats = await this.fetchCampaignsStatType(campaignType);
    const { from, to } = this.requestDateRange;

    let result = [];
    for (let date = dayjs(from); date.isBefore(to); date = dayjs(date).add(1, 'days')) {
      let dayDate = date.format('YYYY-MM-DD');
      const dayItem = stats.campaignHistogram.find(day => dayjs(day.day).format('YYYY-MM-DD') === dayDate);
      if (!dayItem) {
        result = result.concat({
          day: dayDate,
          data: { clicked: 0, delivered: 0, ctr: 0, dsp: 0, campaignCount: 0, sent: 0, averageDsp: 0, averageCtr: 0 }
        });
        continue;
      }
      result = result.concat({ day: dayDate, data: dayItem.data });
    }

    stats.campaignHistogram = result;
    campaignsTypeProperty.setValue(stats);
    this.addCampaignTypesToLineChart(stats, t(id), this.mapColor[campaignType]);
  };

  /**
   * Adds line chart items to clicked , delivered and sent charts
   * @param stats
   * @param id
   * @param color
   */
  @action
  public addCampaignTypesDataToGeneralChart = (stats: IDashboardCampaignsStatsWithCount, id: string, color: string): void => {
    this.addCampaignTypeToLineChart({
      chartDataProperty: this.getProperty('clickedChartData'),
      stats,
      id,
      color,
      histogramItemDataKey: 'clicked',
    });
    this.addCampaignTypeToLineChart({
      chartDataProperty: this.getProperty('deliveredChartData'),
      stats,
      id,
      color,
      histogramItemDataKey: 'delivered'
    });
    this.addCampaignTypeToLineChart({
      chartDataProperty: this.getProperty('sentChartData'),
      stats,
      id,
      color,
      histogramItemDataKey: 'sent'
    });
  };

  /**
   * Adds line chart item to line chart array
   * @param lineChartUpdateItem
   */
  @action
  public addCampaignTypeToLineChart = (lineChartUpdateItem: IExtendedLineChartUpdateItem): void => {
    const { histogramItemDataKey, chartDataProperty, stats, id } = lineChartUpdateItem;
    const chartDataExists = chartDataProperty.getValue().some(chartDataItem => chartDataItem.id === id);

    if (chartDataExists) {
      this.updateLineChart(lineChartUpdateItem);
      return;
    }

    chartDataProperty.setValue([...chartDataProperty.getValue(), {
        id,
        data: this.getChartDataFromHistogram(stats.campaignHistogram, histogramItemDataKey),
        color: lineChartUpdateItem.color,
      }]
    );
  };

  /**
   * Formats histogram data to line chart datum
   * @param histogram
   * @param key
   */
  @action
  public getChartDataFromHistogram(histogram: ICampaignHistogramItem[], key: string): IHistogramData[] {
    return histogram
      .map(histogramItem => {
        return {
          x: histogramItem.day,
          y: histogramItem.data[key],
        };
      });
  }

  /**
   * Updates line chart values by adding to existing array passed line chart item
   * @param lineChartUpdateItem
   */
  @action
  public updateLineChart = (lineChartUpdateItem: IExtendedLineChartUpdateItem): void => {
    const { chartDataProperty, id, stats, histogramItemDataKey } = lineChartUpdateItem;
    chartDataProperty.setValue(chartDataProperty.getValue()
      .map(chartDataItem => {
        if (chartDataItem.id === id) {
          chartDataItem.data = this.getChartDataFromHistogram(stats.campaignHistogram, histogramItemDataKey);
        }
        return chartDataItem;
      }));
  };

  @action
  public addCampaignTypesToLineChart(stats: IDashboardCampaignsStatsWithCount, id: string, color: string): void {
    this.addCampaignTypesDataToGeneralChart(stats, id, color);
    this.addCampaignTypesDataToSendChart(stats, id, color);
    this.addCampaignTypesDataToCtrChart(stats, id, color);
    this.addCampaignTypesDataToDspChart(stats, id, color);
  }

  @action
  public addCampaignTypesDataToDspChart = (stats: IDashboardCampaignsStatsWithCount, id: string, color: string): void => {
    this.addCampaignTypeToLineChart({
      chartDataProperty: this.getProperty('dspChartData'),
      stats,
      id,
      histogramItemDataKey: 'dsp',
      color,
    });
    this.addCampaignTypeToLineChart({
      chartDataProperty: this.getProperty('averageDspChartData'),
      stats,
      id,
      histogramItemDataKey: 'averageDsp',
      color,
    });
  };

  @action
  public addCampaignTypesDataToCtrChart = (stats: IDashboardCampaignsStatsWithCount, id: string, color: string): void => {
    this.addCampaignTypeToLineChart({
      chartDataProperty: this.getProperty('ctrChartData'),
      stats,
      id,
      histogramItemDataKey: 'ctr',
      color,
    });
    this.addCampaignTypeToLineChart({
      chartDataProperty: this.getProperty('averageCtrChartData'),
      stats,
      id,
      histogramItemDataKey: 'averageCtr',
      color,
    });
  };

  @action
  public addCampaignTypesDataToSendChart = (stats: IDashboardCampaignsStatsWithCount, id: string, color: string): void => {
    this.addCampaignTypeToLineChart({
      chartDataProperty: this.getProperty('sentCampaignsChartData'),
      stats,
      id,
      histogramItemDataKey: 'campaignCount',
      color,
    });
  };

  /**
   * Responsible for fetching ShippingHours data and invoking bar chart assigning method
   * @param assignBarChartItem
   */

  @action
  public getAndAssignShippingHoursToChartData = async (assignBarChartItem: IAssignBarChartItem): Promise<void> => {
    const { campaignsTypeProperty, campaigns, campaignType } = assignBarChartItem;
    const shippingHoursProperty = this.getProperty('shippingHoursChartData');

    const stats = await this.fetchCampaignsStatType(campaignType);

    campaignsTypeProperty.setValue(stats);
    const shippingHours = await this.getShippingHoursChartData(campaignType);

    const barChartUpdateItem: IBarChartUpdateItem = {
      property: shippingHoursProperty,
      campaigns: campaigns,
      key: campaignType,
      campaignTypeData: shippingHours,
    };

    await this.addCampaignTypeToBarChart(barChartUpdateItem);
  };

  @action
  public getCampaignSentHoursStats = async (campaignType: DashboardCampaignType): Promise<ICampaignSentHourStats[]> => {
    const { timezone, to, from, platform } = this.projectRequestBody;
    const { campaignSentHourStats } = await getProjectSendHoursUseCase.exec({ campaignType, from, to, timezone, platform });

    return campaignSentHourStats;
  };

  /**
   * Returns shipping hours data in bar datum format
   * @param key - custom key necessary for displaying campaign type: summary, targeting etc.
   */
  @action
  public getShippingHoursChartData = async (key: DashboardCampaignType): Promise<BarDatum[]> => {
    const campaignSentHourStats = await this.getCampaignSentHoursStats(key);
    return campaignSentHourStats.map(sendHour => {
      return {
        hour: `${ sendHour.hour }:00`,
        [key]: sendHour.count
      };
    });
  };

  /**
   * Responsible for fetching ClickedDeliveredHours data and invoking bar chart assigning methods
   * @param assignBarChartItem
   */
  @action
  public getAndAssignClickedDeliveredHoursToChart = async (assignBarChartItem: IAssignBarChartItem): Promise<void> => {
    const { campaignType } = assignBarChartItem;
    const stats = await this.fetchCampaignsStatType(campaignType);

    assignBarChartItem.campaignsTypeProperty.setValue(stats);

    const {
      clickedBarChartUpdateItem,
      deliveredBarChartUpdateItem
    } = await this.getClickedDeliveredBarChartUpdateItems(assignBarChartItem);

    this.addCampaignTypeToBarChart(clickedBarChartUpdateItem);
    this.addCampaignTypeToBarChart(deliveredBarChartUpdateItem);
  };

  /**
   * Adds bar chart item to bar chart array
   * @param barChartUpdateItem
   */
  public addCampaignTypeToBarChart = (barChartUpdateItem: IBarChartUpdateItem): void => {
    const { property, key, campaignTypeData } = barChartUpdateItem;
    const isChartDataExists = !isArrayEmpty(property.getValue());

    if (isChartDataExists) {
      return this.updateCampaignTypeBarChart(barChartUpdateItem);
    }

    property.setValue(campaignTypeData.map((campaignTypeItem: { hour: string, count: number }) => {
      return {
        hour: campaignTypeItem.hour,
        [key]: campaignTypeItem[key],
      };
    }));
  };

  @action
  public getClickedDeliveredBarChartUpdateItems = async (assignBarChartItem: IAssignBarChartItem): Promise<{
    clickedBarChartUpdateItem: IBarChartUpdateItem,
    deliveredBarChartUpdateItem: IBarChartUpdateItem
  }> => {
    const { campaigns, campaignType } = assignBarChartItem;

    const campaignClickedDeliveredHourStats = await this.getCampaignClickedDeliveredHourStats(campaignType);

    const clickedUpdateItem: IClickedDeliveredUpdateItem = {
      campaignType,
      type: 'clicked',
      clickedDeliveredHourStats: campaignClickedDeliveredHourStats,
      campaigns
    };
    const deliveredUpdateItem: IClickedDeliveredUpdateItem = {
      campaignType,
      type: 'delivered',
      clickedDeliveredHourStats: campaignClickedDeliveredHourStats,
      campaigns
    };

    const clickedBarChartUpdateItem = await this.getClickedDeliveredHoursBarChartUpdateItem(clickedUpdateItem);
    const deliveredBarChartUpdateItem = await this.getClickedDeliveredHoursBarChartUpdateItem(deliveredUpdateItem);

    return { clickedBarChartUpdateItem, deliveredBarChartUpdateItem };
  };

  @action
  public getCampaignClickedDeliveredHourStats = async (campaignType: DashboardCampaignType): Promise<ICampaignClickedDeliveredHourStats[]> => {
    const { timezone, platform, from, to } = this.projectRequestBody;
    const { campaignClickedDeliveredHourStats } = await getProjectClickedAndDeliveredUseCase.exec({
      timezone,
      campaignType,
      platform,
      from,
      to
    });

    return campaignClickedDeliveredHourStats;
  };

  @action
  public getClickedHoursData = async (key: string, campaignClickedDeliveredHourStats: ICampaignClickedDeliveredHourStats[]): Promise<BarDatum[]> => {
    return campaignClickedDeliveredHourStats.map(sendHour => {
      return {
        hour: `${ sendHour.hour }:00`,
        [key]: sendHour.clicked
      };
    });
  };

  @action
  public getDeliveredHoursData = async (key: string, campaignClickedDeliveredHourStats: ICampaignClickedDeliveredHourStats[]): Promise<BarDatum[]> => {
    return campaignClickedDeliveredHourStats.map(sendHour => {
      return {
        hour: `${ sendHour.hour }:00`,
        [key]: sendHour.delivered
      };
    });
  };

  @action
  public getClickedDeliveredHoursBarChartUpdateItem = async (clickedDeliveredUpdateItem: IClickedDeliveredUpdateItem): Promise<IBarChartUpdateItem> => {
    const { type, campaigns, campaignType, clickedDeliveredHourStats } = clickedDeliveredUpdateItem;
    const isClickedType = type === 'clicked';
    const clicked = await this.getClickedHoursData(campaignType, clickedDeliveredHourStats);
    const delivered = await this.getDeliveredHoursData(campaignType, clickedDeliveredHourStats);

    return {
      property: isClickedType ? this.getProperty('clickedHoursChartData') : this.getProperty('deliveredHoursChartData'),
      campaigns: campaigns,
      key: campaignType,
      campaignTypeData: isClickedType ? clicked : delivered,
    };
  };

  /**
   * Updates bar chart values by adding to existing array passed bar chart item
   * @param barChartUpdateItem
   */
  @action
  public updateCampaignTypeBarChart = (barChartUpdateItem: IBarChartUpdateItem): void => {
    const { property, key, campaignTypeData } = barChartUpdateItem;
    property.setValue(property.getValue().map((propertyItem: { hour: string, count: number }) => {
      const existingHour = campaignTypeData.find((campaignTypeItem: { hour: string, count: number }) => {
        return propertyItem.hour === campaignTypeItem.hour;
      });

      return {
        ...propertyItem,
        [key]: existingHour ? existingHour[key] : 0
      };
    }));

    this.clearLoaders();
  };

  /**
   * Responsible for fetching campaign stats (clicked, delivered, sent, ctr etc. ) data for line charts and stats tiles & table
   */
  @action
  private fetchCampaignsStatType = async (
    campaignType: DashboardCampaignType,
  ): Promise<IDashboardCampaignsStatsWithCount> => {
    const { timezone, from, to, platform } = this.projectRequestBody;
    const { campaignStats } = await getProjectCampaignStatsUseCase.exec({
      type: campaignType.toString(),
      timezone,
      from,
      to,
      platform
    });

    const averageCtr = await this.getProjectAverageCtr(campaignType.toString());
    return {
      ...campaignStats,
      averageCtr: averageCtr
    };
  };

  @action
  public fetchMassCampaigns = async (): Promise<void> => {
    if (!this.isMassCampaignsEnabled) {
      this.removeCampaignFromActiveChart(dashboardFiltersCampaignTypes.MASS, DashboardCampaignType.MASS);
      return;
    }
    this.isLoading = true;
    await this.assignCampaignToActiveChart(DashboardCampaignType.MASS, this.getProperty('massCampaigns'), dashboardFiltersCampaignTypes.MASS);

    this.checkPlatformIsSelected();
  };

  @action
  public fetchTargetingCampaigns = async (): Promise<void> => {
    if (!this.isTargetingCampaignsEnabled) {
      this.removeCampaignFromActiveChart(dashboardFiltersCampaignTypes.TARGETING, DashboardCampaignType.TARGETED);
      return;
    }
    this.isLoading = true;
    await this.assignCampaignToActiveChart(DashboardCampaignType.TARGETED, this.getProperty('targetingCampaigns'), dashboardFiltersCampaignTypes.TARGETING);

    this.checkPlatformIsSelected();
  };

  @action
  public fetchGeolocationCampaigns = async (): Promise<void> => {
    if (!this.isGeolocationCampaignsEnabled) {
      this.removeCampaignFromActiveChart(dashboardFiltersCampaignTypes.GEOLOCATION, DashboardCampaignType.GEOLOCATION);
      return;
    }
    this.isLoading = true;
    await this.assignCampaignToActiveChart(DashboardCampaignType.GEOLOCATION, this.getProperty('geolocationCampaigns'), dashboardFiltersCampaignTypes.GEOLOCATION);

    this.checkPlatformIsSelected();
  };

  @action
  public fetchStatisticsHistogramsTableData = async (): Promise<void> => {
    const { timezone, platform, from, to } = this.projectRequestBody;
    const { dashboardCampaignStatisticsHistogram } = await getProjectCampaignStatsHistogramUseCase.exec({
      platform,
      from,
      to,
      limit: this.limit,
      offset: this.offset,
      timezone,
    });

    this.statisticsHistogramsTableData = dashboardCampaignStatisticsHistogram.histogram;
    this.total = dashboardCampaignStatisticsHistogram.total;
    return;
  };

  @action
  public fetchOnRangeAndPlatformChange = async (): Promise<void> => {
    this.isLoading = true;
    await this.fetchCampaignsStats();
  };

  @action
  public fetchClickedDeliveredHoursTableData = async (): Promise<void> => {
    this.clickedDeliveredHoursTableData = await this.getCampaignClickedDeliveredHourStats(DashboardCampaignType.SUMMARY);
  };

  @action
  public fetchShippingHoursTableData = async (): Promise<void> => {
    this.shippingHoursTableData = await this.getCampaignSentHoursStats(DashboardCampaignType.SUMMARY);
  };

  @action
  public fetchOnCampaignType = async (type: string): Promise<void> => {
    this.isClickedDeliveredInitialLoading = false;
    this.isClickedDeliveredLoading = true;

    switch (type) {
      case 'SUMMARY':
        await this.fetchSummaryCampaigns();
        break;
      case 'MASS':
        await this.fetchMassCampaigns();
        break;
      case 'TARGETING':
        await this.fetchTargetingCampaigns();
        break;
      case 'GEO':
        await this.fetchGeolocationCampaigns();
        break;
    }

    this.isClickedDeliveredLoading = false;
    this.isClickedDeliveredInitialLoading = true;
    this.clearLoaders();
  };

  @computed
  public get defaultProjectCampaignStats(): IDashboardCampaignsStatsWithCount {
    return {
      sent: 0,
      clicked: 0,
      ctr: 0,
      averageCtr: 0,
      delivered: 0,
      campaignsCount: 0,
      campaignHistogram: []
    };
  }

  @computed
  public get loaders(): IExternalLoaders {
    return {
      isLoading: this.isLoading,
      isInitialLoader: this.initialLoader,
    };
  }

  @action
  public getBarDatumKeysAndColors = (keyToOmit: string, chartData: BarDatum[]): IBarDatumKeysColors => {
    if (isArrayEmpty(chartData)) {
      return { keys: [], colors: [] };
    }

    let keys = Object.keys(chartData[0]).filter(key => key !== keyToOmit);
    return {
      keys,
      colors: keys.map(key => this.mapKeyToColor(key))
    };
  };

  private mapKeyToColor = (translatedKey: string): string => {
    for (let key in DashboardCampaignType) {
      if (translatedKey.toLowerCase() === DashboardCampaignType[key].toLowerCase()) {
        return this.mapColor[DashboardCampaignType[key]];
      }
    }
  };

  // Cleaners
  @action
  private clearLoaders = (): void => {
    this.isLoading = false;
    this.initialLoader = true;
  };

  @action
  public cleanCtrData = (): void => {
    this.ctrChartData = [];
    this.averageCtrChartData = [];
    this.statisticsHistogramsTableData = [];
  };

  @action
  public cleanDspData = (): void => {
    this.dspChartData = [];
    this.averageDspChartData = [];
    this.statisticsHistogramsTableData = [];
  };

  @action
  public cleanClickedDeliveredData = (): void => {
    this.clickedHoursChartData = [];
    this.deliveredHoursChartData = [];
    this.clickedDeliveredHoursTableData = [];
  };

  @action
  private clearCampaignsFilters = (): void => {
    this.isSummaryCampaignsEnabled = true;
    this.isTargetingCampaignsEnabled = false;
    this.isGeolocationCampaignsEnabled = false;
    this.isMassCampaignsEnabled = false;
  };

  @action
  public setDefaultValues(): void {
    this.clearCampaignsFilters();
    this.clearCampaignsStats();
  }

  private checkPlatformIsSelected = (): void => {
    !this.isPlatformSelected && this.clearCampaignsStats();
  };

  @action
  private clearCampaignsStats = (): void => {
    this.summaryCampaigns = this.defaultProjectCampaignStats;
    this.targetingCampaigns = this.defaultProjectCampaignStats;
    this.geolocationCampaigns = this.defaultProjectCampaignStats;
    this.massCampaigns = this.defaultProjectCampaignStats;
  };

  @action
  public cleanShippingHoursData = (): void => {
    this.shippingHoursChartData = [];
    this.shippingHoursTableData = [];
  };

  @action
  public clearOffset = (): void => {
    this.offset = 0;
  };
}
