import { API, UseCase } from "@ppg/common";
import { getPlatformTypeParam } from '../../../helpers';
import { IGetSubscribersAverageLifetimeUseCaseResult, PlatformType } from '../../../interfaces';

interface IGetSubscribersAverageLifetimeRequest {
  organization: string;
  platform?: PlatformType;
}

export class GetSubscribersAverageLifetimeUseCase extends UseCase<IGetSubscribersAverageLifetimeRequest, IGetSubscribersAverageLifetimeUseCaseResult> {
  protected async execute({ organization, platform }: IGetSubscribersAverageLifetimeRequest) {
    const endpoint = `statistics/organizations/${ organization }/subscribers/lifetime/average`
      + getPlatformTypeParam(platform);

    return API.HTTP.get<IGetSubscribersAverageLifetimeUseCaseResult>(endpoint);
  }
}

