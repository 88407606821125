import { API, UseCase } from "@ppg/common";

interface ISegmentConditionGroupItemPlainObject {
  key: string;
  value: string;
  operator: string;
}

interface ISegmentConditionGroupPlainObject {
  operator: string;
  conditions: ISegmentConditionGroupItemPlainObject[]
}

export interface ISegmentConditionPlainObject {
  operator: string;
  conditions: ISegmentConditionGroupPlainObject[];
}

interface ICreateSegmentRequest {
  name: string;
  conditions: ISegmentConditionPlainObject;
}

export class CreateSegmentUseCase extends UseCase<ICreateSegmentRequest, void> {
  protected async execute({ name, conditions}: ICreateSegmentRequest) {
    return API.HTTP.post<void>(`core/projects/${API.HTTP.currentProjectId}/segment`, {name, conditions})
  }
}

export const createSegmentUseCase = new CreateSegmentUseCase()
