import { API, UseCase } from '@ppg/common';

interface CheckChangePasswordTokenExpireData {
  token: string;
}

export class CheckChangePasswordTokenExpireUseCase extends UseCase<CheckChangePasswordTokenExpireData, void> {
  protected async execute({ token }) {
    await API.HTTP.post<void>(`aai/user/password/check/${ token }`);
  }
}

export const checkChangePasswordTokenExpireUseCase = new CheckChangePasswordTokenExpireUseCase();
