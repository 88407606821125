import { API, UseCase } from "@ppg/common";
import { PlatformType } from '../../interfaces';

interface IGetCampaignSummaryUseCaseRequest {
  project: string;
  campaign: string
  platform?: PlatformType;
}

interface IGetCampaignSummaryUseCaseResult {
  project: string,
  campaign: string,
  provider: string,
  clicked: number,
  delivered: number,
  rejected: number,
  sent: number,
  unregistered: number,
  click0: number,
  click1: number,
}

export class GetCampaignSummaryUseCase extends UseCase<IGetCampaignSummaryUseCaseRequest, IGetCampaignSummaryUseCaseResult> {
  protected async execute({ platform, campaign, project }: IGetCampaignSummaryUseCaseRequest) {
    return API.HTTP.get<IGetCampaignSummaryUseCaseResult>(`statistics/projects/${project}/pushes/${campaign}/summary/${platform ? platform : ''}`);
  }
}
