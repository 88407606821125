import { ProjectRelated } from '../ProjectRelatedStore';
import { action, computed, observable, reaction } from 'mobx';
import { debounce, property } from '@ppg/common';
import { getLabelsKeysUseCase, getSubscribersLabelsUseCase } from '../../useCases/core';
import { ITEMS_PER_PAGE } from '../../constants';
import { ISubscriberLabels } from '../../useCases/core/subscriber/GetSubscribersLabelsUseCase';
import { Utils } from '../utils';
import { IListQueryOptions } from '../../interfaces/IListQueryOptions';
import { AutomationProjectDataStore } from "./AutomationProjectDataStore";
import { ProjectStore } from "./ProjectStore";
import { userStore } from "../index";
import { ISubscriberSelector, SubscriberNew } from "../../modelsMobx/subscriber/SubscriberNew";
import { listSubscribersBySegmentUseCase } from "../../useCases/core/subscriber/ListSubscribersBySegmentUseCase";
import { Segment } from "../../modelsMobx/segmentation/Segment";
import { SubscriberFilterStoreNew } from "./SubscriberFilterStoreNew";
import { SubscriberDetailsStore } from "./SubscriberDetailsStore";
import { ISubscriberNewDTO } from "../../modelsMobx/subscriber/interfaces";
import { getSubscriberNewUseCase } from "../../useCases/core/subscriber/GetSubscriberNewUseCase";

export class SubscribersPageStore extends ProjectRelated {
  static SUBSCRIBERS_LABELS_LIMIT = 40;
  static SUBSCRIBERS_LABELS_HEIGHT_LIST = 108;
  static SUBSCRIBERS_LABELS_HEIGHT = 130;

  @property()
  public subscribers: SubscriberNew[] = [];

  @property()
  public subscriber: SubscriberNew | null;

  @property()
  public offset: number = 0;

  @property()
  public limit: number = ITEMS_PER_PAGE;

  @property()
  public total: number = 0;

  @property()
  private infinity: boolean;

  @property()
  public labelsOffset: number = 0;

  @property()
  public labelsLimit: number = SubscribersPageStore.SUBSCRIBERS_LABELS_LIMIT;

  @property()
  public startDate: Date;

  @property()
  public labelsKeys: string[] = [];

  @observable public isSubscribersLoading: boolean = true;
  @observable public isSubscriberLoading: boolean = true;
  @observable public isLabelsLoading: boolean = true;

  public filters: SubscriberFilterStoreNew;
  public details: SubscriberDetailsStore;

  constructor(
    projectStore: ProjectStore,
    private readonly automationProjectDataStore: AutomationProjectDataStore
  ) {
    super(projectStore);
  }

  public setDefaultValues(): void {
    this.initializeStores();
    this.subscribers = [];
    this.subscriber = null;
    this.labelsOffset = 0;
  }

  public reactOnPaginationChange = reaction(() => this.listFactors, () => this.debouncedSubscribersFetch());
  public debouncedSubscribersFetch = debounce(() => this.fetchSubscribers(), 300);

  @action
  private initializeStores = (): void => {
    this.filters = new SubscriberFilterStoreNew(this);
    this.details = new SubscriberDetailsStore(this);
  };

  public initializeSubscriberList = async (): Promise<void> => {
    this.setInfinity(userStore.settings.infinity);
    await this.fetchLabelsKeys();

    await Promise.all([
      this.fetchSubscribers(),
      this.filters.setHeaders(),
    ]);
  };

  @computed
  private get listFactors(): IListQueryOptions {
    return {
      offset: this.offset,
      limit: this.limit,
    };
  }

  @computed
  public get subscribersIds(): string[] {
    return this.subscribers.map(subscriber => subscriber.id);
  }

  public async fetchSubscribersWithClearOffset(): Promise<void> {
    if(this.offset !== 0) this.offset = 0;
    await this.fetchSubscribers();
  }

  @action
  public async fetchSubscribers(): Promise<void> {
    await this.executeWithSubscribersLoader(async () => {
      const { customIdSearch } = this.filters;
      const selectedSegment = this.projectStore.segmentsStore.selectedSegment?.id;

      const { data, metadata } = await listSubscribersBySegmentUseCase.exec({
        limit: this.limit,
        offset: this.offset,
        customId: customIdSearch,
        segment: selectedSegment || null
      });

      this.total = metadata.total;
      const subscribers = data.map(subscriber => SubscriberNew.createSubscriber(subscriber, this.automationProjectDataStore));

      this.subscribers = this.infinity ? this.subscribers.concat(subscribers) : subscribers;

      const labels = await this.fetchSubscribersLabels();
      this.assignLabelsToSubscribers(labels);
    });
  };

  @action
  private assignLabelsToSubscribers(labels: ISubscriberLabels[]) {
    const subscribersMap = new Map();

    for (let subscriber of this.subscribers) {
      subscribersMap.set(subscriber.id, subscriber);
    }

    for (let label of labels) {
      const subscriber = subscribersMap.get(label.subscriber);
      if (subscriber) {
        subscriber.appendLabels(label.labels);
      }
    }
  }

  @action
  public async selectSubscriberById(subscriberId: string): Promise<void> {
    await this.executeWithSubscriberLoader(async () => {
      const subscriber: ISubscriberNewDTO = await getSubscriberNewUseCase.exec({
        subscriber: subscriberId
      });

      this.subscriber = SubscriberNew.createSubscriber(subscriber, this.automationProjectDataStore);

      await this.subscriber.fetchSelectors();
      await this.subscriber.getMoreLabels({ limit: this.labelsLimit, offset: this.labelsOffset });
    });
  };

  @action
  public async fetchSubscribersLabels(): Promise<ISubscriberLabels[]> {
    if (this.subscribers.length === 0) return [];
    return await this.executeWithLabelsLoader<ISubscriberLabels[]>(() => getSubscribersLabelsUseCase.exec({
      offset: this.labelsOffset,
      limit: this.labelsLimit,
      subscribers: this.subscribersIds
    }));
  }

  @action
  public async fetchLabelsKeys(): Promise<void> {
    this.labelsKeys = await getLabelsKeysUseCase.exec();
  };

  public get labelsSysKeys(): string[] {
    return this.labelsKeys.filter(key => key.includes('sys.'));
  }

  public get labelsKeysWithoutSys(): string[] {
    return this.labelsKeys.filter(key => !key.includes("sys."));
  }

  @computed
  public get paginatedSelectorsList(): ISubscriberSelector[] {
    return this.subscriber ? this.subscriber.selectors.slice(this.details.selectorsOffset, Math.floor((this.details.selectorsOffset / this.details.selectorsLimit) + 1) * this.details.selectorsLimit) : [];
  }

  @computed
  public get getInfinity(): boolean {
    return this.infinity;
  }

  @action
  public setInfinity(infinity: boolean): void {
    this.infinity = infinity;
  }

  @action
  public async initializeSubscriberDetails(subscriberId: string) {
    if (!this.subscriber || this.subscriber.id !== subscriberId) {
      await this.selectSubscriberById(subscriberId);
    }
  }

  private executeWithSubscriberLoader<T = any>(callback: () => Promise<T>) {
    return Utils.executeWithLoader(
      callback,
      () => this.isSubscriberLoading = true,
      () => this.isSubscriberLoading = false,
      (err) => {
        console.error(err);
        this.isSubscriberLoading = false;
      }
    );
  }

  private executeWithSubscribersLoader<T = any>(callback: () => Promise<T>) {
    return Utils.executeWithLoader(
      callback,
      () => this.isSubscribersLoading = true,
      () => this.isSubscribersLoading = false,
      (err) => {
        console.error(err);
        this.isSubscribersLoading = false;
      }
    );
  }

  private executeWithLabelsLoader<T = any>(callback: () => Promise<T>) {
    return Utils.executeWithLoader(
      callback,
      () => this.isLabelsLoading = true,
      () => this.isLabelsLoading = false,
      (err) => {
        console.error(err);
        this.isLabelsLoading = false;
      }
    );
  }
}
