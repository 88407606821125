import { Form, connector } from '../../../base';
import { property } from '../../../base/decorators';
import { PushCampaign, WebUser } from '../../../models';
import { getCampaignStatsInDateRange } from '../../../useCases/core/push/GetCampaignStatsInDateRange';
import dayjs from 'dayjs';

interface IFormProps {
}

export interface IDateRange {
  from: Date;
  till: Date;
}

export interface IReportsForDateRange {
  received: number,
  clicked: number
}

export class TriggeredPushForm extends Form<IFormProps, IFormProps> implements IFormProps {

  @property() public isListVisible: boolean;
  @property() public advancedMode: boolean;
  @property() public campaign: PushCampaign;
  @property() public reportsForDateRange: IReportsForDateRange = {
    clicked: 0,
    received: 0
  };
  @property() public isCreatorVisible: boolean;
  @property() public isLoading: boolean;

  @connector.bindWith('user') private user: WebUser;

  public initialize() {
    this.listenTo(this, 'change:isListVisible', this.handleChangeIsListVisible);
    this.listenTo(this, 'change:isCreatorVisible', this.handleChangeIsCreatorVisible);
  }

  public defaults(): Partial<IFormProps> {
    return {
      reportsForDateRange: {
        clicked: 0,
        received: 0
      }
    };
  }

  private handleChangeIsListVisible = () => {
    if (this.isListVisible === true)
      this.isCreatorVisible = false;
  };

  private handleChangeIsCreatorVisible = () => {
    if (this.isCreatorVisible === true)
      this.isListVisible = false;
  };

  public async fetchCampaign(id: string, dateRange: IDateRange) {
    if (!id) {
      this.campaign = null;
      return;
    }
    this.campaign = await PushCampaign.getPush(this.user.currentProject, id);
    await this.fetchReportForDateRange(dateRange.from, dateRange.till);
    this.trigger('change', this, this.collection);
  }

  public async fetchReportForDateRange(from: Date, till: Date) {
    this.isLoading = true;

    const { clicked, delivered } = await getCampaignStatsInDateRange.exec({
      projectID: this.user.currentProject,
      campaign: this.campaign.id as string,
      from: dayjs(from).startOf('day').toDate(),
      to: dayjs(till).endOf('day').toDate(),
    });

    this.set({
      reportsForDateRange: {
        received: delivered || 0,
        clicked: clicked || 0,
      }
    });

    this.isLoading = false;
  }

  public toggleForm() {
    this.isCreatorVisible = !this.isCreatorVisible;
  }

  public toggleList() {
    this.isListVisible = !this.isListVisible;
  }

}
