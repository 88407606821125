import { API, UseCase } from "@ppg/common";
import { ICopyToProjectRequest } from '../interfaces';

export class CopyToProjectsGeolocationPushUseCase extends UseCase<ICopyToProjectRequest, void> {
  protected async execute({ campaignId, projects }: ICopyToProjectRequest) {
    return API.HTTP.post<void>(`core/projects/${ API.HTTP.currentProjectId }/pushes/geolocation/${ campaignId }/copy-transfer`, { projects });
  }
}

export const copyToProjectsGeolocationPushUseCase = new CopyToProjectsGeolocationPushUseCase();
