import { observer } from 'mobx-react';
import * as React from 'react';
import { observable } from 'mobx';
import { t } from '../../base/helpers';
import { CARD_OPTIONS } from '../../stores/payment/CardsStore';
import { CardElement } from '@stripe/react-stripe-js';

interface CardPaymentModalContentProps {
  description?: string;
  onInputChange(e):void;
  billingDetails: string;
}

@observer
export class AddCardModalContent extends React.Component<CardPaymentModalContentProps> {

  @observable checkoutError: string = '';

  public handleCardDetailsChange = e => {
    this.checkoutError = e.error ? e.error.message : '';
  };

  public render() {
    const { description, onInputChange, billingDetails } = this.props;
    return <>
      {description && <span className='modal-description'>{ description }</span>}
      <form id='add-card-form' className='add-card-modal-content'>
        <label>{ t('Name on card') }
          <input name='name'
                 type='text'
                 onChange={ e => onInputChange(e) }
                 value={ billingDetails }
                 placeholder={ t('Adam Kowalski') } required/>
        </label>
        <CardElement options={ CARD_OPTIONS }
                     onChange={ this.handleCardDetailsChange }/>
        <div className="error-wrapper">
          { this.checkoutError && <div id="card-errors" className='error-container' role="alert"><span
            className='icon-cancel-badge'/>{ this.checkoutError }</div> }
        </div>
      </form>
    </>
  }
}
