import { property, localSettings, LANGUAGES } from '@ppg/common';
import { UserRelatedStore } from './UserRelatedStore';
import { action } from 'mobx';
import { registerFullUserUseCase } from '../../useCases/auth-and-identity/RegisterFullUserUseCase';
import { registerToPPGLiteUseCase } from '../../useCases/auth-and-identity/RegisterToPPGLiteUseCase';
import { loginUseCase } from '../../useCases/auth-and-identity/LoginUseCase';
import { generateConfirmationTokenForResetPasswordUseCase } from '../../useCases/auth-and-identity/GenerateConfirmationTokenForResetPasswordUseCase';
import { registerFullUserWithPlanUseCase } from '../../useCases/auth-and-identity/RegisterFullUserWithPlanUseCase';
import { offerStore } from '../index';
import { getOauthTokenUseCase } from '../../useCases/auth-and-identity/GetOauthToken';
import { authenticateOauthUserUseCase } from '../../useCases/auth-and-identity/AuthenticateOauthUserUseCase';
import { confirmOauthUserUseCase } from '../../useCases/auth-and-identity/ConfirmOauthUserUseCase';

export class AuthorizationStore extends UserRelatedStore {
  @property()
  public acceptTerms: boolean = true;

  @property()
  public remember: boolean = false;

  @property() errors: string[] = [];

  @action
  public async login(): Promise<void> {
    const { username } = this.userStore.user;
    const { password } = this.userStore.password;

    await loginUseCase
      .exec({ username, password, remember: this.remember })
      // @todo remove token
      .then((result) => this.userStore.token = result?.token)
      .then(() => this.userStore.checkIsAuthorized())
      .then(() => this.userStore.getUserLanguage());
  }

  @action
  public async loginByToken(token: string): Promise<void> {
    this.userStore.token = token;
    localSettings.setValue('token', token);
    await this.userStore.checkIsAuthorized();
  }

  @action
  public async registerFullUser(): Promise<void> {
    await registerFullUserUseCase
      .exec({
        email: this.userStore.user.username,
        password: this.userStore.password.password,
        repeatedPassword: this.userStore.password.confirmationPassword,
        organizationName: this.userStore.user.organization,
        acceptRules: this.userStore.authorization.acceptTerms,
        language: this.userStore.lang,
        privacyPolicyCheckDate: this.userStore.user.privacyPolicyCheckDate
      })
  }

  @action
  public async registerToPPGLite(): Promise<void> {
    await registerToPPGLiteUseCase
      .exec({
        email: this.userStore.user.username,
        password: this.userStore.password.password,
        repeatedPassword: this.userStore.password.confirmationPassword,
        organizationName: this.userStore.user.organization,
        acceptRules: this.userStore.authorization.acceptTerms,
        code: offerStore.referralCode,
        offerPlan: offerStore.activeRegisterPlan.id,
        language: this.userStore.lang,
        privacyPolicyCheckDate: this.userStore.user.privacyPolicyCheckDate,
        termsOfAgreementCheckDate: this.userStore.user.termsOfAgreementCheckDate
      })
  }

  @action
  public async registerFullUserWithPlan({ currencyType, planType }): Promise<void> {
    await registerFullUserWithPlanUseCase.exec({
        email: this.userStore.user.username,
        password: this.userStore.password.password,
        repeatedPassword: this.userStore.password.confirmationPassword,
        organizationName: this.userStore.user.organization,
        acceptRules: this.userStore.authorization.acceptTerms,
        currencyType: currencyType,
        planType: planType,
        privacyPolicyCheckDate: this.userStore.user.privacyPolicyCheckDate
      })
  }

  @action
  public async loginByOauthOnet(): Promise<void> {
    await getOauthTokenUseCase.exec()
      .then((token) => {
        this.userStore._csrf = token;
        localSettings.setValue('_csrf', token);
      })
      .catch(err => err.message);
  }

  @action
  public async authenticateOauthUser(): Promise<string> {
    return await authenticateOauthUserUseCase.exec()
      .catch(err => err.message);
  }

  @action
  async confirmOauthUser(url: URL): Promise<void> {
    await confirmOauthUserUseCase.exec({ url })
      .then(async (data) => {
        const token = data.token;
        await this.loginByToken(token);
      })
      .then(() => {
        this.userStore._csrf = null;
        localSettings.setValue('_csrf', null);
      })
      .then(() => {
        const language = localSettings.getValue('lang');
        const languageToSet = language && Object.keys(LANGUAGES).includes(language) ? language : 'en';
        return this.userStore.setLanguage(languageToSet)
      })
      .catch(err => err.message);
  }

  public parseErrors(error) {
    error && [].concat(error.map(err => {
      if (typeof err === 'string') {
        this.errors.push(err);
      } else {
        this.errors.push(err.message);
      }
    }));
  }

  public async firstStepPasswordReset(): Promise<void> {
    await generateConfirmationTokenForResetPasswordUseCase.exec({ email: this.userStore.user.username });
  }
}
