import { API, UseCase } from '@ppg/common';
import { SimpleType } from "../../modelsMobx/CustomField";

export interface IGetAutomationAvailableLabelsReq {
  project: string;
}

export interface IGetAutomationAvailableLabelsRes {
  labels: {
    key: string;
    value: string;
    type: SimpleType;
  }[],
  cfLabels: {
    key: string;
    type: SimpleType;
  }[]
}

export class GetAutomationAvailableLabelsUseCase extends UseCase<IGetAutomationAvailableLabelsReq, IGetAutomationAvailableLabelsRes> {
  protected async execute({ project }: IGetAutomationAvailableLabelsReq) {
    return API.HTTP.get<IGetAutomationAvailableLabelsRes>(`project/${project}/automation/available-labels`);
  }
}
