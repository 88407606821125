import { property } from '@ppg/common';
import { WebsiteIntegrationRelated } from '../../stores/project/WebsiteIntegrationRelated';
import { updateConfirmWindowUseCase } from '../../useCases/website-integration';
import { action, computed } from 'mobx';

export interface IConfirmWindow {
  description: string;
  customHTML: string;
  logo: string;
  customButton: string;
}

export class ConfirmWindowPlugin extends WebsiteIntegrationRelated implements IConfirmWindow {
  @property() description: string;
  @property() customHTML: string;
  @property() logo: string;
  @property() customButton: string;

  constructor(confirmWindowDTO: IConfirmWindow) {
    super();
    this.description = confirmWindowDTO.description;
    this.customHTML = confirmWindowDTO.customHTML;
    this.logo = confirmWindowDTO.logo;
    this.customButton = confirmWindowDTO.customButton;
  }

  static createPlugin(confirmWindowDTO?: IConfirmWindow) {
    if (!confirmWindowDTO) {
      return ConfirmWindowPlugin.createDefault();
    } else {
      return new ConfirmWindowPlugin(confirmWindowDTO);
    }
  }

  static createDefault() {
    return new ConfirmWindowPlugin({
      logo: '',
      description: '',
      customHTML: '',
      customButton: ''
    })
  }

  @computed
  public get hasImage(): boolean {
    return !!this.logo;
  }

  @action
  public setLogo = (image: string): void => {
    this.logo = image;
  }

  public async save(): Promise<void> {
    await updateConfirmWindowUseCase.exec({
      websiteIntegrationId: this.websiteIntegrationId,
      doubleOptInDescription: this.description,
      doubleOptInCustomHTML: this.customHTML,
      doubleOptInProjectLogoURL: this.logo,
      doubleOptInCustomButton: this.customButton
    });
  }
}
