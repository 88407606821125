export interface IFontWeight {
  light: number,
  medium: number,
  bold: number,
  extra: number,
}

export const weight:IFontWeight = {
  light: 300,
  medium: 400,
  bold: 700,
  extra: 900,
}

