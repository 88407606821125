import { computed } from "mobx";
import { BrowserTypes, DeviceTypes, LanguagesTypes, SystemLabels, SystemTypes } from "../modelsMobx/SystemLabelWrapperEnums";

interface ISystemLabelWrapper {
  key: string;
  value: string | number | boolean;
}

export class SystemLabelWrapper {

  constructor(private readonly label: ISystemLabelWrapper) {

  }

  private isSystemLabelMatch = (labelTypes): boolean => {
    return !!Object.keys(labelTypes).find(key => this.label.value?.toString().toLowerCase() === labelTypes[key] || this.label.value?.toString().toLowerCase() === key);
  };

  @computed
  public get labelKey(): string {
    if (this.isSystemLabelMatch(BrowserTypes)) {
      return `${SystemLabels.BROWSER}`;
    }

    if (this.isSystemLabelMatch(SystemTypes)) {
      return `${SystemLabels.SYSTEM}`;
    }

    if (this.isSystemLabelMatch(LanguagesTypes)) {
      return `${SystemLabels.LANGUAGE}`;
    }

    if (this.isSystemLabelMatch(DeviceTypes)) {
      return `${SystemLabels.DEVICE}`;
    }

    return this.label.key.toString();
  }
}
