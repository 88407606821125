export enum ProjectRoutesTypes {
  AUTOMATION = '/automation',
  AUTOMATION_ID = '/automation/:id',
  AUTOMATION_ID_EDIT = '/automation/:id/edit',
  AUTOMATION_LIST = '/automation/list',
  AUTOMATION_NEW = '/automation/new',
  AUTOMATION_SELECTORS = '/automation/selectors',
  AUTOMATION_TAGS = '/automation/tags',
  CAMPAIGN = '/campaign',
  CAMPAIGN_LIST = '/campaign/list',
  CAMPAIGN_LIST_PUSHES = '/campaign/list/pushes',
  CAMPAIGN_LIST_PUSHES_STATE = '/campaign/list/pushes/:state',
  CAMPAIGN_LIST_PUSHES_DRAFT = '/campaign/list/pushes/draft',
  CAMPAIGN_LIST_PUSHES_READY = '/campaign/list/pushes/ready',
  CAMPAIGN_LIST_PUSHES_ALL = '/campaign/list/pushes/all',
  CAMPAIGN_LIST_PUSH = '/campaign/list/push',
  CAMPAIGN_LIST_PUSH_STATE = '/campaign/list/push/:state',
  CAMPAIGN_LIST_PUSH_DRAFT = '/campaign/list/push/draft',
  CAMPAIGN_LIST_PUSH_READY = '/campaign/list/push/ready',
  CAMPAIGN_LIST_PUSH_ALL = '/campaign/list/push/all',
  CAMPAIGN_LIST_AB = '/campaign/list/ab',
  CAMPAIGN_LIST_AB_STATE = '/campaign/list/ab/:state',
  CAMPAIGN_LIST_AB_ALL = '/campaign/list/ab/all',
  CAMPAIGN_LIST_AB_DRAFT = '/campaign/list/ab/draft',
  CAMPAIGN_PUSH = '/campaign/push',
  CAMPAIGN_PUSH_ID = '/campaign/push/:id',
  CAMPAIGN_PUSH_ID_REPORT = '/campaign/push/:id/report',
  CAMPAIGN_PUSH_ID_EDIT = '/campaign/push/:id/edit',
  CAMPAIGN_PUSH_ID_RESEND = '/campaign/push/:id/resend',
  CAMPAIGN_NEW = '/campaign/new',
  CAMPAIGN_AB_EDIT = '/campaign/ab/:id/edit',
  CAMPAIGN_AB_RESEND = '/campaign/ab/:id/resend',
  CAMPAIGN_NEW_AB = '/campaign/new/ab',
  AB_REPORT = '/campaign/ab/:id/report',
  CAMPAIGN_NEW_PUSH = '/campaign/new/push',
  CAMPAIGN_NEW_TARGETED_PUSH = '/campaign/new/targeted-push',
  CAMPAIGN_NEW_TARGETED_PUSH_BY_SEGMENT = '/campaign/new/targeted-push/send-to-segment',
  CAMPAIGN_TARGETED_PUSH = '/campaign/targeted-push',
  CAMPAIGN_TARGETED_PUSH_ID_EDIT = '/campaign/targeted-push/:id/edit',
  CAMPAIGN_TARGETED_PUSH_ID_RESEND = '/campaign/targeted-push/:id/resend',
  CAMPAIGN_TARGETED_PUSH_REPORT = '/campaign/targeted-push/:type/:id/report',
  CAMPAIGN_NEW_ROCKET = '/campaign/new/rocket',
  DASHBOARD = '/dashboard',
  DASHBOARD_VIEW = '/dashboard/view',
  EXPORT = '/export',
  EXPORT_LIST = '/export/list',
  GEOLOCATION = '/geolocation',
  HOME = '/',
  INTEGRATIONS = '/integrations',
  INTEGRATIONS_WEBHOOKS = '/integrations/webhooks',
  PHOTO_LIBRARY = '/photo-library',
  ORGANIZATION_DASHBOARD = '/organization/dashboard',
  ORGANIZATION_DASHBOARD_SUBSCRIBERS = '/organization/dashboard/subscribers',
  ORGANIZATION_DASHBOARD_CAMPAIGNS = '/organization/dashboard/campaigns',
  ORGANIZATION_DASHBOARD_AUTOMATIONS = '/organization/dashboard/automation',
  ORGANIZATION_PROJECTS_COMPARISON = '/organization/project-comparison',
  PROJECT_DASHBOARD = '/dashboard/project',
  PROJECT_DASHBOARD_SUBSCRIBERS = '/dashboard/project/subscribers',
  PROJECT_DASHBOARD_CAMPAIGNS = '/dashboard/project/campaigns',
  PROJECT_DASHBOARD_AUTOMATIONS = '/dashboard/project/automation',
  PROJECT = '/project',
  PROJECT_SETTING = '/project/settings',
  PROJECT_NEW = '/project/new',
  PROJECT_INTEGRATION = '/project/integration',
  PROJECT_PROVIDERS = '/project/providers',
  SEGMENTS = '/segments',
  SEGMENTS_NEW = '/segments/new',
  SEGMENTS_EDIT = '/segments/:id/edit',
  SUBSCRIBER = '/subscriber',
  SUBSCRIBER_LIST = '/subscriber/list',
  SUBSCRIBER_ID = '/subscriber/:id',

  SUBSCRIBERS = '/subscribers',
  SUBSCRIBER_DETAILS = '/subscribers/:id',

  TEMPLATES = '/templates',
  TEMPLATES_ELEMENTS = '/templates/elements',
  TEMPLATES_FORM_CREATOR = '/templates/form-creator',
  TEMPLATES_MSG_SIDEBAR = '/templates/msg-sidebar',
  USER_ACCESS_MANAGER = '/user/access-manager',
  USER_ACCESS_MANAGER_KEYS = '/user/access-manager/keys',
  USER_ACCESS_MANAGER_SHARE = '/user/access-manager/share',
  USER_ACCOUNT = '/user/account',
  USER_ACCOUNT_GENERAL_INFORMATION = '/user/account/general-information',
  USER_ACCOUNT_INFO = '/user/account/info',
  USER_ACCOUNT_NOTIFICATIONS = '/user/account/notifications',
  USER_ACCOUNT_SETTINGS = '/user/account/settings',
  USER_SETTINGS_TOKEN = '/user/settings/:token',
  USER_PARTNERSHIP = '/user/partnership',
}
