import { observable } from 'mobx';
import { VideoTutorial } from '../modelsMobx/VideoTutorial';
import { connector } from '../base';
import { WebUser } from '../models';
import { listActiveVideoTutorialsUseCase } from '../useCases/app-helper';

export class VideoTutorialStore {

  @observable
  public videoTutorials: VideoTutorial[] = [];

  public findMatchingVideo(pathname: string) {
    const user = connector.get<WebUser>('user');
    return this.videoTutorials && this.videoTutorials
      .find(({ componentName, lang }) => {
        const re = new RegExp(componentName);
        return lang === user.lang && (re).test(pathname);
      });
  }

  public async fetchVideoTutorials() {
    this.videoTutorials = [];
    return listActiveVideoTutorialsUseCase.exec()
      .then(({ data }) => {
        this.videoTutorials = data.map(video => {
          return new VideoTutorial({
            id: video.id,
            componentName: video.componentName,
            lang: video.lang,
            externalVideoId: video.externalVideoId,
            listing: video.listing,
          });
        });
      })
      .catch(err => console.error(err));
  }
}
