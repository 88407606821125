import { Property, property, PropertyHandler } from '@ppg/common';
import { IDisableDays } from '@ppg/styled';
import { DATE_RANGE_MONTH_LIMIT } from '../../constants';
import { IOrganizationDashboardRequestBody, PlatformType } from '../../useCases/interfaces';
import { action, computed, observable } from 'mobx';
import { getOrganizationMobileFeaturesEnabled } from '../../useCases/statistics/organization';
import { OrganizationDashboardSubscriberStore } from './OrganizationDashboardSubscriberStore';
import { OrganizationDashboardCampaignsStore } from './OrganizationDashboardCampaignsStore';
import { ActiveViewType } from '../../pages/User/Navigations/UnderlinedNavigationBar/UnderlinedNavigationBar';
import dayjs from 'dayjs';
import { OrganizationDashboardAutomationStore } from './OrganizationDashboardAutomationStore';
import { userStore } from '..';
import { ProjectComparisonStore } from './ProjectComparisonStore';
import { IChangeFactors, IRangeFactors } from '../../pages/Dashboard/common/interfaces';

export class OrganizationDashboardStore extends PropertyHandler {
  @property()
  public organizationHasMobileFeaturesEnabled: boolean = false;

  @property()
  public webPlatformEnabled: boolean = true;

  @property()
  public mobilePlatformEnabled: boolean = true;

  @property()
  public dateRangeFrom: Date = this.defaultRangeDate.from;

  @property()
  public dateRangeTo: Date = this.defaultRangeDate.to;

  @property()
  public activeTab: string = 'General';

  @property()
  public activeViewType: ActiveViewType = ActiveViewType.CHART;

  @property()
  public exportSubscribers: boolean = true;

  @property()
  public exportCampaigns: boolean = false;

  @property()
  public exportAutomations: boolean = false;

  @observable
  public isExportModalEnabled: boolean = false;

  @observable
  public timezone: string = '';

  public subscriber: OrganizationDashboardSubscriberStore;
  public campaigns: OrganizationDashboardCampaignsStore;
  public automation: OrganizationDashboardAutomationStore;
  public projectComparisonStore: ProjectComparisonStore;

  constructor() {
    super();
    this.subscriber = new OrganizationDashboardSubscriberStore(this);
    this.campaigns = new OrganizationDashboardCampaignsStore(this);
    this.automation = new OrganizationDashboardAutomationStore(this);
    this.projectComparisonStore = new ProjectComparisonStore(this);
    this.setTimezone();
  }

  public isPlatformSelected = (): boolean => {
    return this.webPlatformEnabled || this.mobilePlatformEnabled;
  };

  public setTimezone = (): void => {
    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  @action
  public async fetchOrganizationMobileFeatures(): Promise<void> {
    const { hasMobileSettingsEnabled} = await getOrganizationMobileFeaturesEnabled.exec(this.organizationRequestBody);
    this.organizationHasMobileFeaturesEnabled = hasMobileSettingsEnabled;
  }

  @computed
  public get defaultRangeDate() {
    return {
      from: dayjs().subtract(6, 'days').startOf('day').toDate(),
      to: dayjs().endOf('day').toDate(),
    }
  }

  @computed
  public get disabledDays(): IDisableDays {
    const maxRangeTo = dayjs(this.dateRangeFrom).add(DATE_RANGE_MONTH_LIMIT, 'months');
    const defaultTo = this.defaultRangeDate.to;
    return {
      before: dayjs(this.dateRangeTo).subtract(DATE_RANGE_MONTH_LIMIT, 'months').startOf('day').toDate(),
      after: maxRangeTo.isAfter(defaultTo) ? defaultTo : maxRangeTo.toDate(),
    }
  }

  @action
  public enableAllPlatforms = (): void => {
    this.webPlatformEnabled = true;
    this.mobilePlatformEnabled = true;
  };

  @action
  public enableExportModal = (): void => {
    this.isExportModalEnabled = true;
  };

  @action
  public disableExportModal = (): void => {
    this.isExportModalEnabled = false;
  };

  @computed
  public get bothPlatformsEnabled(): boolean {
    return this.webPlatformEnabled && this.mobilePlatformEnabled
  }

  /**
   * Assertion for endpoints where data is not distinguished by platform type - received data from wrapped fetch with this function will be visible only
   * when both platforms types are selected
   * @param callback
   * @param PropertyToClear
   * @param emptyValue
   */

  @action
  public undifferentiatedPlatforms = async (callback: () => Promise<void>, PropertyToClear: Property, emptyValue: any = []): Promise<void> => {
    if (!this.bothPlatformsEnabled) {
      PropertyToClear.setValue(emptyValue);
      return;
    }

    await callback();
  }

  @computed
  public get platformType(): PlatformType | null {
    if (this.bothPlatformsEnabled) {
      return null;
    }

    if (this.webPlatformEnabled) {
      return PlatformType.WEB;
    }

    if (this.mobilePlatformEnabled) {
      return PlatformType.MOBILE;
    }
  }

  @computed
  get rangeChangeFactors(): IRangeFactors {
    return {
      from: this.dateRangeFrom.toISOString(),
      to: this.dateRangeTo.toISOString()
    };
  }

  @computed
  get platformChangeFactors(): IChangeFactors {
    return {
      web: this.webPlatformEnabled,
      mobile: this.mobilePlatformEnabled,
    };
  }

  @computed
  public get organizationRequestBody(): IOrganizationDashboardRequestBody {
    return {
      organization: userStore.user.organization, //TODO: use user related
      platform: this.platformType,
      from: this.dateRangeFrom.toISOString(),
      to: this.dateRangeTo.toISOString(),
      timezone: this.timezone,
    };
  }

  @computed
  public get organization(): string {
    return userStore.user.organization;
  }
}
