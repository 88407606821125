import { property, PropertyHandler } from '@ppg/common';
import { observable, set } from 'mobx';

export enum ImageType {
  LOGO = 'logo',
  SCREENSHOT = 'screenshot',
  PUSH_ICON = 'push_icon',
  PUSH_IMAGE = 'push_image',
  CONFIRM_IMAGE = 'confirm_image',
  PHOTO_LIBRARY_PUSH_ICON = 'photo_library_push_icon',
  PHOTO_LIBRARY_PUSH_IMAGE = 'photo_library_push_image',
  FORM_IMAGE = 'form_image'
}

export enum ImageFormatType {
  SMALL = 'small',
  MEDIUM = 'medium',
  NORMAL = 'normal',
  ORIGINAL = 'original',
  DUMMY = 'dummy',
  /**
   * @deprecated
   * only for legacy images
   */
  LEGACY_ORIGINAL = '',
}

export class ImageFormat {
  constructor(
    public type: ImageFormatType,
    public url: string,
  ) {
  }
}

export interface IImage {
  id: string;
  owner: string;
  name: string;
  type: ImageType;
  formats: ImageFormat[];
  createdAt: string;
}

export class Image extends PropertyHandler {

  @observable public initialValues:IImage;
  @property() public id: string;
  @property() public owner: string;
  @property() public name: string;
  @property() public type: ImageType;
  @property() public formats: ImageFormat[];
  @property() public createdAt: string;

  constructor(image: IImage) {
    super();
    this.id = image.id;
    this.owner = image.owner;
    this.name = image.name;
    this.type = image.type;
    this.formats = image.formats;
    this.createdAt = image.createdAt;
    this.initialValues = image;
  }

  public getFormatByType(imageFormatType: ImageFormatType): ImageFormat | null {
    const imageFormat = this.formats.find((format) => {
      return format.type === imageFormatType;
    });

    if (!imageFormat) {
      return null;
    }

    return imageFormat;
  }

  public static createNewImage(image?: IImage) {
    if (image) return new Image(image);
    return new Image({
      owner: null,
      name: '',
      formats: [],
      createdAt: null,
      id: null,
      type: ImageType.PHOTO_LIBRARY_PUSH_IMAGE
    })
  }

  public getURL(imageFormatType: ImageFormatType): string {
    const imageFormat = this.getFormatByType(imageFormatType);

    if (!imageFormat) {
      const originalFormat = this.getFormatByType(ImageFormatType.ORIGINAL);

      if (!originalFormat) {
        const legacyFormat = this.getFormatByType(ImageFormatType.LEGACY_ORIGINAL);

        return legacyFormat?.url || '';
      }

      return originalFormat.url;
    }

    return imageFormat.url;
  }

  public restore():void {
    set(this, this.initialValues)
  }

  public get url(): string {
    return this.getURL(ImageFormatType.MEDIUM);
  }
}
