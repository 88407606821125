import { API, UseCase } from '@ppg/common';

interface ISetRecurring {
  organization: string;
  cardId: string;
}

export class SetRecurringCardUseCase extends UseCase<ISetRecurring, void> {
  protected async execute({ organization, cardId }: ISetRecurring) {
    return API.HTTP.post<void>(`acc/organizations/${ organization }/payments/cards/recurring`, {
      card: cardId
    })
  }
}

export const setRecurringCardUseCase = new SetRecurringCardUseCase();
