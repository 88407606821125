import cs from 'classnames';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { t } from '../../base/helpers';
import { PaymentsStore } from '../../stores/payment/PaymentsStore';

interface IPaymentsBalanceDetails {
  paymentsStore?: PaymentsStore;
}

@inject('paymentsStore')
@observer
export class PaymentsBalanceDetails extends React.Component<IPaymentsBalanceDetails, {}> {

  public render() {
    const { paymentsStore } = this.props;

    return (
      <div className='payments-balance-details-wrapper row'>
        <div className="well balance-details-wrapper">
          <div className="balance-desc">
            { t('Your balance') }:
          </div>
          <div className="balances-wrapper">
            {
              paymentsStore.accountDetails.length > 0
              && paymentsStore.accountDetails.map(detail => {
                return <div key={ detail.id } className={ cs('balance-amount', {
                  'text-green': detail.balance >= 0,
                  'text-red': detail.balance < 0,
                }) }>
                  { (detail.balance / 100).toFixed(2) } { detail.currency }
                </div>
              })
            }
          </div>
        </div>

      </div>
    )
  }
}
