import { BarSvgProps } from '@nivo/bar';
import { campaignTypeColors } from '../LineChart/defaultValues';

export const DEFAULT_BAR_CHART_PROPERTIES: BarSvgProps = {
  colors: campaignTypeColors,
  data: [],
  defs: [],
  fill: [],
  margin: {
    "top": 50,
    "right": 50,
    "bottom": 75,
    "left": 70
  },
  groupMode: "grouped",
  layout: "horizontal",
  axisTop: null,
  axisRight: null,
  axisBottom: {
    "tickSize": 3,
    "tickPadding": 11,
    "tickRotation": 0,
    "legend": "axis bottom",
    "legendPosition": "end",
    "legendOffset": 37
  },
  axisLeft: {
    "tickSize": 5,
    "tickPadding": 5,
    "tickRotation": 0,
    "legend": "axis left",
    "legendPosition": "end",
    "legendOffset": -50
  },
  enableGridX: true,
  enableLabel: false,
  labelSkipWidth: 12,
  labelSkipHeight: 10,
  labelTextColor: "inherit:darker(1.6)",
  animate: true,
  motionStiffness: 90,
  motionDamping: 15,
  legends: [
    {
      "dataFrom": "keys",
      "anchor": "bottom-left",
      "direction": "row",
      "justify": false,
      "translateX": -9,
      "translateY": 59,
      "itemsSpacing": 2,
      "itemWidth": 100,
      "itemHeight": 20,
      "itemDirection": "left-to-right",
      "symbolSize": 20,
    }
  ]
};

