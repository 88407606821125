import { API, UseCase } from '@ppg/common';

interface AddLegacyApiKeyData {
  organization: string;
  /**
   * Projects identifiers (ID)
   */
  projects: string[];

  expiresAt: string;
}

export class AddLegacyApiKeyUseCase extends UseCase<AddLegacyApiKeyData, void> {
  protected async execute({ projects, expiresAt, organization }) {
    return API.HTTP.post<void>(`aai/organizations/${ organization }/api-keys`, { projects, expiresAt });
  }
}

export const addLegacyApiKeyUseCase = new AddLegacyApiKeyUseCase();
