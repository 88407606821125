import { PropertyHandler, property } from "@ppg/common";
import { IProviderAction } from '../../useCases/core/providers/GetProjectProviderUseCase';
import { observable } from 'mobx';

interface IVapidKeys {
  publicKey: string;
  privateKey: string;

  actions: IProviderAction[];
}

export class VapidKeys extends PropertyHandler implements IVapidKeys {
  @property() public publicKey;
  @property() public privateKey;

  @observable public actions;

  constructor({ publicKey, privateKey, actions }: IVapidKeys) {
    super();
    this.publicKey = publicKey;
    this.privateKey = privateKey;
    this.actions = actions;
  }

  static createProvider(VapidDTO?: IVapidKeys): VapidKeys {
    if (VapidDTO) {
      return new VapidKeys(VapidDTO);
    } else {
      return VapidKeys.createDefault();
    }
  }

  static createDefault(): VapidKeys {
    return new VapidKeys({
      privateKey: '',
      publicKey: '',
      actions: []
    });
  }
}
