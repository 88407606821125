import { API, UseCase } from '@ppg/common';
import { ICampaignSentHourStats, IProjectDashboardRequestBody } from '../../../interfaces';
import { DashboardCampaignType } from '../../../../pages/Dashboard/common/enums';

interface IGetCampaignsSendHoursRequest extends IProjectDashboardRequestBody {
  campaignType: DashboardCampaignType;
}

interface IGetProjectSendHoursResult {
  campaignSentHourStats: ICampaignSentHourStats[];
}

export class GetProjectSendHoursUseCase extends UseCase<IGetCampaignsSendHoursRequest, IGetProjectSendHoursResult> {
  protected async execute({ platform, from, to, timezone, campaignType }: IGetCampaignsSendHoursRequest) {
    const platformType = platform ? `/${ platform }` : '';
    let query = '?';
    query += `&from=${ from }&to=${ to }`;
    query += `&timezone=${ timezone }`;

    return API.HTTP.get<IGetProjectSendHoursResult>(
      `statistics/v2/projects/${ API.HTTP.currentProjectId }/campaigns/dashboard/statistics/${ campaignType }/hours/sent${ platformType }${ query }`
    );
  };
}

export const getProjectSendHoursUseCase = new GetProjectSendHoursUseCase();
