import { ElementIdType } from "../../SubscriptionFormEnums";

export const defaultStylesMap = {
  [ElementIdType.FORM]: {
    background: '#dedede',
    borderRadius: '3px',
    border: '1px solid #bababa',
    padding: '15px',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.3)',
    minWidth: '350px',
    maxWidth: '350px',
    direction: 'ltr',
    marginRight: '10px',
    position: 'fixed',
    zIndex: 999999999999,
    top: '10px',
    lineHeight: '1.2',
    fontFamily: 'Helvetica',
    fontSize: '13px',
    color: '#2d2d2d',
    left: '83px',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '10px',
      height: '10px',
      transform: 'rotate(45deg)',
      background: '#bababa',
      top: '-6px',
      left: '10px'
    },
    '&:after': {
      marginTop: '2px',
      background: '#dedede',
      content: '""',
      position: 'absolute',
      width: '10px',
      height: '10px',
      transform: 'rotate(45deg)',
      top: '-6px',
      left: '10px'
    },
    '@media (max-width: 480px)': {
      minWidth: '0px',
      width: 'calc(100% - 10px)',
      left: '0px',
      boxSizing: 'border-box',
      padding: '15px 10px',
      marginRight: '5px',
      marginLeft: '5px',
    },
  },
  [ElementIdType.CONTENT]: {
  },
  [ElementIdType.ICON]: {
    width: '18px',
    height: '18px',
    display: 'inline-block',
    fontSize: '18px',
    marginRight: '10px',
    marginLeft: 'auto',
    '@media (max-width: 590px)': {
    },
    '@media (max-width: 480px)': {
    },
  },
  [ElementIdType.SVG]: {},
  [ElementIdType.HEADER]: {
    textAlign: 'left',
    verticalAlign: 'top',
    fontSize: '13px',
    marginTop: '5px',
    '@media (max-width: 480px)': {
    }
  },
  [ElementIdType.DESCRIPTION]: {
    fontSize: '13px',
    textAlign: 'left',
    lineHeight: '1.5',
    margin: '5px 0',
    '@media (max-width: 480px)': {
    }
  },
  [ElementIdType.BUTTONS]: {
    textAlign: 'right',
    marginBottom: '5px',
    '@media (max-width: 480px)': {
    },
  },
  [ElementIdType.ALLOW]: {
    background: '#f9f9f9',
    border: '1px solid #A8A8A8',
    borderBottomColor: '#A8A8A8',
    borderBottom: '1px solid #A8A8A8',
    height: '30px',
    lineHeight: '30px',
    padding: '0 20px',
    display: 'inline-block',
    borderRadius: '2px',
    textDecoration: 'none',
    backgroundImage: 'none',
    color: '#2d2d2d',
    cursor: 'pointer',
    marginLeft: '5px',
    marginBottom: '3px',
    fontWeight: 400,
    fontSize: '13px',
    transition: 'none',
    overflow: 'visible',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    position: 'relative',
    verticalAlign: 'baseline',
    boxShadow: 'inset 0px -7px 18px -7px rgba(0, 0, 0, 0.19)',
    '&:link, &:visited, &:active, &:hover': {
      color: '#2d2d2d',
      textDecoration: 'none',
    },
    '&::after, &::before': {
      content: 'none'
    },
  },
  [ElementIdType.DENY]: {
    background: '#f9f9f9',
    border: '1px solid #A8A8A8',
    borderBottomColor: '#A8A8A8',
    borderBottom: '1px solid #A8A8A8',
    height: '30px',
    lineHeight: '30px',
    padding: '0 20px',
    display: 'inline-block',
    textDecoration: 'none',
    borderRadius: '2px',
    backgroundImage: 'none',
    color: '#2d2d2d',
    cursor: 'pointer',
    marginLeft: '5px',
    marginBottom: '3px',
    fontWeight: 400,
    fontSize: '13px',
    transition: 'none',
    overflow: 'visible',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    position: 'relative',
    verticalAlign: 'baseline',
    boxShadow: 'inset 0px -7px 18px -7px rgba(0, 0, 0, 0.19)',
    '&:link, &:visited, &:active, &:hover': {
      color: '#2d2d2d',
      textDecoration: 'none',
    },
    '&::after, &::before': {
      content: 'none'
    },
  },
  [ElementIdType.POWERED_BY]: {
    position: 'absolute',
    bottom: '3px',
    fontSize: '10px',
    '@media (max-width: 480px)': {
      fontSize: '9px',
    }
  },
  [ElementIdType.POWERED_BY_TEXT]: {
    color: '#70787f',
    textDecoration: 'none',
    display: 'inline',
    fontSize: '10px',
    fontWeight: 400,
    transition: 'none',
    verticalAlign: 'baseline',
    letterSpacing: 'normal',
    position: 'relative',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.2',
    borderBottomColor: 'transparent',
    borderBottom: 'none',
    '&:link, &:visited, &:active': {
      color: '#70787f',
      textDecoration: 'none',
    },
    '&::after, &::before': {
      content: 'none'
    },
    '@media (max-width: 480px)': {
      bottom: '3px',
      fontSize: '9px',
    },
  }
};

export const defaultRtlStylesMap = {
  [ElementIdType.FORM]: {
    background: '#dedede',
    borderRadius: '3px',
    border: '1px solid #bababa',
    padding: '15px',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.3)',
    minWidth: '350px',
    maxWidth: '350px',
    direction: 'rtl',
    marginRight: '10px',
    position: 'fixed',
    zIndex: 999999999999,
    top: '10px',
    lineHeight: '1.2',
    fontFamily: 'Helvetica',
    fontSize: '13px',
    color: '#2d2d2d',
    left: '83px',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '10px',
      height: '10px',
      transform: 'rotate(45deg)',
      background: '#bababa',
      top: '-6px',
      left: '10px'
    },
    '&:after': {
      marginTop: '2px',
      background: '#dedede',
      content: '""',
      position: 'absolute',
      width: '10px',
      height: '10px',
      transform: 'rotate(45deg)',
      top: '-6px',
      left: '10px'
    },
    '@media (max-width: 480px)': {
      minWidth: '0px',
      width: 'calc(100% - 10px)',
      left: '0px',
      boxSizing: 'border-box',
      padding: '15px 10px',
      marginRight: '5px',
      marginLeft: '5px',
    },
  },
  [ElementIdType.CONTENT]: {},
  [ElementIdType.ICON]: {
    width: '18px',
    height: '18px',
    display: 'inline-block',
    fontSize: '18px',
    marginLeft: '10px',
    marginRight: 'auto',
    '@media (max-width: 590px)': {},
    '@media (max-width: 480px)': {},
  },
  [ElementIdType.SVG]: {},
  [ElementIdType.HEADER]: {
    textAlign: 'right',
    verticalAlign: 'top',
    fontSize: '13px',
    marginTop: '5px',
    '@media (max-width: 480px)': {}
  },
  [ElementIdType.DESCRIPTION]: {
    fontSize: '13px',
    textAlign: 'right',
    lineHeight: '1.5',
    margin: '5px 0',
    '@media (max-width: 480px)': {}
  },
  [ElementIdType.BUTTONS]: {
    textAlign: 'left',
    marginBottom: '5px',
    '@media (max-width: 480px)': {},
  },
  [ElementIdType.ALLOW]: {
    background: '#f9f9f9',
    border: '1px solid #A8A8A8',
    borderBottomColor: '#A8A8A8',
    borderBottom: '1px solid #A8A8A8',
    height: '30px',
    lineHeight: '30px',
    padding: '0 20px',
    display: 'inline-block',
    borderRadius: '2px',
    textDecoration: 'none',
    color: '#2d2d2d',
    cursor: 'pointer',
    marginLeft: '5px',
    marginBottom: '3px',
    fontWeight: 400,
    fontSize: '13px',
    transition: 'none',
    overflow: 'visible',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    position: 'relative',
    verticalAlign: 'baseline',
    boxShadow: 'inset 0px -7px 18px -7px rgba(0, 0, 0, 0.19)',
    '&:link, &:visited, &:active, &:hover': {
      color: '#2d2d2d',
      textDecoration: 'none',
    },
    '&::after, &::before': {
      content: 'none'
    },
  },
  [ElementIdType.DENY]: {
    background: '#f9f9f9',
    border: '1px solid #A8A8A8',
    borderBottomColor: '#A8A8A8',
    borderBottom: '1px solid #A8A8A8',
    height: '30px',
    lineHeight: '30px',
    padding: '0 20px',
    display: 'inline-block',
    textDecoration: 'none',
    borderRadius: '2px',
    color: '#2d2d2d',
    cursor: 'pointer',
    marginLeft: '5px',
    marginBottom: '3px',
    fontWeight: 400,
    fontSize: '13px',
    transition: 'none',
    overflow: 'visible',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    position: 'relative',
    verticalAlign: 'baseline',
    boxShadow: 'inset 0px -7px 18px -7px rgba(0, 0, 0, 0.19)',
    '&:link, &:visited, &:active, &:hover': {
      color: '#2d2d2d',
      textDecoration: 'none',
    },
    '&::after, &::before': {
      content: 'none'
    },
  },
  [ElementIdType.POWERED_BY]: {
    position: 'absolute',
    bottom: '3px',
    fontSize: '10px',
    '@media (max-width: 480px)': {
      fontSize: '9px',
    }
  },
  [ElementIdType.POWERED_BY_TEXT]: {
    color: '#70787f',
    textDecoration: 'none',
    display: 'inline',
    fontSize: '10px',
    fontWeight: 400,
    transition: 'none',
    verticalAlign: 'baseline',
    letterSpacing: 'normal',
    position: 'relative',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.2',
    borderBottomColor: 'transparent',
    borderBottom: 'none',
    '&:link, &:visited, &:active': {
      color: '#70787f',
      textDecoration: 'none',
    },
    '&::after, &::before': {
      content: 'none'
    },
    '@media (max-width: 480px)': {
      bottom: '3px',
      fontSize: '9px',
    },
  }
};

export const poweredProperties = {
  href: 'https://pushpushgo.com/en/?utm_medium=form&utm_source=https://n.porannakawka.com/&utm_campaign=referal',
  target: '_blank'
};

export const svgDefaultProperties = {
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  width: '16',
  height: '16',
  viewBox: '0 0 32 32',
};

export const path1 = {
  fill: '#000',
  d: 'M17.629 8.567c0 0.9-0.729 1.629-1.629 1.629v0c-0.9 0-1.63-0.729-1.63-1.629v-3.716c0-0.9 0.73-1.63 1.63-1.63v0c0.9 0 1.629 0.729 1.629 1.63v3.716z',
};

export const path2 = {
  fill: '#000',
  d: 'M13.129 25.909c0 1.586 1.286 2.871 2.871 2.871s2.872-1.285 2.872-2.871v-0.404h-5.742v0.404z',
};

export const path3 = {
  fill: '#000',
  d: 'M26.246 20.096c0-0.161-0.121-0.292-0.27-0.292-1.423-0.224-3.537-0.582-3.769-4.41-0.603-9.938-5.54-10.107-6.206-10.090-0.668-0.017-5.605 0.152-6.207 10.090-0.232 3.828-2.346 4.187-3.77 4.41-0.148 0-0.269 0.131-0.269 0.292v3.793c0 0.011 0.002 0.024 0.003 0.036l-0.003 0.051h0.014c0.034 0.118 0.134 0.206 0.255 0.206h19.952c0.122 0 0.222-0.087 0.256-0.206h0.014l-0.003-0.051c0.002-0.012 0.003-0.024 0.003-0.036v-3.793z',
};

