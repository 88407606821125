import * as React from 'react';
import { EmptyDataComponent, Loader, Modal, SearchInput, Select, toast, ConfirmIconButton, TypeSizeBtn } from '@ppg/styled';
import { inject, observer } from 'mobx-react';
import { AccessManagerStore } from '../../../stores/AccessManagerStore';
import { t } from '../../../base/helpers';
import { observable } from 'mobx';
import { AccessProject } from '../../../modelsMobx/AccessProject';
import { AccessManagerShareProjectModal } from './AccessManagerShareProjectModal';
import './AccessManager.scss';
import { WrappedPagination } from '../../../assets/wrapped';
import { ProjectStore } from '../../../stores/project/ProjectStore';
import { UserHeader } from '../Layouts/UserLayout/UserHeader/UserLayoutHeader';
import ReactTooltip from 'react-tooltip';
import { isArrayEmpty } from '@ppg/common';

interface IAccessKeyManagerShareProps {
  accessManagerStore: AccessManagerStore;
  projectStore?: ProjectStore;
}

@inject('accessManagerStore', 'projectStore')
@observer
export class AccessManagerSharedProjects extends React.Component<IAccessKeyManagerShareProps> {

  @observable private isShareProjectModalOpen: boolean = false;

  public get projectOptions() {
    const options = this.props.projectStore.userOwnedProjects.map((project) => {
      return {
        name: project.name,
        value: project.id,
      };
    });
    options.unshift({ name: 'Select project', value: '' });
    return options;
  }

  public componentWillUnmount(): void {
    this.props.accessManagerStore.setDefaultValues();
  }

  public async componentDidMount() {
    await this.props.accessManagerStore.fetchSharedProjects();
  }

  public componentDidUpdate(): void {
    ReactTooltip.rebuild();
  }

  private handleSearch = (e): void => {
    const { accessManagerStore } = this.props;

    accessManagerStore.getProperty('search').setValue(e.target.value);
    !accessManagerStore.search && accessManagerStore.getProperty('isLoading').setValue(true);
  };

  private async handleDeleteSharedProject(accessProject: AccessProject): Promise<void> {
    ReactTooltip.hide()
    await this.props.accessManagerStore.revokeSharedProject(accessProject.id)
  }

  public render() {
    const { accessManagerStore, projectStore } = this.props;

    const shareProjectButton = {
      size: "medium",
      icon: "plus-badge",
      content: t('user/access-manager/share::Share Project'),
      onClick: () => this.openShareProjectModal(),
    };

    return (
      <div className="section-access-projects-wrapper">
        <UserHeader title={ t('Shared projects') } subtitle={ t('user/access-manager/keys::Manage access to projects and generate API keys') } button={ shareProjectButton }/>
        <div className="share-project-header">
          <div className="share-project-header-filters">
            <div className="share-project-header-filters-search">
              <SearchInput value={ accessManagerStore.getProperty('search').getValue() }
                           placeholder={ t('user/access-manager/share::Search project by e-mail...') }
                           handleSearch={ this.handleSearch }/>
            </div>
            <div className="share-project-header-filters-select">
              <Select property={ accessManagerStore.getProperty('projectSelect') } options={ this.projectOptions }/>
            </div>
          </div>
        </div>
        {
          accessManagerStore.isLoading ? <Loader/> : (!isArrayEmpty(accessManagerStore.accessProjects))
            ? <table className="access-projects-table">
              { this.renderTableHeader() }
              <tbody>
              {
                accessManagerStore.accessProjects.map((accessProject) => {
                  return (
                    <tr className="p10 borderb" key={ accessProject.id }>
                      <td data-label={ t('user/access-manager/share::E-mail') }> { accessProject.username }</td>
                      <td data-label={ t('user/access-manager/share::Project') }> { projectStore.getProjectNameByID(accessProject.project) } </td>
                      <td className="delete-cell">
                        <ConfirmIconButton icon={ 'trash' }
                                           typeBtn="white"
                                           positionBtn="row"
                                           size={ TypeSizeBtn.MEDIUM }
                                           dataTip={ t('user/access-manager/share::Delete shared project') }
                                           dataTipConfirmBtn={ t('user/access-manager/share::Confirm delete shared project') }
                                           dataTipCancelBtn={ t('user/access-manager/share::Cancel delete shared project') }
                                           onConfirm={ () => this.handleDeleteSharedProject(accessProject)}/>
                      </td>
                    </tr>
                  );
                })
              }
              </tbody>
            </table>
            : this.renderEmptyDataComponent()
        }

        <WrappedPagination total={ accessManagerStore.getProperty('sharedProjectsTotal') }
                           offsetProperty={ accessManagerStore.getProperty('sharedProjectsOffset') }
                           itemsPerPage={ accessManagerStore.getProperty('perPage') }
                           perPageSelector={ true }
                           infinityScroll={ accessManagerStore.getProperty('infinity').getValue() }/>

        <Modal show={ this.isShareProjectModalOpen }
               title={ t('user/access-manager/share::Share Project') }
               content={ <AccessManagerShareProjectModal newAccessProject={ accessManagerStore.newAccessProject }/> }
               onCancel={ () => this.closeShareProjectModal() }
               onConfirm={ () => this.shareProjects() }
               confirmButton={ t('user/access-manager/share::Save') }
               cancelButton={ t('user/access-manager/share::Cancel') }
               width={ 500 }
               showOverflow={ false }
               contentHeight={ 1000 }
        />
        <ReactTooltip type="light" effect="solid" place="top"/>
      </div>
    );
  }

  private openShareProjectModal(): void {
    this.isShareProjectModalOpen = true;
  }

  private closeShareProjectModal(): void {
    this.isShareProjectModalOpen = false;
  }

  private async shareProjects(): Promise<void> {
    if (!this.props.accessManagerStore.newAccessProject.selectedUsers.length) {
      toast.error('user/access-manager/share::You didn\'t provide any user');
      return;
    }

    this.props.accessManagerStore.shareProjectsToMany()
      .then(() => {
        this.isShareProjectModalOpen = false;
        this.props.accessManagerStore.newAccessProject = new AccessProject({});
      }).then(() => toast.success(t('user/access-manager/share::Data received by the server. If such a user exists, we will inform them of the access granted')));
  }

  private renderTableHeader() {
    return <thead className="borderb p10">
    <tr>
      <th className="primary">{ t('user/access-manager/share::E-mail') }</th>
      <th className="primary">{ t('user/access-manager/share::Project') }</th>
      <th className="primary"/>
    </tr>
    </thead>;
  }

  private renderEmptyDataComponent = (): JSX.Element => {
    return this.props.accessManagerStore.search ?
      <EmptyDataComponent
        content={ t('user/access-manager/share::You don\'t have any shared projects that meet the criteria') }
        icon={ 'projects' }
        hideButton/> :
      <EmptyDataComponent
        content={ t('user/access-manager/share::You don\'t have any shared projects') }
        buttonContent={ t('user/access-manager/share::share project') }
        icon={ 'projects' }
        buttonIcon={ 'plus-badge' }
        onClick={ () => this.openShareProjectModal() }
      />;
  };
}

