import { API, UseCase } from '@ppg/common';
import { getPlatformTypeParam, getRangeDateParam, getTimezoneParam } from '../../../helpers';
import { IGetSubscribedActivityResult, IProjectDashboardRequestBody } from '../../../interfaces';

export class GetProjectSubscribedActivityUseCase extends UseCase<IProjectDashboardRequestBody, IGetSubscribedActivityResult> {
  protected async execute({ platform, from, to, timezone }: IProjectDashboardRequestBody) {
    const endpoint = `statistics/v2/projects/${ API.HTTP.currentProjectId }/subscribers/dashboard/activity`
      + getPlatformTypeParam(platform) + getRangeDateParam(from, to) + getTimezoneParam(timezone);

    return API.HTTP.get<IGetSubscribedActivityResult>(endpoint);
  }
}

export const getProjectSubscribedActivityUseCase = new GetProjectSubscribedActivityUseCase();
