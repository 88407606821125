import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { UserStore } from '../../stores/user/UserStore';
import { CardsStore } from '../../stores/payment/CardsStore';
import { t } from '../../base/helpers';
import { Loader, Modal, toast } from '@ppg/styled';
import './AccountBlocker.scss'
import PaymentsCards from '../../pages/Payments/PaymentsCards';
import { observable } from 'mobx';
import { Button, ButtonTypes } from '@ppg/styled';

interface IProjectLayoutComponentProps {
  userStore?: UserStore;
  cardsStore?: CardsStore;
}

@inject('userStore', 'cardsStore')
@observer
export class AccountBlocker extends React.Component<IProjectLayoutComponentProps> {
  @observable showPaymentStatus: boolean = false;
  @observable isStatusLoading: boolean = false;

  private get isAccountBlocked() {
    return this.props.userStore.user.blocked && this.props.userStore.isLite
  }

  componentDidMount() {
    this.isAccountBlocked && this.props.cardsStore.requestToAddCard();
  }

  private chargeCard = async ():Promise<void> => {
    const { userStore, cardsStore } = this.props
    await cardsStore.chargePPGLiteUser().then(() => {
      toast.success(`@lite::Card charged successfully`);
      this.showPaymentStatus = true
    })
    await userStore.fetchUserDetail()
  }

  private checkAccountBlockStatus = async ():Promise<void> => {
    const { userStore } = this.props

    this.isStatusLoading = true;
    this.showPaymentStatus = false;

    await userStore.fetchUserDetail().then(() => this.showPaymentStatus = true);
    if(!userStore.user.blocked){
      toast.success(t('@lite::Your subscription has been successfully renewed'))
    }
    this.isStatusLoading = false;
  }
  public render() {
    if (!this.isAccountBlocked || this.props.cardsStore.isSecretLoading) {
      return null;
    }

    return <>
      <Modal
        content={ <div className={ 'accountBlocker__content' }>
          <span> { t('@lite::Unfortunately we were not able to debit your account for the next month. Please charge your account or add a new card with sufficient funds on your account.') }</span>
          <span className={ 'icon-lock size70 m30t m20b' }/>
          <Button content={'Check payment status'}
                  type={ ButtonTypes.DEFAULT_GREEN }
                  onClick={ this.checkAccountBlockStatus }/>
          { this.isStatusLoading && <Loader /> }
          { this.showPaymentStatus && <span className={ 'text-red m20t size16' }>
            { t('@lite::Payment has not been processed yet (it may take several minutes)') }
            <p> { t('@lite::Please make sure you have added a card with sufficient funds and it has the recurring payment option checked ') }</p>
          </span> }
          <PaymentsCards hideAddCardButton/>
        </div> }
        title={ <h1>{ t('Unpaid subscription') }</h1> }
        onConfirm={ () => this.props.cardsStore.enableCardModal() }
        width={ 620 }
        confirmButton={ t('@lite::Add new card') }
        onCancel={ this.chargeCard }
        cancelButton={ t('@lite::Charge card manually') }
        disableCloseOnEscape
        hideCloseButton
        show={ true }/>

    </>
  }
}

