import * as React from 'react';
import { t } from '../../../base/helpers';
import "./WordCounter.scss";

export interface IWordCounter {
  words: string;
  signLimit: number;
}

function countSigns({ words, signLimit }) {
  const content = words || '';
  const limit = signLimit || 0;
  const signNumber = content.split('').length;
  const signsLeft = limit - signNumber;
  if (signsLeft >= 0) {
    return signsLeft;
  } else {
    return <p className="m0">{t ('You have exceeded suggested character limit ')}<span className="icon-heart-broken size14 m5l"/></p>;
  }
}

export const WordCounter = (props: IWordCounter) => <div className="word-counter-wrapper">{ countSigns(props) }</div>;
