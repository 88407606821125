import { API, UseCase } from '@ppg/common';
import { getPlatformTypeParam, getRangeDateParam, getTimezoneParam } from '../../../helpers';
import { IGetActiveSubscribersInRangeResult, IProjectDashboardRequestBody } from '../../../interfaces';

export class GetProjectActiveSubscribersInRangeResult extends UseCase<IProjectDashboardRequestBody, IGetActiveSubscribersInRangeResult> {
  protected async execute({ platform, from, to, timezone }: IProjectDashboardRequestBody) {
    let rangeEndpoint = `statistics/v2/projects/${ API.HTTP.currentProjectId }/subscribers/dashboard/active/range`
      + getPlatformTypeParam(platform) + getRangeDateParam(from, to) + getTimezoneParam(timezone);

    return API.HTTP.get<IGetActiveSubscribersInRangeResult>(rangeEndpoint);
  }
}

export const getProjectActiveSubscribersInRangeResult = new GetProjectActiveSubscribersInRangeResult();
