import { API, UseCase } from "@ppg/common";
import { ICampaignId, IGetSegmentsPushCampaign } from '../interfaces';

interface IGetSegmentedPushRequest extends ICampaignId {}
interface IGetSegmentedPushResponse extends IGetSegmentsPushCampaign {}

export class GetSegmentedPushUseCase extends UseCase<IGetSegmentedPushRequest, IGetSegmentedPushResponse> {
  protected async execute({ campaignId }: IGetSegmentedPushRequest) {
    return API.HTTP.get<IGetSegmentedPushResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/segmented/${ campaignId }`);
  }
}

export const getSegmentedPushUseCase = new GetSegmentedPushUseCase();
