import { ILineChartProps, LineChartData } from '../../../../components/Charts/LineChart/LineChart';
import { FunnelDatum } from '@nivo/funnel/dist/types/types';
import { BarSvgProps } from '@nivo/bar';
import { FunnelSvgProps } from '@nivo/funnel';
import { IChangeFactors, IRangeFactors } from '../interfaces';

export enum ChartType {
  LINE = 'line',
  BAR = 'bar',
  FUNNEL = 'funnel'
}

export type IChartCardSize = 'small' | 'medium' | 'large';

interface IDetailsChartData {
  line?: LineChartData,
  bar?: object[],
  funnel?: FunnelDatum[],
}

interface IChartCustomProperties {
  bar?: Partial<BarSvgProps>;
  line?: Partial<ILineChartProps>;
  funnel?: Partial<FunnelSvgProps<FunnelDatum>>;
}

export interface IExternalLoaders {
  isLoading: boolean,
  isInitialLoader: boolean,
}

export interface IDetailsChartProps {
  fetchData?(): Promise<void>;

  mountFetchData?(): Promise<void>;

  disablePlatformFetch?: boolean;
  data: IDetailsChartData;
  customProperties?: IChartCustomProperties;
  chartType: ChartType;
  size?: IChartCardSize;
  scrollAreaSize?: IChartCardSize;
  externalLoaders?: IExternalLoaders;
  valueInPercent?: boolean;
  title?: string;
  rangeChangeFactors: IRangeFactors;
  platformChangeFactors: IChangeFactors;

  isPlatformSelected?(): boolean;
}
