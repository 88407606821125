import { API, UseCase } from "@ppg/common";

interface DeleteABTest {
  abTestId: string;
}

export class DeleteABTestUseCase extends UseCase<DeleteABTest, void> {
  protected async execute({ abTestId }: DeleteABTest) {
    return API.HTTP.post<void>(`core/projects/${ API.HTTP.currentProjectId }/ab-tests/${ abTestId }/delete`);
  }
}

export const deleteABTestUseCase = new DeleteABTestUseCase();
