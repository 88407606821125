import styled from '@emotion/styled';
import { MainTheme } from '../../../assets/themes/MainTheme';

export const WarningWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${ MainTheme.colors.tertiary.red500 };
  margin-left: 1rem;
  margin-bottom: 1.5rem;
`;

export const ErrorMessage = styled.div`
  color: ${ MainTheme.colors.action.error.base }`;

export const WarningMessage = styled.div`
  margin-top: 5px;
  color: ${ MainTheme.colors.action.warning.base }`;
