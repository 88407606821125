import * as React from "react";

interface ISubscriberChartWrapper {
  children: React.ReactNode;
}

export const SubscriberChartWrapper = ({ children }: ISubscriberChartWrapper): JSX.Element => {
  return <div className={ 'subscribers-tables' }>
    { children }
  </div>
}