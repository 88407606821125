import { API, UseCase } from '@ppg/common';
import { ICampaignId, ICampaignIdResponse } from '../interfaces';

interface ICopySegmentedPushRequest extends ICampaignId {}
interface ICopySegmentedPushResponse extends ICampaignIdResponse {}

export class CopySegmentedPushUseCase extends UseCase<ICopySegmentedPushRequest, ICopySegmentedPushResponse> {
  protected async execute({ campaignId }: ICopySegmentedPushRequest) {
    return API.HTTP.post<ICopySegmentedPushResponse>(`core/projects/${ API.HTTP.currentProjectId }/pushes/segmented/${ campaignId }/copy`);
  }
}

export const copySegmentedPushUseCase = new CopySegmentedPushUseCase();
