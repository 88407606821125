import { create } from 'jss';
import camelCase from 'jss-plugin-camel-case';
import nested from 'jss-plugin-nested';

const jss = create();

jss.setup({
  createGenerateId: () => (rule: any) => rule.key,
});

jss.use(camelCase());
jss.use(nested());

export default jss;
