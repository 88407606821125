import { API, UseCase } from '@ppg/common';

interface ChangePasswordData {
  currentPassword: string;
  newPassword: string;
  repeatedPassword: string;
}

export class ChangePasswordUseCase extends UseCase<ChangePasswordData, void> {
  protected async execute({ currentPassword, newPassword, repeatedPassword }) {
    await API.HTTP.post('aai/user/password', { currentPassword, newPassword, repeatedPassword });
  }
}

export const changePasswordUseCase = new ChangePasswordUseCase();
