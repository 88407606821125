import { ProjectRelated } from '../ProjectRelatedStore';
import { action, computed, observable, reaction } from 'mobx';
import { debounce, property } from '@ppg/common';
import { SubscriberFilterStore, SubscriberRangeType } from './SubscriberFilterStore';
import { SubscriberHistoryStore } from './SubscriberHistoryStore';
import { getSubscribersLabelsUseCase } from '../../useCases/core';
import { ISubscriberSelector, Subscriber } from '../../modelsMobx/subscriber/Subscriber';
import { DAY, ITEMS_PER_PAGE } from '../../constants';
import { listSubscribersUseCase } from '../../useCases/core/subscriber/ListSubscriberUseCase';
import { IDateRange } from '@ppg/styled';
import dayjs from 'dayjs';
import { ISubscriberCustomField, ISubscriberDTO } from '../../modelsMobx/subscriber/interfaces';
import { getSubscriberUseCase } from '../../useCases/core/subscriber/GetSubscriberUseCase';
import { ISubscriberLabels } from '../../useCases/core/subscriber/GetSubscribersLabelsUseCase';
import { Utils } from '../utils';
import { IListQueryOptions } from '../../interfaces/IListQueryOptions';
import { SegmentationStore } from './SegmentationStore';
import { LabelCountStrategy } from '../../modelsMobx/Label';
import { CustomField } from "../../modelsMobx/CustomField";
import { AutomationProjectDataStore } from "./AutomationProjectDataStore";
import { ProjectStore } from "./ProjectStore";
import { userStore } from "../index";

/** @deprecated **/
export class SubscribersStore extends ProjectRelated {
  static SUBSCRIBERS_LABELS_LIMIT = 40;
  static SUBSCRIBERS_LABELS_HEIGHT_LIST = 108;
  static SUBSCRIBERS_LABELS_HEIGHT = 130;

  @property()
  public subscribers: Subscriber[] = [];

  @property()
  public subscriber: Subscriber | null;

  @property()
  public offset: number = 0;

  @property()
  public limit: number = ITEMS_PER_PAGE;

  @property()
  public total: number = 0;

  @property()
  private infinity: boolean;

  @property()
  public labelsOffset: number = 0;

  @property()
  public labelsLimit: number = SubscribersStore.SUBSCRIBERS_LABELS_LIMIT;

  @property()
  public startDate: Date;

  @observable public isSubscribersLoading: boolean = true;
  @observable public isSubscriberLoading: boolean = true;
  @observable public isLabelsLoading: boolean = true;

  public filters: SubscriberFilterStore;
  public history: SubscriberHistoryStore;
  public segmentationLabels: SegmentationStore;

  constructor(
    projectStore: ProjectStore,
    private readonly automationProjectDataStore: AutomationProjectDataStore
  ) {
    super(projectStore)
  }

  public setDefaultValues(): void {
    this.initializeStores();
    this.subscribers = [];
    this.subscriber = null;
    this.labelsOffset = 0;
  }

  public reactOnPaginationChange = reaction(() => this.listFactors, () => this.debouncedSubscribersFetch());
  public debouncedSubscribersFetch = debounce(() => this.fetchSubscribers(), 300);

  @action
  private initializeStores = (): void => {
    this.filters = new SubscriberFilterStore(this);
    this.history = new SubscriberHistoryStore(this);
    this.segmentationLabels = new SegmentationStore(this.projectId, [], LabelCountStrategy.OR, LabelCountStrategy.OR);
  };

  public initializeSubscriberList = async (customFields: CustomField[]): Promise<void> => {
    this.setInfinity(userStore.settings.infinity);

    await this.filters.setCustomFields(customFields);

    await Promise.all([
      this.fetchSubscribers(),
      this.filters.setHeaders(),
    ]);
  };

  @computed
  private get listFactors(): IListQueryOptions {
    return {
      offset: this.offset,
      limit: this.limit,
    };
  }

  @computed
  public get subscribersIds(): string[] {
    return this.subscribers.map(subscriber => subscriber.id);
  }

  @action
  public getDateRange = (): IDateRange => {
    const { rangeSelect, dateRangeTo, dateRangeFrom } = this.filters;

    if (rangeSelect === SubscriberRangeType.DATE_RANGE) {
      const from: Date = dayjs(dateRangeFrom).utc().startOf('day').add(DAY, 'day').toDate();
      const to: Date = dayjs(dateRangeTo).utc().endOf('day').toDate();
      return { from, to };
    }

    return { from: dateRangeFrom, to: dateRangeTo };
  };

  @action
  public async fetchSubscribers(): Promise<void> {
    await this.executeWithSubscribersLoader(async () => {
      const { customIdSearch } = this.filters;
      const { from, to } = this.getDateRange();
      const {sortBy, includedStrategy, excludedStrategy, includedLabels, excludedLabels} = this.segmentationLabels

      const { data, metadata } = await listSubscribersUseCase.exec({
        limit: this.limit,
        offset: this.offset,
        customId: customIdSearch,
        from: from.toISOString(),
        to: to.toISOString(),
        sortBy,
        includedLabels: includedLabels.map((label) => label.serialize()),
        excludedLabels: excludedLabels.map((label) => label.serialize()),
        includedStrategy,
        excludedStrategy,
      });

      this.total = metadata.total;
      const subscribers = data.map(subscriber => Subscriber.createSubscriber(subscriber, this.automationProjectDataStore));

      this.subscribers = this.infinity ? this.subscribers.concat(subscribers) : subscribers;

      const labels = await this.fetchSubscribersLabels();
      this.assignLabelsToSubscribers(labels);
    });
  };

  @action
  private assignLabelsToSubscribers(labels: ISubscriberLabels[]) {
    const subscribersMap = new Map();

    for (let subscriber of this.subscribers) {
      subscribersMap.set(subscriber.id, subscriber);
    }

    for (let label of labels) {
      const subscriber = subscribersMap.get(label.subscriber);
      if (subscriber) {
        subscriber.appendLabels(label.labels);
      }
    }
  }

  @action
  public async selectSubscriberById(subscriberId: string): Promise<void> {
    await this.executeWithSubscriberLoader(async () => {
      const subscriber: ISubscriberDTO = await getSubscriberUseCase.exec({
        subscriber: subscriberId
      });
      this.subscriber = Subscriber.createSubscriber(subscriber, this.automationProjectDataStore);
      await this.subscriber.fetchSelectors();
      await this.subscriber.getMoreLabels({ limit: this.labelsLimit, offset: this.labelsOffset });
    });
  };

  @action
  public async fetchSubscribersLabels(): Promise<ISubscriberLabels[]> {
    if (this.subscribers.length === 0) return [];
    return await this.executeWithLabelsLoader<ISubscriberLabels[]>(() => getSubscribersLabelsUseCase.exec({
      offset: this.labelsOffset,
      limit: this.labelsLimit,
      subscribers: this.subscribersIds
    }));
  }

  @computed
  public get paginatedCustomFieldList(): ISubscriberCustomField[] {
    return this.subscriber ? this.subscriber.customFields.slice(this.history.customFieldOffset, Math.floor((this.history.customFieldOffset / this.history.customFieldLimit) + 1) * this.history.customFieldLimit) : [];
  }

  @computed
  public get paginatedSelectorsList(): ISubscriberSelector[] {
    return this.subscriber ? this.subscriber.selectors.slice(this.history.selectorsOffset, Math.floor((this.history.selectorsOffset / this.history.selectorsLimit) + 1) * this.history.selectorsLimit) : [];
  }

  @action
  public setSubscriber(subscriber): void {
    this.subscriber = subscriber;
  }

  @computed
  public get getInfinity(): boolean {
    return this.infinity;
  }

  @action
  public setInfinity(infinity: boolean): void {
    this.infinity = infinity;
  }

  @action
  public async initializeSubscriberDetails(subscriberId: string) {
    if (!this.subscriber || this.subscriber.id !== subscriberId) {
      await this.selectSubscriberById(subscriberId);
    }
  }

  private executeWithSubscriberLoader<T = any>(callback: () => Promise<T>) {
    return Utils.executeWithLoader(
      callback,
      () => this.isSubscriberLoading = true,
      () => this.isSubscriberLoading = false,
      (err) => {
        console.error(err);
        this.isSubscriberLoading = false;
      }
    );
  }

  private executeWithSubscribersLoader<T = any>(callback: () => Promise<T>) {
    return Utils.executeWithLoader(
      callback,
      () => this.isSubscribersLoading = true,
      () => this.isSubscribersLoading = false,
      (err) => {
        console.error(err);
        this.isSubscribersLoading = false;
      }
    );
  }

  private executeWithLabelsLoader<T = any>(callback: () => Promise<T>) {
    return Utils.executeWithLoader(
      callback,
      () => this.isLabelsLoading = true,
      () => this.isLabelsLoading = false,
      (err) => {
        console.error(err);
        this.isLabelsLoading = false;
      }
    );
  }
}
