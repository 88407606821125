import { UseCase, API } from '@ppg/common';
import { IFabButton } from '../interfaces';

export class UpdateFabButtonPluginUseCase extends UseCase<IFabButton, void> {
  protected async execute({ websiteIntegrationId, enabled, css, primaryColor, disableButtonText, disabledText, enableButtonText, enabledText, fabIcon, nativeBlockedText, title }: IFabButton) {
    await API.HTTP.put(`wi/projects/${ API.HTTP.currentProjectId }/website-integrations/${ websiteIntegrationId }/plugins/fab-button`, {
      enabled,
      css,
      primaryColor,
      disableButtonText,
      disabledText,
      enableButtonText,
      enabledText,
      fabIcon,
      nativeBlockedText,
      title
    })
  }
}

export const updateFabButtonPluginUseCase = new UpdateFabButtonPluginUseCase();
