import { API, UseCase } from '@ppg/common';

interface IConfirmAccountDeleteWithTokenUseCase {
  token: string;
}

export class ConfirmAccountDeleteWithTokenUseCase extends UseCase<IConfirmAccountDeleteWithTokenUseCase, void> {
  protected async execute({ token }) {
    await API.HTTP.post<void>(`aai/user/delete/${ token }`);
  }
}

export const confirmAccountDeleteWithTokenUseCase = new ConfirmAccountDeleteWithTokenUseCase();
