import { UseCase, API } from "@ppg/common";

interface IGetPaymentData {
  organizationId: string;
}

interface IGetPaymentDataResponse {
  city: string;
  country: string;
  flat: string;
  house: string;
  name: string;
  street: string;
  taxNumber: string;
  zip: string;
}

export class GetPaymentDataUseCase extends UseCase<IGetPaymentData, IGetPaymentDataResponse> {
  protected async execute({ organizationId }: IGetPaymentData) {
    return API.HTTP.get<IGetPaymentDataResponse>(`acc/organizations/${ organizationId }`);
  }
}

export const getPaymentDataUseCase = new GetPaymentDataUseCase();
