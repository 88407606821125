import * as React from 'react';
import { h, dom, VNode } from 'maquette';
import { ISubscriptionForm, } from '../index';

export class FormViewBuilder {
  constructor(private template: ISubscriptionForm[]) {
  }

  private createElement(element: ISubscriptionForm): VNode {
    let child, className;

    if (element.child) {
      child = element.child.map(item => this.createElement(item));
    }

    if (Object.keys(element.styles).length > 0) {
      className = element.uuid;
    }

    return h(element.type, { class: className, ...element.properties }, element.text ? [element.text] : child);
  }

  private createElements() {
    const elements = this.template.map(element => this.createElement(element));

    return h('div', elements);
  }

  public buildView(element: HTMLElement) {
    dom.replace(element, this.createElements());
  }
}
