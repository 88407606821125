import { ProjectRelated } from './ProjectRelatedStore';
import { property } from '@ppg/common';
import { action } from 'mobx';
import { getAppSettingUseCase, updateAppSettingUseCase } from '../useCases/app-helper';

interface IGetAppSettingDTO {
  project: string;
  mobileFeaturesEnabled: boolean;
  showSentBy: boolean;
  forcePoweredByPPGEnabled: boolean;
  isDemoProject: boolean;
  enableSendingDelay: boolean;
  isCappingEnabled: boolean;
  defaultTitleEnabled: boolean;
}

export class AppSettingStore extends ProjectRelated {
  @property() public readonly mobileFeaturesEnabled: boolean;
  @property() public readonly showSentBy: boolean;
  @property() public readonly forcePoweredByPPGEnabled: boolean;
  @property() public readonly isDemoProject: boolean;
  @property() public readonly enableSendingDelay: boolean;
  @property() public readonly isCappingEnabled: boolean;
  @property() public readonly defaultTitleEnabled: boolean;

  @action
  async setDefaultValues(): Promise<void> {
    if (!this.projectId) {
      return;
    }

    const appSettings: IGetAppSettingDTO = await this.getAppSetting();
    this.setSettings(appSettings);
  }

  public async getAppSetting(): Promise<IGetAppSettingDTO> {
    return getAppSettingUseCase.exec({ project: this.projectId });
  }

  public setSettings(appSettings: IGetAppSettingDTO): void {
    for (let [key, value] of Object.entries(appSettings)) {
      if (key !== "project") {
        this.getProperty(`${ key }`).setValue(value);
      }
    }
  }

  public async saveSettings(): Promise<void> {
    await updateAppSettingUseCase.exec({
      project: this.projectId,
      mobileFeaturesEnabled: this.mobileFeaturesEnabled,
      showSentBy: this.showSentBy,
      forcePoweredByPPGEnabled: this.forcePoweredByPPGEnabled,
      isDemoProject: this.isDemoProject,
      enableSendingDelay: this.enableSendingDelay,
      defaultTitleEnabled: this.defaultTitleEnabled
    });
  }
}
