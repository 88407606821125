import { connector, Form } from '../base';
import { api, canCompileTest, t } from '../base/helpers';
import { IPushCampaignForm } from './IPushCampaignForm';
import { property, validate } from '../base/decorators';
import { Project, PushAction, PushCampaign, WebUser } from '../models';
import * as lodash from 'lodash';
import { CAMPAIGN_CHARS, dummyImage, MAX_TTL, MIN_TTL, SAFARI_COLLAPSE } from '../constants';
import { CampaignDirectionType, CampaignType } from '../models/PushCampaign';
import { Polygon } from '../modelsMobx';
import dayjs from 'dayjs';
import * as DummyIcon from '../assets/images/dummy-icon.png';
import { GeolocationStore } from "../stores/project/GeolocationStore";
import { RedirectLink } from "../components/PushCampaignCreator/RedirectLink";
import { IPushAction } from '../modelsMobx/PushAction';
import {
  featureStore,
  imageStore,
  projectImageStore,
  pushSettingStore,
  userStore,
  websiteIntegrationStore
} from '../stores';
import { getCountSubscribersUseCase, readMetaDataUseCase } from '../useCases/core';
import { Image, ImageFormatType, ImageType } from '../modelsMobx/Image';
import { SenderStrategyType } from '../stores/PushSettingStore';
import { API, Property } from '@ppg/common';
import { Label, LabelCountStrategy, LabelState } from '../modelsMobx/Label';
import { SegmentationStore } from '../stores/project/SegmentationStore';
import { IStrategyChange } from '../components/SegmentationLabelsFilter';
import { action, computed } from "mobx";
import { Segment } from "../modelsMobx/segmentation/Segment";
import { ITestPush } from '../components/commonMobX/TestSendPush/TestSendPushButton';

export enum CampaignStepType {
  SPLIT = 'split',
  CONTENT = 'content',
  TIME = 'time',
  TARGET = 'target',
  SUMMARY = 'summary',
  UTM = 'utm',
  TESTS = 'tests',
}

export interface IInsideProjectCopy {
  sample?: number;
  title?: string;
  content: string;
  polygons?: [Number, Number][][];
  redirectLink?: string;
  requireInteraction?: boolean;
  expireDate?: Date;
  delayTime?: number;
  tagsCountStrategy?: LabelCountStrategy;
  excludedTagsCountStrategy?: LabelCountStrategy;
  tags?: any[];
  files?: any;
  excludedTags?: any[];
  actions?: any[];
  global?: boolean;
  attachFiles?: any;
  image?: Image;
  scheme?: string;
  icon?: Image;
  currentProjectId?: string;
  senderStrategy?: SenderStrategyType;
  labels: Label[];
}

export interface IOutsideProjectCopy {
  title?: string;
  content: string;
  redirectLink?: string;
  actions?: any[];
  requireInteraction?: boolean;
  expireDate?: Date;
  sendDate?: Date,
  currentProjectId?: string;
  direction?: CampaignDirectionType;
}

export interface ICopyCampaign {
  copiedProjectId: string;
  modelName: string;
  insideProject: IInsideProjectCopy;
  outsideProject: IOutsideProjectCopy;
}

export enum TargetScreenSelection {
  GEO = 'geo',
  TAGS = 'tags',
  SEGMENTS = 'segments'
}

export class PushCampaignForm extends Form<IPushCampaignForm, ICopyCampaign> implements IPushCampaignForm {
  private user = connector.get<WebUser>('user');
  public static readonly ACTIONS_LIMIT = 2;
  public static readonly CAMPAIGN_ATTRS = ['title', 'content', 'redirectLink', 'requireInteraction', 'actions', 'senderStrategy', 'icon', 'image'];

  @property()
  @validate('required', { label: () => t('Title') })
  @validate('nunjucks')
  @validate('safariCollapse', { length: SAFARI_COLLAPSE.TITLE, warning: true })
  @validate('characterRange', { max: CAMPAIGN_CHARS.TITLE, min: 0, nunjucks: true })
  public title: string;

  @property()
  @validate('required', { label: () => t('Content') })
  @validate('nunjucks')
  @validate('safariCollapse', { length: SAFARI_COLLAPSE.CONTENT, warning: true })
  @validate('characterRange', { max: CAMPAIGN_CHARS.CONTENT, min: 0, nunjucks: true })
  public content: string;

  @property()
  @validate('url_nunjucks')
  public redirectLink: string;

  @property() public state: string;
  @property() public sample: number;
  @property() public sendDate: Date;
  @property() public delayTime: number;
  @property() public isAdvancedEditor: boolean;
  @property() public expireDate: Date;
  @property() public expireDateEnable: boolean;
  @property() public requireInteraction: boolean;
  @property() public project: string;
  @property() public hasAudience: boolean;
  @property() public tagsCountStrategy: LabelCountStrategy;
  @property() public excludedTagsCountStrategy: LabelCountStrategy;
  @property() public polygons: [Number, Number][][];
  @property() public campaignId: string;
  @property() public currentStep: CampaignStepType = CampaignStepType.CONTENT;
  @property() public actions: PushAction[];
  @property() public timzezonesToExclude: number[];
  @property() public targetSelection: TargetScreenSelection = TargetScreenSelection.TAGS;
  @property() public direction: CampaignDirectionType = CampaignDirectionType.AUTO;
  @property() public sentBy: string;
  @property() public segmentationStore: SegmentationStore;
  @property() public audience: number = 0;
  @property() public icon: Image = null;
  @property() public image: Image = null;
  @property() public omitCapping: boolean = false;
  @property() public segment: string;

  private _sourceCampaign: PushCampaign;

  @property() public labels: Label[] = [];

  @action
  public async setSegment(segment: Segment): Promise<void> {
    if (segment) {
      this.segment = segment.id;
      this.labels = [];
      this.polygons = [];
      this.segmentationStore.resetStateOfLabels();
    }
  }

  @computed
  private get includedLabels(): string[] {
    return this.labels.filter(label => label.isIncluded()).map(label => label.serialize());
  }

  @computed
  private get excludedLabels(): string[] {
    return this.labels.filter(label => label.isExcluded()).map(label => label.serialize());
  }

  /**
   * @deprecated - please use .labels instead in new components
   */
  @computed
  public get tags(): string[] {
    return this.labels?.filter(label => label.isIncluded()).map(label => label.getLegacyId());
  }

  /**
   * @deprecated - please use .labels instead in new components
   */
  @computed
  public get excludedTags(): string[] {
    return this.labels?.filter(label => label.isExcluded()).map(label => label.getLegacyId());
  }

  @action
  public async checkAudienceExistence() {
    if (this.hasAudience) return;

    if (this.tags.length !== 0 || this.excludedTags.length !== 0) {
      const { data } = await getCountSubscribersUseCase.exec({
        projectId: `${ this.user.selectedProject.id }`,
        includedLabels: this.includedLabels,
        excludedLabels: this.excludedLabels,
        includedStrategy: this.tagsCountStrategy,
        excludedStrategy: this.excludedTagsCountStrategy
      });

      this.hasAudience = data > 0;
    } else {
      this.hasAudience = true;
    }
  }

  public createSegmentationStore(): SegmentationStore {
    return new SegmentationStore(
      API.HTTP.currentProjectId,
      this.labels,
      this.tagsCountStrategy,
      this.excludedTagsCountStrategy
    );
  }

  /**
   * Checks if labels already exists if yes then change state, if not append.
   * We need to synchronize state with old "legacy" tags and tagsExcluded fields
   *   after we resolve legacy id - it's temporary solution
   * @param label
   */
  public async onLabelSelected(label: Label): Promise<void> {
    await label.resolveLegacyId();

    this.hasAudience = false;

    const actualExistedLabel = this.labels.find(actualLabel => actualLabel.getLabelIdentity() === label.getLabelIdentity());

    switch (label.state) {
      case LabelState.INCLUDED:
      case LabelState.EXCLUDED:
        if (actualExistedLabel) {
          actualExistedLabel.setState(label.state);
        } else {
          this.labels.push(label);
        }
        break;

      case LabelState.IDLE:
        if (actualExistedLabel) {
          this.labels = this.labels.filter(item => item.getLabelIdentity() !== label.getLabelIdentity());
        }
        break;
    }

  }

  public async onStrategyChange(strategy: IStrategyChange): Promise<void> {
    this.excludedTagsCountStrategy = strategy.excludedStrategy;
    this.tagsCountStrategy = strategy.includedStrategy;
  }

  public get sourceCampaign(): PushCampaign {
    return this._sourceCampaign;
  }

  public set sourceCampaign(value: PushCampaign) {
    const wasChanged = this._sourceCampaign !== value;
    this._sourceCampaign = value;

    if (wasChanged) this.trigger('change', this, this.collection);
  }

  public setRocketDefaultValues(defaultRequireInteractionValue: Property) {
    const requireInteraction = defaultRequireInteractionValue.getValue();
    const value = this.getRocketRequireInteraction(requireInteraction);
    this.requireInteraction = value;
  }

  public setDefaultExpireDate(expireTime: number, isExpireTimeEnabled?: boolean): Date {
    if (isExpireTimeEnabled) this.expireDateEnable = isExpireTimeEnabled;
    this.expireDate = this.getDefaultExpireDate(expireTime, isExpireTimeEnabled);
    return this.expireDate;
  }

  public setDirectionSetting(isDirectionRtl: boolean) {
    this.direction = isDirectionRtl ? CampaignDirectionType.RTL : CampaignDirectionType.AUTO;
  }

  public setDefaultTitleValue(isDefaultTitleEnabled: boolean, defaultTitle: string): void {
    this.title = isDefaultTitleEnabled ? defaultTitle : this.title;
  }

  public initialize() {
    this.listenTo(this, 'change:sendDate', () => this.onSendDateChange());
    this.segmentationStore = this.createSegmentationStore();
    this.targetSelection = TargetScreenSelection.TAGS;
  }

  public syncPolygonStates(polygons: Polygon[]) {
    polygons.forEach(polygon => {
      if (GeolocationStore.isInCoordinatesSet(this.polygons, polygon.coordinates)) {
        polygon.setIsSelected(true);
      }
    });
  }

  public togglePolygon(polygon: Polygon) {
    const polygonIndex = GeolocationStore.indexOf(this.polygons, polygon.coordinates);
    const isInSet = polygonIndex !== -1;

    if (isInSet) {
      this.polygons.splice(polygonIndex, 1);
      polygon.setIsSelected(false);
    } else {
      this.polygons.push(polygon.coordinates);
      polygon.setIsSelected(true);
    }
  }

  public getRocketRequireInteraction(defaultRocketRequireInteraction) {
    return lodash.isUndefined(defaultRocketRequireInteraction) ? this.requireInteraction : defaultRocketRequireInteraction;
  }

  public getDefaultExpireDate(expireTime: number, isExpireTimeEnabled?: boolean) {
    const initialDate = this.sendDate ? dayjs(this.sendDate) : dayjs();
    return expireTime && isExpireTimeEnabled || expireTime !== 1 ? initialDate.add(expireTime, 'h').toDate() : this.expireDate;
  }

  get imageFile() {
    return this.image?.url;
  }

  get iconFile() {
    return this.icon?.url;
  }

  public toCopy(): ICopyCampaign {
    return {
      copiedProjectId: this.user.selectedProject.id as string,
      modelName: "pushCampaign",
      insideProject: {
        sample: this.sample,
        title: this.title,
        content: this.content,
        polygons: this.polygons,
        redirectLink: this.redirectLink,
        requireInteraction: this.requireInteraction,
        expireDate: this.expireDate,
        delayTime: this.delayTime,
        tagsCountStrategy: this.tagsCountStrategy,
        excludedTagsCountStrategy: this.excludedTagsCountStrategy,
        actions: this.getParsedActions(),
        image: this.image,
        icon: this.icon,
        senderStrategy: "all",
        labels: this.labels
      },
      outsideProject: {
        title: this.title,
        content: this.content,
        redirectLink: this.redirectLink,
        actions: this.getParsedActions(),
        requireInteraction: this.requireInteraction,
      }
    };
  }

  public async toPaste(data) {
    let dataToPaste;
    if (this.user.selectedProject.id && this.user.selectedProject.id === data.copiedProjectId) {
      const omitUTM = this.user.selectedProject.utm.disabled;
      dataToPaste = { ...data.insideProject };
      const newLink = this.fromRedirectLink(data.insideProject.redirectLink, omitUTM);
      this.set({ redirectLink: newLink });
    } else {
      dataToPaste = { ...data.outsideProject };
      const omitUTM = this.user.selectedProject.utm.disabled;
      const newLink = this.fromRedirectLink(data.outsideProject.redirectLink, omitUTM);
      this.set({ redirectLink: newLink });
    }

    if (!dataToPaste.expireDate) {
      const { expireTime, isExpireTimeEnabled } = pushSettingStore;
      dataToPaste.expireDate = this.getDefaultExpireDate(expireTime, isExpireTimeEnabled);
    }

    this.fromCampaign(dataToPaste);
    this.trigger('paste');
  }

  public toTestPush(): ITestPush {
    return {
      title: this.title,
      content: this.content,
      icon: this.icon?.url,
      image: this.image?.url,
      requireInteraction: this.requireInteraction,
      redirectLink: this.redirectLink
    };
  }

  public toCampaign(project: Project, campaign?: PushCampaign): PushCampaign {
    campaign = campaign || new PushCampaign();
    const campaignProps = {
      id: this.campaignId,
      project: project && project.id,
      title: this.title,
      polygons: this.polygons,
      content: this.content,
      redirectLink: this.redirectLink,
      state: this.state,
      sendDate: this.sendDate || new Date(),
      delayTime: this.delayTime,
      expireDate: this.expireDate,
      requireInteraction: this.requireInteraction,
      tagsCountStrategy: this.tagsCountStrategy,
      excludedTagsCountStrategy: this.excludedTagsCountStrategy,
      actions: this.getParsedActions(),
      sample: this.sample,
      senderStrategy: "all",
      direction: this.direction,
      sentBy: userStore.user.username,
      imageOwnerID: this.image?.owner,
      iconOwnerID: this.icon?.owner,
      labels: this.labels,
      omitCapping: this.omitCapping,
      segment: this.segment,
    };

    campaign.set(campaignProps);
    campaign.setNewIcon(this.getCampaignIcon());
    campaign.setNewImage(this.image);
    this._sourceCampaign = campaign;

    return campaign;
  }

  public toSendCampaign(project: Project, campaign?: PushCampaign): PushCampaign {
    campaign = campaign || new PushCampaign();
    const campaignProps = {
      id: this.campaignId,
      project: project && project.id,
      title: this.title,
      polygons: this.polygons,
      content: this.content,
      redirectLink: this.redirectLink,
      state: this.state,
      sendDate: this.sendDate || new Date(),
      delayTime: this.delayTime,
      expireDate: this.expireDate,
      requireInteraction: this.requireInteraction,
      tagsCountStrategy: this.tagsCountStrategy,
      excludedTagsCountStrategy: this.excludedTagsCountStrategy,
      tags: this.tags,
      excludedTags: this.excludedTags,
      labels: this.includedLabels,
      excludedLabels: this.excludedLabels,
      actions: this.getParsedActions(),
      segment: this.segment,
      sample: this.sample,
      senderStrategy: "all",
      direction: this.direction,
      sentBy: userStore.user.username,
      imageOwnerID: this.image?.owner,
      iconOwnerID: this.icon?.owner,
      icon: null,
      image: null,
      omitCapping: this.omitCapping,
    };

    campaign.set(campaignProps);
    this._sourceCampaign = campaign;

    return campaign;
  }

  public setStartExpireDate = (): void => {
    if (this.expireDate) return;
    const initialDate = this.sendDate ? dayjs(this.sendDate) : dayjs();
    pushSettingStore.isExpireTimeEnabled
      ? this.set({ expireDate: initialDate.add(pushSettingStore.expireTime, 'hours').toDate() })
      : this.set({ expireDate: initialDate.add(MAX_TTL, 'seconds').toDate() });
  };

  public fromCampaign(campaign: PushCampaign, state: string = 'ready') {
    campaign = campaign || new PushCampaign();
    this._sourceCampaign = campaign;

    if ([CampaignType.SENT, CampaignType.GLOBAL].indexOf(campaign.state) !== -1) {

      campaign.set({
        id: null,
        state: state,
        sendDate: null,
        expireDate: null,
      });
    }

    this.set({
      tagsCountStrategy: campaign.tagsCountStrategy,
      excludedTagsCountStrategy: campaign.excludedTagsCountStrategy,
      sample: campaign.sample,
      campaignId: campaign.id,
      title: campaign.title,
      content: campaign.content,
      polygons: campaign.polygons,
      state: campaign.state,
      sendDate: campaign.sendDate,
      delayTime: campaign.delayTime,
      expireDate: campaign.expireDate,
      requireInteraction: campaign.requireInteraction || false,
      redirectLink: campaign.redirectLink,
      expireDateEnable: lodash.isDate(campaign.expireDate),
      actions: [],
      senderStrategy: "all",
      direction: campaign.direction,
      labels: campaign.labels || [],
      omitCapping: campaign.omitCapping,
    });

    this.setDefaultIcon();

    this.icon = campaign.icon ? new Image(campaign.icon) : this.icon;
    this.image = campaign.image ? new Image(campaign.image) : this.image;

    this.createActionsModels(campaign.actions);

    this.segmentationStore.setLabels(this.labels);
    this.segmentationStore.setIncludedStrategy(this.tagsCountStrategy);
    this.segmentationStore.setExcludedStrategy(this.excludedTagsCountStrategy);
  }

  public isCampaignPlanned() {
    return this.sendDate !== null;
  }

  public onTargetSelected(type: TargetScreenSelection): void {
    this.targetSelection = type;
  }

  public isNewCampaign() {
    return lodash.isEmpty(this.campaignId);
  }

  public getCurrentImage(): string {
    return this.image?.url || null;
  }

  public getCurrentIcon(): string {
    return this.icon?.url || null;
  }

  public setDefaultIcon(): void {
    if (this.getDefaultIcon() === this.dummyIcon) {
      this.icon = dummyImage;
      return;
    }

    this.icon = projectImageStore.getImageByType(ImageType.LOGO);
  }

  public getDefaultIcon(): string {
    const logo = projectImageStore.getLogoURL(ImageFormatType.MEDIUM);

    return logo || this.dummyIcon;
  }

  public get isDefaultIcon(): boolean {
    return this.getCurrentIcon() === this.getDefaultIcon() || this.getCurrentIcon() === dummyImage.url;
  }

  public get dummyIcon(): string {
    return DummyIcon;
  }

  public getCampaignIcon(): Image | null {
    return this.icon;
  }

  get isUpdated() {
    return this.campaignId && this.state === 'ready';
  }

  public onSendDateChange() {
    const ttlRange = this.getTTLRange(null);

    if (ttlRange && dayjs(this.expireDate).isBefore(ttlRange.from)) {
      this.expireDate = ttlRange.from;
    }

    if (ttlRange && dayjs(this.expireDate).isAfter(ttlRange.to)) {
      this.expireDate = ttlRange.to;
    }
  }

  public getTTLRange(maxTTL) {
    let maxTTLInScope = MAX_TTL;
    if (maxTTL) {
      maxTTLInScope = maxTTL;
    }

    const fromDate = this.sendDate || new Date();
    return {
      from: dayjs(fromDate).add(MIN_TTL, 'seconds').toDate(),
      to: dayjs(fromDate).add(maxTTLInScope, 'seconds').toDate(),
      maxTTL: maxTTLInScope,
    };
  }

  get canAddAction(): boolean {
    return this.actions.length < PushCampaignForm.ACTIONS_LIMIT;
  }

  public addAction(): PushAction {
    const action = new PushAction();
    this.actions = this.actions.concat([action]);
    action.on('change', () => this.trigger('change', this, this.collection));
    return action;
  }

  public setDefaultPushActions(defaultActionsProperty: Property): void {
    const defaultActions: IPushAction[] = defaultActionsProperty.getValue();
    const actions = defaultActions && defaultActions.map((action: IPushAction) => {
        const defaultAction = new PushAction({ title: action.title, link: action.link, useRedirectLink: action.useRedirectLink });
        defaultAction.on('change', () => this.trigger('change', this, this.collection));
        return defaultAction;
      }
    );
    if (this.actions.length === 0) {
      this.actions = defaultActions ? this.actions.concat([...actions]) : [];

    }
  }

  public deleteAction(action: PushAction) {
    this.actions = lodash.filter(this.actions, a => a !== action);
    action.off('change');
    action.destroy();
  }

  public getParsedActions() {
    return lodash.map(this.actions, action => action.parseAction());
  }

  public createActionsModels(actions: PushAction[]) {
    lodash.each(actions, action => {
      const newAction = this.addAction();
      newAction.set(action);
      newAction.fromActionLink(newAction.link);
      newAction.trigger('paste');
    });
  }

  public toggleEditorMode() {
    this.isAdvancedEditor = !this.isAdvancedEditor;
  }

  public isNewOrChanged(): boolean {
    return this.isNewCampaign() || this.wasContentChanged();
  }

  public wasContentChanged(): boolean {
    if (!this._sourceCampaign)
      return false;
//@ts-ignore
    return this.differInAttributes(this._sourceCampaign, PushCampaignForm.CAMPAIGN_ATTRS)
      || this.icon?.url !== this._sourceCampaign.icon?.url
      || this.image?.url !== this._sourceCampaign.image?.url;
  }

  public onSendNow() {
    this.sendDate = null;
  }

  public onSchedule() {
    if (!this.sendDate) this.sendDate = new Date();
  }

  public areActionsValid(): boolean {
    return !lodash.some(this.actions, action => {
      action.validate();
      return !action.isValid();
    });
  }

  public hasDefaultUTMCampaignName(): boolean {
    //@ts-ignore
    const redirectLink = new RedirectLink();
    const isUtmDisabled = this.user.selectedProject.utm.disabled;

    if (canCompileTest(this.redirectLink)) {
      return false;
    }

    if
    (redirectLink.validUrl(this.redirectLink)) {
      const redirectLinkUrl = redirectLink.validUrl(this.redirectLink) && new URL(this.redirectLink);
      const utmCampaignName = redirectLinkUrl.searchParams.get('utm_campaign');

      return !isUtmDisabled && utmCampaignName === "CampaignName";
    }

    return false;
  }

  public isCampaignTargeted(): boolean {
    return (this.tags && this.tags.length > 0) || (this.excludedTags && this.excludedTags.length > 0) || (this.polygons && this.polygons.length > 0);
  }

  public setDelayTime(time: number) {
    this.delayTime = time;
  }

  get suggestions() {
    const items = [
      { description: t('How about targeting the campaign?'), step: CampaignStepType.TARGET, condition: !this.isCampaignTargeted() },
      { description: t('How about enabling the require interaction option?'), step: CampaignStepType.CONTENT, condition: !this.requireInteraction },
      { description: t('In UTMs your campaign\'s name equals "CampaignName" - how about changing it?'), step: CampaignStepType.UTM, condition: this.hasDefaultUTMCampaignName() },
      {
        description: t('You have enabled require interaction option but didn\'t specify any actions - how about adding some ?'),
        step: CampaignStepType.CONTENT,
        condition: this.requireInteraction && this.actions.length === 0
      },
      {
        description: t(`You are targeting your campaign by geolocation but geolocation option is disabled in your project settings - how about enabling it?`),
        step: CampaignStepType.TARGET,
        condition: this.polygons && this.polygons.length !== 0 && !websiteIntegrationStore.geolocation.enabled
      }
    ];

    return lodash.filter(items, item => item.condition);
  }

  get errors() {
    const items = [
      { description: t('The title is empty'), step: CampaignStepType.CONTENT, condition: !this.title },
      { description: t('The content is empty'), step: CampaignStepType.CONTENT, condition: !this.content },
      { description: t('Actions are invalid'), step: CampaignStepType.CONTENT, condition: !this.areActionsValid() },
    ];

    return lodash.filter(items, item => item.condition);
  }

  get blockers() {
    const items = [
      {
        description: t('Check if the tags you have selected are not mutually exclusive and your audience is greater than zero'),
        icon: 'icon-tag',
        condition: (this.tags.length > 0 || this.excludedTags.length > 0) && !this.hasAudience
      }
    ];
    return lodash.filter(items, item => item.condition);
  }

  get isFullyCorrectCampaign() {
    return this.errors.length === 0;
  }

  public getContentFormMetadata = async (imageType?: ImageType) => {
    const linkWithoutUtm = this.redirectLink.split('?')[0];
    const projectId = this.user.selectedProject.id as string;

    const data = await readMetaDataUseCase.exec({ url: linkWithoutUtm });

    this.title = this.title === '' ? data['title'] : this.title;
    this.content = this.content === '' ? data['description'] : this.content;

    switch (imageType) {
      /**
       * Zmieniaj tylko jeżeli nie było icon
       */
      case ImageType.PUSH_ICON:
        if (this.isDefaultIcon) {
          await this.setImageFromMetadata(data, imageType);
        }

        break;

      /**
       * Zmieniaj tylko jeżeli nie było image
       */
      case ImageType.PUSH_IMAGE:
        if (!this.image) {
          await this.setImageFromMetadata(data, imageType);
        }
    }

    this.trigger('paste');
  };

  public getDataFromUrlInRocket = async (): Promise<void> => {
    const linkWithoutUtm = this.redirectLink.split('?')[0];

    const data = await readMetaDataUseCase.exec({ url: linkWithoutUtm });

    this.title = data['title'];
    this.content = data['description'];

    await this.setImageFromMetadata(data, ImageType.PUSH_ICON);

    this.trigger('paste');
  };

  public async setImageFromMetadata(data, imageType) {
    await this.setImage(data['image'], imageType || imageType.PUSH_ICON);
  }

  public async setImage(url: string, type: ImageType) {
    if (!url) {
      return;
    }
    const image = await this.getImageFromMetadata(url);

    if (!image) {
      return;
    }

    const uploadedImage = await imageStore
      .uploadImage({
        blob: await image,
        name: type,
        type: type
      });

    if (type === ImageType.PUSH_IMAGE) {
      this.image = uploadedImage;
    }

    if (type === ImageType.PUSH_ICON) {
      this.icon = uploadedImage;
    }

    return uploadedImage;
  }

  private async getImageFromMetadata(url: string) {
    try {
      const req = await fetch(api(`/proxy?url=${ encodeURIComponent(url) }`));

      if (!req) {
        return;
      }

      return req.blob();
    } catch (error) {
      return;
    }
  }

  private appendUTMs(parsedUrl: URL) {
    const utm = featureStore.utm;

    if (!parsedUrl.searchParams.has('utm_medium') && utm.isMediumOn)
      parsedUrl.searchParams.append('utm_medium', utm.medium);

    if (!parsedUrl.searchParams.has('utm_source') && utm.isSourceOn)
      parsedUrl.searchParams.append('utm_source', utm.source);

    if (!parsedUrl.searchParams.has('utm_campaign') && utm.isCampaignOn)
      parsedUrl.searchParams.append('utm_campaign', utm.campaign);

    if (utm.customUtms.length > 0) {
      utm.customUtms.forEach(utm => {
        !parsedUrl.searchParams.has(`utm_${ utm.parameter }`) &&
        parsedUrl.searchParams.append(`utm_${ utm.parameter }`, utm.value);
      });
    }

    return parsedUrl;
  }

  public fromRedirectLink(url: string, omitUTM: boolean = false) {
    let parsedUrl;

    try {
      if (canCompileTest(url)) {
        return url;
      }

      parsedUrl = new URL(url);

      if (!omitUTM) {
        parsedUrl = this.appendUTMs(parsedUrl);
      }

    } catch (error) {

    }

    return RedirectLink.encodeURIWithNunjucks(`${ parsedUrl }`);
  }

  public appendNewCampaignName(url: string, newCampaignName: string) {
    const newUrl = new URL(url);
    newUrl.searchParams.set('utm_campaign', newCampaignName);
    return `${ newUrl }`;
  }

  public defaults(): IPushCampaignForm {
    return {
      tagsCountStrategy: LabelCountStrategy.OR,
      excludedTagsCountStrategy: LabelCountStrategy.OR,
      tags: [],
      excludedTags: [],
      sample: null,
      title: '',
      content: '',
      redirectLink: '',
      state: 'ready',
      segment: null,
      sendDate: null,
      delayTime: 0,
      expireDateEnable: true,
      expireDate: dayjs().add(MAX_TTL, 'seconds').toDate(),
      requireInteraction: false,
      actions: [],
      polygons: [],
      project: '',
      currentStep: 'content',
      labels: [],
      segmentationStore: this.createSegmentationStore()
    };
  }

}
