import { property, PropertyHandler } from '@ppg/common';
import { observable } from 'mobx';

export enum CappingPlanType {
  PROJECT = "PROJECT",
  AUTOMATION = "AUTOMATION",
  CAMPAIGN = "CAMPAIGN"
}

export enum OfferPlanPrototypeType {
  SETTLEMENT_POLICY = 'SETTLEMENT_POLICY',
  CAPPING_POLICY = 'CAPPING_POLICY'
}

export interface IOfferCappingPlan {
  type: CappingPlanType;
  limit: number;
}

export interface IOfferSettlementPlan {
  name: string,
  startFrom: string,
  steps: {
    type: string,
    payload: {
      amount: string,
    }
  }
}

export interface IPlanProfit {
  value: string,
  includes: boolean
}

export interface ISettlementPlans {
  name: string,
  startFrom: string,
  steps: {
    type: string,
    payload: {
      amount: string,
    }
  }[]
}

interface IPrototype<T> {
  type: OfferPlanPrototypeType;
  data: {
    plans: T[]
  }
}

export type CappingPrototype = IPrototype<IOfferCappingPlan>
export type SettlementPrototype = IPrototype<IOfferSettlementPlan>
export type Prototypes = CappingPrototype | SettlementPrototype;

export interface IOfferPlan {
  id: string;
  name: string;
  title: string;
  price: number;
  prototypes: Prototypes[],
  profits: IPlanProfit[],
  footer: string;
}

export class Offer extends PropertyHandler {

  @property()
  public id: string;

  @property()
  public name: string;

  @property()
  public currency: string;

  @property()
  public group: string;

  @observable
  public plans: IOfferPlan[];

}

