export interface IVideoTutorial {
  id: string;
  componentName: string;
  lang: string;
  externalVideoId: string;
  listing: IVideoListing[];
}

export interface IVideoListing {
  second: number;
  description: string;
}


export class VideoTutorial {
  public id: string;
  public componentName: string;
  public lang: string;
  public externalVideoId: string;
  public listing: IVideoListing[];

  constructor(videoTutorial: IVideoTutorial) {
    this.id = videoTutorial.id;
    this.componentName = videoTutorial.componentName;
    this.lang = videoTutorial.lang;
    this.externalVideoId = videoTutorial.externalVideoId;
    this.listing = videoTutorial.listing;
  }

  public getSortedListing() {
    return this.listing.sort((a, b) => a.second - b.second);
  }

  public static resourceUrl() {
    return 'videoTutorial';
  }
}
