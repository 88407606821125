import { Model as BackboneModel } from 'backbone';
import { AbstractValidator, defaultValidator, MethodValidator } from '../Validator';
import * as lodash from 'lodash';
import { IMap, IConstructor } from '../../interfaces';

export function validate(key: string, options?: IMap<any>): PropertyDecorator {
  options = options || {};
  return (proto: Object | any , propertyKey: string) => {
    proto.validators = proto.validators || [];

    const ValidatorCreator: (model: BackboneModel) => AbstractValidator = createValidator(key, propertyKey, options);

    if (ValidatorCreator) {
      proto.validators.push(ValidatorCreator);
    }
  };
}

function createValidator(key: string|IConstructor<AbstractValidator>, propertyName: string, options?: IMap<any>): (model: BackboneModel) => AbstractValidator {
  if (lodash.isString(key) && defaultValidator[key]) {
    return (model: BackboneModel) => new defaultValidator[key](model, propertyName, options);
  }

  if (lodash.isFunction(key)) {
    const ValidatorConstructor: IConstructor<AbstractValidator> = key as IConstructor<AbstractValidator>;
    return (model: BackboneModel) => new ValidatorConstructor(model, propertyName, options);
  }

  if (lodash.isString(key)) {
    return (model: BackboneModel) => new MethodValidator(model, propertyName, lodash.extend(options, {
      method: key as string
    }));
  }

  return null;
}
