import { IS_DEVELOPMENT } from "../constants";
import { polyglot } from "./polyglot";
import * as Polyglot from "node-polyglot";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/pl";
import "dayjs/locale/pt";
import "dayjs/locale/hr";
import { IMap } from "../interfaces";
import * as lodash from "lodash";
import { MainTheme } from "../assets/themes/MainTheme";
import { Config, TimeUnit } from "@ppg/common";
import { createBrowserHistory } from "history";

export function devValue(valueForDev: any, valueForProduction: any) {
  return IS_DEVELOPMENT ? valueForDev : valueForProduction;
}

export function api(url: string): string {
  const config = Config.getConfig();
  return trimSlashes(`${config.apiBasePath}/${url}`);
}

export function trimSlashes(uri: string): string {
  return uri.replace(/([^:]\/)\/+/g, "$1");
}

export const history = createBrowserHistory();

export function redirect(url: string): void {
  history.push(url);
}

export function dateFormat(date: string | Date, format?: string): string {
  return dayjs(date).format(format || "LLL");
}

export function nextTick(fn: () => void) {
  setTimeout(fn, 0);
}

export function inRange(start: number, end: number, value: number): boolean {
  if (start > end) [start, end] = [end, start];

  return value >= start && value <= end;
}

export function getStateLabel(state: string) {
  return campaignStateConfig[state]
    ? campaignStateConfig[state].label()
    : t("Unknown state");
}

export function percentage(numerator: number, denominator: number): number {
  if (denominator === 0) return 0;
  return lodash.floor((numerator / denominator) * 100, 2);
}

export function numberWithSpaces(num: number): string {
  if (!num) {
    return "0";
  }

  const parts = num.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}

export function getTimeUnit(unit: TimeUnit) {
  switch (unit) {
    case TimeUnit.DAY:
      return t("organization/dashboard/subscribers::days");
    case TimeUnit.HOUR:
      return t("organization/dashboard/subscribers::hours");
    case TimeUnit.MINUTE:
      return t("organization/dashboard/subscribers::minutes");
    case TimeUnit.MONTH:
      return t("organization/dashboard/subscribers::months");
    case TimeUnit.SECONDS:
      return t("organization/dashboard/subscribers::seconds");
    case TimeUnit.WEEK:
      return t("organization/dashboard/subscribers::weeks");
    case TimeUnit.YEAR:
      return t("organization/dashboard/subscribers::years");
    default:
      return "";
  }
}

export function Deferred() {
  let resolver;
  let rejector;

  const deffered = new Promise((resolve, reject) => {
    resolver = resolve;
    rejector = reject;
  });

  return {
    resolve: resolver,
    promise: deffered,
    reject: rejector,
  };
}

export function capitalize(word: string): string {
  return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
}

/**
 * @example
 *  t('context::Hello, %{name}!', {name: 'Spike'}); // "Hello, Spike!"
 *
 * If you use the variable, remember to wrap it in %{} not as template literal
 *
 * When you add a new translation, at the beginning of the string provide a context
 * and separate it from the text with double colon '::'.
 * Contexts name's related to the view
 * and equals to url path, ex. 'dashboard', or 'dashboard/analytics'
 * In case you're creating a new context, please add it to languages.namespaces
 * in application's config.example.json
 *
 * As the whole text should be wrapped, remember to wrap each line.
 *
 * @param p
 * @param opt
 */
export const t = (p: string, opt?: number | Polyglot.InterpolationOptions) =>
  polyglot.t(p, opt as number);

export const campaignStateConfig: IMap<{ icon: string; label: () => string }> =
  {
    sent: {
      icon: "send",
      label: () => t("Campaign was sent"),
    },
    ready: {
      icon: "hourglass",
      label: () => t("Campaign is ready to send"),
    },
    draft: {
      icon: "folder",
      label: () => t("This is a campaign draft"),
    },
    sending: {
      icon: "hourglass",
      label: () => t("This campaign is currently sending"),
    },
    processing: {
      icon: "refresh",
      label: () => t("This campaign is currently processing"),
    },
    testing: {
      icon: "condition",
      label: () => t("This campaign is currently testing"),
    },
    abx: {
      icon: "condition",
      label: () => t("This is an abx campaign item"),
    },
    failed: {
      icon: "cancel-badge",
      label: () => t("Campaign not send"),
    },
    triggered: {
      icon: "play",
      label: () => t("This is automation campaign"),
    },
    stopped: {
      icon: "cancel-badge",
      label: () => t("This is stopped"),
    },
    blocked: {
      icon: "cancel-badge",
      label: () => t("Campaign is blocked"),
    },
    deleted: {
      icon: "cancel-badge",
      label: () => t("Campaign is deleted"),
    },
    canceled: {
      icon: "cancel-badge",
      label: () => t("Campaign is canceled"),
    },
    suspended: {
      icon: "pause",
      label: () => t("Campaign is suspended"),
    },
    inqueue: {
      icon: "hourglass",
      label: () => t("Campaign is in queue"),
    },
    transactional: {
      icon: "play",
      label: () => t("This is transactional campaign"),
    },
  };

export function canCompileTest(test: string) {
  /**
   * Warning: If you modify this value please modify also on backend side:
   * /({{(.*)}})|({%(.*)%})/ig on push.js and Render.js
   */
  return /({{(.*)}})|({%(.*)%})/gi.test(test);
}

export const automationStepsConfig: IMap<{
  icon: string;
  dataTip: () => string;
}> = {
  tag: {
    icon: "tag",
    dataTip: () => t("Tags"),
  },
  update: {
    icon: "pencil",
    dataTip: () => t("Update"),
  },
  sendPush: {
    icon: "bell",
    dataTip: () => t("Send push"),
  },
  get: {
    icon: "cloud-sync",
    dataTip: () => t("Get"),
  },
};

export const abTestStateConfig: IMap<{
  color: string;
  background: string;
  border: string;
  icon?: string;
  label: () => string;
  dataTip: () => string;
}> = {
  draft: {
    color: MainTheme.colors.tertiary.gray700,
    background: MainTheme.colors.tertiary.gray100,
    border: MainTheme.colors.tertiary.gray400,
    icon: "folder",
    label: () => t("Draft"),
    dataTip: () => t("Draft"),
  },
  accepted: {
    color: MainTheme.colors.tertiary.blue800,
    background: MainTheme.colors.tertiary.blue100,
    border: MainTheme.colors.tertiary.blue500,
    icon: "ok-badge",
    label: () => t("Accepted"),
    dataTip: () => t("Test accepted by user"),
  },
  preparing: {
    color: MainTheme.colors.tertiary.gray700,
    background: MainTheme.colors.tertiary.gray100,
    border: MainTheme.colors.tertiary.gray400,
    icon: "clock",
    label: () => t("Preparing"),
    dataTip: () =>
      t("We are preparing everything for your test under the hood"),
  },
  test_ready_to_send: {
    color: MainTheme.colors.tertiary.blue800,
    background: MainTheme.colors.tertiary.blue100,
    border: MainTheme.colors.tertiary.blue500,
    icon: "hourglass",
    label: () => t("Test ready to send"),
    dataTip: () => t("Test is ready to send"),
  },
  test_sending: {
    color: MainTheme.colors.tertiary.yellow800,
    background: MainTheme.colors.tertiary.yellow100,
    border: MainTheme.colors.tertiary.yellow500,
    icon: "send",
    label: () => t("Test sending"),
    dataTip: () => t("Test is being sent to chosen sample"),
  },
  test_sent: {
    color: MainTheme.colors.tertiary.blue800,
    background: MainTheme.colors.tertiary.blue100,
    border: MainTheme.colors.tertiary.blue500,
    icon: "ok-badge",
    label: () => t("Test sent"),
    dataTip: () => t("Test is sent"),
  },
  manual_winner: {
    color: MainTheme.colors.tertiary.yellow800,
    background: MainTheme.colors.tertiary.yellow100,
    border: MainTheme.colors.tertiary.yellow500,
    icon: "winner-cup",
    label: () => t("Choose the winner"),
    dataTip: () => t("Action needed, choose the winner"),
  },
  winner_ready_to_send: {
    color: MainTheme.colors.tertiary.yellow800,
    background: MainTheme.colors.tertiary.yellow100,
    border: MainTheme.colors.tertiary.yellow500,
    icon: "send",
    label: () => t("Winner ready to send"),
    dataTip: () => t("Winner campaign is ready to be send"),
  },
  winner_sending: {
    color: MainTheme.colors.tertiary.yellow800,
    background: MainTheme.colors.tertiary.yellow100,
    border: MainTheme.colors.tertiary.yellow500,
    icon: "send",
    label: () => t("Winner sending"),
    dataTip: () => t("Winner campaign is being sent"),
  },
  finished: {
    color: MainTheme.colors.tertiary.green800,
    background: MainTheme.colors.tertiary.green100,
    border: MainTheme.colors.tertiary.green500,
    icon: "ok-badge",
    label: () => t("Finished"),
    dataTip: () => t("Test is finished"),
  },
  failed: {
    color: MainTheme.colors.action.error.text,
    background: MainTheme.colors.action.error.background,
    border: MainTheme.colors.action.error.border,
    icon: "warning-badge",
    label: () => t("Failed"),
    dataTip: () => t("Test has status failed, something went wrong"),
  },
  canceled: {
    color: MainTheme.colors.action.error.text,
    background: MainTheme.colors.action.error.background,
    border: MainTheme.colors.action.error.border,
    icon: "cancel-badge",
    label: () => t("Canceled"),
    dataTip: () => t("Test has been canceled"),
  },
};
