import * as React from "react";
import { observer } from 'mobx-react';
import { Input, Select } from '@ppg/styled';
import { SegmentsStore } from '../../../stores/project/SegmentsStore';
import * as Styled from './SegmentCreator.styled';
import { t } from '../../../base/helpers';
import { useEffect, useRef } from 'react';
import { PeriodDate, SegmentCondition } from '../../../modelsMobx/segmentation/SegmentCondition';

interface ISegmentConditionValueDate {
  condition: SegmentCondition;
}

export const SegmentConditionValueDate = observer(({ condition }: ISegmentConditionValueDate) => {
  const { value, operator } = condition;

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (value) {
      const [ttl, period] = condition.convertSecondsToTime(Number(value));

      condition.setValueDateTtl(parseFloat(ttl.toFixed()));
      condition.setValueDatePeriod(period as PeriodDate);
    }

    condition.setConditionValueForDate();

    return () => {
      condition.clearValueDate();
    };
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    condition.clearValueDate();
  }, [operator]);

  const onChange = (): void => {
    condition.setConditionValueForDate();
  };

  return <Styled.SegmentConditionValueTypeDate>
    <Input
      label={ t('segments/new::Value') }
      type="number"
      min={ SegmentsStore.MIN_VALUE_TYPE_NUMBER_CONDITION }
      property={ condition.getProperty('valueDateTtl') }
      onChange={ () => onChange() }
    />
    <Select
      label={ t('segments/new::Period') }
      property={ condition.getProperty('valueDatePeriod') }
      onChange={ () => onChange() }
      options={ condition.periodDateOptions }/>

    <span className="preview-date">
      { `${ t('segments/new::Example today will compare with:') }
      ${ condition.previewDate }` }</span>
  </Styled.SegmentConditionValueTypeDate>;
});