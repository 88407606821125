import { API, UseCase } from "@ppg/common";

interface IUpdateAppSettingUseCase {
  project: string;
  mobileFeaturesEnabled: boolean;
  showSentBy: boolean;
  forcePoweredByPPGEnabled: boolean;
  isDemoProject: boolean;
  enableSendingDelay: boolean;
  defaultTitleEnabled: boolean;
}

export class UpdateAppSettingUseCase extends UseCase<IUpdateAppSettingUseCase, void> {
  protected async execute({ project, mobileFeaturesEnabled, showSentBy, forcePoweredByPPGEnabled, isDemoProject, enableSendingDelay, defaultTitleEnabled }: IUpdateAppSettingUseCase) {
    return API.HTTP.post<void>(`ah/projects/${ project }/settings`, { mobileFeaturesEnabled, showSentBy, forcePoweredByPPGEnabled, isDemoProject, enableSendingDelay, defaultTitleEnabled });
  }
}

export const updateAppSettingUseCase = new UpdateAppSettingUseCase();
