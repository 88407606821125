import { API, UseCase } from '@ppg/common';
import { getListQueryParam, getRangeDateParam } from '../../../helpers';
import { IComparatorStatisticsDay } from '../campaigns/GetCampaignsComparatorHistogramUseCase';
import { IOrganizationDashboardRequestBody } from '../../../interfaces';

export interface IGetAutomationComparatorTableRequest extends IOrganizationDashboardRequestBody {
  offset: number;
  limit: number;
}

interface AutomationComparatorStatistics {
  automationHistogramList: {
    total: number;
    histogram: IComparatorStatisticsDay[];
  };
}

export class GetAutomationComparatorTableUseCase extends UseCase<IGetAutomationComparatorTableRequest, AutomationComparatorStatistics> {
  protected async execute({
                            organization,
                            platform,
                            from,
                            to,
                            timezone,
                            offset,
                            limit,
                          }: IGetAutomationComparatorTableRequest) {
    return API.HTTP.get<AutomationComparatorStatistics>(
      `statistics/organizations/${ organization }/campaigns/statistics/automation/comparator/histogram/list${ getRangeDateParam(from, to) }&timezone=${ timezone }${ getListQueryParam(offset, limit) }`
    );
  }
}
