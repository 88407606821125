import { ElementIdType } from "../../SubscriptionFormEnums";

export const tinyStylesMap = {
  [ElementIdType.BACKDROP]: {
    display: 'none',
    background: 'none',
    width: '100%',
    height: '100%',
    zIndex: 99999999999,
    position: 'fixed',
    top: 0,
    left: 0,
  },
  [ElementIdType.FORM]: {
    backgroundColor: '#ffffff',
    boxShadow: '0px 10px 40px 3px rgba(0, 0, 0, 0.3)',
    width: '490px',
    height: 'auto',
    borderRadius: '0',
    display: 'flex',
    zIndex: 999999999999,
    position: 'fixed',
    lineHeight: '1.2',
    padding: '18px',
    boxSizing: 'border-box',
    flexDirection: 'row',
    top: '0',
    left: '50%',
    transform: 'translateX(-50%)',
    fontFamily: 'sans-serif',
    '@media (max-width: 480px)': {
      top: '0',
      width: '100%',
      transform: 'translate(-50%)',
      padding: '12px',
    },
  },
  [ElementIdType.PICTURE]: {
    background: '#f1f1f1',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '80px',
    width: '80px',
    flexShrink: '0',
    marginBottom: '10px',
    '@media (min-width: 481px) and (max-width: 700px)': {
    },
    '@media (max-width: 480px)': {
      height: '80px',
      width: '80px',
      marginBottom: '10px',
    },
  },
  [ElementIdType.CONTENT]: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: '100%',
    '@media (min-width: 481px) and (max-width: 700px)': {
    },
    '@media (max-width: 480px)': {
    },
  },
  [ElementIdType.HEADER]: {
    color: '#585858',
    textAlign: 'left',
    alignSelf: 'stretch',
    fontSize: '18px',
    direction: 'ltr',
    marginBottom: '5px',
    padding: '0 3px 0 15px',
    '@media (max-width: 480px)': {
      lineHeight: '1.2em',
      fontSize: '14px',
    }
  },
  [ElementIdType.DESCRIPTION]: {
    fontSize: '14px',
    color: '#585858',
    textAlign: 'left',
    direction: 'ltr',
    alignSelf: 'stretch',
    lineHeight: '24px',
    padding: '0 3px 0 15px',
    marginBottom: '5px',
    '@media (max-width: 480px)': {
      lineHeight: '1.2em',
      fontSize: '12px',
    }
  },
  [ElementIdType.BUTTONS]: {
    alignSelf: 'flex-end',
    '@media (max-width: 480px)': {
      fontSize: '12px',
      lineHeight: '14px',
    },
  },
  [ElementIdType.DENY]: {
    margin: '5px',
    borderStyle: 'solid',
    borderWidth: '2px',
    height: '33px',
    lineHeight: '1em',
    padding: '0 15px',
    direction: 'ltr',
    textDecoration: 'none',
    fontSize: '15px',
    color: '#808080',
    textTransform: 'none',
    borderColor: '#ffffff',
    fontWeight: 400,
    backgroundColor: '#ffffff',
    backgroundImage: 'none',
    cursor: 'pointer',
    overflow: 'visible',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    position: 'relative',
    verticalAlign: 'baseline',
    whiteSpace: 'nowrap',
    transition: 'background-color .2s, border-color .2s',
    '&::after, &::before': {
      content: 'none'
    },
    '&:hover': {
      color: '#808080',
    },
    '@media (max-width: 480px)': {
      height: '30px',
      padding: '0 10px',
      margin: '2px',
      fontSize: '12px',
    }
  },
  [ElementIdType.ALLOW]: {
    margin: '5px',
    height: '33px',
    lineHeight: '1em',
    fontSize: '15px',
    padding: '0 15px',
    borderStyle: 'solid',
    direction: 'ltr',
    borderWidth: '2px',
    color: '#F9F9F9',
    textDecoration: 'none',
    textTransform: 'none',
    borderColor: '#16a085',
    fontWeight: '700',
    backgroundColor: '#16a085',
    backgroundImage: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    overflow: 'visible',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    position: 'relative',
    verticalAlign: 'baseline',
    whiteSpace: 'nowrap',
    transition: 'background-color .2s, border-color .2s',
    '&::after, &::before': {
      content: 'none'
    },
    '&:hover': {
      color: '#F9F9F9',
    },
    '@media (max-width: 480px)': {
      height: '30px',
      padding: '0 10px',
      margin: '2px',
      fontSize: '12px',
    }
  },
  [ElementIdType.POWERED_BY]: {
    position: 'absolute',
    bottom: '5px',
    fontSize: '10px',
    left: '18px',
    right: 'auto',
    '@media (max-width: 480px)': {
      fontSize: '9px',
      bottom: '3px',
      left: '12px',
      right: 'auto',
    }
  },
  [ElementIdType.POWERED_BY_TEXT]: {
    color: '#70787f',
    textDecoration: 'none',
    display: 'inline',
    fontSize: '10px',
    fontWeight: 400,
    transition: 'none',
    verticalAlign: 'baseline',
    letterSpacing: 'normal',
    position: 'relative',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.2',
    '&:link, &:visited, &:active': {
      color: '#70787f',
      textDecoration: 'none',
    },
    '&::after, &::before': {
      content: 'none'
    },
    '@media (max-width: 480px)': {
      fontSize: '9px',
    }
  }
};

export const tinyRtlStylesMap = {
  [ElementIdType.BACKDROP]: {
    display: 'none',
    background: 'none',
    width: '100%',
    height: '100%',
    zIndex: 99999999999,
    position: 'fixed',
    top: 0,
    left: 0,
  },
  [ElementIdType.FORM]: {
    backgroundColor: '#ffffff',
    boxShadow: '0px 10px 40px 3px rgba(0, 0, 0, 0.3)',
    width: '490px',
    height: 'auto',
    borderRadius: '0',
    display: 'flex',
    zIndex: 999999999999,
    position: 'fixed',
    lineHeight: '1.2',
    padding: '18px',
    boxSizing: 'border-box',
    flexDirection: 'row-reverse',
    top: '0',
    left: '50%',
    transform: 'translateX(-50%)',
    fontFamily: 'sans-serif',
    '@media (max-width: 480px)': {
      top: '0',
      width: '100%',
      transform: 'translate(-50%)',
      padding: '12px',
    },
  },
  [ElementIdType.PICTURE]: {
    background: '#f1f1f1',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '80px',
    width: '80px',
    flexShrink: '0',
    marginBottom: '10px',
    '@media (min-width: 481px) and (max-width: 700px)': {
    },
    '@media (max-width: 480px)': {
      height: '80px',
      width: '80px',
      marginBottom: '10px',
    },
  },
  [ElementIdType.CONTENT]: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    '@media (min-width: 481px) and (max-width: 700px)': {
    },
    '@media (max-width: 480px)': {
    },
  },
  [ElementIdType.HEADER]: {
    color: '#585858',
    textAlign: 'right',
    direction: 'rtl',
    alignSelf: 'stretch',
    fontSize: '18px',
    marginBottom: '5px',
    padding: '0 15px 0 3px',
    '@media (max-width: 480px)': {
      lineHeight: '1.2em',
      fontSize: '14px',
    }
  },
  [ElementIdType.DESCRIPTION]: {
    fontSize: '14px',
    color: '#585858',
    textAlign: 'right',
    direction: 'rtl',
    alignSelf: 'stretch',
    lineHeight: '24px',
    padding: '0 15px 0 3px',
    marginBottom: '5px',
    '@media (max-width: 480px)': {
      lineHeight: '1.2em',
      fontSize: '12px',
    }
  },
  [ElementIdType.BUTTONS]: {
    alignSelf: 'flex-start',
    '@media (max-width: 480px)': {
      fontSize: '12px',
      lineHeight: '14px',
    },
  },
  [ElementIdType.DENY]: {
    margin: '5px',
    borderStyle: 'solid',
    borderWidth: '2px',
    height: '33px',
    lineHeight: '1em',
    padding: '0 15px',
    textDecoration: 'none',
    direction: 'rtl',
    fontSize: '15px',
    color: '#808080',
    textTransform: 'none',
    borderColor: '#ffffff',
    fontWeight: 400,
    backgroundColor: '#ffffff',
    cursor: 'pointer',
    overflow: 'visible',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    position: 'relative',
    verticalAlign: 'baseline',
    whiteSpace: 'nowrap',
    transition: 'background-color .2s, border-color .2s',
    '&::after, &::before': {
      content: 'none',
    },
    '&:hover': {
      color: '#808080',
    },
    '@media (max-width: 480px)': {
      height: '30px',
      padding: '0 10px',
      margin: '2px',
      fontSize: '12px',
    }
  },
  [ElementIdType.ALLOW]: {
    margin: '5px',
    height: '33px',
    lineHeight: '1em',
    fontSize: '15px',
    padding: '0 15px',
    borderStyle: 'solid',
    borderWidth: '2px',
    color: '#F9F9F9',
    direction: 'rtl',
    textDecoration: 'none',
    textTransform: 'none',
    borderColor: '#16a085',
    fontWeight: '700',
    backgroundColor: '#16a085',
    borderRadius: '5px',
    cursor: 'pointer',
    overflow: 'visible',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    position: 'relative',
    verticalAlign: 'baseline',
    whiteSpace: 'nowrap',
    transition: 'background-color .2s, border-color .2s',
    '&::after, &::before': {
      content: 'none'
    },
    '&:hover': {
      color: '#F9F9F9',
    },
    '@media (max-width: 480px)': {
      height: '30px',
      padding: '0 10px',
      margin: '2px',
      fontSize: '12px',
    }
  },
  [ElementIdType.POWERED_BY]: {
    position: 'absolute',
    bottom: '5px',
    fontSize: '10px',
    left: 'auto',
    right: '18px',
    '@media (max-width: 480px)': {
      fontSize: '9px',
      bottom: '3px',
      right: '12px',
      left: 'auto',
    }
  },
  [ElementIdType.POWERED_BY_TEXT]: {
    color: '#70787f',
    textDecoration: 'none',
    display: 'inline',
    fontSize: '10px',
    fontWeight: 400,
    transition: 'none',
    verticalAlign: 'baseline',
    letterSpacing: 'normal',
    position: 'relative',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.2',
    '&:link, &:visited, &:active': {
      color: '#70787f',
      textDecoration: 'none',
    },
    '&::after, &::before': {
      content: 'none'
    },
    '@media (max-width: 480px)': {
      fontSize: '9px',
    }
  }
};

export const poweredByProperties = {
  href: 'https://pushpushgo.com/en/?utm_medium=form&utm_source=https://n.porannakawka.com/&utm_campaign=referal',
  target: '_blank'
};

export const tinyAnimations = {
  '@keyframes subtleAppear': {
    from: { opacity: .2 },
    to: { opacity: 1 }
  },
  '@keyframes slideTop': {
    from: {
      opacity: 0,
      transform: 'translate(-50%, -100%)'
    },
    to: {
      opacity: 1,
      transform: 'translate(-50%, -50%)'
    }
  },
  '@keyframes top-slideTop': {
    from: {
      opacity: 0,
      transform: 'translate(-50%, -50%)'
    },
    to: {
      opacity: 1,
      transform: 'translate(-50%, 0)'
    }
  },
  '@keyframes slideBottom': {
    from: {
      opacity: 0,
      transform: 'translate(-50%, 0%)'
    },
    to: {
      opacity: 1,
      transform: 'translate(-50%, -50%)'
    }
  },
  '@keyframes top-slideBottom': {
    from: {
      opacity: 0,
      transform: 'translate(-50%, 50%)'
    },
    to: {
      opacity: 1,
      transform: 'translate(-50%, 0)'
    }
  },
  '@keyframes jumpTop': {
    '0%': {
      transform: 'translate(-50%, -90%)',
      opacity: 0,
    },
    '60%': {
      transform: 'translate(-50%, -45%)',
      opacity: 1,
    },
    '80%': {
      transform: 'translate(-50%, -52%)',
    },
    '100%': {
      transform: 'translate(-50%, -50%)',
    }
  },
  '@keyframes top-jumpTop': {
    '0%': {
      transform: 'translate(-50%, -40%)',
      opacity: 0,
    },
    '60%': {
      transform: 'translate(-50%, 5%)',
      opacity: 1,
    },
    '80%': {
      transform: 'translate(-50%, -2%)',
    },
    '100%': {
      transform: 'translate(-50%, 0)',
    }
  },
  '@keyframes jumpBottom': {
    '0%': {
      transform: 'translate(-50%, 0%)',
      opacity: 0,
    },
    '60%': {
      transform: 'translate(-50%, -55%)',
      opacity: 1,
    },
    '80%': {
      transform: 'translate(-50%, -48%)',
    },
    '100%': {
      transform: 'translate(-50%, -50%)',
    }
  }, '@keyframes top-jumpBottom': {
    '0%': {
      transform: 'translate(-50%, 50%)',
      opacity: 0,
    },
    '60%': {
      transform: 'translate(-50%, -5%)',
      opacity: 1,
    },
    '80%': {
      transform: 'translate(-50%, 2%)',
    },
    '100%': {
      transform: 'translate(-50%, 0)',
    }
  },
};
