import {
  isCorrectRedirectLink,
  isNotEmpty,
  property,
  PropertyHandler,
} from "@ppg/common";
import { Image, ImageFormatType, ImageType } from "./Image";
import { IPushAction, PushAction } from "./PushAction";
import { CampaignDirectionType } from "./PushCampaign";
import { action, computed } from "mobx";
import * as DummyIcon from "../assets/images/dummy-icon.png";
import { projectImageStore } from "../stores";
import { CAMPAIGN_ACTION_LIMIT, dummyImage } from "../constants";
import { Utm } from "./helpers/Utm";
import { canCompileTest } from "../base/helpers";
import { Render } from "../components/commonMobX";
import { IDateVariables } from "../components/commonMobX/Render";

export interface IABVariant {
  name?: string;
  id: string;
  title: string;
  content: string;
  icon: Image;
  image: Image;
  actions?: PushAction[];
  direction: CampaignDirectionType;
  requireInteraction: boolean;
  redirectLink: string;

  totalClicked?: number;
  clicked?: number;
  sent?: number;
  delivered?: number;
  dsp?: number;
  ctr?: number;
  lost?: number;
  actionPrimary?: number;
  actionSecondary?: number;
}

interface IBaseVariant {
  title: string;
  content: string;
  icon: string;
  image: string;
  requireInteraction: boolean;
}

interface IVariantPreview extends IBaseVariant {
  iconURL: string;
  defaultIcon: string;
  actions: string[];
  isDirectionRtl: string;
}

export interface ITestVariant extends IBaseVariant {
  redirectLink: string;
}

interface IDirectionValue {
  isDirectionRtl: boolean;
}

interface IPushActionValue {
  pushActions: IPushAction[];
}

interface IRequireInteractionValue {
  requireInteraction: boolean;
}

interface IRedirectLinkValue {
  redirectLink: string;
  utm: Utm;
}

interface IDefaultTitleValue {
  isDefaultTitleEnabled: boolean;
  defaultTitle: string;
}

interface ISerializedVariant {
  id: string;
  name: string;
  title: string;
  content: string;
  iconOwnerID: string;
  imageOwnerID: string;
  actions: {
    title: string;
    link: string;
  }[];
  direction: string;
  requireInteraction: boolean;
  redirectLink: string;
}

export interface IVariantScope extends IDateVariables {
  title: string;
  redirectLink: string;
}

type IProjectValues = IDirectionValue &
  IPushActionValue &
  IRequireInteractionValue &
  IRedirectLinkValue &
  IDefaultTitleValue & { projectLogo: Image | null };

interface ICollapse {
  title: number;
  content: number;
}

interface IAppendUTMs {
  utm: Utm;
  url: URL;
}

interface ISetStatistics {
  totalClicked: number;
  sent: number;
  delivered: number;
  dsp: number;
  ctr: number;
  clicked: number;
  lost: number;
  actionPrimary: number;
  actionSecondary: number;
}

export class ABVariant extends PropertyHandler implements IABVariant {
  @property() public name;
  @property() public id;
  @property([isNotEmpty()]) public title;
  @property([isNotEmpty()]) public content;
  @property() public icon = null;
  @property() public image = null;

  @property([isCorrectRedirectLink()]) public redirectLink;
  @property() public isGetDataFromRedirectLink: boolean = false;

  @property() public actions = [];
  @property() public direction;
  @property() public requireInteraction = false;

  @property() public totalClicked = 0;
  @property() public clicked = 0;
  @property() public sent = 0;
  @property() public delivered = 0;
  @property() public dsp = 0;
  @property() public ctr = 0;
  @property() public actionPrimary = 0;
  @property() public actionSecondary = 0;
  @property() public lost = 0;

  public static imageCropWidth: number = 240;
  public static imageCropHeight: number = 360;
  public static safariCollapse: ICollapse = { title: 22, content: 24 };
  public static campaignCollapse: ICollapse = { title: 50, content: 120 };
  public static characterLimitTitle = 50;
  public static characterLimitMessage = 120;

  public constructor(abVariant: IABVariant) {
    super();
    this.name = abVariant.name || "";
    this.id = abVariant.id;
    this.title = abVariant.title;
    this.content = abVariant.content;
    this.icon = abVariant.icon ? new Image(abVariant.icon) : null;
    this.image = abVariant.image ? new Image(abVariant.image) : null;
    this.actions = abVariant.actions
      ? abVariant.actions.map((action) => new PushAction(action))
      : [];
    this.direction = abVariant.direction;
    this.requireInteraction = abVariant.requireInteraction;
    this.redirectLink = abVariant.redirectLink;
  }

  // Actions

  @computed
  public get hasActions(): boolean {
    return this.actions.length > 0;
  }

  @computed
  public get canAddAction(): boolean {
    return this.actions.length < CAMPAIGN_ACTION_LIMIT;
  }

  @action
  public addAction = (): void => {
    const action = PushAction.createPushAction();
    this.actions = this.actions.concat([action]);
  };

  @action
  public deleteAction = (action: PushAction): void => {
    this.actions = this.actions.filter((a) => a !== action);
  };

  public getPreviewActions = (): string[] => {
    return this.actions.map((action) => action.title);
  };

  @computed
  public get isTitleValid(): boolean {
    return this.title !== "";
  }

  @computed
  public get isContentValid(): boolean {
    return this.content !== "";
  }

  // Image

  @computed
  public get isDefaultIcon(): boolean {
    return (
      this.getCurrentIcon() === this.getDefaultIcon() ||
      this.getCurrentIcon() === dummyImage.url
    );
  }

  @computed
  public get getImage(): string {
    return this.image?.url;
  }

  @computed
  public get hasBigImage(): boolean {
    return !!this.image;
  }

  public getCurrentIcon(): string {
    return this.icon?.url || null;
  }

  @computed
  public get getIcon(): string {
    return this.iconURL || DummyIcon;
  }

  @computed
  public get iconURL(): string {
    return this.icon?.url;
  }

  @computed
  public get dummyIcon(): string {
    return DummyIcon;
  }

  @computed
  public get getPreviewIcon(): string {
    return this.icon?.url || this.dummyIcon;
  }

  @computed
  public get getPreviewImage(): string {
    return this.image?.url || null;
  }

  public getDefaultIcon(): string {
    const logo = projectImageStore.getLogoURL(ImageFormatType.MEDIUM);
    return logo || this.dummyIcon;
  }

  @action
  public setDefaultIcon = (): void => {
    if (this.getDefaultIcon() === this.dummyIcon) {
      this.icon = dummyImage;
      return;
    }
    this.icon = projectImageStore.getImageByType(ImageType.LOGO);
  };

  public get toSendTestVariant(): ITestVariant {
    return {
      title: this.title,
      redirectLink: this.redirectLink,
      content: this.content,
      requireInteraction: this.requireInteraction,
      icon: this.icon ? this.icon.url : this.getDefaultIcon(),
      image: this.getImage,
    };
  }

  public getVariantPreview = (): IVariantPreview => {
    return {
      title: this.title,
      content: this.content,
      image: this.getImage,
      icon: this.getIcon,
      requireInteraction: this.requireInteraction,
      iconURL: this.iconURL,
      defaultIcon: this.getDefaultIcon(),
      actions: this.actions.map((action) => action.title),
      isDirectionRtl: this.direction,
    };
  };

  // Redirect link

  @action
  public setGetDataFromRedirectLink = (isGetData: boolean): void => {
    this.isGetDataFromRedirectLink = isGetData;
  };

  // Setting project default values

  @action
  public setProjectValues({
    isDirectionRtl,
    pushActions,
    requireInteraction,
    redirectLink,
    utm,
    isDefaultTitleEnabled,
    defaultTitle,
    projectLogo,
  }: IProjectValues) {
    this.setDirectionSetting({ isDirectionRtl });
    this.setStartPushActions({ pushActions });
    this.setStartRequireInteraction({ requireInteraction });
    this.setStartRedirectLink({ redirectLink, utm });
    this.setDefaultTitleValue({
      isDefaultTitleEnabled,
      defaultTitle,
    });
    this.setProjectLogo(projectLogo);
  }

  @action
  private setDefaultTitleValue({
    isDefaultTitleEnabled,
    defaultTitle,
  }: IDefaultTitleValue): void {
    if (!isDefaultTitleEnabled) return;
    this.title = defaultTitle;
  }

  @action
  private setProjectLogo(projectLogo: Image | null): void {
    if (!projectLogo) return;
    this.icon = projectLogo;
  }

  @action
  private setDirectionSetting({ isDirectionRtl }: IDirectionValue): void {
    this.direction = isDirectionRtl
      ? CampaignDirectionType.RTL
      : CampaignDirectionType.AUTO;
  }

  @action
  private setStartPushActions({ pushActions }: IPushActionValue): void {
    //  for edit
    if (this.hasActions) return;
    const actions = pushActions.map((a) => new PushAction(a));
    this.actions = this.actions.concat([...actions]);
  }

  @action
  private setStartRequireInteraction({
    requireInteraction,
  }: IRequireInteractionValue): void {
    this.requireInteraction = requireInteraction;
  }

  @action
  private setStartRedirectLink({
    redirectLink,
    utm,
  }: IRedirectLinkValue): void {
    this.redirectLink = this.addProjectUTMs({ utm, link: redirectLink });
  }

  public addProjectUTMs = ({ utm, link }): string => {
    let parsedUrl;

    try {
      if (canCompileTest(link)) {
        return link;
      }

      parsedUrl = new URL(link);

      if (!utm.disabled) {
        parsedUrl = this.appendUTMs({
          url: parsedUrl,
          utm,
        });
      }
    } catch (error) {
      console.error(error);
    }

    return this.encodeURIWithNunjucks(`${parsedUrl}`);
  };

  @computed
  public get hasDirectionRtl(): boolean {
    return this.direction === CampaignDirectionType.RTL;
  }

  public getVariantScope = (): IVariantScope => {
    return {
      title: this.title,
      redirectLink: this.redirectLink,
      ...Render.getDateVariablesScope(),
    };
  };

  public appendUTMs = ({ url, utm }: IAppendUTMs): URL => {
    if (!url.searchParams.has("utm_medium") && utm.isMediumOn)
      url.searchParams.append("utm_medium", utm.medium);

    if (!url.searchParams.has("utm_source") && utm.isSourceOn)
      url.searchParams.append("utm_source", utm.source);

    if (!url.searchParams.has("utm_campaign") && utm.isCampaignOn)
      url.searchParams.append("utm_campaign", utm.campaign);

    if (utm.customUtms.length > 0) {
      utm.customUtms.forEach((utm) => {
        !url.searchParams.has(`utm_${utm.parameter}`) &&
          url.searchParams.append(`utm_${utm.parameter}`, utm.value);
      });
    }

    return url;
  };

  public encodeURIWithNunjucks(parsedUrl: string): string {
    return parsedUrl.replace(/%7B/g, "{").replace(/%7D/g, "}");
  }

  @action
  public setStatistics = ({
    totalClicked,
    sent,
    delivered,
    dsp,
    ctr,
    clicked,
    lost,
    actionPrimary,
    actionSecondary,
  }: ISetStatistics): void => {
    this.totalClicked = totalClicked;
    this.sent = sent;
    this.delivered = delivered;
    this.dsp = Number((dsp * 100).toFixed(2));
    this.ctr = Number((ctr * 100).toFixed(2));
    this.clicked = clicked;
    this.lost = lost;
    this.actionPrimary = actionPrimary;
    this.actionSecondary = actionSecondary;
  };

  private serializePushAction(action: PushAction): {
    useRedirectLink: boolean;
    title: string;
    link: string;
  } {
    if (action.useRedirectLink) {
      return {
        title: action.title,
        link: this.redirectLink,
        useRedirectLink: action.useRedirectLink,
      };
    } else {
      return action.serialize();
    }
  }

  public serialize(): ISerializedVariant {
    console.log({
      icon: this.icon,
      image: this.image,
      id: this.id,
      name: this.name,
      title: this.title,
      content: this.content,
      iconOwnerID: this.icon?.owner,
      imageOwnerID: this.image?.owner,
      actions: this.actions.map((action) => this.serializePushAction(action)),
      direction: this.direction,
      requireInteraction: this.requireInteraction,
      redirectLink: this.redirectLink,
    });

    return {
      id: this.id,
      name: this.name,
      title: this.title,
      content: this.content,
      iconOwnerID: this.icon?.owner,
      imageOwnerID: this.image?.owner,
      actions: this.actions.map((action) => this.serializePushAction(action)),
      direction: this.direction,
      requireInteraction: this.requireInteraction,
      redirectLink: this.redirectLink,
    };
  }
}
