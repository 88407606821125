interface IActionType {
  base: string,
  text: string,
  border: string,
  background: string,
}

export interface IActionColors {
  error: IActionType,
  warning: IActionType,
  success: IActionType
}

export const action: IActionColors = {
  error: {
    base: '#e8575e',
    text: '#A74341',
    border: '#e4a1ac',
    background: '#F2DEDF',
  },
  warning: {
    base: '#F9B226',
    text: '#867457',
    border: '#f7d7a5',
    background: '#ffe3b6',
  },
  success: {
    base: '#16a085',
    text: '#0F7561',
    border: '#AFDAD1',
    background: '#C8EFE7',
  },
};
