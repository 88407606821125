import { property, PropertyHandler } from "@ppg/common";
import { action, computed, observable } from "mobx";
import { IProviderAction } from "../../useCases/core/providers/GetProjectProviderUseCase";
import { t } from "../../base/helpers";

interface IFCMProvider {
  fcmName: string;
  fcmSenderId: string;
  authorizationKey: string;

  actions: IProviderAction[];
}

export class FCMProvider extends PropertyHandler implements IFCMProvider {
  @property() public fcmName: string;
  @property() public fcmSenderId: string;
  @property() public authorizationKey: string;
  @property() public fileName: string;
  @property() public v1: string;

  @observable public actions;
  @observable public isAuthKeyUnmasked: boolean = false;

  constructor({
    fcmSenderId,
    fcmName,
    authorizationKey,
    actions,
  }: IFCMProvider) {
    super();
    this.fcmName = fcmName;
    this.fcmSenderId = fcmSenderId;
    this.authorizationKey = authorizationKey;
    this.actions = actions;
  }

  static createProvider(IFCMProviderDTO?: IFCMProvider): FCMProvider {
    if (IFCMProviderDTO) {
      return new FCMProvider(IFCMProviderDTO);
    } else {
      return FCMProvider.createDefault();
    }
  }

  private getFileAndValidate = (
    event: React.ChangeEvent<HTMLInputElement>
  ): File => {
    const file = event.target.files[0];
    if (file.name.indexOf(".json") === -1) {
      alert(t("Please upload .json file"));
      event.target.value = null;
      return null;
    }

    return file;
  };

  private readAsText = async (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      const timer = setTimeout(reject, 2000);

      reader.addEventListener(
        "load",
        function (event: ProgressEvent<FileReader>) {
          resolve(event.target.result as string);
          window.clearTimeout(timer);
        }
      );

      reader.readAsText(file);
      reader.onload = () => resolve(reader.result?.toString() || "");
      reader.onerror = (error) => reject(error);
    });
  };

  private async readAndParseFile(file) {
    try {
      return JSON.parse(await this.readAsText(file));
    } catch (error) {
      alert(
        t("Cannot parse file as JSON please confirm that you upload .json file")
      );

      console.error(error);
      return null;
    }
  }

  public onKeyUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const file = this.getFileAndValidate(event);

    if (!file) return;

    this.v1 = await this.readAndParseFile(file);
    this.fileName = file.name;
  };

  @action
  public setAuthKeySecret = (key: string): void => {
    this.authorizationKey = key;
  };

  @computed
  public get isIntegrated(): boolean {
    return !this.actions.includes(IProviderAction.CREATE);
  }

  @computed
  public get isIntegratedV1(): boolean {
    return !this.actions.includes(IProviderAction.MIGRATE_TO_V1);
  }

  @computed
  public get canRequestPassword(): boolean {
    return (
      this.actions.includes(IProviderAction.GET) ||
      this.actions.includes(IProviderAction.CREATE)
    );
  }

  @computed
  public get hasUnmaskedAppSecret(): boolean {
    return this.isAuthKeyUnmasked;
  }

  static createDefault(): FCMProvider {
    return new FCMProvider({
      fcmName: "",
      authorizationKey: "",
      fcmSenderId: "",
      actions: [],
    });
  }
}
