import { LANGUAGES, localProperty, PropertyHandler } from '@ppg/common';
import { action, observable } from 'mobx';
import { projectStore } from '..';
import { apiManager, connector } from '../../base';
import { WebUser } from '../../models';
import { User } from '../../modelsMobx/User';
import { changeUserDetailUseCase, changeUserLanguageUseCase, getUserDetailUseCase } from '../../useCases/app-helper';
import { checkTokenAndProlongateUseCase } from '../../useCases/auth-and-identity/CheckTokenAndProlongateUseCase';
import { getUserDetailsAAIUseCase, OrganizationTypes } from '../../useCases/auth-and-identity/GetUserDetailsUseCase';
import { logoutUseCase } from '../../useCases/auth-and-identity/LogoutUseCase';
import { AuthorizationStore } from './AuthorizationStore';
import { ChannelsStore } from './ChannelsStore';
import { PasswordStore } from './PasswordStore';
import { SettingsStore } from './SettingsStore';

export class UserStore extends PropertyHandler {
  @observable public isAuthorized: boolean = false;
  @observable public isPasswordExpired: boolean = false;

  @observable public user: User = new User();

  @localProperty() public token: string;
  @localProperty(null) public _csrf: string;
  @localProperty('en') public lang: string;

  @observable public settings: SettingsStore;
  @observable public authorization: AuthorizationStore;
  @observable public password: PasswordStore;
  @observable public notificationChannels: ChannelsStore;
  @observable public ownedProjects: string[];

  constructor() {
    super();

    this.settings = new SettingsStore(this);
    this.authorization = new AuthorizationStore(this);
    this.password = new PasswordStore(this);
    this.notificationChannels = new ChannelsStore(this);
    this.lang = this.lang && Object.keys(LANGUAGES).includes(this.lang) ? this.lang : 'en';
  }

  @action
  public async checkIsAuthorized(): Promise<void> {
    if (!this.token) {
      this.logoutCleanData();
      this.isAuthorized = false;
      return;
    }

    // @todo remove in the future
    apiManager.setHeader('x-token', this.token);

    return checkTokenAndProlongateUseCase.exec()
      .then(async () => {
        await this.fetchUserDetail();
        this.isAuthorized = true;
      })
      .catch(() => this.logoutCleanData())
  }

  public get isLite(): boolean {
    return this.user.organizationType === OrganizationTypes.LITE
  }

  public cleanStateForRegistrationForm() {
    this.password.clear();
    this.user = new User();
  }

  public async changeUserDetail() {
    await changeUserDetailUseCase.exec({
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      phone: this.user.phone,
      language: this.lang
    });
  }

  @action
  public setLanguage(language: string): void {
    this.lang = language;
    localStorage.setItem('lang', language);
  }

  @action
  public async getUserLanguage(): Promise<void> {
    return getUserDetailUseCase.exec()
      .then(async ({ language }) => {
        if (!language || (language !== this.lang)) {
          await this.changeUserLanguage();
        }
      });
  }

  @action
  public async changeUserLanguage(): Promise<void> {
    const lang = this.lang && Object.keys(LANGUAGES).includes(this.lang) ? this.lang : 'en';
    await changeUserLanguageUseCase.exec({ language: lang })
  }

  public async fetchUserDetail():Promise<void> {
    await Promise.all([
      getUserDetailsAAIUseCase.exec().then(user => user && this.user.parseUser(user)),
      getUserDetailUseCase.exec().then((user) => user && this.user.parseUser(user))
    ])
  }

  public async logout(): Promise<void> {
    this.isAuthorized && await logoutUseCase.exec().finally(() => this.logoutCleanData());
  }

  public logoutCleanData() {
    this.token = null;

    this.password.clear();

    this.user = new User();

    // @todo remove in the future
    apiManager.removeHeader('x-token');

    // @todo remove in the future
    // localStorage.removeItem('__pushpushgo');
    // localStorage.removeItem('__ppg');

    projectStore.currentProject = null;
    projectStore.currentProjectID = null;
    projectStore.websiteIntegrationStore.websiteIntegrationId = null;

    const user = connector.get<WebUser>('user');
    user.logout();

    this.isAuthorized = false;
  }
}

