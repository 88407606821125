import { API, UseCase } from '@ppg/common';

interface IListChannels {
  limit: number;
  offset: number;
  organization: string;
}

interface IListChannelsResponse {
  id: string;
  language: string;
  payload: string;
  type: string;
  tags: string[];
  status: string;
}

interface IListChannelsResult {
  data: IListChannelsResponse[];
  metadata: {
    total: number,
  }
}

export class ListChannelsUseCase extends UseCase<IListChannels, IListChannelsResult> {
  protected async execute({ organization, limit, offset }) {
    return API.HTTP.get<IListChannelsResult>(`notification/organizations/${ organization }/channels?offset=${ offset }&limit=${ limit }`);
  }
}

export const listChannelsUseCase = new ListChannelsUseCase();
