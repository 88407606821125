import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Modal, Input, Select } from '@ppg/styled';
import { t } from '../../../../base/helpers';
import { NotificationChannelsTable } from './NotificationChannelsTable';
import './NotificationChannels.scss';
import { ChannelsStore } from '../../../../stores/user/ChannelsStore';
import { UserStore } from '../../../../stores/user/UserStore';
import { UserHeader } from '../../Layouts/UserLayout/UserHeader/UserLayoutHeader';

interface INotificationChannels {
  channelsStore: ChannelsStore;
  userStore: UserStore;
}

@inject('channelsStore', 'userStore')
@observer
export class NotificationChannels extends React.Component<INotificationChannels> {

  async componentWillMount() {
    const { channelsStore } = this.props;
    await channelsStore.fetchChannels()
      .then(() => channelsStore.getAvailableOptions());

      this.props.channelsStore.setInfinity(this.props.userStore.settings.infinity)
  }

  componentWillUnmount() {
    this.props.channelsStore.reset();
  }

  async handleConfirm() {
    await this.props.channelsStore.createChannel();
  }

  public handleOnChange(event) {
    const tags = this.props.channelsStore.newChannel.getProperty('tags').getValue();
    const index = tags.indexOf(event.target.value);

    if (event.target.checked) {
      index === -1 && tags.push(event.target.value.toUpperCase())
    } else {
      index > -1 && tags.splice(index, 1);
    }
  }

  private renderModalContent() {
    const { channelsStore } = this.props;
    const languages = channelsStore.languages.map(lang => {
      return { name: lang, value: lang }
    });

    return channelsStore.newChannel && <div className='create-channel-wrapper'>
      <Input property={ channelsStore.newChannel.getProperty('payload') }
             label={ t('User email') }/>
      <Select property={ channelsStore.newChannel.getProperty('language') }
              className={ 'channel-language' }
              label={ t('Language') }
              options={ languages }/>
      <div className='channel-tags-wrapper'>
        <p className='channel-tags-title'>{ t('Category') }</p>
        { channelsStore.tags.map((tag, idx) => {
          return <label htmlFor={ tag } key={ idx }>
            <div className="checkbox-wrapper">
              <input type='checkbox' id={ tag }
                     name={ 'tags' }
                     onChange={ (e) => this.handleOnChange(e) }
                     value={ tag }/>
              <span/>
            </div>
            { tag.toLowerCase() }
          </label>
        }) }
      </div>
    </div>
  }

  public render() {
    const { channelsStore } = this.props;

    const newChannelButton = {
      icon: 'plus-badge',
      content: t('new channel'),
      size: 'medium',
      onClick: () => channelsStore.onChannelCreate()
    };

    return (
      <section className='section-notification-channels'>

        <UserHeader title={ t('Notification Channels') }
                    subtitle={ t('Manage notifications about your PushPushGo activities') }
                    button={newChannelButton} />

        <Modal show={ channelsStore.newChannel !== null }
               title={ <h1>{ t('Create new channel') }</h1> }
               content={ this.renderModalContent() }
               onCancel={ () => channelsStore.onChannelCancel() }
               confirmButton={ t('Save') }
               width={ 400 }
               onConfirm={ () => this.handleConfirm() }
               cancelButton={ t('Cancel') }/>

        <NotificationChannelsTable />
      </section>
    )
  }
}
