function defineGetter(type: string, propertyKey: string) {
  switch (type) {
    case 'date':
      return function () {
        const date = this.get(propertyKey);
        return date ? new Date(date) : null;
      };

    default:
      return function () {
        return this.get(propertyKey);
      };
  }
}

function defineSetter(type: string, propertyKey: string) {
  switch (type) {
    case 'date':
      return function (value: any) {
        value = new Date(value).toISOString();
        this.set(propertyKey, value);
      };

    default:
      return function (value: any) {
        this.set(propertyKey, value);
      };
  }
}

interface IOptions {
  type?: 'date';
}

export function property(options?: IOptions): PropertyDecorator {
  options = options || {};
  return (proto: Object, propertyKey: string) => {
    if (propertyKey === 'id') {
      throw `Do not declare model property named 'id' it causes maximum call stack`;
    }
    Object.defineProperty(proto, propertyKey, {
      get: defineGetter(options.type, propertyKey),
      set: defineSetter(options.type, propertyKey)
    });
  }
}