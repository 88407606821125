import { API, UseCase } from "@ppg/common";
import { IProvider } from "../interfaces";

interface IUpdateFCMProvider extends IProvider, IUpdateFCMProviderResponse {}

interface IUpdateFCMProviderResponse {
  authorizationKey: string;
  fcmSenderId: string;
  fcmName: string;
  v1: object;
}

export class UpdateFCMProviderUseCase extends UseCase<
  IUpdateFCMProvider,
  IUpdateFCMProviderResponse
> {
  protected async execute({
    authorizationKey,
    fcmSenderId,
    organizationId,
    fcmName,
    projectId,
    v1,
  }: IUpdateFCMProvider) {
    return API.HTTP.post<IUpdateFCMProviderResponse>(
      `core/organizations/${organizationId}/projects/${projectId}/providers/android`,
      {
        authorizationKey,
        fcmSenderId,
        fcmName,
        v1,
      }
    );
  }
}

export const updateFCMProviderUseCase = new UpdateFCMProviderUseCase();
