export const campaignTypeColors = [
  '#e8a838', '#f47560', '#f1e15b', '#61cdbb', '#97e3d5', '#e8c1a0',
  '#0D6986', '#50c278', '#acde61', '#dccb0c',
];

export const DEFAULT_LINE_CHART_PROPERTIES = {
  colors: campaignTypeColors,
  maxLineAreaItems: 2,
  smallDotsDaysAmountLimit: 60,
  smallDotsLinesAmountLimit: 4,
  axisItemsNumberBreak: 15,
  margin: {
    'top': 20,
    'right': 60,
    'bottom': 50,
    'left': 60
  },
  xScale: {
    type: 'time',
    format: '%Y-%m-%d',
    useUTC: false,
    precision: 'day',
  },
  yScale: {
    'type': 'linear',
    'stacked': false,
  },
  minY: 'auto',
  maxY: 'auto',
  stacked: true,
  axisTop: null,
  axisRight: null,
  axisBottom: {
    format: '%m-%d',
    legendOffset: 40,
  },
  axisLeft: {
    'orient': 'left',
    'tickSize': 5,
    'tickPadding': 5,
    'tickRotation': 0,
    'legendOffset': -40,
    'legendPosition': 'center',
    'tickValues': 5
  },
  dotSize: 10,
  dotSizeSmall: 5,
  dotColor: 'inherit:darker(0.3)',
  dotBorderWidth: 2,
  dotBorderWidthSmall: 1,
  dotBorderColor: '#ffffff',
  enableDotLabel: false,
  dotLabel: 'y',
  dotLabelYOffset: -12,
  animate: true,
  motionStiffness: 90,
  motionDamping: 15,
  legends: [
    {
      'anchor': 'bottom-left',
      'direction': 'row',
      'justify': false,
      'translateX': -30,
      'translateY': 50,
      'itemsSpacing': 50,
      'itemDirection': 'left-to-right',
      'itemWidth': 80,
      'itemHeight': 20,
      'itemOpacity': 0.75,
      'symbolSize': 12,
      'symbolShape': 'circle',
      'symbolBorderColor': 'rgba(0, 0, 0, .5)',
    }
  ]
};

