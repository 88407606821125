import * as React from 'react';
import { t } from '../../../../base/helpers';
import { Button, ButtonTypes, EmptyDataComponent, Loader, Select, ConfirmIconButton, TypeSizeBtn } from '@ppg/styled';
import { inject, observer } from 'mobx-react';
import { WrappedPagination } from '../../../../assets/wrapped';
import { UserStore } from '../../../../stores/user/UserStore';
import ReactTooltip from 'react-tooltip';
import { Channel } from '../../../../modelsMobx/Channel';

interface INotificationChannelsTable {
  userStore?: UserStore;
}

@inject('userStore')
@observer
export class NotificationChannelsTable extends React.Component<INotificationChannelsTable> {

  public componentDidUpdate(): void {
    ReactTooltip.rebuild();
  }

  public handleOnChange(event) {
    const channelsStore = this.props.userStore.notificationChannels;
    const channel = channelsStore.channels.find(channel => channel === channelsStore.channelToEdit.channel);
    const tags = channel.getProperty('tags').getValue();
    const index = tags.indexOf(event.target.value.toUpperCase());

    if (event.target.checked) {
      index === -1 && tags.push(event.target.value.toUpperCase());
    } else {
      index > -1 && tags.splice(index, 1);
    }
  }

  public async handleOnEdit(channel) {
    const channelsStore = this.props.userStore.notificationChannels;
    const { channelToEdit } = channelsStore;

    if (channelToEdit.haveTagsChanged()) {
      await channelsStore.updateTags(channel);
    }

    if (channelToEdit.hasLanguageChanged()) {
      await channelsStore.updateLanguage(channel);
    }

    if (channelsStore.channelToEdit.channel.tags.length >= 1) {
      channelsStore.onChannelCancel();
    }
  }

  public handleOnCancel() {
    const channelsStore = this.props.userStore.notificationChannels;
    const { initialChannel, channel } = channelsStore.channelToEdit;

    const selectedChannel = channelsStore.channels.find(ch => ch.id === channel.id);

    if (selectedChannel) {
      selectedChannel.getProperty('language').setValue(initialChannel.language);
      selectedChannel.getProperty('tags').setValue(initialChannel.tags);
    }

    channelsStore.onChannelCancel();
  }

  private async handleDeleteChannel(channel: Channel): Promise<void> {
    const channelsStore = this.props.userStore.notificationChannels;
    await channelsStore.removeChannel(channel.id)
  }

  public render() {
    const channelsStore = this.props.userStore.notificationChannels;

    const languages = channelsStore.languages.map(lang => {
      return { name: lang, value: lang };
    });

    if (channelsStore.isLoading) {
      return <Loader/>;
    }

    return <>
      { channelsStore.channels.length > 0 ?
        <table className="notification-channels-table">
          { this.renderTableHeader() }
          <tbody>
          { channelsStore.channels.map((channel, idx) => {
            return (
              <tr key={ idx }>
                <td data-label={ t('E-mail') }>{ channel.payload }</td>
                <td data-label={ t('Status') }>{ channel.status.toLowerCase() }</td>
                {
                  channelsStore.channelToEdit && channelsStore.channelToEdit.channel === channel
                    ? <>
                      <td className="update-tags-wrapper" data-label={ t('Tags') }>
                        { channelsStore.tags.map((tag, idx) => {
                          return <label htmlFor={ tag } key={ idx }>
                            <div className="checkbox-wrapper">
                              <input type="checkbox" id={ tag }
                                     name={ 'tags' }
                                     onChange={ (e) => this.handleOnChange(e) }
                                     defaultChecked={ channel.getProperty('tags').getValue().find(item => item === tag) }
                                     value={ tag }/>
                              <span/>
                            </div>
                            { tag.toLowerCase() }
                          </label>;
                        }) }
                      </td>
                      <td data-label={ t('Language') }>
                        <Select property={ channel.getProperty('language') }
                                options={ languages }/>
                      </td>
                      <td>
                        <Button icon={ 'ok-badge' }
                                size={ 'medium' }
                                isOutline
                                type={ ButtonTypes.DEFAULT }
                                onClick={ () => this.handleOnEdit(channel) }
                                isSquare/>
                        <Button icon={ 'cancel-badge' }
                                isOutline
                                size={ 'medium' }
                                onClick={ () => this.handleOnCancel() }
                                type={ ButtonTypes.DEFAULT }
                                isSquare/>
                      </td>
                    </>
                    : <>
                      <td data-label={ t('Tags') } className="channels-tags-cell">{ channel.tags.map(tag => tag.toLowerCase()).join(', ') }</td>
                      <td data-label={ t('Language') }> { channel.language }</td>
                      <td>
                        <ConfirmIconButton icon={ 'trash' }
                                           typeBtn="white"
                                           positionBtn="row"
                                           size={ TypeSizeBtn.MEDIUM }
                                           dataTip={ t('user/notification-channels::Delete channel') }
                                           dataTipConfirmBtn={ t('user/notification-channels::Confirm channel segment') }
                                           dataTipCancelBtn={ t('user/notification-channels::Cancel channel segment') }
                                           onConfirm={ () => this.handleDeleteChannel(channel) }/>

                        <Button icon={ 'pencil' }
                                size={ 'medium' }
                                onClick={ () => channelsStore.onChannelEdit(channel) }
                                type={ ButtonTypes.DEFAULT }
                                dataTip={ t('user/notification-channels::Edit') }
                                isSquare/>
                      </td>
                    </>
                }
              </tr>
            );
          }) }
          </tbody>
        </table> :
        <EmptyDataComponent
          content={ t('user/notification-channels::You don\'t have any defined channels') }
          buttonContent={ t('user/notification-channels::new channel') }
          icon={ 'bell' }
          buttonIcon={ 'plus-badge' }
          onClick={ () => channelsStore.onChannelCreate() }
        />
      }

      <WrappedPagination total={ channelsStore.getProperty('total') }
                         offsetProperty={ channelsStore.getProperty('offset') }
                         itemsPerPage={ channelsStore.getProperty('perPage') }
                         perPageSelector={ true }
                         infinityScroll={ channelsStore.getProperty('infinity').getValue() }/>
      <ReactTooltip type="light" effect="solid"/>
    </>;
  }

  private renderTableHeader() {
    return <thead className="borderb p10">
    <tr>
      <th className="primary">{ t('user/notification-channels::Email') }</th>
      <th className="primary hide-on-800">{ t('user/notification-channels::Request status') }</th>
      <th className="primary">{ t('user/notification-channels::Tags') }</th>
      <th className="primary">{ t('user/notification-channels::Language') }</th>
      <th/>
    </tr>
    </thead>;
  }
}

// Translations
// t('user/notification-channels::pending')
// t('user/notification-channels::accepted')

// t('user/notification-channels::accounting')
// t('user/notification-channels::all')
// t('user/notification-channels::organization_common')
// t('user/notification-channels::reports')
// t('user/notification-channels::newsletter_mailing')
