import { API, UseCase } from '@ppg/common';
import { ExportCollectionType, ExportStateType } from '../../modelsMobx/ExportFile';

export interface ExportResponse {
  id: string;
  type: ExportCollectionType;
  state: ExportStateType;
  createdAt: Date;
  from: string;
  to: string;
  rangeReference: string;
}


interface IGetExportsCommand {
  projectID: string;
  exportID: string;
}

export class GetExportUseCase extends UseCase<IGetExportsCommand, ExportResponse> {
  protected async execute({ projectID, exportID }) {
    return API.HTTP.get<ExportResponse>(`core/project/${ projectID }/exports/${ exportID }`);
  }
}

export const getExportUseCase = new GetExportUseCase();
