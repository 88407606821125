import { isUrlWithProtocol, property, PropertyHandler } from '@ppg/common';
import { t } from '../../base/helpers';
import { set } from 'mobx';

export interface IPopupRuleDTO {
  enable: boolean;
  url: string;
  newRule?: boolean;
  re: {
    source: string;
    flags: string;
  };
}

export class PopupRule extends PropertyHandler {
  @property()
  public action: string;

  @property([isUrlWithProtocol()])
  public url: string;

  private newRule: boolean;
  private initialValues;

  public re: {
    source: string,
    flags: string,
  };

  constructor(popupRuleDTO: IPopupRuleDTO) {
    super();
    this.action = popupRuleDTO.enable ? 'show' : 'hide';
    this.url = popupRuleDTO.url;
    this.re = popupRuleDTO.re;
    this.newRule = popupRuleDTO.newRule;
    this.setInitialValues();
  };

  public get actionName() {
    return this.action === 'show' ? t('show') : t('hide');
  };

  public get isEnable() {
    return this.action === 'show';
  }

  private setInitialValues() {
    this.initialValues = {
      url: this.url,
      action: this.action,
      newRule: false,
    };
  };

  public restore() {
    set(this, this.initialValues);
  };

  public get isNew() {
    return this.newRule;
  };

  public onEditSave() {
    this.newRule = false;
    this.setInitialValues();
  };

  public static get popupRuleOptions() {
    return [{ name: t('show'), value: 'show' }, { name: t('hide'), value: 'hide' }];
  };

  public toProjectDTO(): IPopupRuleDTO {
    return {
      enable: this.isEnable,
      url: this.url,
      re: this.re,
    };
  };
}
