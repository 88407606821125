import { ChannelType } from './ChannelType';

export interface ISubscriberEvent {
  id: string;
  kind: ISubscriberEventTypes;
  project: string;
  subscriber: string;
  channel: string;
  channelType: ChannelType;
  createdAt: string;
}

export interface IPopulatedSubscriber {
  campaign?: {title: string};
  automation?: {name: string};
  automationProcess?: string;
}

export enum ISubscriberEventTypes {
  STATUS_SUBSCRIBED = 'subscribed',
  STATUS_UNSUBSCRIBED = 'unsubscribed',
  CLICKED = 'clicked',
  DELIVERED = 'delivered',
  SENT = 'sent',
  NO_ACTIVITY = 'noactivity',
  ADDED = 'added'
}
