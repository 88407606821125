import styled from '@emotion/styled';

interface IProjectItem {
  isSelected: boolean;
}

interface IBtnOwnedProjects {
  isActive: boolean;
}

export const CampaignCopyBox = styled.div`
  padding: 5px 60px 0;
  overflow-y: auto;
  min-height: 550px;
  max-height: 600px;
  cursor: default;
  
  @media(max-width: 550px) {
    padding: 5px 30px 0;
  }
`;

export const CampaignCopyTitle = styled.h2`
  margin: 0;
  font-size: 1.5rem;
  letter-spacing: .01em;
  line-height: 1.6;
`;

export const EmptyProjectsTitle = styled.p`
  margin: 22px 0 0 4px;
  font-size: 1.5rem;
  letter-spacing: .01em;
`

export const SearchProjectBox = styled.div`
  margin: 16px 0 22px;

  .search-input-wrapper {
    width: 50%;
  }

  .search-input {
    margin-bottom: 0;
    height: 38px;
  }

  .icon-search {
    top: 11px;
  }

  @media (max-width: 600px) {
    .search-input-wrapper {
      width: 100%;
    }
  }
`;

export const ProjectsListBox = styled.div`
  height: 260px;
  overflow-y: auto;
`;

export const ProjectsList = styled.ul`
  padding: 6px 15px 0 5px;
  margin: 6px 0 0;
  display: grid;
  grid-template-columns: repeat(3, 30%);
  justify-content: space-between;
  align-items: flex-start;
  row-gap: 10px;
  list-style: none;

  @media (max-width: 780px) {
    grid-template-columns: repeat(2, 45%);
  }

  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 90%);
    justify-content: center;
  }
`;

export const ProjectItem = styled.li<IProjectItem>`
  padding: 12px 10px;
  text-align: center;
  border-radius: 4px;
  box-shadow: ${ props => props.isSelected ? "0 0 1px 1px #9dd8cc" : "0 0 1px 1px #dce2e1" };
  transition: box-shadow 0.3s;
  position: relative;
  cursor: ${ props => props.isSelected ? "default" : "pointer" };

  p {
    margin-bottom: 0;
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
`;

export const DeselectProjectIcon = styled.div`
  border: 1px solid #9dd8cc;
  position: absolute;
  top: -8px;
  right: -10px;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: #e9f9f5;
  color: #7b7b7b;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon-close {
    font-size: 1.3rem;
  }
`;

export const ProjectItemName = styled.h4`
  margin-bottom: 5px;
  font-size: 1.6rem;
  font-weight: 500;
  overflow-wrap: anywhere;
`;

export const ProjectItemUrl = styled.p`
  overflow-wrap: anywhere;
`;

export const BtnTypeProjectsBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
`;

export const BtnProjects = styled.div<IBtnOwnedProjects>`
  padding: 5px 15px;
  background: ${ props => props.isActive ? "#eff8f6" : "#fff" };
  border-color: ${ props => props.isActive ? "#c8dfda" : '#dedede' };
  border-width: 1px;
  border-style: solid;
  color: ${ props => props.isActive ? "#54a696" : "#979797" };
  font-size: 1.5rem;
  letter-spacing: -0.3px;
  font-weight: 500;
  transition: background-color 0.2s, color 0.2s;
  cursor: pointer;
  
  @media(max-width: 400px) {
    font-size: 1.4rem;
  }
`;

export const BtnOwnedProjects = styled(BtnProjects)`
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

export const BtnSharedProjects = styled(BtnProjects)`
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: none;
`;

export const SelectedProjectsBox = styled.section`
  margin: 20px 0 15px;

  p {
    margin: 0;
    font-size: 1.4rem;
    letter-spacing: 0;
  }
`;

export const SelectedProjectsTitle = styled.h4`
  margin-bottom: 5px;
  font-size: 1.6rem;
  font-weight: 500;
`;

export const SelectedProjects = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 0;
  list-style: none;
  overflow-wrap: anywhere;
`;

export const SelectedProject = styled.li`
  margin-right: 10px;
  border: 1px solid #ddefeb;
  padding: 4px 15px;
  border-radius: 4px;
  background-color: #eff8f6;
`;
