import { API, UseCase } from "@ppg/common";
import { IListQueryOptions } from '../../../interfaces/IListQueryOptions';
import { IListResponse } from '../../../interfaces/IListResponse';
import { Label } from '../../../modelsMobx';

interface IGetLabelsRequest extends IListQueryOptions {
  sortBy: string;
  key: string;
  query: string;
}

export interface IGetLabelsResponse extends IListResponse<Label[]> {}

export class GetLabelsUseCase extends UseCase<IGetLabelsRequest, IGetLabelsResponse> {
  protected async execute({ offset, limit, sortBy, key, query}:IGetLabelsRequest) {
    return API.HTTP.get<IGetLabelsResponse>(`core/projects/${ API.HTTP.currentProjectId }/segmentation/labels?offset=${ offset }&limit=${ limit }&sortBy=${sortBy}&key=${key}&query=${query}`)
  }
}

export const getLabelsUseCase = new GetLabelsUseCase()
