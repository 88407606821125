import * as React from 'react';
import { t } from '../../base/helpers';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

@observer
export class WebpSupportInfo extends React.Component<{}> {
  @observable isLoading: boolean = true;
  @observable isWebpSupported: boolean;

  public async componentDidMount() {
    this.isWebpSupported = await this.supportsWebp();
    this.isLoading = false;
  }

  private supportsWebp = async () => {
    if (!self.createImageBitmap) return false;
    const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
    const blob = await fetch(webpData).then(r => r.blob());
    return createImageBitmap(blob).then(() => true, () => false);
  }

  public render() {
    if (this.isLoading || this.isWebpSupported) {
      return null;
    }
    
    return <div className='alert-block alert--warning m5b'>
      { t('Oops, it seems your browser doesn\'t support the webp format. This can cause problems with displaying images inside the application. Until we fix this issue, please open the application in a different browser.') }
    </div>;
  }
}
