import * as React from 'react';
import { observable, action, set, remove, } from 'mobx';
import { VNodeProperties } from 'maquette';
import { ISubscriptionForm, ISubscriptionFormElement } from './SubscriptionFormInterfaces';
import GeneratorID from './helpers/GeneratorID';

export class SubscriptionFormElementStore implements ISubscriptionFormElement {
  @observable public id: string;
  @observable public text: string;
  @observable public styles: React.CSSProperties;
  @observable public type: string;
  @observable public uuid: string;
  @observable public properties: VNodeProperties;
  @observable public cachedAnimationName: string;
  @observable public cachedMobileAnimationName: string;

  constructor(element: ISubscriptionForm) {
    const id = GeneratorID.generateID();

    this.id = element.id || id;
    this.text = element.text || '';
    this.styles = element.styles || {};
    this.type = element.type || 'div';
    this.uuid = element.uuid || id;
    this.properties = element.properties || {};
  }

  public hasStyles(styles: {}) {
    return Object.keys(styles).every((el) => this.styles[el] === styles[el]);
  }

  public hasMobileStyles(styles: {}) {
    return Object.keys(styles).every((el) => this.styles['@media (max-width: 480px)'][el] === styles[el]);
  }

  @action
  public toggleStyles(styles: {}) {
    return this.hasStyles(styles) ? this.removeStyles(styles) : this.addStyles(styles);
  }

  @action
  public setMobileStyles(mobileStyles: any) {
    if (!this.styles['@media (max-width: 480px)']) {
      this.styles['@media (max-width: 480px)'] = {};
    }
    Object.keys(mobileStyles).every(el => this.styles['@media (max-width: 480px)'][el] = mobileStyles[el]);
  }

  @action
  public setMediumStyles(mediumStyles: any) {
    if (!this.styles[`@media (min-width: 481px) and (max-width: 700px)`]) {
      this.styles[`@media (min-width: 481px) and (max-width: 700px)`] = {};
    }
    Object.keys(mediumStyles).every(el => this.styles[`@media (min-width: 481px) and (max-width: 700px)`][el] = mediumStyles[el]);
  }

  @action
  public setStyles(styles: {}) {
    return this.addStyles(styles);
  }

  @action
  public setAnimation(styles: any, mobileStyles: any) {
    if (!!styles) {
      this.addStyles(styles);
      this.cachedAnimationName = styles.animationName;
    }

    if (!!mobileStyles) {
      this.setMobileStyles(mobileStyles);
      this.cachedMobileAnimationName = mobileStyles.animationName;
    }

    setTimeout(() => {
      this.styles.animationName = '';
      this.styles['@media (max-width: 480px)'].animationName = '';
    }, 600);
  }

  private addStyles(styles: {}) {
    set(this.styles, styles);
  }

  private removeStyles(styles: {}) {
    return Object.keys(styles).map((el) => remove(this.styles, el));
  }

  public setCachedAnimation() {
    this.styles.animationName = this.cachedAnimationName;
    if (!this.styles['@media (max-width: 480px)']) {
      this.styles['@media (max-width: 480px)'] = {};
    }
    this.styles['@media (max-width: 480px)'].animationName = this.cachedMobileAnimationName;
  }
}
