export enum selectorSettingsTypes {
  GET_JS = 'getJS',
  GET_TEXT = 'getText',
  GET_NUMBER = 'getNumber',
  GET_CLICK = 'getClick',
  GET_COUNT = 'getCount',
  GET_INPUT = 'getInput',
  TAG = 'tag',
  TAG_JS = 'tagJS',
  CUSTOM_ID_FROM_VARIABLE = 'custom_id_from_variable',
  CUSTOM_ID_FROM_SELECTOR = 'custom_id_from_selector',
  CUSTOM = 'custom',
}

export enum selectorEventTypes {
  VIEW = 'view',
  BLUR = 'blur',
  CLICK = 'click',
}

export enum selectorTypeTypes {
  ARRAY = 'array',
  NUMBER = 'number',
  STRING = 'string',
  BOOLEAN = 'boolean',
  DATE = 'date',
}

export enum selectorReducerTypes {
  SUM = 'sum',
  LAST = 'last',
  AVG = 'avg',
  UNIQ = 'uniq',
  COUNT = 'count',
  OR = 'or'
}
