import { CreateCampaignActivityExportUseCase } from './CreateCampaignAcitivityExportUseCase';
import { GetActiveSubscribersUseCase } from './GetActiveSubscribersUseCase';
import { GetCampaignActivityComparisonUseCase } from './GetCampaignActivityComparisonUseCase';
import { GetCampaignBulkStatsUseCase } from './GetCampaignBulkStatsUseCase';
import { GetCampaignSummaryUseCase } from './GetCampaignSummaryUseCase';
import { GetSubscribersActivityComparisonUseCase } from './GetSubscribersActivityComparisonUseCase';
import { GetSubscribersActivityHistogramDataUseCase } from './GetSubscribersActivityHistogramDataUseCase';

/**
 * Campaigns
 */
export const createCampaignActivityExportUseCase = new CreateCampaignActivityExportUseCase();
export const getCampaignActivityComparisonUseCase = new GetCampaignActivityComparisonUseCase();
export const getCampaignBulkStatsUseCase = new GetCampaignBulkStatsUseCase();
export const getCampaignSummaryUseCase = new GetCampaignSummaryUseCase();

/**
 * Subscribers
 */
export const getActiveSubscribersUseCase = new GetActiveSubscribersUseCase();
export const getSubscribersActivityComparisonUseCase = new GetSubscribersActivityComparisonUseCase();
export const getSubscribersActivityHistogramDataUseCase = new GetSubscribersActivityHistogramDataUseCase();

export { getABTestStatisticUseCase, IGetABTestStatisticResponse, IStatisticVariant } from "./GetABTestStatisticUseCase";

/**
 * Dashboard Subscribers
 */

export { getProjectSubscribedActivityUseCase } from "./subscribers/GetProjectSubscribedActivityUseCase";
export { getProjectActiveSubscribersUseCase } from "./subscribers/GetProjectActiveSubscribersUseCase";
export { getProjectSubscribersAverageLifetimeUseCase } from "./subscribers/GetProjectSubscribersAverageLifetimeUseCase";
export { getProjectSubscribersStructureUseCase } from "./subscribers/GetProjectSubscriberStructureUseCase";
export { getProjectActiveSubscribersInRangeResult } from "./subscribers/GetProjectActiveSubscribersInRangeUseCase";
export { getProjectActiveSubscriberHistogramUseCase } from "./subscribers/GetProjectActiveSubscriberHistogramUseCase";
export { getProjectSubscribersActivityHistogramUseCase, IProjectGetSubscribersActivityHistogramRequest } from "./subscribers/GetProjectSubscriberActivityHistogramUseCase";
export { getProjectSubscribersLifetimeStructureUseCase } from "./subscribers/GetProjectSubscribersLifetimeStructureUseCase";
export { getProjectSubscribersLocalisationUseCase } from "./subscribers/GetProjectSubscribersLocalisationUseCase";

/**
 * Dashboard Automations
 */

export { getProjectAutomationStatsUseCase, IAutomationStats } from "./automation/GetProjectAutomationStatsUseCase";
export { getProjectAutomationStatsHistogramUseCase, IAutomationStatisticsHistogramItem } from "./automation/GetProjectAutomationStatsHistogramUseCase";
export { getProjectAutomationCampaignsUseCase, IAutomationsCampaignsItem } from "./automation/GetProjectAutomationCampaignsUseCase";
export { getProjectAutomtionRankUseCase, IAutomationRankResultItem } from "./automation/GetProjectAutomationRankUseCase";

/**
 * Dashboard Campaigns
 */

export { getProjectClickedAndDeliveredUseCase } from "./campaigns/GetProjectClickedAndDeliveredUseCase";
export { getProjectCampaignStatsUseCase } from "./campaigns/GetProjectCampaignStatsUseCase";
export { getProjectSendHoursUseCase } from "./campaigns/GetProjectSendHoursUseCase";
export { getProjectCampaignStatsHistogramUseCase, IStatisticsHistogramItem } from "./campaigns/GetProjectCampaignStatsHistogramUseCase";
export { getProjectCampaignAverageCtrUseCase } from "./campaigns/GetProjectCampaignAverageCtrUseCase";
